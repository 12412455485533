import { Rect, Group, Text } from "react-konva";
import { PlayTypeEnum } from "../../../generated/from-api/models/enums/play-type.enum";
import { colors } from "../../../shared/shared-with-mobile/play-editor/play-editor-colors";
import { lineOfScrimmageY } from "../../../shared/shared-with-mobile/play-editor/playEditor.constants";
import {
  hexToRgbA,
  reduceAlpha,
} from "../../../shared/shared-with-mobile/play-editor/playEditor.utils";

interface Props {
  stageWidth: number;
  stageHeight: number;
  playType: PlayTypeEnum;
  isPlay?: boolean; // if it's not a play, then it's a formation
}

const PlayerChangingFieldOverlay: React.FC<Props> = ({
  stageWidth,
  stageHeight,
  playType,
  isPlay = true,
}) => {
  return (
    <Group listening={false}>
      <Rect
        x={2}
        y={2}
        width={stageWidth - 5}
        height={stageHeight / 2 - 7}
        fill={reduceAlpha(hexToRgbA(colors.punch), 0.25)}
        stroke={colors.punch}
        strokeWidth={1}
        cornerRadius={10}
        visible={isPlay}
      />
      <Rect
        x={2}
        y={lineOfScrimmageY * stageHeight + 4}
        width={stageWidth - 5}
        height={stageHeight / 2 - 7}
        fill={reduceAlpha(hexToRgbA(colors.cerulean), 0.25)}
        stroke={colors.cerulean}
        strokeWidth={1}
        cornerRadius={10}
      />
      <Text
        text={`ADD/REMOVE ${
          playType === "Offensive" ? "DEFENSIVE" : "OFFENSIVE"
        } PLAYERS`}
        x={0}
        y={0}
        width={stageWidth}
        height={stageHeight / 10}
        fill={colors.white}
        stroke={colors.white}
        letterSpacing={1}
        strokeWidth={0.5}
        fontFamily="Chakra Petch"
        fontStyle="italic"
        fontSize={30}
        align="center"
        verticalAlign="middle"
        visible={isPlay}
      />
      <Text
        text={`ADD/REMOVE ${
          playType === "Offensive" ? "OFFENSIVE" : "DEFENSIVE"
        } PLAYERS`}
        x={0}
        y={(stageHeight * 9) / 10}
        width={stageWidth}
        height={stageHeight / 10}
        fill={colors.white}
        stroke={colors.white}
        letterSpacing={1}
        strokeWidth={0.5}
        fontFamily="Chakra Petch"
        fontStyle="italic"
        fontSize={30}
        align="center"
        verticalAlign="middle"
      />
    </Group>
  );
};

export default PlayerChangingFieldOverlay;
