import React, { useContext } from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import PercentageBar from "../../../components/PercentageBar/PercentageBar";
import PositionsList from "../../../components/PositionsList/PositionsList";

import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";
import { CustomQuizModel } from "../../../generated/from-api/models/custom-quiz.model";
import { CustomQuizStatisticModel } from "../../../generated/from-api/models/custom-quiz-statistic.model";

import kebabMenu from "../../../resources/images/kebabMenu.svg";

// This view is mostly a clone of the install one, include it's CSS and the Installs one.
import quizzesStyles from "../Quizzes.module.scss";
import installStyles from "../../Installs/Installs.module.scss";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { QuizzesContext } from "../../../shared/shared-with-mobile/providers/quizzes.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { useHistory } from "react-router-dom";
import { userRoles } from "../../../shared/shared-with-mobile/constants";
const styles = { ...installStyles, ...quizzesStyles };

interface ArchivedQuizProps {
  data: CustomQuizModel;
  stats: CustomQuizStatisticModel[];
}

const ArchivedQuiz: React.FC<ArchivedQuizProps> = ({ data, stats }) => {
  const { userProfile } = useContext(UserContext);
  const { removeQuiz, updateQuiz } = useContext(QuizzesContext);
  const { dispatchModal, dispatchToast } = useContext(UIContext);
  const history = useHistory();
  const quizRouteName =
    userProfile?.role === userRoles.ADMIN ? "global-flashcards" : "flashcards";

  const preparedStats = {
    avgScore: 0,
    finished: 0,
    assigned: 0,
  };

  stats.forEach((statPoint: CustomQuizStatisticModel) => {
    preparedStats.avgScore += statPoint.score * 100;
    preparedStats.finished += statPoint.completedAt === null ? 0 : 1;
    preparedStats.assigned++;
  });
  preparedStats.avgScore =
    preparedStats.avgScore / stats.length || preparedStats.avgScore;

  const unarchiveQuiz = async () => {
    const unarchived = Object.assign({}, data);
    unarchived.archived = false;
    try {
      const response = await APIService.CUSTOM_QUIZ.PUT(unarchived);
      updateQuiz(response as CustomQuizModel);
      dispatchToast({
        type: "success",
        message: "Quiz unarchived.",
      });
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "An error occurred while unarchiving the quiz.",
      });
    }
  };

  const openDeleteQuizModal = (quiz: CustomQuizModel) => {
    if (!quiz.id) {
      return;
    }

    dispatchModal({
      title: "Delete Flashcard Game",
      open: true,
      body: (
        <ConfirmationModal
          actionName="delete"
          itemName="game"
          actionCallback={async () => {
            try {
              await APIService.CUSTOM_QUIZ.DELETE(quiz.id as string);
              removeQuiz(quiz.id as string);
              history.push(`/${quizRouteName}`);
              dispatchToast({
                type: "success",
                message: "Game deleted.",
              });
            } catch (e) {
              dispatchToast({
                type: "error",
                message: "Error deleting game.",
              });
            }
          }}
        />
      ),
    });
  };

  return (
    <li
      key={`archive-item-${data.name}`}
      className={`
        ${styles.installsPageArchiveItem}
        ${userProfile?.role === "ADMIN" ? "simplified-admin" : ""}
      `}
    >
      <span className={styles.installsPageArchiveItemCell}>{data.name}</span>
      {userProfile?.role !== "ADMIN" ? (
        <>
          <span className={styles.installsPageArchiveItemCell}>
            {preparedStats.avgScore === 0
              ? "—"
              : preparedStats.avgScore.toFixed(1) + "%"}
          </span>
          <span
            className={`${styles.installsPageArchiveItemCell} ${styles.installsPageArchiveItemCellBar}`}
          >
            <PercentageBar
              hideText
              positive={preparedStats.finished}
              total={preparedStats.assigned}
            />
          </span>
          <span className={styles.installsPageArchiveItemCell}>
            {preparedStats.finished}
          </span>
          <span className={styles.installsPageArchiveItemCell}>
            {preparedStats.assigned}
          </span>
        </>
      ) : null}
      <span className={styles.installsPageArchiveItemCell}>
        {new Date(data.created || "").toLocaleString("en-US", {
          timeZone: "UTC",
          month: "short",
          day: "numeric",
        })}
      </span>
      {userProfile?.role === "ADMIN" ? (
        <span className={styles.installsPageArchiveItemCell}>
          {new Date(data.modified || "").toLocaleString("en-US", {
            timeZone: "UTC",
            month: "short",
            day: "numeric",
          })}
        </span>
      ) : null}
      {userProfile?.role !== "ADMIN" ? (
        <>
          <span className={styles.installsPageArchiveItemCell}>
            {(() => {
              const preparedDueDate = new Date(
                data.dueDate || ""
              ).toLocaleString("en-US", {
                timeZone: "UTC",
                month: "short",
                day: "numeric",
              });
              return preparedDueDate === "Invalid Date" ? "—" : preparedDueDate;
            })()}
          </span>
          <span
            className={`${styles.installsPageArchiveItemCell}  ${styles.installsPageArchiveItemCellList}`}
          >
            <PositionsList
              positions={data.showQuizToPositions}
              short={true}
              theme={"transparent"}
            />
          </span>
        </>
      ) : null}
      <Tooltip
        tip={
          <ol className={styles.installsPageListItemActionOverflow}>
            <li key={`archived-quiz-popup-unarchive`} onClick={unarchiveQuiz}>
              Unarchive
            </li>
            <li
              key={`archived-quiz-popup-delete`}
              onClick={() => {
                openDeleteQuizModal(data);
              }}
            >
              Delete
            </li>
          </ol>
        }
        clickTip
        noArrow
        noPadding
        color={"dark"}
        placement={"bottom"}
      >
        <img
          className={`${styles.installsPageListItemActionButton}`}
          src={kebabMenu}
        />
      </Tooltip>
    </li>
  );
};

export default ArchivedQuiz;
