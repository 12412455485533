import React, { useState } from "react";

interface Props {
  dropAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hoverClass: string;
  children: React.ReactNode | React.ReactNode[];
}

const DropUpload: React.FC<Props> = ({ dropAction, hoverClass, children }) => {
  const [fileOver, setFileOver] = useState(false);
  return (
    <span
      className={fileOver ? hoverClass : ""}
      onDrop={(e: any) => {
        dropAction(e.dataTransfer.files);
        setFileOver(false);
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragEnter={() => {
        setFileOver(true);
      }}
      onDragLeave={(e) => {
        const parentElement = (e.target as HTMLInputElement).closest(
          "." + hoverClass
        );
        if (
          parentElement?.getAttribute("class") ===
          (e.target as HTMLInputElement)?.getAttribute("class")
        ) {
          setFileOver(false);
        }
      }}
    >
      {children}
    </span>
  );
};

export default DropUpload;
