import React, { useState } from "react";

import { PlaySetModel } from "../../../generated/from-api/models/play-set.model";

export const PlaySetsContext = React.createContext<{
  playSets: PlaySetModel[];
  addPlaySet: (playSet: PlaySetModel) => void;
  updatePlaySet: (playSet: PlaySetModel) => void;
  setPlaySets: (playSet: PlaySetModel[]) => void;
  clearPlaySets: () => void;
  removePlaySet: (playSet: string) => void;
  selectedPlaySetId: string | undefined;
  setSelectedPlaySetId: (playSetId: string | undefined) => void;
  reopenPlaySetModal: boolean;
  setReopenPlaySetModal: (openState: boolean) => void;
}>({
  playSets: [],
  addPlaySet: () => null,
  updatePlaySet: () => null,
  setPlaySets: () => null,
  clearPlaySets: () => null,
  removePlaySet: () => null,
  selectedPlaySetId: undefined,
  setSelectedPlaySetId: () => null,
  reopenPlaySetModal: false,
  setReopenPlaySetModal: () => null,
});

export const PlaySetsProvider: React.FC = ({ children }) => {
  const [playSets, setPlaySets] = useState<PlaySetModel[]>([]);
  const [reopenPlaySetModal, setReopenPlaySetModal] = useState<boolean>(false);
  const [selectedPlaySetId, setSelectedPlaySetId] = useState<
    string | undefined
  >();

  const updatePlaySet = (updatedPlaySet: PlaySetModel) => {
    const nextPlaySets = playSets.map((playSet) => {
      if (playSet.id === updatedPlaySet.id) {
        return updatedPlaySet;
      }

      return playSet;
    });

    setPlaySets(nextPlaySets);
  };

  const addPlaySet = (playSetToAdd: PlaySetModel) => {
    const nextPlaySets = [...playSets, playSetToAdd];
    setPlaySets(nextPlaySets);
  };

  const clearPlaySets = () => {
    setPlaySets([]);
    setSelectedPlaySetId(undefined);
  };

  const removePlaySet = (playSetId: string) => {
    const nextPlaySets = playSets.filter((ps) => ps.id !== playSetId);

    setPlaySets(nextPlaySets);
    if (selectedPlaySetId && selectedPlaySetId === playSetId) {
      setSelectedPlaySetId(undefined);
    }
  };

  return (
    <PlaySetsContext.Provider
      value={{
        reopenPlaySetModal,
        selectedPlaySetId,
        playSets,
        setReopenPlaySetModal,
        addPlaySet,
        setSelectedPlaySetId,
        updatePlaySet,
        setPlaySets,
        clearPlaySets,
        removePlaySet,
      }}
    >
      {children}
    </PlaySetsContext.Provider>
  );
};
