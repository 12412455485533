import * as React from "react";

const CircleCheckmarkIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <circle
      cx="16"
      cy="16"
      r="15"
      stroke="url(#paint0_linear_170_20242)"
      strokeWidth="2"
    />
    <path
      d="M8.72705 16.2203L13.4191 20.6224L23.2725 11.3779"
      stroke="url(#paint1_linear_170_20242)"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_170_20242"
        x1="9.18646"
        y1="2.27274e-07"
        x2="27.6108"
        y2="22.4868"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFBC17" />
        <stop offset="1" stopColor="#FFEA2D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_170_20242"
        x1="12.9027"
        y1="11.3779"
        x2="17.3504"
        y2="19.9191"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFBC17" />
        <stop offset="1" stopColor="#FFEA2D" />
      </linearGradient>
    </defs>
  </svg>
);

export default CircleCheckmarkIcon;
