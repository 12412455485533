import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { API_BASE_URL } from "../../constants";

export const UserService = {
  /**
   * Use to create a new user
   * @param email
   * @param password
   */
  SIGNUP_WITH_EMAIL: async function (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    phone: string
  ): Promise<undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.USER}/signup-with-email`, {
        firstName,
        lastName,
        email,
        password,
        phone,
      })
    ).data;
  },
  RESEND_EMAIL_VERIFICATION: async function (
    email: string
  ): Promise<{ emailSent: boolean }> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.USER}/resend-email`, {
        email,
      })
    ).data;
  },

  /**
   * Use to check phone number
   * @param phoneNumber
   */
  CHECK_IF_PHONE_NUMBER_EXISTS: async function (
    phoneNumber: string
  ): Promise<boolean> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.USER}/check-phone-number`, {
        phoneNumber,
      })
    ).data;
  },

  /**
   * Use to verify a new user's email address
   * @param token
   * @param email
   */
  VERIFY_EMAIL: async function (
    token: string,
    email: string
  ): Promise<{ verified: boolean; expired: boolean }> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.USER}/verify-email`, {
        params: {
          token: token,
          email: email,
        },
      })
    ).data;
  },

  /**
   * Use to send reset password link to user's email address
   * @param email
   */
  FORGOT_PASSWORD: async function (
    email: string
  ): Promise<{ verified: boolean; expired: boolean }> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.USER}/forgot-password`, {
        params: {
          email: email,
        },
      })
    ).data;
  },

  /**
   * Use to send reset password link to user's email address
   * @param email
   */
  RESET_PASSWORD: async function (
    email: string,
    token: string,
    newPassword: string
  ): Promise<{ verified: boolean; expired: boolean }> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.USER}/reset-password`, {
        email,
        token,
        newPassword,
      })
    ).data;
  },

  /**
   * Use to change an authenticated user's password
   * @param newPassword
   */
  CHANGE_PASSWORD: async function (newPassword: string): Promise<undefined> {
    const data = { newPassword };
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.USER}/change-password`, data)
    ).data;
  },

  /**
   * Use to delete account
   */
  DELETE_ACCOUNT: async function (): Promise<unknown> {
    return (
      await axios.delete(`${API_BASE_URL}${APIRoutes.USER}/delete-account`)
    ).data;
  },
};
