import React, { createContext, useState } from "react";
import { LicenseHolderModel } from "../../../generated/from-api/models/license.model";

export const LicensesContext = createContext<{
  allLicenses: LicenseHolderModel[] | undefined;
  currentLicenses: LicenseHolderModel[] | undefined;
  setAllLicenses: (licensesToAdd: LicenseHolderModel[]) => void;
  addLicenses: (licensesToAdd: LicenseHolderModel[]) => void;
  updateLicenses: (licenses: LicenseHolderModel[]) => void;
  clearLicenses: () => void;
}>({
  allLicenses: undefined,
  currentLicenses: undefined,
  setAllLicenses: () => null,
  addLicenses: () => null,
  updateLicenses: () => null,
  clearLicenses: () => null,
});

export const LicensesProvider: React.FC = ({ children }) => {
  const [allLicenses, setAllLicenses] = useState<
    LicenseHolderModel[] | undefined
  >(undefined);
  const [currentLicenses, setCurrentLicenses] = useState<
    LicenseHolderModel[] | undefined
  >(undefined);

  const addLicenses = (licensesToAdd: LicenseHolderModel[]) => {
    const nextCurrentLicenses = currentLicenses
      ? [...currentLicenses, ...licensesToAdd]
      : licensesToAdd;
    setCurrentLicenses(nextCurrentLicenses);
  };

  const updateLicenses = (licenses: LicenseHolderModel[]) => {
    setCurrentLicenses(licenses);
  };

  const clearLicenses = () => {
    setAllLicenses(undefined);
    setCurrentLicenses(undefined);
  };

  return (
    <LicensesContext.Provider
      value={{
        allLicenses,
        currentLicenses,
        setAllLicenses,
        addLicenses,
        updateLicenses,
        clearLicenses,
      }}
    >
      {children}
    </LicensesContext.Provider>
  );
};
