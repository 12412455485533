import { PlayerWithReadinessModel } from "../../../generated/from-api/models/dtos/player-with-readiness.model";
import { PlayCategoryEnum } from "../../../generated/from-api/models/enums/play-category.enum";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import { getReadinessScoreForUserByType } from "./getPlayerReadinessScoresBy";
import { PlayerOverallReadinessModel } from "../../../generated/from-api/models/dtos/player-to-play.readiness.model";

export interface AllRankings {
  overall: Ranking;
  run: Ranking;
  pass: Ranking;
  offense: Ranking;
  defense: Ranking;
  specialTeams: Ranking;
}
export interface Ranking {
  score: number;
  rank: number;
  leaderScore: number;
}
export const sortDescending = (a: number, b: number): number => {
  return b - a;
};
export const sortAscending = (a: number, b: number): number => {
  return a - b;
};
export const getDataForPlayerDetailStats = (
  player: PlayerModel,
  playersWithReadinessScores: PlayerWithReadinessModel[]
): {
  overall: Ranking;
  run: Ranking;
  pass: Ranking;
  offense: Ranking;
  defense: Ranking;
  specialTeams: Ranking;
} => {
  const myOverall = getReadinessScoreForUserByType(
    "category",
    "Overall",
    player.userId,
    playersWithReadinessScores
  );
  const myRunScore = getReadinessScoreForUserByType(
    "category",
    "Run" as PlayCategoryEnum,
    player.userId,
    playersWithReadinessScores
  );
  const myPassScore = getReadinessScoreForUserByType(
    "category",
    "Pass" as PlayCategoryEnum,
    player.userId,
    playersWithReadinessScores
  );
  const myOffensiveScore = myRunScore + myPassScore / 2;
  const myDefenseScore = getReadinessScoreForUserByType(
    "category",
    "Defense" as PlayCategoryEnum,
    player.userId,
    playersWithReadinessScores
  );
  const mySpecialTeamsScore = getReadinessScoreForUserByType(
    "category",
    "SpecialTeams" as PlayCategoryEnum,
    player.userId,
    playersWithReadinessScores
  );
  const overallScores: number[] = [];
  const runScores: number[] = [];
  const passScores: number[] = [];
  const offensiviveScores: number[] = [];
  const defensiveScores: number[] = [];
  const specialTeamsScores: number[] = [];
  playersWithReadinessScores.forEach((p) => {
    if (p.readinessScores && p.readinessScores.length > 0 && p.userId) {
      const overall = getReadinessScoreForUserByType(
        "category",
        "Overall",
        p.userId,
        playersWithReadinessScores
      );
      const runScore = getReadinessScoreForUserByType(
        "category",
        "Run" as PlayCategoryEnum,
        p.userId,
        playersWithReadinessScores
      );
      const passScore = getReadinessScoreForUserByType(
        "category",
        "Pass" as PlayCategoryEnum,
        p.userId,
        playersWithReadinessScores
      );
      const offensiveScore = runScore + passScore / 2;
      const defenseScore = getReadinessScoreForUserByType(
        "category",
        "Defense" as PlayCategoryEnum,
        p.userId,
        playersWithReadinessScores
      );
      const specialTeamsScore = getReadinessScoreForUserByType(
        "category",
        "SpecialTeams" as PlayCategoryEnum,
        p.userId,
        playersWithReadinessScores
      );
      overallScores.push(overall, 0);
      runScores.push(runScore, 0);
      passScores.push(passScore, 0);
      offensiviveScores.push(offensiveScore, 0);
      defensiveScores.push(defenseScore, 0);
      specialTeamsScores.push(specialTeamsScore, 0);
    }
  });
  const newRanking = {
    overall: getRanking(overallScores, myOverall),
    run: getRanking(runScores, myRunScore),
    pass: getRanking(passScores, myPassScore),
    offense: getRanking(offensiviveScores, myOffensiveScore),
    defense: getRanking(defensiveScores, myDefenseScore),
    specialTeams: getRanking(specialTeamsScores, mySpecialTeamsScore),
  };
  return newRanking;
};

export const getRanking = (scores: number[], myScore = 0): Ranking => {
  if (scores && scores.length) {
    const sortedScores = [...scores].sort(sortDescending);
    const rank = sortedScores.findIndex((v) => v === myScore) + 1;
    return {
      score: myScore,
      leaderScore: sortedScores[0],
      rank,
    };
  } else {
    return {
      score: 0,
      leaderScore: 0,
      rank: 1,
    };
  }
};

export const getPlayerRanking = (
  player: PlayerModel,
  playerScores: PlayerOverallReadinessModel[],
  scoreType:
    | "overallScore"
    | "playbookReadinessScore"
    | "installReadinessScore"
    | "quizReadinessScore"
): number => {
  const sortedScores = [...playerScores].sort((a, b) => {
    return Number(b[scoreType]) - Number(a[scoreType]);
  });

  const index = sortedScores.findIndex(
    (playerScore) => playerScore.playerId === player.id
  );

  const ranking = index + 1;

  return ranking;
};
