import * as React from "react";

const CheckmarkIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="19"
    viewBox="0 0 24 19"
  >
    <path
      d="M2 9.30952L8.45161 15.5L22 2.5"
      stroke="#FFD523"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export default CheckmarkIcon;
