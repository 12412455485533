import React, { useContext, useEffect, useState } from "react";

import styles from "./ImportInstallsModal.module.scss";
import CloseIcon from "../../../../resources/images/close.svg";
import MultiSelect, {
  MultiSelectOption,
} from "../../../../components/MultiSelect/MultiSelect";
import SingleSelect from "../../../../components/SingleSelect/SingleSelect";
import CharacterInput from "../../../../components/Input/CharacterInput";
import search from "../../../../resources/images/search.svg";
import ArrowLeftIcon from "../../../../resources/images/arrowLeft.svg";
import { InstallModel } from "../../../../generated/from-api/models/install.model";
import ImportInstallItem from "./ImportInstallItem";
import { Toolbar } from "@material-ui/core";
import Tooltip from "../../../../components/Tooltip/Tooltip";

import {
  defensivePositions,
  offensivePositions,
  specialTeamsPositions,
} from "../../../../shared/shared-with-mobile/play-editor/playEditor.constants";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import { isEmpty, trim } from "lodash";
import { APIService } from "../../../../shared/shared-with-mobile/api-client/api.service";
import { TagTypeEnum } from "../../../../generated/from-api/models/enums/tag-type.enum";
import { TagModel } from "../../../../generated/from-api/models/tag.model";
import { TeamContext } from "../../../../shared/shared-with-mobile/providers/team.provider";
import ImportInstallRename from "./ImportInstallRename";
import PositionsList from "../../../../components/PositionsList/PositionsList";
import { InstallsContext } from "../../../../shared/shared-with-mobile/providers/installs.provider";
import InstallPreview from "../InstallPreview/InstallPreview";
import InstallSlideViewOnly from "../installSlide/InstallSlideViewOnly";
import { INSTALL_SETTINGS } from "../../../../utils/web-only-constants";
import ContentLimitModal from "../../../../components/ContentLimitModal/ContentLimitModal";
import Button from "../../../../components/Button/Button";
import PhonePreview from "../../../../components/PhonePreview/PhonePreview";

interface InstallFiltersModel {
  category: any | null;
  searchText: string;
  positionGroups: string[];
}
const defaultTag: TagModel = {
  id: "",
  teamId: null,
  name: "All",
  type: TagTypeEnum.CUSTOM_QUIZ_CATEGORY,
  category: null,
  createdBy: "Team Nation",
};
const tagNameMaxLen = 20;

interface ImportInstallsProps {
  justPreviewInstall?: InstallModel;
}

const ImportInstalls: React.FC<ImportInstallsProps> = ({
  justPreviewInstall,
}) => {
  const { LABELS } = INSTALL_SETTINGS;
  const { currentTeam } = useContext(TeamContext);
  const { closeModal, dispatchModal, handleCreateError } = useContext(
    UIContext
  );
  const { dispatchToast } = useContext(UIContext);
  const { installs, globalInstalls, addInstall } = useContext(InstallsContext);
  const sortByOptions = [
    { label: "Most Recent", value: "Most Recent" },
    { label: "Name", value: "Name" },
  ];
  const positionGroupOptions: MultiSelectOption[] = [
    "Offense",
    "Defense",
    "Special Teams",
  ].map((positionGroup) => ({
    label: positionGroup,
    value: positionGroup,
  }));
  const [sortedInstalls, setSortedInstalls] = useState<InstallModel[]>([]);
  const [filteredInstalls, setFilteredInstalls] = useState<InstallModel[]>(
    globalInstalls || []
  );
  const [closing, setClosing] = useState<boolean>(false);
  const [globalCategories, setGlobalCategories] = useState<TagModel[]>([
    defaultTag,
  ]);
  const [globalCategoryNames, setGlobalCategoryNames] = useState<
    Map<string, string>
  >();
  const [selectedTab, setSelectedTab] = useState<TagModel>(defaultTag);
  const [searchText, setSearchText] = useState("");
  const [positionGroups, setPositionGroups] = useState<string[]>([]);
  const [sortByOption, setSortByOption] = useState<string>("Most Recent");
  const [previewInstall, setPreviewInstall] = useState<
    InstallModel | undefined
  >(justPreviewInstall || undefined);

  const filterInstalls = (
    installFilters: InstallFiltersModel,
    currentInstalls: InstallModel[]
  ): InstallModel[] => {
    const filterPublished = (install: InstallModel) =>
      install.published === true && install.teamId === null;

    const filterByCategory = (install: InstallModel) =>
      install.categoryTagId === installFilters.category.id;

    const filterBySearchText = (install: InstallModel) =>
      install.name
        .toLowerCase()
        .includes(installFilters.searchText.toLowerCase());

    const filterByPosition = (install: InstallModel) => {
      const positionsArray = install.showInstallToPositions.split("|");
      const obj: any = {};

      positionsArray.forEach((position: string) => {
        if (!obj[position]) {
          obj[position] = true;
        }
      });

      return (
        (installFilters.positionGroups.includes("Offense") &&
          offensivePositions.some((item: string) =>
            obj.hasOwnProperty(item)
          )) ||
        (installFilters.positionGroups.includes("Defense") &&
          defensivePositions.some((item: string) =>
            obj.hasOwnProperty(item)
          )) ||
        (installFilters.positionGroups.includes("Special Teams") &&
          specialTeamsPositions.some((item: string) =>
            obj.hasOwnProperty(item)
          ))
      );
    };

    let nextFilteredInstalls = currentInstalls || [];

    nextFilteredInstalls = nextFilteredInstalls.filter(filterPublished);

    if (installFilters.category !== null && installFilters.category.id !== "") {
      nextFilteredInstalls = nextFilteredInstalls.filter(filterByCategory);
    }

    if (installFilters.searchText) {
      nextFilteredInstalls = nextFilteredInstalls.filter(filterBySearchText);
    }

    if (
      installFilters.positionGroups !== undefined &&
      installFilters.positionGroups.length > 0
    ) {
      nextFilteredInstalls = nextFilteredInstalls.filter(filterByPosition);
    }

    return nextFilteredInstalls;
  };

  const sortByName = (installs: InstallModel[]) => {
    const alphabeticallySortedData = [
      ...(installs as any),
    ].sort((a: any, b: any) =>
      trim(a.name.toLowerCase()) === trim(b.name.toLowerCase())
        ? 0
        : trim(a.name.toLowerCase()) < trim(b.name.toLowerCase())
        ? -1
        : 1
    );
    return alphabeticallySortedData;
  };

  const sortByDate = (installs: InstallModel[]) => {
    const sortedDataByTime: any = [...(installs as any)].sort((a, b) => {
      if (a.created && b.created) {
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      }
      return -1;
    });
    return sortedDataByTime;
  };

  const onRenameOpen = (install: InstallModel) => {
    dispatchModal({
      open: true,
      size: "small",
      body: (
        <ImportInstallRename
          install={install}
          onSubmitCallback={onRenameSubmit}
        />
      ),
    });
  };

  const onRenameSubmit = (install: InstallModel) => {
    addGlobalInstall(install);
  };

  const copyInstall = (install: InstallModel, teamId: string) => {
    const copy = Object.assign({}, install);
    copy.teamId = teamId;
    copy.categoryTagId = undefined;
    copy.archived = false;
    copy.published = true;
    delete copy.id;
    return copy;
  };

  const addGlobalInstall = async (install: InstallModel) => {
    if (!currentTeam || !currentTeam.id) {
      return;
    }
    const newInstall = copyInstall(install, currentTeam.id);
    try {
      if (
        !installs ||
        installs.every((item: InstallModel) => item.name !== install.name)
      ) {
        try {
          const result = await APIService.INSTALL.POST([newInstall]);
          if (result && result[0]) {
            const installObjectFromApi = result[0];
            addInstall(installObjectFromApi);
            dispatchToast({
              type: "success",
              message: install.name + ` ${LABELS.singular} added`,
            });
            setPreviewInstall(undefined);
          }
        } catch (error) {
          handleCreateError(error, ContentLimitModal, "lesson", "lessons");
        }
      } else {
        onRenameOpen(newInstall);
      }
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "There's been an error. Please try again.",
      });
    }
  };

  const getCategoryName = (install: InstallModel) => {
    return globalCategoryNames && install.categoryTagId
      ? globalCategoryNames.get(install.categoryTagId)
      : "";
  };

  useEffect(() => {
    let isCancelled = false;
    const loadGlobalTags = async () => {
      const fetchedTags = await APIService.TAG.GET_TAGS_FOR_TEAM(null);
      if (fetchedTags && !isCancelled) {
        const fetchedInstallTags = fetchedTags.filter(
          (tag: TagModel) => tag.type === TagTypeEnum.CUSTOM_QUIZ_CATEGORY
        );
        fetchedInstallTags.unshift(defaultTag);
        setGlobalCategories(fetchedInstallTags);
        const fetchedInstallNames: Map<string, string> = new Map<
          string,
          string
        >();
        fetchedTags.forEach((tag) => {
          const id: string = tag.id ? tag.id : "";
          fetchedInstallNames.set(id, tag.name);
        });
        setGlobalCategoryNames(fetchedInstallNames);
      }
    };
    loadGlobalTags();

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (sortedInstalls) {
      const filters: InstallFiltersModel = {
        searchText: searchText,
        category: selectedTab,
        positionGroups: positionGroups,
      };
      const nextFilteredInstalls = filterInstalls(filters, sortedInstalls);
      setFilteredInstalls(nextFilteredInstalls);
    }
  }, [sortedInstalls]);

  useEffect(() => {
    if (!selectedTab && globalCategories) {
      setSelectedTab(defaultTag);
    }
  }, [globalCategories]);

  useEffect(() => {
    if (!isEmpty(globalInstalls)) {
      if (sortByOption === "Name") {
        setSortedInstalls(sortByName(globalInstalls));
      }
      if (sortByOption === "Most Recent" || !sortByOption) {
        setSortedInstalls(sortByDate(globalInstalls));
      }
    }
  }, [sortByOption, globalInstalls]);

  useEffect(() => {
    const filters: InstallFiltersModel = {
      searchText: searchText,
      category: selectedTab,
      positionGroups: positionGroups,
    };
    const nextFilteredInstalls = filterInstalls(filters, sortedInstalls);

    setFilteredInstalls(nextFilteredInstalls);
  }, [positionGroups, searchText, selectedTab]);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        close();
        setClosing(false);
      }, 350);
    }
  }, [closing]);

  const categoryTag = (category: TagModel) => {
    return (
      <div
        key={category.id}
        className={`
        ${styles.tab}
        ${selectedTab.id === category.id ? styles.selected : ""}
      `}
        onClick={() => {
          setSelectedTab(category);
        }}
      >
        {category.name}
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className={styles.modalHeader}>
          Team Nation {LABELS.multiple_capitalized} Library{" "}
          <span className={styles.closeIcon} onClick={() => closeModal()}>
            <img src={CloseIcon} />
          </span>
        </div>
        {!previewInstall ? (
          <div className={styles.importInstalls}>
            <div className={styles.modalSidebar}>
              {globalCategories.map((category: TagModel) =>
                category.name.length < tagNameMaxLen ? (
                  categoryTag(category)
                ) : (
                  <Tooltip tip={category.name}>{categoryTag(category)}</Tooltip>
                )
              )}
            </div>
            <div style={{ overflow: "hidden" }}>
              <div className={styles.toolbar}>
                <Toolbar>
                  <div className={styles.filterBar}>
                    <div className={styles.searchHeight}>
                      <CharacterInput
                        placeholder="Search"
                        id="search"
                        icon={search}
                        value={searchText}
                        maxLength={255}
                        onChange={(e) => {
                          setSearchText(e.currentTarget.value);
                        }}
                      />
                    </div>
                    <div className={styles.select}>
                      <div className={styles.multi}>
                        <MultiSelect
                          placeholder="Position Groups: All"
                          options={positionGroupOptions}
                          onChange={(value) => {
                            setPositionGroups(
                              (value.currentTarget as any).selectedList
                            );
                          }}
                        />
                      </div>
                      <SingleSelect
                        options={sortByOptions}
                        placeholder="Sort by: Most Recent"
                        onChange={(e) =>
                          setSortByOption(e.currentTarget.value as any)
                        }
                      />
                    </div>
                  </div>
                </Toolbar>
              </div>
              <div>
                <legend className={`tableHeader ${styles.installTableHeader}`}>
                  <span>{LABELS.singular_capitalized}</span>
                  <span>Presented By</span>
                  <span>Category</span>
                </legend>
                {globalInstalls && globalInstalls.length ? (
                  filteredInstalls.length ? (
                    <ul className={styles.installsList}>
                      {filteredInstalls.map((install) => {
                        return (
                          <li key={install.id}>
                            <ImportInstallItem
                              install={install}
                              categories={globalCategoryNames}
                              onPreviewInstallCallback={setPreviewInstall}
                              onAddInstallCallback={addGlobalInstall}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className={styles.emptyList}>
                      <div>0 {LABELS.multiple} matching applied filters.</div>
                    </div>
                  )
                ) : (
                  <ul className={styles.installsList}>
                    <li key="1" className={styles.loadInstall}></li>
                    <li key="2" className={styles.loadInstall}></li>
                    <li key="3" className={styles.loadInstall}></li>
                    <li key="4" className={styles.loadInstall}></li>
                    <li key="5" className={styles.loadInstall}></li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.previewInstall}>
            <div className={styles.previewHeader}>
              <div className={styles.leftHeader}>
                {justPreviewInstall !== undefined ? (
                  <span className={styles.imageButterPlaceholder}></span>
                ) : (
                  <img
                    onClick={() => {
                      setPreviewInstall(undefined);
                    }}
                    src={ArrowLeftIcon}
                    role="button"
                    className={styles.imageButton}
                  />
                )}
                <div>
                  <div className={styles.installDetails}>
                    {getCategoryName(previewInstall)}
                  </div>
                  <div className={styles.installTitle}>
                    {previewInstall.name}
                  </div>
                </div>
                <PositionsList
                  positions={previewInstall.showInstallToPositions}
                  theme="light"
                  short={previewInstall.showInstallToPositions.length > 20}
                />
              </div>
              <div>
                <Button
                  size={"small"}
                  onClick={() => addGlobalInstall(previewInstall)}
                >{`Add ${LABELS.singular_capitalized}`}</Button>
              </div>
            </div>
            <div className={styles.previewDetails}>
              <div className={styles.previewSlides}>
                {previewInstall.slides &&
                  previewInstall.slides.length > 0 &&
                  previewInstall.slides.map((slide, slideIndex) => (
                    <div className={styles.previewSlide} key={slideIndex}>
                      <InstallSlideViewOnly
                        slideIndex={slideIndex}
                        slide={slide}
                      />
                    </div>
                  ))}
              </div>
              <div className={styles.previewDevice}>
                <PhonePreview containerClass={styles.previewDevice}>
                  <InstallPreview slides={previewInstall.slides} />
                </PhonePreview>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportInstalls;
