import { useContext, useEffect, useState } from "react";

import styles from "./Header.module.scss";
import Nav, { NavLinks } from "../Nav/Nav";
import teamNationLogo from "../../resources/images/TeamNation.svg";
import AccountWidget from "./AccountWidget";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import Button from "../Button/Button";
import PercentageBar from "../PercentageBar/PercentageBar";
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip/Tooltip";
import { TeamModel } from "../../generated/from-api/models/team.model";
import CheckmarkIcon from "../../resources/icons/CheckmarkIcon";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { LicenseQuotaService } from "../../shared/shared-with-mobile/api-client/services/license-quota.service";
import { LicenseUsageModel } from "../../generated/from-api/models/license-usage.model";
import HamburgerIcon from "../../resources/icons/HamburgerIcon";
import CloseIcon from "../../resources/icons/CloseIcon";
import FreeTrialBanner from "../Banner/FreeTrialBanner/FreeTrialBanner";
import { LicenseTiersContext } from "../../shared/shared-with-mobile/providers/licenseTiers";
import { LicensesContext } from "../../shared/shared-with-mobile/providers/licenses.provider";
import { LicenseTierModel } from "../../generated/from-api/models/license-tier.model";
import { LICENSE_TIERS } from "../../generated/from-api/api-constants/license-tier-constants";

interface Props {
  handleLogout: () => void;
}

const Header: React.FC<Props> = ({ handleLogout }) => {
  const { currentLicenses } = useContext(LicensesContext);
  const { licenseTiers } = useContext(LicenseTiersContext);
  const { userProfile } = useContext(UserContext);
  const { teams, switchTeam, currentTeam, licenseForCurrentTeam } = useContext(
    TeamContext
  );

  const [licenseTier, setLicenseTier] = useState<
    LicenseTierModel | undefined
  >();
  const [licenseUsages, setLicenseUsages] = useState<
    Record<string, LicenseUsageModel>
  >({});

  const [showMobileNav, setShowMobileNav] = useState(false);
  const isAdmin = userProfile?.role === "ADMIN";

  useEffect(() => {
    const root = document.documentElement;
    if (licenseForCurrentTeam && licenseTiers && currentLicenses) {
      const licenseForCurrentTeamIsOwnedByCurrentUser = currentLicenses
        .map((lic) => lic.id)
        .includes(licenseForCurrentTeam.id);

      const licenseTier = licenseTiers.filter((tier) => {
        return tier.id == licenseForCurrentTeam?.licenseTierId;
      })[0] as LicenseTierModel;

      if (
        ((currentLicenses && currentLicenses.length === 0) ||
          licenseTier?.tier === LICENSE_TIERS.FREE.name ||
          licenseTier?.tier === LICENSE_TIERS.TRIAL.name ||
          licenseTier?.tier === LICENSE_TIERS.FREE2023.name ||
          licenseTier?.tier === LICENSE_TIERS.BASIC2023.name ||
          licenseTier?.tier === LICENSE_TIERS.PREMIER.name) &&
        licenseForCurrentTeamIsOwnedByCurrentUser &&
        !isAdmin
      ) {
        root?.style.setProperty("--app-trial-banner-height", "45px");
      } else {
        root?.style.setProperty("--app-trial-banner-height", "0px");
      }
      setLicenseTier(licenseTier);
    }
  }, [licenseForCurrentTeam, licenseTiers, currentLicenses]);

  useEffect(() => {
    const fetchLicenseQuotaUsage = async () => {
      const nextLicenseUsages: Record<string, LicenseUsageModel> = {};
      const arrayOfTeams = Array.from(teams.values());
      const promises = arrayOfTeams.map((team) => {
        return LicenseQuotaService.GET_USAGE_FOR_TEAM(team.id as string);
      });

      const responses = await Promise.all(promises);

      for (const usage of responses) {
        if (usage?.teamId) {
          nextLicenseUsages[usage.teamId] = usage;
        }
      }

      setLicenseUsages(nextLicenseUsages);
    };

    fetchLicenseQuotaUsage();
  }, [teams]);

  const renderTeamSetupWidget = (team: TeamModel) => {
    if (!team.id) {
      return <div></div>;
    }

    const usage = licenseUsages[team.id];

    if (!usage) {
      return <div></div>;
    }

    const teamSetupSteps = [
      {
        completed: true,
        label: "Create Team",
        link: undefined,
      },
      {
        completed: usage.numberOfCoaches >= 1 && usage.numberOfPlayers >= 1,
        label: "Invite Roster",
        link: "/roster",
      },
      {
        completed: usage.numberOfPlays >= 4,
        label: "Add 4 Plays",
        link: "/playbook",
      },
      {
        completed: usage.numberOfCustomQuizzes >= 3,
        label: "Add 3 Flashcards",
        link: "/flashcards",
      },
      {
        completed: usage.numberOfInstalls >= 3,
        label: "Add 3 Lessons",
        link: "/lessons",
      },
    ];

    const numStepsCompleted = teamSetupSteps.filter((step) => step.completed)
      .length;

    return (
      <div>
        <span className={styles.TeamListingSingleDetailsSmallHeader}>
          Team Setup
        </span>
        <Tooltip
          tip={
            <ol className={styles.TeamListingSingleDetailsTooltip}>
              {teamSetupSteps.map((step, stepIndex) => {
                return (
                  <li
                    key={`step-index-${stepIndex}-${team.id}`}
                    className={styles.TeamListingSingleDetailsTooltipLine}
                  >
                    <span
                      className={`${
                        styles.TeamListingSingleDetailsTooltipBullet
                      } ${step.completed ? "active" : ""}`}
                    >
                      <CheckmarkIcon />
                    </span>
                    {step.link && !step.completed ? (
                      <Link
                        onClick={() => {
                          if (team.id) {
                            switchTeam(team.id);
                          }
                        }}
                        to={step.link}
                      >
                        {step.label}
                      </Link>
                    ) : (
                      <>{step.label}</>
                    )}
                  </li>
                );
              })}
            </ol>
          }
          clickTip={true}
          placement={"bottom"}
          className={styles.TeamListingSingleDetailsSetup}
        >
          <PercentageBar
            type="segmented"
            complete={numStepsCompleted}
            total={5}
          />
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      <div
        className={`${styles.mobileNav} ${
          showMobileNav ? styles.showMobileNav : styles.hideMobileNav
        }`}
      >
        <ul>
          <li>
            <span>Close</span>{" "}
            <Button
              theme="transparent"
              icon={<CloseIcon />}
              onClick={() => setShowMobileNav(!showMobileNav)}
            />
          </li>
          <NavLinks />
        </ul>
      </div>
      <div className={styles.header}>
        <span className={styles.mobileNavHamburger}>
          <Button
            theme="transparent"
            icon={<HamburgerIcon />}
            onClick={() => setShowMobileNav(!showMobileNav)}
          />
        </span>
        <img className={styles.logo} src={teamNationLogo} alt="Team Nation" />
        <Nav />
        <Button
          className={`${styles.helpLink} ${isAdmin && styles.hidden}`}
          type={"external link"}
          theme={"secondary"}
          size={"small"}
          externalLinkOptions={{
            href: "https://knowledge.teamnationsports.com",
          }}
        >
          Help
        </Button>
        {currentTeam && renderTeamSetupWidget(currentTeam)}
        <AccountWidget handleLogout={handleLogout} />
      </div>
      {(licenseTier?.tier === LICENSE_TIERS.FREE.name ||
        licenseTier?.tier === LICENSE_TIERS.TRIAL.name ||
        licenseTier?.tier === LICENSE_TIERS.FREE2023.name ||
        licenseTier?.tier === LICENSE_TIERS.BASIC2023.name ||
        licenseTier?.tier === LICENSE_TIERS.PREMIER.name) &&
        !isAdmin && (
          <>
            <FreeTrialBanner />
          </>
        )}
    </>
  );
};

export default Header;
