import React from "react";
import styles from "./ErrorDisplay.module.scss";
import search from "../../resources/images/search.svg";

interface ErrorDisplayProps {
  body1Message?: string;
  onClickCallback: () => void;
  resetFilterBoolean?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ErrorDisplay = ({
  body1Message,
  onClickCallback,
  resetFilterBoolean,
}: ErrorDisplayProps): JSX.Element => {
  return (
    <div className={styles.errorDisplay}>
      <img src={search} className={styles.icon} />
      <p className={styles.typography}>There were no results found.</p>
      <span>
        <span
          onClick={() => {
            onClickCallback();
            if (resetFilterBoolean) {
              setTimeout(() => {
                resetFilterBoolean(false);
              }, 50);
            }
          }}
          className={styles.link}
        >
          Clear the Filters
        </span>{" "}
        <span className={styles.typography}>to see more {body1Message}.</span>
      </span>
    </div>
  );
};

export default ErrorDisplay;
