import * as React from "react";

const ChevronUpIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path d="M0.94 5.46979L4 2.41645L7.06 5.46979L8 4.52979L4 0.529785L0 4.52979L0.94 5.46979Z" />
  </svg>
);

export default ChevronUpIcon;
