import React from "react";
import { Link } from "react-router-dom";

// import barChart from "../../../resources/images/barChart.svg";
import kebabMenu from "../../../resources/images/kebabMenu.svg";

import styles from "../Installs.module.scss";

import PositionsList from "../../../components/PositionsList/PositionsList";
import PercentageBar from "../../../components/PercentageBar/PercentageBar";
import PlayerParticipationList from "./PlayerParticipationList";
import PlayerScoresList from "./PlayerScoresList";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { useContext } from "react";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import DeleteModal from "./DeleteModal";
import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import { InstallModel } from "../../../generated/from-api/models/install.model";
import { InstallStatisticModel } from "../../../generated/from-api/models/install-statistic.model";
import { userRoles } from "../../../shared/shared-with-mobile/constants";
import { useHistory } from "react-router-dom";
import { InstallsContext } from "../../../shared/shared-with-mobile/providers/installs.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import { INSTALL_SETTINGS } from "../../../utils/web-only-constants";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import Button from "../../../components/Button/Button";
import { UserProfileModel } from "../../../generated/from-api/models/user-profile.model";

interface InstallsListItemProps {
  install: InstallModel;
  stats: InstallStatisticModel[];
  showGuidingFlow?: boolean;
}

const InstallsListItem: React.FC<InstallsListItemProps> = ({
  install,
  stats,
  showGuidingFlow = false,
}) => {
  const history = useHistory();
  const { dispatchToast, dispatchModal } = useContext(UIContext);
  const { userProfile, updateProfile } = useContext(UserContext);
  const {
    addInstall,
    updateInstall,
    areInstallTooltipsTemporarilyDismissed,
    setAreInstallTooltipsTemporarilyDismissed,
  } = useContext(InstallsContext);
  const { customQuizCategoryTags } = useContext(TagsContext);
  const { currentPlayersAsArray } = useContext(PlayersContext);
  const { URLS, LABELS } = INSTALL_SETTINGS;

  const isAdmin = userProfile?.role === userRoles.ADMIN;

  // Need the route for links
  const installRouteName =
    userProfile?.role === userRoles.ADMIN ? URLS.admin : URLS.generic;

  const dismissGuideFlowForLessons = async () => {
    markStepAsVisited();
    setAreInstallTooltipsTemporarilyDismissed(true);
  };

  const markStepAsVisited = async () => {
    if (!userProfile) {
      return;
    }

    const updatedUserProfile: UserProfileModel = {
      ...userProfile,
      tooltipsInfo: {
        ...userProfile.tooltipsInfo,
        lessonsToolTip: 2,
      },
    } as UserProfileModel;

    const updated = await APIService.USER_PROFILE.PUT(
      userProfile.id,
      updatedUserProfile
    );

    if (updated) {
      updateProfile(updated);
    }
  };

  // This will open the modal to delete the lesson, the delete itself is done in the modal
  const deleteInstall = () => {
    dispatchModal({
      title: `Delete ${LABELS.multiple_capitalized}`,
      open: true,
      size: "medium",
      className: styles.installDeleteModal,
      body: <DeleteModal install={install} />,
    });
  };

  // Edit just redirects to the URL for that entry
  const editInstall = () => {
    history.push(`/${installRouteName}/${install.id}`);
  };

  // Publish and Archive are effectively the same, just changing a different value to true
  const publishInstall = async () => {
    const publishedInstall = Object.assign({}, install);
    publishedInstall.published = true;
    const response = await APIService.INSTALL.PUT(publishedInstall);
    if (response) {
      updateInstall(response);
      dispatchToast({
        type: "success",
        message: `${LABELS.singular_capitalized} Published.`,
      });
    }
  };
  const archiveInstall = async () => {
    install.archived = true;
    const response = await APIService.INSTALL.PUT(install);
    if (response) {
      updateInstall(response);
      dispatchToast({
        type: "success",
        message: `${LABELS.singular_capitalized} Archived.`,
      });
    }
  };

  // Duplicate adds "Copy" to the name and create a new lesson.
  const duplicateInstall = async () => {
    const newInstall = Object.assign({}, install);
    newInstall.name += " Copy";
    delete newInstall.id;
    const response = await APIService.INSTALL.POST([newInstall]);
    if (response && response[0]) {
      const installObjectFromApi = response[0];
      addInstall(installObjectFromApi);
      dispatchToast({
        type: "success",
        message: `${LABELS.singular_capitalized} Duplicated.`,
      });
    }
  };

  const currentPlayersToDisplay = currentPlayersAsArray.filter(
    (player: PlayerModel) => !player.hideOnLeaderBoard
  );

  // We need to prepare some summary stats
  const preparedStats = {
    avgScore: 0,
    finished: 0,
    assigned: currentPlayersToDisplay.length,
  };
  stats.forEach((statPoint: InstallStatisticModel) => {
    preparedStats.avgScore += parseFloat("" + statPoint.score * 100);
    preparedStats.finished += statPoint.completedAt === null ? 0 : 1;
  });
  preparedStats.avgScore =
    preparedStats.avgScore / stats.length || preparedStats.avgScore;

  // The list items itself.
  return (
    <li
      key={`installs-list-item-${install.id}`}
      className={`card
        ${styles.installsPageListItem}
        ${!install.published ? styles.installsPageListDraftItem : ""}
        ${userProfile?.role === "ADMIN" ? "simplified-admin" : ""}
      `}
    >
      <span>
        {!isAdmin &&
        showGuidingFlow &&
        !areInstallTooltipsTemporarilyDismissed &&
        userProfile?.tooltipsInfo?.lessonsToolTip === 1 ? (
          <Tooltip
            tip={
              <p>
                Lessons have been pre-installed by Team Nation from our library.
                You may edit, unpublish, or delete them at any time.
                <br />
                <span className={`${styles.tooltipStep1Footer}`}>
                  <Button
                    theme="transparent"
                    size="x-small"
                    className={`${styles.buttonDismiss}`}
                    onClick={() => dismissGuideFlowForLessons()}
                  >
                    Dismiss
                  </Button>
                  <Button size="x-small" onClick={() => markStepAsVisited()}>
                    Next
                  </Button>
                </span>
              </p>
            }
            placement="top-start"
            defaultOpen={true}
            showPermanent={true}
          >
            <Link to={`/${installRouteName}/${install.id}`}>
              <div className={"title"}>
                {(!install.published ? "[DRAFT] " : "") +
                  (install.name || `Unnamed ${LABELS.singular_capitalized}`)}
              </div>
              <PositionsList positions={install.showInstallToPositions} />
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/${installRouteName}/${install.id}`}>
            <div className={"title"}>
              {(!install.published ? "[DRAFT] " : "") +
                (install.name || `Unnamed ${LABELS.singular_capitalized}`)}
            </div>
            <PositionsList positions={install.showInstallToPositions} />
          </Link>
        )}
      </span>

      <span className={"category"}>
        {(install.categoryTagId &&
          customQuizCategoryTags.find((tag) => tag.id === install.categoryTagId)
            ?.name) || <em>Uncategorized</em>}
      </span>

      {userProfile?.role !== "ADMIN" ? (
        <>
          {preparedStats.avgScore === 0 ? (
            <span className={`${styles.installsPageListItemNoAvgScore}`}>
              —
            </span>
          ) : (
            <Tooltip
              tip={<PlayerScoresList stats={stats} />}
              placement="left"
              clickTip
            >
              <span className={`${styles.installsPageListItemAvgScore}`}>
                {"" +
                  parseFloat("" + preparedStats.avgScore)
                    .toFixed(2)
                    .replace(/\.?0+$/, "")}
                %
              </span>
            </Tooltip>
          )}

          <span>
            {install.published && (
              <Tooltip
                tip={<PlayerParticipationList stats={stats} />}
                placement="left"
                clickTip
              >
                {preparedStats.assigned === 0 ? null : (
                  <PercentageBar
                    positive={preparedStats.finished}
                    total={preparedStats.assigned}
                  />
                )}
              </Tooltip>
            )}
          </span>
        </>
      ) : null}

      <span>
        {new Date(install.created + "").toLocaleString("en-US", {
          timeZone: "UTC",
          month: "short",
          day: "numeric",
        })}
      </span>

      {userProfile?.role !== "ADMIN" ? (
        <>
          <span>
            {install.dueDate === null ? (
              <>—</>
            ) : (
              new Date(install.dueDate + "").toLocaleString("en-US", {
                timeZone: "UTC",
                month: "short",
                day: "numeric",
              })
            )}
          </span>
        </>
      ) : null}

      <span className={`${styles.installsPageListItemActions}`}>
        {/* {userProfile?.role !== "ADMIN" ? (
          <img
            className={`${styles.installsPageListItemActionButton}`}
            src={barChart}
            onClick={placeholderAction}
          />
        ) : null} */}
        <Tooltip
          tip={
            <ol className={styles.installsPageListItemActionOverflow}>
              {/* {userProfile?.role !== "ADMIN" ? (
                <li
                  key={`install-popup-seeTeamData`}
                  onClick={placeholderAction}
                >
                  See Team Data
                </li>
              ) : null} */}
              <li key={`install-popup-edit`} onClick={editInstall}>
                Edit
              </li>
              {!install.published && (
                <li key={`install-popup-publish`} onClick={publishInstall}>
                  Publish
                </li>
              )}
              <li key={`install-popup-archive`} onClick={archiveInstall}>
                Archive
              </li>
              <li key={`install-popup-duplicate`} onClick={duplicateInstall}>
                Duplicate
              </li>
              <li key={`install-popup-delete`} onClick={deleteInstall}>
                Delete
              </li>
            </ol>
          }
          clickTip
          noArrow
          noPadding
          color={"dark"}
          placement={"bottom"}
        >
          <img
            className={`${styles.installsPageListItemActionButton}`}
            src={kebabMenu}
          />
        </Tooltip>
      </span>
    </li>
  );
};

export default InstallsListItem;
