import React, { useContext } from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import PositionsList from "../../../components/PositionsList/PositionsList";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import PlayerScoresList from "../../Installs/install-components/PlayerScoresList";
import PlayerParticipationList from "../../Installs/install-components/PlayerParticipationList";
import PercentageBar from "../../../components/PercentageBar/PercentageBar";

import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";

import { CustomQuizModel } from "../../../generated/from-api/models/custom-quiz.model";
import { CustomQuizStatisticModel } from "../../../generated/from-api/models/custom-quiz-statistic.model";

import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import { QuizzesContext } from "../../../shared/shared-with-mobile/providers/quizzes.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { MediaContext } from "../../../shared/shared-with-mobile/providers/media.provider";

import kebabMenu from "../../../resources/images/kebabMenu.svg";

// This view is mostly a clone of the install one, include it's CSS and the Installs one.
import quizzesStyles from "../Quizzes.module.scss";
import installStyles from "../../Installs/Installs.module.scss";
import { userRoles } from "../../../shared/shared-with-mobile/constants";
import { Link } from "react-router-dom";
import validateQuizAndGetErrorMessage from "../../../utils/quizValidator.utils";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import Button from "../../../components/Button/Button";
import { UserProfileModel } from "../../../generated/from-api/models/user-profile.model";

const styles = { ...installStyles, ...quizzesStyles };

interface QuizListItemProps {
  quiz: CustomQuizModel;
  stats: CustomQuizStatisticModel[];
  editAction?: any;
  showGuidingFlow?: boolean;
}

const QuizListItem: React.FC<QuizListItemProps> = ({
  quiz,
  stats,
  editAction,
  showGuidingFlow = false,
}) => {
  const { userProfile, updateProfile } = useContext(UserContext);
  const { customQuizCategoryTags } = useContext(TagsContext);
  const {
    addQuiz,
    updateQuiz,
    removeQuiz,
    areQuizTooltipsTemporarilyDismissed,
    setAreQuizTooltipsTemporarilyDismissed,
  } = useContext(QuizzesContext);
  const { dispatchModal, dispatchToast } = useContext(UIContext);
  const { mediaItemsMap } = useContext(MediaContext);
  const { currentPlayersAsArray } = useContext(PlayersContext);
  const quizRouteName =
    userProfile?.role === userRoles.ADMIN ? "global-flashcards" : "flashcards";

  const currentPlayersToDisplay = currentPlayersAsArray.filter(
    (player: PlayerModel) => !player.hideOnLeaderBoard
  );

  const isAdmin = userProfile?.role === userRoles.ADMIN;

  const preparedStats = {
    avgScore: 0,
    finished: 0,
    assigned: currentPlayersToDisplay.length,
  };
  stats.forEach((statPoint: CustomQuizStatisticModel) => {
    preparedStats.avgScore += parseFloat("" + statPoint.score);
    preparedStats.finished += statPoint.completedAt === null ? 0 : 1;
  });
  preparedStats.avgScore =
    preparedStats.avgScore / stats.length || preparedStats.avgScore;

  const editQuiz = () => {
    if (editAction !== undefined) {
      editAction();
    }
  };

  const publishQuiz = async () => {
    const published = Object.assign({}, quiz);
    const isQuizValid = validateQuizAndGetErrorMessage(
      published,
      Object.values(mediaItemsMap)
    );
    if (!isQuizValid.isValid) {
      dispatchToast({
        type: "error",
        message: `${isQuizValid.errorMessage}.`,
      });
      return;
    }
    published.published = true;
    try {
      const response = await APIService.CUSTOM_QUIZ.PUT(published);
      updateQuiz(response as CustomQuizModel);
      dispatchToast({
        type: "success",
        message: "Quiz published.",
      });
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "An error occurred while publishing the quiz.",
      });
    }
  };

  const archiveQuiz = async () => {
    const archived = Object.assign({}, quiz);
    archived.archived = true;
    try {
      const response = await APIService.CUSTOM_QUIZ.PUT(archived);
      updateQuiz(response as CustomQuizModel);
      dispatchToast({
        type: "success",
        message: "Quiz archived.",
      });
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "An error occurred while archiving the quiz.",
      });
    }
  };

  const duplicateQuiz = async () => {
    const duplicated = Object.assign({}, quiz);
    duplicated.name += " Copy";
    try {
      const response = await APIService.CUSTOM_QUIZ.POST([duplicated]);
      if (response === undefined) {
        throw new Error();
      }
      addQuiz(response[0] as CustomQuizModel);
      dispatchToast({
        type: "success",
        message: "Quiz duplicated.",
      });
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "An error occurred while duplicating the quiz.",
      });
    }
  };

  const dismissGuideFlowForQuizzes = async (userProfile: UserProfileModel) => {
    markStepAsVisited(userProfile);
    setAreQuizTooltipsTemporarilyDismissed(true);
  };

  const markStepAsVisited = async (userProfile: UserProfileModel) => {
    const updatedUserProfile: UserProfileModel = {
      ...userProfile,
      tooltipsInfo: { ...userProfile?.tooltipsInfo, quizzesToolTip: 2 },
    } as UserProfileModel;

    const updated = await APIService.USER_PROFILE.PUT(
      userProfile.id,
      updatedUserProfile
    );

    if (updated) {
      updateProfile(updated);
    }
  };

  const deleteQuiz = () => {
    dispatchModal({
      title: "Delete Flashcard Game",
      open: true,
      size: "medium",
      className: styles.installDeleteModal,
      body: (
        <ConfirmationModal
          actionName="delete"
          itemName="game"
          actionCallback={async () => {
            try {
              const response = await APIService.CUSTOM_QUIZ.DELETE(
                quiz.id as string
              );
              if (response === undefined) {
                throw new Error();
              }
              removeQuiz(quiz.id as string);
              dispatchToast({
                type: "success",
                message: "Game deleted.",
              });
            } catch (e) {
              dispatchToast({
                type: "error",
                message: "An error occurred while deleting this game.",
              });
            }
          }}
        />
      ),
    });
  };

  // The list items itself.
  return (
    <li
      key={`quiz-list-item-${quiz.id}`}
      className={`card
        ${styles.installsPageListItem}
        ${!quiz.published ? styles.installsPageListDraftItem : ""}
        ${userProfile?.role === "ADMIN" ? "simplified-admin" : ""}
        `}
    >
      <span>
        {!isAdmin &&
        showGuidingFlow &&
        userProfile?.tooltipsInfo?.quizzesToolTip === 1 &&
        !areQuizTooltipsTemporarilyDismissed ? (
          <Tooltip
            tip={
              <p>
                Flashcard Games have been pre-installed by Team Nation from our
                library. You may edit, unpublish, or delete them at any time.
                <br />
                <span className={`${styles.tooltipStep1Footer}`}>
                  <Button
                    theme="transparent"
                    size="x-small"
                    className={`${styles.buttonDismiss}`}
                    onClick={() => dismissGuideFlowForQuizzes(userProfile)}
                  >
                    Dismiss
                  </Button>
                  <Button
                    size="x-small"
                    onClick={() => markStepAsVisited(userProfile)}
                  >
                    Next
                  </Button>
                </span>
              </p>
            }
            placement="top-start"
            defaultOpen={true}
            showPermanent={true}
          >
            <Link to={`/${quizRouteName}/${quiz.id}`}>
              <div className={"title"}>
                {(!quiz.published ? "[DRAFT] " : "") + quiz.name}
              </div>
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/${quizRouteName}/${quiz.id}`}>
            <div className={"title"}>
              {(!quiz.published ? "[DRAFT] " : "") + quiz.name}
            </div>
          </Link>
        )}
        <PositionsList positions={quiz.showQuizToPositions} />
      </span>

      <span className={"category"}>
        {(quiz.categoryTagId &&
          customQuizCategoryTags.find((tag) => tag.id === quiz.categoryTagId)
            ?.name) || <em>Uncategorized</em>}
      </span>

      {userProfile?.role !== "ADMIN" ? (
        <>
          {preparedStats.avgScore === 0 ? (
            <span className={`${styles.installsPageListItemNoAvgScore}`}>
              —
            </span>
          ) : (
            <Tooltip
              tip={<PlayerScoresList stats={stats} />}
              placement="left"
              clickTip
            >
              <span className={`${styles.installsPageListItemAvgScore}`}>
                {"" +
                  parseFloat("" + preparedStats.avgScore * 100)
                    .toFixed(2)
                    .replace(/\.?0+$/, "")}
                %
              </span>
            </Tooltip>
          )}

          <span>
            {quiz.published && (
              <Tooltip
                tip={<PlayerParticipationList stats={stats as any} />}
                placement="left"
                clickTip
              >
                {preparedStats.assigned === 0 ? (
                  <>—</>
                ) : (
                  <PercentageBar
                    positive={preparedStats.finished}
                    total={preparedStats.assigned}
                  />
                )}
              </Tooltip>
            )}
          </span>
        </>
      ) : null}

      <span>
        {new Date(quiz.created + "").toLocaleString("en-US", {
          timeZone: "UTC",
          month: "short",
          day: "numeric",
        })}
      </span>

      {userProfile?.role !== "ADMIN" ? ( // This feature will come later
        <>
          <span>{quiz.dueDate === null ? <>—</> : <>Due Date?</>}</span>
        </>
      ) : null}

      <span className={`${styles.installsPageListItemActions}`}>
        <Tooltip
          tip={
            <ol className={styles.installsPageListItemActionOverflow}>
              <li key={`install-popup-edit`} onClick={editQuiz}>
                Edit
              </li>
              {!quiz.published && (
                <li key={`install-popup-publish`} onClick={publishQuiz}>
                  Publish
                </li>
              )}
              <li key={`install-popup-archive`} onClick={archiveQuiz}>
                Archive
              </li>
              <li key={`install-popup-duplicate`} onClick={duplicateQuiz}>
                Duplicate
              </li>
              <li key={`install-popup-delete`} onClick={deleteQuiz}>
                Delete
              </li>
            </ol>
          }
          clickTip
          noArrow
          noPadding
          color={"dark"}
          placement={"bottom"}
        >
          <img
            className={`${styles.installsPageListItemActionButton}`}
            src={kebabMenu}
          />
        </Tooltip>
      </span>
    </li>
  );
};

export default QuizListItem;
