import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { LicenseTierModel } from "../../../../generated/from-api/models/license-tier.model";
import { API_BASE_URL } from "../../constants";

export const LicenseTierService = {
  /**
   * Use to retrieve a list of the license tiers, only works for admins
   */
  LIST: async function (): Promise<LicenseTierModel[]> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.LICENSE_TIER}`)).data;
  },
};
