import React, { useContext, useEffect, useState } from "react";
import styles from "../AccountSetupModal/AccountSetupModal.module.scss";
import CharacterInput from "../../../../components/Input/CharacterInput";
import { INPUT_TYPES } from "../../../../utils/web-only-constants";
import { invitationTypes } from "../../../../shared/shared-with-mobile/constants";
import Button from "../../../../components/Button/Button";
import CheckmarkIcon from "../../../../resources/icons/CheckmarkIcon";
import { LicensesContext } from "../../../../shared/shared-with-mobile/providers/licenses.provider";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import LicenseCard from "../../../../components/LicenseCard/LicenseCard";
import TeamDetails from "../../../../components/Team/TeamDetails/TeamDetails";
import TeamPlayers from "../../../../components/Team/TeamPlayers/TeamPlayers";
import TeamCoaches from "../../../../components/Team/TeamCoaches/TeamCoaches";
import CircleCheckmarkIcon from "../../../../resources/icons/CircleCheckmarkIcon";
import TeamCard from "../../../../components/Team/TeamCard/TeamCard";
import { TeamContext } from "../../../../shared/shared-with-mobile/providers/team.provider";
import { APIService } from "../../../../shared/shared-with-mobile/api-client/api.service";
import { TeamLevelEnum } from "../../../../generated/from-api/models/enums/team-level.enum";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import WelcomeLogo from "../../../../resources/images/license-activation-welcome.png";
import ArrowLeftIcon from "../../../../resources/icons/ArrowLeftIcon";
import SpreadsheetUpload from "../../../../components/SpreadsheetUpload/SpreadsheetUpload";
import CircleInformationIcon from "../../../../resources/icons/CircleInformationIcon";
import { LicenseTierModel } from "../../../../generated/from-api/models/license-tier.model";
import { LicenseTiersContext } from "../../../../shared/shared-with-mobile/providers/licenseTiers";
import { QuizzesContext } from "../../../../shared/shared-with-mobile/providers/quizzes.provider";
import { InstallsContext } from "../../../../shared/shared-with-mobile/providers/installs.provider";
import { DateTime } from "luxon";

export enum OnboardingProgress {
  LicenseWelcome = 1,
  LicenseActivation = 1.1,
  FreeTrial = 1.2,
  LicenseRenewal = 1.3,
  LicenseRenewalSuccess = 1.8,
  LicenseActivationSuccess = 1.9,
  CreateTeam = 2.1,
  InvitePlayers = 2.2,
  InviteCoaches = 2.3,
  AllCreatedTeams = 3,
}

interface Props {
  initialProgress?: OnboardingProgress; // sometimes we want to skip the Welcome step and go right to another step
}

const AccountSetupModal: React.FC<Props> = ({
  initialProgress = OnboardingProgress.LicenseWelcome,
}) => {
  // Global
  const { dispatchToast, dispatchModal } = useContext(UIContext);
  const { setQuizzes } = useContext(QuizzesContext);
  const { setInstalls } = useContext(InstallsContext);

  // Teams Count
  const {
    teams,
    currentTeam,
    licenseForCurrentTeam,
    setLicenseForCurrentTeam,
    addTeams,
    updateTeam,
    updateSuspensionStatus,
  } = useContext(TeamContext);
  const [teamsCount, setTeamsCount] = useState<number>(
    teams ? teams.size + 1 : 1
  );

  // Steps
  const [step, setStep] = useState<number>(initialProgress);
  useEffect(() => {
    if (step === OnboardingProgress.CreateTeam) {
      setTeamsCount(teams ? teams.size + 1 : 1);
    } else {
      setTeamsCount(teams ? teams.size : 1);
    }
  }, [step]);

  // License Activation
  const { currentLicenses, updateLicenses } = useContext(LicensesContext);
  const { licenseTiers } = useContext(LicenseTiersContext);
  const [licenseCode, setLicenseCode] = useState<string>("");
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [licenseTier, setLicenseTier] = useState<
    LicenseTierModel | undefined
  >();

  // Create Teams
  const [availableTeamsCount, setAvailableTeamsCount] = useState(0);
  const [upgradedTeamsCount, setUpgradedTeamsCount] = useState(0);
  const [teamId, setTeamId] = useState<string>("");
  const [teamLogo, setTeamLogo] = useState<string | Blob>();
  const [teamName, setTeamName] = useState<string>("");
  const [teamLevel, setTeamLevel] = useState<string>("");
  const [teamPrimaryColor, setTeamPrimaryColor] = useState<string>("#2867F4");
  const [teamSecondaryColor, setTeamSecondaryColor] = useState<string>(
    "#ffe42a"
  );
  const [teamCity, setTeamCity] = useState<string | null>(null);
  const [teamState, setTeamState] = useState<string | null>(null);
  const [teamPostalCode, setTeamPostalCode] = useState<string>("");

  useEffect(() => {
    if (currentLicenses && currentLicenses.length > 0 && licenseTiers) {
      const licenseTier = licenseTiers.filter((tier) => {
        return tier.id == currentLicenses[0].licenseTierId;
      })[0] as LicenseTierModel;

      setLicenseTier(licenseTier);
    }
  }, [currentLicenses, licenseTiers]);

  const activateLicense = async (licenseCode: string, orderNumber: string) => {
    try {
      const newLicense = await APIService.LICENSE.ACTIVATE_LICENSE(
        licenseCode,
        orderNumber
      );

      if (newLicense) {
        const numUpgradedTeams = newLicense.filter(
          (license) => license.assigned
        ).length;

        const numAvailableTeams = newLicense.length - numUpgradedTeams;

        setUpgradedTeamsCount(numUpgradedTeams);
        setAvailableTeamsCount(numAvailableTeams);
        updateLicenses(newLicense);

        if (currentTeam) {
          const installs = await APIService.INSTALL.LIST(currentTeam?.id);
          setInstalls(installs);
          const quizzes = await APIService.CUSTOM_QUIZ.LIST(currentTeam?.id);
          setQuizzes(quizzes);
        }
      }

      dispatchToast({
        type: newLicense ? "success" : "error",
        message: newLicense
          ? "License Activated"
          : "License Activation Failed. Please try again.",
      });

      setStep(OnboardingProgress.LicenseActivationSuccess);
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "Invalid License Code",
      });
    }
  };

  const renewLicense = async (licenseCode: string, orderNumber: string) => {
    try {
      // technically it could be multiple licenses if they are legacy licenses
      const renewedLicenses = await APIService.LICENSE.RENEW_LICENSE(
        licenseCode,
        orderNumber
      );

      if (renewedLicenses) {
        updateLicenses(renewedLicenses);
        setStep(OnboardingProgress.LicenseRenewalSuccess);
        updateSuspensionStatus(false);
        setLicenseForCurrentTeam(renewedLicenses[0]);
      }

      dispatchToast({
        type: renewedLicenses ? "success" : "error",
        message: renewedLicenses
          ? "License Renewed"
          : "License Renewal Failed. Please try again.",
      });
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "Invalid Renewal Code",
      });
    }
  };

  const teamLogoUpdate = (file: string | Blob) => {
    setTeamLogo(file ? file : undefined);
  };

  const teamNameUpdate = (name: string) => {
    setTeamName(name);
  };

  const teamLevelUpdate = (level: string) => {
    setTeamLevel(level);
  };

  const teamPrimaryColorUpdate = (color: string) => {
    setTeamPrimaryColor(color);
  };

  const teamSecondaryColorUpdate = (color: string) => {
    setTeamSecondaryColor(color);
  };

  const teamCityUpdate = (city: string) => {
    setTeamCity(city);
  };

  const teamStateUpdate = (state: string) => {
    setTeamState(state);
  };

  const teamPostalCodeUpdate = (postalCode: string) => {
    setTeamPostalCode(postalCode);
  };

  const createTeam = async () => {
    if (teamName && teamLevel) {
      try {
        const newTeam = await APIService.TEAM.POST({
          name: teamName.trim(),
          level: teamLevel.toUpperCase() as TeamLevelEnum,
          primaryColor: teamPrimaryColor,
          secondaryColor: teamSecondaryColor,
          city: teamCity ? teamCity.trim() : null,
          state: teamState ? teamState : null,
          postalCode: teamPostalCode ? teamPostalCode.trim() : null,
        });
        if (newTeam && newTeam.id) {
          addTeams([newTeam]);
          setTeamId(newTeam.id ? newTeam.id : "");
          setAvailableTeamsCount(availableTeamsCount - 1);

          if (teamLogo) {
            const newFormData = new FormData();
            newFormData.append("image", teamLogo);
            const newTeamLogo = await APIService.TEAM.POST_UPLOAD_LOGO(
              newTeam.id,
              newFormData
            );
            newTeam.logo = newTeamLogo.url;
            updateTeam(newTeam);
            setTeamLogo("");
            setStep(OnboardingProgress.InvitePlayers);
          } else {
            setStep(OnboardingProgress.InvitePlayers);
          }
        }

        dispatchToast({
          type: newTeam ? "success" : "error",
          message: newTeam
            ? `Team ${newTeam.name} Created`
            : "Team Creation Failed. Please try again.",
        });
      } catch (error) {
        dispatchToast({
          type: "error",
          message: "Team Creation Failed. Please try again.",
        });
      }
    } else {
      dispatchToast({
        type: "error",
        message: "Team Creation Failed. Please try again.",
      });
    }
  };

  const uploadRoster = (
    dataType: invitationTypes,
    templateFilePath?: string
  ) => {
    dispatchModal({
      open: true,
      size: "large",
      className: `${styles.accountSetupModal__SpreadSheetUploadModal}`,
      body: (
        <SpreadsheetUpload
          dataType={dataType}
          teamId={teamId}
          templateFilePath={templateFilePath}
        />
      ),
    });
  };

  const getShopifyProductURL = () => {
    if (
      !licenseForCurrentTeam ||
      step === OnboardingProgress.LicenseActivation
    ) {
      return "https://teamnationsports.com/pricing/";
    }

    const youthURL =
      "https://team-nation-sports.myshopify.com/products/youth-flag-renewal";
    const highSchoolURL =
      "https://team-nation-sports.myshopify.com/products/high-school-renewal";
    const highSchoolPlusURL =
      "https://team-nation-sports.myshopify.com/products/high-school-plus-renewal";
    const collegeURL =
      "https://team-nation-sports.myshopify.com/products/college-renewal";

    const licenseToUrlMap: Record<string, string> = {
      "20000000-0000-0000-0000-000000000000": youthURL, // Bronze (Legacy)
      "20000000-1000-0000-0000-000000000000": youthURL, // Bronze
      "20000000-2000-0000-0000-000000000000": youthURL, // Youth
      "30000000-0000-0000-0000-000000000000": highSchoolURL, // Silver (Legacy)
      "30000000-1000-0000-0000-000000000000": highSchoolURL, // Silver / High School
      "40000000-0000-0000-0000-000000000000": highSchoolPlusURL, // Gold (Legacy)
      "40000000-1000-0000-0000-000000000000": highSchoolPlusURL, // Gold / High School Plus
      "50000000-0000-0000-0000-000000000000": collegeURL, // Platinum (Legacy)
      "50000000-2000-0000-0000-000000000000": collegeURL, // College
    };

    // If everything works as intended, then it should never have to use the default url, it should always find one from the map.
    const productUrl =
      licenseToUrlMap[licenseForCurrentTeam?.licenseTierId] ||
      "https://teamnationsports.com/pricing/";

    return productUrl;
  };

  return (
    <div
      className={`${styles.accountSetupModal} ${
        step >= OnboardingProgress.CreateTeam &&
        step < OnboardingProgress.AllCreatedTeams &&
        styles.accountSetupModal__Tuna
      }`}
    >
      {/* NAVIGATION */}
      {step > OnboardingProgress.LicenseWelcome &&
        step < OnboardingProgress.LicenseActivationSuccess && (
          <div className={styles.accountSetupModal__Navigation}>
            {step !== OnboardingProgress.LicenseRenewal &&
              step !== OnboardingProgress.LicenseRenewalSuccess && (
                <Button
                  theme="transparent"
                  icon={<ArrowLeftIcon />}
                  onClick={() => setStep(OnboardingProgress.LicenseWelcome)}
                />
              )}
          </div>
        )}

      {/* HEADER */}
      {step >= OnboardingProgress.CreateTeam &&
        step < OnboardingProgress.AllCreatedTeams && (
          <div className={styles.accountSetupModal__Header}>
            <span>Create Team {teamsCount}</span>
          </div>
        )}

      {/* TABS */}
      {step >= OnboardingProgress.CreateTeam &&
        step < OnboardingProgress.AllCreatedTeams && (
          <div className={styles.accountSetupModal__Tabs}>
            <button
              className={`${styles.accountSetupModal__TabsButton} ${
                step === OnboardingProgress.CreateTeam &&
                styles.accountSetupModal__TabsButtonActive
              }`}
            >
              <span className={styles.accountSetupModal__TabsButtonNumber}>
                1
              </span>
              <span>Details</span>
            </button>
            <button
              className={`${styles.accountSetupModal__TabsButton} ${
                step === OnboardingProgress.InvitePlayers &&
                styles.accountSetupModal__TabsButtonActive
              }`}
            >
              <span className={styles.accountSetupModal__TabsButtonNumber}>
                2
              </span>
              <span>Players</span>
            </button>
            <button
              className={`${styles.accountSetupModal__TabsButton} ${
                step === OnboardingProgress.InviteCoaches &&
                styles.accountSetupModal__TabsButtonActive
              }`}
            >
              <span className={styles.accountSetupModal__TabsButtonNumber}>
                3
              </span>
              <span>Coaches</span>
            </button>
          </div>
        )}

      {/* BODY */}
      <div className={styles.accountSetupModal__Body}>
        {step === OnboardingProgress.LicenseWelcome && (
          <div className={styles.accountSetupModal__Step_LicenseWelcome}>
            <div className={styles.accountSetupModal__Row}>
              <div className={styles.accountSetupModal__Step_Header}>
                <span className={styles.accountSetupModal__Step_HeaderFirstRow}>
                  Welcome to
                </span>
                <span
                  className={styles.accountSetupModal__Step_HeaderSecondRow}
                >
                  Team Nation
                </span>
              </div>
            </div>
            <div className={styles.accountSetupModal__Row}>
              <div
                className={styles.accountSetupModal__Step_LicenseWelcome_Image}
              >
                <img src={WelcomeLogo} alt="Welcome to Team Nation" />
              </div>
            </div>
            <div className={styles.accountSetupModal__Row}>
              <div
                className={
                  styles.accountSetupModal__Step_LicenseWelcome_Buttons
                }
              >
                <Button
                  className={
                    styles.accountSetupModal__Step_LicenseWelcome_Button
                  }
                  onClick={() => setStep(OnboardingProgress.LicenseActivation)}
                >
                  Activate License
                </Button>
                {/* <Button
                  className={
                    styles.accountSetupModal__Step_LicenseWelcome_Button
                  }
                  onClick={() => setStep(OnboardingProgress.FreeTrial)}
                >
                  Free Trial
                </Button> */}
                <Button
                  className={
                    styles.accountSetupModal__Step_LicenseWelcome_Button
                  }
                  type={"external link"}
                  externalLinkOptions={{
                    href: "https://www.teamnationsports.com/pricing",
                  }}
                >
                  Buy a License
                </Button>
              </div>
            </div>
          </div>
        )}

        {(step === OnboardingProgress.LicenseActivation ||
          step === OnboardingProgress.LicenseRenewal) && (
          <div className={styles.accountSetupModal__Step_LicenseActivation}>
            <div className={styles.accountSetupModal__RowClean}>
              <div className={styles.accountSetupModal__Step_Header}>
                <span
                  className={styles.accountSetupModal__Step_HeaderSecondRow}
                >
                  {step === OnboardingProgress.LicenseActivation
                    ? "Activate License"
                    : "Renew License"}
                </span>
              </div>
            </div>
            <div className={styles.accountSetupModal__RowClean}>
              <div
                className={
                  styles.accountSetupModal__Step_LicenseActivationWrapper
                }
              >
                <div
                  className={
                    styles.accountSetupModal__Step_LicenseActivationInput
                  }
                >
                  <CharacterInput
                    type={INPUT_TYPES.TEXT}
                    placeholder={
                      step === OnboardingProgress.LicenseActivation
                        ? "License Code"
                        : "Renewal Code"
                    }
                    value={licenseCode}
                    onChange={(e) => setLicenseCode(e.target.value)}
                    autofocus
                  />
                  <div
                    className={
                      styles.accountSetupModal__Step_LicenseActivationInputTooltip
                    }
                  >
                    <Tooltip
                      tip={`Find this code in your ${
                        step === OnboardingProgress.LicenseActivation
                          ? "activation"
                          : "renewal confirmation"
                      } email. If you cannot find the email, check your spam filters or search "Team Nation" in your inbox.`}
                      placement="right"
                    >
                      <CircleInformationIcon />
                    </Tooltip>
                  </div>
                </div>
                <div
                  className={
                    styles.accountSetupModal__Step_LicenseActivationInput
                  }
                >
                  <CharacterInput
                    type={INPUT_TYPES.TEXT}
                    placeholder="Order ID"
                    onChange={(e) => setOrderNumber(e.target.value)}
                    value={orderNumber}
                  />
                  <div
                    className={
                      styles.accountSetupModal__Step_LicenseActivationInputTooltip
                    }
                  >
                    <Tooltip
                      tip={`Find this code in your ${
                        step === OnboardingProgress.LicenseActivation
                          ? "activation"
                          : "renewal confirmation"
                      } email. If you cannot find the email, check your spam filters or search "Team Nation" in your inbox.`}
                      placement="right"
                    >
                      <CircleInformationIcon />
                    </Tooltip>
                  </div>
                </div>
                <div
                  className={
                    styles.accountSetupModal__Step_LicenseActivationButton
                  }
                >
                  <Button
                    width="full"
                    onClick={() => {
                      if (step === OnboardingProgress.LicenseActivation) {
                        activateLicense(licenseCode, orderNumber);
                      } else {
                        renewLicense(licenseCode, orderNumber);
                      }
                    }}
                    disabled={!licenseCode}
                  >
                    Continue
                  </Button>
                </div>
                <div
                  className={
                    styles.accountSetupModal__Step_LicenseActivationButton
                  }
                >
                  <Button
                    type="external link"
                    theme="secondary"
                    width="full"
                    externalLinkOptions={{
                      href: getShopifyProductURL(),
                    }}
                  >
                    Get a{" "}
                    {step === OnboardingProgress.LicenseActivation
                      ? "License Code"
                      : "Renewal Code"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {step === OnboardingProgress.FreeTrial && (
          <div className={styles.accountSetupModal__Step_FreeTrial}>
            <div className={styles.accountSetupModal__RowClean}>
              <div className={styles.accountSetupModal__Step_Header}>
                <span
                  className={styles.accountSetupModal__Step_HeaderSecondRow}
                >
                  Free Trial
                </span>
              </div>
            </div>
            <div className={styles.accountSetupModal__RowClean}>
              <div className={styles.accountSetupModal__Step_FreeTrialWrapper}>
                <div className={styles.accountSetupModal__Step_FreeTrialInput}>
                  <CharacterInput
                    type={INPUT_TYPES.TEXT}
                    placeholder="Trial Code"
                    value={licenseCode}
                    onChange={(e) => setLicenseCode(e.target.value)}
                    autofocus
                  />
                  <div
                    className={
                      styles.accountSetupModal__Step_LicenseActivationInputTooltip
                    }
                  >
                    <Tooltip
                      tip={
                        'Find this code in your activation email. If you cannot find the email, check your spam filters or search "Team Nation" in your inbox.'
                      }
                      placement="right"
                    >
                      <CircleInformationIcon />
                    </Tooltip>
                  </div>
                </div>
                <div className={styles.accountSetupModal__Step_FreeTrialButton}>
                  <Button
                    width="full"
                    onClick={() => activateLicense(licenseCode, "")}
                    disabled={!licenseCode}
                  >
                    Continue
                  </Button>
                </div>
                <div className={styles.accountSetupModal__Step_FreeTrialButton}>
                  <Button
                    type="external link"
                    theme="secondary"
                    width="full"
                    externalLinkOptions={{
                      href: "https://landing.teamnationsports.com/get-my-trial",
                    }}
                  >
                    Get a Trial Code
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {(step === OnboardingProgress.LicenseActivationSuccess ||
          step === OnboardingProgress.LicenseRenewalSuccess) && (
          <div className={styles.accountSetupModal__Step_1_2}>
            <div className={styles.accountSetupModal__Step_1_2_Header}>
              <div
                className={`${styles.accountSetupModal__Step_1_2_HeaderIcon} ${
                  licenseTier ? styles[licenseTier?.tier?.toLowerCase()] : ""
                }`}
              >
                <CheckmarkIcon />
              </div>
              <div className={styles.accountSetupModal__Step_1_2_HeaderText}>
                Your license has been{" "}
                {step === OnboardingProgress.LicenseActivationSuccess
                  ? "activated"
                  : "renewed"}
                !
              </div>
              {step === OnboardingProgress.LicenseRenewalSuccess && (
                <div className={styles.accountSetupModal__Step_1_2_HeaderText}>
                  The new expiration date is{" "}
                  {currentLicenses?.[0]?.expires
                    ? DateTime.fromISO(
                        currentLicenses[0].expires
                      ).toLocaleString()
                    : "not found"}
                  .
                </div>
              )}
            </div>
            <div className={styles.accountSetupModal__Step_1_2_Body}>
              <LicenseCard
                licenseTier={licenseTier}
                availableLicensesCount={availableTeamsCount}
                upgradedLicensesCount={upgradedTeamsCount}
              />
            </div>
            {availableTeamsCount > 0 && (
              <div className={styles.accountSetupModal__Step_1_2_Footer}>
                <div className={styles.accountSetupModal__Step_1_2_FooterText}>
                  Create at least one team to get started
                </div>
                <div
                  className={styles.accountSetupModal__Step_1_2_FooterButton}
                >
                  <Button
                    onClick={() => setStep(OnboardingProgress.CreateTeam)}
                  >
                    Create Teams
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        {step === OnboardingProgress.CreateTeam && (
          <div className={styles.accountSetupModal__Step_2_1}>
            <TeamDetails
              teamLogoChangeCallback={teamLogoUpdate}
              teamLogo={teamLogo}
              teamNameChangeCallback={teamNameUpdate}
              teamLevelChangeCallback={teamLevelUpdate}
              teamPrimaryColorChangeCallback={teamPrimaryColorUpdate}
              teamSecondaryColorChangeCallback={teamSecondaryColorUpdate}
              teamCityChangeCallback={teamCityUpdate}
              teamStateChangeCallback={teamStateUpdate}
              teamPostalCodeChangeCallback={teamPostalCodeUpdate}
            />
          </div>
        )}

        {step === OnboardingProgress.InvitePlayers && (
          <div className={styles.accountSetupModal__Step_2_2}>
            <TeamPlayers teamId={teamId} />
          </div>
        )}

        {step === OnboardingProgress.InviteCoaches && (
          <div className={styles.accountSetupModal__Step_2_3}>
            <TeamCoaches teamId={teamId} />
          </div>
        )}

        {step === OnboardingProgress.AllCreatedTeams && (
          <div className={styles.accountSetupModal__Step_3}>
            <div className={styles.accountSetupModal__Step_3__TopBanner}>
              <div className={styles.accountSetupModal__Step_3__TopBannerTitle}>
                <CircleCheckmarkIcon />
                <span>Good work! Your teams have been created</span>
              </div>
              <div
                className={
                  styles.accountSetupModal__Step_3__TopBannerDescription
                }
              >
                Team Nation can create learning games from the content in your
                playbook. Check out our libraries of plays, lessons and
                flashcards to help you get started.{" "}
                <Tooltip
                  tip={
                    <div>
                      <p>
                        Each of your teams gets a playbook where you can add
                        plays, lessons and flashcards.
                      </p>
                      <p>
                        Team Nation Pros have assembled a library of plays,
                        flashcards and lessons that you can quickly add to your
                        playbook.
                      </p>
                      <p>
                        To browse the libraries, go to a team, then navigate to
                        the type of content you’d like to add (plays, flashcards
                        or lessons) and there will be a button in the top right
                        corner to open the library.
                      </p>
                    </div>
                  }
                >
                  <span
                    className={
                      styles.accountSetupModal__Step_3__TopBannerTooltipLink
                    }
                  >
                    Learn More
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className={styles.accountSetupModal__Step_3__BottomBanner}>
              <div
                className={styles.accountSetupModal__Step_3__BottomBannerTitle}
              >
                Pick one of your teams to get started:
              </div>
              <div
                className={`${
                  styles.accountSetupModal__Step_3__BottomBannerCards
                } ${
                  teams && teams?.size > 3
                    ? styles.accountSetupModal__Step_3__BottomBannerCardsMultipleRows
                    : ""
                }`}
              >
                {teams &&
                  Array.from(teams, ([key, team]) => (
                    <TeamCard key={key} team={team} />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* FOOTER */}
      {step >= OnboardingProgress.CreateTeam &&
        step < OnboardingProgress.AllCreatedTeams && (
          <div className={styles.accountSetupModal__Footer}>
            <div className={styles.accountSetupModal__FooterLeftSide}>
              {step === OnboardingProgress.InvitePlayers && (
                <Button
                  onClick={() =>
                    uploadRoster(
                      invitationTypes.PLAYER,
                      "/csv-templates/invite-players.csv"
                    )
                  }
                >
                  Upload Players
                </Button>
              )}
              {step === OnboardingProgress.InviteCoaches && (
                <Button
                  onClick={() =>
                    uploadRoster(
                      invitationTypes.COACH,
                      "/csv-templates/invite-coaches.csv"
                    )
                  }
                >
                  Upload Coaches
                </Button>
              )}
            </div>
            <div className={styles.accountSetupModal__FooterRightSide}>
              {step === OnboardingProgress.CreateTeam && (
                <Button onClick={createTeam} disabled={!teamName || !teamLevel}>
                  Continue
                </Button>
              )}
              {step === OnboardingProgress.InvitePlayers && (
                <Button
                  onClick={() => setStep(OnboardingProgress.InviteCoaches)}
                >
                  Continue
                </Button>
              )}
              {step === OnboardingProgress.InviteCoaches && (
                <>
                  <Button
                    onClick={() => {
                      setTeamId("");
                      setStep(OnboardingProgress.CreateTeam);
                    }}
                    disabled={availableTeamsCount < 1}
                  >
                    Create Another Team
                  </Button>
                  <Button
                    onClick={() => setStep(OnboardingProgress.AllCreatedTeams)}
                  >
                    Complete Setup
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default AccountSetupModal;
