import * as React from "react";

const TeamSetupIconEmpty: React.FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-tag="allowRowEvents"
  >
    <rect
      x="1"
      y="1.5"
      width="18"
      height="18"
      rx="9"
      stroke="#484A59"
      strokeWidth="2"
      data-tag="allowRowEvents"
    />
  </svg>
);

export default TeamSetupIconEmpty;
