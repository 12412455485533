import React, { useContext } from "react";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import capitalize from "lodash/capitalize";

import styles from "./ConfirmationModal.module.scss";
import Button from "../Button/Button";

interface Props {
  actionCallback: () => void;
  actionName?: string;
  itemName?: string;
}

const ConfirmationModal: React.FC<Props> = ({
  actionCallback,
  actionName,
  itemName,
}) => {
  const { closeModal } = useContext(UIContext);

  return (
    <div className={styles.ConfirmationModal}>
      <p>
        {`Are you sure you want to ${actionName} this${
          itemName ? " " + itemName : ""
        }? This action cannot be undone.`}
      </p>
      <div className={styles.buttonRow}>
        <Button
          theme={"tertiary"}
          onClick={() => {
            actionCallback();
            closeModal();
          }}
          destructive
        >{`${actionName} ${
          itemName ? " " + capitalize(itemName) : ""
        }`}</Button>
        <Button onClick={closeModal}>{`Keep${
          itemName ? " " + capitalize(itemName) : ""
        }`}</Button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
