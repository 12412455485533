import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import styles from "./QuestionCard.module.scss";
import CharacterInput from "../../Input/CharacterInput";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import SunglowPlusIcon from "../../../resources/icons/SunglowPlusIcon";
import Switch from "../../Switch/Switch";
import AnswerTextLine from "./AnswerTextLine";
import { CustomQuizQuestionModel } from "../../../generated/from-api/models/custom-quiz/custom-quiz-question.model";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { MediaModel } from "../../../generated/from-api/models/media.model";
import MediaGallery from "../../MediaGallery/MediaGallery";
import MediaThumbnail from "../../media/MediaThumbnail/MediaThumbnail";
import { MediaContext } from "../../../shared/shared-with-mobile/providers/media.provider";
import CloseIcon from "../../../resources/icons/CloseIcon";
import MediaAnswersCard from "./MediaAnswersCard";
import isNumber from "lodash/isNumber";
import { CustomQuizQuestionOption } from "../../../generated/from-api/models/custom-quiz/custom-quiz-question-option.model";
import {
  uploadFile,
  validateFileSize,
} from "../../../utils/mediaHelpers.utils";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";

interface Props {
  className?: string;
  index?: number;
  onChangeCallback?: (
    questionObject: CustomQuizQuestionModel,
    isImagesAsAnswers: boolean
  ) => void;
  currentQuestionObject?: CustomQuizQuestionModel;
  isCurrentQuestionImagesAsAnswers?: boolean;
}

export type QuestionAnswerType = "correct" | "incorrect";
export type QuestionAnswerActionType = "add" | "delete";

const QuestionCard: React.FC<Props> = ({
  className = "",
  index,
  onChangeCallback,
  currentQuestionObject,
  isCurrentQuestionImagesAsAnswers,
}) => {
  const { currentTeam } = useContext(TeamContext);
  const { dispatchModal, dispatchToast } = useContext(UIContext);
  const { mediaItemsMap, addMediaItems } = useContext(MediaContext);

  const [questionObject, setQuestionObject] = useState<CustomQuizQuestionModel>(
    currentQuestionObject
      ? currentQuestionObject
      : {
          id: uuidv4(),
          question: "",
          questionMediaId: undefined,
          correctOptions: [{ optionType: "TEXT", text: "" }],
          incorrectOptions: [{ optionType: "TEXT", text: "" }],
        }
  );

  const [isImagesAsAnswers, setImagesAsAnswers] = useState<boolean>(
    !!isCurrentQuestionImagesAsAnswers
  );

  useEffect(() => {
    onChangeCallback && onChangeCallback(questionObject, isImagesAsAnswers);
  }, [questionObject, isImagesAsAnswers]);

  const updateQuestionName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.currentTarget.value;
    setQuestionObject({ ...questionObject, question: value });
  };

  const updateAnswerLine = (
    type: QuestionAnswerType,
    actionType: QuestionAnswerActionType,
    index: number
  ) => {
    const newQuestion = cloneDeep(questionObject);
    const isAddQuestion = actionType === "add";
    const isTypeCorrect = type === "correct";

    if (isAddQuestion) {
      if (isTypeCorrect) {
        newQuestion.correctOptions.push({ optionType: "TEXT" });
      } else {
        newQuestion.incorrectOptions.push({ optionType: "TEXT" });
      }
    } else {
      if (isTypeCorrect) {
        newQuestion.correctOptions.splice(index, 1);
      } else {
        newQuestion.incorrectOptions.splice(index, 1);
      }
    }

    setQuestionObject(newQuestion);
  };

  const updateQuestionAnswer = (
    type: QuestionAnswerType,
    value: string,
    index: number
  ) => {
    const newQuestion = cloneDeep(questionObject);
    const isTypeCorrect = type === "correct";

    if (isTypeCorrect) {
      newQuestion.correctOptions[index] = {
        ...newQuestion.correctOptions[index],
        text: value,
      };
    } else {
      newQuestion.incorrectOptions[index] = {
        ...newQuestion.incorrectOptions[index],
        text: value,
      };
    }

    setQuestionObject(newQuestion);
  };

  const addCustomQuestionImage = () => {
    dispatchModal({
      open: true,
      size: "large",
      className: styles.mediaGallerySetupModal,
      body: (
        <MediaGallery
          type={"Modal"}
          defaultFilters={["IMAGE", "VIDEO"]}
          allowBulkSelect={false}
          handleAddMedia={(media: MediaModel[]) => {
            const newQuestion = cloneDeep(questionObject);
            newQuestion.questionMediaId = media[0].id;
            setQuestionObject(newQuestion);
          }}
          addMediaButtonText="Add Media to Question"
        />
      ),
    });
  };

  const removeCustomQuestionImage = () => {
    const newQuestion = cloneDeep(questionObject);

    newQuestion.questionMediaId = "";
    setQuestionObject(newQuestion);
  };

  const updateQuestionUploadedMedia = (
    type: QuestionAnswerType,
    media: MediaModel,
    index?: number
  ) => {
    const newQuestion = cloneDeep(questionObject);
    const isTypeCorrect = type === "correct";
    const imageObject: CustomQuizQuestionOption = {
      optionType: "IMAGE",
      mediaId: media.id,
    };

    if (isTypeCorrect) {
      if (isNumber(index)) {
        newQuestion.correctOptions.splice(index, 1, imageObject);
      } else {
        newQuestion.correctOptions.push(imageObject);
      }
    } else {
      if (isNumber(index)) {
        newQuestion.incorrectOptions.splice(index, 1, imageObject);
      } else {
        newQuestion.incorrectOptions.push(imageObject);
      }
    }

    setQuestionObject(newQuestion);
  };

  const removeQuestionUploadedMedia = (
    type: QuestionAnswerType,
    index: number
  ) => {
    const newQuestion = cloneDeep(questionObject);
    const isTypeCorrect = type === "correct";

    if (isTypeCorrect) {
      newQuestion.correctOptions.splice(index, 1);
    } else {
      newQuestion.incorrectOptions.splice(index, 1);
    }

    setQuestionObject(newQuestion);
  };

  const updateQuestionDroppedMedia = async (
    type: QuestionAnswerType,
    file: any
  ) => {
    const obj = validateFileSize(file);

    if (!obj.isValid) {
      dispatchToast({
        type: "error",
        message: obj.errorMessage,
      });
      return;
    }

    const result = await uploadFile(file, currentTeam?.id);

    if (result && result.id) {
      addMediaItems([result]);
      updateQuestionUploadedMedia(type, result);
    }
  };

  const toggleImagesAsAnswers = (newVal: boolean) => {
    const newQuestionObject = cloneDeep(questionObject);
    if (newVal) {
      newQuestionObject.correctOptions = [];
      newQuestionObject.incorrectOptions = [];
    } else {
      newQuestionObject.correctOptions = [
        {
          optionType: "TEXT",
          text: "",
        },
      ];
      newQuestionObject.incorrectOptions = [
        {
          optionType: "TEXT",
          text: "",
        },
      ];
    }

    setQuestionObject(newQuestionObject);
    setImagesAsAnswers(newVal);
  };

  return (
    <div className={`${styles.questionCard} ${className}`}>
      <div className={styles.questionCardHeader}>
        <div className={styles.questionCardNormalSpacingWrapper}>
          <div className={styles.questionCardTitle}>
            Question {index ? index : ""}
          </div>
        </div>

        <div className={styles.questionCardSmallSpacingWrapper}>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Question"
            value={questionObject.question}
            onChange={updateQuestionName}
            autofocus
          />
        </div>

        {questionObject.questionMediaId ? (
          <div className={styles.questionCardMainImageWrapper}>
            <button
              className={styles.questionCardMainImageCloseButton}
              type={"button"}
              onClick={removeCustomQuestionImage}
            >
              <CloseIcon />
            </button>
            <MediaThumbnail
              mediaItem={mediaItemsMap[questionObject.questionMediaId]}
            />
          </div>
        ) : (
          <div className={styles.questionCardAddMediaButtonWrapper}>
            <button
              className={styles.questionCardAddMediaButton}
              type="button"
              onClick={addCustomQuestionImage}
            >
              <span className={styles.questionCardAddMediaButtonIcon}>
                <SunglowPlusIcon />
              </span>
              <span className={styles.questionCardAddMediaButtonText}>
                Add Image or Video to the question
              </span>
            </button>
          </div>
        )}
      </div>
      <div className={styles.questionCardBody}>
        <div className={styles.questionCardNormalSpacingWrapper}>
          <div className={styles.questionCardTitleSwitchWrapper}>
            <div className={styles.questionCardTitle}>Answers</div>
            <div className={styles.questionCardSwitchWrapper}>
              <Switch
                theme={"yellow"}
                onToggle={toggleImagesAsAnswers}
                toggled={isImagesAsAnswers}
              />
              <span className={styles.questionCardSwitchText}>
                Use images as answers
              </span>
            </div>
          </div>
        </div>

        {/* Text Answers */}
        {!isImagesAsAnswers && (
          <div className={styles.questionCardContentAnswers}>
            <div className={styles.questionCardCorrectAnswers}>
              {questionObject.correctOptions &&
              questionObject.correctOptions.length > 0 ? (
                questionObject.correctOptions
                  .filter((item) => item.optionType === "TEXT")
                  .map((item, index) => (
                    <div
                      key={index}
                      className={styles.questionCardNormalSpacingWrapper}
                    >
                      <AnswerTextLine
                        type="correct"
                        actionType={index === 0 ? "add" : "delete"}
                        text={item.text}
                        onChangeCallback={(event) =>
                          updateQuestionAnswer(
                            "correct",
                            event.currentTarget.value,
                            index
                          )
                        }
                        buttonClickCallback={(action) =>
                          updateAnswerLine("correct", action, index)
                        }
                      />
                    </div>
                  ))
              ) : (
                <div className={styles.questionCardNormalSpacingWrapper}>
                  <AnswerTextLine
                    type="correct"
                    actionType={index === 0 ? "add" : "delete"}
                    onChangeCallback={(event) =>
                      updateQuestionAnswer(
                        "correct",
                        event.currentTarget.value,
                        0
                      )
                    }
                    buttonClickCallback={(action) =>
                      updateAnswerLine("correct", action, 0)
                    }
                  />
                </div>
              )}
            </div>
            <div className={styles.questionCardIncorrectAnswers}>
              {questionObject.incorrectOptions &&
              questionObject.incorrectOptions.length > 0 ? (
                questionObject.incorrectOptions
                  .filter((item) => item.optionType === "TEXT")
                  .map((item, index) => (
                    <div
                      key={index}
                      className={styles.questionCardNormalSpacingWrapper}
                    >
                      <AnswerTextLine
                        type={"incorrect"}
                        actionType={index === 0 ? "add" : "delete"}
                        text={item.text}
                        onChangeCallback={(event) =>
                          updateQuestionAnswer(
                            "incorrect",
                            event.currentTarget.value,
                            index
                          )
                        }
                        buttonClickCallback={(action) =>
                          updateAnswerLine("incorrect", action, index)
                        }
                      />
                    </div>
                  ))
              ) : (
                <div className={styles.questionCardNormalSpacingWrapper}>
                  <AnswerTextLine
                    type="incorrect"
                    actionType={index === 0 ? "add" : "delete"}
                    onChangeCallback={(event) =>
                      updateQuestionAnswer(
                        "incorrect",
                        event.currentTarget.value,
                        0
                      )
                    }
                    buttonClickCallback={(action) =>
                      updateAnswerLine("incorrect", action, 0)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {/* Media Answers */}
        {isImagesAsAnswers && (
          <div className={styles.questionCardMediaAnswersWrapper}>
            {/* Correct Media Answers */}
            <div className={styles.questionCardMediaAnswers}>
              <MediaAnswersCard
                type={"correct"}
                questionMediaOptions={questionObject.correctOptions.filter(
                  (item) => item.optionType === "IMAGE"
                )}
                uploadMediaCallback={updateQuestionUploadedMedia}
                removeMediaCallback={removeQuestionUploadedMedia}
                dropActionCallback={updateQuestionDroppedMedia}
              />
            </div>

            {/* Incorrect Media Answers */}
            <div className={styles.questionCardMediaAnswers}>
              <MediaAnswersCard
                type={"incorrect"}
                questionMediaOptions={questionObject.incorrectOptions.filter(
                  (item) => item.optionType === "IMAGE"
                )}
                uploadMediaCallback={updateQuestionUploadedMedia}
                removeMediaCallback={removeQuestionUploadedMedia}
                dropActionCallback={updateQuestionDroppedMedia}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionCard;
