import { FormationModel } from "../generated/from-api/models/drawable/formation.model";
import { GamePlanContentModel } from "../generated/from-api/models/dtos/gamePlan-content.model";
import { PlayModel } from "../generated/from-api/models/play.model";
import { TagModel } from "../generated/from-api/models/tag.model";
import { PlaybookFiltersModel } from "../pages/Playbook/Playbook";
import { convertFormationIdsToNames } from "../shared/shared-with-mobile/functions";
import { SingleSelectOption } from "../components/SingleSelect/SingleSelect";

/**
 * @description Given a string that matches query string parameters, return an object containing parsed search parameters and their values.
 * @param {string} queryString (ex. param1=val1,val2&param2=val1)
 * @returns {[key: string]: string} {[key: string]: string}
 */
export const parseQuery = (queryString: string): unknown => {
  const query: { [key: string]: string } = {};
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    const key: string = decodeURIComponent(pair[0]);
    query[key] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

/**
 * @description Given a string that is pathname of a url, return a string that is just the initial "/" and the page path
 * @param {string} s (ex. "/admin")
 * @returns {string} {string}
 */
export const findPathName = (s: string): string => {
  const tempString = s.slice(1, s.length - 1);
  return `/${tempString.slice(0, tempString.indexOf("/"))}`;
};

export const toStringHeight = (n: number): string => {
  const feet = Math.floor(n / 12);
  const inches = n % 12;
  return `${feet}' ${inches}"`;
};

/**
 * @description Given a playbook, formations, and a set of filters, return the filtered playbook
 * @param {PlaybookFiltersModel} playbookFilters
 * @param {PlayModel[]} currentPlaybook
 * @param {FormationModel[]} currentFormations
 * @returns {PlayModel[]}
 */
export const filterPlaybook = (
  playbookFilters: PlaybookFiltersModel,
  currentPlaybook: PlayModel[],
  currentFormations: FormationModel[],
  gamePlans: GamePlanContentModel[] = []
): PlayModel[] => {
  const filterByCategory = (play: PlayModel) =>
    play.playCategory === playbookFilters.category;

  const filterBySearchText = (play: PlayModel) =>
    play.name.toLowerCase().includes(playbookFilters.searchText.toLowerCase());

  const filterByScheme = (play: PlayModel) =>
    play.scheme === playbookFilters.scheme;

  let nextFilteredPlaybook = currentPlaybook || [];

  if (playbookFilters.category !== null) {
    nextFilteredPlaybook = nextFilteredPlaybook.filter(filterByCategory);
  }

  if (playbookFilters.searchText) {
    nextFilteredPlaybook = nextFilteredPlaybook.filter(filterBySearchText);
  }

  if (playbookFilters.scheme !== null) {
    nextFilteredPlaybook = nextFilteredPlaybook.filter(filterByScheme);
  }

  if (playbookFilters.formationIds.length) {
    const formationNames = convertFormationIdsToNames(
      playbookFilters.formationIds,
      currentFormations
    );

    nextFilteredPlaybook = nextFilteredPlaybook.filter((play) => {
      if (!!play.formationName && formationNames.includes(play.formationName)) {
        return true;
      }

      if (
        !!play.opponentFormationName &&
        formationNames.includes(play.opponentFormationName)
      ) {
        return true;
      }

      return false;
    });
  }

  if (playbookFilters.defensiveSetIds.length) {
    const formationNames = convertFormationIdsToNames(
      playbookFilters.defensiveSetIds,
      currentFormations
    );

    nextFilteredPlaybook = nextFilteredPlaybook.filter((play) => {
      if (!!play.formationName && formationNames.includes(play.formationName)) {
        return true;
      }

      if (
        !!play.opponentFormationName &&
        formationNames.includes(play.opponentFormationName)
      ) {
        return true;
      }

      return false;
    });
  }
  if (playbookFilters.situationNames.length) {
    nextFilteredPlaybook = nextFilteredPlaybook.filter((play) => {
      if (play.situation) {
        return playbookFilters.situationNames.includes(play.situation);
      }
    });
  }
  if (playbookFilters.personnelPackageNames.length) {
    nextFilteredPlaybook = nextFilteredPlaybook.filter((play) => {
      if (play.personnel) {
        return playbookFilters.personnelPackageNames.includes(play.personnel);
      }
    });
  }
  if (playbookFilters.gamePlanId) {
    const selectedGamePlan = gamePlans.find(
      (gamePlan) => gamePlan.id === playbookFilters.gamePlanId
    );

    const playIdSet = new Set();
    const playSetIdSet = new Set();
    if (selectedGamePlan) {
      for (const play of selectedGamePlan.plays) {
        playIdSet.add(play.id);
      }

      for (const playset of selectedGamePlan.playSets) {
        playSetIdSet.add(playset.id);
      }
    }

    nextFilteredPlaybook = nextFilteredPlaybook.filter((play) => {
      return playIdSet.has(play.id) || playSetIdSet.has(play.id);
    });
  }

  return nextFilteredPlaybook;
};

/**
 * @description Given a a list of categories, a list of schemes, and a list of plays, generate a count for each category and scheme
 * @param {string[]} categories
 * @param {string[]} schemes
 * @param {PlayModel[]} plays
 * @returns {Record<string, number>[]}
 */
export const getCategoryAndSchemeCounts = (
  categories: string[],
  schemes: string[],
  plays: PlayModel[]
): Record<string, number>[] => {
  const categoryCounts: Record<string, number> = {};
  const schemeCounts: Record<string, number> = {};

  for (const category of categories) {
    if (category === "All") {
      categoryCounts[category] = plays.length;
    } else {
      categoryCounts[category] = 0;
    }
  }

  for (const scheme of schemes) {
    schemeCounts[scheme] = 0;
  }

  for (const play of plays) {
    if (play.playCategory) {
      categoryCounts[play.playCategory]++;
    }

    if (play.scheme) {
      schemeCounts[play.scheme]++;
    }
  }

  return [categoryCounts, schemeCounts];
};

export const mapTagToOption = (tag: TagModel): SingleSelectOption => ({
  label: tag.name,
  value: tag.name,
});

export const mapTagsToSelectOptions = (
  tags: TagModel[]
): SingleSelectOption[] => [
  { label: "None", value: "" },
  ...tags.map(mapTagToOption),
];

export const mapGamePlanToOption = (
  gamePlan: GamePlanContentModel
): SingleSelectOption => ({
  label: gamePlan.name,
  value: gamePlan.id as string,
});

export const generateDateString = (date?: string): string => {
  if (!date) {
    return "";
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateObject = new Date(date);
  return `${
    monthNames[dateObject.getMonth()]
  } ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
};

export const shortenPositionsList = (
  positions: string | string[]
): string[] => {
  // The list of provided positions will be checked against this data, if all the positions are
  // found they will be removed and replaced with the group name.
  const PositionsListBreakdowns = [
    {
      name: `All Positions`,
      positions: "QB|RB|WR|TE|C|OG|OT|LB|CB|SS|FS|DE|DT|NT|K|H|PR|KR|KOS|P|LS",
    },
    {
      name: `Offensive Line`,
      positions: "QB|RB|WR|TE|C|OG|OT",
    },
    {
      name: `Defensive Line`,
      positions: "LB|CB|SS|FS|DE|DT|NT",
    },
    {
      name: `Special Teams`,
      positions: "K|H|PR|KR|KOS|P|LS",
    },
  ];

  if (typeof positions === "string") {
    positions = positions.split("|");
  }
  const shortenedPositions = Object.assign([], positions);

  // Widdle down the positions using the above data
  PositionsListBreakdowns.forEach((group) => {
    const groupPositions = group.positions.split("|");
    const foundIndexes: number[] = [];
    groupPositions.forEach((position) => {
      const indexInPositions = shortenedPositions.indexOf(position);
      if (indexInPositions >= 0) {
        foundIndexes.push(indexInPositions);
      }
    });
    if (foundIndexes.length === groupPositions.length) {
      foundIndexes.forEach((removeIndex) => {
        delete shortenedPositions[removeIndex];
      });
      shortenedPositions.unshift(group.name);
    }
  });

  // Clean it up
  positions = shortenedPositions
    .join("|")
    .replace(/(\|+)/g, "|")
    .replace(/^\|*(.+?)\|*$/g, "$1")
    .split("|");

  return positions;
};

export const generateCategoryNameById = (id: string, categories: any) => {
  for (const key in categories) {
    if (categories[key] === id) {
      return key;
    }
  }
};

export const daysToMilliseconds = (days: number) => {
  return days * 24 * 60 * 60 * 1000;
};
