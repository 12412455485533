import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { API_BASE_URL } from "../../constants";

export const ImpersonationService = {
  /**
   * Use to impersonate a user, admins only
   * @param
   */
  POST: async function (
    userIdToImpersonate: string
  ): Promise<{ token: string } | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.IMPERSONATION}`, {
        userIdToImpersonate,
      })
    ).data;
  },
};
