import { createContext, useState } from "react";

import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";

export const PlayersContext = createContext<{
  currentPlayers: undefined | null | Map<string, PlayerModel>;
  currentPlayersAsArray: PlayerModel[];
  replacePlayers: (newPlayers: PlayerModel[]) => void;
  addOrUpdatePlayer: (player: PlayerModel) => void;
  removePlayer: (playerId: string) => void;
  clearPlayers: () => void;
}>({
  currentPlayers: undefined,
  currentPlayersAsArray: [],
  replacePlayers: () => null,
  addOrUpdatePlayer: () => null,
  removePlayer: () => null,
  clearPlayers: () => null,
});

export const PlayersProvider: React.FC = ({ children }) => {
  const [currentPlayers, setCurrentPlayers] = useState<Map<
    string,
    PlayerModel
  > | null>(null);

  const [currentPlayersAsArray, setCurrentPlayerAsArray] = useState<
    PlayerModel[]
  >([]);

  const replacePlayers = (newPlayers: PlayerModel[]) => {
    const newPlayersMap = new Map<string, PlayerModel>();

    if (newPlayers) {
      newPlayers.forEach((player) => {
        newPlayersMap.set(player.id as string, player);
      });
    }

    setCurrentPlayers(newPlayersMap);
    setCurrentPlayerAsArray(Array.from(newPlayersMap.values()));
  };

  const addOrUpdatePlayer = (player: PlayerModel) => {
    const currentPlayersCopy = currentPlayers
      ? new Map(currentPlayers)
      : new Map<string, PlayerModel>();

    currentPlayersCopy.set(player.id as string, player);

    setCurrentPlayers(currentPlayersCopy);
    setCurrentPlayerAsArray(Array.from(currentPlayersCopy.values()));
  };

  const removePlayer = (playerId: string) => {
    const currentPlayersCopy = currentPlayers
      ? new Map(currentPlayers)
      : new Map<string, PlayerModel>();

    currentPlayersCopy.delete(playerId);

    setCurrentPlayers(currentPlayersCopy);
    setCurrentPlayerAsArray(Array.from(currentPlayersCopy.values()));
  };

  const clearPlayers = () => {
    setCurrentPlayers(null);
    setCurrentPlayerAsArray([]);
  };

  return (
    <PlayersContext.Provider
      value={{
        currentPlayers,
        currentPlayersAsArray,
        replacePlayers,
        addOrUpdatePlayer,
        removePlayer,
        clearPlayers,
      }}
    >
      {children}
    </PlayersContext.Provider>
  );
};
