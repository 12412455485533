import styles from "../PlaybookIQ.module.scss";
import { ResponsiveLine, Serie } from "@nivo/line";
import { Theme } from "@nivo/core";
import { useEffect, useState } from "react";
const theme: Theme = {
  textColor: "#ffffff",
  axis: {
    ticks: {
      line: {
        stroke: "transparent",
        color: "#eee",
      },
      text: {
        fill: "#7E7E85",
        fontSize: "13px",
        fontFamily: "QUANTICO",
      },
    },
  },
  grid: {
    line: {
      stroke: "#7E7E85",
      strokeWidth: 0.3,
    },
  },
  crosshair: {
    line: {
      stroke: "#7E7E85",
      strokeWidth: 0.3,
    },
  },
  tooltip: {
    basic: { color: "#000000" },
    container: {
      fontSize: 9,
      transform: "translate(-15%, -10%)",
      fontWeight: "bold",
      alignItems: "center",
      justifyContent: "center",
    },
  },
};
interface Props {
  title?: string;
  xLabel?: string;
  yLabel?: string;
  ySymbol?: string;
  data?: Serie[];
}
const ReadinessOverTime: React.FC<Props> = ({
  title,
  xLabel,
  yLabel,
  ySymbol,
  data,
}) => {
  const [defaultTickSetting, setDefaultTickSetting] = useState<string>(
    "every 1 days"
  );

  useEffect(() => {
    if (!data || !data[0] || !data[0].data) {
      setDefaultTickSetting("every 1 days");
      return;
    }
    const dataset = data[0].data;
    if (dataset.length <= 8) {
      setDefaultTickSetting("every 1 days");
    } else if (dataset.length <= 16) {
      setDefaultTickSetting("every 2 days");
    } else if (dataset.length <= 32) {
      setDefaultTickSetting("every 4 days");
    } else {
      setDefaultTickSetting("every 7 days");
    }
  }, [data]);

  return (
    <div className={styles.dashboardCard} style={{ height: "320px" }}>
      <div className={styles.cardSubtitleWithBottomBorder}>{title}</div>
      <div className={styles.chartContainer}>
        {data && data[0] && data[0].data && data[0].data.length >= 2 ? (
          <ResponsiveLine
            data={data}
            margin={{
              top: 10,
              right: 30,
              bottom: 70,
              left: yLabel && ySymbol ? 65 : yLabel || ySymbol ? 50 : 30,
            }}
            xScale={{
              type: "time",
              format: "%Y-%m-%dT%H:%M:%S.%L%Z",
              precision: "day",
              useUTC: false,
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: "linear",
              min: 0,
              max: 100,
              stacked: false,
              reverse: false,
            }}
            theme={theme}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -60,
              legend: yLabel,
              legendPosition: "middle",
              format: (value) => `${Number(value)} ${ySymbol}`,
            }}
            axisBottom={{
              format: "%b %d",
              tickSize: 0,
              tickPadding: 10,
              tickRotation: -40,
              tickValues: defaultTickSetting,
              legend: xLabel,
              legendOffset: 60,
              legendPosition: "middle",
            }}
            pointSize={5}
            pointBorderWidth={2}
            pointLabel="y"
            pointLabelYOffset={-10}
            colors={["#00b2ff"]}
            pointBorderColor={{ from: "serieColor" }}
            enableGridX={false}
            useMesh={true}
            gridYValues={9}
          />
        ) : (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            We are still collecting data for this chart.
          </p>
        )}
      </div>
    </div>
  );
};

export default ReadinessOverTime;
