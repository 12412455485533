import React from "react";
import Tooltip from "../Tooltip/Tooltip";

import styles from "./PositionsList.module.scss";
interface Props {
  positions: string | string[];
  theme?: "light" | "dark" | "transparent";
  short?: boolean;
}

// The list of provided positions will be checked against this data, if all the positions are
// found they will be removed and replaced with the group name.
const PositionsListBreakdowns = [
  {
    name: `All Positions`,
    shortName: `All`,
    positions: "QB|RB|WR|TE|C|OG|OT|LB|CB|SS|FS|DE|DT|NT|K|H|PR|KR|KOS|P|LS",
  },
  {
    name: `All Offense`,
    shortName: `Off`,
    positions: "QB|RB|WR|TE|C|OG|OT",
  },
  {
    name: `All Defense`,
    shortName: `Def`,
    positions: "LB|CB|SS|FS|DE|DT|NT",
  },
  {
    name: `Special Teams`,
    shortName: `S.T.`,
    positions: "K|H|PR|KR|KOS|P|LS",
  },
];

// Component starts here
const PositionsList: React.FC<Props> = ({
  positions,
  theme = "light",
  short = false,
}) => {
  if (typeof positions === "string") {
    positions = positions.split("|");
  }
  positions = positions.filter((pos) => {
    return pos !== "";
  });
  if (positions.length === 0) {
    return null;
  }
  const shortenedPositions = Object.assign([], positions);

  // Widdle down the positions using the above data
  PositionsListBreakdowns.forEach((group) => {
    const groupPositions = group.positions.split("|");
    const foundIndexes: number[] = [];
    groupPositions.forEach((position) => {
      const indexInPositions = shortenedPositions.indexOf(position);
      if (indexInPositions >= 0) {
        foundIndexes.push(indexInPositions);
      }
    });
    if (foundIndexes.length === groupPositions.length) {
      foundIndexes.forEach((removeIndex) => {
        delete shortenedPositions[removeIndex];
      });
      shortenedPositions.unshift(short ? group.shortName : group.name);
    }
  });

  // Clean it up
  positions = shortenedPositions
    .join("|")
    .replace(/(\|+)/g, "|")
    .replace(/^\|*(.+?)\|*$/g, "$1")
    .split("|");

  // If we're short shorten it even further
  const loosePositions: string[] = [];
  if (short) {
    const shortNamesArray = PositionsListBreakdowns.map((breakdown) => {
      return breakdown.shortName;
    });
    const groupedPositions: string[] = [];
    positions.forEach((position) => {
      if (shortNamesArray.indexOf(position) < 0) {
        loosePositions.push(position);
      } else {
        groupedPositions.push(position);
      }
    });
    positions = groupedPositions;
  }

  // The actual element is really basic
  return (
    <div className={`${styles.positionsBlock} ${theme}`}>
      {positions.map((position) => {
        return <span key={position}>{position}</span>;
      })}
      {short && loosePositions.length > 0 ? (
        <Tooltip
          tip={loosePositions.join(", ")}
          color={theme === "dark" ? "dark" : "light"}
        >
          {positions.length > 0 ? "+" : null}
          {loosePositions.length} Pos.
        </Tooltip>
      ) : null}
    </div>
  );
};

export default PositionsList;
