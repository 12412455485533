/* eslint-disable no-console */
import { useRef, useEffect, useState } from "react";
import { MediaModel } from "../../../generated/from-api/models/media.model";
import backArrow from "../../../resources/images/arrowLeft.svg";
import cropIcon from "../../../resources/images/crop.svg";
import cropIconSelected from "../../../resources/images/crop-selected.svg";
import restoreImage from "../../../resources/images/restorePage.svg";
import rotateLeft from "../../../resources/images/rotate-left.svg";
import rotateRight from "../../../resources/images/rotate-right.svg";
import styles from "./SelectedImageEdit.module.scss";

import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import Button from "../../Button/Button";
const imageToBase64 = require("image-to-base64");
interface Props {
  image: MediaModel;
  setIsImageEditing: React.Dispatch<React.SetStateAction<boolean>>;
  updateImage: (event: FormData) => Promise<MediaModel | undefined>;
}

interface cropInterface {
  unit?: "px" | "%";
  width?: number;
  height?: number;
  x?: number;
  y?: number;
}

const SelectedImageEdit = ({
  image,
  setIsImageEditing,
  updateImage,
}: Props): JSX.Element => {
  const [IMAGE, setIMAGE] = useState("");
  const [editMode, setEditMode] = useState<"none" | "crop" | "rotate">("none");
  const [crop, setCrop] = useState<cropInterface>({
    unit: "px",
    width: 100,
    height: 100,
    x: 1,
    y: 1,
  });
  const imageRef = useRef<HTMLImageElement>();
  const [img, setImg] = useState<HTMLImageElement>();
  const [blob, setBlob] = useState<Blob | any>();
  const [newImageURL, setNewImageURL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [rotation, setRotation] = useState<number>(0);

  const getBase64FromUrl = async (url: any) => {
    setLoading(true);
    try {
      const base64Data = await imageToBase64(url);
      const base64Response = await fetch(
        `data:image/jpeg;base64,${base64Data}`
      );
      const Blob = await base64Response.blob();
      setBlob(Blob);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (image && image.url) {
      getBase64FromUrl(image.url);
    }
  }, [image]);

  useEffect(() => {
    if (newImageURL) {
      getBase64FromUrl(newImageURL);
    }
  }, [newImageURL]);

  useEffect(() => {
    if (blob) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          setIMAGE(e.target ? (e.target.result as string) : "");
        }
      };

      reader.readAsDataURL(blob as Blob);
    }
  }, [blob]);

  const onImageLoaded = (image: HTMLImageElement) => {
    imageRef.current = image;
    setImg(image);
  };

  const toggleCropMode = () => {
    if (editMode === "crop") {
      setEditMode("none");
    } else {
      setEditMode("crop");
      setRotation(0);
    }
  };

  const setCropModeOff = () => {
    setEditMode("none");
  };

  const handleCrop = async () => {
    if (imageRef.current) {
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      const data = new FormData();
      const imageName = fileExtensionCheckHandler(
        image.filePathInBucket as string
      );
      data.append("image", blob, imageName);

      if (crop.x && crop.y && crop.width && crop.height) {
        data.append("x", `${Math.floor(crop.x * scaleX)}`);
        data.append("y", `${Math.floor(crop.y * scaleY)}`);
        data.append("width", `${Math.floor(crop.width * scaleX)}`);
        data.append("height", `${Math.floor(crop.height * scaleY)}`);
        data.append("mediaId", image.id as string);
      }

      if (img) {
        const canvas = document.createElement("canvas");
        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        canvas.width = crop.width ? crop.width : 0;
        canvas.height = crop.height ? crop.height : 0;
        const ctx = canvas.getContext("2d");
        if (crop.x && crop.y && crop.width && crop.height) {
          ctx?.drawImage(
            img,
            crop.x ? Math.floor(crop.x * scaleX) : 0,
            crop.y ? Math.floor(crop.y * scaleY) : 0,
            crop.width ? Math.floor(crop.width * scaleX) : 0,
            crop.height ? Math.floor(crop.height * scaleY) : 0,
            0,
            0,
            crop.width,
            crop.height
          );
        }
      }
      try {
        const response = await updateImage(data);
        if (response && response.url) {
          setNewImageURL(response.url);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setEditMode("none");
      }
    }
  };

  const handleRotation = async () => {
    if (imageRef.current) {
      const rotateFlipsDimensions =
        rotation == 90 || rotation == 270 ? true : false;

      if (img) {
        const canvas = document.createElement("canvas");
        canvas.width = rotateFlipsDimensions
          ? imageRef.current.naturalHeight
          : imageRef.current.naturalWidth;
        canvas.height = rotateFlipsDimensions
          ? imageRef.current.naturalWidth
          : imageRef.current.naturalHeight;
        const ctx = canvas.getContext("2d");
        ctx?.translate(canvas.width / 2, canvas.height / 2);
        ctx?.rotate((rotation * Math.PI) / 180);
        if (rotateFlipsDimensions) {
          ctx?.drawImage(img, canvas.height / -2, canvas.width / -2);
        } else {
          ctx?.drawImage(img, canvas.width / -2, canvas.height / -2);
        }

        let type = image.name.split(".").pop();
        if (type == "jpeg" || type == "jpg") {
          type = "image/jpg";
        } else {
          type = "image/png";
        }
        canvas.toBlob(
          async (blob) => {
            const data = new FormData();
            data.append("image", blob || "", image.name);
            data.append("mediaId", image.id as string);
            try {
              const response: any = await updateImage(data);
              setNewImageURL(response.url && response.url);
            } catch (e) {
              console.log(e);
            } finally {
              setEditMode("none");
              setRotation(0);
            }
          },
          type,
          1
        );
      }
    }
  };

  const fileExtensionCheckHandler = (URL: string) => {
    const fileExtensionLetters = [];

    for (let i = URL.length - 1; i >= 0; i--) {
      fileExtensionLetters.unshift(URL[i]);
      if (URL[i] === ".") {
        break;
      }
    }
    const fileExtension = fileExtensionLetters.join("");
    return `image${fileExtension}`;
  };

  const rotateImage = (angle: number) => {
    const newAngle = (rotation + angle) % 360;
    setRotation(newAngle < 0 ? newAngle + 360 : newAngle);
    if (newAngle != 0) {
      setEditMode("rotate");
    } else {
      setEditMode("none");
    }
  };

  return (
    <>
      <div className={styles.header}>
        <span
          className={styles.goBackArea}
          onClick={() => {
            setIsImageEditing(false);
          }}
        >
          <img src={backArrow} alt="back arrow icon" role="button" />
          Edit Image
        </span>
      </div>
      <div className={styles.imageEditorGrid}>
        <div className={styles.mediaPreview}>
          {loading ? (
            <div className={styles.loader} />
          ) : editMode === "crop" ? (
            <ReactCrop
              style={{ maxHeight: "55vh" }}
              src={IMAGE}
              crop={crop}
              circularCrop={false}
              onChange={(newCrop) => setCrop(newCrop)}
              onImageLoaded={onImageLoaded}
            />
          ) : (
            <img
              src={blob ? URL.createObjectURL(blob) : (image.url as string)}
              style={{ transform: `rotate(${rotation}deg)` }}
              alt={image?.name}
              className={styles.imagePreview}
              ref={imageRef as any}
              id="edit-image"
              onLoad={(e) => {
                onImageLoaded(e.target as HTMLImageElement);
              }}
            />
          )}
        </div>
        <div className={styles.editToolbar}>
          <img
            src={restoreImage}
            alt="restore image icon"
            role="button"
            onClick={setCropModeOff}
          />
          <img
            src={editMode === "crop" ? cropIconSelected : cropIcon}
            alt="crop image icon"
            role="button"
            onClick={toggleCropMode}
          />
          {editMode === "rotate" && (
            <span className={styles.uploadButton}>
              <Button type="button" onClick={handleRotation}>
                Rotate Image
              </Button>
            </span>
          )}
          {editMode === "crop" && (
            <span className={styles.uploadButton}>
              <Button size={"small"} onClick={handleCrop}>
                Crop Image
              </Button>
            </span>
          )}
          <img
            src={rotateRight}
            alt="rotate right icon"
            role="button"
            onClick={() => {
              rotateImage(90);
            }}
          />
          <img
            src={rotateLeft}
            alt="rotate left icon"
            role="button"
            onClick={() => rotateImage(-90)}
          />
        </div>
      </div>
    </>
  );
};

export default SelectedImageEdit;
