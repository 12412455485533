import React, { useContext } from "react";
import styles from "./QuizCard.module.scss";
import { CustomQuizModel } from "../../../generated/from-api/models/custom-quiz.model";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import Button from "../../Button/Button";

interface Props {
  className?: string;
  quiz: CustomQuizModel;
  previewQuizCallback?: (quizId: string) => void;
  addQuizCallback?: (quizId: string) => void;
}

const QuizCard: React.FC<Props> = ({
  quiz,
  className = "",
  previewQuizCallback,
  addQuizCallback,
}) => {
  const { customQuizCategoryTags } = useContext(TagsContext);
  const quizCategory = quiz.categoryTagId
    ? customQuizCategoryTags.filter((tag) => tag.id === quiz.categoryTagId)
    : [];

  return (
    <div className={`${styles.quizCard} ${className}`}>
      <div className={styles.quizCardNameWrapper}>
        <span className={styles.quizCardName}>{quiz.name}</span>
      </div>
      {quizCategory && quizCategory.length > 0 && (
        <div className={styles.quizCardTermsWrapper}>
          {quizCategory[0].name}
        </div>
      )}
      <div className={styles.quizCardButtonsWrapper}>
        <div className={styles.quizCardButtonWrapper}>
          <Button
            theme={"tertiary"}
            onClick={() =>
              previewQuizCallback && quiz.id && previewQuizCallback(quiz.id)
            }
          >
            Preview
          </Button>
        </div>
        <div className={styles.quizCardButtonWrapper}>
          <Button
            onClick={() =>
              addQuizCallback && quiz.id && addQuizCallback(quiz.id)
            }
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
