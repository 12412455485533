import React from "react";
import styles from "./Switch.module.scss";

interface Props {
  theme?: "jade" | "yellow";
  size?: "normal" | "large";
  toggled: boolean;
  onToggle: (newVal: boolean) => void;
  disabled?: boolean;
}

const Switch: React.FC<Props> = ({
  toggled,
  onToggle,
  theme = "jade",
  size = "normal",
  disabled = false,
}) => {
  return (
    <div
      className={`${styles.switchContainer} ${styles[theme]} ${
        styles[size]
      } ${size} ${toggled && styles.toggled} ${disabled && styles.disabled}`}
      onClick={() => {
        if (!disabled) onToggle(!toggled);
      }}
    >
      <div className={styles.switch} />
    </div>
  );
};

export default Switch;
