import mainStyles from "../Dashboard.module.scss";
import CharacterInput from "../../../components/Input/CharacterInput";
import searchIcon from "../../../resources/images/search.svg";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import ToggleSelect from "../../../shared/shared-with-mobile/components/ToggleSelect/ToggleSelect";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReadinessScoresContext } from "../../../shared/shared-with-mobile/providers/readinessScores.provider";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import { DashboardStatsGrid } from "../../../components/DashboardStatsGrid/DashboardStatsGrid";
import OverallReadiness from "../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/OverallReadiness";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import PlayerModal from "../Components/PlayerModal/PlayerModal";
import dashboardStatsStyles from "../../../components/DashboardStatsGrid/DashboardStatsGrid.module.scss";
import Avatar from "../../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import CircularSpinner from "../../../components/Loaders/CircularSpinner";
import { isEmpty } from "lodash";
import { getAbbreviatedDisplayName } from "../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import {
  PlayerRow,
  PositionFiltersMap,
} from "../../../shared/shared-with-mobile/functions";
import LessonLessons from "./Lesson-components/LessonDrillIn/LessonLessons";
import { playersFilter } from "../../../utils/playerFilter.utils";
import ReadinessOverTime from "../../../shared/shared-with-mobile/dashboard-widgets/ReadinessOverTime/ReadinessOverTime";
import {
  match,
  RouteComponentProps,
  useHistory,
  withRouter,
} from "react-router";
import { DashboardUtility } from "../../../shared/shared-with-mobile/utilities/dashboard.utility";
import Button from "../../../components/Button/Button";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import GenericConfirmationModal from "../../../components/GenericConfirmationModal/ConfirmationModal";
const filtersForPlayersAndLessons = ["Players", "Lessons"];

interface Props extends RouteComponentProps {
  match: match<MatchParams>;
}
interface MatchParams {
  id: string;
}

type InstallRow = {
  installName: string;
  installId: string;
  categoryId: string;
  readiness: number;
};

const DashboardLessons: React.FC<Props> = ({ match }) => {
  const { currentTeam } = useContext(TeamContext);
  const { dispatchModal, dispatchToast } = useContext(UIContext);
  const {
    loading,
    playerInstallMetrics,
    teamMetricsOverTime,
    setPlayerInstallMetrics,
    setPlayerOverallMetrics,
  } = useContext(ReadinessScoresContext);
  const { currentPlayersAsArray } = useContext(PlayersContext);

  const [selectedFilter, setSelectedFilter] = useState(PositionFiltersMap[0]);
  const [searchText, setSearchText] = useState("");

  const { customQuizCategoryTags } = useContext(TagsContext);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [
    selectedFilterForPlayersAndLessons,
    setSelectedFilterForPlayersAndLessons,
  ] = useState(filtersForPlayersAndLessons[0]);
  const [lessonCategories, setLessonCategories] = useState([
    {
      label: "All",
      value: "",
    },
  ]);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [showLessonDrillIn, setShowLessonDrillIn] = useState(false);
  const [lessonId, setLessonId] = useState<string>("");
  const [player, setPlayer] = useState<PlayerModel>();
  const [dataForPlayerStatsGrid, setDataForPlayerStatsGrid] = useState<
    PlayerRow[]
  >();
  const [dataForInstallStatsGrid, setDataForInstallStatsGrid] = useState<
    InstallRow[]
  >();
  const history = useHistory();
  useEffect(() => {
    if (
      currentPlayersAsArray &&
      !isEmpty(currentPlayersAsArray) &&
      playerInstallMetrics &&
      !isEmpty(playerInstallMetrics) &&
      customQuizCategoryTags
    ) {
      const newCategories = customQuizCategoryTags.map((c) => {
        return { label: c.name, value: c.id ? c.id : "" };
      });
      newCategories.unshift({ label: "All", value: "" });
      setLessonCategories(newCategories);

      const installsMap: Map<
        string,
        {
          id: string;
          installName: string;
          categoryId: string;
          installPositions: string;
        }
      > = new Map();
      playerInstallMetrics.forEach((p) => {
        installsMap.set(p.installId, {
          id: p.installId,
          installName: p.installName,
          categoryId: p.categoryId,
          installPositions: p.installPositions,
        });
      });
      const installsArray = Array.from(installsMap.values());

      const allPlayers = currentPlayersAsArray;
      const playerRows: PlayerRow[] = allPlayers
        .filter((player: PlayerModel) => !player.hideOnLeaderBoard)
        .map((p) => {
          const playerInstalls = DashboardUtility.selectPlayerInstalls(
            p,
            playerInstallMetrics
          );
          const playerInstallCount = playerInstalls.length;
          let playerScoreSum = 0;
          playerInstalls.forEach((install) => {
            playerScoreSum = playerScoreSum + +install.readinessScore;
          });

          return {
            playerFirstName: p.firstName,
            playerLastName: p.lastName,
            playerId: p.id ? p.id : "",
            playerUserId: p.userId,
            positions: p.positions ? p.positions : "",
            readiness:
              playerInstallCount == 0 ? 0 : playerScoreSum / playerInstallCount,
          };
        });
      setDataForPlayerStatsGrid(playerRows);

      const installRows: InstallRow[] = installsArray.map((install) => {
        const scoreSum = playerInstallMetrics.reduce((prev, cur) => {
          if (cur.installId == install.id) {
            return prev + +cur.readinessScore;
          } else {
            return prev;
          }
        }, 0);
        const currentPlayersToDisplay = currentPlayersAsArray.filter(
          (player: PlayerModel) => !player.hideOnLeaderBoard
        );

        return {
          installId: install.id,
          installName: install.installName,
          categoryId: install.categoryId,
          readiness:
            currentPlayersToDisplay.length == 0
              ? 0
              : scoreSum / currentPlayersToDisplay.length,
        };
      });
      setDataForInstallStatsGrid(installRows);
    }
  }, [currentPlayersAsArray, playerInstallMetrics, customQuizCategoryTags]);

  const onRowClick = (id: string) => {
    setPlayer(
      currentPlayersAsArray.find((player: PlayerModel) => player.id === id)
    );
    setShowPlayerModal(true);
  };
  const onLessonRowClick = (id: string) => {
    history.push(`/dashboard/lessons/${id}`);
    setLessonId(id);
    setShowLessonDrillIn(true);
  };

  useEffect(() => {
    if (match.params.id) {
      setLessonId(match.params.id);
      setShowLessonDrillIn(true);
    } else {
      setShowLessonDrillIn(false);
    }
  }, [match.params.id]);

  const getCategoryName = (id: string) => {
    if (customQuizCategoryTags) {
      const existing = customQuizCategoryTags.find((lc) => lc.id === id);
      if (existing) {
        return existing.name;
      }
    }
    return "Uncategorized";
  };

  const columnsForPlayers = useMemo(
    () => [
      {
        name: "PLAYER",
        selector: (row: PlayerRow) => row.playerId,
        cell: (row: PlayerRow) => {
          return (
            <div
              className={`${dashboardStatsStyles.clickableCell} ${dashboardStatsStyles.flexRow}`}
              onClick={() => onRowClick(row.playerId)}
            >
              <div className={dashboardStatsStyles.usreListImg}>
                <Avatar userId={row.playerId} small type="player" />
              </div>

              <span className={dashboardStatsStyles.playerName}>
                {getAbbreviatedDisplayName(
                  row.playerFirstName,
                  row.playerLastName
                )}
              </span>
              {row.positions && (
                <div className={dashboardStatsStyles.playerPosition}>
                  {row.positions}
                </div>
              )}
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "LESSON READINESS",
        selector: (row: PlayerRow) => +row.readiness,
        format: (row: PlayerRow) => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [currentPlayersAsArray]
  );

  const columnsForLessons = useMemo(
    () => [
      {
        name: "Lesson",
        selector: (row: InstallRow) => row.installName,
        cell: (row: InstallRow) => {
          return (
            <div
              className={dashboardStatsStyles.clickableCell}
              onClick={() => onLessonRowClick(row.installId)}
            >
              <span className={dashboardStatsStyles.playerName}>
                {row.installName}
              </span>
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "CATEGORY",
        selector: (row: InstallRow) => row.categoryId,
        format: (row: InstallRow): string =>
          `${getCategoryName(row.categoryId)}`,
        sortable: true,
        right: true,
      },
      {
        name: "TEAM PERFORMANCE (ALL TIME)",
        selector: (row: InstallRow) => +row.readiness,
        format: (row: InstallRow): string => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [dataForInstallStatsGrid]
  );

  const openResetScoresModal = () => {
    if (currentTeam && currentTeam.id) {
      dispatchModal({
        title: "Reset Lesson Scores for this Team",
        open: true,
        body: (
          <GenericConfirmationModal
            message="Are you sure you want to reset the team's performance for Lessons? Players will lose their progress on all Lessons, but Playbook & Quiz performance will not be affected. This may take up to 24 hours to reflect across all dashboards."
            confirmLabel="Reset Scores"
            cancelLabel="Cancel"
            actionCallback={async () => {
              try {
                await APIService.INSTALL_STATISTIC.RESET_SCORES(
                  currentTeam.id as string
                );
                const playerOverallMetrics = await APIService.DASHBOARD.GET_PLAYER_OVERALL_METRICS(
                  currentTeam.id as string
                );
                if (playerOverallMetrics) {
                  setPlayerOverallMetrics(
                    playerOverallMetrics.overallReadinessList
                  );
                }
                const playerInstallMetrics = await APIService.DASHBOARD.GET_PLAYER_INSTALL_METRICS(
                  currentTeam.id as string
                );
                if (playerInstallMetrics) {
                  setPlayerInstallMetrics(playerInstallMetrics);
                }
                dispatchToast({
                  type: "success",
                  message: "Scores reset.",
                });
              } catch (e) {
                dispatchToast({
                  type: "error",
                  message: "Error resetting scores.",
                });
              }
            }}
          />
        ),
      });
    }
  };

  return loading ? (
    <CircularSpinner />
  ) : !showLessonDrillIn ? (
    <div>
      <div className={mainStyles.dashboardHeader}>
        <p className={mainStyles.dashboardHeader__Title}>Lesson Readiness</p>
        <Button
          type={"button"}
          theme={"secondary"}
          size={"small"}
          onClick={openResetScoresModal}
        >
          Reset Lesson Scores
        </Button>
      </div>

      <div className={mainStyles.chartCardRow}>
        <ReadinessOverTime
          title="Team Lesson Readiness"
          xLabel="Date"
          yLabel="Readiness"
          ySymbol="%"
          data={
            teamMetricsOverTime
              ? [
                  {
                    id: "line1",
                    data: teamMetricsOverTime.map((t) => {
                      return {
                        x: t.created,
                        y: +t.installReadinessScore,
                      };
                    }),
                  },
                ]
              : []
          }
        />
        <OverallReadiness
          title="Lesson Readiness Distribution"
          type="Bar-Distribution"
          isLabel={true}
          xLabel="Ready"
          yLabel="% of Players"
          ySymbol="%"
          scoreType="installReadinessScore"
        />
        <OverallReadiness
          title="Lesson Readiness by Unit"
          type="Bar-Unit"
          xLabel="Unit"
          yLabel="Readiness"
          ySymbol="%"
          scoreType="installReadinessScore"
        />
      </div>

      <div className={mainStyles.searchAndFilterBtn}>
        <div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Search"
            id="search"
            size="x-small"
            icon={searchIcon}
            clearButton
            value={searchText}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
        </div>
        <div className={mainStyles.toggleTableData}>
          <ToggleSelect
            options={filtersForPlayersAndLessons}
            selectedOption={selectedFilterForPlayersAndLessons}
            background="#151724"
            borderColor="#151724"
            onChange={(newSelection1: string) => {
              setSelectedFilterForPlayersAndLessons(newSelection1);
            }}
          />
        </div>
        <div className={mainStyles.filterBtns}>
          {selectedFilterForPlayersAndLessons == "Players" ? (
            <ToggleSelect
              options={PositionFiltersMap.map((f) => f.label)}
              selectedOption={selectedFilter.label}
              background="#151724"
              borderColor="#151724"
              onChange={(label: string) => {
                const newFilter = PositionFiltersMap.find(
                  (f) => f.label == label
                );
                if (newFilter) {
                  setSelectedFilter(newFilter);
                }
              }}
            />
          ) : (
            <SingleSelect
              id="lessonCategory"
              placeholder="Category *"
              alwaysOpen={false}
              options={lessonCategories}
              className={mainStyles.selectCategories}
              onChange={(e) => {
                setSelectedCategory(e.currentTarget.value);
              }}
            />
          )}
        </div>
      </div>
      <br />
      {selectedFilterForPlayersAndLessons == "Players" ? (
        <>
          <div>
            <DashboardStatsGrid
              data={dataForPlayerStatsGrid?.filter(
                playersFilter({
                  searchString: searchText,
                  filterByPosition: selectedFilter.value,
                })
              )}
              columns={columnsForPlayers}
            />
          </div>
          {player && (
            <PlayerModal
              player={player}
              visible={showPlayerModal}
              defaultTab={"lessons"}
              close={() => setShowPlayerModal(false)}
            />
          )}
        </>
      ) : (
        <div>
          <DashboardStatsGrid
            data={dataForInstallStatsGrid?.filter((m) => {
              return (
                (selectedCategory ? m.categoryId == selectedCategory : true) &&
                (searchText
                  ? ("" + m.installName)
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase(), 0) !== -1
                  : true)
              );
            })}
            columns={columnsForLessons}
          />
        </div>
      )}
    </div>
  ) : (
    <LessonLessons
      lessonId={lessonId}
      close={() => history.push("/dashboard/lessons")}
    />
  );
};

export default withRouter(DashboardLessons);
