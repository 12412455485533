import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { InstallStatisticModel } from "../../../../generated/from-api/models/install-statistic.model";
import { API_BASE_URL } from "../../constants";

export const InstallStatisticService = {
  /**
   * Use to retrieve a list of InstallStatistics for a team.
   *
   */
  LIST: async function (teamId?: string): Promise<InstallStatisticModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.INSTALL_STATISTIC}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },
  /**
   * Use to create an Install Statistic. If the Install Statistic was not created, returns undefined.
   * Also updates an install statistic column on the related license_usage record
   * @param
   * @returns InstallStatisticModel | undefined
   */
  POST: async function (
    installStatisticData: Pick<
      InstallStatisticModel,
      | "installId"
      | "startedAt"
      | "currentProgress"
      | "completedAt"
      | "score"
      | "correct"
      | "incorrect"
    > & {
      questionsAnswered?: number;
    }
  ): Promise<InstallStatisticModel | undefined> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.INSTALL_STATISTIC}`,
        installStatisticData
      )
    ).data;
  },
  /**
   * Use to update a Install Statistic, and also update an install statistic column on the related license_usage record
   * @param
   */
  PUT: async function (
    installStatisticData: Pick<
      InstallStatisticModel,
      | "id"
      | "currentProgress"
      | "completedAt"
      | "score"
      | "correct"
      | "incorrect"
    > & {
      questionsAnswered?: number;
    }
  ): Promise<InstallStatisticModel | undefined> {
    return (
      await axios.put(
        `${API_BASE_URL}${APIRoutes.INSTALL_STATISTIC}`,
        installStatisticData
      )
    ).data;
  },

  /**
   * Reset the team's scores
   * @param teamId
   * @returns
   */
  RESET_SCORES: async function (
    teamId: string
  ): Promise<InstallStatisticModel[] | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.INSTALL_STATISTIC}/reset`, {
        teamId,
      })
    ).data;
  },
};
