import { useEffect, useState } from "react";
import styles from "./Tabs.module.scss";

interface Props {
  children: string[];
  onSelect: (selection: string) => void;
  // Switch tabs on the UI based on selectedTab prop
  selectedTab?: string;
  defaultTab?: string;
  theme?: "primary" | "secondary";
}

const Tabs: React.FC<Props> = ({
  children,
  onSelect,
  theme = "primary",
  defaultTab = children[0],
  selectedTab = children[0],
}) => {
  const [selection, setSelection] = useState<string>(defaultTab);

  useEffect(() => {
    if (!!selectedTab) {
      setSelection(selectedTab);
    }
  }, [selectedTab]);

  const handleSelection = (selection: string) => {
    setSelection(selection);
    onSelect(selection);
  };

  return (
    <div className={`${styles.tabs} ${styles[theme]}`}>
      {children.map((label) => (
        <button
          className={`${styles.tab} ${
            selection === label ? styles.active : ""
          }`}
          type="button"
          key={label}
          onClick={() => handleSelection(label)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
