import React, { useContext, useEffect, useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import InstallBuilder from "./install-components/InstallBuilder/InstallBuilder";
import InstallsListItem from "./install-components/ListItem";
// import FeaturedInstalls from "./install-components/FeaturedInstalls";
import ArchiveItem from "./install-components/ArchiveItem";
import { InstallModel } from "../../generated/from-api/models/install.model";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { userRoles } from "../../shared/shared-with-mobile/constants";
import { useHistory, useParams } from "react-router-dom";
import { InstallsContext } from "../../shared/shared-with-mobile/providers/installs.provider";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import search from "../../resources/images/search.svg";

import styles from "./Installs.module.scss";
import ImportInstallsModal from "./install-components/InstallImportModal/ImportInstallsModal";
import { INSTALL_SETTINGS } from "../../utils/web-only-constants";
import InstallPreview from "./install-components/InstallPreview/InstallPreview";
import ContentLimitModal from "../../components/ContentLimitModal/ContentLimitModal";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import QuizCategoriesModal from "../Quizzes/quiz-components/QuizCategoriesModal/QuizCategoriesModal";
import CharacterInput from "../../components/Input/CharacterInput";
import { TagsContext } from "../../shared/shared-with-mobile/providers/tags.provider";
import ListIcon from "../../resources/icons/ListIcon";
import GearIcon from "../../resources/icons/GearIcon";
import Button from "../../components/Button/Button";
import PhonePreview from "../../components/PhonePreview/PhonePreview";
import Tooltip from "../../components/Tooltip/Tooltip";
import { UserProfileModel } from "../../generated/from-api/models/user-profile.model";

const Installs: React.FC = () => {
  const history = useHistory();
  const { userProfile, updateProfile } = useContext(UserContext);
  const { dispatchModal, handleCreateError } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);
  const { customQuizCategoryTags } = useContext(TagsContext);
  const {
    installs,
    globalInstalls,
    installStats,
    addInstall,
    updateInstall,
    areInstallTooltipsTemporarilyDismissed,
  } = useContext(InstallsContext);
  const [install, setInstall] = useState<InstallModel | null>(null);
  const { id: installId } = useParams<{ id: string }>();
  const { URLS, LABELS } = INSTALL_SETTINGS;
  const [searchString, setSearchString] = useState("");
  const [filterCategories, setFilterCategories] = useState([]);
  const [focusedSlideIndex, setFocusedSlideIndex] = useState<number>(0);

  const isAdmin = userProfile?.role === userRoles.ADMIN;

  const createLessonContainerRef = useRef<null | HTMLDivElement>(null);

  // for top
  const executeScrollForStep2 = () =>
    createLessonContainerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });

  // scroll to step-2
  useEffect(() => {
    executeScrollForStep2();
  }, [userProfile?.tooltipsInfo?.lessonsToolTip === 2]);

  useEffect(() => {
    const matchInstall = installs.find((install) => install.id === installId);
    if (matchInstall) {
      setInstall(matchInstall);
    } else {
      setInstall(null);
    }
  }, [installs, installId]);

  const saveInstall = async (newInstallObject: InstallModel) => {
    try {
      await APIService.INSTALL.PUT(newInstallObject);
    } catch (e) {
      console.log(`Error updating ${LABELS.singular_capitalized}`, e);
    }
  };

  const debouncedSaveInstall = useRef(
    debounce((newInstallObject: InstallModel) => {
      saveInstall(newInstallObject);
    }, 2000)
  );

  const guideFlowComplete = async () => {
    if (!userProfile) {
      return;
    }
    // lessonsToolTip: 0 means all the nodes are visited no need to go forward
    const updatedUserProfile: UserProfileModel = {
      ...userProfile,
      tooltipsInfo: { ...userProfile.tooltipsInfo, lessonsToolTip: 0 },
    } as UserProfileModel;

    const updated = await APIService.USER_PROFILE.PUT(
      userProfile.id,
      updatedUserProfile
    );

    if (updated) {
      updateProfile(updated);
    }
  };

  const userIsAdmin = userProfile?.role === userRoles.ADMIN;
  const installRouteName = userIsAdmin ? URLS.admin : URLS.generic;

  const openInstallLibrary = () => {
    dispatchModal({
      open: true,
      size: "large",
      body: <ImportInstallsModal />,
    });
  };

  const createNewInstall = async () => {
    const blankInstall: InstallModel = {
      teamId: currentTeam?.id,
      name: "",
      categoryTagId: "",
      showInstallToPositions: "",
      slides: [],
      autoInclude: false,
      dueDate: null,
      archived: false,
      published: false,
      deleted: false,
      deletedAt: "",
    };
    try {
      const response = await APIService.INSTALL.POST([blankInstall]);
      if (response && response[0]) {
        const installObjectFromApi = response[0];
        addInstall(installObjectFromApi);
        history.push(`/${installRouteName}/${installObjectFromApi.id}`);
      }
    } catch (error) {
      handleCreateError(error, ContentLimitModal, "lesson", "lessons");
    }
  };

  const validateAndUpdateInstall = (install: InstallModel) => {
    const newInstallObject = cloneDeep(install);
    updateInstall(newInstallObject);
    debouncedSaveInstall.current(newInstallObject);
  };

  return (
    <div className={styles.installsPage}>
      {install && (
        <div className={styles.installsPageBuilderPreviewContainer}>
          <div className={styles.installsPageBuilderContainer}>
            <div className={styles.installsPageBuilderWrapper}>
              <InstallBuilder
                install={install}
                updateInstall={validateAndUpdateInstall}
                currentRoute={installRouteName}
                userIsAdmin={userIsAdmin}
                onSlidefocus={setFocusedSlideIndex}
              />
            </div>
          </div>

          {/* Preview Section */}
          <div className={styles.installPreviewContainer}>
            <div className={styles.installPreviewContainerPreviewHeader}>
              {LABELS.singular_capitalized} Preview
            </div>
            <PhonePreview containerClass={styles.installPreviewContainer}>
              <InstallPreview
                slides={install.slides}
                key={install.slides.length.toString()}
                focusedSlideIndex={focusedSlideIndex}
              />
            </PhonePreview>
          </div>
        </div>
      )}

      {!install && (
        <>
          <div className={"pageHeader"}>
            <h3 className="pageTitle">{LABELS.multiple_capitalized}</h3>
            <div
              className={styles.installsPageHeaderButtons}
              ref={createLessonContainerRef}
            >
              {!isAdmin &&
              !areInstallTooltipsTemporarilyDismissed &&
              userProfile?.tooltipsInfo?.lessonsToolTip === 2 ? (
                <Tooltip
                  tip={
                    <p>
                      You can redownload any of these lessons by accessing the
                      Team Nation Library here.
                      <br />
                      <span className={`${styles.tooltipStep2Footer}`}>
                        <Button
                          size="x-small"
                          onClick={() => guideFlowComplete()}
                        >
                          Got it
                        </Button>
                      </span>
                    </p>
                  }
                  placement="top-start"
                  defaultOpen={true}
                  showPermanent={true}
                >
                  {currentTeam && (
                    <Button
                      theme="secondary"
                      size="small"
                      icon={<ListIcon />}
                      className={styles.libraryButton}
                      onClick={openInstallLibrary}
                    >
                      Library
                    </Button>
                  )}
                </Tooltip>
              ) : (
                currentTeam && (
                  <Button
                    theme="secondary"
                    size="small"
                    icon={<ListIcon />}
                    className={styles.libraryButton}
                    onClick={openInstallLibrary}
                  >
                    Library
                  </Button>
                )
              )}
              <Button theme="primary" size="small" onClick={createNewInstall}>
                {`Create ${LABELS.singular_capitalized}`}
              </Button>
            </div>
          </div>

          {/* {globalInstalls.length > 0 ? (
            <FeaturedInstalls
              featured={globalInstalls.filter((e) => !e.archived)}
            />
          ) : null} */}
          <div className={styles.installsPageFilterBar}>
            <div className={styles.installsPageFilterBarCategoryCombo}>
              <MultiSelect
                options={customQuizCategoryTags.map((tag) => {
                  return {
                    label: tag.name as string,
                    value: tag.id as string,
                  };
                })}
                placeholder="All Categories"
                onChange={(e) => {
                  setFilterCategories((e.currentTarget as any).selectedList);
                }}
              />
              <Button
                theme="transparent"
                icon={<GearIcon />}
                onClick={() => {
                  dispatchModal({
                    body: <QuizCategoriesModal />,
                    size: "medium",
                    open: true,
                  });
                }}
              />
            </div>

            <CharacterInput
              placeholder={"Search"}
              value={searchString}
              maxLength={255}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              icon={search}
            />
          </div>

          <div
            className={`
            tableHeader
            ${styles.installsPageListHeader}
            ${userProfile?.role === "ADMIN" ? "simplified-admin" : ""}
          `}
          >
            <span>{LABELS.singular_capitalized}</span>
            <span>Category</span>
            {userProfile?.role !== "ADMIN" ? <span>Avg Score</span> : null}
            {userProfile?.role !== "ADMIN" ? <span>Participation</span> : null}
            <span>Created</span>
            {userProfile?.role !== "ADMIN" ? <span>Due Date</span> : null}
            <span></span>
          </div>
          <ul className={`${styles.installsPageListActual}`}>
            {installs
              .filter((install) => {
                // Hide Deleted and ones that are not for the team
                if (
                  install.deleted ||
                  (userProfile?.role !== "ADMIN" && install.teamId === null) ||
                  install.archived === true
                ) {
                  return false;
                }

                // If Searching hide if name does not contain search (case insensitive)
                if (
                  searchString !== "" &&
                  install.name
                    .toLowerCase()
                    .search(
                      new RegExp(
                        searchString
                          .toLowerCase()
                          .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
                        "g"
                      )
                    ) < 0
                ) {
                  return false;
                }

                // If category selected and has category
                if (
                  filterCategories.length > 0 &&
                  filterCategories.indexOf(install.categoryTagId as never) < 0
                ) {
                  return false;
                }

                return true;
              })
              .map((install, index) => {
                return (
                  <InstallsListItem
                    key={`install-list-item-${install.id}`}
                    install={install}
                    stats={installStats.filter(
                      (e) => e.installId === install.id
                    )}
                    showGuidingFlow={
                      index === 0 &&
                      globalInstalls.map((i) => i.name).includes(install.name)
                    }
                  />
                );
              })}
          </ul>

          <div
            className={`tableHeader ${styles.installsPageArchiveHeader} ${
              userProfile?.role === "ADMIN" ? "simplified-admin" : ""
            }`}
          >
            <span>Archived {LABELS.multiple_capitalized}</span>
            {userProfile?.role !== "ADMIN" ? <span>Avg Score</span> : null}
            {userProfile?.role !== "ADMIN" ? <span>% Finished</span> : null}
            {userProfile?.role !== "ADMIN" ? <span>Finished</span> : null}
            {userProfile?.role !== "ADMIN" ? <span>Assigned</span> : null}
            <span>Created</span>
            {userProfile?.role === "ADMIN" ? <span>Last Mod.</span> : null}
            {userProfile?.role !== "ADMIN" ? <span>DueDate</span> : null}
            {userProfile?.role !== "ADMIN" ? <span>Positions</span> : null}
            <span></span>
          </div>
          <ul className={`${styles.installsPageArchiveActual}`}>
            {(() => {
              let foundInstall = false;
              const displayInstalls = installs.map((install) => {
                if (install.archived) {
                  foundInstall = true;
                  return (
                    <ArchiveItem
                      key={`archive-item-${install.id}`}
                      data={install}
                      stats={installStats.filter(
                        (e) => e.installId === install.id
                      )}
                    />
                  );
                }
              });
              if (foundInstall) {
                return displayInstalls;
              } else {
                return (
                  <li className={`${styles.installsPageArchiveNoItems}`}>
                    Archived {LABELS.multiple} will appear here
                  </li>
                );
              }
            })()}
          </ul>
        </>
      )}
    </div>
  );
};

export default Installs;
