import * as React from "react";

const ToolbarItalicIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="12"
    viewBox="0 0 10 12"
  >
    <path d="M3.33333 0.166748V2.66675H5.175L2.325 9.33342H0V11.8334H6.66667V9.33342H4.825L7.675 2.66675H10V0.166748H3.33333Z" />
  </svg>
);

export default ToolbarItalicIcon;
