import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import styles from "./PlaybookSettings.module.scss";
import backArrow from "../../resources/images/arrowLeft.svg";
import FormationsTab from "./tabs/FormationsTab/FormationsTab";
import SchemesTab from "./tabs/SchemesTab/SchemesTab";
import TagsTab from "./tabs/TagsTab/TagsTab";
import { TagTypeEnum } from "../../generated/from-api/models/enums/tag-type.enum";
import { userRoles } from "../../shared/shared-with-mobile/constants";

enum TabNames {
  offensiveFormation = "Offensive Formation",
  defensiveFront = "Defensive Front",
  subcategory = "Subcategory",
  personnel = "Personnel",
  situation = "Situation",
  blitz = "Blitz",
  coverage = "Coverage",
}

const PlaybookSettings: React.FC = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<TabNames>(
    TabNames.offensiveFormation
  );
  const { userProfile } = useContext(UserContext);

  const play = history.location.state as any;

  useEffect(() => {
    if (play && play.type === "Defensive") {
      setSelectedTab(TabNames.defensiveFront);
    }
  }, [play]);

  return (
    <div className={styles.playbookSettings}>
      <div className={styles.header}>
        <img
          src={backArrow}
          alt="back arrow icon"
          role="button"
          onClick={() => {
            if (userProfile?.role === userRoles.ADMIN) {
              history.push("/global-playbook");
            } else {
              history.push("/playbook");
            }
          }}
        />
        <div>Playbook Settings</div>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.sidebar}>
          {Object.values(TabNames).map((tabName: TabNames) => (
            <div
              key={tabName}
              className={`
                ${styles.tab}
                ${selectedTab === tabName ? styles.selected : ""}
              `}
              onClick={() => {
                setSelectedTab(tabName);
              }}
            >
              {tabName}
            </div>
          ))}
        </div>
        {selectedTab === TabNames.offensiveFormation && (
          <FormationsTab type="Offensive" />
        )}
        {selectedTab === TabNames.defensiveFront && (
          <FormationsTab type="Defensive" />
        )}
        {selectedTab === TabNames.subcategory && <SchemesTab />}
        {selectedTab === TabNames.personnel && (
          <TagsTab tagType={TagTypeEnum.PERSONNEL_PACKAGE} />
        )}
        {selectedTab === TabNames.situation && (
          <TagsTab tagType={TagTypeEnum.SITUATION} />
        )}
        {selectedTab === TabNames.blitz && (
          <TagsTab tagType={TagTypeEnum.BLITZ} />
        )}
        {selectedTab === TabNames.coverage && (
          <TagsTab tagType={TagTypeEnum.COVERAGE} />
        )}
      </div>
    </div>
  );
};

export default PlaybookSettings;
