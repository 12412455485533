import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import { auth } from "../api-client/firebase.utils";
import { UserProfileModel } from "../../../generated/from-api/models/user-profile.model";
import { APIService } from "../api-client/api.service";

// Global User Context
export const UserContext = React.createContext<{
  currentUser: undefined | null | firebase.User;
  userProfile: undefined | null | UserProfileModel;
  updateProfile: (profile: UserProfileModel) => void;
  isLoggingOut: boolean;
  setIsLoggingOut: (loggingOut: boolean) => void;
}>({
  currentUser: undefined,
  userProfile: undefined,
  updateProfile: () => null,
  isLoggingOut: false,
  setIsLoggingOut: () => null,
});

export const UserProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<
    undefined | null | firebase.User
  >(undefined);
  const [userProfile, setUserProfile] = useState<
    undefined | null | UserProfileModel
  >(undefined);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (
        user &&
        (user.emailVerified || !user.emailVerified || user.phoneNumber)
      ) {
        setCurrentUser(user);
        setIsLoggingOut(false);
      } else {
        setIsLoggingOut(true);
        setCurrentUser(null);
        setUserProfile(undefined);
      }
    });
  }, []);

  useEffect(() => {
    const loadUser = async () => {
      if (currentUser && (currentUser.email || currentUser.phoneNumber)) {
        const profile = await APIService.USER_PROFILE.GET(currentUser.uid);

        setUserProfile(profile);
      } else {
        setUserProfile(undefined);
      }
    };
    loadUser();
  }, [currentUser]);

  const updateProfile = (profile: UserProfileModel) => {
    setUserProfile(profile);
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        userProfile,
        updateProfile,
        isLoggingOut,
        setIsLoggingOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
