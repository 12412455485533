import * as React from "react";

const SunglowPlusIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="#FFE42A"
    />
    <path
      d="M13 8.64286H8.64286V13H7.35714V8.64286H3V7.35714H7.35714V3H8.64286V7.35714H13V8.64286Z"
      fill="#151724"
    />
  </svg>
);

export default SunglowPlusIcon;
