import React, { useState, useContext, useEffect } from "react";
import { DateTime } from "luxon";
import styles from "./CreateGamePlan.module.scss";

import { PlayModel } from "../../../generated/from-api/models/play.model";
import CharacterInput from "../../../components/Input/CharacterInput";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Button from "../../../components/Button/Button";
import DatePickerInput from "../../../components/DatePickerInput/DatePickerInput";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { GamePlansContext } from "../../../shared/shared-with-mobile/providers/gamePlans.provider";
import ContentLimitModal from "../../../components/ContentLimitModal/ContentLimitModal";

interface Props {
  selectedPlaySets: Map<string, PlayModel>;
  selectedPlays: Map<string, PlayModel>;
  clearSelectedPlaysAndPlaySets: () => void;
  gamePlanId?: string;
  clearGamePlanFilter?: () => void;
}

interface GamePlanDataProps {
  teamId: string;
  name: string;
  displayOnMenu: boolean;
  expirationDate: string | null;
  plays: string[];
  playSets: string[];
}

const CreateGamePlan: React.FC<Props> = ({
  selectedPlays,
  selectedPlaySets,
  clearSelectedPlaysAndPlaySets,
  gamePlanId,
  clearGamePlanFilter,
}) => {
  const endOfYear = DateTime.now().endOf("year").toString();
  const { closeModal } = useContext(UIContext);
  const { gamePlans, addGamePlan, updateGamePlan, removeGamePlan } = useContext(
    GamePlansContext
  );
  const { currentTeam } = useContext(TeamContext);
  const { dispatchToast, handleCreateError } = useContext(UIContext);

  const selectedGamePlan = gamePlanId
    ? gamePlans.filter((gamePlan) => {
        return gamePlan.id === gamePlanId;
      })[0]
    : undefined;

  const [gamePlanData, setGamePlanData] = useState<GamePlanDataProps>({
    teamId: currentTeam?.id || "",
    name: selectedGamePlan?.name || "",
    displayOnMenu: selectedGamePlan?.displayOnMenu || false,
    expirationDate: selectedGamePlan?.expirationDate || null,
    plays: Array.from(selectedPlays.keys()),
    playSets: Array.from(selectedPlaySets.keys()),
  });

  const [showDatePicker, setShowDatePicker] = useState<boolean>(
    !!gamePlanData.expirationDate || false
  );

  useEffect(() => {
    if (showDatePicker) {
      setGamePlanData({
        ...gamePlanData,
        expirationDate: gamePlanData.expirationDate || endOfYear,
      });
    } else {
      setGamePlanData({ ...gamePlanData, expirationDate: null });
    }
  }, [showDatePicker]);

  const selectExpirationDate = (event: MaterialUiPickersDate) => {
    if (event) {
      setGamePlanData({ ...gamePlanData, expirationDate: event.toString() });
    }
  };

  const deleteGamePlan = async () => {
    if (selectedGamePlan && gamePlanId) {
      const deletedGamePlan = await APIService.GAME_PLAN.DELETE(gamePlanId);
      if (deletedGamePlan && deletedGamePlan.deleted) {
        removeGamePlan(gamePlanId);
        clearSelectedPlaysAndPlaySets();
        clearGamePlanFilter && clearGamePlanFilter();
        closeModal();
      }
    }
  };

  const submitGamePlan = async () => {
    if (selectedGamePlan && gamePlanId) {
      const updatedGamePlanData = gamePlanData;
      const existingGamePlan = await APIService.GAME_PLAN.GET(gamePlanId);

      updatedGamePlanData.plays = existingGamePlan.plays
        .map((play) => {
          return play.id || "";
        })
        .filter((play) => {
          return play.length > 0;
        });
      updatedGamePlanData.playSets = existingGamePlan.playSets
        .map((playSet) => {
          return playSet.id || "";
        })
        .filter((playSet) => {
          return playSet.length > 0;
        });

      const updated = await APIService.GAME_PLAN.PUT(
        gamePlanId,
        updatedGamePlanData
      );
      if (updated) {
        const updatedGamePlan = await APIService.GAME_PLAN.GET(gamePlanId);
        if (updatedGamePlan) {
          updateGamePlan(updatedGamePlan);
        }
        dispatchToast({
          type: "success",
          message: `${selectedGamePlan.name} play group updated.`,
        });
        clearSelectedPlaysAndPlaySets();
        closeModal();
      }
    } else {
      try {
        const created = await APIService.GAME_PLAN.POST(gamePlanData);
        if (created) {
          addGamePlan(created);
          dispatchToast({
            type: "success",
            message: "New play group saved.",
          });
          clearSelectedPlaysAndPlaySets();
          closeModal();
        }
      } catch (error) {
        handleCreateError(
          error,
          ContentLimitModal,
          "play group",
          "play groups"
        );
      }
    }
  };

  return (
    <div className={styles.createGamePlanContainer}>
      <CharacterInput
        placeholder="Name of Play Group"
        id="gamePlan-name"
        value={gamePlanData.name}
        onChange={(e) =>
          setGamePlanData({ ...gamePlanData, name: e.currentTarget.value })
        }
      />
      {!!selectedPlays.size && (
        <span className={`${styles.subText} ${styles.playsSelected}`}>
          {selectedPlays.size} PLAY{selectedPlays.size > 1 ? "S" : ""} SELECTED
        </span>
      )}
      <span className={styles.checkBoxes}>
        <Checkbox
          id="display-game"
          onChange={(e) =>
            setGamePlanData({
              ...gamePlanData,
              displayOnMenu: e.currentTarget.checked,
            })
          }
          checked={gamePlanData.displayOnMenu}
        >
          Display on game menu
        </Checkbox>
      </span>
      <span className={styles.checkBoxes}>
        <Checkbox
          id="expiration-date"
          onChange={(e) => setShowDatePicker(e.currentTarget.checked)}
          checked={!!gamePlanData.expirationDate}
        >
          Set expiration date
        </Checkbox>
      </span>
      <span className={styles.subText}>
        Expired play groups are hidden from players
      </span>
      {showDatePicker && (
        <div className={styles.datePickerCtn}>
          <DatePickerInput
            label="Expiration Date"
            onChange={(event) => selectExpirationDate(event)}
            value={gamePlanData.expirationDate || endOfYear}
            format="DD"
            theme="dark"
          />
        </div>
      )}
      <div className={styles.btnContainer}>
        <div>
          {selectedGamePlan && (
            <Button
              size="small"
              theme="secondary"
              onClick={() => deleteGamePlan()}
              destructive
            >
              Delete
            </Button>
          )}
        </div>
        <div>
          <Button theme="secondary" size={"small"} onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            className={styles.createBtn}
            size={"small"}
            onClick={() => submitGamePlan()}
            disabled={!gamePlanData.name}
          >
            {selectedGamePlan ? "Update Play Group" : "Create Play Group"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateGamePlan;
