import { CustomQuizModel } from "../generated/from-api/models/custom-quiz.model";
import { MediaModel } from "../generated/from-api/models/media.model";
import { CustomQuizUtility } from "../generated/from-api/shared-utilities/custom-quiz.utility";

const validateQuizAndGetErrorMessage = (
  quiz: CustomQuizModel,
  mediaItems: MediaModel[]
): { isValid: boolean; errorMessage: string } => {
  const validityObject = CustomQuizUtility.isQuizValid(quiz, mediaItems);
  let errorMessage = "";

  const {
    deletedMediaErrors,
    cardSwipingGameErrors,
    multipleChoiceGameErrors,
    generalErrors,
  } = validityObject;

  if (deletedMediaErrors.containsDeletedMedia) {
    errorMessage = "Quiz contains deleted media.";
  } else if (cardSwipingGameErrors.insufficentUniqueDefinitions) {
    const num = cardSwipingGameErrors.numberOfAdditionalUniqueDefinitionsNeeded;
    errorMessage = `Please add ${num} more unique definition(s).`;
  } else if (cardSwipingGameErrors.questionsMissingACorrectOption.length) {
    errorMessage = "Each term requires at least one definition.";
  } else if (multipleChoiceGameErrors.questionsMissingACorrectOption.length) {
    const num = multipleChoiceGameErrors.questionsMissingACorrectOption.length;
    errorMessage = `${num} question(s) are missing a correct answer.`;
  } else if (
    multipleChoiceGameErrors.questionsMissingAnIncorrectOption.length
  ) {
    const num =
      multipleChoiceGameErrors.questionsMissingAnIncorrectOption.length;
    errorMessage = `${num} question(s) are missing an incorrect answer.`;
  } else if (generalErrors.quizIsArchived) {
    errorMessage = "Quiz is archived.";
  } else if (generalErrors.questionsWithBlankOptions.length) {
    const num = generalErrors.questionsWithBlankOptions.length;
    errorMessage = `Please fix ${num} empty definition(s).`;
  } else if (generalErrors.questionsWithBlankTerms.length) {
    const num = generalErrors.questionsWithBlankTerms.length;
    const userFacingQuestionLabel = quiz.autoGenerateQuestions
      ? "term"
      : "question";
    errorMessage = `Please fix ${num} empty ${userFacingQuestionLabel}(s).`;
  }

  return {
    isValid: validityObject.valid,
    errorMessage,
  };
};

export default validateQuizAndGetErrorMessage;
