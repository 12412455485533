import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { CoachModel } from "../../../../generated/from-api/models/relationships/coach.model";
import { API_BASE_URL } from "../../constants";

export const CoachService = {
  /**
   * Retrieve a list of all coaches for a team, if the user has permission to view the coaches
   * @param teamId
   * @returns Coach[]
   */
  GET_ALL_COACHES_FOR_TEAM: async function (
    teamId: string
  ): Promise<CoachModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.COACH}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   * Retrieve a coach by id, if the user has permission to view the coach
   * @param coachId
   * @returns Coach
   */
  GET: async function (coachId: string): Promise<CoachModel> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.COACH}/${coachId}`))
      .data;
  },

  /**
   * Update a coach, if the user has permission to do so
   * @param coachId
   * @param dataToUpdate
   * @returns Coach
   */
  PUT: async function (
    coachId: string,
    dataToUpdate: Pick<CoachModel, "firstName" | "lastName" | "role">
  ): Promise<CoachModel> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.COACH}`, {
        id: coachId,
        ...dataToUpdate,
      })
    ).data;
  },

  // POST - intentionally left blank, coaches are created by the system when invitations are accepted, or when teams are created

  /**
   * Delete a coach by id, if the user has permission to do so
   * @param coachId
   * @returns
   */
  DELETE: async function (coachId: string): Promise<{ deleted: boolean }> {
    return (
      await axios.delete(`${API_BASE_URL}${APIRoutes.COACH}/${coachId}`, {
        params: { id: coachId },
      })
    ).data;
  },
};
