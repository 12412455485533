import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import styles from "./LoginSignup.module.scss";
import Logo from "../../resources/images/Logo.svg";
import { parseQuery } from "../../utils/functions";
import Button from "../../components/Button/Button";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";

const EmailVerification: React.FC<RouteComponentProps> = ({ history }) => {
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  useEffect(() => {
    const verifyEmail = async (token: string, email: string) => {
      const response = await APIService.USER.VERIFY_EMAIL(token, email);
      setIsVerified(response.verified);
      setIsExpired(response.expired);
    };

    const search = parseQuery(history.location.search.replace("?", "")) as {
      token: string;
      email: string;
    };
    verifyEmail(search.token, encodeURIComponent(search.email));
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (isVerified) {
      history.push("/login");
    } else {
      history.push("/signup");
    }
  };

  return (
    <div className={styles.login}>
      <section className={styles.content}>
        <img src={Logo} alt="Team Nation" className={styles.logo} />
        {isVerified !== null ? (
          <form className={styles.form} onSubmit={handleSubmit}>
            {isVerified ? (
              <>
                <div className={styles.title}>Email verification complete!</div>
                <p className={styles.body}>
                  Click the button below to continue on to login.
                </p>
              </>
            ) : isExpired ? (
              <>
                <div className={styles.title}>
                  Your verification token has expired!
                </div>
                <p className={styles.body}>
                  Check your inbox for a new link to verify your email address.
                </p>
              </>
            ) : (
              <>
                <div className={styles.title}>Email not verified!</div>
                <p className={styles.body}>
                  Click the button below to sign up.
                </p>
              </>
            )}
            {!isExpired && (
              <Button size={"small"} buttonType={"submit"}>
                Continue
              </Button>
            )}
          </form>
        ) : (
          <div>Verifying...</div>
        )}
      </section>
    </div>
  );
};

export default EmailVerification;
