import pageStyles from "../../Dashboard.module.scss";
import styles from "./HomeGrid.module.scss";

type Cell = string | React.ReactNode;

interface Props {
  title?: string;
  columnHeaders: string[];
  rows: Cell[][];
}

const HomeGrid: React.FC<Props> = ({ title, rows, columnHeaders }) => {
  return (
    <div className={pageStyles.dashboardCard}>
      <div className={styles.cardSubtitle}>{title}</div>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            {columnHeaders.map((str, index) => (
              <th key={index}>{str}</th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, columnIndex) => (
                <td key={columnIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HomeGrid;
