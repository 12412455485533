import { useContext } from "react";

import { PlayModel } from "../../../generated/from-api/models/play.model";
import styles from "./MediaPlayThumbnail.module.scss";
import { MediaContext } from "../../../shared/shared-with-mobile/providers/media.provider";

interface Props {
  play: PlayModel;
}

const MediaPlayThumbnail: React.FC<Props> = ({ play }) => {
  const { mediaItemsMap } = useContext(MediaContext);
  const placeholder = (
    <div className={styles.mediaPlayThumbnail}>Loading...</div>
  );

  if (!play.mediaId) {
    return placeholder;
  }

  const mediaItem = mediaItemsMap[play.mediaId];

  if (!mediaItem) {
    return placeholder;
  }

  return (
    <div className={styles.mediaPlayThumbnail}>
      <img alt={`play image for ${play.name}`} src={mediaItem.url} />
    </div>
  );
};

export default MediaPlayThumbnail;
