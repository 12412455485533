import styles from "./MediaGalleryThumbnail.module.scss";
import { MediaModel } from "../../../generated/from-api/models/media.model";
import Checkbox from "../../Checkbox/Checkbox";
import { ChangeEvent } from "react";
import VideoIcon from "../../../resources/images/yellowPlayVideoIcon.svg";
import AudioFileIcon from "../../../resources/images/audioFileIcon.svg";
import { generateDateString } from "../../../utils/functions";

interface Props {
  selected: boolean;
  mediaItem?: MediaModel;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTap: (item: MediaModel) => void;
  onDoubleTap: (item: MediaModel) => void;
  showCheckbox?: boolean;
  hideCheckbox?: boolean;
  previewMedia?: MediaModel;
}

const MediaGalleryThumbnail: React.FC<Props> = ({
  mediaItem,
  onChange,
  onTap,
  onDoubleTap,
  selected,
  showCheckbox = true,
  hideCheckbox = false,
  previewMedia = { id: undefined },
}) => {
  if (!mediaItem) {
    return null;
  }

  return (
    <>
      <div className={styles.thumbnailContainer}>
        {hideCheckbox ? (
          <div
            onClick={(e: any) => {
              e.preventDefault();
              onTap(mediaItem);
            }}
            onDoubleClick={(e: any) => {
              e.preventDefault();
              onDoubleTap(mediaItem);
            }}
            className={`${styles.thumbnailContents} ${
              selected || previewMedia.id == mediaItem.id ? styles.selected : ""
            } ${mediaItem.type === "AUDIO" ? styles.addBorder : ""}`}
          >
            {mediaItem.type === "IMAGE" && (
              <div className={styles.thumbnail}>
                <img
                  src={mediaItem.url}
                  alt={mediaItem.name}
                  className={styles.mediaImage}
                />
              </div>
            )}
            {mediaItem.type === "VIDEO" && (
              <>
                <div className={styles.thumbnail}>
                  <img
                    src={mediaItem.thumbnailUrl}
                    alt={mediaItem.name}
                    className={styles.mediaImage}
                  />
                  <img
                    src={VideoIcon}
                    alt="video icon"
                    className={styles.videoIcon}
                  />
                </div>
              </>
            )}
            {mediaItem.type === "AUDIO" && (
              <div className={styles.thumbnail}>
                <img
                  src={AudioFileIcon}
                  alt="audio file icon"
                  className={styles.audioIcon}
                />
              </div>
            )}
          </div>
        ) : (
          <Checkbox
            onChange={onChange}
            checked={selected}
            showCheckbox={showCheckbox}
            addExtraPadding
          >
            <div
              onClick={(e: any) => {
                e.preventDefault();
                onTap(mediaItem);
              }}
              onDoubleClick={(e: any) => {
                e.preventDefault();
                onDoubleTap(mediaItem);
              }}
              className={`${styles.thumbnailContents} ${
                selected || previewMedia.id == mediaItem.id
                  ? styles.selected
                  : ""
              } ${mediaItem.type === "AUDIO" ? styles.addBorder : ""}`}
            >
              {mediaItem.type === "IMAGE" && (
                <div className={styles.thumbnail}>
                  <img
                    src={mediaItem.url}
                    alt={mediaItem.name}
                    className={styles.mediaImage}
                  />
                </div>
              )}
              {mediaItem.type === "VIDEO" && (
                <>
                  <div className={styles.thumbnail}>
                    <img
                      src={mediaItem.thumbnailUrl}
                      alt={mediaItem.name}
                      className={styles.mediaImage}
                    />
                    <img
                      src={VideoIcon}
                      alt="video icon"
                      className={styles.videoIcon}
                    />
                  </div>
                </>
              )}
              {mediaItem.type === "AUDIO" && (
                <div className={styles.thumbnail}>
                  <img
                    src={AudioFileIcon}
                    alt="audio file icon"
                    className={styles.audioIcon}
                  />
                </div>
              )}
            </div>
          </Checkbox>
        )}
      </div>
      <div className={styles.mediaInfo}>{mediaItem.name}</div>
      <div className={styles.mediaInfo}>
        {generateDateString(mediaItem.fileDate)}
      </div>
    </>
  );
};

export default MediaGalleryThumbnail;
