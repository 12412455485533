export enum ReadinessTypeEnum {
  RUN = "Run",
  PASS = "Pass",
  DEFENSE = "Defense",
  SPECIAL_TEAMS = "SpecialTeams",
  OFFENSIVE_FORMATIONS = "OffensiveFormations",
  DEFENSIVE_FORMATIONS = "DefensiveFormations",
  OVERALL = "Overall",
  GAME_PLAN = "gamePlan",
}
