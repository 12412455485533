import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import LicenseCard from "../../../components/LicenseCard/LicenseCard";
import Button from "../../../components/Button/Button";

import { LicensesContext } from "../../../shared/shared-with-mobile/providers/licenses.provider";
import { LicenseTiersContext } from "../../../shared/shared-with-mobile/providers/licenseTiers";
import { LicenseTierModel } from "../../../../../api/src/shared-with-mobile-and-web/models/license-tier.model";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";

import styles from "../Account.module.scss";
import { OnboardingProgress } from "./AccountSetupModal/AccountSetupModal";
import { LICENSE_TIERS } from "../../../generated/from-api/api-constants/license-tier-constants";
import { DateTime } from "luxon";

interface Props {
  openAccountSetup: (initialProgress: OnboardingProgress) => void;
}

const SubscriptionsTab: React.FC<Props> = ({ openAccountSetup }) => {
  const { currentLicenses } = useContext(LicensesContext);
  const { licenseTiers } = useContext(LicenseTiersContext);
  const [licenseTier, setLicenseTier] = useState<
    LicenseTierModel | undefined
  >();
  const { teams, isSuspended } = useContext(TeamContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (currentLicenses && currentLicenses.length > 0 && licenseTiers) {
      const licenseTier = licenseTiers.filter((tier) => {
        return tier.id == currentLicenses[0].licenseTierId;
      })[0] as LicenseTierModel;

      setLicenseTier(licenseTier);
    }
  }, [currentLicenses, licenseTiers]);

  // if this page is linked to from the "LicenseExpirationModal" due to a suspended license,
  // then we should immediately open the License Renewal modal
  useEffect(() => {
    const locationState = location.state as any;

    if (locationState?.showRenewLicenseModal) {
      openAccountSetup(OnboardingProgress.LicenseRenewal);
      history.replace({ pathname: location.pathname, state: {} });
    }
  }, [location.state, history, location.pathname]);

  const openAccountSetupModalToStep = () => {
    // if user is not upgrading a license, then they are activating a new license
    const isUpgradingLicense = currentLicenses && currentLicenses.length > 0;

    // if user is upgrading a license, send them straight to License Activation (skipping the welcome screen of onboarding modal)
    const initialProgress = isUpgradingLicense
      ? OnboardingProgress.LicenseActivation
      : OnboardingProgress.LicenseWelcome;

    openAccountSetup(initialProgress);
  };

  const getLicenseExpirationDate = () => {
    // just get the first license if multiple exist (true for legacy licenses)
    const firstLicense = currentLicenses?.[0];

    if (!firstLicense?.expires) {
      return "Not found. If you believe this to be an error, please contact support.";
    }

    const isExpired =
      new Date().getTime() > new Date(firstLicense?.expires).getTime();

    return (
      <span className={isExpired ? styles.expiredLicense : ""}>
        {DateTime.fromISO(firstLicense.expires).toLocaleString()}
      </span>
    );
  };

  const shouldShowLicenseRenewalButton = () => {
    const firstLicense = currentLicenses?.[0];

    if (!firstLicense?.expires) {
      return false;
    }

    const licenseExpirationDate = new Date(firstLicense.expires);

    const today = new Date();

    const diffInMS = licenseExpirationDate.getTime() - today.getTime();
    const MS_IN_A_DAY = 1000 * 60 * 60 * 24;
    const diffInDays = Math.round(diffInMS / MS_IN_A_DAY);
    const numDaysFromExpirationWhenShowRenewButton = Math.round(365 / 2); // show it when ~6 months away

    if (diffInDays <= numDaysFromExpirationWhenShowRenewButton) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <h1>Subscription</h1>
      <LicenseCard
        className={styles.SubscriptsTabLicenseCard}
        licenseTier={licenseTier}
        teamsLimit={licenseTier?.maxTeams}
        availableLicensesCount={
          (licenseTier?.maxTeams || 0) - (teams?.size || 0)
        }
        coachesLimit={licenseTier?.maxCoaches}
        playersLimit={licenseTier?.maxPlayers}
        playsLimit={licenseTier?.maxPlays}
        gamifiedPlaysLimit={licenseTier?.maxGamifiedPlays}
        installsLimit={licenseTier?.maxInstalls}
        quizzesLimit={licenseTier?.maxCustomQuizzes}
      />
      {((currentLicenses && currentLicenses.length == 0) ||
        licenseTier?.tier === LICENSE_TIERS.FREE2023.name ||
        licenseTier?.tier === LICENSE_TIERS.BASIC2023.name ||
        licenseTier?.tier === LICENSE_TIERS.FREE.name ||
        licenseTier?.tier === LICENSE_TIERS.TRIAL.name ||
        licenseTier?.tier === LICENSE_TIERS.PREMIER.name) && (
        <>
          <Button
            theme="secondary"
            className={styles.SubscriptsTabUpdateButton}
            type="external link"
            externalLinkOptions={{
              href: "https://www.teamnationsports.com/pricing",
            }}
          >
            Buy a License
          </Button>
          <Button
            theme="primary"
            className={styles.SubscriptsTabActivateButton}
            onClick={openAccountSetupModalToStep}
          >
            Activate License
          </Button>
        </>
      )}

      {currentLicenses &&
        currentLicenses.length > 0 &&
        licenseTier?.tier !== LICENSE_TIERS.BASIC2023.name &&
        licenseTier?.tier !== LICENSE_TIERS.FREE2023.name &&
        licenseTier?.tier !== LICENSE_TIERS.FREE.name &&
        licenseTier?.tier !== LICENSE_TIERS.TRIAL.name &&
        licenseTier?.tier !== LICENSE_TIERS.PREMIER.name && (
          <>
            {shouldShowLicenseRenewalButton() && (
              <Button
                className={styles.renewLicenseBtn}
                theme="secondary"
                onClick={() =>
                  openAccountSetup(OnboardingProgress.LicenseRenewal)
                }
              >
                Renew License
              </Button>
            )}
            {isSuspended ? (
              <p className={styles.expiredLicense}>
                License Expired: {getLicenseExpirationDate()}
              </p>
            ) : (
              <p>License Expiration: {getLicenseExpirationDate()}</p>
            )}
            <p>
              Please contact support if you would like to upgrade your license.
            </p>
          </>
        )}
    </div>
  );
};

export default SubscriptionsTab;
