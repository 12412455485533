import React, { useEffect, useRef, useState } from "react";
import styles from "./SingleSelect.module.scss";
import { isNil } from "lodash";

export interface SingleSelectOption {
  label: string;
  value: number | string;
  disabled?: boolean;
  icon?: string;
  color?: string;
  selectedColor?: string;
}

interface Props {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: SingleSelectOption[];
  placeholder?: string;
  id?: string;
  name?: string;
  value?: string | number;
  theme?: "light" | "dark";
  size?: "large" | "medium" | "small" | "x-small";
  className?: string;
  alwaysOpen?: boolean;
}

const SingleSelect: React.FC<Props> = ({
  onChange,
  options,
  placeholder,
  id,
  name,
  value,
  className,
  theme = "dark",
  size = "medium",
  alwaysOpen = false,
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const optionRefs = useRef<any[]>([]);
  optionRefs.current = options.map(
    (ref, index) => (optionRefs.current[index] = React.createRef())
  );

  const [inputValue, setInputValue] = useState<string | number>(value ?? "");
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [isListOverflowing, setIsListOverflowing] = useState<boolean>(false);
  const [virtualSearch, setVirtualSearch] = useState<string>("");
  const [virtualOption, setVirtualOption] = useState<
    SingleSelectOption | undefined
  >(undefined);

  const updateVirtualSearch = (char: string) => {
    let nextVirtualSearch = virtualSearch + char;

    const wasFirstSearchFound = searchAndSelectVirtualOption(nextVirtualSearch);

    if (!wasFirstSearchFound) {
      // if no option was found, reset virtual search to be the last letter typed and search once more
      nextVirtualSearch = char;
      const wasSecondSearchFound = searchAndSelectVirtualOption(
        nextVirtualSearch
      );

      if (!wasSecondSearchFound) {
        setVirtualOption(undefined);
      }
    }

    setVirtualSearch(nextVirtualSearch);
  };

  const searchAndSelectVirtualOption = (str: string) => {
    const foundOptionIndex = options.findIndex((option) =>
      option.label.toLowerCase().startsWith(str)
    );
    let foundOption: SingleSelectOption | undefined;

    if (foundOptionIndex > -1) {
      foundOption = options[foundOptionIndex];
      setVirtualOption(foundOption);

      if (optionRefs?.current[foundOptionIndex]?.current) {
        optionRefs.current[foundOptionIndex].current.scrollIntoView();
      }
    }

    return !!foundOption;
  };

  useEffect(() => {
    if (!isListOpen) {
      setVirtualSearch("");
      setVirtualOption(undefined);
    }
  }, [isListOpen]);

  useEffect(() => {
    setIsListOpen(false);
  }, [inputValue]);

  useEffect(() => {
    if (typeof value === "string" || typeof value === "number") {
      setInputValue(value);
    }
  }, [value]);

  const handleChange = (newValue: string | number) => {
    setInputValue(newValue);
    onChange &&
      onChange({
        currentTarget: { name: name, value: newValue },
      } as React.ChangeEvent<HTMLInputElement>);
  };

  const inputIcon = () => {
    const icon = options.find((option) => option.value === inputValue)?.icon;
    if (icon) {
      return <img className={styles.selectedIcon} src={icon} />;
    }
    return "";
  };

  const inputColor = () => {
    const option = options.find((option) => option.value === inputValue);
    if (!option) return "";
    return option.selectedColor || option.color || "";
  };

  const isOverflown = ({
    clientWidth,
    clientHeight,
    scrollWidth,
    scrollHeight,
  }: any): boolean => {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  };

  useEffect(() => {
    setTimeout(() => {
      if (!isNil(listRef.current)) {
        const isListNodeOverflowing = isOverflown(listRef.current);
        setIsListOverflowing(isListNodeOverflowing);
      }
    }, 0);
  }, [listRef]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (!isListOpen) return;
    const keyCode = event.code;

    switch (keyCode) {
      case "Escape":
        setIsListOpen(false);
        break;
      case "Enter":
        if (virtualOption) handleChange(virtualOption?.value);
        setIsListOpen(false);
        break;
      default:
        updateVirtualSearch(event.key.toLowerCase());
    }
  };

  return (
    <label
      htmlFor={`select-${id}`}
      className={`
        ${styles.label}
        ${styles[theme]}
        ${styles[size]}
        ${inputValue ? styles.hasValue : ""}
        ${isListOpen || alwaysOpen ? styles.listOpen : ""}
        ${className ? className : ""}
        ${alwaysOpen ? styles.alwaysOpen : ""}
      `}
      onKeyDown={handleKeyDown}
    >
      {alwaysOpen && placeholder && (
        <div className={styles.selectLabel}>{placeholder}</div>
      )}
      {inputIcon()}
      <input
        type="text"
        name={name || ""}
        readOnly
        value={
          options.find((option) => option.value === inputValue)?.label || ""
        }
        placeholder={placeholder}
        className={`
          ${styles.selectedValue}
          ${alwaysOpen ? styles.hide : ""}
          ${inputIcon() ? styles.valueIcon : ""}
        `}
        style={{ color: inputColor() }}
        tabIndex={-1}
        id={id}
      />
      <div
        id={`select-${id}`}
        className={`
          ${styles.select}
          ${alwaysOpen ? styles.hide : ""}
          ${isListOverflowing ? styles.showScrollbar : ""}
        `}
        onClick={() => setIsListOpen(true)}
        onFocus={() => setIsListOpen(true)}
        onBlur={() => setIsListOpen(false)}
        ref={selectRef}
        tabIndex={0}
      >
        <ul
          ref={listRef}
          className={`
          ${styles.list}
          ${alwaysOpen ? styles.alwaysOpen : ""}
        `}
        >
          {options.map((option, index) => (
            <li
              ref={optionRefs.current[index]}
              key={option.value}
              className={`
                ${styles.option}
                ${option.value === inputValue ? styles.selected : ""}
                ${option.value === virtualOption?.value ? styles.hover : ""}
                ${option.disabled ? styles.disabled : ""}
              `}
              style={{ color: option.color || "" }}
              onClick={() => {
                if (option.disabled) {
                  return;
                }
                handleChange(option.value);
              }}
              onMouseEnter={() => {
                setVirtualSearch("");
                setVirtualOption(option);
              }}
              onMouseLeave={() => setVirtualOption(undefined)}
            >
              {option.icon && <img className={styles.icon} src={option.icon} />}
              {option.label.length > 40
                ? `${option.label.slice(0, 40)}...`
                : option.label}
            </li>
          ))}
        </ul>
      </div>
    </label>
  );
};

export default SingleSelect;
