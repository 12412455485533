import { useContext } from "react";
import { useHistory } from "react-router";

import styles from "./DeletePlayModal.module.scss";
import { PlaybookContext } from "../../../shared/shared-with-mobile/providers/playbook.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import Button from "../../Button/Button";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { PlaySetModel } from "../../../generated/from-api/models/play-set.model";
import { PlaySetsContext } from "../../../shared/shared-with-mobile/providers/playSets.provider";

interface Props {
  playIds: string[];
  setSelectedPlays?: React.Dispatch<
    React.SetStateAction<Map<string, PlayModel>>
  >;
  playSet?: PlaySetModel;
  clearSelectedPlays?: () => void;
}

const DeletePlayModal: React.FC<Props> = ({
  playIds,
  setSelectedPlays,
  playSet,
  clearSelectedPlays,
}) => {
  const { removePlays } = useContext(PlaybookContext);
  const { closeModal, dispatchToast } = useContext(UIContext);
  const history = useHistory();
  const { updatePlaySet } = useContext(PlaySetsContext);
  const doMultiplePlayIdsExist = playIds.length > 1;
  const deletePlays = async () => {
    try {
      const promises = playIds.map(
        async (playId) => await APIService.PLAY.DELETE(playId)
      );
      const response = await Promise.all(promises);
      if (response) {
        const deletedIds: string[] = [];
        response.forEach((res, index) => {
          if (res.deleted) {
            deletedIds.push(playIds[index]);
          }
        });

        if (playSet) {
          try {
            const updatedPlaySet = await APIService.PLAY_SET.GET(
              playSet.id as string
            );
            if (updatedPlaySet) {
              updatePlaySet(updatedPlaySet);
            }
            if (clearSelectedPlays) {
              clearSelectedPlays();
            }
            closeModal();
          } catch (e) {
            dispatchToast({
              type: "error",
              message: "There's been an error. Please try again.",
            });
          }
        }

        removePlays(deletedIds);
        dispatchToast({
          type: "success",
          message: `${deletedIds.length} ${
            deletedIds.length > 1 ? "Plays" : "Play"
          } Deleted`,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      closeModal();
      if (history.location.pathname !== "/playbook") {
        history.goBack();
      } else {
        if (setSelectedPlays) {
          setSelectedPlays(new Map<string, PlayModel>());
        }
        history.push("/playbook");
      }
    }
  };

  return (
    <div className={styles.deletePlayModal}>
      <p>
        Are you sure you want to remove{" "}
        {doMultiplePlayIdsExist ? "these" : "this"} play
        {doMultiplePlayIdsExist && "s"} from the playbook? This action cannot be
        undone.
      </p>
      <div className={styles.buttonRow}>
        <Button
          theme={"tertiary"}
          size={"small"}
          onClick={deletePlays}
          destructive
        >
          Delete Play{doMultiplePlayIdsExist && "s"}
        </Button>
        <Button size={"small"} onClick={closeModal}>
          Keep Play{doMultiplePlayIdsExist && "s"}
        </Button>
      </div>
    </div>
  );
};

export default DeletePlayModal;
