import React, { useEffect, useState } from "react";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import styles from "./Input.module.scss";
import close from "../../resources/images/close.svg";

interface Props {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  placeholder?: string;
  id?: string;
  value?: string;
  type?: INPUT_TYPES;
  min?: string;
  max?: string;
  step?: string;
  theme?: "light" | "dark";
  size?: "large" | "medium" | "small" | "x-small";
  icon?: string;
  iconAlign?: "left" | "right";
  autofocus?: boolean;
  clearButton?: boolean;
  maxLength?: number;
  isTextArea?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  name?: string;
  isValid?: boolean;
}

const CharacterInput: React.FC<Props> = ({
  onChange,
  onBlur,
  onFocus,
  placeholder,
  id,
  value,
  type,
  min,
  max,
  step,
  disabled,
  readonly = false,
  icon,
  theme = "dark",
  size = "medium",
  iconAlign = "left",
  autofocus = false,
  clearButton = false,
  maxLength = Infinity,
  isTextArea = false,
  className = "",
  name = "",
  isValid = true,
}) => {
  const [inputValue, setInputValue] = useState(value ?? "");
  const [hideLabel, setHideLabel] = useState(false);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(event.currentTarget.value);
    onChange && onChange(event);
  };

  const handleClear = () => {
    setInputValue("");
    onChange &&
      onChange({
        currentTarget: { value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <label
      htmlFor={id}
      className={`
        ${styles.label}
        ${styles[theme]}
        ${styles[size]}
        ${icon && iconAlign ? styles[`icon-${iconAlign}`] : ""}
        ${inputValue ? styles.hasValue : ""}
        ${placeholder ? styles.hasPlaceholder : ""}
        ${disabled || readonly ? styles.disabled : ""}
        ${hideLabel ? styles.hideLabel : ""}
        ${clearButton ? styles.clearButton : ""}
        ${!isValid ? styles.error : ""}
        ${className}
      `}
    >
      {icon && <img src={icon} className={styles.icon} alt="icon" />}
      {clearButton && (
        <img
          src={close}
          className={styles.clearButton}
          alt="close"
          onClick={handleClear}
        />
      )}
      {!isTextArea ? (
        <input
          id={id}
          type={type ?? "text"}
          className={`
            ${styles.input}
          `}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={inputValue}
          min={min}
          max={max}
          step={step}
          autoFocus={autofocus}
          maxLength={maxLength}
          autoComplete="new-password" // prevent any auto complete
          pattern={
            type === INPUT_TYPES.TEL ? "[0-9]{3}-[0-9]{3}-[0-9]{4}" : undefined
          }
          disabled={disabled}
          readOnly={readonly}
          name={name}
        />
      ) : (
        <textarea
          id={id}
          className={styles.input}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onScroll={(e) => {
            const el = e.target as any;
            if (el && el.scrollTop) {
              setHideLabel(true);
            } else {
              setHideLabel(false);
            }
          }}
          value={inputValue}
          autoFocus={autofocus}
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readonly}
          name={name}
        />
      )}
      {placeholder && <span>{placeholder}</span>}
    </label>
  );
};

export default CharacterInput;
