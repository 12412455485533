import React, { useContext, useEffect, useState, useMemo } from "react";

import styles from "./Admin.module.scss";
import { LicenseHolderContext } from "../../shared/shared-with-mobile/providers/licenseHolder.provider";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { LicenseHolderModel } from "../../generated/from-api/models/license.model";
import ManageLicenseModal2 from "./admin-components/ManageLicenseModal2";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { LicenseTiersContext } from "../../shared/shared-with-mobile/providers/licenseTiers";
import { DateTime } from "luxon";
import AddLicenseModal from "./admin-components/AddLicenseModal";
import Button from "../../components/Button/Button";
import DataTable, {
  SortDirection,
  TableColumn,
} from "react-data-table-component";
import GreenCheckIcon from "../../resources/icons/GreenCheckIcon";
import YellowWarningIcon from "../../resources/icons/YellowWarningIcon";
import RedAlertIcon from "../../resources/icons/RedAlertIcon";
import TeamSetupIconCheck from "../../resources/icons/TeamSetupIconCheck";
import TeamSetupIconEmpty from "../../resources/icons/TeamSetupIconEmpty";
import PlusIcon from "../../resources/icons/PlusIcon";
import ListIcon from "../../resources/icons/ListIcon";
import search from "../../resources/images/search.svg";
import { LicenseUsageModel } from "../../generated/from-api/models/license-usage.model";
import { LicenseTierModel } from "../../../../api/src/shared-with-mobile-and-web/models/license-tier.model";
import { AdminUtility, MAX_DAYS_BEFORE_PAYMENT } from "./admin.utility";
import CharacterInput from "../../components/Input/CharacterInput";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import FilterLicenseModal from "./admin-components/FilterLicenseModal";
import { UserProfileModel } from "../../generated/from-api/models/user-profile.model";
import { getAbbreviatedDisplayName } from "../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import Tooltip from "@material-ui/core/Tooltip";

import TrialIcon from "../../resources/icons/TrialIcon";
import { getUserFacingSubscriptionName } from "../../generated/from-api/api-constants/license-tier-constants";

export enum PaymentStatus {
  Free = "Free Tier",
  Trial = "Trial - OK",
  TrialExpired = "Trial - Expired",
  Paid = "Paid",
  PaidNoInvoice = "Paid - No invoice",
  UnpaidOk = "Unpaid - OK",
  UnpaidAlert = "Unpaid - Alert",
}

export enum SetupCriteria {
  teamCreated = "Team Created",
  rosterInvited = "Roster",
  playsAdded = "Playbook",
  quizzesAdded = "Flashcards",
  lessonsAdded = "Lessons",
}

export interface NumericFilterOption {
  name: string;
  lowest?: number;
  highest?: number;
}

export const UsersFilterOptions: NumericFilterOption[] = [
  { name: "<10", highest: 10 },
  { name: "11-50", lowest: 11, highest: 50 },
  { name: "51-100", lowest: 51, highest: 100 },
  { name: ">100", lowest: 101 },
];

export const TimeSpentFilterOptions: NumericFilterOption[] = [
  { name: "<10", highest: 10 },
  { name: "11-50", lowest: 11, highest: 50 },
  { name: "51-300", lowest: 51, highest: 300 },
  { name: ">300", lowest: 301 },
];

export const QuestionsFilterOptions: NumericFilterOption[] = [
  { name: "<10", highest: 10 },
  { name: "11-500", lowest: 11, highest: 500 },
  { name: "501-2000", lowest: 501, highest: 2000 },
  { name: ">2000", lowest: 2001 },
];

export const CreationDateFilterOptions: NumericFilterOption[] = [
  { name: "> 30 Days", lowest: 30 },
  { name: "> 60 Days", lowest: 60 },
];

export interface LicenseOwnerListRow {
  id: string;
  owner: string;
  email: string;
  tier: LicenseTierModel;
  payment: PaymentStatus;
  hubspotPaymentStatus: PaymentStatus;
  tnRep: string;
  tnRepDisplay?: string;
  dateCreated: DateTime;
  users: number;
  timeSpent: number;
  questions: number;
  teamSetup: number;
  firstTeamName: string;
  firstTeamLevel: string;
  licenseHolderData: LicenseHolderModel[];
  licenseStats?: LicenseUsageModel[];
  setupCriteria: Set<SetupCriteria>;
  expirationDate: DateTime;
}

const headerTextColor = "#ABABB7"; // spun-pearl
const primaryTextColor = "#ffffff"; // white
const primaryBackgroundColor = "#282B3A"; // stell-gray
const primaryDividerColor = "#424258"; // abby
export const DATA_TABLES_LICENSE_STYLES = {
  table: {
    style: {
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
      borderWidth: "1px",
      borderColor: primaryDividerColor,
      borderStyle: "solid",
      borderRadius: "6px",
    },
  },
  headRow: {
    style: {
      minHeight: "30px",
      backgroundColor: primaryBackgroundColor,
      borderBottomWidth: "1px",
      borderBottomColor: primaryDividerColor,
      borderBottomStyle: "solid",
      borderRadius: "6px 6px 0 0",
    },
  },
  rows: {
    style: {
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
      "&:hover": {
        cursor: "pointer",
      },
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: primaryDividerColor,
      },
      "&:last-of-type": {
        borderRadius: "6px",
      },
    },
  },
  headCells: {
    style: {
      fontSize: "11px",
      color: headerTextColor,
      backgroundColor: primaryBackgroundColor,
      textTransform: "upperCase",
      paddingLeft: "8px",
      paddingRight: "8px",
      borderRadius: "6px",
      "&:not(:last-of-type)": {
        borderRightWidth: "1px",
        borderRightColor: primaryDividerColor,
        borderRightStyle: "solid",
      },
    },
    activeSortStyle: {
      color: primaryTextColor,
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: primaryTextColor,
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: primaryTextColor,
      },
      "&:hover": {
        color: primaryTextColor,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      fontSize: "14px",
      "&:not(:last-of-type)": {
        borderRightWidth: "1px",
        borderRightColor: primaryDividerColor,
        borderRightStyle: "solid",
      },
    },
  },
  pagination: {
    style: {
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
    },
    pageButtonsStyle: {
      color: primaryTextColor,
      fill: primaryTextColor,
      "&:disabled": {
        cursor: "unset",
        color: primaryDividerColor,
        fill: primaryDividerColor,
      },
    },
  },
  noData: {
    style: {
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
    },
  },
};

export const Admin: React.FC = () => {
  const { dispatchToast, dispatchModal, closeModal } = useContext(UIContext);
  const { licenseHolders, setLicenseHolders } = useContext(
    LicenseHolderContext
  );
  const { licenseTiers } = useContext(LicenseTiersContext);
  const [holdersUpdate, setHoldersUpdate] = useState<number>(0);
  const [licenseFilters, setLicenseFilters] = useState(
    new Map<string, string[]>()
  );
  const [filterCount, setFilterCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [licenseUsage, setLicenseUsage] = useState<LicenseUsageModel[]>([]);
  const [tnReps, setTnReps] = useState<UserProfileModel[]>([]);
  const [ownerListData, setOwnerListData] = useState<LicenseOwnerListRow[]>([]);
  const [ownerListRows, setOwnerListRows] = useState<LicenseOwnerListRow[]>([]);

  const [sortColumn, setSortColumn] = useState<string>("OWNER");
  const [sortAsc, setSortAsc] = useState<boolean>(true);

  const tierStyle: Map<string, string> = new Map();
  tierStyle.set("PLATINUM", styles.tierPlatinum);
  tierStyle.set("GOLD", styles.tierGold);
  tierStyle.set("SILVER", styles.tierSilver);
  tierStyle.set("BRONZE", styles.tierBronze);
  tierStyle.set("TRIAL", styles.tierTrial);
  tierStyle.set("PREMIER", styles.tierPremier);
  tierStyle.set("GOLD2023", styles.tierGold);
  tierStyle.set("SILVER2023", styles.tierSilver);
  tierStyle.set("BRONZE2023", styles.tierBronze);
  // tierStyle.set("FREE2023", styles.tierTrial);
  tierStyle.set("YOUTH2023", styles.tierBronze);
  tierStyle.set("BASIC2023", styles.tierTrial);
  tierStyle.set("COLLEGE2023", styles.tierPlatinum);

  useEffect(() => {
    async function getQuotas() {
      const usage = await APIService.LICENSE_QUOTA.LIST();
      setLicenseUsage(usage);
    }
    async function getRepresentatives() {
      await APIService.USER_REPRESENTATIVE.GET_LIST_OF_REPRESENTATIVES().then(
        (reps) => {
          setTnReps(reps);
        }
      );
    }
    getQuotas();
    getRepresentatives();
  }, []);

  const checkTeamSetupCriteria = (
    stats: LicenseUsageModel,
    criteria: Set<SetupCriteria>
  ) => {
    if (!!stats.teamId) criteria.add(SetupCriteria.teamCreated);
    if (stats.numberOfPlayers >= 10) criteria.add(SetupCriteria.rosterInvited);
    if (stats.numberOfPlays >= 10) criteria.add(SetupCriteria.playsAdded);
    if (stats.numberOfCustomQuizzes >= 10)
      criteria.add(SetupCriteria.quizzesAdded);
    if (stats.numberOfInstalls >= 10) criteria.add(SetupCriteria.lessonsAdded);
  };

  const getTeamSetupCriteriaRank = (criteria: Set<SetupCriteria>) => {
    return (
      (criteria.has(SetupCriteria.teamCreated) ? 1 : 0) +
      (criteria.has(SetupCriteria.rosterInvited) ? 1 : 0) +
      (criteria.has(SetupCriteria.playsAdded) ? 1 : 0) +
      (criteria.has(SetupCriteria.quizzesAdded) ? 1 : 0) +
      (criteria.has(SetupCriteria.lessonsAdded) ? 1 : 0)
    );
  };

  const validateFilters = (dataOriginal: LicenseOwnerListRow[]) => {
    const checkFilterRange = (
      values: number,
      filterOptions: NumericFilterOption[],
      ranges: string[]
    ) => {
      if (
        filterOptions.findIndex((option) => {
          if (!ranges.includes(option.name)) return false;
          if (option.lowest && values < option.lowest) return false;
          if (option.highest && values >= option.highest) return false;
          return true;
        }) !== -1
      ) {
        return true;
      }
      return false;
    };
    let data = dataOriginal.slice();
    const tierCheck = licenseFilters.get("tier");
    if (tierCheck && tierCheck.length > 0) {
      data = data.filter((datum) => tierCheck.includes(datum.tier.tier));
    }
    const paymentCheck = licenseFilters.get("payment");
    if (paymentCheck && paymentCheck.length > 0) {
      data = data.filter((datum) => {
        return paymentCheck.includes(datum.payment);
      });
    }
    const tnRepCheck = licenseFilters.get("tnRep");
    if (tnRepCheck && tnRepCheck.length > 0) {
      data = data.filter((datum) => tnRepCheck.includes(datum.tnRep));
    }
    const usersCheck = licenseFilters.get("users");
    if (usersCheck && usersCheck.length > 0) {
      data = data.filter((datum) =>
        checkFilterRange(datum.users, UsersFilterOptions, usersCheck)
      );
    }
    /*const timeSpentCheck = licenseFilters.get("timeSpent");
    if (timeSpentCheck && timeSpentCheck.length > 0) {
      data = data.filter((datum) =>
        checkFilterRange(
          datum.timeSpent,
          TimeSpentFilterOptions,
          timeSpentCheck
        )
      );
    }*/
    const questionsCheck = licenseFilters.get("questions");
    if (questionsCheck && questionsCheck.length > 0) {
      data = data.filter((datum) =>
        checkFilterRange(
          datum.questions,
          QuestionsFilterOptions,
          questionsCheck
        )
      );
    }
    const creationDateCheck = licenseFilters.get("creationDate");
    if (creationDateCheck && creationDateCheck.length > 0) {
      data = data.filter((datum) => {
        return (
          CreationDateFilterOptions.findIndex((option) => {
            if (
              creationDateCheck.findIndex(
                (filter) => filter === option.name
              ) === -1
            )
              return false;
            return AdminUtility.createdOverXDaysAgo(
              datum.dateCreated.toISO(),
              typeof option.lowest === "number"
                ? option.lowest
                : MAX_DAYS_BEFORE_PAYMENT
            );
          }) !== -1
        );
      });
    }
    const teamSetupCheck = licenseFilters.get("teamSetup");
    if (teamSetupCheck && teamSetupCheck.length > 0) {
      data = data.filter((datum) => {
        if (!datum.setupCriteria?.size) return false;
        if (teamSetupCheck.includes("Complete")) {
          return datum.teamSetup === Object.values(SetupCriteria).length;
        }
        return teamSetupCheck.every((check) => {
          if (!datum.setupCriteria) return false;
          return datum.setupCriteria.has(check as SetupCriteria);
        });
      });
    }
    if (searchTerm) {
      data = data.filter((datum) => {
        return searchTermMatch(datum, searchTerm);
      });
    }
    return data;
  };

  const searchTermMatch = (data: LicenseOwnerListRow, term: string) => {
    const licenseOne = data.licenseHolderData[0];
    if (!licenseOne || !data.licenseStats) return true; // This should always exist, if not just show regardless
    const upTerm = term.toUpperCase();
    const stats = AdminUtility.getAggregateStats(data.licenseStats);
    const licenseOneChecks =
      DateTime.fromISO(licenseOne.expires)?.toLocaleString().includes(upTerm) ||
      licenseOne.invoiceNumber?.includes(term);
    const statChecks =
      (stats.numberOfCoaches + stats.numberOfPlayers)
        .toString()
        .includes(upTerm) ||
      stats.numberOfInstalls.toString().includes(upTerm) ||
      stats.numberOfGamePlans.toString().includes(upTerm) ||
      stats.numberOfCustomQuizzes.toString().includes(upTerm);
    return (
      licenseOneChecks ||
      statChecks ||
      data.owner?.toUpperCase().includes(upTerm) ||
      data.email?.toUpperCase().includes(upTerm) ||
      data.tier?.tier?.toUpperCase().includes(upTerm) ||
      data.tnRepDisplay?.toUpperCase().includes(upTerm) ||
      data.dateCreated?.toLocaleString().includes(upTerm) ||
      data.users?.toLocaleString().includes(upTerm) ||
      // data.timeSpent?.toString().includes(upTerm) ||
      data.questions?.toLocaleString().includes(upTerm)
    );
  };

  useEffect(() => {
    if (licenseHolders) {
      const data: LicenseOwnerListRow[] = [];
      licenseHolders.forEach(async (licenses) => {
        if (!licenses || licenses.length <= 0) return;
        const licenseOne = licenses[0];

        const displayName =
          licenseOne.firstName || licenseOne.lastName
            ? licenseOne.firstName + " " + licenseOne.lastName
            : "???";
        const licenseTier = licenseTiers.find((tier) => {
          return tier.id === licenseOne.licenseTierId;
        });
        if (!licenseTier) return;
        const setupCriteria = new Set<SetupCriteria>();
        let usersCount = 0;
        let questionsCount = 0;
        const stats: LicenseUsageModel[] = [];
        licenses.forEach((license) => {
          const usageStats = licenseUsage.find((usage) => {
            return usage.licenseId === license.id;
          });
          if (usageStats) {
            usersCount += usageStats.numberOfPlayers;
            usersCount += usageStats.numberOfCoaches;
            questionsCount += usageStats.numberOfQuestionsAnswered;
            questionsCount += usageStats.numberOfQuizQuestionsAnswered;
            questionsCount += usageStats.numberOfInstallQuestionsAnswered;
            checkTeamSetupCriteria(usageStats, setupCriteria);
            stats.push(usageStats);
          }
        });

        const created = AdminUtility.getEarliestCreationDate(licenses);
        const newOwnerListRows: LicenseOwnerListRow = {
          id: licenseOne.id,
          owner: displayName,
          email: licenseOne.email,
          tier: licenseTier,
          payment: AdminUtility.getPaymentCategory(
            licenseOne,
            created.toLocaleString(),
            licenseOne.invoiceNumber
          ),
          hubspotPaymentStatus: AdminUtility.getPaymentCategory(
            licenseOne,
            created.toLocaleString(),
            licenseOne.invoiceNumber,
            "HUBSPOT"
          ),
          tnRep: licenseOne.tnRepresentativeUserId,
          tnRepDisplay: getTnRepName(licenseOne.tnRepresentativeUserId),
          dateCreated: created,
          users: usersCount,
          timeSpent: 0,
          questions: questionsCount,
          teamSetup: getTeamSetupCriteriaRank(setupCriteria),
          firstTeamName: licenseOne.teamName ? licenseOne.teamName : "-",
          firstTeamLevel: licenseOne.teamLevel ? licenseOne.teamLevel : "-",
          licenseHolderData: licenses,
          licenseStats: stats,
          setupCriteria: setupCriteria,
          expirationDate: licenseOne.expires
            ? DateTime.fromISO(licenseOne.expires)
            : DateTime.fromISO(new Date().toISOString()), // a license should always exist, so this ternary should always evaluate to the first
        };
        data.push(newOwnerListRows);
      });
      setOwnerListData(data);
      const filteredData = validateFilters(data);
      sortHolderRows(filteredData);
      setOwnerListRows(filteredData);
    }
  }, [licenseHolders, licenseTiers, licenseUsage, holdersUpdate]);

  useEffect(() => {
    const unfilteredRows = ownerListData.slice();
    const sortedRows = validateFilters(unfilteredRows);
    sortHolderRows(sortedRows);
    setOwnerListRows(sortedRows);
  }, [sortColumn, searchTerm, sortAsc, licenseFilters]);

  const sortHolderRows = (rows: LicenseOwnerListRow[]) => {
    const getSortValue = (row: LicenseOwnerListRow) => {
      switch (sortColumn) {
        case "OWNER":
          return row.owner;
        case "EMAIL":
          return row.email;
        case "TIER":
          return row.tier.id?.substring(0, 1) || "0";
        case "PAYMENT":
          return row.payment;
        case "HUBSPOTPAYMENT":
          return row.hubspotPaymentStatus;
        case "TNREP":
          return row.tnRepDisplay || "";
        case "DATECREATED":
          return row.dateCreated;
        case "EXPIRATION":
          return row.expirationDate;
        case "USERS":
          return row.users;
        /*case "TIMESPENT":
          return row.timeSpent;*/
        case "QUESTIONS":
          return row.questions;
        case "TEAMNAME":
          return row.firstTeamName;
        case "TEAMLEVEL":
          return row.firstTeamLevel;
        case "TEAMSETUP":
          return row.teamSetup;
        default:
          return "";
      }
    };
    rows.sort((a, b) => {
      const aVal = getSortValue(a);
      const bVal = getSortValue(b);
      if (aVal === bVal) return 0;
      if (sortAsc) {
        return aVal > bVal ? 1 : -1;
      } else {
        return aVal < bVal ? 1 : -1;
      }
    });
  };

  const handleAddClick = (userEmail?: string) => {
    const addLicenses = async (
      email: string,
      licenseTierId: string,
      licenseInvoiceNumber: string,
      firstName?: string,
      lastName?: string
    ) => {
      const licenseTier = licenseTiers.find((tier) => {
        return tier.id === licenseTierId;
      });
      if (!licenseTier) {
        dispatchToast({
          type: "error",
          message: "Failed to add license.",
        });
        return;
      }
      await APIService.LICENSE.CREATE_LICENSE(
        email,
        new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        ).toISOString(),
        licenseTierId,
        licenseInvoiceNumber,
        false,
        firstName,
        lastName
      )
        .then((data) => {
          if (data) setLicenseHolders(data);
          dispatchToast({
            type: "success",
            message: "License added.",
          });
          setHoldersUpdate(holdersUpdate + 1);
        })
        .catch(() => {
          dispatchToast({
            type: "error",
            message: "Failed to add license.",
          });
        });
    };

    dispatchModal({
      title: "Add License",
      open: true,
      className: styles.addLicensesModal,
      body: (
        <AddLicenseModal
          userEmail={userEmail ? userEmail : ""}
          saveCallback={(
            email,
            licenseTierId,
            licenseInvoiceNumber,
            firstName,
            lastName
          ) => {
            addLicenses(
              email,
              licenseTierId,
              licenseInvoiceNumber,
              firstName,
              lastName
            ).then(() => {
              closeModal();
            });
          }}
          cancelCallback={closeModal}
        />
      ),
    });
  };

  const handleExportClick = () => {
    // Format the current table as a CSV
    const csv = prepCsv();
    const fileName = `license-holders-from-${new Date().toLocaleString(
      "en-us",
      {
        dateStyle: "medium",
        timeStyle: "short",
      }
    )}.csv`;
    // Actually download the csv file
    // https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
    const blob = new Blob([csv], { type: "csv" });
    const temp = document.createElement("a");
    temp.href = URL.createObjectURL(blob);
    temp.download = fileName;
    document.body.append(temp);
    temp.click();
    temp.remove();
  };

  const getTeamSetupPercentage = (teamSetup: number) => {
    const percent = teamSetup * 20; // 5 Steps, so 20% * 5 = 100%
    return percent + "%";
  };

  const prepCsv = () => {
    const dq = (str: string) => {
      return '"' + str + '"';
    };
    // Wrap in double quotes
    const header = [
      dq("Owner"),
      dq("Email"),
      dq("Tier"),
      dq("Payment"),
      dq("Hubspot Payment"),
      dq("TN Rep"),
      dq("Date Created"),
      dq("Users"),
      // dq("Time Spent (Hours)"),
      dq("Questions"),
      dq("Team Name"),
      dq("Team Level"),
      dq("Team Setup"),
    ].join(",");
    const data = ownerListRows.map((row) => {
      const rowFormatted: string[] = [];
      rowFormatted.push(dq(row.owner));
      rowFormatted.push(dq(row.email));
      rowFormatted.push(dq(getUserFacingSubscriptionName(row.tier.tier)));
      rowFormatted.push(dq(row.payment));
      rowFormatted.push(dq(row.hubspotPaymentStatus));
      rowFormatted.push(dq(getTnRepName(row.tnRep)));
      rowFormatted.push(row.dateCreated.toLocaleString());
      rowFormatted.push(row.users.toString());
      // rowFormatted.push(row.timeSpent.toString());
      rowFormatted.push(row.questions.toString());
      rowFormatted.push(row.firstTeamName.toString());
      rowFormatted.push(row.firstTeamLevel.toString());
      rowFormatted.push(dq(getTeamSetupPercentage(row.teamSetup)));
      return rowFormatted.join(",");
    });
    const csv = header + "\r\n" + data.join("\r\n");
    return csv;
  };

  const handleFilterClick = () => {
    const updateFilters = (filters: Map<string, string[]>) => {
      setLicenseFilters(filters);
      setFilterCount(AdminUtility.countFiltersInUse(filters));
      closeModal();
    };

    dispatchModal({
      title: "Filter",
      open: true,
      size: "medium",
      className: styles.filterLicenseModal,
      body: (
        <FilterLicenseModal
          licenseFilters={licenseFilters}
          tnReps={tnReps}
          saveCallback={updateFilters}
        />
      ),
    });
  };

  const handleClearFilterClick = () => {
    setLicenseFilters(new Map<string, string[]>());
    setFilterCount(0);
  };

  const manageLicense = (license: LicenseOwnerListRow) => {
    const updateLicense = async (value: number) => {
      await APIService.LICENSE.LIST()
        .then((licenses) => {
          setLicenseHolders(licenses);
          setHoldersUpdate(value);
        })
        .catch(() => {
          dispatchToast({
            type: "error",
            message: "Could not retrieve updated license holders.",
          });
        });
      closeModal();
    };
    dispatchModal({
      open: true,
      size: "medium",
      className: styles.manageLicenseModal,
      body: (
        <ManageLicenseModal2
          licenseData={license}
          updates={holdersUpdate}
          tnReps={tnReps}
          closeCallback={updateLicense}
        />
      ),
      disableBackdropClick: true,
    });
  };

  const getTierCell = (row: LicenseOwnerListRow) => {
    const format: boolean = tierStyle.has(row.tier.tier);
    if (format) {
      return (
        <div className={tierStyle.get(row.tier.tier)} data-tag="allowRowEvents">
          {getUserFacingSubscriptionName(row.tier.tier)}
        </div>
      );
    }
    return (
      <div className={styles.freeTier} data-tag="allowRowEvents">
        {getUserFacingSubscriptionName(row.tier.tier)}
      </div>
    );
  };

  const getPaymentCell = (row: LicenseOwnerListRow) => {
    return (
      <div className={styles.iconCell} data-tag="allowRowEvents">
        {AdminUtility.getPaymentIcon(
          row.licenseHolderData[0],
          row.dateCreated.toLocaleString(),
          <GreenCheckIcon />,
          <YellowWarningIcon />,
          <RedAlertIcon />,
          <TrialIcon />
        )}
      </div>
    );
  };

  const getHubspotPaymentCell = (row: LicenseOwnerListRow) => {
    return (
      <div className={styles.iconCell} data-tag="allowRowEvents">
        {AdminUtility.getPaymentIcon(
          row.licenseHolderData[0],
          row.dateCreated.toLocaleString(),
          <GreenCheckIcon />,
          <YellowWarningIcon />,
          <RedAlertIcon />,
          <TrialIcon />,
          "HUBSPOT"
        )}
      </div>
    );
  };

  const getTnRepName = (repId: string) => {
    if (!tnReps || tnReps.length === 0 || !repId) return "";
    const userRep = tnReps?.find((rep) => rep.id === repId);
    if (!userRep) return "";
    return getAbbreviatedDisplayName(userRep.firstName, userRep.lastName);
  };

  const getTeamSetupCheck = (
    criteria: Set<SetupCriteria>,
    step: SetupCriteria
  ) => {
    const getTeamSetupTooltip = (step: SetupCriteria) => {
      if (step === SetupCriteria.teamCreated) return "Team Created";
      if (step === SetupCriteria.rosterInvited)
        return "At least 10 players invited";
      if (step === SetupCriteria.playsAdded)
        return "At least 10 plays added to playbook";
      if (step === SetupCriteria.quizzesAdded)
        return "At least 10 custom flashcards added";
      if (step === SetupCriteria.lessonsAdded)
        return "At least 10 lessons added";
      return "???";
    };
    const checked = criteria.has(step);
    return (
      <div className={styles.teamSetupRank}>
        <Tooltip
          title={getTeamSetupTooltip(step)}
          classes={{
            tooltip: `${styles.teamSetupTooltip} light`,
            arrow: `${styles.teamSetupArrow} light`,
          }}
          arrow
        >
          <div>{checked ? <TeamSetupIconCheck /> : <TeamSetupIconEmpty />}</div>
        </Tooltip>
      </div>
    );
  };

  const getTeamSetupCell = (row: LicenseOwnerListRow) => {
    const values = [];
    const criteria = row.setupCriteria;
    values.push(getTeamSetupCheck(criteria, SetupCriteria.teamCreated));
    values.push(getTeamSetupCheck(criteria, SetupCriteria.rosterInvited));
    values.push(getTeamSetupCheck(criteria, SetupCriteria.playsAdded));
    values.push(getTeamSetupCheck(criteria, SetupCriteria.quizzesAdded));
    values.push(getTeamSetupCheck(criteria, SetupCriteria.lessonsAdded));
    return <div className={styles.iconCell}>{values}</div>;
  };

  const columns: TableColumn<LicenseOwnerListRow>[] = useMemo(
    () => [
      {
        id: "OWNER",
        name: "OWNER",
        selector: (row: LicenseOwnerListRow) => row.owner,
        format: (row: LicenseOwnerListRow) => row.owner,
        sortable: true,
        grow: 2,
        wrap: true,
      },
      {
        id: "EMAIL",
        name: "EMAIL",
        selector: (row: LicenseOwnerListRow) => row.owner,
        format: (row: LicenseOwnerListRow) => row.email,
        sortable: true,
        grow: 2,
      },
      {
        id: "TEAMNAME",
        name: "TEAM NAME",
        selector: (row: LicenseOwnerListRow) => row.firstTeamName,
        sortable: true,
        minWidth: "150px",
        format: (row: LicenseOwnerListRow) =>
          row.firstTeamName.toLocaleString(),
      },
      {
        id: "TEAMLEVEL",
        name: "TEAM LEVEL",
        selector: (row: LicenseOwnerListRow) => row.firstTeamLevel,
        sortable: true,
        minWidth: "80px",
        format: (row: LicenseOwnerListRow) =>
          row.firstTeamLevel.toLocaleString(),
      },
      {
        id: "TIER",
        name: "TIER",
        selector: (row: LicenseOwnerListRow) => row.tier,
        cell: (row: LicenseOwnerListRow) => {
          return getTierCell(row);
        },
        sortable: true,
      },
      {
        id: "EXPIRATION",
        name: "EXPIRATION",
        selector: (row: LicenseOwnerListRow) => row.expirationDate,
        format: (row: LicenseOwnerListRow) =>
          row.expirationDate.toLocaleString(),
        sortable: true,
        minWidth: "130px",
      },
      {
        id: "PAYMENT",
        name: "PAYMENT",
        selector: (row: LicenseOwnerListRow) => row.payment,
        cell: (row: LicenseOwnerListRow) => {
          return getPaymentCell(row);
        },
        sortable: true,
        width: "90px",
      },
      {
        id: "HUBSPOTPAYMENT",
        name: "PAYMENT (HUBSPOT)",
        selector: (row: LicenseOwnerListRow) => row.hubspotPaymentStatus,
        cell: (row: LicenseOwnerListRow) => {
          return getHubspotPaymentCell(row);
        },
        sortable: true,
        width: "130px",
      },
      // {
      //   id: "TNREP",
      //   name: "TN REP",
      //   selector: (row: LicenseOwnerListRow) => row.tnRep,
      //   format: (row: LicenseOwnerListRow) => row.tnRepDisplay,
      //   sortable: true,
      // },
      {
        id: "DATECREATED",
        name: "DATE CREATED",
        selector: (row: LicenseOwnerListRow) => row.dateCreated,
        sortable: true,
        width: "130px",
        format: (row: LicenseOwnerListRow) => row.dateCreated.toLocaleString(),
      },
      {
        id: "USERS",
        name: "USERS",
        selector: (row: LicenseOwnerListRow) => row.users,
        sortable: true,
        minWidth: "86px",
        format: (row: LicenseOwnerListRow) => row.users.toLocaleString(),
      },
      /*{
        id: "TIMESPENT",
        name: "TIME SPENT",
        selector: () => "--", // (row: LicenseOwnerListRow) => row.timeSpent,
        sortable: true,
        format: () => "--", // (row: LicenseOwnerListRow) => row.timeSpent.toLocaleString() + " HRS",
        minWidth: "116px",
      },*/
      {
        id: "QUESTIONS",
        name: "QUESTIONS",
        selector: (row: LicenseOwnerListRow) => row.questions,
        sortable: true,
        format: (row: LicenseOwnerListRow) => row.questions.toLocaleString(),
        minWidth: "116px",
      },
      {
        id: "TEAMSETUP",
        name: "TEAM SETUP",
        selector: (row: LicenseOwnerListRow) => row.teamSetup,
        cell: (row: LicenseOwnerListRow) => {
          return getTeamSetupCell(row);
        },
        sortable: true,
        minWidth: "140px",
      },
    ],
    [tnReps]
  );

  const rowSort = (
    column: TableColumn<LicenseOwnerListRow>,
    direction: SortDirection
  ) => {
    if (!column.id) return;
    setSortColumn(column.id.toString());
    setSortAsc(direction === "asc");
  };

  const sortArrow = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 8.5L12 15.5L19 8.5H5Z" fill="#ABABB7" />
    </svg>
  );

  return (
    <div className={styles.adminPage}>
      <section className={`${styles.header} pageHeader`}>
        <h1 className="pageTitle">Manage Licenses</h1>
      </section>
      <section>
        <div className={styles.header__Buttons}>
          <div className={styles.header__Actions}>
            <Button
              className={styles.header__Button}
              theme={"tertiary"}
              size={"small"}
              icon={<PlusIcon />}
              onClick={() => handleAddClick()}
            >
              New License
            </Button>
            <Button
              className={styles.header__Button}
              theme={"tertiary"}
              size={"small"}
              icon={<PlusIcon />}
              onClick={() => handleExportClick()}
            >
              Export View
            </Button>
          </div>
          <div className={styles.header__Filters}>
            {filterCount > 0 && (
              <Button
                className={styles.header__Button}
                theme={"tertiary"}
                size={"small"}
                onClick={() => handleClearFilterClick()}
              >
                {"Clear Filters (" + filterCount + ")"}
              </Button>
            )}
            <Button
              className={styles.header__Button}
              theme={"tertiary"}
              size={"small"}
              icon={<ListIcon />}
              onClick={() => handleFilterClick()}
            >
              Filter
            </Button>
            <div className={styles.searchBar}>
              <CharacterInput
                type={INPUT_TYPES.TEXT}
                placeholder="Type teams, owners, emails..."
                id="search"
                size="x-small"
                icon={search}
                value={searchTerm}
                clearButton
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
      </section>
      <div>
        <DataTable
          columns={columns}
          data={ownerListRows}
          customStyles={DATA_TABLES_LICENSE_STYLES}
          pagination
          sortIcon={sortArrow}
          onSort={rowSort}
          onRowClicked={manageLicense}
          sortServer
        />
      </div>
    </div>
  );
};

export default Admin;
