import * as React from "react";

const GreenCheckIcon: React.FC = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-tag="allowRowEvents"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 13.5C0.5 6.324 6.324 0.5 13.5 0.5C20.676 0.5 26.5 6.324 26.5 13.5C26.5 20.676 20.676 26.5 13.5 26.5C6.324 26.5 0.5 20.676 0.5 13.5ZM10.9 16.321L19.467 7.754L21.3 9.6L10.9 20L5.7 14.8L7.533 12.967L10.9 16.321Z"
      fill="#00A863"
      data-tag="allowRowEvents"
    />
  </svg>
);

export default GreenCheckIcon;
