import React, { useEffect, useState } from "react";
import { CustomQuizStatisticModel } from "../../../generated/from-api/models/custom-quiz-statistic.model";
import { CustomQuizModel } from "../../../generated/from-api/models/custom-quiz.model";
import { processDataUpdates } from "../utilities/DataSync/DataSync";

export const QuizzesContext = React.createContext<{
  quizzes: CustomQuizModel[];
  setQuizzes: (quizzes: CustomQuizModel[]) => void;
  addQuiz: (quiz: CustomQuizModel) => void;
  updateQuiz: (quiz: CustomQuizModel) => void;
  updateQuizzes: (quizzes: CustomQuizModel[]) => void;
  updateGlobalQuizzes: (quizzes: CustomQuizModel[]) => void;
  removeQuiz: (quizId: string) => void;
  clearQuizzes: () => void;

  globalQuizzes: CustomQuizModel[];
  setGlobalQuizzes: (quizzes: CustomQuizModel[]) => void;

  quizStats: CustomQuizStatisticModel[];
  setQuizStats: (quizzes: CustomQuizStatisticModel[]) => void;
  quizItemsMap: Record<string, CustomQuizModel>;

  areQuizTooltipsTemporarilyDismissed: boolean;
  setAreQuizTooltipsTemporarilyDismissed: (newValue: boolean) => void;

  consumeUpdatesForCurrentQuizzes: (updates: CustomQuizModel[]) => void;
}>({
  quizzes: [],
  setQuizzes: () => null,
  addQuiz: () => null,
  updateQuiz: () => null,
  updateQuizzes: () => null,
  removeQuiz: () => null,
  clearQuizzes: () => null,

  globalQuizzes: [],
  setGlobalQuizzes: () => null,
  updateGlobalQuizzes: () => null,

  quizStats: [],
  setQuizStats: () => null,
  quizItemsMap: {},

  areQuizTooltipsTemporarilyDismissed: false,
  setAreQuizTooltipsTemporarilyDismissed: () => null,

  consumeUpdatesForCurrentQuizzes: () => null,
});

export const QuizzesProvider: React.FC = ({ children }) => {
  const [quizzes, setQuizzes] = useState<CustomQuizModel[]>([]);
  const [globalQuizzes, setGlobalQuizzes] = useState<CustomQuizModel[]>([]);
  const [quizStats, setQuizStats] = useState<CustomQuizStatisticModel[]>([]);
  const [quizItemsMap, setQuizItemsMap] = useState<
    Record<string, CustomQuizModel>
  >({});
  const [
    areQuizTooltipsTemporarilyDismissed,
    setAreQuizTooltipsTemporarilyDismissed,
  ] = useState<boolean>(false);

  useEffect(() => {
    const availableQuizzes: CustomQuizModel[] = [];
    if (quizzes) availableQuizzes.push(...quizzes);
    if (globalQuizzes) availableQuizzes.push(...globalQuizzes);
    if (availableQuizzes.length === 0) return;

    const nextQuizItemsMap: Record<string, CustomQuizModel> = {};
    for (const quiz of availableQuizzes) {
      if (quiz.id) {
        nextQuizItemsMap[quiz.id] = quiz;
      }
    }
    setQuizItemsMap(nextQuizItemsMap);
  }, [quizzes, globalQuizzes]);

  const updateQuiz = (quizToUpdate: CustomQuizModel) => {
    const nextQuizzes = quizzes.map((quiz) => {
      if (quizToUpdate.id !== quiz.id) {
        return quiz;
      }

      return quizToUpdate;
    });

    setQuizzes(nextQuizzes);
  };

  const updateQuizzes = (quizzesToUpdate: CustomQuizModel[]) => {
    const nextQuizzes = quizzes.map((quiz) => {
      const quizInUpdateArray = quizzesToUpdate.find(
        (quizToUpdate) => quizToUpdate.id === quiz.id
      );
      if (!quizInUpdateArray) {
        return quiz;
      }
      return quizInUpdateArray;
    });
    setQuizzes(nextQuizzes);
  };

  const updateGlobalQuizzes = (globalQuizzesToUpdate: CustomQuizModel[]) => {
    const nextQuizzes = globalQuizzes.map((quiz) => {
      const quizInUpdateArray = globalQuizzesToUpdate.find(
        (quizToUpdate) => quizToUpdate.id === quiz.id
      );
      if (!quizInUpdateArray) {
        return quiz;
      }
      return quizInUpdateArray;
    });
    setGlobalQuizzes(nextQuizzes);
  };

  const addQuiz = (quizToAdd: CustomQuizModel) => {
    setQuizzes([...quizzes, quizToAdd]);
  };

  const clearQuizzes = () => {
    setQuizzes([]);
  };

  const removeQuiz = (quizId: string) => {
    const quizToDeleteIndex = quizzes.findIndex((quiz) => quiz.id === quizId);
    const nextQuizzes = [...quizzes];
    if (quizToDeleteIndex > -1) {
      nextQuizzes.splice(quizToDeleteIndex, 1);
    }
    setQuizzes(nextQuizzes);
  };

  const consumeUpdatesForCurrentQuizzes = (updates: CustomQuizModel[]) => {
    const newDataSet = processDataUpdates(updates, quizzes);
    setQuizzes(newDataSet);
  };

  return (
    <QuizzesContext.Provider
      value={{
        quizzes,
        setQuizzes,
        addQuiz,
        updateQuiz,
        updateQuizzes,
        removeQuiz,
        clearQuizzes,
        globalQuizzes,
        setGlobalQuizzes,
        updateGlobalQuizzes,
        quizStats,
        setQuizStats,
        quizItemsMap,
        areQuizTooltipsTemporarilyDismissed,
        setAreQuizTooltipsTemporarilyDismissed,
        consumeUpdatesForCurrentQuizzes,
      }}
    >
      {children}
    </QuizzesContext.Provider>
  );
};
