import * as React from "react";

const YellowWarningIcon: React.FC = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-tag="allowRowEvents"
  >
    <path
      data-tag="allowRowEvents"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 13.2011C0.5 6.19012 6.19012 0.5 13.2011 0.5C20.2122 0.5 25.9023 6.19012 25.9023 13.2011C25.9023 20.2122 20.2122 25.9023 13.2011 25.9023C6.19012 25.9023 0.5 20.2122 0.5 13.2011ZM21.1207 11.2586H5.85039L4.98276 14.8448H20.2531L21.1207 11.2586Z"
      fill="#FFE42A"
    />
  </svg>
);

export default YellowWarningIcon;
