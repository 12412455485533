import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import {
  LicenseHolderModel,
  LimitedLicenseModel,
} from "../../../../generated/from-api/models/license.model";
import { API_BASE_URL } from "../../constants";

export const LicenseService = {
  /**
   * Use to retrieve a list of current licenses
   */
  LIST: async function (email?: string): Promise<LicenseHolderModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.LICENSE}`, {
        params: { email: email },
      })
    ).data;
  },

  GET: async function (
    id: string
  ): Promise<LicenseHolderModel | LimitedLicenseModel> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.LICENSE}/${id}`)).data;
  },

  /**
   * Use to create a user with license(s)
   * @param email
   * @param expires
   */
  CREATE_LICENSE: async function (
    email: string,
    expires: string,
    licenseTierId: string,
    invoiceNumber?: string,
    paid?: boolean,
    firstName?: string,
    lastName?: string
  ): Promise<LicenseHolderModel[] | undefined> {
    const data = {
      email,
      expires,
      licenseTierId,
      invoiceNumber,
      paid,
      firstName,
      lastName,
    };
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.LICENSE}/create`, data)
    ).data;
  },

  ADD_LICENSE: async function (
    email: string
  ): Promise<LicenseHolderModel[] | undefined> {
    const data = { email };
    return (await axios.post(`${API_BASE_URL}${APIRoutes.LICENSE}/add`, data))
      .data;
  },

  DELETE_LICENSE: async function (id: string): Promise<{ deleted: boolean }> {
    return (await axios.delete(`${API_BASE_URL}${APIRoutes.LICENSE}/${id}`))
      .data;
  },

  /**
   * Used by new users to activate their licenses with activation codes.
   * License are marked unpaid by default.
   * @param activationCode
   * @param invoiceNumber
   * @returns
   */
  ACTIVATE_LICENSE: async function (
    activationCode: string,
    invoiceNumber: string
  ): Promise<LicenseHolderModel[] | undefined> {
    const data = { activationCode, invoiceNumber };
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.LICENSE}/activate`, data)
    ).data;
  },

  /**
   * Used by paid license holders to renew their licenses with a renewal code.
   * @param renewalCode
   * @param invoiceNumber
   * @returns
   */
  RENEW_LICENSE: async function (
    renewalCode: string,
    invoiceNumber: string
  ): Promise<LicenseHolderModel[] | undefined> {
    const data = { renewalCode, invoiceNumber };
    return (await axios.post(`${API_BASE_URL}${APIRoutes.LICENSE}/renew`, data))
      .data;
  },

  /**
   * Used by new users to activate trial licenses WITHOUT activation codes (changed in an attempt to streamline team creation)
   * License are marked unpaid by default.
   * @param activationCode
   * @param invoiceNumber
   * @returns
   */
  ACTIVATE_TRIAL_LICENSE: async function (): Promise<
    LicenseHolderModel[] | undefined
  > {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.LICENSE}/activate-trial`)
    ).data;
  },

  MANAGE_LICENSE: async function (
    licenseId: string,
    invoiceNumber: string,
    licenseTierId: string,
    isSuspended = false,
    paid = false
  ): Promise<LicenseHolderModel | undefined> {
    const data = { licenseId, invoiceNumber, licenseTierId, isSuspended, paid };
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.LICENSE}/manage`, data)
    ).data;
  },

  MANAGE_LICENSE_HOLDER: async function (
    licenseIds: string[],
    invoiceNumber: string,
    licenseTierId: string,
    isSuspended = false,
    paid = false,
    expirationDate: string | undefined
  ): Promise<LicenseHolderModel | undefined> {
    const data = {
      licenseIds,
      invoiceNumber,
      licenseTierId,
      isSuspended,
      paid,
      expirationDate,
    };
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.LICENSE}/holdermanage`, data)
    ).data;
  },
};
