import React, { useEffect, useRef, useState } from "react";
import { Tooltip as MaterialToolTip } from "@material-ui/core";

import styles from "./Tooltip.module.scss";
import tooltipIcon from "../../resources/images/tooltipIcon.svg";

interface TooltipProps {
  top?: number;
  left?: number;
  placement?:
    | "top"
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start";
  clickTip?: boolean;
  tip: string | React.ReactNode;
  children?: React.ReactNode;
  color?: "light" | "dark";
  noArrow?: boolean;
  noPadding?: boolean;
  className?: string;
  defaultOpen?: boolean;
  showPermanent?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  top = 0,
  left = 0,
  placement = "top",
  clickTip = false,
  tip,
  children,
  color = "light",
  noArrow = false,
  noPadding = false,
  className = "",
  defaultOpen = false,
  showPermanent = false,
}: TooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(defaultOpen);
  let mouseOver: "yes" | "no" | "stale" = "no";

  // Open
  const openTip = (e: any) => {
    if (!tooltipOpen && (!clickTip || e.type === "click")) {
      // If there is no tooltipopened then it's close, so open it.
      if (document.body.dataset.tooltipopened === undefined) {
        document.body.dataset["tooltipopened"] = "" + Date.now();
        e.target.classList.add("active");
        setTooltipOpen(true);

        // If there is a tooltipopened then we want to give it a moment to close the existing open one before opening
      }
    }
  };

  // The surprisingly complicated act of closing
  const closeTip = useRef((e: any) => {
    // less then 100ms since openTip was called do nothing
    if (
      Date.now() -
        (document.body.dataset.tooltipopened === undefined
          ? 0
          : parseInt(document.body.dataset.tooltipopened)) <
      100
    ) {
      return null;
    }
    // More than 100ms and it's not a click
    if (!clickTip) {
      if (mouseOver === "no") {
        actuallyCloseTip();
      } else {
        mouseOver = "stale";
      }
      // IF it is a click there are 2 close options
    } else if (
      (e.type === "click" &&
        e.target.closest &&
        e.target.closest(".MuiTooltip-popper") === null) ||
      (e.type === "keyup" && e.key === "Escape")
    ) {
      actuallyCloseTip();
    }
  });
  // This function will actually close the tip, since it is needed in a few places
  const actuallyCloseTip = () => {
    setTooltipOpen(false);
    delete document.body.dataset.tooltipopened;
    document
      .querySelectorAll(`.${styles.tooltipTrigger} .active`)
      .forEach((el) => {
        el.classList.remove("active");
      });
  };

  // Capture mouse over state
  const mouseMove = (e: any) => {
    if (e.type === "mouseenter") {
      mouseOver = "yes";
    } else {
      if (mouseOver === "stale") {
        actuallyCloseTip();
      }
      mouseOver = "no";
    }
  };

  // Manage events bindings
  useEffect(() => {
    if (clickTip) {
      if (tooltipOpen) {
        document.addEventListener("click", closeTip.current);
        document.addEventListener("keyup", closeTip.current);
      } else {
        document.removeEventListener("keyup", closeTip.current);
        document.removeEventListener("click", closeTip.current);
      }
    }
  }, [tooltipOpen]);

  // The component itself
  return (
    <MaterialToolTip
      className={className}
      title={
        <div onMouseEnter={mouseMove} onMouseLeave={mouseMove}>
          {tip}
        </div>
      }
      placement={placement}
      arrow={!noArrow} // This is inverse for backwards compatibility
      leaveDelay={150}
      open={tooltipOpen}
      onOpen={!showPermanent ? openTip : undefined}
      onClose={!showPermanent ? closeTip.current : undefined}
      classes={{
        tooltip: `${styles.tooltipOverride} ${color} ${
          noPadding ? "no-pad" : "pad"
        }`,
        arrow: `${styles.arrowOverride} ${color} ${
          noPadding ? "no-pad" : "pad"
        }`,
      }}
    >
      {children === undefined ? (
        <span
          className={`${styles.tooltipTrigger} ${
            styles.tooltipTriggerDefault
          } ${clickTip ? styles.tooltipTriggerClickable : ""}`}
          style={{ top: `${top}px`, left: `${left}px` }}
          onClick={openTip}
        >
          <img src={tooltipIcon} />
        </span>
      ) : (
        <span
          className={`${styles.tooltipTrigger} ${
            clickTip ? styles.tooltipTriggerClickable : ""
          }`}
          onClick={openTip}
        >
          {children}
        </span>
      )}
    </MaterialToolTip>
  );
};

export default Tooltip;
