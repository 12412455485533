import { memo } from "react";

import styles from "./ImportInstallsModal.module.scss";
import { InstallModel } from "../../../../generated/from-api/models/install.model";
import { shortenPositionsList } from "../../../../utils/functions";
import Button from "../../../../components/Button/Button";

interface Props {
  install: InstallModel;
  categories: Map<string, string> | undefined;
  onPreviewInstallCallback: React.Dispatch<
    React.SetStateAction<InstallModel | undefined>
  >;
  onAddInstallCallback: (install: InstallModel) => void;
}

const ImportInstallItem: React.FC<Props> = ({
  install,
  categories,
  onPreviewInstallCallback,
  onAddInstallCallback,
}) => {
  const name = install.name;
  const category =
    categories && install.categoryTagId
      ? categories.get(install.categoryTagId)
      : "";
  const positions = shortenPositionsList(install.showInstallToPositions).join(
    ", "
  );
  const presentedBy = "Team Nation";

  const handleClickPreview = () => {
    onPreviewInstallCallback(install);
  };
  const handleClickAdd = () => {
    onAddInstallCallback(install);
  };

  return (
    <div className={styles.listItem}>
      <div className={styles.listTitle}>
        <span className={styles.name}>{name}</span>
        <span className={styles.positions}>{positions}</span>
      </div>
      <span className={styles.presentedby}>{presentedBy}</span>
      <span className={styles.category}>{category}</span>
      <div className={styles.buttons}>
        <Button
          className={styles.previewButton}
          theme="tertiary"
          size={"small"}
          onClick={handleClickPreview}
        >
          Preview
        </Button>
        <Button
          className={styles.addButton}
          theme="secondary"
          size={"small"}
          onClick={handleClickAdd}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default memo(ImportInstallItem);
