import React, { useContext } from "react";

import styles from "./QuizCategoriesModal.module.scss";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import TagsTab from "../../../PlaybookSettings/tabs/TagsTab/TagsTab";
import { TagTypeEnum } from "../../../../generated/from-api/models/enums/tag-type.enum";
import CloseIcon from "../../../../resources/icons/CloseIcon";
import Button from "../../../../components/Button/Button";

const QuizCategoriesModal: React.FC = () => {
  const { closeModal } = useContext(UIContext);

  return (
    <div className={styles.quizCategoriesModal}>
      <Button
        className={styles.closeModalButton}
        theme={"transparent"}
        size={"small"}
        icon={<CloseIcon />}
        onClick={closeModal}
      />
      <TagsTab
        tagType={TagTypeEnum.CUSTOM_QUIZ_CATEGORY}
        customHeaderText="Categories"
      />
    </div>
  );
};

export default QuizCategoriesModal;
