import { useState, createContext } from "react";
import { PlayerWithReadinessModel } from "../../../generated/from-api/models/dtos/player-with-readiness.model";
import {
  PlayerOverallReadinessModel,
  PlayerToInstallReadinessModel,
  PlayerToPlayReadinessModel,
  PlayerToFormationReadinessModel,
  PlayerToQuizReadinessModel,
} from "../../../generated/from-api/models/dtos/player-to-play.readiness.model";
import { TeamReadinessSnapshotModel } from "../../../generated/from-api/models/readiness-snapshot/team-readiness-snapshot.model";
import { PlayerReadinessSnapshotModel } from "../../../generated/from-api/models/readiness-snapshot/player-readiness-snapshot.model";

export const ReadinessScoresContext = createContext<{
  /**
   * These are used by Dashboard 2.0
   */
  playerOverallMetrics: PlayerOverallReadinessModel[];
  setPlayerOverallMetrics: (data: PlayerOverallReadinessModel[]) => void;
  playerPlayMetrics: PlayerToPlayReadinessModel[];
  setPlayerPlayMetrics: (data: PlayerToPlayReadinessModel[]) => void;
  playerFormationMetrics: PlayerToFormationReadinessModel[];
  setPlayerFormationMetrics: (data: PlayerToFormationReadinessModel[]) => void;
  playerInstallMetrics: PlayerToInstallReadinessModel[];
  setPlayerInstallMetrics: (data: PlayerToInstallReadinessModel[]) => void;
  playerQuizMetrics: PlayerToQuizReadinessModel[];
  setPlayerQuizMetrics: (data: PlayerToQuizReadinessModel[]) => void;
  teamMetricsOverTime: TeamReadinessSnapshotModel[];
  setTeamMetricsOverTime: (data: TeamReadinessSnapshotModel[]) => void;
  selectedPlayerMetricsOverTime: PlayerReadinessSnapshotModel[];
  setSelectedPlayerMetricsOverTime: (
    data: PlayerReadinessSnapshotModel[]
  ) => void;

  /**
   * This is used by Dashboard 1.0
   */
  readinessScores: PlayerWithReadinessModel[];
  setReadinessScores: (readinessScores: PlayerWithReadinessModel[]) => void;

  /**
   * Generic cleanup
   */
  clearReadinessScores: () => void;
  getReadinessScoreForUserByType: (
    type: "category" | "gamePlan" | "formation" | "customQuiz" | "install",
    identifier: string,
    userId: string
  ) => number;
  loading: boolean;
  setLoading: (val: boolean) => void;
}>({
  // dashboard 2.0
  playerOverallMetrics: [],
  setPlayerOverallMetrics: () => null,
  playerPlayMetrics: [],
  setPlayerPlayMetrics: () => null,
  playerFormationMetrics: [],
  setPlayerFormationMetrics: () => null,
  playerInstallMetrics: [],
  setPlayerInstallMetrics: () => null,
  playerQuizMetrics: [],
  setPlayerQuizMetrics: () => null,
  teamMetricsOverTime: [],
  setTeamMetricsOverTime: () => null,
  selectedPlayerMetricsOverTime: [],
  setSelectedPlayerMetricsOverTime: () => null,

  // dashboard 1.0
  readinessScores: [],
  setReadinessScores: () => null,

  // general
  clearReadinessScores: () => null,
  getReadinessScoreForUserByType: () => 0,
  loading: true,
  setLoading: () => null,
});

export const ReadinessScoresProvider: React.FC = ({ children }) => {
  const [playerOverallMetrics, setPlayerOverallMetrics] = useState<
    PlayerOverallReadinessModel[]
  >([]);

  const [playerPlayMetrics, setPlayerPlayMetrics] = useState<
    PlayerToPlayReadinessModel[]
  >([]);

  const [playerFormationMetrics, setPlayerFormationMetrics] = useState<
    PlayerToFormationReadinessModel[]
  >([]);

  const [playerInstallMetrics, setPlayerInstallMetrics] = useState<
    PlayerToInstallReadinessModel[]
  >([]);

  const [playerQuizMetrics, setPlayerQuizMetrics] = useState<
    PlayerToQuizReadinessModel[]
  >([]);

  const [teamMetricsOverTime, setTeamMetricsOverTime] = useState<
    TeamReadinessSnapshotModel[]
  >([]);
  const [
    selectedPlayerMetricsOverTime,
    setSelectedPlayerMetricsOverTime,
  ] = useState<PlayerReadinessSnapshotModel[]>([]);

  const [readinessScores, setReadinessScores] = useState<
    PlayerWithReadinessModel[]
  >([]);

  const [loading, setLoading] = useState<boolean>(true);

  const clearReadinessScores = () => {
    setReadinessScores([]);
    setPlayerOverallMetrics([]);
    setPlayerPlayMetrics([]);
    setPlayerInstallMetrics([]);
    setPlayerQuizMetrics([]);
    setTeamMetricsOverTime([]);
  };

  const getReadinessScoreForUserByType = (
    type: "category" | "gamePlan" | "formation" | "customQuiz" | "install",
    identifier: string,
    userId: string
  ) => {
    const playerWithReadiness = readinessScores.find(
      (s) => s.userId === userId
    );
    const currentReadiness = playerWithReadiness
      ? playerWithReadiness.readinessScores
      : undefined;

    if (currentReadiness) {
      if (type === "category") {
        const categoryScore = currentReadiness.find(
          (r) => r.scoreType === identifier
        );
        if (categoryScore) {
          return categoryScore.score;
        }
      } else if (type === "gamePlan") {
        const gamePlanScore = currentReadiness.find(
          (r) => r.gamePlanId && r.gamePlanId === identifier
        );
        if (gamePlanScore) {
          return gamePlanScore.score;
        }
      } else if (type === "formation") {
        const formationScore = currentReadiness.find(
          (r) => r.scoreType === identifier
        );
        if (formationScore) {
          return formationScore.score;
        }
      } else {
        // TEMPORARY - For custom quiz scores
        return 0;
      }
    }

    return 0;
  };

  return (
    <ReadinessScoresContext.Provider
      value={{
        // dashboard 2.0
        playerOverallMetrics,
        setPlayerOverallMetrics,
        playerPlayMetrics,
        setPlayerPlayMetrics,
        playerFormationMetrics,
        setPlayerFormationMetrics,
        playerInstallMetrics,
        setPlayerInstallMetrics,
        playerQuizMetrics,
        setPlayerQuizMetrics,
        teamMetricsOverTime,
        setTeamMetricsOverTime,
        selectedPlayerMetricsOverTime,
        setSelectedPlayerMetricsOverTime,

        // dashboard 1.0
        readinessScores,
        setReadinessScores,

        clearReadinessScores,
        getReadinessScoreForUserByType,
        loading,
        setLoading,
      }}
    >
      {children}
    </ReadinessScoresContext.Provider>
  );
};
