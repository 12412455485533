import React, { useState, useEffect } from "react";

import { MediaModel } from "../../../generated/from-api/models/media.model";
import { processDataUpdates } from "../utilities/DataSync/DataSync";

export const MediaContext = React.createContext<{
  // mediaItems is what will be shown in the Media Gallery
  // this will be Team Media or Global Media, depending on if user is a coach or an admin
  mediaItems: MediaModel[];
  // mediaItemsMap will include both team media + global media
  // so that coaches and players can access global media on imported quizes
  mediaItemsMap: Record<string, MediaModel>;
  globalAndTeamMediaLoaded: boolean;
  updateMediaItem: (item: MediaModel) => void;
  addMediaItems: (items: MediaModel[]) => void;
  setMediaItems: (items: MediaModel[]) => void;
  clearMediaItems: () => void;
  removeMediaItems: (itemIds: string[]) => void;
  setGlobalMediaItems: (items: MediaModel[]) => void;
  setGlobalAndTeamMediaLoaded: (value: boolean) => void;
  consumeUpdatesForCurrentMediaLibrary: (updates: MediaModel[]) => void;
}>({
  mediaItems: [],
  mediaItemsMap: {},
  globalAndTeamMediaLoaded: false,
  updateMediaItem: () => null,
  addMediaItems: () => null,
  setMediaItems: () => null,
  clearMediaItems: () => null,
  removeMediaItems: () => null,
  setGlobalMediaItems: () => null,
  setGlobalAndTeamMediaLoaded: () => null,
  consumeUpdatesForCurrentMediaLibrary: () => null,
});

export const MediaProvider: React.FC = ({ children }) => {
  const [mediaItems, setMediaItems] = useState<MediaModel[]>([]);
  const [globalMediaItems, setGlobalMediaItems] = useState<MediaModel[]>([]);
  const [mediaItemsMap, setMediaItemsMap] = useState<
    Record<string, MediaModel>
  >({});
  const [
    globalAndTeamMediaLoaded,
    setGlobalAndTeamMediaLoaded,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (mediaItems && globalMediaItems) {
      const nextMediaItemsMap: Record<string, MediaModel> = {};
      for (const item of [...mediaItems, ...globalMediaItems]) {
        if (item.id) {
          nextMediaItemsMap[item.id] = item;
        }
      }
      setMediaItemsMap(nextMediaItemsMap);
    }
  }, [mediaItems, globalMediaItems]);

  const updateMediaItem = (itemToUpdate: MediaModel) => {
    const nextMediaItems = mediaItems.map((item) => {
      if (itemToUpdate.id !== item.id) {
        return item;
      }

      return itemToUpdate;
    });

    if (nextMediaItems) {
      setMediaItems(nextMediaItems);
    }
  };

  const addMediaItems = (itemsToAdd: MediaModel[]) => {
    const nextMediaItems = [...mediaItems, ...itemsToAdd];
    setMediaItems(nextMediaItems);
  };

  const clearMediaItems = () => {
    setMediaItems([]);
    setGlobalMediaItems([]);
  };

  const removeMediaItems = (itemIdsToDelete: string[]) => {
    const setToDelete = new Set(itemIdsToDelete);
    const nextMediaItems = mediaItems.filter(
      (item) => !setToDelete.has(item.id as string)
    );
    setMediaItems(nextMediaItems);
  };

  const consumeUpdatesForCurrentMediaLibrary = (updates: MediaModel[]) => {
    const newDataSet = processDataUpdates(updates, mediaItems);
    setMediaItems(newDataSet);
  };

  return (
    <MediaContext.Provider
      value={{
        mediaItems,
        mediaItemsMap,
        globalAndTeamMediaLoaded,
        updateMediaItem,
        addMediaItems,
        setMediaItems,
        clearMediaItems,
        removeMediaItems,
        setGlobalMediaItems,
        setGlobalAndTeamMediaLoaded,
        consumeUpdatesForCurrentMediaLibrary,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};
