import LineChart from "../../components/LineChart/LineChart";
import styles from "../PlaybookIQ.module.scss";

import BarChart from "../../components/BarChart/BarChart";
import { BarDatum } from "@nivo/bar";

interface Props {
  title?: string;
  type: "Line" | "Bar";
  isLabel?: boolean;
  isFlipped?: boolean;
  xLabel?: string;
  yLabel?: string;
  ySymbol?: string;
  data: BarDatum[];
  indexBy: string;
  valueKey: string;
  cardHeight?: string;
}

const chartDataForLine = [
  {
    id: "japan",
    color: "hsl(268, 70%, 50%)",
    data: [
      {
        x: "Oct 10",
        y: 20,
      },
      {
        x: "Oct 17",
        y: 65,
      },
      {
        x: "Oct 24",
        y: 60,
      },
      {
        x: "Oct 31",
        y: 80,
      },
      {
        x: "Nov 7",
        y: 75,
      },
    ],
  },
];

const ReadinessGraph: React.FC<Props> = ({
  title,
  type,
  isLabel,
  isFlipped,
  xLabel,
  yLabel,
  ySymbol,
  data,
  indexBy,
  valueKey,
  cardHeight = "320px",
}) => {
  return (
    <div className={styles.dashboardCard} style={{ height: cardHeight }}>
      {title && (
        <div className={styles.cardSubtitleWithBottomBorder}>{title}</div>
      )}
      <div className={styles.chartContainer}>
        {type === "Line" ? (
          chartDataForLine ? (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              No Data
            </p>
          ) : (
            <LineChart
              data={chartDataForLine}
              indexBy="category"
              valueKey="averageScore"
              xLabel={xLabel}
              yLabel={yLabel}
              ySymbol={ySymbol}
            />
          )
        ) : data.length ? (
          <BarChart
            data={data}
            indexBy={indexBy}
            valueKey={valueKey}
            isFlipped={isFlipped}
            isLabel={isLabel}
            xLabel={xLabel}
            yLabel={yLabel}
            ySymbol={ySymbol}
          />
        ) : (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            No Data
          </p>
        )}
      </div>
    </div>
  );
};

export default ReadinessGraph;
