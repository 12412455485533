import { Slider } from "@material-ui/core";

import { PlayModel } from "../../../generated/from-api/models/play.model";
import styles from "./FieldOptions.module.scss";
import {
  ballOnOptions,
  ballOnSliderValueToActualValueMap,
  ballOnActualValueToSliderValueMap,
  ballOnSliderValueMax,
} from "../../../shared/shared-with-mobile/play-editor/playEditor.constants";
import "./FieldOptions.scss";

interface Props {
  play: Omit<PlayModel, "drawablePlayers">;
  setPlay: React.Dispatch<
    React.SetStateAction<Omit<PlayModel, "drawablePlayers">>
  >;
}

const FieldOptions: React.FC<Props> = ({ play, setPlay }) => {
  return (
    <div className={styles.fieldOptions}>
      <div className={styles.col}>
        {/* TODO: Turn this UI to toggle between two options into a re-usable component */}
        <div className={styles.label}>Opponent</div>
        <div>
          <button
            className={`${styles.toggleButton} ${
              play.hideOpponent && styles.selected
            }`}
            onClick={() => {
              setPlay({
                ...play,
                hideOpponent: true,
              });
            }}
          >
            Hide
          </button>
          <button
            className={`${styles.toggleButton} ${
              !play.hideOpponent && styles.selected
            }`}
            onClick={() => {
              setPlay({
                ...play,
                hideOpponent: false,
              });
            }}
          >
            Show
          </button>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Ball On</div>
        <Slider
          track={false}
          marks={ballOnOptions}
          step={null}
          value={Number(ballOnActualValueToSliderValueMap[play.ballOn])}
          min={0}
          max={ballOnSliderValueMax}
          onChange={(e, sliderValue) => {
            const actualValue =
              ballOnSliderValueToActualValueMap[sliderValue as number];

            setPlay({
              ...play,
              ballOn: actualValue,
            });
          }}
        />
      </div>
    </div>
  );
};

export default FieldOptions;
