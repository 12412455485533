import { useContext, useState } from "react";
import { useHistory } from "react-router";

import styles from "./Header.module.scss";
import SingleSelect from "../SingleSelect/SingleSelect";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { userRoles } from "../../shared/shared-with-mobile/constants";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import Avatar from "../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
// disabling this for now
// import TooltipTextWrapper from "../../components/TooltipTextWrapper/TooltipTextWrapper";

interface Props {
  handleLogout: () => void;
}

const AccountWidget: React.FC<Props> = ({ handleLogout }) => {
  const { userProfile } = useContext(UserContext);
  const { currentTeam, teams, switchTeam } = useContext(TeamContext);
  const [accountMenuOpen, setAccountMenuOpen] = useState<boolean>(false);
  const history = useHistory();

  const handleAccountMenuClick = (value: string) => {
    if (value === "settings") {
      history.push(`/account/account-settings`);
    } else {
      handleLogout();
    }
  };

  return (
    <div className={styles.accountWidget}>
      {userProfile?.role !== userRoles.ADMIN &&
        (currentTeam ? (
          <>
            {/* <div className={styles.customLabel}>
              <TooltipTextWrapper text={currentTeam.name} />
              <div className={styles.teamType}>[{currentTeam.level}]</div>
            </div> */}
            {teams && teams.size >= 1 ? (
              <SingleSelect
                id="teamLevel"
                options={Array.from(teams).map(([key, team]) => ({
                  value: key,
                  label: `${team.name} [${team.level}]`,
                }))}
                value={currentTeam.id}
                className={styles.teamPicker}
                onChange={(e) => switchTeam(e.currentTarget.value)}
              />
            ) : (
              <div className={styles.teamPickerBg}></div>
            )}
          </>
        ) : (
          <div></div> // placeholder div to preserve css grid template columns
        ))}
      <div onClick={() => setAccountMenuOpen(!accountMenuOpen)}>
        <SingleSelect
          id="accountMenu"
          options={[
            { label: "Account Settings", value: "settings" },
            { label: "Sign Out", value: "signout" },
          ]}
          className={styles.accountMenu}
          onChange={(e) => handleAccountMenuClick(e.currentTarget.value)}
        />
        {userProfile && <Avatar userId={userProfile.id} small />}
      </div>
    </div>
  );
};

export default AccountWidget;
