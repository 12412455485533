import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { PlaySetModel } from "../../../../generated/from-api/models/play-set.model";
import { API_BASE_URL } from "../../constants";

export const PlaySetService = {
  /**
   * Retrieve a list of all playSets for a team,
   * @param teamId
   * @returns playSet[]
   */
  GET_ALL_PLAY_SETS_FOR_TEAM: async function (
    teamId: string
  ): Promise<PlaySetModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.PLAY_SET}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },
  /**
   * Use to get a play set
   * @param playSetId
   * @returns playSet
   */
  GET: async function (playSetId: string): Promise<PlaySetModel | undefined> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.PLAY_SET}/${playSetId}`)
    ).data;
  },
  POST: async function (
    playSetData: Pick<PlaySetModel, "teamId" | "name" | "playOrder">
  ): Promise<PlaySetModel | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.PLAY_SET}`, playSetData)
    ).data;
  },
  PUT: async function (
    playSetData: Pick<PlaySetModel, "id" | "teamId" | "name" | "playOrder">
  ): Promise<PlaySetModel | undefined> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.PLAY_SET}`, playSetData)
    ).data;
  },
  /**
   * Use to delete a play set
   * @param playSetId
   * @returns
   */
  DELETE: async function (playSetId: string): Promise<{ deleted: boolean }> {
    return (
      await axios.delete(`${API_BASE_URL}${APIRoutes.PLAY_SET}/${playSetId}`)
    ).data;
  },
};
