import React, { useContext, useEffect, useState } from "react";
import styles from "./InviteWithCode.module.scss";
import Switch from "../../Switch/Switch";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import Checkbox from "../../Checkbox/Checkbox";
import DatePickerInput from "../../DatePickerInput/DatePickerInput";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { InvitationCodeModel } from "../../../generated/from-api/models/invitation-code.model";

interface Props {
  className?: string;
  teamId?: string;
}

/**
 * This component is used by the new team setup modal,
 * so we can assume this team is a blank slate
 */
const InviteWithCode: React.FC<Props> = ({ className = "", teamId }) => {
  const { dispatchToast } = useContext(UIContext);

  const currentDate = new Date();
  const tomorrowDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(currentDate.setDate(currentDate.getDate() + 1));
  const [expiryDate, setExpiryDate] = useState<string>("");
  const [
    showExpirationDatePicker,
    setShowExpirationDatePicker,
  ] = useState<boolean>(!!expiryDate);
  const [newCodeLoading, setNewCodeLoading] = useState<boolean>(false);
  const [currentInvitationCode, setCurrentInvitationCode] = useState<
    InvitationCodeModel | undefined
  >();

  useEffect(() => {
    let isCancelled = false;
    const loadInvitationCode = async () => {
      if (!teamId) {
        return;
      }
      let existingCode = await APIService.INVITATION_CODE.GET(teamId);
      if (!existingCode) {
        existingCode = await APIService.INVITATION_CODE.POST({
          teamId,
        });
        // since we want code to be disabled by default (in this case)
        existingCode = await APIService.INVITATION_CODE.PUT(
          {
            teamId,
            expirationDate: null,
            disabled: true,
          },
          false
        );
      }
      if (!isCancelled && existingCode) {
        setCurrentInvitationCode(existingCode);
        const expDate: any = existingCode.expirationDate;
        if (!expDate) {
          setShowExpirationDatePicker(false);
        } else {
          setShowExpirationDatePicker(true);
          setExpiryDate(expDate);
        }
      }
    };
    loadInvitationCode();
    return () => {
      isCancelled = true;
    };
  }, [teamId]);

  const toggleShowExpirationDatePicker = async (expires: boolean) => {
    if (!currentInvitationCode) {
      return;
    }
    setShowExpirationDatePicker(expires);
    if (!expires) {
      // if the code does not expire, we should clear the expiration date
      await updateInvitationCode(null, false, currentInvitationCode.disabled);
      // reset datepicker
      setExpiryDate(tomorrowDate);
    } else {
      // if the code should expire, we set the default expiry to tomorrow
      await updateInvitationCode(
        tomorrowDate,
        false,
        currentInvitationCode.disabled
      );
    }
  };

  useEffect(() => {
    if (showExpirationDatePicker) {
      setExpiryDate(tomorrowDate);
    } else setExpiryDate("");
  }, [showExpirationDatePicker]);

  const updateInvitationCode = async (
    expirationDate: string | null,
    generateANewCode: boolean,
    disabled: boolean | undefined
  ) => {
    if (!currentInvitationCode || !teamId) {
      return;
    }
    setNewCodeLoading(true);
    try {
      const updatedCode = await APIService.INVITATION_CODE.PUT(
        {
          teamId: teamId,
          expirationDate: expirationDate,
          disabled: disabled,
        },
        generateANewCode
      );
      setCurrentInvitationCode(updatedCode);
      setShowExpirationDatePicker(
        updatedCode ? !!updatedCode.expirationDate : false
      );
      setNewCodeLoading(false);
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "There's been an error. Please try again.",
      });
      setNewCodeLoading(false);
    }
  };

  const onExpirationDateSelected = (event: MaterialUiPickersDate) => {
    if (!currentInvitationCode) {
      return;
    }
    if (event) {
      const d = new Date(event.toString());
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      const newExpirationDate = `${ye}/${mo}/${da}`;
      updateInvitationCode(
        newExpirationDate,
        false,
        currentInvitationCode.disabled
      );
      setExpiryDate(event.toString());
    }
  };

  return (
    <div className={`${styles.inviteWithCode} ${className}`}>
      <div className={styles.inviteWithCode__Top}>
        {currentInvitationCode && (
          <div className={styles.inviteWithCode__LeftSide}>
            <div className={styles.inviteWithCode__Switch}>
              <Switch
                size={"large"}
                toggled={!currentInvitationCode.disabled}
                disabled={newCodeLoading}
                onToggle={() =>
                  updateInvitationCode(
                    null,
                    false,
                    !currentInvitationCode.disabled
                  )
                }
              />
            </div>
            <div className={styles.inviteWithCode__Copy}>
              <span>Invite with code</span>
              <span>
                Activate this code to allow players to request to join your team
                by typing the code on their phones.
              </span>
            </div>
          </div>
        )}
        <div className={styles.inviteWithCode__RightSide}>
          <div className={styles.inviteWithCode__Code}>
            {!currentInvitationCode || currentInvitationCode.disabled ? (
              <span className={styles.inviteWithCode__CodePlaceholder}>
                -------
              </span>
            ) : (
              <div className={styles.inviteWithCode__CodeWrapper}>
                <span className={styles.inviteWithCode__CodeNumber}>
                  {currentInvitationCode.invitationCode}
                </span>
                <button
                  type="button"
                  className={styles.inviteWithCode__CodeCopy}
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(
                        currentInvitationCode.invitationCode ?? ""
                      );
                      dispatchToast({
                        type: "success",
                        message: "Invite code copied to clipboard.",
                      });
                    } catch (error) {
                      dispatchToast({
                        type: "error",
                        message:
                          "Invite code copy to clipboard failed. No permissions.",
                      });
                    }
                  }}
                >
                  Copy
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {currentInvitationCode && !currentInvitationCode.disabled && (
        <div className={styles.inviteWithCode__Bottom}>
          <div className={styles.inviteWithCode__CodeExpirationWrapper}>
            <Checkbox
              onChange={(e) =>
                toggleShowExpirationDatePicker(e.currentTarget.checked)
              }
              checked={showExpirationDatePicker}
            >
              Expiration
            </Checkbox>
            {showExpirationDatePicker && (
              <div className={styles.inviteWithCode__DatePickerWrapper}>
                <DatePickerInput
                  label="Expiration Date"
                  theme="dark"
                  size="x-small"
                  format={"D"}
                  onChange={(event) => onExpirationDateSelected(event)}
                  value={expiryDate}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteWithCode;
