import React from "react";
import wysiwygStyles from "../Wysiwyg.module.scss";
import { EditorState } from "draft-js";
import ToolbarBoldIcon from "../../../resources/icons/ToolbarBoldIcon";
import ToolbarItalicIcon from "../../../resources/icons/ToolbarItalicIcon";
import ToolbarUnderlineIcon from "../../../resources/icons/ToolbarUnderlineIcon";

interface Props {
  editorState: EditorState;
  mouseDownHandler: (
    styleType: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

const InlineStyleControls: React.FC<Props> = ({
  editorState,
  mouseDownHandler,
}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div>
      <button
        className={`${wysiwygStyles.wysiwygButton} ${
          currentStyle.has("BOLD") && wysiwygStyles.wysiwygButtonActive
        }`}
        type={"button"}
        onMouseDown={(event) => mouseDownHandler("BOLD", event)}
      >
        <ToolbarBoldIcon />
      </button>
      <button
        className={`${wysiwygStyles.wysiwygButton} ${
          currentStyle.has("ITALIC") && wysiwygStyles.wysiwygButtonActive
        }`}
        type={"button"}
        onMouseDown={(event) => mouseDownHandler("ITALIC", event)}
      >
        <ToolbarItalicIcon />
      </button>
      <button
        className={`${wysiwygStyles.wysiwygButton} ${
          currentStyle.has("UNDERLINE") && wysiwygStyles.wysiwygButtonActive
        }`}
        type={"button"}
        onMouseDown={(event) => mouseDownHandler("UNDERLINE", event)}
      >
        <ToolbarUnderlineIcon />
      </button>
    </div>
  );
};

export default InlineStyleControls;
