import { memo, useContext } from "react";

import styles from "./InstallPreview.module.scss";
import MediaThumbnail from "../../../../components/media/MediaThumbnail/MediaThumbnail";
import { MediaContext } from "../../../../shared/shared-with-mobile/providers/media.provider";
import { CustomQuizQuestionOption } from "../../../../generated/from-api/models/custom-quiz/custom-quiz-question-option.model";
import { CustomQuizQuestionModel } from "../../../../generated/from-api/models/custom-quiz/custom-quiz-question.model";
import Button from "../../../../components/Button/Button";

interface Props {
  question: CustomQuizQuestionModel;
  isMultipleChoice: boolean;
}

const InstallPreviewQuestion: React.FC<Props> = ({
  question,
  isMultipleChoice,
}) => {
  const { mediaItemsMap } = useContext(MediaContext);
  return (
    <div
      className={`${styles.previewPhoneSlideQuestion} ${
        isMultipleChoice ? styles.multipleChoiceQuestion : ""
      }`}
    >
      <div className={styles.previewPhoneSlideQuestionCard}>
        {question.questionMediaId ? (
          <div className={styles.previewPhoneSlideQuestionCardSplit}>
            <div className={styles.previewPhoneSlideQuestionCardSplitText}>
              {question.question}
            </div>
            <div className={styles.previewPhoneSlideQuestionCardSplitImage}>
              <MediaThumbnail
                mediaItem={mediaItemsMap[question.questionMediaId]}
              />
            </div>
          </div>
        ) : (
          <div>{question.question}</div>
        )}
      </div>
      <div className={styles.previewPhoneSlideQuestionPrompt}>
        <div className={styles.previewPhoneSlideQuestionPromptChoose}>
          {isMultipleChoice ? "CHOOSE ONE ANSWER" : "Do these cards match?"}
        </div>
        <div className={styles.previewPhoneSlideQuestionPromptInstruction}>
          {"Swipe the cards below to decide."}
        </div>
      </div>
      <div>
        {(function () {
          const correctList: CustomQuizQuestionOption[] = [];
          const wrongList: CustomQuizQuestionOption[] = [];
          if (question?.correctOptions)
            correctList.push(...question.correctOptions);
          if (question?.incorrectOptions)
            wrongList.push(...question.incorrectOptions);
          if (correctList.length === 0) {
            return null;
          }
          const correctAnswers = correctList.filter(
            (item) => !(item.optionType === "TEXT" && !item.text)
          );
          const wrongAnswers = wrongList.filter(
            (item) => !(item.optionType === "TEXT" && !item.text)
          );
          return (
            <div>
              <div className={styles.previewPhoneSlideQuestionCard}>
                {correctAnswers[0] && correctAnswers[0].mediaId && (
                  <MediaThumbnail
                    mediaItem={mediaItemsMap[correctAnswers[0].mediaId]}
                  />
                )}
                {correctAnswers[0] && correctAnswers[0].text && (
                  <span>{correctAnswers[0].text}</span>
                )}
              </div>
              {isMultipleChoice && (
                <div className={styles.previewPhoneSlideQuestionList}>
                  <div className={styles.previewPhoneSlideQuestionListSelected}>
                    A
                  </div>
                  {wrongAnswers.length >= 1 && (
                    <div className={styles.previewPhoneSlideQuestionListAnswer}>
                      B
                    </div>
                  )}
                  {wrongAnswers.length >= 2 && (
                    <div className={styles.previewPhoneSlideQuestionListAnswer}>
                      C
                    </div>
                  )}
                  {wrongAnswers.length >= 3 && (
                    <div className={styles.previewPhoneSlideQuestionListAnswer}>
                      D
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })()}
      </div>
      {isMultipleChoice && (
        <div className={styles.previewPhoneSlideQuestionSubmit}>
          <Button theme="primary" size="x-small" width="full">
            Submit Answer
          </Button>
        </div>
      )}
    </div>
  );
};

export default memo(InstallPreviewQuestion);
