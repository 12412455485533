export const APIRoutes = {
  USER: "/user",
  USER_PROFILE: "/user_profile",
  TEAM: "/team",
  INVITATION: "/invitation",
  LICENSE: "/license",
  PLAY: "/play",
  PLAY_SET: "/playSet",
  PLAYER: "/player",
  COACH: "/coach",
  FORMATION: "/formation",
  GAME_PLAN: "/gamePlan",
  TAG: "/tag",
  GAME: "/game",
  DASHBOARD: "/dashboard", // dashboard 1.0
  TASK: "/task",
  CUSTOM_QUIZ: "/custom_quiz",
  MEDIA: "/media",
  MEDIA_TAGS: "/media_tags",
  PUB_SUB_JOB: "/pub_sub_job",
  INSTALL: "/install_V2", // V2 is required to avoid backwards compatability issues after old installs were deprecated
  INSTALL_STATISTIC: "/install_statistic",
  CUSTOM_QUIZ_STATISTIC: "/custom_quiz_statistic",
  LICENSE_QUOTA: "/license_quota",
  LICENSE_TIER: "/license_tier",
  INVITATION_CODE: "/invitation_code",
  OVERALL_READINESS: "/overall_readiness", // dashboard 2.0
  DEVICE_REGISTRATION: "/device_registration",
  NOTIFICATIONS: "/notifications",
  USER_REPRESENTATIVE: "/user_representative",
  IMPERSONATION: "/impersonation",
  TAG_FOLDER: "/tag_folder",
  VERSION: "/version",
};
