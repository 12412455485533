import React, { useState } from "react";

import { MediaTagListModel } from "../../../generated/from-api/models/media.model";

export const MediaTagContext = React.createContext<{
  mediaItemTags: MediaTagListModel[];
  setMediaItemTags: (items: MediaTagListModel[]) => void;
  addMediaItemsTags: (items: MediaTagListModel[]) => void;
  updateMediaItemsTags: (items: MediaTagListModel[]) => void;
  removeMediaItemsTags: (mediaIds: string[], itemIds: string[]) => void;
  clearMediaItemsTags: () => void;
}>({
  mediaItemTags: [],
  setMediaItemTags: () => null,
  addMediaItemsTags: () => null,
  updateMediaItemsTags: () => null,
  removeMediaItemsTags: () => null,
  clearMediaItemsTags: () => null,
});

export const MediaTagProvider: React.FC = ({ children }) => {
  const [mediaItemTags, setMediaItemTags] = useState<MediaTagListModel[]>([]);

  const addMediaItemsTags = (itemsToAdd: MediaTagListModel[]) => {
    const nextMediaItems = [...mediaItemTags, ...itemsToAdd];

    setMediaItemTags(nextMediaItems);
  };

  const updateMediaItemsTags = (itemsToUpdate: MediaTagListModel[]) => {
    const newMediaItems = itemsToUpdate
      .filter(
        (elem: MediaTagListModel) =>
          !mediaItemTags.find(({ mediaId }: any) => elem.mediaId === mediaId)
      )
      .map((e) => e as MediaTagListModel);

    const nextMediaItems = mediaItemTags.map((item) => {
      const mediaItemsInUpdateArray = itemsToUpdate.find(
        (itemToUpdate) => itemToUpdate.mediaId === item.mediaId
      );
      if (!mediaItemsInUpdateArray) {
        return item;
      }

      return mediaItemsInUpdateArray;
    });

    if (nextMediaItems && newMediaItems) {
      setMediaItemTags([...nextMediaItems, ...newMediaItems]);
    } else if (nextMediaItems && newMediaItems.length === 0) {
      setMediaItemTags(nextMediaItems);
    } else if (newMediaItems && nextMediaItems.length === 0) {
      setMediaItemTags([...mediaItemTags, ...newMediaItems]);
    }
  };

  const removeMediaItemsTags = (
    mediaIds: string[],
    itemIdsToDelete: string[]
  ) => {
    const setToDelete = new Set(itemIdsToDelete);

    const nextMediaItems = mediaItemTags.map((item) => {
      if (mediaIds.includes(item.mediaId)) {
        const removeTagsFromMedia = {
          ...item,
          tags: item.tags.filter((tag) => !setToDelete.has(tag.id as string)),
        };
        return removeTagsFromMedia;
      } else {
        return item;
      }
    });

    setMediaItemTags(nextMediaItems);
  };

  const clearMediaItemsTags = () => {
    setMediaItemTags([]);
  };

  return (
    <MediaTagContext.Provider
      value={{
        mediaItemTags,
        setMediaItemTags,
        addMediaItemsTags,
        updateMediaItemsTags,
        removeMediaItemsTags,
        clearMediaItemsTags,
      }}
    >
      {children}
    </MediaTagContext.Provider>
  );
};
