import React, { useContext, useEffect, useState } from "react";
import styles from "./AddToPlaySet.module.scss";

import { PlayModel } from "../../../generated/from-api/models/play.model";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import Button from "../../../components/Button/Button";
import CharacterInput from "../../../components/Input/CharacterInput";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import searchIcon from "../../../resources/images/search.svg";
import CloseIconSvg from "../../../resources/images/close.svg";
// import PencilIcon from "../../../resources/icons/PencilIcon";
// import CheckIcon from "../../../resources/icons/CheckIcon";
// import CloseIcon from "../../../resources/icons/CloseIcon";
import { PlaySetsContext } from "../../../shared/shared-with-mobile/providers/playSets.provider";
import { PlaySetModel } from "../../../generated/from-api/models/play-set.model";
import { PlaybookContext } from "../../../shared/shared-with-mobile/providers/playbook.provider";

interface Props {
  selectedPlays: Map<string, PlayModel>;
  clearSelectedPlays: () => void;
}

const AddToPlaySet: React.FC<Props> = ({
  selectedPlays,
  clearSelectedPlays,
}) => {
  const { closeModal, dispatchToast } = useContext(UIContext);
  const { playSets, updatePlaySet } = useContext(PlaySetsContext);
  const { setCurrentPlaybook, setGlobalPlaybook } = useContext(PlaybookContext);
  const [searchText, setSearchText] = useState("");
  const [filteredResult, setFilteredResult] = useState<PlaySetModel[]>();

  const [selectedPlaySet, setSelectedPlaySet] = useState<PlaySetModel>();

  // TODO: perform this deduplication on API side
  const preventAddingDuplicatePlaysToPlaySet = (
    playIdsToAdd: string[],
    allPlaySetsPlayIds: string[],
    playSetPlayIds: string[]
  ): string[] => {
    const idsToAddInSelectedPlaySet = new Set(playSetPlayIds);
    const allPlaysIdsThorughAllPlaySets = new Set(allPlaySetsPlayIds);

    if (playIdsToAdd.length > 0) {
      for (const id of playIdsToAdd) {
        if (!allPlaysIdsThorughAllPlaySets.has(id)) {
          idsToAddInSelectedPlaySet.add(id);
        }
      }
    }

    return Array.from(idsToAddInSelectedPlaySet);
  };

  useEffect(() => {
    const sortedPlaySets = playSets.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const filterByPlaySetName = sortedPlaySets.filter((gameplan) => {
      if (gameplan.name.toLowerCase().search(searchText.toLowerCase()) >= 0) {
        return true;
      }
    });
    setFilteredResult(filterByPlaySetName);
  }, [searchText]);

  const addPlaysToPlaySet = async (playSet: PlaySetModel) => {
    const playIdsToAdd = Array.from(selectedPlays.keys());
    let playIds: string[] = [];

    const selectedPlaySet = playSets.find((i) => i.id === playSet.id);

    if (playSets && selectedPlaySet) {
      const associatedPlaysIdsWithAllPlaySets = playSets
        .map((playSet) =>
          playSet.playOrder.map((play) => play.playId as string)
        )
        .reduce((p, c) => p.concat(c));

      const playSetPlayIds = selectedPlaySet.playOrder.map(
        (play) => play.playId as string
      );

      playIds = preventAddingDuplicatePlaysToPlaySet(
        playIdsToAdd,
        associatedPlaysIdsWithAllPlaySets,
        playSetPlayIds
      );
    }

    if (playSet.id) {
      try {
        const response = await APIService.PLAY_SET.PUT({
          id: playSet.id as string,
          name: playSet.name,
          teamId: playSet.teamId,
          playOrder: playIds.map((playId) => ({
            playId,
          })),
        });

        if (response) {
          const updatedPlaySet = await APIService.PLAY_SET.GET(
            playSet.id as string
          );
          if (updatedPlaySet) {
            updatePlaySet(updatedPlaySet);
          }

          if (playSet.teamId) {
            const plays = await APIService.PLAY.LIST(playSet.teamId);

            if (plays) {
              setCurrentPlaybook(plays);
            }

            const globalPlays = await APIService.PLAY.LIST();

            if (globalPlays) {
              setGlobalPlaybook(globalPlays);
            }
          }

          dispatchToast({
            type: "success",
            message: `${playIdsToAdd.length} play${
              playIdsToAdd.length === 1 ? "" : "s"
            } added to play set.`,
          });
          clearSelectedPlays();
          closeModal();
        }
      } catch (e) {
        dispatchToast({
          type: "error",
          message: "There's been an error. Please try again.",
        });
      }
    }
  };

  return (
    <div className={styles.addToPlaySetContainer}>
      <div className={styles.modalHeader}>
        <span>Add plays to set </span>

        <span
          className={styles.closeIcon}
          onClick={() => {
            clearSelectedPlays();
            closeModal();
          }}
        >
          <img src={CloseIconSvg} alt="close-icon" />
        </span>
      </div>
      <div className={styles.searchBarSection}>
        <CharacterInput
          type={INPUT_TYPES.TEXT}
          placeholder="Search"
          id="search"
          size="x-small"
          icon={searchIcon}
          clearButton
          value={searchText}
          onChange={(e) => setSearchText(e.currentTarget.value)}
        />
      </div>
      <div className={styles.playSetsListSection}>
        {!!filteredResult && filteredResult.length > 0 ? (
          filteredResult?.map((gamePlan: PlaySetModel) => {
            return (
              <Button
                key={gamePlan.id}
                className={`${
                  gamePlan.id === selectedPlaySet?.id
                    ? `${styles.playSetBtns} ${styles.active}`
                    : styles.playSetBtns
                }`}
                theme="tertiary"
                size={"small"}
                onClick={() => setSelectedPlaySet(gamePlan)}
              >
                {gamePlan.name}
              </Button>
            );
          })
        ) : (
          <p className={styles.resultNotFound}> Result Not Found</p>
        )}
      </div>

      <div className={styles.modalFooter}>
        <Button
          disabled={!selectedPlaySet}
          className={styles.createBtn}
          size={"small"}
          onClick={() =>
            !!selectedPlaySet && addPlaysToPlaySet(selectedPlaySet)
          }
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddToPlaySet;
