import { useContext } from "react";

import mean from "lodash/mean";

import LineChart from "../../components/LineChart/LineChart";
import { ReadinessScoresContext } from "../../providers/readinessScores.provider";
import styles from "../PlaybookIQ.module.scss";

import BarChart from "../../components/BarChart/BarChart";
import { hasPositionType } from "../../functions";
import { PlayerOverallReadinessModel } from "../../../../generated/from-api/models/dtos/player-to-play.readiness.model";

type ScoreType =
  | "overallScore"
  | "playbookReadinessScore"
  | "installReadinessScore"
  | "quizReadinessScore";

interface Props {
  title?: string;
  type: "Line" | "Bar-Distribution" | "Bar-Unit";
  isLabel?: boolean;
  xLabel?: string;
  yLabel?: string;
  ySymbol?: string;
  scoreType: ScoreType;
}

const OverallReadiness: React.FC<Props> = ({
  title,
  type,
  isLabel,
  xLabel,
  yLabel,
  ySymbol,
  scoreType,
}) => {
  const { playerOverallMetrics } = useContext(ReadinessScoresContext);
  const playersMetricsToDisplay = playerOverallMetrics.filter(
    (player: PlayerOverallReadinessModel) => !player.playerHideOnLeaderBoard
  );

  const chartDataForLine: any = [
    {
      id: "japan",
      color: "hsl(268, 70%, 50%)",
      data: [
        {
          x: "Oct 10",
          y: 20,
        },
        {
          x: "Oct 17",
          y: 65,
        },
        {
          x: "Oct 24",
          y: 60,
        },
        {
          x: "Oct 31",
          y: 80,
        },
        {
          x: "Nov 7",
          y: 75,
        },
      ],
    },
  ];

  return (
    <div className={styles.dashboardCard} style={{ height: "320px" }}>
      <div className={styles.cardSubtitleWithBottomBorder}>{title}</div>
      <div className={styles.chartContainer}>
        {type === "Line" ? (
          chartDataForLine ? (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              No Data
            </p>
          ) : chartDataForLine.length ? (
            <LineChart
              data={chartDataForLine}
              indexBy="category"
              valueKey="averageScore"
              xLabel={xLabel}
              yLabel={yLabel}
              ySymbol={ySymbol}
            />
          ) : (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              No Data
            </p>
          )
        ) : type === "Bar-Distribution" ? (
          <BarChart
            data={formatDistributionChartData(
              playersMetricsToDisplay,
              scoreType
            )}
            indexBy="xValue"
            valueKey="yValue"
            isLabel={isLabel}
            xLabel={xLabel}
            yLabel={yLabel}
            ySymbol={ySymbol}
          />
        ) : (
          <BarChart
            data={formatReadinessByUnitChartData(
              playersMetricsToDisplay,
              scoreType
            )}
            indexBy="category"
            valueKey="averageScore"
            xLabel={xLabel}
            yLabel={yLabel}
            ySymbol={ySymbol}
          />
        )}
      </div>
    </div>
  );
};

export default OverallReadiness;

const formatReadinessByUnitChartData = (
  players: PlayerOverallReadinessModel[],
  scoreType: ScoreType
) => {
  const offensiveScores = players
    .filter((player) => {
      return (
        player.playerPositions &&
        hasPositionType(player.playerPositions.split("|"), "offensive")
      );
    })
    .map((player) => Number(player[scoreType]));
  const defensiveScores = players
    .filter((player) => {
      return (
        player.playerPositions &&
        hasPositionType(player.playerPositions.split("|"), "defensive")
      );
    })
    .map((player) => Number(player[scoreType]));

  const specialTeamsScores = players
    .filter((player) => {
      return (
        player.playerPositions &&
        hasPositionType(player.playerPositions.split("|"), "specialTeams")
      );
    })
    .map((player) => Number(player[scoreType]));

  const offensiveAvg = mean(offensiveScores) || 0;
  const defensiveAvg = mean(defensiveScores) || 0;
  const specialTeamsAvg = mean(specialTeamsScores) || 0;

  return [
    {
      category: "Offense",
      averageScore: offensiveAvg,
    },
    {
      category: "Defense",
      averageScore: defensiveAvg,
    },
    {
      category: "S.T.",
      averageScore: specialTeamsAvg,
    },
  ];
};

const formatDistributionChartData = (
  players: PlayerOverallReadinessModel[],
  scoreType: ScoreType
) => {
  // we'll mutate these bucket objects below (calculating "playerCount" and "percentage" properties)
  const buckets = [
    {
      min: 0,
      max: 25,
      playerCount: 0,
      percentage: 0,
    },
    {
      min: 26,
      max: 50,
      playerCount: 0,
      percentage: 0,
    },
    {
      min: 51,
      max: 75,
      playerCount: 0,
      percentage: 0,
    },
    {
      min: 76,
      max: 100,
      playerCount: 0,
      percentage: 0,
    },
  ];

  players.forEach((player) => {
    const rounded = Math.round(Number(player[scoreType]));

    buckets.forEach((bucket) => {
      if (rounded >= bucket.min && rounded <= bucket.max) {
        bucket.playerCount++;
        bucket.percentage = (bucket.playerCount / players.length) * 100;
      }
    });
  });

  return buckets.map((bucket) => {
    const xValue = `${bucket.min}-${bucket.max}%`;
    const yValue = bucket.percentage;
    const playerCount = bucket.playerCount;
    return {
      xValue,
      yValue,
      playerCount,
    };
  });
};
