import { useState, useContext } from "react";
import clone from "lodash/clone";

import styles from "../../PlaybookSettings.module.scss";
import { tagCategories } from "../../../../shared/shared-with-mobile/play-editor/playEditor.constants";
import CharacterInput from "../../../../components/Input/CharacterInput";
import TrashIcon from "../../../../resources/icons/TrashIcon";
import PlusIcon from "../../../../resources/icons/PlusIcon";
import { TagModel } from "../../../../generated/from-api/models/tag.model";
import { TeamContext } from "../../../../shared/shared-with-mobile/providers/team.provider";
import { TagCategoryEnum } from "../../../../generated/from-api/models/enums/tag-category.enum";
import { TagTypeEnum } from "../../../../generated/from-api/models/enums/tag-type.enum";
import { APIService } from "../../../../shared/shared-with-mobile/api-client/api.service";
import { TagsContext } from "../../../../shared/shared-with-mobile/providers/tags.provider";
import Button from "../../../../components/Button/Button";

type InputsByCategory = Record<TagCategoryEnum, string>;
type SchemesByCategory = Record<TagCategoryEnum, TagModel[]>;

const initialInputs: InputsByCategory = {
  [TagCategoryEnum.RUN]: "",
  [TagCategoryEnum.PASS]: "",
  [TagCategoryEnum.DEFENSE]: "",
  [TagCategoryEnum.SPECIAL_TEAMS]: "",
};

const SchemesTab: React.FC = () => {
  const { currentTeam } = useContext(TeamContext);
  const {
    runSchemeTags,
    passSchemeTags,
    defenseSchemeTags,
    addSchemeTag,
    updateSchemeTag,
    removeSchemeTag,
  } = useContext(TagsContext);

  // state for inputs representing existing schemes, one array of strings for each category
  const schemes: SchemesByCategory = {
    [TagCategoryEnum.RUN]: runSchemeTags,
    [TagCategoryEnum.PASS]: passSchemeTags,
    [TagCategoryEnum.DEFENSE]: defenseSchemeTags,
    [TagCategoryEnum.SPECIAL_TEAMS]: [],
  };

  // state for inputs used to add new schemes, one string for each category
  const [newInputs, setNewInputs] = useState<InputsByCategory>(
    clone(initialInputs)
  );

  const addScheme = async (category: TagCategoryEnum) => {
    const newSchemeName = newInputs[category];

    const teamId: string = currentTeam?.id || "";

    const newTag: TagModel = {
      name: newSchemeName,
      teamId,
      type: TagTypeEnum.SCHEME,
      category: category,
      deleted: false,
      createdBy: "",
    };

    const created = await APIService.TAG.POST(newTag);

    if (created) {
      addSchemeTag(created, category);

      const nextNewInputs = { ...newInputs };
      nextNewInputs[category] = "";
      setNewInputs(nextNewInputs);
    }
  };

  const deleteScheme = async (
    tagToDelete: TagModel,
    category: TagCategoryEnum
  ) => {
    if (tagToDelete.id) {
      const response = await APIService.TAG.DELETE(tagToDelete.id);
      if (response.deleted) {
        removeSchemeTag(tagToDelete, category);
      }
    }
  };

  const updateScheme = async (
    tag: TagModel,
    newTagName: string,
    category: TagCategoryEnum
  ) => {
    if (tag.id) {
      const updated = await APIService.TAG.PUT(tag.id, {
        name: newTagName,
      });

      if (updated) {
        updateSchemeTag(updated, category);
      }
    }
  };
  return (
    <div className={`${styles.tabContent} ${styles.tagsTab}`}>
      <div className={styles.tabHeader}>Subcategories</div>
      {tagCategories.map((category: TagCategoryEnum) => (
        <div key={category} className={styles.categorySection}>
          <div key={category} className={styles.categoryName}>
            {category}
          </div>
          {schemes[category].map((tag: TagModel) => (
            <div key={tag.id} className={styles.inputRow}>
              <CharacterInput
                value={tag.name}
                maxLength={100}
                onBlur={(e) => {
                  updateScheme(tag, e.target.value, category);
                }}
              />
              <Button
                icon={<TrashIcon />}
                theme={"transparent"}
                className={styles.button}
                onClick={() => {
                  deleteScheme(tag, category);
                }}
              />
            </div>
          ))}
          <div className={styles.inputRow}>
            <CharacterInput
              placeholder="Add new"
              maxLength={100}
              value={newInputs[category]}
              onChange={(e) => {
                setNewInputs({
                  ...newInputs,
                  [category]: e.target.value,
                });
              }}
            />

            <Button
              className={`${styles.button} ${styles.plusIcon} ${
                newInputs[category] && styles.showing
              }`}
              theme={"transparent"}
              icon={<PlusIcon />}
              onClick={() => {
                addScheme(category);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SchemesTab;
