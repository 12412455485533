import { useState } from "react";
import { serialize, deserialize } from "superjson";

/*
    This function returns the same things in the same order as useState however it will keep the
    value synced with the users localStorage in avoid inter-page sync loss (even when reactive).
*/
export function useLocallySyncedState(
  key: string,
  defaultValue: string | boolean | unknown
): any[] {
  // Get the existing value if there is one, this should be the default
  if (typeof window !== "undefined" && window.localStorage[key] !== undefined) {
    try {
      const decodedLocalState = deserialize(
        JSON.parse(window.localStorage[key])
      );
      defaultValue = decodedLocalState;
    } catch (e) {
      /* eslint-disable no-console */
      console.log(
        '𝕝𝕠𝕔𝕒𝕝𝕝𝕪𝕊𝕪𝕟𝕔𝕖𝕕𝕊𝕥𝕒𝕥𝕖 encountered a problem decoding the synced localStorage copy of "' +
          key +
          '", created with initially provided default.'
      );
    }
  }
  // Get the main React state so we can wrap the set function
  const [getState, setState] = useState(defaultValue);
  const setAndSyncState = (newValue: any) => {
    window.localStorage[key] = JSON.stringify(serialize(newValue));
    setState(newValue);
  };
  return [getState, setAndSyncState];
}
