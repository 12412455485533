import shuffle from "lodash/shuffle";

import { CustomQuizModel } from "../models/custom-quiz.model";
import { CustomQuizQuestionOption } from "../models/custom-quiz/custom-quiz-question-option.model";
import { CustomQuizQuestionModel } from "../models/custom-quiz/custom-quiz-question.model";
import { MediaModel } from "../models/media.model";
import { MediaUtility } from "./media.utility";

// for each term, we should generate 3 cards to display below it, only 1 is correct
// if the correct card is shown first,
// const MAX_CARDS_PER_GAME = 10;
const NUM_OPTIONS_PER_TERM_CARD_SWIPE_GAME = 3;

export interface CardSwipingCardModel {
  textValue?: string;
  media?: MediaModel;
}

export interface CardSwipingQuestionModel {
  id: string;
  topCard: CardSwipingCardModel;
  bottomCards: CardSwipingCardModel[];
  correctAnswerCard: CardSwipingCardModel;
}

export interface CustomQuizQuestionResultModel {
  text: string;
  isCorrect: boolean;
}

export const CustomQuizUtility = {
  /**
   * Use this to validate a quiz before displaying it to players; or displaying a quiz in the global import modal.
   *
   * We can use this to alert coaches that they need to fix a quiz; or to prevent players from trying to play a quiz that is no longer valid.
   * A quiz may be invalid due to:
   * - deleted media
   * - invalid questions
   *
   * We should perform this on the web/mobile apps (clientside).
   *
   * @param quiz
   * @param fullMediaList
   * @returns
   */
  isQuizValid: (
    quiz: CustomQuizModel,
    fullMediaList: MediaModel[]
  ): {
    valid: boolean;

    deletedMediaErrors: {
      containsDeletedMedia: boolean;
      deletedMediaIds: string[];
      questionsWithDeletedMedia: CustomQuizQuestionModel[];
    };

    generalErrors: {
      quizIsArchived: boolean;
      questionsWithBlankOptions: string[];
      questionsWithBlankTerms: string[];
    };

    cardSwipingGameErrors: {
      insufficentUniqueDefinitions: boolean;
      numberOfAdditionalUniqueDefinitionsNeeded: number;
      questionsMissingACorrectOption: CustomQuizQuestionModel[];
    };

    multipleChoiceGameErrors: {
      questionsMissingACorrectOption: CustomQuizQuestionModel[];
      questionsMissingAnIncorrectOption: CustomQuizQuestionModel[];
    };
  } => {
    // check for deleted media
    const {
      containsDeletedMedia,
      deletedMediaIds,
      questionsWithDeletedMedia,
    } = CustomQuizUtility.doesQuizContainDeletedMedia(quiz, fullMediaList);

    const deletedMediaErrorsObject = {
      containsDeletedMedia: containsDeletedMedia,
      deletedMediaIds: deletedMediaIds,
      questionsWithDeletedMedia: questionsWithDeletedMedia,
    };

    let cardSwipingGameResult = {
      insufficentUniqueDefinitions: false,
      numberOfAdditionalUniqueDefinitionsNeeded: 0,
      questionsMissingACorrectOption: <CustomQuizQuestionModel[]>[],
    };
    let multipleChoiceGameResult = {
      questionsMissingACorrectOption: <CustomQuizQuestionModel[]>[],
      questionsMissingAnIncorrectOption: <CustomQuizQuestionModel[]>[],
    };
    const quizIsArchived = !!quiz.archived;
    const generalErrorsResult = {
      quizIsArchived,
      questionsWithBlankOptions: <string[]>[],
      questionsWithBlankTerms: <string[]>[],
    };

    quiz.questions.forEach((q) => {
      const allOpts = [...q.correctOptions, ...q.incorrectOptions];
      if (allOpts.find((opt) => opt.optionType === "TEXT" && !opt.text)) {
        generalErrorsResult.questionsWithBlankOptions.push(q.id);
      }
      if (!q.question) {
        generalErrorsResult.questionsWithBlankTerms.push(q.id);
      }
    });

    if (quiz.autoGenerateQuestions) {
      // check if quiz is valid for generating a card swiping game
      const numberOfUniqueOptions = CustomQuizUtility.getListOfUniqueOptionsForCardSwipingGame(
        quiz
      );
      const questionsMissingACorrectOption: CustomQuizQuestionModel[] = [];
      quiz.questions.forEach((q) => {
        if (q.correctOptions.length < 1) {
          questionsMissingACorrectOption.push(q);
        }
      });

      cardSwipingGameResult = {
        insufficentUniqueDefinitions:
          numberOfUniqueOptions.length < NUM_OPTIONS_PER_TERM_CARD_SWIPE_GAME,
        numberOfAdditionalUniqueDefinitionsNeeded:
          NUM_OPTIONS_PER_TERM_CARD_SWIPE_GAME - numberOfUniqueOptions.length,
        questionsMissingACorrectOption: questionsMissingACorrectOption,
      };
    } else {
      // check for multiple choice question validity
      // We can generate a multiple choice question as long as there is at least 1 correct, and 1 incorrect option
      const questionsMissingACorrectOption: CustomQuizQuestionModel[] = [];
      const questionsMissingAnIncorrectOption: CustomQuizQuestionModel[] = [];
      quiz.questions.forEach((q) => {
        if (q.correctOptions.length < 1) {
          questionsMissingACorrectOption.push(q);
        }
        if (q.incorrectOptions.length < 1) {
          questionsMissingAnIncorrectOption.push(q);
        }
      });
      multipleChoiceGameResult = {
        questionsMissingACorrectOption: questionsMissingACorrectOption,
        questionsMissingAnIncorrectOption: questionsMissingAnIncorrectOption,
      };
    }

    const isValid =
      !containsDeletedMedia &&
      !deletedMediaErrorsObject.containsDeletedMedia &&
      !generalErrorsResult.quizIsArchived &&
      !generalErrorsResult.questionsWithBlankOptions.length &&
      !generalErrorsResult.questionsWithBlankTerms.length &&
      !multipleChoiceGameResult.questionsMissingACorrectOption.length &&
      !multipleChoiceGameResult.questionsMissingAnIncorrectOption.length &&
      !cardSwipingGameResult.insufficentUniqueDefinitions &&
      !cardSwipingGameResult.questionsMissingACorrectOption.length;

    return {
      valid: isValid,
      deletedMediaErrors: deletedMediaErrorsObject,
      generalErrors: generalErrorsResult,
      cardSwipingGameErrors: cardSwipingGameResult,
      multipleChoiceGameErrors: multipleChoiceGameResult,
    };
  },

  /**
   * Shortcut method for checking if a single quiz contains deleted media.
   *
   * @param quiz
   * @param fullMediaList
   * @returns
   */
  doesQuizContainDeletedMedia: (
    quiz: CustomQuizModel,
    fullMediaList: MediaModel[]
  ): {
    containsDeletedMedia: boolean;
    deletedMediaIds: string[];
    questionsWithDeletedMedia: CustomQuizQuestionModel[];
  } => {
    const deletedMediaIds = MediaUtility.identifyMediaThatNoLongerExists(
      fullMediaList,
      [quiz]
    );
    const containsDeletedMedia = deletedMediaIds.length !== 0;
    const questionWithDeletedMedia: CustomQuizQuestionModel[] = [];

    if (containsDeletedMedia) {
      quiz.questions.forEach((q) => {
        const optionsWithDeletedMedia = q.correctOptions.find((q) =>
          deletedMediaIds.find((id) => q.mediaId === id)
        );
        const incorrectOptionsWithDeletedMedia = q.incorrectOptions.find((q) =>
          deletedMediaIds.find((id) => q.mediaId === id)
        );
        if (optionsWithDeletedMedia || incorrectOptionsWithDeletedMedia) {
          questionWithDeletedMedia.push(q);
        }
      });
    }

    return {
      containsDeletedMedia,
      deletedMediaIds,
      questionsWithDeletedMedia: questionWithDeletedMedia,
    };
  },

  areQuestionOptionsIdentical: (
    option1: CustomQuizQuestionOption,
    option2: CustomQuizQuestionOption
  ): boolean => {
    if (option1.optionType !== option2.optionType) {
      return false;
    }
    // check if text value is the same (case insensitive)
    if (
      option1.text &&
      option2.text &&
      option1.text.toLowerCase() === option2.text.toLowerCase()
    ) {
      return true;
    }
    // check id media Id is the same
    if (
      option1.mediaId &&
      option2.mediaId &&
      option1.mediaId === option2.mediaId
    ) {
      return true;
    }
    return false;
  },

  areCardOptionsIdentical: (
    card1: CardSwipingCardModel,
    card2: CardSwipingCardModel
  ): boolean => {
    if (card1.media && card2.media) {
      return card1.media.id === card2.media.id;
    }

    if (card1.textValue && card2.textValue) {
      return card1.textValue === card2.textValue;
    }

    return false;
  },

  getListOfUniqueOptionsForCardSwipingGame: (
    quiz: CustomQuizModel
  ): CustomQuizQuestionOption[] => {
    const allOptions: CustomQuizQuestionOption[] = [];
    quiz.questions.forEach((q) => allOptions.push(...q.correctOptions));

    const allUniqueOptions: CustomQuizQuestionOption[] = [];
    allOptions.forEach((opt) => {
      const duplicateOptionExists = allUniqueOptions.find((el) =>
        CustomQuizUtility.areQuestionOptionsIdentical(el, opt)
      );
      if (!duplicateOptionExists) {
        allUniqueOptions.push(opt);
      }
    });
    return allUniqueOptions;
  },

  // TODO: also handle global media items
  convertQuizQuestionOptionToCard: (
    option: CustomQuizQuestionOption,
    mediaItemsMap: Record<string, MediaModel>
  ): CardSwipingCardModel => {
    const card: CardSwipingCardModel = {
      textValue: option.text,
      media: option.mediaId ? mediaItemsMap[option.mediaId] : undefined,
    };

    return card;
  },

  generateQuestionsFromQuizForCardSwipeGame: (
    quiz: CustomQuizModel,
    mediaItemsMap: Record<string, MediaModel>
  ): CardSwipingQuestionModel[] => {
    // SETUP
    const questions = quiz.questions;
    const allUniqueOptions: CustomQuizQuestionOption[] = CustomQuizUtility.getListOfUniqueOptionsForCardSwipingGame(
      quiz
    );

    // BEGIN GENERATING SET OF QUESTIONS
    const cardQuestions: CardSwipingQuestionModel[] = [];

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];

      const { correctOptions } = question;
      const randomCorrectOptionIndex = Math.floor(
        Math.random() * correctOptions.length
      );
      const randomCorrectOption = correctOptions[randomCorrectOptionIndex];
      const correctCard = CustomQuizUtility.convertQuizQuestionOptionToCard(
        randomCorrectOption,
        mediaItemsMap
      );

      const newCardQuestion: CardSwipingQuestionModel = {
        id: String(i),
        topCard: {
          textValue: question.question,
          media: question.questionMediaId
            ? mediaItemsMap[question.questionMediaId]
            : undefined,
        },
        bottomCards: [correctCard], // initialize bottomCards with one correct option
        correctAnswerCard: correctCard,
      };

      // add two incorrect options to the bottomCards
      const shuffledUniqueOptions = shuffle(allUniqueOptions);
      for (const option of shuffledUniqueOptions) {
        let isOptionCorrect = false;

        for (const correctOption of correctOptions) {
          if (
            CustomQuizUtility.areQuestionOptionsIdentical(option, correctOption)
          ) {
            isOptionCorrect = true;
          }
        }

        if (!isOptionCorrect) {
          const incorrectCard = CustomQuizUtility.convertQuizQuestionOptionToCard(
            option,
            mediaItemsMap
          );
          newCardQuestion.bottomCards.push(incorrectCard);
          if (
            newCardQuestion.bottomCards.length >=
            NUM_OPTIONS_PER_TERM_CARD_SWIPE_GAME
          ) {
            break;
          }
        }
      }

      newCardQuestion.bottomCards = shuffle(newCardQuestion.bottomCards);

      cardQuestions.push(newCardQuestion);
    }

    return shuffle(cardQuestions);
  },
  generateQuestionsFromQuizForMultipleChoiceGame: (
    quiz: CustomQuizModel,
    mediaItemsMap: Record<string, MediaModel>
  ): any => {
    const questions = quiz.questions;
    const cardQuestions: any = [];
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];

      const { correctOptions, incorrectOptions } = question;
      const randomCorrectOptionIndex = Math.floor(
        Math.random() * correctOptions.length
      );
      const randomCorrectOption = correctOptions[randomCorrectOptionIndex];
      const correctCard = CustomQuizUtility.convertQuizQuestionOptionToCard(
        randomCorrectOption,
        mediaItemsMap
      );

      const newCardQuestion: any = {
        id: String(i),
        topCard: {
          textValue: question.question,
          mediaId: question.questionMediaId && question.questionMediaId,
        },
        allOptionsCards: [correctCard], // initialize bottomCards with one correct option
        correctAnswerCard: correctCard,
      };

      const shuffledUniqueIncorrectOptions = shuffle(incorrectOptions);
      const chosenUniqueIncorrectOptions = shuffledUniqueIncorrectOptions.slice(
        0,
        incorrectOptions.length - 1 >= 3 ? 3 : incorrectOptions.length
      );

      for (const option of chosenUniqueIncorrectOptions) {
        const incorrectCard = CustomQuizUtility.convertQuizQuestionOptionToCard(
          option,
          mediaItemsMap
        );
        newCardQuestion.allOptionsCards.push(incorrectCard);
      }
      newCardQuestion.allOptionsCards = shuffle(
        newCardQuestion.allOptionsCards
      );
      cardQuestions.push(newCardQuestion);
    }
    return shuffle(cardQuestions);
  },
  generateQuestionFromQuizForInstall: (
    question: CustomQuizQuestionModel,
    mediaItemsMap: Record<string, MediaModel>
  ): any => {
    const cardQuestions: any = [];
    const { correctOptions, incorrectOptions } = question;
    const randomCorrectOptionIndex = Math.floor(
      Math.random() * correctOptions.length
    );
    const randomCorrectOption = correctOptions[randomCorrectOptionIndex];
    const correctCard = CustomQuizUtility.convertQuizQuestionOptionToCard(
      randomCorrectOption,
      mediaItemsMap
    );

    const newCardQuestion: any = {
      id: String(0),
      topCard: {
        textValue: question.question,
        mediaId: question.questionMediaId && question.questionMediaId,
      },
      allOptionsCards: [correctCard], // initialize bottomCards with one correct option
      correctAnswerCard: correctCard,
    };

    const shuffledUniqueIncorrectOptions = shuffle(incorrectOptions);
    const chosenUniqueIncorrectOptions = shuffledUniqueIncorrectOptions.slice(
      0,
      incorrectOptions.length - 1 >= 3 ? 3 : incorrectOptions.length
    );

    for (const option of chosenUniqueIncorrectOptions) {
      const incorrectCard = CustomQuizUtility.convertQuizQuestionOptionToCard(
        option,
        mediaItemsMap
      );
      newCardQuestion.allOptionsCards.push(incorrectCard);
    }
    newCardQuestion.allOptionsCards = shuffle(newCardQuestion.allOptionsCards);
    cardQuestions.push(newCardQuestion);
    return cardQuestions;
  },

  generateMinimumNumberOfQuestionsForCardSwipingGame: (
    quiz: CustomQuizModel,
    mediaItemsMap: Record<string, MediaModel>,
    minimumNumberOfQuestions: number
  ): CardSwipingQuestionModel[] => {
    let questions: CardSwipingQuestionModel[] = [];

    while (questions.length < minimumNumberOfQuestions) {
      const questionsToAdd = CustomQuizUtility.generateQuestionsFromQuizForCardSwipeGame(
        quiz,
        mediaItemsMap
      );
      questions = questions.concat(questionsToAdd);
    }

    return questions;
  },
};
