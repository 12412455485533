import React, { useContext } from "react";

import styles from "./MobileMenu.module.scss";
import MobileMenuGraphic from "../../resources/images/mobileMenuGraphic.svg";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import Button from "../Button/Button";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import OnboardingModal from "../OnboardingModal/OnboardingModal";
import { OnboardingStep } from "../OnboardingModal/OnboardingModal";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import ShareCode from "../../pages/ShareCode/ShareCode";
import TeamDetails from "../../pages/TeamDetails/TeamDetails";

interface Props {
  handleLogout: () => void;
}

const MobileMenu: React.FC<Props> = ({ handleLogout }) => {
  const { userProfile } = useContext(UserContext);
  const { dispatchModal, closeModal } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);

  const openHudlImportModal = () => {
    dispatchModal({
      open: true,
      size: "responsive",
      disableBackdropClick: true,
      body: (
        <OnboardingModal
          startingStep={OnboardingStep.PdfImportStart}
          handlePdfImportCancel={() => {
            closeModal();
          }}
          showBackButtonOnFinalScreen
        />
      ),
    });
  };

  const openShareCodeModal = () => {
    dispatchModal({
      open: true,
      size: "responsive",
      disableBackdropClick: true,
      body: (
        <ShareCode
          onBackButtonClick={() => {
            closeModal();
          }}
        />
      ),
    });
  };

  const openTeamDetailsModal = () => {
    dispatchModal({
      open: true,
      size: "responsive",
      disableBackdropClick: true,
      body: (
        <TeamDetails
          onBackButtonClick={() => {
            closeModal();
          }}
        />
      ),
    });
  };

  const openMobileAppLinksModal = () => {
    dispatchModal({
      open: true,
      size: "responsive",
      disableBackdropClick: true,
      body: (
        <OnboardingModal
          startingStep={OnboardingStep.MobileAppLinks}
          handlePdfImportCancel={() => {
            closeModal();
          }}
          hideRunningProgressBar
          showBackButtonOnFinalScreen
        />
      ),
    });
  };

  return (
    <>
      {userProfile && (
        <div className={styles.mobileMenu}>
          <div className={styles.header}>Mobile Menu</div>
          <img src={MobileMenuGraphic} />
          <div className={styles.helpText}>
            Team Nation is optimized to be used on devices with larger screens.
            Customizing your playbook, games, and lessons can only be done on a
            desktop computer.
          </div>
          <div className={styles.bottomButtons}>
            {!!currentTeam && (
              <Button
                width="full"
                theme="secondary"
                onClick={openHudlImportModal}
              >
                Import Plays from Hudl
              </Button>
            )}
            <Button width="full" theme="secondary" onClick={openShareCodeModal}>
              Share Team Code
            </Button>
            <Button
              width="full"
              theme="secondary"
              onClick={openTeamDetailsModal}
            >
              View Team Details
            </Button>
            <Button
              width="full"
              theme="primary"
              onClick={openMobileAppLinksModal}
            >
              Play Learning Games
            </Button>
            <Button width="auto" theme="tertiary" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
