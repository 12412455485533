import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import PlayNotes from "./PlayNotes";
import EditPlayDetails from "./EditPlayDetails/EditPlayDetails";
import styles from "./PlaySettingsToolbar.module.scss";
import { DrawablePlayerModel } from "../../generated/from-api/models/drawable/drawablePlayer.model";
import { PlayModel } from "../../generated/from-api/models/play.model";
import CloseIcon from "../../resources/images/blackX.svg";
import FieldOptions from "./FieldOptions/FieldOptions";
import { userRoles } from "../../shared/shared-with-mobile/constants";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import Button from "../Button/Button";
import { PlaySetsContext } from "../../shared/shared-with-mobile/providers/playSets.provider";
import ArrowLeftIcon from "../../resources/icons/ArrowLeftIcon";
import ChevronDownIcon from "../../resources/icons/ChevronDownIcon";

interface Props {
  play: Omit<PlayModel, "drawablePlayers">;
  setPlay: React.Dispatch<
    React.SetStateAction<Omit<PlayModel, "drawablePlayers">>
  >;
  players: DrawablePlayerModel[];
  setPlayers: (players: DrawablePlayerModel[]) => void;
  saveAsFormation: () => void;
  isPlaySetEditModal: boolean;
  isMediaPlay?: boolean;
}

type DetailTabEnum = "details" | "notes" | "field";

const tabFullNameMap = {
  details: "Play Details",
  notes: "Play Notes",
  field: "Field Options",
};

const PlaySettingsToolbar: React.FC<Props> = (props) => {
  const { userProfile } = useContext(UserContext);
  const history = useHistory();
  const { setReopenPlaySetModal } = useContext(PlaySetsContext);
  const [whichTabShowing, setWhichTabShowing] = useState<DetailTabEnum | null>(
    null
  );

  const tabs: DetailTabEnum[] = props.isMediaPlay
    ? ["details", "notes"]
    : ["details", "notes", "field"];

  return (
    <div className={styles.playSettingsToolbar}>
      <div className={styles.topToolbar}>
        {whichTabShowing === null && (
          <>
            <div className={styles.leftControls}>
              <div
                onClick={() => {
                  if (userProfile?.role === userRoles.ADMIN) {
                    history.push("/global-playbook");
                  } else {
                    if (props.isPlaySetEditModal) {
                      setReopenPlaySetModal(props.isPlaySetEditModal);
                    } else {
                      setReopenPlaySetModal(false);
                    }
                    history.push("/playbook");
                  }
                }}
                role="button"
                className={styles.backButton}
              >
                <ArrowLeftIcon />
              </div>
              <div className={styles.details}>
                <div className={styles.playDetails}>
                  {props.play.playCategory} /{" "}
                  {props.play.scheme ? `${props.play.scheme} / ` : ""}
                  {props.play.formationName}
                  {props.play?.opponentFormationName
                    ? " vs " + props.play.opponentFormationName
                    : ""}
                </div>
                <div className={styles.playTitle}>{props.play.name}</div>
              </div>
              {!props.isMediaPlay && (
                <Button
                  theme="tertiary"
                  size={"small"}
                  onClick={props.saveAsFormation}
                >
                  Save as Formation
                </Button>
              )}
            </div>
            <div className={styles.rightControls}>
              <div
                className={styles.tabButtons}
                style={props.isMediaPlay ? { minWidth: "192px" } : {}}
              >
                {tabs.map((tab) => (
                  <button
                    onClick={() => {
                      if (whichTabShowing !== tab) {
                        setWhichTabShowing(tab);
                      } else {
                        setWhichTabShowing(null);
                      }
                    }}
                    key={tab}
                  >
                    {tab}
                    <span>
                      <ChevronDownIcon />
                    </span>
                  </button>
                ))}
              </div>
              <Button
                className={styles.helpButton}
                type={"external link"}
                externalLinkOptions={{
                  href: "https://knowledge.teamnationsports.com",
                }}
                theme="tertiary"
                size={"x-small"}
              >
                Help
              </Button>
            </div>
          </>
        )}
        {whichTabShowing !== null && (
          <>
            <img
              onClick={() => {
                setWhichTabShowing(null);
              }}
              src={CloseIcon}
              role="button"
              className={styles.imageButton}
            />
            <div className={styles.tabName}>
              {tabFullNameMap[whichTabShowing]}
            </div>
            <button
              className={styles.doneButton}
              onClick={() => {
                setWhichTabShowing(null);
              }}
            >
              Done
            </button>
          </>
        )}
      </div>
      {whichTabShowing === "notes" && <PlayNotes {...props} />}
      {whichTabShowing === "details" && <EditPlayDetails {...props} />}
      {whichTabShowing === "field" && <FieldOptions {...props} />}
    </div>
  );
};

export default PlaySettingsToolbar;
