import { useContext } from "react";
import PlaySettingsToolbar from "../../components/drawing/PlaySettingsToolbar";
import { DrawablePlayerModel } from "../../generated/from-api/models/drawable/drawablePlayer.model";

import { PlayModel } from "../../generated/from-api/models/play.model";
import { MediaContext } from "../../shared/shared-with-mobile/providers/media.provider";
import styles from "./EditPlay.module.scss";

interface Props {
  play: Omit<PlayModel, "drawablePlayers">;
  players: DrawablePlayerModel[];
  setPlayers: React.Dispatch<React.SetStateAction<DrawablePlayerModel[]>>;
  setPlay: React.Dispatch<
    React.SetStateAction<Omit<PlayModel, "drawablePlayers">>
  >;
  saveAsFormation: () => void;
}

const EditMediaPlay: React.FC<Props> = ({
  play,
  players,
  setPlayers,
  setPlay,
  saveAsFormation,
}) => {
  const { mediaItemsMap } = useContext(MediaContext);
  const mediaItem = mediaItemsMap[play.mediaId as string];

  if (!mediaItem) {
    return null;
  }

  return (
    <div className={styles.editMediaPlayPage}>
      <PlaySettingsToolbar
        players={players}
        setPlayers={setPlayers}
        play={play}
        setPlay={setPlay}
        saveAsFormation={saveAsFormation}
        isPlaySetEditModal={false}
        isMediaPlay
      />
      <div className={styles.mediaPlayImageContainer}>
        <img src={mediaItem.url} />
      </div>
    </div>
  );
};

export default EditMediaPlay;
