import React, { useState, useContext } from "react";
import styles from "./TagCategoryTab.module.scss";
import { TagTypeEnum } from "../../../generated/from-api/models/enums/tag-type.enum";
import {
  TagFolderModel,
  TagModel,
} from "../../../generated/from-api/models/tag.model";
import PlusIcon from "../../../resources/icons/PlusIcon";
import TrashIcon from "../../../resources/icons/TrashIcon";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import Button from "../../Button/Button";
import CharacterInput from "../../Input/CharacterInput";
import TagTab from "../TagTab/TagTab";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { cloneDeep } from "lodash";
import { TagFoldersContext } from "../../../shared/shared-with-mobile/providers/tagFolders.provider";
import { MediaTagContext } from "../../../shared/shared-with-mobile/providers/mediaTags.provider";

interface Props {
  tagFolder: TagFolderModel;
}

const TagCategoryTab: React.FC<Props> = ({ tagFolder }) => {
  const { currentTeam } = useContext(TeamContext);
  const { addTag } = useContext(TagsContext);
  const { removeTagFolder, updateTagFolder } = useContext(TagFoldersContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { mediaItemTags, removeMediaItemsTags } = useContext(MediaTagContext);

  const getListStyle = (isDraggingOver: boolean, numberOfTags: number) => ({
    background: isDraggingOver ? "#484A59" : "#282B3A",
    borderRadius: "6px",
    paddingTop: "0.1rem",
    width: "100%",
    height: numberOfTags * 3.5 + `rem`,
  });

  const reorderTags = (startIndex: number, endIndex: number) => {
    const newTagCategory = cloneDeep(tagFolder);
    const [removed]: any =
      newTagCategory.tagOrder && newTagCategory.tagOrder.splice(startIndex, 1);
    newTagCategory.tagOrder &&
      newTagCategory.tagOrder.splice(endIndex, 0, removed);
    tagFolder = newTagCategory;

    if (tagFolder) {
      updateTagFolderApi(tagFolder);
    }
  };

  const updateTagFolderApi = async (data: TagFolderModel) => {
    const updateTagFolderTagOrder = await APIService.TAG_FOLDER.PUT(
      data.id as string,
      data
    );
    if (updateTagFolderTagOrder) {
      updateTagFolder(updateTagFolderTagOrder);
    }
  };

  const handleSlideDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }

    const startIndex = dropResult.source.index;
    const endIndex = dropResult.destination.index;
    if (startIndex === endIndex) {
      return;
    }
    reorderTags(startIndex, endIndex);
  };

  const [newTagInput, setNewTagInput] = useState<string>("");

  const createTag = async () => {
    const teamId: string = currentTeam?.id || "";

    const newTag: TagModel = {
      name: newTagInput,
      teamId,
      type: TagTypeEnum.MEDIA_TAG,
      deleted: false,
      category: null,
      createdBy: "",
      tagFolderId: tagFolder.id,
    };

    const created = await APIService.TAG.POST(newTag);

    if (created) {
      addTag(created, TagTypeEnum.MEDIA_TAG);
      setNewTagInput("");

      if (tagFolder.id) {
        let updatedTagOrder: TagModel[] | null = tagFolder.tagOrder;

        if (updatedTagOrder) {
          updatedTagOrder = [...updatedTagOrder, created];
        } else {
          updatedTagOrder = [created];
        }

        const updatedTagFolder: TagFolderModel = {
          ...tagFolder,
          tagOrder: updatedTagOrder,
        };

        const updated = await APIService.TAG_FOLDER.PUT(
          tagFolder.id,
          updatedTagFolder
        );

        if (updated) {
          updateTagFolder(updated);
        }
      }
    }
  };

  const deleteTagFolder = async (tagFolderToDelete: TagFolderModel) => {
    if (tagFolderToDelete.id) {
      const response = await APIService.TAG_FOLDER.DELETE(tagFolderToDelete.id);
      if (response && response.deleted) {
        removeTagFolder(tagFolderToDelete.id);
        //  remove tags from media items tag context
        const tagOrdersIdArray =
          tagFolderToDelete &&
          tagFolderToDelete.tagOrder &&
          tagFolderToDelete.tagOrder.map((tag) => tag.id as string);

        const getMediaItemsassociatedToTag = mediaItemTags
          .filter(
            (mediaItem) =>
              mediaItem.tags.filter(
                (tag) =>
                  tagOrdersIdArray &&
                  tagOrdersIdArray.includes(tag.id as string)
              ).length > 0
          )
          .map((e) => e.mediaId);
        if (tagOrdersIdArray) {
          removeMediaItemsTags(getMediaItemsassociatedToTag, tagOrdersIdArray);
        }
      }
    }
  };

  const renameTagFolder = async (
    tagFolder: TagFolderModel,
    newTagFolderName: string
  ) => {
    if (tagFolder.id) {
      const updated = await APIService.TAG_FOLDER.PUT(tagFolder.id, {
        name: newTagFolderName,
      });

      if (updated) {
        updateTagFolder(updated);
      }
    }
  };

  return (
    <div className={`${styles.tabContent} ${styles.tagCategoryTab}`}>
      <>
        <div key={tagFolder.id} className={styles.inputRow}>
          <span
            className={`${styles.arrow} ${
              isExpanded ? styles.arrowBottom : styles.arrowRight
            }`}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
          <CharacterInput
            value={tagFolder.name}
            onBlur={(e) => {
              renameTagFolder(tagFolder as any, e.target.value);
            }}
          />
          <Button
            className={styles.button}
            theme={"transparent"}
            icon={<TrashIcon />}
            onClick={() => {
              deleteTagFolder(tagFolder as any);
            }}
          />
        </div>
        <>
          <div
            className={`${styles.tagCategory} ${
              isExpanded ? styles.expand : styles.collapse
            }`}
          >
            <DragDropContext onDragEnd={handleSlideDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={getListStyle(
                        snapshot.isDraggingOver,
                        tagFolder.tagOrder ? tagFolder.tagOrder.length : 0
                      )}
                    >
                      {tagFolder.tagOrder &&
                        tagFolder.tagOrder.length > 0 &&
                        tagFolder.tagOrder.map((tag: any, tagIndex: any) => {
                          return (
                            <Draggable
                              key={tag.id}
                              draggableId={tag.id as string}
                              index={tagIndex}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <TagTab
                                    tagType={TagTypeEnum.MEDIA_TAG}
                                    tag={tag}
                                    tagFolder={tagFolder}
                                    provided={provided}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>

            <div className={styles.inputRow}>
              <div className={styles.dragButton}></div>
              <CharacterInput
                placeholder="Add new tag"
                value={newTagInput}
                onChange={(e) => {
                  setNewTagInput(e.target.value);
                }}
              />
              <Button
                className={`${styles.button} ${styles.plusIcon} ${
                  newTagInput && styles.showing
                }`}
                theme={"transparent"}
                icon={<PlusIcon />}
                onClick={createTag}
              />
            </div>
          </div>
        </>
      </>
    </div>
  );
};

export default TagCategoryTab;
