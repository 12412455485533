import * as React from "react";

const GamifiedIcon: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H26C29.3137 0 32 2.68629 32 6V32H12C5.37258 32 0 26.6274 0 20V0Z"
      fill="#DA3731"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5455 11.6364H9.45455C8.65455 11.6364 8 12.2909 8 13.0909V18.9091C8 19.7091 8.65455 20.3636 9.45455 20.3636H22.5455C23.3455 20.3636 24 19.7091 24 18.9091V13.0909C24 12.2909 23.3455 11.6364 22.5455 11.6364ZM22.5454 18.9091H9.45452V13.0909H22.5454V18.9091ZM13.0909 18.1818H11.6363V16.7273H10.1818V15.2727H11.6363V13.8182H13.0909V15.2727H14.5454V16.7273H13.0909V18.1818ZM17.8182 16C17.2157 16 16.7273 16.4884 16.7273 17.0909C16.7273 17.6934 17.2157 18.1818 17.8182 18.1818C18.4207 18.1818 18.9091 17.6934 18.9091 17.0909C18.9091 16.4884 18.4207 16 17.8182 16ZM19.6363 14.9091C19.6363 14.3066 20.1248 13.8182 20.7273 13.8182C21.3297 13.8182 21.8182 14.3066 21.8182 14.9091C21.8182 15.5116 21.3297 16 20.7273 16C20.1248 16 19.6363 15.5116 19.6363 14.9091Z"
      fill="white"
    />
  </svg>
);

export default GamifiedIcon;
