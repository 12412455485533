import * as React from "react";

const YellowWarningIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.77011C0 4.37701 4.37701 0 9.77011 0C15.1632 0 19.5402 4.37701 19.5402 9.77011C19.5402 15.1632 15.1632 19.5402 9.77011 19.5402C4.37701 19.5402 0 15.1632 0 9.77011ZM15.8621 8.27586H4.11568L3.44828 11.0345H15.1947L15.8621 8.27586Z"
      fill="#FFE42A"
    />
  </svg>
);

export default YellowWarningIcon;
