import { useContext } from "react";
import styles from "./PendingInvitationModal.module.scss";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import { InvitationModel } from "../../../../generated/from-api/models/invitation.model";
import { TeamModel } from "../../../../generated/from-api/models/team.model";
import ribbon from "../../../../resources/images/ribbon.png";
import frontHand from "../../../../resources/images/front-hand.svg";
import handshake from "../../../../resources/images/handshake.svg";
import Button from "../../../../components/Button/Button";
import whistle from "../../../../resources/images/whistle.svg";

interface Props {
  invitation: InvitationModel;
  teamInvitedTo: TeamModel;
  handleAcceptInvite: (id: string) => void;
}

const PendingInvitationModal = ({
  invitation,
  teamInvitedTo,
  handleAcceptInvite,
}: Props): JSX.Element => {
  const { closeModal } = useContext(UIContext);
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{`You've been invited to join a team!`}</h3>

      <div className={styles.whitePaper}>
        <div className={styles.imgBlock}>
          <img
            className={styles.logo}
            src={teamInvitedTo.logo ? teamInvitedTo.logo : whistle}
          />
        </div>

        <h4 className={styles.teamName}>{teamInvitedTo.name}</h4>
        <div className={styles.coachBlock}>
          <span className={styles.coachDescription}>Coach</span>
          <span
            className={styles.coachName}
          >{`${invitation.firstName} ${invitation.lastName}`}</span>
        </div>
        <div className={styles.signatureBlock}>
          <div className={styles.signature}>
            <hr />
            <hr />
            <hr />
            <span className={styles.signatureLine}>
              <hr />
              <hr />

              <p className={styles.signatureFont}>{invitation.firstName}</p>
            </span>
          </div>
          <div className={styles.ribbonBlock}>
            <img src={ribbon} />
          </div>
        </div>
      </div>

      <div className={styles.actionButtons}>
        <Button
          className={styles.actionButton}
          size="large"
          icon={<img className={styles.icon} src={frontHand} />}
          onClick={() => {
            closeModal();
          }}
        >
          Dismiss
        </Button>
        <Button
          className={styles.actionButton}
          size="large"
          icon={<img className={styles.icon} src={handshake} />}
          onClick={() => {
            closeModal();
            handleAcceptInvite(invitation.id as string);
          }}
        >
          Join Team
        </Button>
      </div>
    </div>
  );
};

export default PendingInvitationModal;
