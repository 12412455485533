import { CoachRole } from "../generated/from-api/models/enums/coach-role.enum";

export enum INPUT_TYPES {
  TEXT = "text",
  NUMBER = "number",
  EMAIL = "email",
  PASSWORD = "password",
  TEL = "tel",
}

export enum FILE_UPLOAD_TYPES {
  USER_AVATAR = "avatar",
  TEAM_LOGO = "logo",
}

export const coachPositions: CoachRole[] = ["HEAD", "DEFAULT"];

export const publishMode: string[] = ["Draft", "Published"];

export const INSTALL_SETTINGS = {
  URLS: { generic: "lessons", admin: "global-lessons" },
  LABELS: {
    singular: "lesson",
    multiple: "lessons",
    singular_capitalized: "Lesson",
    multiple_capitalized: "Lessons",
  },
};
