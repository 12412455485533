import React, { useEffect, useContext, useState } from "react";
import styles from "./AppContainer.module.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { createBrowserHistory } from "history";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import Button from "../Button/Button";
import {
  DataSetType,
  syncData,
  SyncState,
  SYNC_STATE_DEFAULT,
} from "../../shared/shared-with-mobile/utilities/DataSync/DataSync";
import { PlaybookContext } from "../../shared/shared-with-mobile/providers/playbook.provider";
import { MediaContext } from "../../shared/shared-with-mobile/providers/media.provider";
import { InstallsContext } from "../../shared/shared-with-mobile/providers/installs.provider";
import { QuizzesContext } from "../../shared/shared-with-mobile/providers/quizzes.provider";

interface Props {
  children: JSX.Element;
  handleLogout: () => void;
  isAdminBrowse: boolean;
}

const AppContainer: React.FC<Props & RouteComponentProps> = ({
  children,
  handleLogout,
  isAdminBrowse,
  location,
}) => {
  const { currentTeam } = useContext(TeamContext);
  const { consumeUpdatesForCurrentPlaybook } = useContext(PlaybookContext);
  const { consumeUpdatesForCurrentMediaLibrary } = useContext(MediaContext);
  const { consumeUpdatesForCurrentInstalls } = useContext(InstallsContext);
  const { consumeUpdatesForCurrentQuizzes } = useContext(QuizzesContext);

  const history = createBrowserHistory();

  // this should be being handled by Google Tag Manager automatically
  // but it's not working for some unknown reason
  // so manually create the tracking session below
  useEffect(() => {
    setTimeout(() => {
      const w = window as any;
      const googleAnalyticsTrackingId = "UA-193578106-2";
      if (w.ga) {
        w.ga("create", googleAnalyticsTrackingId, "auto");
      }
    }, 500); // wait half a second to signficantly raise chance that GA is finished initializing
  }, []);

  // because our application is an SPA
  // manually track pageviews on location change
  useEffect(() => {
    const w = window as any;
    if (w.ga) {
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
      w.ga("send", "pageview", location.pathname);
    }
  }, [location]);

  const capitalize = (s: string) => {
    return (
      s.charAt(0).toUpperCase() + s.slice(1).replace(/[^A-Za-z0-9\s!?]/g, " ")
    );
  };

  document.title = `Team Nation | ${capitalize(
    history.location.pathname.slice(1)
  )}`;

  /**
   * Below is tooling for maintaining the most up-to-date data
   */
  const [contextSyncState, setContextSyncState] = useState<SyncState>(
    SYNC_STATE_DEFAULT
  );
  useEffect(() => {
    consumeUpdatesForCurrentPlaybook(contextSyncState.plays.lastUpdates);
    consumeUpdatesForCurrentMediaLibrary(contextSyncState.media.lastUpdates);
    consumeUpdatesForCurrentInstalls(contextSyncState.installs.lastUpdates);
    consumeUpdatesForCurrentQuizzes(contextSyncState.quizzes.lastUpdates);
  }, [contextSyncState]);
  useEffect(() => {
    const sync = async () => {
      if (!currentTeam || !currentTeam.id) {
        return;
      }
      // customize what data gets synced on which routes for further optimization
      const path = location.pathname;
      const dataSetsToSync: DataSetType[] = [];
      if (path.startsWith("/playbook")) {
        dataSetsToSync.push(DataSetType.plays);
      }
      if (path.startsWith("/flashcards")) {
        dataSetsToSync.push(DataSetType.flashcards);
        dataSetsToSync.push(DataSetType.media);
      }
      if (path.startsWith("/lessons")) {
        dataSetsToSync.push(DataSetType.installs);
        dataSetsToSync.push(DataSetType.media);
      }
      const newState = await syncData(
        contextSyncState,
        currentTeam.id,
        dataSetsToSync
      );
      setContextSyncState(newState);
    };

    sync();
  }, [location, currentTeam]);

  return (
    <>
      {isAdminBrowse ? (
        <div className={styles.container}>
          <div className={styles.header}>
            <span className={styles.title}>Admin Browsing</span>
            <Button
              onClick={handleLogout}
              size="small"
              className={styles.logoutButton}
              theme="secondary"
            >
              Logout
            </Button>
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default withRouter(AppContainer);
