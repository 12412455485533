import React, { useState } from "react";
import { InstallModel } from "../../../generated/from-api/models/install.model";
import { InstallStatisticModel } from "../../../generated/from-api/models/install-statistic.model";
import { processDataUpdates } from "../utilities/DataSync/DataSync";

export const InstallsContext = React.createContext<{
  installs: InstallModel[];
  globalInstalls: InstallModel[];
  installStats: InstallStatisticModel[];
  addInstall: (install: InstallModel) => void;
  updateInstall: (install: InstallModel) => void;
  deleteInstall: (install: string) => void;
  setInstalls: (install: InstallModel[]) => void;
  setGlobalInstalls: (install: InstallModel[]) => void;
  setInstallStats: (install: InstallStatisticModel[]) => void;
  clearInstalls: () => void;
  updateInstallStats: (install: InstallStatisticModel) => void;
  areInstallTooltipsTemporarilyDismissed: boolean;
  setAreInstallTooltipsTemporarilyDismissed: (newValue: boolean) => void;
  consumeUpdatesForCurrentInstalls: (updates: InstallModel[]) => void;
}>({
  installs: [],
  globalInstalls: [],
  installStats: [],
  addInstall: () => null,
  updateInstall: () => null,
  deleteInstall: () => null,
  setInstalls: () => null,
  setGlobalInstalls: () => null,
  setInstallStats: () => null,
  clearInstalls: () => null,
  updateInstallStats: () => null,
  areInstallTooltipsTemporarilyDismissed: false,
  setAreInstallTooltipsTemporarilyDismissed: () => null,
  consumeUpdatesForCurrentInstalls: () => null,
});

export const InstallsProvider: React.FC = ({ children }) => {
  const [installs, setInstalls] = useState<InstallModel[]>([]);
  const [globalInstalls, setGlobalInstalls] = useState<InstallModel[]>([]);
  const [installStats, setInstallStats] = useState<InstallStatisticModel[]>([]);
  const [
    areInstallTooltipsTemporarilyDismissed,
    setAreInstallTooltipsTemporarilyDismissed,
  ] = useState<boolean>(false);

  const addInstall = (installToAdd: InstallModel) => {
    setInstalls([...installs, installToAdd]);
  };

  const deleteInstall = (installID: string) => {
    setInstalls([...installs.filter((install) => install.id !== installID)]);
  };

  const updateInstall = (updatedInstall: InstallModel) => {
    const nextInstall = installs.map((install) => {
      return updatedInstall.id !== install.id ? install : updatedInstall;
    });

    setInstalls(nextInstall);
  };

  const updateInstallStats = (updatedInstallStats: InstallStatisticModel) => {
    const nextInstallStats = installStats.map((stats) => {
      return updatedInstallStats.id !== stats.id ? stats : updatedInstallStats;
    });
    setInstallStats(nextInstallStats);
  };

  const clearInstalls = () => {
    setInstalls([]);
  };

  const consumeUpdatesForCurrentInstalls = (updates: InstallModel[]) => {
    const newDataSet = processDataUpdates(updates, installs);
    setInstalls(newDataSet);
  };

  return (
    <InstallsContext.Provider
      value={{
        installs,
        globalInstalls,
        installStats,
        addInstall,
        updateInstall,
        deleteInstall,
        setInstalls,
        setGlobalInstalls,
        setInstallStats,
        clearInstalls,
        updateInstallStats,
        areInstallTooltipsTemporarilyDismissed,
        setAreInstallTooltipsTemporarilyDismissed,
        consumeUpdatesForCurrentInstalls,
      }}
    >
      {children}
    </InstallsContext.Provider>
  );
};
