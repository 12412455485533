import * as React from "react";

const RedAlertIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.77011C0 4.37701 4.37701 0 9.77011 0C15.1632 0 19.5402 4.37701 19.5402 9.77011C19.5402 15.1632 15.1632 19.5402 9.77011 19.5402C4.37701 19.5402 0 15.1632 0 9.77011ZM9.55968 4.13793L9.17772 6.38227L8.41379 12.4788H10.069L11.3422 6.38227L11.7241 4.13793H9.55968ZM7.74536 14.1369L7.58621 15.1419L8.14324 15.8621H9.22546L10.0371 15.1419L10.1963 14.1369L9.62334 13.4167H8.54111L7.74536 14.1369Z"
      fill="#DA3731"
    />
  </svg>
);

export default RedAlertIcon;
