import { Position } from "../../generated/from-api/models/drawable/drawablePlayer.model";
import Checkbox from "../Checkbox/Checkbox";
import PositionSelectorRow from "./PositionSelectorRow";
import styles from "./PositionSelector.module.scss";

import {
  offensivePositions,
  defensivePositions,
  specialTeamsPositions,
  allPositions,
} from "../../shared/shared-with-mobile/play-editor/playEditor.constants";

// accepts a string such as "QB|RB"
// returns an object such as { QB: true, RB: true, WR: false, TE: false, etc. }
const transformPositionsStringToObject = (positions: string) =>
  Object.fromEntries(
    allPositions.map((position: Position) => {
      return [position, positions.split("|").includes(position)];
    })
  );

// accepts an object such as { QB: true, RB: true, WR: false, TE: false, etc. }
// returns a string such as "QB|RB"
const transformPositionsObjectToString = (
  positionsObject: Record<string, boolean>
) =>
  Object.entries(positionsObject)
    .filter(([, selected]) => selected)
    .map(([position]) => position)
    .join("|");

interface Props {
  checkboxLabel: string;
  showToPositions: string; // in the form "QB|RB|LB"
  updatePositions: (positionsString: string) => void;
}

const PositionSelector: React.FC<Props> = ({
  checkboxLabel,
  showToPositions,
  updatePositions,
}) => {
  // return an array of selected positions that belong to whatever set of positions was passed in
  // positions passed in may be offensive, defensive, or special teams positions
  const getSubsetOfSelectedPositions = (positions: string[]) => {
    const selectedPositions = transformPositionsStringToObject(showToPositions);
    return Object.entries(selectedPositions)
      .filter(
        ([position, selected]) => positions.includes(position) && selected
      )
      .map(([position]) => position);
  };

  const togglePositions = (positions: string[], newVal: boolean) => {
    const nextSelectedPositions = transformPositionsStringToObject(
      showToPositions
    );

    for (const position of positions) {
      nextSelectedPositions[position] = newVal;
    }

    const nextShowToPositions = transformPositionsObjectToString(
      nextSelectedPositions
    );

    updatePositions(nextShowToPositions);
  };

  const showingToAllPositions =
    showToPositions.split("|").length === allPositions.length;

  return (
    <div>
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={showingToAllPositions}
          onChange={() => {
            if (showingToAllPositions) {
              togglePositions(allPositions, false);
            } else {
              togglePositions(allPositions, true);
            }
          }}
        >
          {checkboxLabel}
        </Checkbox>
      </div>
      {!showingToAllPositions && (
        <div className={styles.playerPositionSelectors}>
          <PositionSelectorRow
            playType="Offensive"
            options={offensivePositions}
            selectedOptions={getSubsetOfSelectedPositions(offensivePositions)}
            togglePositions={togglePositions}
          />
          <PositionSelectorRow
            playType="Defensive"
            options={defensivePositions}
            selectedOptions={getSubsetOfSelectedPositions(defensivePositions)}
            togglePositions={togglePositions}
          />
          <PositionSelectorRow
            playType="SpecialTeams"
            options={specialTeamsPositions}
            selectedOptions={getSubsetOfSelectedPositions(
              specialTeamsPositions
            )}
            togglePositions={togglePositions}
          />
        </div>
      )}
    </div>
  );
};

export default PositionSelector;
