import React, { useContext } from "react";
import styles from "./QuestionCard.module.scss";
import DropUpload from "../../DropUpload/DropUpload";
import ImagePlaceholderIcon from "../../../resources/icons/ImagePlaceholderIcon";
import MediaGallery from "../../MediaGallery/MediaGallery";
import {
  MediaModel,
  TN_MEDIA_TYPE,
} from "../../../generated/from-api/models/media.model";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";

interface Props {
  uploadMediaCallback?: (media: MediaModel) => void;
  dropActionCallback?: (file: any) => void;
  defaultFilters?: Array<TN_MEDIA_TYPE>;
}

const MediaDropzone: React.FC<Props> = ({
  dropActionCallback,
  uploadMediaCallback,
  defaultFilters = [],
}) => {
  const { dispatchModal } = useContext(UIContext);

  const handleOnClick = () => {
    dispatchModal({
      open: true,
      size: "large",
      className: styles.mediaGallerySetupModal,
      body: (
        <MediaGallery
          type={"Modal"}
          defaultFilters={defaultFilters}
          allowBulkSelect={false}
          handleAddMedia={(media: MediaModel[]) => {
            uploadMediaCallback && uploadMediaCallback(media[0]);
          }}
        />
      ),
    });
  };

  return (
    <button
      className={styles.questionCardDropzone}
      type="button"
      onClick={handleOnClick}
    >
      <DropUpload
        hoverClass={styles.questionCardDropzoneHover}
        dropAction={(files: any) => {
          dropActionCallback && dropActionCallback(files[0]);
        }}
      >
        <div className={styles.questionCardDropzoneContentWrapper}>
          <div className={styles.questionCardDropzoneIcon}>
            <ImagePlaceholderIcon />
          </div>
          <div className={styles.questionCardDropzoneText}>
            Drop files or{" "}
            <span className={styles.questionCardDropzoneTextYellow}>
              browse
            </span>
          </div>
        </div>
      </DropUpload>
    </button>
  );
};

export default MediaDropzone;
