import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import styles from "./App.module.scss";
import Header from "../components/Header/Header";
import { UserContext } from "../shared/shared-with-mobile/providers/user.provider";

interface Props {
  path: string;
  component: React.FC<any>;
  exact: boolean;
  offCenter: boolean | undefined;
  widePage: boolean | undefined;
  handleLogout: () => void;
  isAdminBrowse: boolean;
}
const PrivateRoute: React.FC<Props> = ({
  component: Component,
  offCenter = false,
  widePage = false,
  handleLogout,
  isAdminBrowse,
  ...rest
}) => {
  const { currentUser } = useContext(UserContext);
  if (window.location.pathname === "/" && !currentUser) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Route
        {...rest}
        render={({ ...props }) => {
          // if user is not authenticated, save the url for forwarding
          if (!currentUser) {
            localStorage.setItem(
              "entryUrl",
              window.location.pathname + window.location.search
            );
          }

          return currentUser ? (
            <>
              <Header handleLogout={handleLogout} />
              <section
                className={`${offCenter ? styles.offCenterPage : styles.page} ${
                  widePage && styles.widePage
                }`}
              >
                <Component {...props} isAdminBrowse={isAdminBrowse} />
              </section>
            </>
          ) : currentUser === undefined ? (
            <div>Loading...</div>
          ) : (
            currentUser === null && (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          );
        }}
      />
    );
  }
};

export default PrivateRoute;
