import { Fragment, useContext, useEffect, useState } from "react";

import CharacterInput from "../../../components/Input/CharacterInput";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import styles from "./PlaybookSidebar.module.scss";
import searchIcon from "../../../resources/images/search.svg";
import { categories } from "../../../shared/shared-with-mobile/play-editor/playEditor.constants";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import { PlayCategoryEnum } from "../../../generated/from-api/models/enums/play-category.enum";
import { PlaybookFiltersModel } from "../Playbook";
import { TagModel } from "../../../generated/from-api/models/tag.model";
import { getCategoryAndSchemeCounts } from "../../../utils/functions";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import GearIcon from "../../../resources/icons/GearIcon";
import Button from "../../../components/Button/Button";

interface Props {
  setPlaybookFilters: (p: PlaybookFiltersModel) => void;
  playbookFilters: PlaybookFiltersModel;
  playbook?: PlayModel[];
  hideSettingsLink?: boolean;
  showGlobalTags?: boolean;
}

const mapTagToName = (tag: TagModel) => tag.name;

const PlaybookSidebar: React.FC<Props> = ({
  setPlaybookFilters,
  playbookFilters,
  playbook,
  hideSettingsLink,
  showGlobalTags = false,
}) => {
  const {
    runSchemeTags,
    passSchemeTags,
    defenseSchemeTags,
    globalRunSchemeTags,
    globalPassSchemeTags,
    globalDefenseSchemeTags,
  } = useContext(TagsContext);

  const [categoryCounts, setCategoryCounts] = useState<Record<string, number>>(
    {}
  );
  const [schemeCounts, setSchemeCounts] = useState<Record<string, number>>({});

  const categoryToSchemesMap: Record<string, TagModel[]> = {
    All: [],
    Run: showGlobalTags ? globalRunSchemeTags : runSchemeTags,
    Pass: showGlobalTags ? globalPassSchemeTags : passSchemeTags,
    Defense: showGlobalTags ? globalDefenseSchemeTags : defenseSchemeTags,
  };

  const categoryFilters = ["All", ...categories];

  useEffect(() => {
    const schemeFilters = [
      ...runSchemeTags.map(mapTagToName),
      ...passSchemeTags.map(mapTagToName),
      ...defenseSchemeTags.map(mapTagToName),
    ];

    // this doesn't need to be state (it's just computed based on other state)
    // but looping through every play to generate the counts seemed like too much heavy lifting to do on every single render
    const [nextCategoryCounts, nextSchemeCounts] = getCategoryAndSchemeCounts(
      categoryFilters,
      schemeFilters,
      playbook || []
    );

    setCategoryCounts(nextCategoryCounts);
    setSchemeCounts(nextSchemeCounts);
  }, [runSchemeTags, passSchemeTags, playbook]);

  const selectCategory = (category: string) => {
    const nextCategoryFilter =
      category === "All" ? null : (category as PlayCategoryEnum);

    setPlaybookFilters({
      ...playbookFilters,
      scheme: null,
      category: nextCategoryFilter,
    });
  };

  const selectScheme = (scheme: string) => {
    setPlaybookFilters({
      ...playbookFilters,
      category: null,
      scheme,
    });
  };

  return (
    <div className={styles.playbookSidebar}>
      <div className={styles.sidebarContainer}>
        <CharacterInput
          type={INPUT_TYPES.TEXT}
          placeholder="Search"
          id="search"
          size="x-small"
          icon={searchIcon}
          clearButton
          value={playbookFilters.searchText}
          onChange={(e) => {
            setPlaybookFilters({
              ...playbookFilters,
              searchText: e.currentTarget.value,
            });
          }}
        />

        <div className={styles.categoryFilters}>
          <div className={styles.headerBar}>
            <div>Categories</div>
            {!hideSettingsLink && (
              <Button
                theme={"transparent"}
                size={"small"}
                type={"link"}
                linkOptions={{ to: "/playbook-settings" }}
                icon={<GearIcon />}
              />
            )}
          </div>
          {categoryFilters.map((category: string) => (
            <Fragment key={category}>
              <div
                className={`
                  ${styles.categoryFilter}
                  ${
                    category === "All" &&
                    playbookFilters.category === null &&
                    playbookFilters.scheme === null &&
                    styles.selected
                  }
                  ${playbookFilters.category === category && styles.selected}
                `}
                onClick={() => {
                  selectCategory(category);
                }}
              >
                <div>{category || ""}</div>
                <div>{categoryCounts[category] || ""}</div>
              </div>
              {categoryToSchemesMap[category].map((scheme: TagModel) => (
                <div
                  key={scheme.id}
                  className={`
                      ${styles.schemeFilter}
                      ${
                        playbookFilters.scheme === scheme.name &&
                        styles.selected
                      }
                    `}
                  onClick={() => {
                    selectScheme(scheme.name);
                  }}
                >
                  <div>{scheme.name || 0}</div>
                  <div>{schemeCounts[scheme.name] || 0}</div>
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlaybookSidebar;
