import React, { useState } from "react";
import { TeamModel } from "../../../generated/from-api/models/team.model";
import { LimitedLicenseModel } from "../../../generated/from-api/models/license.model";

export const TeamContext = React.createContext<{
  currentTeam: undefined | null | TeamModel;
  licenseForCurrentTeam: undefined | LimitedLicenseModel;
  setLicenseForCurrentTeam: (lic: LimitedLicenseModel) => void;
  teams: Map<string, TeamModel>;
  isSuspended: boolean | undefined;
  switchTeam: (teamId: string | null) => void;
  addTeams: (newTeams: TeamModel[], teamId?: string) => void;
  updateTeam: (updatedTeam: TeamModel) => void;
  clearTeams: () => void;
  updateSuspensionStatus: (isSuspended: boolean) => void;
  teamsAreLoaded: boolean;
  setTeamsAreLoaded: (bool: boolean) => void;
  removeTeam: (teamId: string) => void;
  setCurrentTeam: (team: TeamModel) => void;
}>({
  currentTeam: undefined,
  licenseForCurrentTeam: undefined,
  setLicenseForCurrentTeam: () => null,
  teams: new Map(),
  isSuspended: undefined,
  switchTeam: () => null,
  addTeams: () => null,
  updateTeam: () => null,
  clearTeams: () => null,
  updateSuspensionStatus: () => null,
  teamsAreLoaded: false,
  setTeamsAreLoaded: () => null,
  removeTeam: () => null,
  setCurrentTeam: () => null,
});

export const TeamProvider: React.FC = ({ children }) => {
  const [currentTeam, setCurrentTeam] = useState<TeamModel | null | undefined>(
    undefined
  );
  const [licenseForCurrentTeam, setLicenseForCurrentTeam] = useState<
    LimitedLicenseModel | undefined
  >(undefined);

  const [teams, setTeams] = useState<Map<string, TeamModel>>(new Map());
  const [isSuspended, setIsSuspended] = useState<boolean>(false);
  const [teamsAreLoaded, setTeamsAreLoaded] = useState<boolean>(false);

  const switchTeam = (teamId: string | null) => {
    if (teamId === null) {
      setCurrentTeam(null);
    } else {
      const newTeam = teams.get(teamId);
      setCurrentTeam(newTeam);
    }
  };

  const addTeams = (newTeams: TeamModel[]) => {
    const teamsCopy = teams ? new Map(teams) : new Map<string, TeamModel>();

    newTeams.forEach((newTeam: TeamModel) => {
      teamsCopy.set(newTeam.id as string, newTeam);
    });

    setTeams(teamsCopy);
  };

  const updateTeam = (updatedTeam: TeamModel) => {
    const teamsCopy = teams ? new Map(teams) : new Map<string, TeamModel>();
    teamsCopy.set(updatedTeam.id as string, updatedTeam);

    setTeams(teamsCopy);
  };

  const clearTeams = () => {
    setTeamsAreLoaded(false);
    setCurrentTeam(undefined);
    setTeams(new Map());
    setIsSuspended(false);
  };

  const updateSuspensionStatus = (isSuspended: boolean) => {
    setIsSuspended(isSuspended);
  };

  const removeTeam = (teamId: string) => {
    const teamsCopy = teams ? new Map(teams) : new Map<string, TeamModel>();
    teamsCopy.delete(teamId);

    setTeams(teamsCopy);
  };

  return (
    <TeamContext.Provider
      value={{
        currentTeam,
        teams,
        isSuspended,
        licenseForCurrentTeam,
        setLicenseForCurrentTeam,
        switchTeam,
        addTeams,
        updateTeam,
        clearTeams,
        updateSuspensionStatus,
        teamsAreLoaded,
        setTeamsAreLoaded,
        removeTeam,
        setCurrentTeam,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
