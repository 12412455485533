import React, { useState } from "react";

import { TagModel } from "../../../generated/from-api/models/tag.model";
import { TagTypeEnum } from "../../../generated/from-api/models/enums/tag-type.enum";
import { TagCategoryEnum } from "../../../generated/from-api/models/enums/tag-category.enum";

export const TagsContext = React.createContext<{
  storeAllTags: (t: TagModel[]) => void;
  storeAllGlobalTags: (t: TagModel[]) => void;
  clearAllTags: () => void;
  addTag: (tag: TagModel, type: TagTypeEnum) => void;
  updateTag: (tag: TagModel, type: TagTypeEnum) => void;
  removeTag: (tagId: string, type: TagTypeEnum) => void;
  addSchemeTag: (tag: TagModel, category: TagCategoryEnum) => void;
  updateSchemeTag: (tag: TagModel, category: TagCategoryEnum) => void;
  removeSchemeTag: (tag: TagModel, category: TagCategoryEnum) => void;
  situationTags: TagModel[];
  personnelPackageTags: TagModel[];
  blitzTags: TagModel[];
  coverageTags: TagModel[];
  customQuizCategoryTags: TagModel[];
  runSchemeTags: TagModel[];
  passSchemeTags: TagModel[];
  defenseSchemeTags: TagModel[];
  globalRunSchemeTags: TagModel[];
  globalPassSchemeTags: TagModel[];
  globalDefenseSchemeTags: TagModel[];
}>({
  storeAllTags: () => null,
  storeAllGlobalTags: () => null,
  clearAllTags: () => null,
  addTag: () => null,
  updateTag: () => null,
  removeTag: () => null,
  addSchemeTag: () => null,
  updateSchemeTag: () => null,
  removeSchemeTag: () => null,
  situationTags: [],
  personnelPackageTags: [],
  blitzTags: [],
  coverageTags: [],
  customQuizCategoryTags: [],
  runSchemeTags: [],
  passSchemeTags: [],
  defenseSchemeTags: [],
  globalRunSchemeTags: [],
  globalPassSchemeTags: [],
  globalDefenseSchemeTags: [],
});

export const TagsProvider: React.FC = ({ children }) => {
  // const [currentTags, setCurrentTags] = useState<TagModel[]>([]);
  const [situationTags, setSituationTags] = useState<TagModel[]>([]);
  const [personnelPackageTags, setPersonnelPackageTags] = useState<TagModel[]>(
    []
  );
  const [blitzTags, setBlitzTags] = useState<TagModel[]>([]);
  const [coverageTags, setCoverageTags] = useState<TagModel[]>([]);
  const [customQuizCategoryTags, setCustomQuizCategoryTags] = useState<
    TagModel[]
  >([]);
  const [runSchemeTags, setRunSchemeTags] = useState<TagModel[]>([]);
  const [passSchemeTags, setPassSchemeTags] = useState<TagModel[]>([]);
  const [defenseSchemeTags, setDefenseSchemeTags] = useState<TagModel[]>([]);
  const [globalRunSchemeTags, setGlobalRunSchemeTags] = useState<TagModel[]>(
    []
  );
  const [globalPassSchemeTags, setGlobalPassSchemeTags] = useState<TagModel[]>(
    []
  );
  const [globalDefenseSchemeTags, setGlobalDefenseSchemeTags] = useState<
    TagModel[]
  >([]);

  const storeAllTags = (tags: TagModel[]) => {
    // first filter all tags out into various subgroups and THEN store them
    // but that was too long of a function name

    const nextSituationTags = tags.filter(
      (tag: TagModel) => tag.type === TagTypeEnum.SITUATION
    );

    const nextPersonnelPackageTags = tags.filter(
      (tag: TagModel) => tag.type === TagTypeEnum.PERSONNEL_PACKAGE
    );

    const nextBlitzTags = tags.filter(
      (tag: TagModel) => tag.type === TagTypeEnum.BLITZ
    );

    const nextCoverageTags = tags.filter(
      (tag: TagModel) => tag.type === TagTypeEnum.COVERAGE
    );

    const nextCustomQuizCategoryTags = tags.filter(
      (tag: TagModel) => tag.type === TagTypeEnum.CUSTOM_QUIZ_CATEGORY
    );

    const schemeTags = tags.filter(
      (tag: TagModel) => tag.type === TagTypeEnum.SCHEME
    );

    const nextRunSchemeTags = schemeTags.filter(
      (tag: TagModel) => tag.category === TagCategoryEnum.RUN
    );

    const nextPassSchemeTags = schemeTags.filter(
      (tag: TagModel) => tag.category === TagCategoryEnum.PASS
    );

    const nextDefenseSchemeTags = schemeTags.filter(
      (tag: TagModel) => tag.category === TagCategoryEnum.DEFENSE
    );

    setSituationTags(nextSituationTags);
    setPersonnelPackageTags(nextPersonnelPackageTags);
    setBlitzTags(nextBlitzTags);
    setCoverageTags(nextCoverageTags);
    setCustomQuizCategoryTags(nextCustomQuizCategoryTags);
    setRunSchemeTags(nextRunSchemeTags);
    setPassSchemeTags(nextPassSchemeTags);
    setDefenseSchemeTags(nextDefenseSchemeTags);
  };

  const storeAllGlobalTags = (tags: TagModel[]) => {
    const schemeTags = tags.filter(
      (tag: TagModel) => tag.type === TagTypeEnum.SCHEME
    );

    const nextGlobalRunSchemeTags = schemeTags.filter(
      (tag: TagModel) => tag.category === TagCategoryEnum.RUN
    );

    const nextGlobalPassSchemeTags = schemeTags.filter(
      (tag: TagModel) => tag.category === TagCategoryEnum.PASS
    );

    const nextGlobalDefenseSchemeTags = schemeTags.filter(
      (tag: TagModel) => tag.category === TagCategoryEnum.DEFENSE
    );

    setGlobalRunSchemeTags(nextGlobalRunSchemeTags);
    setGlobalPassSchemeTags(nextGlobalPassSchemeTags);
    setGlobalDefenseSchemeTags(nextGlobalDefenseSchemeTags);
  };

  const addSchemeTag = (tag: TagModel, category: TagCategoryEnum) => {
    switch (category) {
      case TagCategoryEnum.RUN:
        setRunSchemeTags([...runSchemeTags, tag]);
        break;
      case TagCategoryEnum.PASS:
        setPassSchemeTags([...passSchemeTags, tag]);
        break;
      case TagCategoryEnum.DEFENSE:
        setDefenseSchemeTags([...defenseSchemeTags, tag]);
        break;
    }
  };

  const updateSchemeTag = (
    tagToUpdate: TagModel,
    category: TagCategoryEnum
  ) => {
    let indexToUpdate: number;

    switch (category) {
      case TagCategoryEnum.RUN:
        const nextRunSchemeTags = [...runSchemeTags];
        indexToUpdate = nextRunSchemeTags.findIndex(
          (tag: TagModel) => tag.id === tagToUpdate.id
        );
        nextRunSchemeTags[indexToUpdate] = tagToUpdate;
        setRunSchemeTags(nextRunSchemeTags);
        break;
      case TagCategoryEnum.PASS:
        const nextPassSchemeTags = [...passSchemeTags];
        indexToUpdate = nextPassSchemeTags.findIndex(
          (tag: TagModel) => tag.id === tagToUpdate.id
        );
        nextPassSchemeTags[indexToUpdate] = tagToUpdate;
        setPassSchemeTags(nextPassSchemeTags);
        break;
      case TagCategoryEnum.DEFENSE:
        const nextDefenseSchemeTags = [...defenseSchemeTags];
        indexToUpdate = nextDefenseSchemeTags.findIndex(
          (tag: TagModel) => tag.id === tagToUpdate.id
        );
        nextDefenseSchemeTags[indexToUpdate] = tagToUpdate;
        setDefenseSchemeTags(nextDefenseSchemeTags);
        break;
    }
  };

  const removeSchemeTag = (
    tagToDelete: TagModel,
    category: TagCategoryEnum
  ) => {
    let indexToDelete: number;

    switch (category) {
      case TagCategoryEnum.RUN:
        const nextRunSchemeTags = [...runSchemeTags];
        indexToDelete = nextRunSchemeTags.findIndex(
          (tag: TagModel) => tag.id === tagToDelete.id
        );
        nextRunSchemeTags.splice(indexToDelete, 1);
        setRunSchemeTags(nextRunSchemeTags);
        break;
      case TagCategoryEnum.PASS:
        const nextPassSchemeTags = [...passSchemeTags];
        indexToDelete = nextPassSchemeTags.findIndex(
          (tag: TagModel) => tag.id === tagToDelete.id
        );
        nextPassSchemeTags.splice(indexToDelete, 1);
        setPassSchemeTags(nextPassSchemeTags);
        break;
      case TagCategoryEnum.DEFENSE:
        const nextDefenseSchemeTags = [...defenseSchemeTags];
        indexToDelete = nextDefenseSchemeTags.findIndex(
          (tag: TagModel) => tag.id === tagToDelete.id
        );
        nextDefenseSchemeTags.splice(indexToDelete, 1);
        setDefenseSchemeTags(nextDefenseSchemeTags);
        break;
    }
  };

  const addTag = (tagToAdd: TagModel, tagType: TagTypeEnum) => {
    switch (tagType) {
      case TagTypeEnum.SITUATION:
        setSituationTags([...situationTags, tagToAdd]);
        break;
      case TagTypeEnum.PERSONNEL_PACKAGE:
        setPersonnelPackageTags([...personnelPackageTags, tagToAdd]);
        break;
      case TagTypeEnum.BLITZ:
        setBlitzTags([...blitzTags, tagToAdd]);
        break;
      case TagTypeEnum.COVERAGE:
        setCoverageTags([...coverageTags, tagToAdd]);
        break;
      case TagTypeEnum.CUSTOM_QUIZ_CATEGORY:
        setCustomQuizCategoryTags([...customQuizCategoryTags, tagToAdd]);
        break;
    }
  };

  const updateTag = (tagToUpdate: TagModel, tagType: TagTypeEnum) => {
    let indexToUpdate: number;

    switch (tagType) {
      case TagTypeEnum.SITUATION:
        const nextSituationTags = [...situationTags];
        indexToUpdate = nextSituationTags.findIndex(
          (tag: TagModel) => tag.id === tagToUpdate.id
        );
        nextSituationTags[indexToUpdate] = tagToUpdate;
        setSituationTags(nextSituationTags);
        break;
      case TagTypeEnum.PERSONNEL_PACKAGE:
        const nextPersonnelPackageTags = [...personnelPackageTags];
        indexToUpdate = nextPersonnelPackageTags.findIndex(
          (tag: TagModel) => tag.id === tagToUpdate.id
        );
        nextPersonnelPackageTags[indexToUpdate] = tagToUpdate;
        setPersonnelPackageTags(nextPersonnelPackageTags);
        break;
      case TagTypeEnum.BLITZ:
        const nextBlitzTags = [...blitzTags];
        indexToUpdate = nextBlitzTags.findIndex(
          (tag: TagModel) => tag.id === tagToUpdate.id
        );
        nextBlitzTags[indexToUpdate] = tagToUpdate;
        setBlitzTags(nextBlitzTags);
        break;
      case TagTypeEnum.COVERAGE:
        const nextCoverageTags = [...coverageTags];
        indexToUpdate = nextCoverageTags.findIndex(
          (tag: TagModel) => tag.id === tagToUpdate.id
        );
        nextCoverageTags[indexToUpdate] = tagToUpdate;
        setCoverageTags(nextCoverageTags);
        break;
      case TagTypeEnum.CUSTOM_QUIZ_CATEGORY:
        const nextCustomQuizCategoryTags = [...customQuizCategoryTags];
        indexToUpdate = nextCustomQuizCategoryTags.findIndex(
          (tag: TagModel) => tag.id === tagToUpdate.id
        );
        nextCustomQuizCategoryTags[indexToUpdate] = tagToUpdate;
        setCustomQuizCategoryTags(nextCustomQuizCategoryTags);
        break;
    }
  };

  const removeTag = (tagToRemoveId: string, tagType: TagTypeEnum) => {
    let indexToDelete: number;

    switch (tagType) {
      case TagTypeEnum.SITUATION:
        const nextSituationTags = [...situationTags];
        indexToDelete = nextSituationTags.findIndex(
          (tag: TagModel) => tag.id === tagToRemoveId
        );
        nextSituationTags.splice(indexToDelete, 1);
        setSituationTags(nextSituationTags);
        break;
      case TagTypeEnum.PERSONNEL_PACKAGE:
        const nextPersonnelPackageTags = [...personnelPackageTags];
        indexToDelete = nextPersonnelPackageTags.findIndex(
          (tag: TagModel) => tag.id === tagToRemoveId
        );
        nextPersonnelPackageTags.splice(indexToDelete, 1);
        setPersonnelPackageTags(nextPersonnelPackageTags);
        break;
      case TagTypeEnum.BLITZ:
        const nextBlitzTags = [...blitzTags];
        indexToDelete = nextBlitzTags.findIndex(
          (tag: TagModel) => tag.id === tagToRemoveId
        );
        nextBlitzTags.splice(indexToDelete, 1);
        setBlitzTags(nextBlitzTags);
        break;
      case TagTypeEnum.COVERAGE:
        const nextCoverageTags = [...coverageTags];
        indexToDelete = nextCoverageTags.findIndex(
          (tag: TagModel) => tag.id === tagToRemoveId
        );
        nextCoverageTags.splice(indexToDelete, 1);
        setCoverageTags(nextCoverageTags);
        break;
      case TagTypeEnum.CUSTOM_QUIZ_CATEGORY:
        const nextCustomQuizCategoryTags = [...customQuizCategoryTags];
        indexToDelete = nextCustomQuizCategoryTags.findIndex(
          (tag: TagModel) => tag.id === tagToRemoveId
        );
        nextCustomQuizCategoryTags.splice(indexToDelete, 1);
        setCustomQuizCategoryTags(nextCustomQuizCategoryTags);
        break;
    }
  };

  const clearAllTags = () => {
    setSituationTags([]);
    setPersonnelPackageTags([]);
    setBlitzTags([]);
    setCoverageTags([]);
    setCustomQuizCategoryTags([]);
    setRunSchemeTags([]);
    setPassSchemeTags([]);
    setDefenseSchemeTags([]);
  };

  return (
    <TagsContext.Provider
      value={{
        storeAllTags,
        storeAllGlobalTags,
        clearAllTags,
        addTag,
        updateTag,
        removeTag,
        addSchemeTag,
        updateSchemeTag,
        removeSchemeTag,
        situationTags,
        personnelPackageTags,
        blitzTags,
        coverageTags,
        customQuizCategoryTags,
        runSchemeTags,
        passSchemeTags,
        defenseSchemeTags,
        globalRunSchemeTags,
        globalPassSchemeTags,
        globalDefenseSchemeTags,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};
