import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { DeviceRegistrationModel } from "../../../../generated/from-api/models/device-registration.model";
import { API_BASE_URL } from "../../constants";

export const DeviceRegistrationService = {
  REGISTER: async function (
    userId: string,
    fcmToken: string
  ): Promise<DeviceRegistrationModel> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.DEVICE_REGISTRATION}/register`,
        {
          userId,
          fcmToken,
        }
      )
    ).data;
  },
  UNREGISTER: async function (
    userId: string,
    fcmToken: string
  ): Promise<DeviceRegistrationModel> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.DEVICE_REGISTRATION}/unregister`,
        {
          userId,
          fcmToken,
        }
      )
    ).data;
  },
};
