import React from "react";

import styles from "./RadioButton.module.scss";

interface Props {
  className?: string;
  selected: boolean;
  handleClick?: () => void;
}

const RadioButton: React.FC<Props> = ({
  className = "",
  selected,
  handleClick,
}) => (
  <div
    className={`${styles.radioContainer} ${className}`}
    onClick={handleClick}
  >
    {selected ? (
      <div className={styles.outerCircle}>
        <div className={styles.middleCircle}>
          <div className={styles.innerCircle}></div>
        </div>
      </div>
    ) : (
      <div className={styles.circle}></div>
    )}
  </div>
);

export default RadioButton;
