import { useRef, useState, useEffect } from "react";

import {
  DrawablePlayerModel,
  FillPattern,
  Position,
} from "../../generated/from-api/models/drawable/drawablePlayer.model";
import {
  LineCapEnum,
  LineTypeEnum,
} from "../../generated/from-api/models/drawable/lineSegments.model";

import {
  colorPalette,
  colors,
} from "../../shared/shared-with-mobile/play-editor/play-editor-colors";
import CharacterInput from "../Input/CharacterInput";
import LineCapArrowIcon from "../../resources/images/lineCapArrow.svg";
import LineCapFlatIcon from "../../resources/images/lineCapFlat.svg";
import LineCapCircleIcon from "../../resources/images/lineCapCircle.svg";
import LineTypeSolidIcon from "../../resources/images/lineTypeSolid.svg";
import LineTypeZigzagIcon from "../../resources/images/lineTypeZigzag.svg";
import LineTypeDashedIcon from "../../resources/images/lineTypeDashed.svg";
import FillPatternFilledIcon from "../../resources/images/fillPatternFilled.svg";
import FillPatternEmptyIcon from "../../resources/images/fillPatternEmpty.svg";
import FillPatternSemiTopIcon from "../../resources/images/fillPatternSemiTop.svg";
import FillPatternSemiBottomIcon from "../../resources/images/fillPatternSemiBottom.svg";
import FillPatternSemiLeftIcon from "../../resources/images/fillPatternSemiLeft.svg";
import FillPatternSemiRightIcon from "../../resources/images/fillPatternSemiRight.svg";
import RedX from "../../resources/images/redX.svg";
import PaletteIcon from "../../resources/images/colorPalette.svg";
import CoverageIcon from "../../resources/images/coverageIcon.svg";
import YellowCircleIcon from "../../resources/images/yellowCircle.svg";
import GrayCircleIcon from "../../resources/images/grayCircle.svg";
import YellowEllipseIcon from "../../resources/images/yellowEllipse.svg";
import GrayEllipseIcon from "../../resources/images/grayEllipse.svg";
import RedEllipseIcon from "../../resources/images/redEllipse.svg";
import styles from "./EditPlayToolbar.module.scss";
import {
  fillPatterns,
  offensivePositions,
  defensivePositions,
} from "../../shared/shared-with-mobile/play-editor/playEditor.constants";
import Switch from "../Switch/Switch";
import PencilIcon from "../../resources/icons/PencilIcon";

interface Props {
  selectedPlayerIds?: string[];
  setSelectedPlayerIds?: (playerIds: string[] | null) => void;
  players: DrawablePlayerModel[];
  setPlayers: React.Dispatch<React.SetStateAction<DrawablePlayerModel[]>>;
  close: () => void;
  lineTypeOptions?: LineTypeEnum[];
  selectedLineType?: LineTypeEnum;
  setSelectedLineType?: React.Dispatch<React.SetStateAction<LineTypeEnum>>;
  lineCapOptions?: LineCapEnum[];
  selectedLineCap?: LineCapEnum;
  setSelectedLineCap?: React.Dispatch<React.SetStateAction<LineCapEnum>>;
  canDrawRoutesAndZones?: boolean;
  shouldSnapLineToAngle?: boolean;
  setShouldSnapLineToAngle?: (newVal: boolean) => void;
  shouldSnapLineToNearestYard?: boolean;
  setShouldSnapLineToNearestYard?: (newVal: boolean) => void;
  togglePlayerChanging?: () => void;
  isPlayerChanging?: boolean;
  flipPlay?: () => void;
  isPlay?: boolean; // if it's not a play, then it's a formation
  isRouteDrawingEnabled?: boolean;
  setIsRouteDrawingEnabled?: (newVal: boolean) => void;
}

const lineCapIconMap: Record<LineCapEnum, string> = {
  arrow: LineCapArrowIcon,
  circle: LineCapCircleIcon,
  flat: LineCapFlatIcon,
};

const lineTypeIconMap: Record<LineTypeEnum, string> = {
  solid: LineTypeSolidIcon,
  dashed: LineTypeDashedIcon,
  zigzag: LineTypeZigzagIcon,
};

const fillPatternIconMap: Record<FillPattern, string> = {
  filled: FillPatternFilledIcon,
  empty: FillPatternEmptyIcon,
  semiTop: FillPatternSemiTopIcon,
  semiBottom: FillPatternSemiBottomIcon,
  semiLeft: FillPatternSemiLeftIcon,
  semiRight: FillPatternSemiRightIcon,
};

const getNewFootball = () => {
  const newBall: DrawablePlayerModel = {
    x: 0.5,
    y: 0.5,
    id: "football",
    color: colors.footballBrownBorder,
    playType: "Offensive",
    fillPattern: "filled",
  };

  return newBall;
};

const EditPlayToolbar: React.FC<Props> = ({
  selectedPlayerIds,
  players,
  setPlayers,
  close,
  lineTypeOptions = [],
  selectedLineType = "solid",
  setSelectedLineType = () => undefined,
  lineCapOptions = [],
  selectedLineCap = "arrow",
  setSelectedLineCap = () => undefined,
  canDrawRoutesAndZones = true,
  shouldSnapLineToAngle = true,
  shouldSnapLineToNearestYard = true,
  setShouldSnapLineToAngle = () => undefined,
  setShouldSnapLineToNearestYard = () => undefined,
  togglePlayerChanging = () => undefined,
  isPlayerChanging = false,
  flipPlay = () => undefined,
  setSelectedPlayerIds = () => undefined,
  isPlay = true,
  isRouteDrawingEnabled = false,
  setIsRouteDrawingEnabled = () => undefined,
}) => {
  const [showingColorToolbar, setShowingColorToolbar] = useState(false);
  const [showingRoleToolbar, setShowingRoleToolbar] = useState(false);
  const [showingPositionToolbar, setShowingPositionToolbar] = useState(false);
  const [showingCoverageZoneToolbar, setShowingCoverageZoneToolbar] = useState(
    false
  );
  const [showingLineSnapToggles, setShowingLineSnapToggles] = useState(false);

  const showingBall = !!players.find(
    (player: DrawablePlayerModel) => player.id === "football"
  );

  const lineSnapButtonRef = useRef<HTMLButtonElement>(null);
  const lineSnapTogglesRef = useRef<HTMLDivElement>(null);

  const selectedPlayer: DrawablePlayerModel | undefined =
    !!selectedPlayerIds && selectedPlayerIds.length === 1
      ? players.find((player) => player.id === selectedPlayerIds[0])
      : undefined;

  // useEffect for closing the Line Snap toggles on outside clicks
  useEffect(() => {
    const onWindowClick = (e: MouseEvent) => {
      const clickTarget = e.target;

      if (
        clickTarget &&
        lineSnapTogglesRef.current &&
        lineSnapButtonRef.current
      ) {
        const buttonElement = lineSnapButtonRef.current;
        const togglesContainerElement = lineSnapTogglesRef.current;

        // detect outside clicks
        if (
          buttonElement !== clickTarget &&
          togglesContainerElement !== clickTarget &&
          !togglesContainerElement.contains(clickTarget as Node)
        ) {
          setShowingLineSnapToggles(false);
        }
      }
    };

    window.addEventListener("click", onWindowClick);

    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, []);

  useEffect(() => {
    if (selectedPlayerIds?.length !== 1) {
      setShowingRoleToolbar(false);
      setShowingPositionToolbar(false);
    }
  }, [selectedPlayerIds]);

  const positions =
    selectedPlayer?.playType === "Offensive"
      ? offensivePositions
      : defensivePositions;

  // haven't figured out how to implement fill patterns (other than "empty" and "filled") on triangles
  const fillPatternOptions: FillPattern[] =
    selectedPlayer?.playType === "Offensive"
      ? fillPatterns
      : ["empty", "filled"];

  const updateColor = (color: string) => {
    const nextPlayers = players.map((player) => {
      if (
        player.id !== selectedPlayer?.id &&
        !selectedPlayerIds?.includes(player.id)
      ) {
        return player;
      }

      return {
        ...player,
        color,
      };
    });

    setPlayers(nextPlayers);
  };

  const updateFillPattern = (fillPattern: FillPattern) => {
    const nextPlayers = players.map((player) => {
      if (
        player.id !== selectedPlayer?.id &&
        !selectedPlayerIds?.includes(player.id)
      ) {
        return player;
      }

      return {
        ...player,
        fillPattern,
      };
    });

    setPlayers(nextPlayers);
  };

  const updatePosition = (position: Position) => {
    const nextPlayers = players.map((player) => {
      if (player.id !== selectedPlayer?.id) {
        return player;
      }

      const nextPosition = player.position === position ? undefined : position;

      return {
        ...player,
        position: nextPosition,
      };
    });

    setPlayers(nextPlayers);
  };

  const updateCoverageZone = (radiusX: number, radiusY: number) => {
    const nextPlayers = players.map((player) => {
      if (player.id !== selectedPlayer?.id) {
        return player;
      }

      return {
        ...player,
        coverageZoneRadiusX: radiusX,
        coverageZoneRadiusY: radiusY,
        coverageZoneRadius: radiusX, // temporarily continue to store deprecated property "coverageZoneRadius" to support older clients
      };
    });

    setPlayers(nextPlayers);
  };

  const updateRole = (role: string) => {
    const nextPlayers = players.map((player) => {
      if (player.id !== selectedPlayer?.id) {
        return player;
      }

      const nextRole = role ? role.toUpperCase() : undefined;

      return {
        ...player,
        role: nextRole,
      };
    });

    setPlayers(nextPlayers);
  };

  const undoRoutePoint = () => {
    const nextPlayers = players.map((player) => {
      if (player.id !== selectedPlayer?.id || !player.route) {
        return player;
      }

      const { route } = player;
      const { line, lineTypes } = route;

      const numPointsInLine = line.length / 2;

      if (numPointsInLine === 2) {
        // if only 2 points in the line, remove the route entirely
        return {
          ...player,
          route: undefined,
        };
      } else {
        // else just remove the last point in the line
        const nextLine = [...line];
        nextLine.splice(-2, 2);
        const nextLineTypes = [...lineTypes];
        nextLineTypes.splice(-1, 1);

        return {
          ...player,
          route: {
            line: nextLine,
            lineTypes: nextLineTypes,
            lineCap: selectedLineCap,
          },
        };
      }
    });

    setPlayers(nextPlayers);
  };

  const rotateLineCap = () => {
    const index = lineCapOptions.findIndex((l) => l === selectedLineCap);
    const nextIndex = (index + 1) % lineCapOptions.length;
    const nextLineCap = lineCapOptions[nextIndex];
    setSelectedLineCap(nextLineCap);

    const nextPlayers = players.map((player) => {
      if (player.id !== selectedPlayer?.id || !player.route) {
        return player;
      }

      return {
        ...player,
        route: {
          ...player.route,
          lineCap: nextLineCap,
        },
      };
    });

    setPlayers(nextPlayers);
  };

  const rotateLineType = () => {
    const index = lineTypeOptions.findIndex((l) => l === selectedLineType);
    const nextIndex = (index + 1) % lineTypeOptions.length;
    const nextLineType = lineTypeOptions[nextIndex];
    setSelectedLineType(nextLineType);
  };

  const unselectSelectedPlayers = () => {
    setShowingColorToolbar(false);
    setShowingCoverageZoneToolbar(false);
    setShowingPositionToolbar(false);
    setShowingRoleToolbar(false);
    close();
  };

  const handleCoverageZoneButtonClick = (type: "circle" | "ellipse") => {
    if (!selectedPlayer) {
      return;
    }

    if (!selectedPlayer.coverageZoneRadiusX) {
      // no coverage zone exists, so we must initialize a shape
      if (type === "circle") {
        // initialize a small circle
        updateCoverageZone(0.08, 0.08);
      } else if (type === "ellipse") {
        // initialize a small ellipse
        updateCoverageZone(0.08, 0.06);
      }
    } else if (
      selectedPlayer.coverageZoneRadiusX ===
        selectedPlayer.coverageZoneRadiusY &&
      type === "ellipse"
    ) {
      // shape is currently a circle, and user wants to change it to an ellipse
      const heightToWidthRatio = 0.75;
      const newRadiusY =
        selectedPlayer.coverageZoneRadiusX * heightToWidthRatio;
      updateCoverageZone(selectedPlayer.coverageZoneRadiusX, newRadiusY);
    } else if (
      selectedPlayer.coverageZoneRadiusX !==
        selectedPlayer.coverageZoneRadiusY &&
      type === "circle"
    ) {
      // shape is currently an ellipse, and user wants to change it to a circle
      updateCoverageZone(
        selectedPlayer.coverageZoneRadiusX,
        selectedPlayer.coverageZoneRadiusX
      );
    }
  };

  const toggleBall = () => {
    if (!showingBall) {
      // it's a bit weird but the ball will be considered a "drawablePlayer"
      // and will live in the play.drawablePlayers array
      const newFootball = getNewFootball();
      const nextPlayers = [...players, newFootball];
      setPlayers(nextPlayers);
    } else {
      const nextPlayers = players.filter(
        (player: DrawablePlayerModel) => player.id !== "football"
      );
      setPlayers(nextPlayers);
      setSelectedPlayerIds(null);
    }
  };

  return (
    <div className={styles.editPlayToolbar}>
      <>
        {((selectedPlayer && selectedPlayer.id !== "football") ||
          !!selectedPlayerIds?.length) && (
          <>
            {showingColorToolbar && (
              <div
                className={`${styles.toolbarColumn} ${styles.secondary} ${styles.smallGap}`}
              >
                {Object.entries(colorPalette).map(([colorName, colorHex]) => {
                  return (
                    <span
                      key={colorName}
                      className={`${styles.colorOptionWrapper} ${
                        colorHex === selectedPlayer?.color
                          ? styles.selected
                          : ""
                      }`}
                    >
                      <div
                        role="button"
                        className={styles.colorOption}
                        style={{ background: colorHex }}
                        onClick={() => {
                          updateColor(colorHex);
                        }}
                      ></div>
                    </span>
                  );
                })}
                {fillPatternOptions.map((pattern) => (
                  <div
                    key={pattern}
                    className={`${styles.svgButtonWrapper} ${
                      selectedPlayer?.fillPattern === pattern
                        ? styles.selected
                        : ""
                    }`}
                  >
                    <button
                      className={styles.svgButton}
                      onClick={() => {
                        updateFillPattern(pattern);
                      }}
                    >
                      <img src={fillPatternIconMap[pattern]} />
                    </button>
                  </div>
                ))}
              </div>
            )}
            {showingRoleToolbar && (
              <div className={`${styles.toolbarColumn} ${styles.secondary}`}>
                <CharacterInput
                  placeholder={!selectedPlayer?.role ? "Role" : undefined}
                  maxLength={2}
                  value={selectedPlayer?.role}
                  clearButton
                  onChange={(e) => {
                    updateRole(e.target?.value);
                  }}
                />
              </div>
            )}
            {showingPositionToolbar && (
              <div className={`${styles.toolbarColumn} ${styles.secondary}`}>
                {positions.map((position: Position) => (
                  <button
                    key={position}
                    onClick={() => {
                      updatePosition(position);
                    }}
                    className={`${styles.squareButton} ${
                      selectedPlayer?.position === position
                        ? styles.selected
                        : styles.unselected
                    }`}
                  >
                    {position}
                  </button>
                ))}
              </div>
            )}
            {showingCoverageZoneToolbar &&
              selectedPlayer?.playType === "Defensive" && (
                <div
                  className={`${styles.toolbarColumn} ${styles.coverageZoneButtonsContainer}`}
                >
                  <button
                    onClick={() => {
                      handleCoverageZoneButtonClick("circle");
                    }}
                  >
                    <img
                      src={
                        selectedPlayer?.coverageZoneRadiusX &&
                        selectedPlayer?.coverageZoneRadiusX ===
                          selectedPlayer?.coverageZoneRadiusY
                          ? YellowCircleIcon
                          : GrayCircleIcon
                      }
                    />
                  </button>
                  <button
                    onClick={() => {
                      handleCoverageZoneButtonClick("ellipse");
                    }}
                  >
                    <img
                      src={
                        selectedPlayer?.coverageZoneRadiusX &&
                        selectedPlayer?.coverageZoneRadiusX !==
                          selectedPlayer?.coverageZoneRadiusY
                          ? YellowEllipseIcon
                          : GrayEllipseIcon
                      }
                    />
                  </button>

                  {!!selectedPlayer?.coverageZoneRadiusX && (
                    <button
                      onClick={() => {
                        updateCoverageZone(0, 0);
                      }}
                    >
                      <img src={RedEllipseIcon} />
                    </button>
                  )}
                </div>
              )}
          </>
        )}

        <div className={`${styles.toolbarColumn} ${styles.spaceBetween}`}>
          <div className={styles.playControls}>
            {isPlay && (
              <button
                className={showingBall ? styles.selected : ""}
                onClick={() => {
                  toggleBall();
                }}
              >
                Ball
              </button>
            )}
            <button onClick={flipPlay}>Flip</button>
            {isPlay && (
              <div className={styles.lineSnapButtonContainer}>
                <button
                  ref={lineSnapButtonRef}
                  className={styles.lineSnapButton}
                  onClick={() => {
                    setShowingLineSnapToggles(!showingLineSnapToggles);
                  }}
                >
                  Line Snap
                </button>
                {showingLineSnapToggles && (
                  <div
                    className={styles.lineSnapToggles}
                    ref={lineSnapTogglesRef}
                  >
                    <div className={styles.lineSnapTogglesRow}>
                      <Switch
                        theme="yellow"
                        toggled={shouldSnapLineToAngle}
                        onToggle={() => {
                          setShouldSnapLineToAngle(!shouldSnapLineToAngle);
                        }}
                      />
                      <div className={styles.lineSnapTogglesCopy}>
                        Vertical/Horizontal
                      </div>
                    </div>
                    <div className={styles.lineSnapTogglesRow}>
                      <Switch
                        theme="yellow"
                        toggled={shouldSnapLineToNearestYard}
                        onToggle={() => {
                          setShouldSnapLineToNearestYard(
                            !shouldSnapLineToNearestYard
                          );
                        }}
                      />
                      <div className={styles.lineSnapTogglesCopy}>
                        Nearest Yard
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <button
              onClick={togglePlayerChanging}
              className={isPlayerChanging ? styles.selected : ""}
            >
              Add/Remove Player
            </button>
          </div>
          <div
            className={`${styles.playerControls} ${
              !selectedPlayer && !selectedPlayerIds?.length && styles.hide
            } ${isPlay ? styles.isPlay : styles.isFormation}`}
          >
            {selectedPlayer?.id !== "football" && (
              <>
                {/* Role Button */}
                <button
                  className={`${styles.squareButton} ${
                    showingRoleToolbar ? styles.selected : styles.unselected
                  } ${selectedPlayerIds?.length !== 1 && styles.disabled}`}
                  onClick={() => {
                    setShowingColorToolbar(false);
                    setShowingPositionToolbar(false);
                    setShowingCoverageZoneToolbar(false);
                    setShowingRoleToolbar(!showingRoleToolbar);
                  }}
                >
                  <div className={styles.label}>Role</div>
                  <div className={styles.selection}>
                    {selectedPlayer?.role || "--"}
                  </div>
                </button>
                {/* Position Button */}
                <button
                  className={`${styles.squareButton} ${
                    showingPositionToolbar ? styles.selected : styles.unselected
                  } ${selectedPlayerIds?.length !== 1 && styles.disabled}`}
                  onClick={() => {
                    setShowingColorToolbar(false);
                    setShowingRoleToolbar(false);
                    setShowingCoverageZoneToolbar(false);
                    setShowingPositionToolbar(!showingPositionToolbar);
                  }}
                >
                  <div className={styles.label}>Pos.</div>
                  <div className={styles.selection}>
                    {selectedPlayer?.position || "--"}
                  </div>
                </button>
                {/* Color Button */}
                <button
                  className={`${styles.squareButton} ${
                    showingColorToolbar ? styles.selected : styles.unselected
                  } ${!selectedPlayerIds?.length && styles.disabled}`}
                  onClick={() => {
                    setShowingRoleToolbar(false);
                    setShowingPositionToolbar(false);
                    setShowingCoverageZoneToolbar(false);
                    setShowingColorToolbar(!showingColorToolbar);
                  }}
                >
                  <img src={PaletteIcon} />
                </button>
                {/* Coverage Zone Button */}
                {selectedPlayer?.playType === "Defensive" &&
                  canDrawRoutesAndZones && (
                    <button
                      className={`${styles.squareButton} ${
                        showingCoverageZoneToolbar
                          ? styles.selected
                          : styles.unselected
                      } ${selectedPlayerIds?.length !== 1 && styles.disabled}`}
                      onClick={() => {
                        setShowingRoleToolbar(false);
                        setShowingPositionToolbar(false);
                        setShowingColorToolbar(false);
                        setShowingCoverageZoneToolbar(
                          !showingCoverageZoneToolbar
                        );
                      }}
                    >
                      <img src={CoverageIcon} />
                    </button>
                  )}
              </>
            )}
            {canDrawRoutesAndZones && (
              <>
                {/* Route Drawing Button */}
                <button
                  className={`${styles.squareButton} ${
                    selectedPlayerIds?.length !== 1 && styles.disabled
                  } ${
                    isRouteDrawingEnabled ? styles.selected : styles.unselected
                  }`}
                  onClick={() => {
                    setIsRouteDrawingEnabled(!isRouteDrawingEnabled);
                  }}
                >
                  <PencilIcon />
                </button>
                {/* Line Cap Button */}
                {/* Line Type Button */}
                <button
                  className={`${styles.squareButton} ${
                    selectedPlayerIds?.length !== 1 && styles.disabled
                  }`}
                  onClick={rotateLineType}
                >
                  <img src={lineTypeIconMap[selectedLineType]} />
                </button>
                {/* Line Cap Button */}
                <button
                  className={`${styles.squareButton} ${
                    selectedPlayerIds?.length !== 1 && styles.disabled
                  }`}
                  onClick={rotateLineCap}
                >
                  <img src={lineCapIconMap[selectedLineCap]} />
                </button>
                {/* Line Cap Button */}
                <button
                  className={`${styles.squareButton} ${
                    selectedPlayerIds?.length !== 1 && styles.disabled
                  }`}
                  onClick={undoRoutePoint}
                >
                  <img src={RedX} className={styles.undoIcon} />
                </button>
              </>
            )}
          </div>
          <button
            className={`${styles.doneButton} ${
              !selectedPlayer && !selectedPlayerIds?.length && styles.hide
            }`}
            onClick={unselectSelectedPlayers}
          >
            Done
          </button>
        </div>
      </>
    </div>
  );
};

export default EditPlayToolbar;
