import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { DashboardMetricResponseModel } from "../../../../generated/from-api/models/dtos/dashboard-metric-response.model";
import {
  PlayerOverallReadinessModel,
  PlayerToInstallReadinessModel,
  PlayerToPlayReadinessModel,
  PlayerToQuizReadinessModel,
  PlayerToFormationReadinessModel,
} from "../../../../generated/from-api/models/dtos/player-to-play.readiness.model";
import { ReadinessScoreModel } from "../../../../generated/from-api/models/readiness-score.model";
import { PlayerReadinessSnapshotModel } from "../../../../generated/from-api/models/readiness-snapshot/player-readiness-snapshot.model";
import { TeamReadinessSnapshotModel } from "../../../../generated/from-api/models/readiness-snapshot/team-readiness-snapshot.model";
import { API_BASE_URL } from "../../constants";

export const DashboardService = {
  /**
   *
   * DASHBOARD 1.0 API ENDPOINT
   *
   * Retrieve a list of metrics data for a player dashboard of particular team, e.g topScorers: PlayerModel[],lowScorers: PlayerModel[];
   * @param teamId
   * @returns dashboard metrics
   */
  GET_DASHBOARD_METRICS: async function (
    teamId: string
  ): Promise<DashboardMetricResponseModel> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.DASHBOARD}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   *
   *
   *
   * Retrieve a list of metrics data for a dashboard overall players and graphs of particular team;
   * @param teamId
   * @returns dashboard metrics
   */
  GET_PLAYER_OVERALL_METRICS: async function (
    teamId: string
  ): Promise<{
    overallReadinessList: PlayerOverallReadinessModel[];
  }> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.OVERALL_READINESS}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   * Retrieve a list of metrics data for lessons of particular team;
   * @param teamId
   * @returns dashboard metrics
   */
  GET_PLAYER_INSTALL_METRICS: async function (
    teamId: string
  ): Promise<PlayerToInstallReadinessModel[]> {
    return (
      await axios.get(
        `${API_BASE_URL}${APIRoutes.OVERALL_READINESS}/installs`,
        {
          params: {
            teamId: teamId,
          },
        }
      )
    ).data;
  },

  /**
   * Retrieve a list of metrics data for plays of particular team;
   * @param teamId
   * @returns dashboard metrics
   */
  GET_PLAYER_PLAY_METRICS: async function (
    teamId: string
  ): Promise<PlayerToPlayReadinessModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.OVERALL_READINESS}/plays`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   * Retrieve a list of metrics data for formations of particular team;
   * @param teamId
   * @returns dashboard metrics
   */
  GET_PLAYER_FORMATION_METRICS: async function (
    teamId: string
  ): Promise<PlayerToFormationReadinessModel[]> {
    return (
      await axios.get(
        `${API_BASE_URL}${APIRoutes.OVERALL_READINESS}/formations`,
        {
          params: {
            teamId: teamId,
          },
        }
      )
    ).data;
  },

  /**
   * Retrieve a list of metrics data for Quiz of particular team;
   * @param teamId
   * @returns dashboard metrics
   */
  GET_PLAYER_QUIZ_METRICS: async function (
    teamId: string
  ): Promise<PlayerToQuizReadinessModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.OVERALL_READINESS}/quizzes`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   * Retrieve a player's readiness over time
   * @param teamId
   * @returns dashboard metrics
   */
  GET_TEAM_READINESS_OVER_TIME: async function (
    teamId: string,
    fromDate: string,
    toDate: string
  ): Promise<TeamReadinessSnapshotModel[]> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.OVERALL_READINESS}/team-readiness-over-time`,
        { teamId, fromDate, toDate }
      )
    ).data;
  },

  /**
   * Retrieve a player's readiness over time
   * @param teamId
   * @returns dashboard metrics
   */
  GET_PLAYER_READINESS_OVER_TIME: async function (
    playerId: string,
    fromDate: string,
    toDate: string
  ): Promise<PlayerReadinessSnapshotModel[]> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.OVERALL_READINESS}/player-readiness-over-time`,
        { playerId, fromDate, toDate }
      )
    ).data;
  },

  /**
   * Reset the team's scores
   * @param teamId
   * @returns
   */
  RESET_SCORES: async function (
    teamId: string
  ): Promise<ReadinessScoreModel[] | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.OVERALL_READINESS}/reset`, {
        teamId,
      })
    ).data;
  },
};
