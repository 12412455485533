import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { InvitationCodeModel } from "../../../../generated/from-api/models/invitation-code.model";
import { InvitationModel } from "../../../../generated/from-api/models/invitation.model";
import { JoinCodeModel } from "../../../../generated/from-api/models/join-code.model";
import { API_BASE_URL } from "../../constants";

export const InvitationCodeService = {
  /**
   * Use to invitation Code
   * @param invitationCode get the invitation code
   */
  GET: async function (
    teamId: string
  ): Promise<InvitationCodeModel | undefined> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.INVITATION_CODE}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   * Use to create an invitation code for the specified team
   * @param data the info needed to create the code
   */
  POST: async function (
    data: Pick<InvitationCodeModel, "teamId">
  ): Promise<InvitationCodeModel | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.INVITATION_CODE}`, {
        ...data,
      })
    ).data;
  },

  /**
   * Use to update an existing invitation Code,
   * or to request that a new code be generated
   * @param invitationCode update invitation code
   */
  PUT: async function (
    invitationCodeData: Pick<
      InvitationCodeModel,
      "teamId" | "expirationDate" | "disabled"
    >,
    generateANewCode: boolean
  ): Promise<InvitationCodeModel | undefined> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.INVITATION_CODE}`, {
        ...invitationCodeData,
        generateANewCode,
      })
    ).data;
  },

  /**
   * Use to join team through invitation code
   * @param joinCode Data to use for join team
   * @returns Newly joined team
   */
  POST_JOIN_CODE: async function (
    joinCode: Pick<
      JoinCodeModel,
      "invitationCode" | "firstName" | "lastName" | "playerPositions"
    >
  ): Promise<{ data: InvitationModel } | undefined> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.INVITATION_CODE}/accept`,
        joinCode
      )
    ).data;
  },
};
