import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import CharacterInput from "../Input/CharacterInput";
import { PlayModel } from "../../generated/from-api/models/play.model";
import { PlayCategoryEnum } from "../../generated/from-api/models/enums/play-category.enum";
import SingleSelect from "../SingleSelect/SingleSelect";
import { PlaybookContext } from "../../shared/shared-with-mobile/providers/playbook.provider";
import { FormationsContext } from "../../shared/shared-with-mobile/providers/formations.provider";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { flipPlayersByDimension } from "../../shared/shared-with-mobile/play-editor/playEditor.utils";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { userRoles } from "../../shared/shared-with-mobile/constants";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { TagsContext } from "../../shared/shared-with-mobile/providers/tags.provider";
import { DrawablePlayerModel } from "../../generated/from-api/models/drawable/drawablePlayer.model";
import styles from "./PlayDetails.module.scss";
import { FormationOption } from "../../shared/shared-with-mobile/play-editor/drawing.types";
import {
  categoryOptions,
  BASE_OFFENSIVE_FORMATION,
  categoryToPlayTypeMap,
} from "../../shared/shared-with-mobile/play-editor/playEditor.constants";
import PlayModeSelector from "./PlayModeSelector/PlayModeSelector";
import { mapTagsToSelectOptions } from "../../utils/functions";
import { FormationModel } from "../../generated/from-api/models/drawable/formation.model";
import ContentLimitModal from "../ContentLimitModal/ContentLimitModal";
import Button from "../Button/Button";
import {
  PlayOrder,
  PlaySetModel,
} from "../../generated/from-api/models/play-set.model";
import { PlaySetsContext } from "../../shared/shared-with-mobile/providers/playSets.provider";

interface Props {
  createForPlayset?: boolean;
  playset?: PlaySetModel;
}

const PlayDetails: React.FC<Props> = ({ createForPlayset, playset }) => {
  const { addPlays } = useContext(PlaybookContext);
  const { globalFormations, currentFormations } = useContext(FormationsContext);
  const { closeModal, handleCreateError } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);
  const { userProfile } = useContext(UserContext);
  const { updatePlaySet } = useContext(PlaySetsContext);
  const {
    situationTags,
    personnelPackageTags,
    runSchemeTags,
    passSchemeTags,
    defenseSchemeTags,
    blitzTags,
    coverageTags,
  } = useContext(TagsContext);
  const history = useHistory();

  const [formationOptions, setFormationOptions] = useState<FormationOption[]>(
    []
  );
  const [opponentFormationOptions, setOpponentFormationOptions] = useState<
    FormationOption[]
  >([]);

  const situationOptions = mapTagsToSelectOptions(situationTags);
  const personnelPackageOptions = mapTagsToSelectOptions(personnelPackageTags);
  const runSchemeOptions = mapTagsToSelectOptions(runSchemeTags);
  const passSchemeOptions = mapTagsToSelectOptions(passSchemeTags);
  const defenseSchemeOptions = mapTagsToSelectOptions(defenseSchemeTags);
  const blitzOptions = mapTagsToSelectOptions(blitzTags);
  const coverageOptions = mapTagsToSelectOptions(coverageTags);

  // pick the first offensive global formation to use as a base formation
  let baseFormation: FormationModel | undefined = globalFormations.find(
    (formation: FormationModel) => formation.type === "Offensive"
  );

  // if none is found, use a hardcoded default
  if (baseFormation === undefined) {
    baseFormation = BASE_OFFENSIVE_FORMATION;
  }

  const defaultPlay: PlayModel = {
    name: "",
    playType: baseFormation.type,
    playCategory: "Run",
    notes: "",
    formationName: baseFormation.name,
    opponentFormationName: null,
    drawablePlayers: baseFormation.drawablePlayers,
    ballOn: 40,
    hideOpponent: false,
    published: true,
    deleted: false,
  };

  const [playToCreate, setPlayToCreate] = useState<PlayModel>(defaultPlay);
  const [selectedFormationId, setSelectedFormationId] = useState<string>(
    baseFormation.id
  );
  const [
    selectedOpponentFormationId,
    setSelectedOpponentFormationId,
  ] = useState<string>("none");

  useEffect(() => {
    const { playType } = playToCreate;

    const nextFormationOptions = [...currentFormations, ...globalFormations]
      .filter((formation) => formation.type === playType)
      .map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));

    const nextOpponentFormationOptions = [
      ...currentFormations,
      ...globalFormations,
    ]
      .filter((formation) => formation.type !== playType)
      .map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));

    nextOpponentFormationOptions.unshift({
      label: "None",
      value: "none",
    });

    // if switching play type, need to update formations to match play type
    setFormationOptions(nextFormationOptions);
    setOpponentFormationOptions(nextOpponentFormationOptions);
    // and reselect a value
    if (
      nextFormationOptions.length > 0 &&
      nextOpponentFormationOptions.length > 0
    ) {
      setSelectedFormationId(nextFormationOptions[0].value);
      setSelectedOpponentFormationId(nextOpponentFormationOptions[0].value);
    }
  }, [playToCreate.playType]);

  useEffect(() => {
    const formation = [...globalFormations, ...currentFormations].find(
      (f) => f.id === selectedFormationId
    );

    if (!formation) {
      return;
    }

    let players: DrawablePlayerModel[];
    players = [...formation.drawablePlayers];

    const opponentFormation = [...globalFormations, ...currentFormations].find(
      (f) => f.id === selectedOpponentFormationId
    );

    if (opponentFormation) {
      const opponents = flipPlayersByDimension(
        opponentFormation.drawablePlayers,
        "y",
        "x"
      );
      players = [...players, ...opponents];
    }

    setPlayToCreate({
      ...playToCreate,
      formationName: formation.name,
      opponentFormationName: opponentFormation?.name || null,
      drawablePlayers: players,
    });
  }, [selectedFormationId, selectedOpponentFormationId]);

  const createPlay = async () => {
    const playToCreateData: any = { ...playToCreate };
    const teamId =
      userProfile?.role === userRoles.USER ? currentTeam?.id : undefined;

    playToCreateData.teamId = teamId;

    try {
      const created = await APIService.PLAY.POST([playToCreateData]);
      if (created) {
        if (createForPlayset && playset && playset.id) {
          const properties: PlayOrder[] = [
            {
              playId: created[0].id as string,
            },
          ];
          const updatedPlayOrder = [...playset.playOrder, ...properties];

          const payLoad: any = {
            id: playset.id as string,
            teamId: playset.teamId,
            name: playset.name,
            playOrder: updatedPlayOrder,
          };
          const newPlayAddedToPlaySet = await APIService.PLAY_SET.PUT(payLoad);

          if (newPlayAddedToPlaySet) {
            updatePlaySet(newPlayAddedToPlaySet);
          }
        }
        addPlays(created);
        history.push({
          pathname: `edit-play/${created[0].id}`,
          state: { isPlaySetEditModal: !!createForPlayset },
        });

        closeModal();
      }
    } catch (error) {
      handleCreateError(error, ContentLimitModal, "play", "plays");
    }
  };

  const updateCategory = (category: PlayCategoryEnum) => {
    const nextPlayType = categoryToPlayTypeMap[category];

    setPlayToCreate({
      ...playToCreate,
      playType: nextPlayType,
      playCategory: category,
      // reset the next 3 fields when changing playType
      // because scheme is only present when playType is "Offensive"
      // and blitz and coverage only present when playType is "Defensive"
      scheme: null,
      blitz: null,
      coverage: null,
    });
  };

  let schemeOptions: any[] = [];
  switch (playToCreate.playCategory) {
    case "Run":
      schemeOptions = runSchemeOptions;
      break;
    case "Pass":
      schemeOptions = passSchemeOptions;
      break;
    case "Defense":
      schemeOptions = defenseSchemeOptions;
      break;
  }

  return (
    <div className={styles.playDetails}>
      <div className={styles.topRow}>
        <PlayModeSelector
          setPlayToCreate={setPlayToCreate}
          playToCreate={playToCreate}
        />
        <CharacterInput
          placeholder={!playToCreate.name ? "Name of the play" : undefined}
          value={playToCreate.name}
          maxLength={100}
          onChange={(e) => {
            setPlayToCreate({
              ...playToCreate,
              name: e.currentTarget.value,
            });
          }}
        />
      </div>

      <div className={`${styles.selectsContainer} `}>
        <div className={styles.selectsContainerRow}>
          <SingleSelect
            id="playCategory"
            placeholder="Category *"
            alwaysOpen
            options={categoryOptions}
            value={playToCreate.playCategory}
            onChange={(e) => {
              updateCategory(e.currentTarget.value as PlayCategoryEnum);
            }}
          />
          <SingleSelect
            id="formation"
            placeholder={
              playToCreate.playType === "Offensive"
                ? "Offensive Formation *"
                : "Defensive Front *"
            }
            alwaysOpen
            options={formationOptions}
            value={selectedFormationId}
            onChange={(e) => {
              setSelectedFormationId(e.currentTarget.value);
            }}
          />
          <SingleSelect
            id="opponentFormation"
            placeholder={
              playToCreate.playType === "Offensive"
                ? "Defensive Front"
                : "Offensive Formation"
            }
            alwaysOpen
            options={opponentFormationOptions}
            value={selectedOpponentFormationId}
            onChange={(e) => {
              setSelectedOpponentFormationId(e.currentTarget.value);
            }}
          />
          <SingleSelect
            id="scheme"
            placeholder="Subcategory"
            alwaysOpen
            options={schemeOptions}
            value={playToCreate.scheme || undefined}
            onChange={(e) => {
              setPlayToCreate({
                ...playToCreate,
                scheme: e.currentTarget.value || null,
              });
            }}
          />
        </div>
        <div
          className={`${styles.selectsContainerRow} ${
            playToCreate.playType === "Defensive"
              ? styles.secondChildDefense
              : styles.secondChildOffense
          }`}
        >
          {playToCreate.playType === "Defensive" && (
            <SingleSelect
              id="blitz"
              placeholder="Blitz"
              alwaysOpen
              options={blitzOptions}
              value={playToCreate.blitz || undefined}
              onChange={(e) => {
                setPlayToCreate({
                  ...playToCreate,
                  blitz: e.currentTarget.value || null,
                });
              }}
            />
          )}
          <SingleSelect
            id="personnel"
            placeholder={
              playToCreate.playType === "Offensive"
                ? "Personnel"
                : "Opponent's Personnel"
            }
            alwaysOpen
            options={personnelPackageOptions}
            value={playToCreate.personnel || undefined}
            onChange={(e) => {
              setPlayToCreate({
                ...playToCreate,
                personnel: e.currentTarget.value || null,
              });
            }}
          />
          <SingleSelect
            id="coverage"
            placeholder={
              playToCreate.playType === "Defensive"
                ? "Coverage"
                : "Opponent's Coverage"
            }
            alwaysOpen
            options={coverageOptions}
            value={playToCreate.coverage || undefined}
            onChange={(e) => {
              setPlayToCreate({
                ...playToCreate,
                coverage: e.currentTarget.value || null,
              });
            }}
          />
          <SingleSelect
            id="situation"
            placeholder="Situation"
            alwaysOpen
            options={situationOptions}
            value={playToCreate.situation || undefined}
            onChange={(e) => {
              setPlayToCreate({
                ...playToCreate,
                situation: e.currentTarget.value || null,
              });
            }}
          />
        </div>
      </div>
      <Button
        className={styles.continueButton}
        theme={"secondary"}
        size={"small"}
        onClick={createPlay}
        disabled={!playToCreate.name}
      >
        Continue
      </Button>
      <Button
        className={styles.cancelButton}
        theme={"tertiary"}
        size={"small"}
        onClick={closeModal}
      >
        Cancel
      </Button>
    </div>
  );
};

export default PlayDetails;
