import React, { useContext, useState } from "react";
import styles from "../Admin.module.scss";
import { LicenseTiersContext } from "../../../shared/shared-with-mobile/providers/licenseTiers";
import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { AdminUtility } from "../admin.utility";
import GreenCheckIconSmall from "../../../resources/icons/GreenCheckIconSmall";
import YellowWarningIconSmall from "../../../resources/icons/YellowWarningIconSmall";
import RedAlertIconSmall from "../../../resources/icons/RedAlertIconSmall";
import {
  PaymentStatus,
  QuestionsFilterOptions,
  TimeSpentFilterOptions,
  UsersFilterOptions,
  SetupCriteria,
  CreationDateFilterOptions,
} from "../ManageLicensesAdmin";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { UserProfileModel } from "../../../generated/from-api/models/user-profile.model";
import { getAbbreviatedDisplayName } from "../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import TrialIcon from "../../../resources/icons/TrialIcon";
import { getUserFacingSubscriptionName } from "../../../generated/from-api/api-constants/license-tier-constants";

interface Props {
  licenseFilters: Map<string, string[]>;
  tnReps: UserProfileModel[];
  saveCallback?: (filters: Map<string, string[]>) => void;
}

const FilterLicenseModal: React.FC<Props> = ({
  licenseFilters,
  tnReps,
  saveCallback,
}) => {
  const { licenseTiers } = useContext(LicenseTiersContext);
  const [filters, setFilters] = useState<Map<string, string[]>>(licenseFilters);
  const [countFilters, setCountFilters] = useState<number>(
    AdminUtility.countFiltersInUse(licenseFilters)
  );
  const [clearReps, setClearReps] = useState<boolean>(false);

  const determineChecked = (category: string, value: string) => {
    const filterList = filters.get(category);
    if (!filterList) return false;
    return filterList.includes(value);
  };

  const handleFilterUpdate = (category: string, value: string) => {
    const filtersCopy = new Map(filters);
    let filterList = filtersCopy.get(category);
    if (!filterList) {
      filtersCopy.set(category, []);
      filterList = [];
    }
    if (filterList.includes(value)) {
      filterList = filterList.filter((name) => name !== value);
    } else {
      filterList.push(value);
    }
    filtersCopy.set(category, filterList);
    setFilters(filtersCopy);
    setCountFilters(AdminUtility.countFiltersInUse(filtersCopy));
  };

  const handleTnRepUpdate = (values: string[]) => {
    const filtersCopy = new Map(filters);
    filtersCopy.set("tnRep", values);
    setFilters(filtersCopy);
    setCountFilters(AdminUtility.countFiltersInUse(filtersCopy));
    setClearReps(false);
  };

  const paymentIcon = (payment: string) => {
    if (payment === PaymentStatus.Free) return <TrialIcon />;
    if (payment === PaymentStatus.Trial) return <TrialIcon />;
    if (payment === PaymentStatus.TrialExpired) return <RedAlertIconSmall />;
    if (payment === PaymentStatus.Paid) return <GreenCheckIconSmall />;
    if (payment === PaymentStatus.PaidNoInvoice) return <RedAlertIconSmall />;
    if (payment === PaymentStatus.UnpaidOk) return <YellowWarningIconSmall />;
    return <RedAlertIconSmall />;
  };

  const handleFilterClear = () => {
    const emptyFilters = new Map<string, string[]>();
    setFilters(emptyFilters);
    setCountFilters(0);
    setClearReps(true);
  };

  const handleFilterSubmit = () => {
    saveCallback && saveCallback(filters);
  };

  const clearFilterTitle = () => {
    const title = "Clear Filters";
    if (countFilters <= 0) return title;
    return title + " (" + countFilters + ")";
  };

  return (
    <div className={styles.filterLicenseModalWrapper}>
      <div className={styles.filterLicenseModal__Body}>
        <div className={styles.filterLicenseModal__Row}>
          <span className={styles.filterLicenseModal__Header}>Tier Level</span>
        </div>
        <div className={styles.filterLicenseModal__Row}>
          {licenseTiers
            .slice()
            .sort((aTier, bTier) => {
              // as of 3/31/2023, these are the 5 license tiers which we are actively selling
              const TIER_SORTER: Record<string, number> = {
                COLLEGE2023: 5,
                GOLD2023: 4,
                SILVER2023: 3,
                YOUTH2023: 2,
                BASIC2023: 1,
              };

              const aTierSortValue = TIER_SORTER[aTier.tier] || 0;
              const bTierSortValue = TIER_SORTER[bTier.tier] || 0;

              return bTierSortValue - aTierSortValue;
            })
            .map((tier) => {
              return (
                <div key={tier.id}>
                  <Checkbox
                    onChange={() => handleFilterUpdate("tier", tier.tier)}
                    checked={determineChecked("tier", tier.tier)}
                  >
                    {getUserFacingSubscriptionName(tier.tier)}
                  </Checkbox>
                </div>
              );
            })}
        </div>
        <div className={styles.filterLicenseModal__Row}>
          <span className={styles.filterLicenseModal__Header}>
            Payment Status
          </span>
        </div>
        <div className={styles.filterLicenseModal__Row}>
          {Object.values(PaymentStatus).map((payment) => {
            return (
              <div className={styles.filterLicenseModal__Payment} key={payment}>
                <Checkbox
                  onChange={() => handleFilterUpdate("payment", payment)}
                  checked={determineChecked("payment", payment)}
                >
                  {payment}
                </Checkbox>
                {paymentIcon(payment)}
              </div>
            );
          })}
        </div>
        <div className={styles.filterLicenseModal__Row}>
          <span className={styles.filterLicenseModal__Header}>
            Creation Date
          </span>
        </div>
        <div className={styles.filterLicenseModal__Row}>
          {CreationDateFilterOptions.map((range) => {
            return (
              <div key={range.name}>
                <Checkbox
                  onChange={() =>
                    handleFilterUpdate("creationDate", range.name)
                  }
                  checked={determineChecked("creationDate", range.name)}
                >
                  {range.name}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className={styles.filterLicenseModal__Row}>
          <span className={styles.filterLicenseModal__Header}>
            TN Representitive
          </span>
        </div>
        <div className={styles.filterLicenseModal__Row__TnRep}>
          <MultiSelect
            placeholder="All"
            options={tnReps.map((rep) => {
              return {
                label: getAbbreviatedDisplayName(rep.firstName, rep.lastName),
                value: rep.id,
              };
            })}
            onChange={(value) => {
              handleTnRepUpdate((value.currentTarget as any).selectedList);
            }}
            value={filters.get("tnRep")}
            clearField={clearReps}
          />
        </div>
        <div className={styles.filterLicenseModal__Row}>
          <span className={styles.filterLicenseModal__Header}>Users</span>
        </div>
        <div className={styles.filterLicenseModal__Row}>
          {UsersFilterOptions.map((range) => {
            return (
              <div key={range.name}>
                <Checkbox
                  onChange={() => handleFilterUpdate("users", range.name)}
                  checked={determineChecked("users", range.name)}
                >
                  {range.name}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className={styles.filterLicenseModal__Row}>
          <span className={styles.filterLicenseModal__Header}>
            Time Spent (Hrs)
          </span>
        </div>
        <div className={styles.filterLicenseModal__Row}>
          {TimeSpentFilterOptions.map((range) => {
            return (
              <div key={range.name}>
                <Checkbox
                  onChange={() => handleFilterUpdate("timeSpent", range.name)}
                  checked={determineChecked("timeSpent", range.name)}
                >
                  {range.name}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className={styles.filterLicenseModal__Row}>
          <span className={styles.filterLicenseModal__Header}>
            Questions Answered
          </span>
        </div>
        <div className={styles.filterLicenseModal__Row}>
          {QuestionsFilterOptions.map((range) => {
            return (
              <div key={range.name}>
                <Checkbox
                  onChange={() => handleFilterUpdate("questions", range.name)}
                  checked={determineChecked("questions", range.name)}
                >
                  {range.name}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className={styles.filterLicenseModal__Row}>
          <span className={styles.filterLicenseModal__Header}>Team Setup</span>
        </div>
        <div className={styles.filterLicenseModal__Row}>
          {Object.values(SetupCriteria).map((criteria) => {
            if (criteria === SetupCriteria.teamCreated) return "";
            return (
              <Checkbox
                key={criteria}
                onChange={() => handleFilterUpdate("teamSetup", criteria)}
                checked={determineChecked("teamSetup", criteria)}
              >
                {criteria}
              </Checkbox>
            );
          })}
          <Checkbox
            key={"Complete"}
            onChange={() => handleFilterUpdate("teamSetup", "Complete")}
            checked={determineChecked("teamSetup", "Complete")}
          >
            {"Complete"}
          </Checkbox>
        </div>
      </div>
      <div className={styles.filterLicenseModal__Footer}>
        <div className={styles.filterLicenseModal__ButtonsWrapper}>
          <Button
            theme={"secondary"}
            size={"small"}
            onClick={handleFilterClear}
          >
            {clearFilterTitle()}
          </Button>
          <Button size={"small"} onClick={handleFilterSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterLicenseModal;
