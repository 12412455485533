import React, { useRef, useState } from "react";
import styles from "./ColorPicker.module.scss";
import { SketchPicker } from "react-color";

interface Props {
  className?: string;
  onChange?: (color: string) => void;
  name?: string;
  text: string;
  color?: string;
  onColorChangeCallback?: (color: string) => void;
}

const ColorPicker: React.FC<Props> = ({
  className = "",
  onChange,
  name = "",
  text,
  color = "#fff",
  onColorChangeCallback,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const [currentColor, setCurrentColor] = useState<string>(color);
  const [isColorPickerVisible, setColorPickerVisibility] = useState<boolean>(
    false
  );

  const onParentBlur = (event: any) => {
    setColorPickerVisibility(
      parentRef.current
        ? parentRef.current.contains(event.relatedTarget)
        : false
    );
  };

  return (
    <div
      ref={parentRef}
      className={`${styles.colorPicker} ${className}`}
      tabIndex={0}
      onBlur={onParentBlur}
    >
      <button
        className={styles.colorPicker__Button}
        onClick={() => setColorPickerVisibility(!isColorPickerVisible)}
      >
        <span
          className={styles.colorPicker__Color}
          style={{ backgroundColor: currentColor }}
        />
        <span className={styles.colorPicker__Text}>{text}</span>
      </button>
      {isColorPickerVisible && (
        <div className={styles.colorPicker__SketchPicker}>
          <SketchPicker
            disableAlpha
            color={currentColor}
            onChangeComplete={(color) => {
              setCurrentColor(color.hex);
              onColorChangeCallback && onColorChangeCallback(color.hex);
              onChange &&
                onChange({
                  name: name,
                  type: "color",
                  color: color.hex,
                } as any);
              setCurrentColor(color.hex);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
