import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";

import styles from "./NewFormationModal.module.scss";
import Button from "../../../../components/Button/Button";
import { APIService } from "../../../../shared/shared-with-mobile/api-client/api.service";
import { FormationsContext } from "../../../../shared/shared-with-mobile/providers/formations.provider";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import { FormationModel } from "../../../../generated/from-api/models/drawable/formation.model";
import { TeamContext } from "../../../../shared/shared-with-mobile/providers/team.provider";
import { UserContext } from "../../../../shared/shared-with-mobile/providers/user.provider";
import { userRoles } from "../../../../shared/shared-with-mobile/constants";
import CharacterInput from "../../../../components/Input/CharacterInput";
import SingleSelect from "../../../../components/SingleSelect/SingleSelect";

interface Props {
  baseFormation: FormationModel;
  formationOptions: FormationModel[];
}

const NewFormationModal: React.FC<Props> = ({
  baseFormation,
  formationOptions,
}) => {
  const history = useHistory();

  const { addFormations } = useContext(FormationsContext);
  const { closeModal } = useContext(UIContext);
  const { userProfile } = useContext(UserContext);
  const { currentTeam } = useContext(TeamContext);

  const formationSelectOptions = formationOptions.map((formation) => ({
    label: formation.name,
    value: formation.id,
  }));

  const defaultFormation: FormationModel = {
    id: "1",
    name: "",
    drawablePlayers: baseFormation.drawablePlayers,
    type: baseFormation.type,
    deleted: false,
  };

  const [formationToCreate, setFormationToCreate] = useState(defaultFormation);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFormationId, setSelectedFormationId] = useState(
    baseFormation.id
  );

  const createFormation = async () => {
    const formationToCreateData = { ...formationToCreate };
    const teamId =
      userProfile?.role === userRoles.USER ? currentTeam?.id : undefined;

    formationToCreateData.teamId = teamId;

    try {
      const created = await APIService.FORMATION.POST(formationToCreateData);
      if (created) {
        addFormations([created]);
        history.push(`/edit-formation/${created.id}`);
        closeModal();
      }
    } catch (e: any) {
      if (e?.response?.status === 400) {
        setErrorMessage("That name was already taken. Please try another.");
      }
    }
  };

  useEffect(() => {
    const selectedFormation = formationOptions.find(
      (formation: FormationModel) => formation.id === selectedFormationId
    );

    if (!selectedFormation) {
      return;
    }

    setFormationToCreate({
      ...formationToCreate,
      drawablePlayers: selectedFormation.drawablePlayers,
    });
  }, [selectedFormationId]);

  return (
    <div className={styles.newFormationModal}>
      <CharacterInput
        placeholder={
          !formationToCreate.name ? "Name of the formation" : undefined
        }
        maxLength={100}
        value={formationToCreate.name}
        onChange={(e) => {
          setFormationToCreate({
            ...formationToCreate,
            name: e.currentTarget.value,
          });
        }}
      />
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      <SingleSelect
        options={formationSelectOptions}
        id="formation"
        placeholder="Base Formation"
        alwaysOpen
        value={selectedFormationId}
        onChange={(e) => {
          setSelectedFormationId(e.currentTarget.value);
        }}
      />
      <div className={styles.buttonRow}>
        <Button theme="tertiary" size={"small"} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          theme="primary"
          size={"small"}
          onClick={createFormation}
          disabled={!formationToCreate.name}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default NewFormationModal;
