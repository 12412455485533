import { useContext } from "react";

import styles from "./Toast.module.scss";
import Toast from "./Toast";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";

const ToastList: React.FC = () => {
  const { toasts } = useContext(UIContext);

  return (
    <div className={styles.toastList}>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default ToastList;
