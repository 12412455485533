import { ResponsiveBar, BarDatum } from "@nivo/bar";

interface Props {
  data: BarDatum[];
  indexBy: string;
  valueKey: string;
  isLabel?: boolean;
  isFlipped?: boolean;
  xLabel?: string;
  yLabel?: string;
  ySymbol?: string;
}

const BarChart: React.FC<Props> = ({
  data,
  indexBy,
  valueKey,
  xLabel = "",
  yLabel = "",
  ySymbol = "",
  isLabel = false,
  isFlipped = false,
}) => {
  const theme = {
    textColor: "#ffffff",
    axis: {
      fontSize: "14px",
      tickColor: "#eee",
      ticks: {
        line: {
          stroke: "transparent",
        },
        text: {
          fill: "#7E7E85",
          fontSize: "13px",
          fontFamily: "QUANTICO",
        },
      },
    },
    grid: {
      line: {
        stroke: "#7E7E85",
        strokeWidth: 0.3,
      },
    },
  };

  // compute max yValue to help determine a reasonable number of y-axis ticks
  const yValues = data.map((datum) => Number(datum[valueKey]));
  const maxValue = Math.max(...yValues);

  // Nivo uses this as a general target for the number of y-axis tick marks
  // and chooses a nearby value that works with the data
  const yAxisNumTicks = maxValue > 10 ? Math.ceil(maxValue / 10) : 4;

  const getPlayers = (data: any) => {
    return Number(data.data.playerCount) > 0
      ? `${data.data.playerCount}` +
          (Number(data.data.playerCount) > 1 ? ` Players` : ` Player`)
      : "";
  };

  return (
    <ResponsiveBar
      data={data}
      indexBy={indexBy}
      keys={[valueKey]}
      theme={theme}
      isInteractive={false}
      enableLabel={isLabel ? true : false}
      label={isLabel ? (d) => getPlayers(d) : ""}
      layers={[
        "grid",
        "axes",
        "bars",
        "markers",
        "legends",
        "annotations",
        ({ bars, labelSkipWidth }) => {
          return (
            <g>
              {bars.map(({ width, x, y, data }, index) => {
                return isLabel && width < labelSkipWidth ? (
                  <text
                    key={index + 1}
                    transform={`translate(${x + width / 2}, ${y - 10})`}
                    fontSize={`12px`}
                    fontWeight={500}
                    textAnchor="middle"
                    fill="gray"
                    dominantBaseline="central"
                  >
                    {getPlayers(data)}
                  </text>
                ) : null;
              })}
            </g>
          );
        },
      ]}
      labelSkipWidth={100}
      layout={isFlipped ? "horizontal" : "vertical"}
      labelTextColor={`gray`}
      margin={{
        top: 20,
        right: 30,
        bottom: 70,
        left:
          yLabel && ySymbol
            ? isFlipped
              ? 90
              : 65
            : yLabel || ySymbol
            ? 50
            : 30,
      }}
      padding={0.4}
      valueScale={{ type: "linear" }}
      colors={["#00b2ff"]}
      axisBottom={{
        tickSize: 3,
        tickValues: yAxisNumTicks,
        tickPadding: 5,
        tickRotation: isFlipped ? 45 : 25,
        legendOffset: 60,
        legend: !isFlipped ? xLabel : yLabel,
        legendPosition: "middle",
        format: isFlipped
          ? (value) => `${Number(value)}${ySymbol}`
          : (value) => `${value}`,
      }}
      axisLeft={{
        tickSize: 0,
        tickValues: yAxisNumTicks,
        tickPadding: 5,
        tickRotation: isFlipped ? -45 : 0,
        legendOffset: isFlipped ? -85 : -60,
        legend: !isFlipped ? yLabel : xLabel,
        legendPosition: "middle",
        format: isFlipped
          ? (value) => `${value}`
          : (value) => `${Number(value)} ${ySymbol}`,
      }}
      enableGridX={isFlipped}
      enableGridY={!isFlipped}
      borderRadius={3}
      gridYValues={yAxisNumTicks}
      gridXValues={yAxisNumTicks}
    />
  );
};

export default BarChart;
