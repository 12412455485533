import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { LicenseUsageModel } from "../../../../generated/from-api/models/license-usage.model";
import { API_BASE_URL } from "../../constants";

export const LicenseQuotaService = {
  /**
   * Use to retrieve a list of current license usage
   */
  LIST: async function (): Promise<LicenseUsageModel[]> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.LICENSE_QUOTA}/all`))
      .data;
  },

  GET_USAGE_FOR_TEAM: async function (
    teamId: string
  ): Promise<LicenseUsageModel | undefined> {
    return (
      await axios.get(
        `${API_BASE_URL}${APIRoutes.LICENSE_QUOTA}/usage/${teamId}`
      )
    ).data;
  },
};
