import * as React from "react";

const PauseIcon: React.FC = () => (
  <svg
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66667 9.66665H0V0.333313H2.66667V9.66665ZM5.33333 9.66665V0.333313H8V9.66665H5.33333Z"
      fill="white"
    />
  </svg>
);

export default PauseIcon;
