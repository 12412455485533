import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { PlayContentModel } from "../../../../generated/from-api/models/dtos/play-content.model";
import { PlayModel } from "../../../../generated/from-api/models/play.model";
import { API_BASE_URL } from "../../constants";

export const PlayService = {
  /**
   * Use to retrieve a list of plays for a team or from the global library if no team id is specified
   */
  LIST: async function (
    teamId?: string,
    onlyIfModifiedSince?: string
  ): Promise<PlayModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.PLAY}`, {
        params: {
          teamId: teamId,
          onlyIfModifiedSince,
        },
      })
    ).data;
  },
  /**
   * Use to retrieve a specific play
   */
  GET: async function (playId: string): Promise<PlayModel[]> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.PLAY}/${playId}`)).data;
  },
  /**
   * Use to create a play. If a the play was not created, returns undefined.
   * @param
   * @returns PlayModel[] | undefined
   */
  POST: async function (
    playData: Pick<
      PlayModel,
      | "teamId" // "teamId" is present when the user is creating a play for the specific team
      | "name"
      | "playType"
      | "playCategory"
      | "formationName"
      | "opponentFormationName"
      | "scheme"
      | "personnel"
      | "situation"
      | "blitz"
      | "coverage"
      | "notes"
      | "drawablePlayers"
      | "ballOn"
      | "hideOpponent"
      | "published"
    >[]
  ): Promise<PlayContentModel[] | undefined> {
    playData = playData.map((play) => {
      return play;
    });
    return (await axios.post(`${API_BASE_URL}${APIRoutes.PLAY}`, playData))
      .data;
  },
  /**
   * Use to update a play
   * @param
   */
  PUT: async function (
    playData: Pick<
      PlayModel,
      | "id"
      | "name"
      | "playType"
      | "playCategory"
      | "formationName"
      | "opponentFormationName"
      | "scheme"
      | "personnel"
      | "situation"
      | "blitz"
      | "coverage"
      | "notes"
      | "drawablePlayers"
      | "ballOn"
      | "hideOpponent"
      | "published"
    >
  ): Promise<PlayContentModel | undefined> {
    return (await axios.put(`${API_BASE_URL}${APIRoutes.PLAY}`, playData)).data;
  },
  /**
   * Use to delete a play
   * @param playId
   * @returns
   */
  DELETE: async function (playId: string): Promise<{ deleted: boolean }> {
    return (await axios.delete(`${API_BASE_URL}${APIRoutes.PLAY}/${playId}`))
      .data;
  },
};
