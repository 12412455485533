import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { TagTypeEnum } from "../../../../generated/from-api/models/enums/tag-type.enum";
import { TagModel } from "../../../../generated/from-api/models/tag.model";
import { API_BASE_URL } from "../../constants";

export const TagService = {
  /**
   * Retrieve a list of tags for a team or for the global playbook, also can filter with query param "?type="
   * @param teamId
   * @returns gamePlan[]
   */
  GET_TAGS_FOR_TEAM: async function (
    teamId: string | null,
    tagType?: TagTypeEnum
  ): Promise<TagModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.TAG}`, {
        params: {
          teamId: teamId,
          type: tagType,
        },
      })
    ).data;
  },

  /**
   * Retrieve a tag by id
   * @param tagId
   * @returns tag
   */
  GET: async function (tagId: string): Promise<TagModel> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.TAG}/${tagId}`)).data;
  },

  /**
   * Use to create a tag in team(only of types => scheme,personal package and situation)
   * if TeamId is null, it is created in global playbook (Admin only)
   * @param tagData
   * @returns tag
   */
  POST: async function (
    tagData: Pick<TagModel, "name" | "teamId" | "type" | "category">
  ): Promise<TagModel | undefined> {
    return (await axios.post(`${API_BASE_URL}${APIRoutes.TAG}`, tagData)).data;
  },

  /**
   * Update a tag
   * @param tagId
   * @param dataToUpdate
   * @returns updated tag
   */
  PUT: async function (
    tagId: string,
    dataToUpdate: Pick<TagModel, "name">
  ): Promise<TagModel> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.TAG}`, {
        id: tagId,
        ...dataToUpdate,
      })
    ).data;
  },

  /**
   * Delete a tag by id,
   * @param tagId
   * @returns
   */
  DELETE: async function (tagId: string): Promise<{ deleted: boolean }> {
    return (await axios.delete(`${API_BASE_URL}${APIRoutes.TAG}/${tagId}`))
      .data;
  },
};
