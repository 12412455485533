import * as React from "react";

const RedAlertIcon: React.FC = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-tag="allowRowEvents"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 13.2011C0.5 6.19012 6.19012 0.5 13.2011 0.5C20.2122 0.5 25.9023 6.19012 25.9023 13.2011C25.9023 20.2122 20.2122 25.9023 13.2011 25.9023C6.19012 25.9023 0.5 20.2122 0.5 13.2011ZM12.9276 5.87931L12.431 8.79695L11.4379 16.7225H13.5897L15.2448 8.79695L15.7414 5.87931H12.9276ZM10.569 18.878L10.3621 20.1844L11.0862 21.1207H12.4931L13.5483 20.1844L13.7552 18.878L13.0103 17.9418H11.6034L10.569 18.878Z"
      fill="#DA3731"
      data-tag="allowRowEvents"
    />
  </svg>
);

export default RedAlertIcon;
