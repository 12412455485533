import MultiCanvas from "wavesurfer.js/src/drawer.multicanvas.js";

export default class AudioPlayerCustomRender extends MultiCanvas {
  constructor(container, params) {
    super(container, params);
  }

  // Overriding the default drawBars method. Employs most of the pre-existing logic.
  drawBars(peaks, channelIndex, start, end) {
    return this.prepareDraw(
      peaks,
      channelIndex,
      start,
      end,
      ({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        absmax,
        hasMinVals,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        height,
        offsetY,
        halfH,
        peaks,
        channelIndex: ch,
      }) => {
        // if drawBars was called within ws.empty we don't pass a start and
        // don't want anything to happen
        if (start === undefined) {
          return;
        }
        // Skip every other value if there are negatives.
        const peakIndexScale = hasMinVals ? 2 : 1;
        const length = peaks.length / peakIndexScale;
        const bar = this.params.barWidth * this.params.pixelRatio;
        const gap =
          this.params.barGap === null
            ? Math.max(this.params.pixelRatio, ~~(bar / 2))
            : Math.max(
                this.params.pixelRatio,
                this.params.barGap * this.params.pixelRatio
              );
        const step = bar + gap;

        const scale = length / this.width;
        const first = start;
        const last = end;
        let i = first;

        /* Figure out local maximum, this is different than the provided
         * absMax since that may not be included depending on our scaling. */
        let localMax = 0;
        for (i; i < last; i += step) {
          const peak = peaks[Math.floor(i * scale * peakIndexScale)] || 0;
          if (Math.abs(peak > localMax)) localMax = peak;
        }
        i = first;

        /* Actually calculate and draw the peak. Instead of linear scaling,
         * adjust the peak by first taking the square root. */
        for (i; i < last; i += step) {
          const peak = peaks[Math.floor(i * scale * peakIndexScale)] || 0;
          const adjustedPeak = Math.sqrt(Math.abs(peak / localMax));
          let h = Math.round(adjustedPeak * halfH);

          /* in case of silences, allow the user to specify that we
           * always draw *something* (normally a 1px high bar) */
          if (h == 0 && this.params.barMinHeight) {
            h = this.params.barMinHeight;
          }

          this.fillRect(
            i + this.halfPixel,
            halfH - h + offsetY,
            bar + this.halfPixel,
            h * 2,
            this.barRadius,
            ch
          );
        }
      }
    );
  }
}
