import * as React from "react";

const ToolbarUnderlineIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0001 7.16667C11.0001 9.925 8.75841 12.1667 6.00008 12.1667C3.24175 12.1667 1.00008 9.925 1.00008 7.16667V0.5H3.08341V7.16667C3.08341 8.775 4.39175 10.0833 6.00008 10.0833C7.60841 10.0833 8.91675 8.775 8.91675 7.16667V0.5H11.0001V7.16667ZM0.166748 15.5V13.8333H11.8334V15.5H0.166748Z"
    />
  </svg>
);

export default ToolbarUnderlineIcon;
