import * as React from "react";

const CloseIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path d="M15.6632 1.88093L14.1196 0.337402L8.00028 6.45676L1.88093 0.337402L0.337402 1.88093L6.45676 8.00028L0.337402 14.1196L1.88093 15.6632L8.00028 9.54381L14.1196 15.6632L15.6632 14.1196L9.5438 8.00028L15.6632 1.88093Z" />
  </svg>
);

export default CloseIcon;
