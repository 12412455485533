export const getUserFacingSubscriptionName = (tier: string): string => {
  const entries = Object.values(LICENSE_TIERS);
  const result = entries.find((kv) => kv.name === tier);
  return result ? result.userFacingSubscriptionName : "";
};

export const EXTRA_TEAM_LICENSE = {
  id: "",
  name: "EXTRA_TEAM_LICENSE",
  userFacingSubscriptionName: "Extra Team",
  hubspotFacingSubscriptionName: "Extra Team",
  hs_product_id: "1345752438",
};

export const LICENSE_TIERS = {
  TRIAL: {
    id: "00000000-0000-0000-0000-000000000000",
    name: "TRIAL",
    userFacingSubscriptionName: "Trial (Legacy)",
    hubspotFacingSubscriptionName: "Trial",
    hs_product_id: "",
  },
  PREMIER: {
    id: "00000000-0000-0000-0000-000000000001",
    name: "PREMIER",
    userFacingSubscriptionName: "Premier Trial (Legacy)",
    hubspotFacingSubscriptionName: "Trial",
    hs_product_id: "",
  },
  FREE: {
    id: "10000000-0000-0000-0000-000000000000",
    name: "FREE",
    userFacingSubscriptionName: "Free (Legacy)",
    hubspotFacingSubscriptionName: "Free",
    hs_product_id: "",
  },
  BRONZE: {
    id: "20000000-0000-0000-0000-000000000000",
    name: "BRONZE",
    userFacingSubscriptionName: "Bronze (Legacy)",
    hubspotFacingSubscriptionName: "Bronze",
    hs_product_id: "1327831946",
  },
  SILVER: {
    id: "30000000-0000-0000-0000-000000000000",
    name: "SILVER",
    userFacingSubscriptionName: "Silver (Legacy)",
    hubspotFacingSubscriptionName: "Silver",
    hs_product_id: "1327835318",
  },
  GOLD: {
    id: "40000000-0000-0000-0000-000000000000",
    name: "GOLD",
    userFacingSubscriptionName: "Gold (Legacy)",
    hubspotFacingSubscriptionName: "Gold",
    hs_product_id: "1327832633",
  },
  PLATINUM: {
    id: "50000000-0000-0000-0000-000000000000",
    name: "PLATINUM",
    userFacingSubscriptionName: "Platinum (Legacy)",
    hubspotFacingSubscriptionName: "Platinum",
    hs_product_id: "1346397702",
  },
  FREE2023: {
    id: "10000000-1000-0000-0000-000000000000",
    name: "FREE2023",
    userFacingSubscriptionName: "Free",
    hubspotFacingSubscriptionName: "Free",
    hs_product_id: "",
  },
  BRONZE2023: {
    id: "20000000-1000-0000-0000-000000000000",
    name: "BRONZE2023",
    userFacingSubscriptionName: "Bronze",
    hubspotFacingSubscriptionName: "Bronze",
    hs_product_id: "",
  },
  SILVER2023: {
    id: "30000000-1000-0000-0000-000000000000",
    name: "SILVER2023",
    userFacingSubscriptionName: "High School",
    hubspotFacingSubscriptionName: "High School",
    hs_product_id: "",
  },
  GOLD2023: {
    id: "40000000-1000-0000-0000-000000000000",
    name: "GOLD2023",
    userFacingSubscriptionName: "High School +",
    hubspotFacingSubscriptionName: "High School Plus",
    hs_product_id: "",
  },
  BASIC2023: {
    id: "10000000-2000-0000-0000-000000000000",
    name: "BASIC2023",
    userFacingSubscriptionName: "Basic",
    hubspotFacingSubscriptionName: "Basic",
    hs_product_id: "",
  },
  YOUTH2023: {
    id: "20000000-2000-0000-0000-000000000000",
    name: "YOUTH2023",
    userFacingSubscriptionName: "Youth / Flag",
    hubspotFacingSubscriptionName: "Youth / Flag",
    hs_product_id: "",
  },
  COLLEGE2023: {
    id: "50000000-2000-0000-0000-000000000000",
    name: "COLLEGE2023",
    userFacingSubscriptionName: "College",
    hubspotFacingSubscriptionName: "College",
    hs_product_id: "",
  },
};

export const PAID_PRODUCTS = [
  LICENSE_TIERS.BRONZE,
  LICENSE_TIERS.SILVER,
  LICENSE_TIERS.GOLD,
  LICENSE_TIERS.PLATINUM,
  LICENSE_TIERS.BRONZE2023,
  LICENSE_TIERS.SILVER2023,
  LICENSE_TIERS.GOLD2023,
  LICENSE_TIERS.YOUTH2023,
  LICENSE_TIERS.COLLEGE2023,
  EXTRA_TEAM_LICENSE,
];
