import { useEffect, useState } from "react";

import styles from "./QuizPreview.module.scss";
import { CustomQuizModel } from "../../../../generated/from-api/models/custom-quiz.model";
import InstallPreviewQuestion from "../../../Installs/install-components/InstallPreview/InstallPreviewQuestion";
import { CustomQuizQuestionModel } from "../../../../generated/from-api/models/custom-quiz/custom-quiz-question.model";

interface QuizPreviewProps {
  quiz: CustomQuizModel;
}

const QuizPreview: React.FC<QuizPreviewProps> = ({ quiz }) => {
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<
    CustomQuizQuestionModel | undefined
  >(quiz.questions[0]);
  const changeQuestionIndex = (change: number) => {
    const newQuestionIndex = questionIndex + change;
    if (newQuestionIndex < 0 || newQuestionIndex > quiz.questions.length - 1) {
      return;
    }
    setQuestionIndex(newQuestionIndex);
  };
  useEffect(() => {
    const questionsLength = quiz.questions.length;
    if (questionIndex < questionsLength) {
      setCurrentQuestion(quiz.questions[questionIndex]);
      return;
    }
    if (questionsLength === 0) setCurrentQuestion(undefined);
    const newIndex = Math.max(questionsLength - 1, 0);
    if (newIndex !== questionIndex) setQuestionIndex(newIndex);
  }, [questionIndex, quiz]);

  return (
    <div className={styles.quizPreview}>
      {!!currentQuestion ? (
        <>
          <InstallPreviewQuestion
            question={currentQuestion}
            isMultipleChoice={!quiz.autoGenerateQuestions}
          />
          <div className={styles.previewPhoneFooter}>
            <div
              className={`${styles.Previous} ${
                questionIndex !== 0 ? "Active" : ""
              }`}
              onClick={() => {
                changeQuestionIndex(-1);
              }}
            >
              Prev
            </div>
            <div>
              {questionIndex + 1} of {Math.max(1, quiz.questions.length)}
            </div>
            <div
              className={`${styles.Next} ${
                questionIndex < quiz.questions.length - 1 ? "Active" : ""
              }`}
              onClick={() => {
                changeQuestionIndex(1);
              }}
            >
              Next
            </div>
          </div>
        </>
      ) : (
        <div className={styles.quizPreviewEmptyState}>
          Flashcards will appear here
        </div>
      )}
    </div>
  );
};

export default QuizPreview;
