import styles from "./ToggleSelect.module.scss";

interface Props {
  options: string[];
  selectedOption: string;
  onChange: (option: string) => void;
  background?: string;
  borderColor?: string;
}

const ToggleSelect: React.FC<Props> = ({
  options,
  selectedOption,
  onChange,
  background,
  borderColor,
}) => {
  return (
    <div
      className={styles.toggleSelect}
      style={{
        background: `${background}`,
        border: `1px solid ${borderColor}`,
      }}
    >
      {options.map((option) => (
        <div
          key={option}
          className={`${styles.option} ${
            selectedOption === option ? styles.selected : ""
          } ${
            options.length > 1
              ? selectedOption === options[0]
                ? styles.isLeft
                : selectedOption === options[options.length - 1]
                ? styles.isRight
                : ""
              : ""
          }`}
          onClick={() => {
            onChange(option);
          }}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default ToggleSelect;
