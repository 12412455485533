import * as React from "react";

const TrialIcon: React.FC = () => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 13.5C0 6.324 5.824 0.5 13 0.5C20.176 0.5 26 6.324 26 13.5C26 20.676 20.176 26.5 13 26.5C5.824 26.5 0 20.676 0 13.5ZM21.1059 11.5118H5.47628L4.58823 15.1824H20.2178L21.1059 11.5118Z"
      fill="#23AAC1"
    />
  </svg>
);

export default TrialIcon;
