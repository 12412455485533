import * as React from "react";

const CircleNotAllowedIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 8.15 2.63 6.45 3.69 5.1L14.9 16.31C13.55 17.37 11.85 18 10 18ZM5.1 3.69L16.31 14.9C17.37 13.55 18 11.85 18 10C18 5.58 14.42 2 10 2C8.15 2 6.45 2.63 5.1 3.69Z"
    />
  </svg>
);

export default CircleNotAllowedIcon;
