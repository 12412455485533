import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { FormationModel } from "../../../generated/from-api/models/drawable/formation.model";
import CloseIcon from "../../../resources/images/blackX.svg";
import styles from "./FormationSettingsToolbar.module.scss";
import CharacterInput from "../../Input/CharacterInput";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import DeleteFormationModal from "./DeleteFormationModal";
import Button from "../../Button/Button";

interface Props {
  formation: Omit<FormationModel, "drawablePlayers">;
  setFormation: React.Dispatch<
    React.SetStateAction<Omit<FormationModel, "drawablePlayers">>
  >;
}

const FormationSettingsToolbar: React.FC<Props> = ({
  formation,
  setFormation,
}) => {
  const history = useHistory();
  const { dispatchModal } = useContext(UIContext);

  const goBack = () => {
    history.push("/playbook-settings", { type: formation.type });
  };

  const openDeleteFormationModal = () => {
    dispatchModal({
      title: "Delete Formation",
      open: true,
      body: <DeleteFormationModal formationId={formation.id} />,
    });
  };

  return (
    <div className={styles.formationSettingsToolbar}>
      <div className={styles.leftControls}>
        <img src={CloseIcon} alt="close icon" onClick={goBack} role="button" />
        <CharacterInput
          value={formation.name}
          maxLength={100}
          size="x-small"
          onChange={(e) => {
            setFormation({
              ...formation,
              name: e.target.value,
            });
          }}
        />
      </div>
      <div className={styles.rightControls}>
        <Button
          theme="tertiary"
          size={"small"}
          onClick={openDeleteFormationModal}
          destructive
        >
          Delete Formation
        </Button>
        <Button size={"small"} onClick={goBack}>
          Done
        </Button>
      </div>
    </div>
  );
};

export default FormationSettingsToolbar;
