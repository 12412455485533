import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { Preferences } from "../../../../generated/from-api/models/preferences.model";
import { UserProfileModel } from "../../../../generated/from-api/models/user-profile.model";
import { API_BASE_URL } from "../../constants";

export interface PreferencesBody {
  teamId: string;
}

export interface UpdatePreferencesBody {
  preferences: Preferences;
  teamId: string;
}

// Updated: 5/14/21
export const UserProfileService = {
  /**
   * Use to retrieve a user profile by userId
   * @param id
   */
  GET: async function (userId: string): Promise<UserProfileModel | undefined> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.USER_PROFILE}/${userId}`)
    ).data;
  },

  // POST - intentionally not used, user profiles are generated by the system automatically

  /**
   * Use to upload a user profile image, it will be automatically added to your user profile.
   * @param data
   */
  POST_UPLOAD_PROFILE_IMAGE: async function (
    data: FormData
  ): Promise<{ url: string }> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.USER_PROFILE}/upload-picture`,
        data
      )
    ).data;
  },

  /**
   * Use to update your user profile, user profiles are not editable by others
   * @param profile
   */
  PUT: async function (
    profileId: string,
    profileDataToUpdate: Pick<
      UserProfileModel,
      | "firstName"
      | "lastName"
      | "weightInLBS"
      | "heightInInches"
      | "schoolYear"
      | "nickname"
      | "weightInLBS"
      | "heightInInches"
      | "marketingEmail"
      | "preferences"
    >
  ): Promise<UserProfileModel | undefined> {
    return (
      await axios.put(
        `${API_BASE_URL}${APIRoutes.USER_PROFILE}/${profileId}`,
        profileDataToUpdate
      )
    ).data;
  },
};
