import {
  Arc,
  Circle,
  Rect,
  Text,
  Line,
  RegularPolygon,
  Group,
} from "react-konva";
import { KonvaEventObject } from "konva/types/Node";

import { colors } from "./play-editor-colors";
import { convertRelativeLineToAbsolute } from "./playEditor.utils";
import LineSegments from "./LineSegments";
import { ShapeModel, KonvaDragHandler } from "./drawing.types";
import { useState } from "react";
interface Props {
  shape: ShapeModel;
  stageWidth: number;
  stageHeight: number;
  draggable: boolean;
  isDragging: boolean;
  isSelected: boolean;
  onDragStart?: (e: KonvaEventObject<DragEvent>) => void;
  onDragMove?: (e: KonvaEventObject<DragEvent>) => void;
  onDragEnd?: (e: KonvaEventObject<DragEvent>) => void;
  dragBoundFunc?: KonvaDragHandler;
  printableView?: boolean;
  isPlayerChanging?: boolean;
  isPreviewingRoute?: boolean;
  cannotDelete?: boolean;
}

const Shape: React.FC<Props> = ({
  shape,
  stageWidth,
  stageHeight,
  draggable,
  isDragging,
  isSelected,
  printableView = false,
  isPlayerChanging = false,
  isPreviewingRoute = false,
  cannotDelete = false,
  ...rest
}) => {
  const size = 6 + stageWidth / 40; // ~ 30. diameter of a circle, side length of a square
  const text = shape.text || shape.secondaryText;
  const fontSize = Math.round(size / 2); // ~ 15
  const shapeStrokeWidth = Math.round(stageWidth / 480) + 1; // ~ 3
  const selectionBorderStrokeWidth = Math.round(stageWidth / 480); // ~ 2
  const selectionBorderGap = Math.round(stageWidth / 320); // ~ 3. num pixels between edge of shape and selection indicator border
  const routeStrokeWidth = Math.min(Math.ceil(stageWidth / 480), 1.5);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  let crossHairHorizontal: number[] = [];
  let crossHairVertical: number[] = [];
  if (isDragging) {
    crossHairHorizontal = [0, shape.y, 1, shape.y];
    crossHairVertical = [shape.x, 0, shape.x, 1];
  }

  const onMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    setIsHovered(true);
    const container = e.target.getStage()?.container();
    if (container?.style.cursor !== undefined) {
      container.style.cursor = "pointer";
    }
  };

  const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    setIsHovered(false);
    const container = e.target.getStage()?.container();
    if (container?.style.cursor !== undefined) {
      container.style.cursor = "default";
    }
  };

  const patternArcMap = {
    empty: {
      rotation: 0,
      angle: 0,
    },
    filled: {
      rotation: 0,
      angle: 360,
    },
    semiBottom: {
      rotation: 0,
      angle: 180,
    },
    semiTop: {
      rotation: 180,
      angle: 180,
    },
    semiLeft: {
      rotation: 90,
      angle: 180,
    },
    semiRight: {
      rotation: 270,
      angle: 180,
    },
  };

  const patternRectMap = {
    empty: {
      rotation: 0,
      width: 0,
    },
    filled: {
      rotation: 0,
      width: size,
    },
    semiTop: {
      rotation: 90,
      width: size / 2,
    },
    semiBottom: {
      rotation: 270,
      width: size / 2,
    },
    semiLeft: {
      rotation: 0,
      width: size / 2,
    },
    semiRight: {
      rotation: 180,
      width: size / 2,
    },
  };

  return (
    <>
      {isDragging && (
        <>
          <Line
            stroke={shape.color}
            strokeWidth={0.5}
            points={convertRelativeLineToAbsolute(
              crossHairHorizontal,
              stageWidth,
              stageHeight
            )}
          />
          <Line
            stroke={shape.color}
            strokeWidth={0.5}
            points={convertRelativeLineToAbsolute(
              crossHairVertical,
              stageWidth,
              stageHeight
            )}
          />
        </>
      )}
      {shape.route && (
        <LineSegments
          shouldHideLineCap={!!(shape.ellipseRadiusX && shape.ellipseRadiusY)}
          lineData={shape.route}
          stageWidth={stageWidth}
          stageHeight={stageHeight}
          stroke={shape.color}
          strokeWidth={routeStrokeWidth}
          isPreviewingRoute={isPreviewingRoute}
        />
      )}

      {shape.type === "football" && (
        <>
          <Circle
            id={shape.id}
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            radius={size / 2}
            fill={"transparent"}
            draggable={draggable}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...rest}
          />
          <Arc
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            innerRadius={0}
            outerRadius={size / 2}
            angle={180}
            scaleY={0.65}
            fill={colors.footballBrown}
            stroke={shape.color}
            strokeWidth={shapeStrokeWidth}
            rotation={135}
            listening={false}
          />
          <Arc
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            innerRadius={0}
            outerRadius={size / 2}
            angle={180}
            scaleY={0.65}
            fill={colors.footballBrown}
            stroke={shape.color}
            strokeWidth={shapeStrokeWidth}
            rotation={-45}
            listening={false}
          />
        </>
      )}

      {isSelected && shape.type === "football" && (
        <Circle
          id="selected-circle-border"
          x={shape.x * stageWidth}
          y={shape.y * stageHeight}
          radius={size / 2 + selectionBorderStrokeWidth + selectionBorderGap}
          scaleY={0.65}
          rotationDeg={135}
          stroke={colors.lightning}
          strokeWidth={selectionBorderStrokeWidth}
          listening={false}
        />
      )}

      {shape.type === "circle" && (
        <>
          <Circle
            id={shape.id}
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            radius={size / 2}
            fill={printableView ? colors.white : colors.fieldBackgroundGreen}
            stroke={shape.color}
            strokeWidth={shapeStrokeWidth}
            draggable={draggable}
            {...rest}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
          <Arc
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            innerRadius={0}
            outerRadius={size / 2}
            angle={patternArcMap[shape.fillPattern].angle}
            fill={shape.color}
            rotation={patternArcMap[shape.fillPattern].rotation}
            listening={false}
          />
        </>
      )}

      {isSelected && shape.type === "circle" && (
        <Circle
          id="selected-circle-border"
          x={shape.x * stageWidth}
          y={shape.y * stageHeight}
          radius={size / 2 + selectionBorderStrokeWidth + selectionBorderGap}
          stroke={colors.lightning}
          strokeWidth={selectionBorderStrokeWidth}
          listening={false}
        />
      )}

      {shape.type === "square" && (
        <>
          <Rect
            id={shape.id}
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            width={size}
            height={size}
            offsetX={size / 2}
            offsetY={size / 2}
            fill={printableView ? colors.white : colors.fieldBackgroundGreen}
            stroke={shape.color}
            strokeWidth={shapeStrokeWidth - 0.5}
            draggable={draggable}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...rest}
          />
          <Rect
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            width={patternRectMap[shape.fillPattern].width}
            rotation={patternRectMap[shape.fillPattern].rotation}
            height={size}
            offsetX={size / 2}
            offsetY={size / 2}
            fill={shape.color}
            listening={false}
          />
        </>
      )}

      {isSelected && shape.type === "square" && (
        <Rect
          id="selected-square-border"
          x={shape.x * stageWidth}
          y={shape.y * stageHeight}
          width={size + selectionBorderStrokeWidth * 2 + selectionBorderGap}
          height={size + selectionBorderStrokeWidth * 2 + selectionBorderGap}
          offsetX={
            (size + selectionBorderStrokeWidth * 2 + selectionBorderGap) / 2
          }
          offsetY={
            (size + selectionBorderStrokeWidth * 2 + selectionBorderGap) / 2
          }
          stroke={colors.lightning}
          strokeWidth={selectionBorderStrokeWidth}
          listening={false}
        />
      )}

      {shape.type === "triangle" && (
        <RegularPolygon
          id={shape.id}
          x={shape.x * stageWidth}
          y={shape.y * stageHeight}
          sides={3}
          radius={(size / 2) * 1.35}
          fill={
            shape.fillPattern === "filled"
              ? shape.color
              : colors.fieldBackgroundGreen
          }
          stroke={shape.color}
          strokeWidth={shapeStrokeWidth - 0.75}
          draggable={draggable}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          rotation={shape.verticalFlip ? 180 : 0}
          {...rest}
        />
      )}

      {isSelected && shape.type === "triangle" && (
        <RegularPolygon
          id="selected-triangle-border"
          x={shape.x * stageWidth}
          y={shape.y * stageHeight}
          sides={3}
          radius={
            ((size + selectionBorderStrokeWidth) / 2 + selectionBorderGap) * 1.4
          }
          stroke={colors.lightning}
          strokeWidth={selectionBorderStrokeWidth}
          rotation={shape.verticalFlip ? 180 : 0}
          listening={false}
        />
      )}

      {text && (
        <Text
          strokeWidth={0.5}
          fontFamily="Quantico"
          fontSize={fontSize}
          text={text}
          stroke={printableView ? "black" : colors.white}
          fill={printableView ? "black" : colors.white}
          x={shape.x * stageWidth}
          y={shape.y * stageHeight}
          listening={false}
          width={size}
          height={size}
          align="center"
          verticalAlign="middle"
          offsetX={size / 2}
          offsetY={size / 2 - 1}
        />
      )}

      {isPlayerChanging && isHovered && !cannotDelete && (
        <Group listening={false}>
          <Circle
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            radius={size / 2 - 3}
            fill={colors.white}
            stroke={colors.red}
          />
          <Text
            strokeWidth={0.5}
            fontFamily="Quantico"
            fontSize={fontSize}
            text="X"
            stroke={colors.red}
            fill={colors.red}
            x={shape.x * stageWidth}
            y={shape.y * stageHeight}
            width={size}
            height={size}
            align="center"
            verticalAlign="middle"
            offsetX={size / 2}
            offsetY={size / 2 - 1}
          />
        </Group>
      )}
    </>
  );
};

export default Shape;
