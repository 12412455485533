import * as React from "react";

const ToolbarAlignCenterIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 2.16667V0.5H15.5V2.16667H0.5ZM3.83333 3.83333V5.5H12.1667V3.83333H3.83333ZM15.5 8.83333H0.5V7.16667H15.5V8.83333ZM3.83333 10.5V12.1667H12.1667V10.5H3.83333ZM0.5 15.5H15.5V13.8333H0.5V15.5Z"
    />
  </svg>
);

export default ToolbarAlignCenterIcon;
