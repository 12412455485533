import React, { useContext, useEffect, useState } from "react";
import styles from "./PlaysGallery.module.scss";
import CloseIcon from "../../resources/icons/CloseIcon";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import search from "../../resources/images/search.svg";
import CharacterInput from "../Input/CharacterInput";
import SingleSelect from "../SingleSelect/SingleSelect";
import { PlaybookContext } from "../../shared/shared-with-mobile/providers/playbook.provider";
import PlayThumbnail from "../drawing/PlayThumbnail";
import { VirtuosoGrid } from "react-virtuoso";
import { PlayModel } from "../../generated/from-api/models/play.model";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import Button from "../Button/Button";
import PlaybookTags from "../../pages/Playbook/PlaybookTags";

interface Props {
  addPlayCallback: (play: PlayModel) => void;
  options?: { draftPlays: boolean };
}

type SortOptionsType = "recent" | "oldest" | "a-z" | "z-a";
const sortOptions: { label: string; value: SortOptionsType }[] = [
  { label: "Most Recent", value: "recent" },
  { label: "Most Oldest", value: "oldest" },
  { label: "Title A-Z", value: "a-z" },
  { label: "Title Z-A", value: "z-a" },
];

const PlaysGallery: React.FC<Props> = ({ addPlayCallback, options }) => {
  const { currentPlaybook } = useContext(PlaybookContext);
  const { closeModal } = useContext(UIContext);

  const allPlays = currentPlaybook ? [...currentPlaybook] : [];

  const [filteredPlays, setFilteredPlays] = useState<PlayModel[] | undefined>(
    options?.draftPlays ? allPlays : allPlays.filter((play) => play.published)
  );

  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(-1);

  const [currentSort, setCurrentSort] = useState<SortOptionsType>("recent");

  useEffect(() => {
    sortPlays();
  }, [filteredPlays, currentSort]);

  const handleSearchFilter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!allPlays) return;

    let filteredPlaysBySearch = [...allPlays];
    const searchValue = event.currentTarget.value;

    filteredPlaysBySearch = filteredPlaysBySearch.filter(
      (play: PlayModel) =>
        play.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    );
    setFilteredPlays(filteredPlaysBySearch);
  };

  const handlePlaysSort = (event: any) => {
    setCurrentSort(event.currentTarget.value);
  };

  const sortPlays = () => {
    if (!filteredPlays) return;

    switch (currentSort) {
      case "recent":
        filteredPlays.sort((playA: PlayModel, playB: PlayModel): number => {
          return playA.created && playB.created
            ? Date.parse(playB.created) - Date.parse(playA.created)
            : 0;
        });
        break;
      case "oldest":
        filteredPlays.sort((playA: PlayModel, playB: PlayModel): number => {
          return playA.created && playB.created
            ? Date.parse(playA.created) - Date.parse(playB.created)
            : 0;
        });
        break;
      case "a-z":
        filteredPlays.sort((playA: PlayModel, playB: PlayModel): number => {
          return playA.name
            .toLowerCase()
            .localeCompare(playB.name.toLowerCase());
        });
        break;
      case "z-a":
        filteredPlays.sort((playA: PlayModel, playB: PlayModel): number => {
          return playB.name
            .toLowerCase()
            .localeCompare(playA.name.toLowerCase());
        });
        break;
    }

    setFilteredPlays(filteredPlays);
  };

  return (
    <div className={styles.playsGallery}>
      <div className={styles.playsGalleryHeader}>
        <div className={styles.playsGalleryTitleWrapper}>
          <h4 className={styles.playsGalleryTitle}>Add Play Card</h4>
        </div>
        <div className={styles.playsGalleryCloseButtonWrapper}>
          <button
            type={"button"}
            className={styles.playsGalleryCloseButton}
            onClick={() => closeModal()}
          >
            <CloseIcon />
          </button>
        </div>
      </div>

      <div className={styles.playsGalleryBody}>
        <div className={styles.playsGalleryFilters}>
          <div className={styles.playsGallerySearchWrapper}>
            <CharacterInput
              type={INPUT_TYPES.TEXT}
              placeholder="Search"
              icon={search}
              clearButton
              onChange={handleSearchFilter}
            />
          </div>
          <div className={styles.playsGallerySortWrapper}>
            <SingleSelect
              options={sortOptions}
              value={currentSort}
              placeholder="Sort by: Most Recent"
              onChange={handlePlaysSort}
            />
          </div>
        </div>
        <div className={styles.playsGalleryPlaybookWrapper}>
          {filteredPlays && filteredPlays.length > 0 && (
            <VirtuosoGrid
              totalCount={filteredPlays.length}
              itemClassName={styles.playsGalleryPlayCardItem}
              itemContent={(index: number) => {
                const play = filteredPlays[index];
                return (
                  <div
                    key={play.id}
                    className={`${styles.playsGalleryPlayCard} ${
                      selectedCardIndex === index &&
                      styles.playsGalleryPlayCardSelected
                    }`}
                    onClick={() => setSelectedCardIndex(index)}
                  >
                    <div className={styles.playsGalleryPlayCardThumbnail}>
                      <PlayThumbnail play={play} />
                    </div>
                    <div className={styles.playsGalleryPlayCardTitle}>
                      {!play.published ? `[Draft] ${play.name}` : play.name}
                    </div>
                    <div className={styles.playsGalleryPlayCardTags}>
                      <PlaybookTags play={play} noLink={true} />
                    </div>
                  </div>
                );
              }}
            />
          )}
        </div>
      </div>

      <div className={styles.playsGalleryFooter}>
        <div className={styles.playsGalleryAddPlayButtonWrapper}>
          <Button
            size={"small"}
            onClick={() => {
              if (filteredPlays) {
                addPlayCallback(filteredPlays[selectedCardIndex]);
              }
            }}
            disabled={selectedCardIndex < 0}
          >
            Add Play Card
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PlaysGallery;
