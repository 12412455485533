import { useContext } from "react";

import styles from "./DeletePlayFromPlaySetModal.module.scss";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { PlaySetModel } from "../../../generated/from-api/models/play-set.model";
import { PlaySetsContext } from "../../../shared/shared-with-mobile/providers/playSets.provider";
import Button from "../../../components/Button/Button";
import { PlaybookContext } from "../../../shared/shared-with-mobile/providers/playbook.provider";

interface Props {
  playIds: string[];
  playSet: PlaySetModel;
  clearSelectedPlays: () => void;
  clearSelectedPlaySets?: () => void;
}

const DeletePlayFromPlaySetModal: React.FC<Props> = ({
  playIds,
  playSet,
  clearSelectedPlays,
  clearSelectedPlaySets,
}) => {
  const { closeModal, dispatchToast, closeModals } = useContext(UIContext);
  const { updatePlaySet, removePlaySet } = useContext(PlaySetsContext);
  const { setCurrentPlaybook, setGlobalPlaybook } = useContext(PlaybookContext);
  const doMultiplePlayIdsExist = playIds.length > 1;
  const deletePlays = async () => {
    if (playSet) {
      const updatedPlayOrder = playSet.playOrder.filter(
        (n) => !playIds.includes(n.playId)
      );

      if (updatedPlayOrder.length > 0) {
        try {
          const response = await APIService.PLAY_SET.PUT({
            id: playSet.id as string,
            name: playSet.name,
            teamId: playSet.teamId,
            playOrder: updatedPlayOrder,
          });
          if (response) {
            const updatedPlaySet = await APIService.PLAY_SET.GET(
              playSet.id as string
            );
            if (playSet.teamId) {
              const plays = await APIService.PLAY.LIST(playSet.teamId);
              if (plays) {
                setCurrentPlaybook(plays);
              }
              const globalPlays = await APIService.PLAY.LIST();
              if (globalPlays) {
                setGlobalPlaybook(globalPlays);
              }
            }
            if (updatedPlaySet) {
              updatePlaySet(updatedPlaySet);
              clearSelectedPlays();
            }

            dispatchToast({
              type: "success",
              message: `${playIds.length} play${
                playIds.length === 1 ? "" : "s"
              } remove from play set.`,
            });

            closeModal();
          }
        } catch (e) {
          closeModal();
          dispatchToast({
            type: "error",
            message: "There's been an error. Please try again.",
          });
        }
      } else {
        onDeletePlayset(playSet);
      }
    }
  };

  const onDeletePlayset = async (playSet: PlaySetModel) => {
    if (playSet.id) {
      try {
        const response = await APIService.PLAY_SET.DELETE(playSet.id as string);
        if (response) {
          if (playSet.teamId) {
            const plays = await APIService.PLAY.LIST(playSet.teamId);
            if (plays) {
              setCurrentPlaybook(plays);
            }
            const globalPlays = await APIService.PLAY.LIST();
            if (globalPlays) {
              setGlobalPlaybook(globalPlays);
            }
            clearSelectedPlaySets && clearSelectedPlaySets();
            closeModals();
          }

          dispatchToast({
            type: "success",
            message: `Playset Deleted.`,
          });

          if (clearSelectedPlays) {
            clearSelectedPlays();
          }

          setTimeout(
            () => playSet && playSet.id && removePlaySet(playSet.id),
            500
          );
        }
      } catch (e) {
        dispatchToast({
          type: "error",
          message: "There's been an error. Please try again.",
        });
      }
    }
  };

  return (
    <div className={styles.deletePlayModal}>
      <p>
        Are you sure you want to remove the{" "}
        {doMultiplePlayIdsExist ? "these" : "this"} play
        {doMultiplePlayIdsExist && "s"} from the playset? The play won’t be
        deleted, it will be placed back in the playbook.
      </p>
      <div className={styles.buttonRow}>
        <Button
          theme={"tertiary"}
          size={"small"}
          onClick={deletePlays}
          destructive
        >
          Remove Play{doMultiplePlayIdsExist && "s"}
        </Button>
        <Button size={"small"} onClick={closeModal}>
          Keep Play{doMultiplePlayIdsExist && "s"}
        </Button>
      </div>
    </div>
  );
};

export default DeletePlayFromPlaySetModal;
