import React from "react";
import { EditorState } from "draft-js";
import wysiwygStyles from "../Wysiwyg.module.scss";
import ToolbarUnorderedListIcon from "../../../resources/icons/ToolbarUnorderedListIcon";
import ToolbarOrderedListIcon from "../../../resources/icons/ToolbarOrderedListIcon";

interface Props {
  editorState: EditorState;
  mouseDownHandler: (
    buttonType: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

const ListStyleControls: React.FC<Props> = ({
  editorState,
  mouseDownHandler,
}) => {
  const buttonSelection = editorState.getSelection();
  const buttonType = editorState
    .getCurrentContent()
    .getBlockForKey(buttonSelection.getStartKey())
    .getType();

  return (
    <div>
      <button
        className={`${wysiwygStyles.wysiwygButton} ${
          buttonType === "unordered-list-item" &&
          wysiwygStyles.wysiwygButtonActive
        }`}
        type={"button"}
        onMouseDown={(event) => mouseDownHandler("unordered-list-item", event)}
      >
        <ToolbarUnorderedListIcon />
      </button>
      <button
        className={`${wysiwygStyles.wysiwygButton} ${
          buttonType === "ordered-list-item" &&
          wysiwygStyles.wysiwygButtonActive
        }`}
        type={"button"}
        onMouseDown={(event) => mouseDownHandler("ordered-list-item", event)}
      >
        <ToolbarOrderedListIcon />
      </button>
    </div>
  );
};

export default ListStyleControls;
