import { Fragment, useContext, useEffect, useState } from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import ReactAudioPlayer from "react-audio-player";

import styles from "./MediaGallery.module.scss";
import CharacterInput from "../Input/CharacterInput";
import DatePickerInput from "../DatePickerInput/DatePickerInput";
import { ReactElement } from "react";
import {
  MediaModel,
  MediaTagListModel,
} from "../../generated/from-api/models/media.model";
import { TN_MEDIA_TYPE } from "../../generated/from-api/models/media.model";
import { generateDateString } from "../../utils/functions";
import { MediaContext } from "../../shared/shared-with-mobile/providers/media.provider";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import Button from "../Button/Button";
import PencilIcon from "../../resources/icons/PencilIcon";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { TagModel } from "../../generated/from-api/models/tag.model";
import CloseIcon from "../../resources/images/close.svg";
import { TagFoldersContext } from "../../shared/shared-with-mobile/providers/tagFolders.provider";
import MediaGalleryTags from "./MediaGalleryTags/MediaGalleryTags";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { MediaTagContext } from "../../shared/shared-with-mobile/providers/mediaTags.provider";
import difference from "lodash/difference";
import uniqBy from "lodash/uniqBy";

interface MediaPreviewSideBar {
  mediaItem: MediaModel;
  deleteSelectedMedia: (id?: string) => void;
  showDeleteConfirmation: boolean;
  setShowDeleteConfirmation: (newVal: boolean) => void;
  setIsImageEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedImage: React.Dispatch<React.SetStateAction<MediaModel>>;
  hidePreview?: boolean;
  closeEditImageModal?: () => void;
}
interface LeftSideBar {
  setFilters: React.Dispatch<React.SetStateAction<TN_MEDIA_TYPE[]>>;
}
interface LeftSideBar {
  setFilters: React.Dispatch<React.SetStateAction<TN_MEDIA_TYPE[]>>;
}

export const EmptySideBar = (): ReactElement => {
  return (
    <div className={styles.sidebarBtnContainer}>
      <div className={styles.emptySidebarText}>
        Select media to preview, edit, delete, or add it to a flashcard term.
      </div>
    </div>
  );
};

interface LeftSideBar {
  setFilters: React.Dispatch<React.SetStateAction<TN_MEDIA_TYPE[]>>;
  setSelectedTagFolder: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTagFolder: string | null;
  setSelectedTag: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTag: string | null;
  defaultFilters?: Array<TN_MEDIA_TYPE>;
}

export const LeftSideBar: React.FC<LeftSideBar> = ({
  setFilters,
  selectedTagFolder,
  setSelectedTagFolder,
  selectedTag,
  setSelectedTag,
  defaultFilters = ["IMAGE", "VIDEO", "AUDIO"],
}) => {
  const mediaTypes = ["All", ...defaultFilters];

  const { mediaItems } = useContext(MediaContext);
  const [mediaTypeFilters, setMediaTypeFilters] = useState<string | null>(null);
  const [mediaTypeCounts, setMediaTypeCounts] = useState<
    Record<string, number>
  >({});
  const { dispatchModal } = useContext(UIContext);
  const { tagFolders } = useContext(TagFoldersContext);

  useEffect(() => {
    const resultByDefaultFilters = mediaItems.filter((item) =>
      defaultFilters.includes(item.type)
    );

    const [nextMediaCounts] = getMediaTypeCounts(
      mediaTypes,
      resultByDefaultFilters || []
    );
    setMediaTypeCounts(nextMediaCounts);
  }, [mediaItems]);

  const selectMediaType = (type: string) => {
    const nextMediaTypeFilter = type === "All" ? null : (type as TN_MEDIA_TYPE);
    nextMediaTypeFilter
      ? setFilters([nextMediaTypeFilter as TN_MEDIA_TYPE])
      : setFilters([]);
    setMediaTypeFilters(nextMediaTypeFilter);
  };

  const getMediaTypeCounts = (
    mediaTypes: string[],
    mediaItems: MediaModel[]
  ): Record<string, number>[] => {
    const mediaTypeCounts: Record<string, number> = {};

    for (const mediaType of mediaTypes) {
      if (mediaType === "All") {
        mediaTypeCounts[mediaType] = mediaItems.length;
      } else {
        mediaTypeCounts[mediaType] = mediaItems.reduce(
          (total, media) => (media.type == mediaType ? total + 1 : total),
          0
        );
      }
    }

    return [mediaTypeCounts];
  };

  const openTagsForMediaGalleryModal = () => {
    dispatchModal({
      open: true,
      className: styles.mediaGalleryTagsSetupModal,
      size: "medium",
      body: <MediaGalleryTags />,
    });
  };

  const getSidebarLabel = (mediaType: string) => {
    // both of these labels are singular OR plural (no need to change them)
    if (mediaType === "All" || mediaType === "AUDIO") {
      return mediaType;
    }
    // if plural, Image -> Images, Video -> Videos
    if (mediaTypeCounts[mediaType] <= 1) {
      return mediaType;
    } else {
      return mediaType + "s";
    }
  };

  return (
    <div className={styles.leftSidebarBtnContainer}>
      <div className={styles.leftSidebarText}>
        <div className={styles.headerBar}>
          <div className={styles.headerBarTitle}>Tags</div>
          <Button
            theme={"transparent"}
            size={"small"}
            type={"button"}
            onClick={() => openTagsForMediaGalleryModal()}
            icon={<PencilIcon />}
          />
        </div>

        {tagFolders.map((tagFolder: any) => (
          <TagFolderItem
            key={tagFolder.id}
            tagFolder={tagFolder}
            setSelectedTagFolder={setSelectedTagFolder}
            selectedTagFolder={selectedTagFolder}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            defaultFilters={defaultFilters}
          />
        ))}

        <div className={styles.headerBar}>
          <div className={styles.headerBarTitle}>Media Type</div>
        </div>

        {mediaTypes.map((mediaType: string) => (
          <Fragment key={mediaType}>
            <div
              className={`
                  ${styles.mediaTypeFilter}
                  ${
                    mediaType === "All" &&
                    mediaTypeFilters === null &&
                    styles.selected
                  }
                  ${mediaTypeFilters === mediaType && styles.selected}
                `}
              onClick={() => {
                selectMediaType(mediaType);
              }}
            >
              <div className={styles.mediaType}>
                {getSidebarLabel(mediaType)}
              </div>
              <div>{mediaTypeCounts[mediaType] || ""}</div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

interface TagFolderItem {
  tagFolder: any;
  setSelectedTagFolder: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTagFolder: string | null;
  setSelectedTag: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTag: string | null;
  defaultFilters?: Array<TN_MEDIA_TYPE>;
}

export const TagFolderItem: React.FC<TagFolderItem> = ({
  tagFolder,
  selectedTagFolder,
  setSelectedTagFolder,
  selectedTag,
  setSelectedTag,
  defaultFilters = ["IMAGE", "VIDEO", "AUDIO"],
}) => {
  const selectTagFolder = (tagFolder: any) => {
    if (tagFolder.id === selectedTagFolder) {
      setSelectedTagFolder(null);
      setSelectedTag(null);
    } else {
      setSelectedTagFolder(tagFolder.id);
      setSelectedTag(null);
    }
  };

  return (
    <Fragment key={tagFolder.id}>
      <div
        className={`
                  ${styles.tagFolderContainer}
                  
                  ${selectedTagFolder === tagFolder.id ? styles.selected : ""}
                `}
        onClick={() => {
          selectTagFolder(tagFolder);
        }}
      >
        <div className={styles.title}>
          <span
            className={`${styles.arrow} ${
              selectedTagFolder === tagFolder.id
                ? styles.arrowBottom
                : styles.arrowRight
            }`}
          />
          {tagFolder.name}
        </div>
        <div>{tagFolder.tagOrder ? tagFolder.tagOrder.length : 0}</div>
      </div>

      <>
        <div
          className={` ${
            selectedTagFolder && selectedTagFolder === tagFolder.id
              ? styles.expand
              : styles.collapse
          }`}
        >
          {tagFolder &&
            tagFolder.tagOrder &&
            tagFolder.tagOrder.map((tag: TagModel) => (
              <TagItem
                key={tag.id}
                tag={tag}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                defaultFilters={defaultFilters}
              />
            ))}
        </div>
      </>
    </Fragment>
  );
};

interface TagItem {
  tag: TagModel;
  setSelectedTag: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTag: string | null;
  defaultFilters?: Array<TN_MEDIA_TYPE>;
}

export const TagItem: React.FC<TagItem> = ({
  tag,
  selectedTag,
  setSelectedTag,
  defaultFilters = ["IMAGE", "VIDEO", "AUDIO"],
}) => {
  const { mediaItems } = useContext(MediaContext);
  const { mediaItemTags } = useContext(MediaTagContext);
  const selectTag = (selectedTagId: any) => {
    setSelectedTag(selectedTagId);
    if (selectedTag == selectedTagId) {
      setSelectedTag(null);
    } else {
      setSelectedTag(selectedTagId);
    }
  };

  const filteredMediaItems = mediaItems.filter((e) =>
    defaultFilters.includes(e.type)
  );

  const tagsAssociatedToMedia = mediaItemTags.filter(
    (media) => media.tags.filter((e) => e.id === tag.id).length
  );

  const tagsAssociatedToMediaCountWithDefaultFilters = tagsAssociatedToMedia.filter(
    (e) => filteredMediaItems.some((media) => media.id === e.mediaId)
  ).length;

  return (
    <Fragment key={tag.id}>
      <div
        className={`
                  ${styles.tagContainer}
                  
                  ${selectedTag == tag.id ? styles.selected : ""}
                `}
        onClick={() => {
          selectTag(tag.id);
        }}
      >
        <div className={styles.title}>{tag.name}</div>
        <div>{tagsAssociatedToMediaCountWithDefaultFilters}</div>
      </div>
    </Fragment>
  );
};

export const MediaPreviewSideBar: React.FC<MediaPreviewSideBar> = ({
  mediaItem,
  deleteSelectedMedia,
  showDeleteConfirmation,
  setShowDeleteConfirmation,
  setIsImageEditing,
  setSelectedImage,
  hidePreview = false,
  closeEditImageModal,
}) => {
  const { updateMediaItem } = useContext(MediaContext);
  const [audioUrl, setAudioUrl] = useState("");
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const editImage = (image: MediaModel) => {
    setIsImageEditing(true);
    setSelectedImage(image);
    closeEditImageModal && closeEditImageModal();
  };

  useEffect(() => {
    return () => {
      if (!!audioUrl) URL.revokeObjectURL(audioUrl);
    };
  }, []);

  useEffect(() => {
    const fetchAudio = async (media: MediaModel) => {
      if (!media) return;
      try {
        const response = await fetch(media.url || "");
        if (!response.ok) {
          console.error("Error retrieving audio file", response.statusText);
          return;
        }
        const blob = await response.blob();
        setAudioUrl(URL.createObjectURL(blob));
      } catch (e) {
        console.error(e);
      }
    };

    if (!!audioUrl) URL.revokeObjectURL(audioUrl);
    if (mediaItem.type !== "AUDIO") return;
    fetchAudio(mediaItem);
  }, [mediaItem]);

  const updateName = (newName: string) => {
    const updated = { ...mediaItem };
    updated.name = newName;
    updateMediaItem(updated);
    APIService.MEDIA.PUT(updated);
  };

  const updateDescription = (newDescription: string) => {
    const updated = { ...mediaItem };
    updated.description = newDescription;
    updateMediaItem(updated);
    APIService.MEDIA.PUT(updated);
  };

  const updateFileDate = (event: MaterialUiPickersDate) => {
    if (event) {
      const updated = { ...mediaItem };
      updated.fileDate = event.toString();
      updateMediaItem(updated);
      APIService.MEDIA.PUT(updated);
    }
  };

  const getFileDateLabel = (type: TN_MEDIA_TYPE) => {
    if (type === "IMAGE") {
      return "Photo Date";
    } else if (type === "VIDEO") {
      return "Video Date";
    } else if (type === "AUDIO") {
      return "Date";
    }

    return "Date";
  };

  return (
    <>
      {!hidePreview && (
        <div className={styles.mediaPreview}>
          {mediaItem.type === "IMAGE" && (
            <img
              src={mediaItem.url}
              alt={mediaItem.name}
              className={styles.imagePreview}
            />
          )}
          {mediaItem.type === "VIDEO" && (
            <video
              src={mediaItem.url}
              className={styles.videoPreview}
              controls
            />
          )}
          {mediaItem.type === "AUDIO" && (
            <div className={styles.audioPreview}>
              <ReactAudioPlayer
                src={audioUrl}
                controls
                style={{ maxWidth: "100%" }}
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.sidebarBtnContainer}>
        {mediaItem.type === "IMAGE" && (
          <Button
            className={styles.sidebarBtns}
            width={"full"}
            theme={"tertiary"}
            onClick={() => editImage(mediaItem)}
            disabled={mediaItem.filePathInBucket
              ?.toLowerCase()
              .endsWith(".gif")}
          >{`${
            mediaItem.filePathInBucket?.toLowerCase().endsWith(".gif")
              ? "Cannot edit GIFs"
              : "Edit Image"
          }`}</Button>
        )}
        {!hidePreview && (
          <Button
            className={styles.sidebarBtns}
            theme={"tertiary"}
            width={"full"}
            onClick={() => {
              deleteSelectedMedia(mediaItem?.id);
            }}
            disabled={showDeleteConfirmation}
          >
            Delete Permanently
          </Button>
        )}
        {showDeleteConfirmation && (
          <div>
            <p>
              This media is used in existing content. You must delete the
              associated content before deleting this media.
            </p>
            <div className={styles.confirmationButtons}>
              <Button
                theme={"tertiary"}
                onClick={() => {
                  setShowDeleteConfirmation(false);
                }}
              >
                Ok
              </Button>
              {/* <Button
                theme={"tertiary"}
                onClick={() => {
                  deleteSelectedMedia(mediaItem?.id);
                }}
                destructive
              >
                Yes
              </Button>
              <Button
                theme={"tertiary"}
                onClick={() => {
                  setShowDeleteConfirmation(false);
                }}
              >
                No
              </Button> */}
            </div>
          </div>
        )}
        <div className={styles.inputTitle}>File Name</div>
        <CharacterInput
          placeholder="Title"
          value={mediaItem.name}
          onBlur={(e) => {
            updateName(e.currentTarget.value);
          }}
        />
        <div className={styles.inputTitle}>Date</div>
        <DatePickerInput
          label={getFileDateLabel(mediaItem.type)}
          value={mediaItem.fileDate || ""}
          theme="dark"
          onChange={updateFileDate}
        />
        {mediaItem.type === "VIDEO" && (
          <>
            <div className={styles.inputTitle}>Description</div>
            <CharacterInput
              placeholder="Description"
              value={mediaItem.description ? mediaItem.description : ""}
              onBlur={(e) => {
                updateDescription(e.currentTarget.value);
              }}
              isTextArea
            />
          </>
        )}
        <div className={styles.tagsList}>
          <div className={styles.title}>Tags</div>
          <MediaTagsList selectedMedia={mediaItem} />
        </div>

        <div className={styles.addTagContainer}>
          <div
            className={styles.addTagBtn}
            onClick={() => setIsExpand(!isExpand)}
          >
            Add Tag
          </div>
          <TagsList selectedMedia={mediaItem} isExpand={isExpand} />
        </div>
        <div className={styles.uploadedDate}>
          Uploaded on: {generateDateString(mediaItem.created)}
        </div>
      </div>
    </>
  );
};

export type componentTypesForMediaTagList = "singleSelect" | "multiSelect";
interface MediaTagsList {
  selectedMedia?: MediaModel;
  selectedMediaItems?: MediaModel[];
  type?: componentTypesForMediaTagList;
}

export const MediaTagsList: React.FC<MediaTagsList> = ({
  selectedMedia,
  selectedMediaItems,
  type = "singleSelect",
}) => {
  const { mediaItemTags, removeMediaItemsTags } = useContext(MediaTagContext);
  const [tags, setTags] = useState<TagModel[]>([]);

  useEffect(() => {
    if (selectedMedia && type === "singleSelect") {
      const result = mediaItemTags.filter(
        (media) => media.mediaId == selectedMedia.id
      );

      setTags(result[0] === undefined ? [] : result[0].tags);
    }

    if (selectedMediaItems && type === "multiSelect") {
      const associatedTags = mediaItemTags
        .filter((elem) =>
          selectedMediaItems.find(({ id }) => elem.mediaId === id)
        )
        .map((e) => e.tags)
        .flat();

      const removeDuplicateTags = uniqBy(associatedTags, "id");

      setTags(removeDuplicateTags);
    }
  }, [mediaItemTags, selectedMedia, selectedMediaItems]);

  const removeTagFromMedia = async (
    tagId: string,
    type: componentTypesForMediaTagList
  ) => {
    if (selectedMedia && type === "singleSelect") {
      const removeTagToMediaItem = await APIService.MEDIA_TAG.DELETE(
        selectedMedia.id as string,
        tagId
      );

      if (removeTagToMediaItem) {
        removeMediaItemsTags([selectedMedia.id as string], [tagId]);
      }
    }

    if (selectedMediaItems && type === "multiSelect") {
      const getMediaItemsassociatedToTag = mediaItemTags
        .filter((elem: MediaTagListModel) =>
          selectedMediaItems.find(
            ({ id }) =>
              elem.mediaId === id && elem.tags.find(({ id }) => id === tagId)
          )
        )
        .map((e) => e.mediaId);

      const promises = getMediaItemsassociatedToTag.map((mediaId: string) =>
        APIService.MEDIA_TAG.DELETE(mediaId as string, tagId)
      );

      const results = await Promise.all(promises);
      console.log("APIService.MEDIA_TAG.DELETE results = ", results);

      // remove deleted tag from mediaTags context
      removeMediaItemsTags(getMediaItemsassociatedToTag, [tagId]);
    }
  };

  return (
    <>
      {tags.length > 0 &&
        tags.map((tag) => (
          <div key={tag.id} className={styles.tagBar}>
            <span className={styles.tag}>{tag.name}</span>
            <img
              src={CloseIcon}
              role="button"
              className={styles.imageButton}
              onClick={() => removeTagFromMedia(tag.id as string, type)}
            />
          </div>
        ))}
    </>
  );
};

interface TagsList {
  selectedMedia?: MediaModel;
  selectedMediaItems?: MediaModel[];
  type?: componentTypesForMediaTagList;
  isExpand: boolean;
}

export const TagsList: React.FC<TagsList> = ({
  selectedMedia,
  selectedMediaItems,
  type = "singleSelect",
  isExpand = false,
}) => {
  const { tagFolders } = useContext(TagFoldersContext);
  const { currentTeam } = useContext(TeamContext);
  const { mediaItemTags, updateMediaItemsTags } = useContext(MediaTagContext);

  const selectedMediaItemTags = mediaItemTags
    .filter(
      (mediaItem) => selectedMedia && mediaItem.mediaId === selectedMedia.id
    )
    .map((media) => media.tags.map((e) => e.id))[0];

  const selectedMediaItemTagsArray = selectedMediaItemTags
    ? selectedMediaItemTags
    : [];

  const getTagOrderTagIdsArray = (tagOrder: TagModel[]) => {
    return tagOrder.map((tag: TagModel) => tag.id);
  };

  const addAndUpdateTagToMediaItem = async (
    mediaId: string,
    tagId: string,
    tagFolderId: string
  ) => {
    const teamId = currentTeam?.id || undefined;

    const addTagToMediaItem = await APIService.MEDIA_TAG.POST({
      mediaId: mediaId,
      teamId: teamId,
      tagFolderId: tagFolderId,
      tagId: tagId,
    });

    return addTagToMediaItem;
  };

  const addTagToMultipleMediaItems = (
    selectedMediaItems: MediaModel[],
    tagId: string,
    tagFolderId: string
  ) => {
    const getMediaItemNotInMediaItemTags = selectedMediaItems
      .filter(
        (elem: MediaModel) =>
          !mediaItemTags.find(({ mediaId }) => elem.id === mediaId)
      )
      .map((e) => e.id as string);

    const getMediaItemsNotAssociatedToMediaItemTagsModelTagsArray = mediaItemTags
      .filter((elem: MediaTagListModel) =>
        selectedMediaItems.find(
          ({ id }) =>
            elem.mediaId === id && !elem.tags.find(({ id }) => id === tagId)
        )
      )
      .map((e) => e.mediaId);

    const combine = [
      ...getMediaItemNotInMediaItemTags,
      ...getMediaItemsNotAssociatedToMediaItemTagsModelTagsArray,
    ];

    addAndUpdateTagToMediaItems(combine, tagId, tagFolderId);
  };

  const addAndUpdateTagToMediaItems = async (
    getMediaItemsNotAssociatedToMediaItemTagsModelTagsArray: any,
    tagId: string,
    tagFolderId: string
  ) => {
    const updatedMediaItemsPromises =
      getMediaItemsNotAssociatedToMediaItemTagsModelTagsArray &&
      getMediaItemsNotAssociatedToMediaItemTagsModelTagsArray.map(
        (mediaId: string) =>
          addAndUpdateTagToMediaItem(mediaId as string, tagId, tagFolderId)
      );

    const updatedResults = await Promise.all(updatedMediaItemsPromises);

    updateMediaItemsTags(updatedResults as MediaTagListModel[]);
  };

  return (
    <>
      <div
        className={`${styles.tagDropDownContainer} ${
          isExpand ? styles.expand : styles.collapse
        }`}
      >
        {tagFolders.map(
          (tagFolder) =>
            tagFolder &&
            tagFolder.tagOrder &&
            tagFolder.tagOrder?.length > 0 &&
            difference(
              getTagOrderTagIdsArray(tagFolder.tagOrder),
              selectedMediaItemTagsArray as string[]
            ).length > 0 && (
              <div key={tagFolder.id}>
                <div className={styles.tagFolderTitle}>{tagFolder.name}</div>
                <div>
                  {tagFolder &&
                    tagFolder.tagOrder &&
                    tagFolder.tagOrder.map(
                      (tag: TagModel) =>
                        tag &&
                        tag.id &&
                        selectedMediaItemTagsArray &&
                        !selectedMediaItemTagsArray.includes(tag.id) && (
                          <div
                            className={styles.tag}
                            key={tag.id}
                            onClick={() =>
                              type === "singleSelect" && selectedMedia
                                ? addTagToMultipleMediaItems(
                                    [selectedMedia] as MediaModel[],
                                    tag.id as string,
                                    tagFolder.id as string
                                  )
                                : addTagToMultipleMediaItems(
                                    selectedMediaItems as MediaModel[],
                                    tag.id as string,
                                    tagFolder.id as string
                                  )
                            }
                          >
                            {tag.name}
                          </div>
                        )
                    )}
                </div>
              </div>
            )
        )}
      </div>
    </>
  );
};

export interface MultipleSelectedSidebarProps {
  numberSelected: number;
  clearSelection: () => void;
  selectedMediaItems: MediaModel[];
  deleteSelectedMedia: () => void;
  showDeleteConfirmation: boolean;
  setShowDeleteConfirmation: (newVal: boolean) => void;
}

export const MultipleSelectedSidebar: React.FC<MultipleSelectedSidebarProps> = ({
  numberSelected,
  selectedMediaItems,
  deleteSelectedMedia,
  showDeleteConfirmation,
  setShowDeleteConfirmation,
}): ReactElement => {
  const [isExpand, setIsExpand] = useState<boolean>(false);

  return (
    <>
      <div className={styles.multiSelectPreview}>
        <span>{`${numberSelected} files selected.`}</span>
      </div>
      <div className={styles.sidebarBtnContainer}>
        <div className={styles.sidebarBtnsContainer}>
          <Button
            className={styles.sidebarBtns}
            theme={"tertiary"}
            onClick={deleteSelectedMedia}
            disabled={showDeleteConfirmation}
          >
            Delete {numberSelected} File{numberSelected > 1 ? `s` : ``}
          </Button>
        </div>

        {showDeleteConfirmation && (
          <div>
            <p>
              This media is used in existing flashcards. Are you sure you want
              to delete?
            </p>
            <div className={styles.confirmationButtons}>
              <Button
                theme={"tertiary"}
                onClick={deleteSelectedMedia}
                destructive
              >
                Yes
              </Button>
              <Button
                theme={"tertiary"}
                onClick={() => {
                  setShowDeleteConfirmation(false);
                }}
              >
                No
              </Button>
            </div>
          </div>
        )}

        <div className={styles.inputTitle}>File Name</div>
        <CharacterInput value="Multiple Values" disabled />
        <div className={styles.inputTitle}>Date</div>
        <CharacterInput value="Multiple Values" disabled />

        <div className={styles.tagsList}>
          <div className={styles.title}>Tags</div>
          <MediaTagsList
            selectedMediaItems={selectedMediaItems}
            type="multiSelect"
          />
        </div>

        <div className={styles.addTagContainer}>
          <div
            className={styles.addTagBtn}
            onClick={() => setIsExpand(!isExpand)}
          >
            Add Tag
          </div>
          <TagsList
            selectedMediaItems={selectedMediaItems}
            type="multiSelect"
            isExpand={isExpand}
          />
        </div>
      </div>
    </>
  );
};
