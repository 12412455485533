import React, { useContext, useState } from "react";
import styles from "./AddToGamePlan.module.scss";

import { PlayModel } from "../../../generated/from-api/models/play.model";
import { GamePlanModel } from "../../../generated/from-api/models/gamePlan.model";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { GamePlansContext } from "../../../shared/shared-with-mobile/providers/gamePlans.provider";
import Button from "../../../components/Button/Button";
import { CircularProgress } from "@material-ui/core";

interface Props {
  selectedPlaySets: Map<string, PlayModel>;
  selectedPlays: Map<string, PlayModel>;
  clearSelectedPlaysAndPlaySets: () => void;
}

const AddToGamePlan: React.FC<Props> = ({
  selectedPlays,
  selectedPlaySets,
  clearSelectedPlaysAndPlaySets,
}) => {
  const { closeModal, dispatchToast } = useContext(UIContext);
  const { gamePlans, updateGamePlan } = useContext(GamePlansContext);
  const [processing, setProcessing] = useState<boolean>(false);

  // TODO: perform this deduplication on API side
  const preventAddingDuplicatePlaysAndPlaySetsToGamePlan = (
    playIdsToAdd: string[],
    gamePlanPlayIds: string[]
  ): string[] => {
    const idsToAdd = new Set(playIdsToAdd); // make this a set for faster removal

    for (const id of gamePlanPlayIds) {
      if (!idsToAdd.has(id)) {
        idsToAdd.add(id);
      }
    }

    return Array.from(idsToAdd);
  };

  const addPlaysToGamePlan = async (gamePlan: GamePlanModel) => {
    if (processing) {
      return;
    }
    try {
      setProcessing(true);
      let playIdsToAdd = Array.from(selectedPlays.keys());
      let playsetIdsToAdd = Array.from(selectedPlaySets.keys());

      const selectedGamePlan = gamePlans.find((i) => i.id === gamePlan.id);
      if (selectedGamePlan) {
        const gamePlanPlayIds = selectedGamePlan.plays.map(
          (play) => play.id as string
        );

        const gamePlanPlaySetIds = selectedGamePlan.playSets.map(
          (playset) => playset.id as string
        );

        playIdsToAdd = preventAddingDuplicatePlaysAndPlaySetsToGamePlan(
          playIdsToAdd,
          gamePlanPlayIds
        );

        playsetIdsToAdd = preventAddingDuplicatePlaysAndPlaySetsToGamePlan(
          playsetIdsToAdd,
          gamePlanPlaySetIds
        );
      }

      if (gamePlan.id) {
        const response = await APIService.GAME_PLAN.PUT(gamePlan.id as string, {
          name: gamePlan.name,
          displayOnMenu: gamePlan.displayOnMenu,
          expirationDate: gamePlan.expirationDate,
          plays: playIdsToAdd,
          playSets: playsetIdsToAdd,
        });

        if (response) {
          const updatedGamePlan = await APIService.GAME_PLAN.GET(
            gamePlan.id as string
          );
          if (updatedGamePlan) {
            updateGamePlan(updatedGamePlan);
          }

          dispatchToast({
            type: "success",
            message: `${playIdsToAdd.length} play${
              playIdsToAdd.length === 1 ? "" : "s"
            } and ${playsetIdsToAdd.length} playset${
              playsetIdsToAdd.length === 1 ? "" : "s"
            }  added to play group.`,
          });
          clearSelectedPlaysAndPlaySets();
        }
      }
    } catch (err) {
      dispatchToast({
        type: "error",
        message: "Something went wrong, please try again.",
      });
    } finally {
      setProcessing(false);
      closeModal();
    }
  };
  return (
    <div className={styles.addToGamePlanContainer}>
      {processing && (
        <div className={styles.processing}>
          <h3>{`Processing ${selectedPlays.size} Play${
            selectedPlays.size > 1 ? "s" : ""
          }`}</h3>
          <CircularProgress />
        </div>
      )}
      {!processing &&
        gamePlans.map((gamePlan: GamePlanModel) => {
          return (
            <Button
              key={gamePlan.id}
              className={styles.gamePlanBtns}
              theme="tertiary"
              size={"large"}
              onClick={() => addPlaysToGamePlan(gamePlan)}
            >
              {gamePlan.name}
            </Button>
          );
        })}
    </div>
  );
};

export default AddToGamePlan;
