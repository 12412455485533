import { createContext, useState } from "react";

import { InvitationModel } from "../../../generated/from-api/models/invitation.model";
import { invitationTypes } from "../constants";

export const InvitationsContext = createContext<{
  currentPlayerInvitations: undefined | null | Map<string, InvitationModel>;
  currentCoachInvitations: undefined | null | Map<string, InvitationModel>;
  replaceInvitations: (newInvitations: InvitationModel[]) => void;
  addOrUpdateInvitation: (invitation: InvitationModel) => void;
  addOrUpdateInvitations: (invitation: InvitationModel[]) => void;
  removeInvitation: (invitationId: string) => void;
  clearInvitations: () => void;
}>({
  currentPlayerInvitations: undefined,
  currentCoachInvitations: undefined,
  replaceInvitations: () => null,
  addOrUpdateInvitation: () => null,
  addOrUpdateInvitations: () => null,
  removeInvitation: () => null,
  clearInvitations: () => null,
});

export const InvitationsProvider: React.FC = ({ children }) => {
  const [currentPlayerInvitations, setCurrentPlayerInvitations] = useState<Map<
    string,
    InvitationModel
  > | null>(null);
  const [currentCoachInvitations, setCurrentCoachInvitations] = useState<Map<
    string,
    InvitationModel
  > | null>(null);

  const replaceInvitations = (newInvitations: InvitationModel[]) => {
    const newPlayerInvitationsMap = new Map<string, InvitationModel>();
    const newCoachInvitationsMap = new Map<string, InvitationModel>();

    newInvitations.forEach((invitation) => {
      switch (invitation.type) {
        case invitationTypes.PLAYER:
          newPlayerInvitationsMap.set(invitation.id as string, invitation);
          break;
        case invitationTypes.COACH:
        default:
          newCoachInvitationsMap.set(invitation.id as string, invitation);
          break;
      }
    });

    setCurrentPlayerInvitations(newPlayerInvitationsMap);
    setCurrentCoachInvitations(newCoachInvitationsMap);
  };

  const addOrUpdateInvitation = (invitation: InvitationModel) => {
    switch (invitation.type) {
      case invitationTypes.PLAYER:
        const currentPlayerInvitationsCopy = currentPlayerInvitations
          ? new Map(currentPlayerInvitations)
          : new Map<string, InvitationModel>();

        currentPlayerInvitationsCopy.set(invitation.id as string, invitation);
        setCurrentPlayerInvitations(currentPlayerInvitationsCopy);
        break;
      case invitationTypes.COACH:
      default:
        const currentCoachInvitationsCopy = currentCoachInvitations
          ? new Map(currentCoachInvitations)
          : new Map<string, InvitationModel>();

        currentCoachInvitationsCopy.set(invitation.id as string, invitation);
        setCurrentCoachInvitations(currentCoachInvitationsCopy);
        break;
    }
  };

  const addOrUpdateInvitations = (invitations: InvitationModel[]) => {
    const currentPlayerInvitationsCopy = currentPlayerInvitations
      ? new Map(currentPlayerInvitations)
      : new Map<string, InvitationModel>();

    const currentCoachInvitationsCopy = currentCoachInvitations
      ? new Map(currentCoachInvitations)
      : new Map<string, InvitationModel>();

    for (const invitation of invitations) {
      switch (invitation.type) {
        case invitationTypes.PLAYER:
          currentPlayerInvitationsCopy.set(invitation.id as string, invitation);
          break;
        case invitationTypes.COACH:
        default:
          currentCoachInvitationsCopy.set(invitation.id as string, invitation);
          break;
      }
    }

    setCurrentPlayerInvitations(currentPlayerInvitationsCopy);
    setCurrentCoachInvitations(currentCoachInvitationsCopy);
  };

  const removeInvitation = (invitationId: string) => {
    const invitation =
      currentPlayerInvitations?.get(invitationId) ||
      currentCoachInvitations?.get(invitationId);

    if (!invitation) {
      return;
    }

    switch (invitation.type) {
      case invitationTypes.PLAYER:
        const currentPlayerInvitationsCopy = currentPlayerInvitations
          ? new Map(currentPlayerInvitations)
          : new Map<string, InvitationModel>();

        currentPlayerInvitationsCopy.delete(invitation.id as string);
        setCurrentPlayerInvitations(currentPlayerInvitationsCopy);
        break;
      case invitationTypes.COACH:
      default:
        const currentCoachInvitationsCopy = currentCoachInvitations
          ? new Map(currentCoachInvitations)
          : new Map<string, InvitationModel>();

        currentCoachInvitationsCopy.delete(invitation.id as string);
        setCurrentCoachInvitations(currentCoachInvitationsCopy);
        break;
    }
  };

  const clearInvitations = () => {
    setCurrentPlayerInvitations(null);
    setCurrentCoachInvitations(null);
  };

  return (
    <InvitationsContext.Provider
      value={{
        currentPlayerInvitations,
        currentCoachInvitations,
        replaceInvitations,
        addOrUpdateInvitation,
        addOrUpdateInvitations,
        removeInvitation,
        clearInvitations,
      }}
    >
      {children}
    </InvitationsContext.Provider>
  );
};
