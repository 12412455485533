import { useContext, useEffect, useState } from "react";

import styles from "../Account.module.scss";
import CharacterInput from "../../../components/Input/CharacterInput";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import Button from "../../../components/Button/Button";

interface FormElements extends HTMLFormControlsCollection {
  newPassword: HTMLInputElement;
  newPasswordCopy: HTMLInputElement;
}

const ChangePassword: React.FC = () => {
  const { dispatchToast } = useContext(UIContext);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordCopy, setNewPasswordCopy] = useState<string>("");
  const [passwordMatchError, setPasswordMatchError] = useState<boolean>(false);

  useEffect(() => {
    if (
      (newPassword.length > 0,
      newPasswordCopy.length > 0 && newPassword !== newPasswordCopy)
    ) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  }, [newPassword, newPasswordCopy]);

  const changePassword = async (event: React.FormEvent) => {
    event.preventDefault();

    const {
      newPassword: { value: newPassword },
      newPasswordCopy: { value: newPasswordCopy },
    } = (event.currentTarget as HTMLFormElement).elements as FormElements;

    if (
      newPassword.length === 0 ||
      newPasswordCopy.length === 0 ||
      newPassword !== newPasswordCopy
    ) {
      return;
    } else {
      const response = await APIService.USER.CHANGE_PASSWORD(newPassword);

      if (response === "OK") {
        dispatchToast({
          type: "success",
          message: "Password successfully changed.",
        });
      } else {
        dispatchToast({
          type: "error",
          message:
            "There has been an error changing your password. Plese try again",
        });
      }
    }
  };

  return (
    <form className={styles.changePasswordForm} onSubmit={changePassword}>
      <CharacterInput
        id="newPassword"
        type={INPUT_TYPES.PASSWORD}
        placeholder="New Password"
        onChange={(e) => setNewPassword(e.currentTarget.value)}
      />
      <CharacterInput
        id="newPasswordCopy"
        type={INPUT_TYPES.PASSWORD}
        placeholder="Confirm Password"
        onChange={(e) => setNewPasswordCopy(e.currentTarget.value)}
      />
      {passwordMatchError && (
        <span className={styles.passwordMatchError}>
          Passwords do not match
        </span>
      )}
      <div className={styles.changePasswordFooter}>
        <Button buttonType={"submit"}>Change Password</Button>
      </div>
    </form>
  );
};

export default ChangePassword;
