import { MediaModel } from "../generated/from-api/models/media.model";
import { APIService } from "../shared/shared-with-mobile/api-client/api.service";

export interface FileSizeValidity {
  isValid: boolean;
  errorMessage: string;
}

export const validateFileSize: (file: File) => FileSizeValidity = (
  file: File
) => {
  const fileSizeMax = 20 * 1024 * 1024; // 20MB
  const videoSizeMax = 30 * 1024 * 1024; // 30MB
  const fileType = file.type.split("/")[0]; // image, video, or audio
  const allowedFileTypes = ["image", "video", "audio"];

  let errorMessage = "";
  if (fileType === "image" && file.size > fileSizeMax) {
    errorMessage = "Images cannot exceed 20MB.";
  } else if (fileType === "video" && file.size > videoSizeMax) {
    errorMessage = "Videos cannot exceed 30MB.";
  } else if (fileType === "audio" && file.size > fileSizeMax) {
    errorMessage = "Audio files cannot exceed 20MB.";
  } else if (!allowedFileTypes.includes(fileType)) {
    errorMessage = "File type not allowed.";
  }

  return {
    isValid: !errorMessage,
    errorMessage,
  };
};

export const uploadFile: (
  file: File,
  teamId: string | undefined
) => Promise<MediaModel | undefined> = async (file, teamId) => {
  // start building FormData
  const data = new FormData();
  data.append("name", file.name || "");
  const fileType = file.type.split("/")[0]; // image, video, or audio

  data.append(fileType, file);

  if (teamId) {
    data.append("teamId", teamId);
  }

  let result: MediaModel | undefined;
  if (fileType === "image") {
    result = await APIService.MEDIA.POST_IMAGE(data);
  } else if (fileType === "video") {
    result = await APIService.MEDIA.POST_VIDEO(data);
  } else if (fileType === "audio") {
    result = await APIService.MEDIA.POST_AUDIO(data);
  }

  if (result) {
    return result;
  }
};
