import React, { useContext, useEffect, useState } from "react";
import styles from "../Admin.module.scss";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { LicenseTiersContext } from "../../../shared/shared-with-mobile/providers/licenseTiers";
import Button from "../../../components/Button/Button";
import { LicenseOwnerListRow, PaymentStatus } from "../ManageLicensesAdmin";
import colorSplotchFree from "../../../resources/images/colorSplotchFree.svg";
import colorSplotchTrial from "../../../resources/images/colorSplotchTrial.svg";
import colorSplotchPremier from "../../../resources/images/colorSplotchPremier.svg";
import colorSplotchBronze from "../../../resources/images/colorSplotchBronze.svg";
import colorSplotchSilver from "../../../resources/images/colorSplotchSilver.svg";
import colorSplotchGold from "../../../resources/images/colorSplotchGold.svg";
import colorSplotchPlatinum from "../../../resources/images/colorSplotchPlatinum.svg";
import GreenCheckIcon from "../../../resources/icons/GreenCheckIcon";
import YellowWarningIcon from "../../../resources/icons/YellowWarningIcon";
import RedAlertIcon from "../../../resources/icons/RedAlertIcon";
import HelpIcon from "../../../resources/images/help.svg";
import CloseIcon from "../../../resources/icons/CloseIcon";
import { AdminUtility, MAX_DAYS_BEFORE_PAYMENT } from "../admin.utility";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { CalculateTeamReadiness } from "../../../generated/from-api/models/readiness-snapshot/readiness.utils";
import { LicenseUsageModel } from "../../../generated/from-api/models/license-usage.model";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import CharacterInput from "../../../components/Input/CharacterInput";
import Switch from "../../../components/Switch/Switch";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { LicenseHolderModel } from "../../../generated/from-api/models/license.model";
import { UserProfileModel } from "../../../generated/from-api/models/user-profile.model";
import { getAbbreviatedDisplayName } from "../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import { auth } from "../../../shared/shared-with-mobile/api-client/firebase.utils";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import {
  getUserFacingSubscriptionName,
  LICENSE_TIERS,
} from "../../../generated/from-api/api-constants/license-tier-constants";
import TrialIcon from "../../../resources/icons/TrialIcon";
import DatePickerInput from "../../../components/DatePickerInput/DatePickerInput";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface Props {
  licenseData: LicenseOwnerListRow;
  updates: number;
  tnReps: UserProfileModel[];
  closeCallback: (value: number) => void;
}

interface StatProps {
  label: string;
  value?: string | number;
}

interface TierStyle {
  icon: string;
  color?: string;
}

const LicenseStats = (props: StatProps): JSX.Element => {
  return (
    <div className={styles.licenseStatBlock}>
      <div className={styles.licenseStatBlock__Label}>{props.label}</div>
      <div className={styles.licenseStatBlock__Value}>{props?.value}</div>
    </div>
  );
};

const ManageLicenseModal2: React.FC<Props> = ({
  licenseData,
  updates,
  tnReps,
  closeCallback,
}) => {
  const { dispatchToast } = useContext(UIContext);
  const { licenseTiers } = useContext(LicenseTiersContext);

  const [holderLicenses, setHolderLicenses] = useState<LicenseHolderModel[]>(
    licenseData.licenseHolderData
  );
  const [licenseOne, setLicenseOne] = useState<LicenseHolderModel>(
    licenseData.licenseHolderData[0]
  );
  const [orderId, setOrderId] = useState<string>(
    licenseOne.invoiceNumber || ""
  );
  const [licenseTierId, setLicenseTierId] = useState<string>(
    licenseOne.licenseTierId || ""
  );
  const [licensePaid, setLicensePaid] = useState<PaymentStatus>(
    licenseData.payment || false
  );
  const [licenseTnRep, setLicenseTnRep] = useState<string>(
    licenseOne.tnRepresentativeUserId || ""
  );
  const [teamCount, setTeamCount] = useState<number>(1);
  const [updateCount, setUpdateCount] = useState<number>(updates);
  const [licenseTotal, setLicenseTotal] = useState<number>(1);
  const [combinedStats, setCombinedStats] = useState<LicenseUsageModel>();
  const [averageReadiness, setAverageReadiness] = useState<number>(0);
  const [licenseSuspended, setLicenseSuspended] = useState<boolean>(
    licenseOne?.isSuspended || false
  );

  const licenseOwnerName = licenseData.owner;
  const creationDate = licenseData.dateCreated;
  const isAlertPayment = AdminUtility.createdOverXDaysAgo(
    licenseData.dateCreated.toLocaleString(),
    MAX_DAYS_BEFORE_PAYMENT
  );

  const tierStyle: Map<string, TierStyle> = new Map();
  tierStyle.set("PLATINUM", { icon: colorSplotchPlatinum, color: "#BEBEBE" });
  tierStyle.set("GOLD", { icon: colorSplotchGold, color: "#FFBC17" });
  tierStyle.set("SILVER", { icon: colorSplotchSilver, color: "#A2A2A2" });
  tierStyle.set("BRONZE", { icon: colorSplotchBronze, color: "#FF9D2A" });
  tierStyle.set("TRIAL", { icon: colorSplotchTrial, color: "#23aac1" });
  tierStyle.set("PREMIER", { icon: colorSplotchPremier, color: "#23aac1" });
  tierStyle.set("FREE", { icon: colorSplotchFree });
  tierStyle.set("FREE2023", { icon: colorSplotchFree });
  tierStyle.set("GOLD2023", { icon: colorSplotchGold, color: "#FFBC17" });
  tierStyle.set("SILVER2023", { icon: colorSplotchSilver, color: "#A2A2A2" });
  tierStyle.set("BRONZE2023", { icon: colorSplotchBronze, color: "#FF9D2A" });
  tierStyle.set("BASIC2023", { icon: colorSplotchTrial, color: "#23aac1" });
  tierStyle.set("YOUTH2023", { icon: colorSplotchBronze, color: "#FF9D2A" });
  tierStyle.set("COLLEGE2023", {
    icon: colorSplotchPlatinum,
    color: "#BEBEBE",
  });

  const suspendLicenseTooltip =
    "This will prevent coaches and players from accessing content or playing games until suspension is reversed.";

  useEffect(() => {
    const getReadiness = async (stats: LicenseUsageModel[], teams: number) => {
      const scores: number[] = await Promise.all(
        stats.map(async (teamStats) => {
          if (!teamStats.teamId) return 0;
          const readiness = await APIService.DASHBOARD.GET_PLAYER_OVERALL_METRICS(
            teamStats.teamId
          );
          const teamReadiness = CalculateTeamReadiness(
            teamStats.teamId,
            readiness.overallReadinessList
          );
          return teamReadiness.overallScore;
        })
      );
      const sumScore = scores.reduce((sum, i) => sum + i, 0);
      const averageScore = sumScore / (teams > 0 ? teams : 1);
      setAverageReadiness(averageScore);
    };
    const stats = licenseData?.licenseStats;
    if (!stats) return;
    // Calculate combined stats
    setCombinedStats(AdminUtility.getAggregateStats(stats));
    // Get number of active teams
    const activeTeams = AdminUtility.getActiveTeams(holderLicenses);
    setTeamCount(activeTeams);
    setLicenseTotal(holderLicenses.length || 1);
    // Get average team readiness
    getReadiness(stats, activeTeams);
  }, []);

  // Reload the license on modification
  useEffect(() => {
    const getUpdatedHolderLicenses = async () => {
      const userLicenses = await APIService.LICENSE.LIST(licenseOne.email);
      setHolderLicenses(userLicenses);
      const firstLicense = userLicenses[0];
      setLicenseOne(firstLicense);
      // Update values
      setOrderId(firstLicense.invoiceNumber);
      setTeamCount(AdminUtility.getActiveTeams(userLicenses));
      setLicenseTotal(userLicenses.length);
      setLicenseTierId(firstLicense.licenseTierId);
      setLicensePaid(
        AdminUtility.getPaymentCategory(
          firstLicense,
          AdminUtility.getEarliestCreationDate(userLicenses).toLocaleString(),
          firstLicense.invoiceNumber
        )
      );
      setLicenseTnRep(firstLicense.tnRepresentativeUserId);
      setLicenseSuspended(firstLicense.isSuspended || false);
    };
    getUpdatedHolderLicenses();
  }, [updateCount]);

  const getTierIcon = (tier: string) => {
    const tierIcon = tierStyle.get(tier);
    return tierIcon ? tierIcon.icon : undefined;
  };

  const getTierColor = (tier: string) => {
    const tierColor = tierStyle.get(tier);
    return tierColor ? tierColor.color : undefined;
  };

  const getPaidOptions = () => {
    if (
      licenseTierId === LICENSE_TIERS.FREE.id ||
      licenseTierId === LICENSE_TIERS.TRIAL.id ||
      licenseTierId === LICENSE_TIERS.PREMIER.id
    ) {
      return [{ value: "N/A", color: "" }];
    }
    const paid = { value: "Paid", color: "#00A863" };
    const alertColor =
      isAlertPayment ||
      licenseOne.invoiceNumber === null ||
      licenseOne.invoiceNumber?.length === 0
        ? "#DA3731"
        : "#FFE42A";
    const unpaid = { value: "Unpaid", color: alertColor };
    return [paid, unpaid];
  };

  const getPaidText = (paid: string) => {
    if (paid === PaymentStatus.Paid || paid === PaymentStatus.PaidNoInvoice)
      return "Paid";
    return "Unpaid";
  };

  const handleOrderIdUpdate = (value: string) => {
    setOrderId(value);
  };

  const handleOrderIdUpdateFinish = () => {
    if (!licenseOne) return;
    if (licenseOne.invoiceNumber === orderId) return;
    const paid = AdminUtility.getPaymentBoolean(licensePaid);
    handleUpdateLicense(licenseTierId, orderId, paid, licenseSuspended);
  };

  const getOrderLink = () => {
    return {
      href: "https://team-nation-sports.myshopify.com/admin/orders/" + orderId,
    };
  };

  const handleTierUpdate = async (value: string) => {
    let paid = AdminUtility.getPaymentBoolean(licensePaid);
    if (licenseTierId === LICENSE_TIERS.FREE.id && value !== licenseTierId) {
      paid = false;
    }
    await handleUpdateLicense(value, orderId, paid, licenseSuspended);
  };

  const handlePaidUpdate = async (value: string) => {
    const paid = AdminUtility.getPaymentBoolean(value);
    await handleUpdateLicense(licenseTierId, orderId, paid, licenseSuspended);
  };

  const handleTnRepUpdate = async (value: string) => {
    await APIService.USER_REPRESENTATIVE.POST_REPRESENTATIVE_FOR_USER(
      licenseOne.userId,
      value
    );
    setUpdateCount(updateCount + 1);
  };

  const handleSuspendLicenseSwitch = async (value: boolean) => {
    setLicenseSuspended(value);
    const paid = AdminUtility.getPaymentBoolean(licensePaid);
    await handleUpdateLicense(licenseTierId, orderId, paid, value);
  };

  const handleExpirationDateChange = async (event: MaterialUiPickersDate) => {
    if (!event) {
      return;
    }

    const date = event.toString();
    const paid = AdminUtility.getPaymentBoolean(licensePaid);

    await handleUpdateLicense(
      licenseTierId,
      orderId,
      paid,
      licenseSuspended,
      date
    );
  };

  // TODO: refactor this entire function
  // mixing and matching async await with promise then/catch is extremely confusing
  // error handling looks to bet set up incorrectly
  const handleUpdateLicense = async (
    tier: string,
    order: string,
    payment: boolean,
    suspendLicense: boolean,
    expirationDate?: string
  ) => {
    await APIService.LICENSE.MANAGE_LICENSE_HOLDER(
      holderLicenses.map((license) => license.id),
      order,
      tier,
      suspendLicense,
      payment,
      expirationDate
    )
      .then(async () => {
        // This should behavior is largely deprecated, as newer licenses only support 1 team
        // But if it is used, the API currently handles creating each new license with the same properties as the first
        if (tier !== licenseTierId) {
          const tierObj = getTier(tier);
          if (tierObj) {
            if (licenseTotal < tierObj.maxTeams) {
              for (let i = licenseTotal; i < tierObj.maxTeams; i++) {
                await APIService.LICENSE.ADD_LICENSE(licenseOne.email);
              }
            }
            // For now, don't worry about removing licenses own downgrade
            /*else {
              const licenseMinimum = Math.max(tierObj.maxTeams, teamCount);
              const removalCount = licenseTotal - licenseMinimum;
              if (removalCount > 0) {
                const holderLicensesToRemove = holderLicenses.filter((team) => {
                  return !team.assigned;
                });
                for (let i = 0; i < removalCount; i++) {
                  if (holderLicensesToRemove.length <= i) return;
                  const license = holderLicensesToRemove[i];
                  await APIService.LICENSE.DELETE_LICENSE(license.id);
                }
              }
            }*/
          }
        }
        dispatchToast({
          type: "success",
          message: "License Holder Updated.",
        });
        setUpdateCount(updateCount + 1);
      })
      .catch(() => {
        dispatchToast({
          type: "error",
          message: "Failed to update license",
        });
      });
  };

  const handleAddTeam = async () => {
    if (!licenseOne) return;
    await APIService.LICENSE.ADD_LICENSE(licenseOne.email)
      .then(() => {
        dispatchToast({
          type: "success",
          message: "Team added.",
        });
        setUpdateCount(updateCount + 1);
      })
      .catch(() => {
        dispatchToast({
          type: "error",
          message: "Failed to add team.",
        });
      });
  };

  const handleRemoveTeam = async () => {
    if (!holderLicenses || !licenseOne) return;
    // Make sure that we aren't going below the minimum
    if (isMinimumTeamAmount()) return;
    // Find an unassigned license
    const removeLicense = holderLicenses.find((team) => {
      return !team.assigned;
    });
    if (!removeLicense) return;
    await APIService.LICENSE.DELETE_LICENSE(removeLicense.id)
      .then(() => {
        removeLicense.deleted = true;
        dispatchToast({
          type: "success",
          message: "Team removed.",
        });
        setUpdateCount(updateCount + 1);
      })
      .catch(() => {
        dispatchToast({
          type: "error",
          message: "Failed to remove team.",
        });
      });
  };

  const handleImpersonation = async () => {
    if (!licenseOne) return;
    const result = await APIService.IMPERSONATION.POST(licenseOne.userId);
    if (!result) {
      // error
    } else {
      await auth.signOut();
      await auth.signInWithCustomToken(result.token);
      localStorage.setItem("isAdminBrowsing", "true");
      window.location.reload();
    }
  };

  const isMinimumTeamAmount = () => {
    const currentTier = getTier(licenseOne.licenseTierId);
    if (!currentTier) return true;
    if (licenseTotal <= teamCount || licenseTotal <= currentTier.maxTeams) {
      return true;
    }
    return false;
  };

  const getTier = (tierId: string) => {
    return licenseTiers.find((tier) => {
      return tier.id === tierId;
    });
  };

  return (
    <div className={styles.manageLicenseModalWrapper}>
      <div className={styles.manageLicenseModal__Header}>
        Manage License
        <Button
          className={styles.closeIcon}
          theme={"transparent"}
          size={"small"}
          icon={<CloseIcon />}
          onClick={() => closeCallback(updateCount + 1)}
        />
      </div>
      <div className={styles.manageLicenseModal__Body}>
        <div className={styles.manageLicenseModal__Row}>
          <LicenseStats label={"Owner"} value={licenseOwnerName} />
          <LicenseStats label={"Email"} value={licenseOne.email} />
          <DatePickerInput
            label={"License Expiration"}
            value={licenseOne.expires}
            theme="dark"
            format="MM/dd/yyyy"
            onChange={handleExpirationDateChange}
          />
          <div className={styles.orderId}>
            <CharacterInput
              type={INPUT_TYPES.TEXT}
              placeholder={"Order ID"}
              value={orderId}
              onChange={(e) => handleOrderIdUpdate(e.currentTarget.value)}
              onBlur={() => handleOrderIdUpdateFinish()}
            />
            <Button
              theme={"secondary"}
              size={"medium"}
              type={"external link"}
              externalLinkOptions={getOrderLink()}
            >
              View
            </Button>
          </div>
          <div className={styles.tierSelect}>
            <SingleSelect
              placeholder="License Tiers"
              options={licenseTiers
                .slice()
                .sort((aTier, bTier) => {
                  // as of 3/31/2023, these are the 5 license tiers which we are actively selling
                  const TIER_SORTER: Record<string, number> = {
                    COLLEGE2023: 5,
                    GOLD2023: 4,
                    SILVER2023: 3,
                    YOUTH2023: 2,
                    BASIC2023: 1,
                  };

                  const aTierSortValue = TIER_SORTER[aTier.tier] || 0;
                  const bTierSortValue = TIER_SORTER[bTier.tier] || 0;

                  return bTierSortValue - aTierSortValue;
                })
                .map((licenseTier) => {
                  return {
                    label: getUserFacingSubscriptionName(licenseTier.tier),
                    value: String(licenseTier.id),
                    icon: getTierIcon(licenseTier.tier),
                    color: getTierColor(licenseTier.tier),
                  };
                })}
              value={licenseTierId}
              onChange={(e) => handleTierUpdate(e.currentTarget.value)}
            />
          </div>
          <div className={styles.paymentSelect}>
            <div className={styles.paymentSelect__Icon}>
              {AdminUtility.getPaymentIcon(
                licenseOne,
                creationDate.toLocaleString(),
                <GreenCheckIcon />,
                <YellowWarningIcon />,
                <RedAlertIcon />,
                <TrialIcon />
              )}
            </div>
            {licenseTierId !== LICENSE_TIERS.FREE.id &&
            licenseTierId !== LICENSE_TIERS.TRIAL.id &&
            licenseTierId !== LICENSE_TIERS.FREE2023.id &&
            licenseTierId !== LICENSE_TIERS.BASIC2023.id &&
            licenseTierId !== LICENSE_TIERS.PREMIER.id ? (
              <SingleSelect
                options={getPaidOptions().map((paid) => {
                  return {
                    label: paid.value,
                    value: paid.value,
                    selectedColor: paid.color,
                  };
                })}
                value={getPaidText(licensePaid)}
                onChange={(e) => handlePaidUpdate(e.currentTarget.value)}
              />
            ) : (
              <SingleSelect
                options={[
                  {
                    label: "N/A",
                    value: "N/A",
                  },
                ]}
                value={"N/A"}
              />
            )}
          </div>
          <div className={styles.tnRepSelect}>
            <SingleSelect
              placeholder="Team Nation Rep"
              options={tnReps.map((rep) => {
                return {
                  label: getAbbreviatedDisplayName(rep.firstName, rep.lastName),
                  value: rep.id,
                };
              })}
              value={licenseTnRep}
              onChange={(e) => handleTnRepUpdate(e.currentTarget.value)}
            />
          </div>
          <div className={styles.suspendLicenseSwitch}>
            <Switch
              theme={"yellow"}
              toggled={licenseSuspended}
              onToggle={(value) => handleSuspendLicenseSwitch(value)}
            />
            <span>Suspend License</span>
            <Tooltip tip={suspendLicenseTooltip}>
              <img src={HelpIcon} />
            </Tooltip>
          </div>
          <LicenseStats
            label={"Teams Created"}
            value={teamCount + "/" + licenseTotal}
          />
          <div className={styles.incrementTeamsButtons}>
            <Button
              theme={"tertiary"}
              size={"x-small"}
              onClick={handleRemoveTeam}
              disabled={isMinimumTeamAmount()}
            >
              Remove Team
            </Button>
            <Button theme={"tertiary"} size={"x-small"} onClick={handleAddTeam}>
              Add Team
            </Button>
          </div>
        </div>
        <div className={styles.manageLicenseModal__Stats}>
          <div className={styles.manageLicenseModal__StatsHeader}>
            Statistics
          </div>
          <LicenseStats
            label={"Created"}
            value={creationDate.toLocaleString()}
          />
          <LicenseStats
            label={"Users"}
            value={
              combinedStats
                ? combinedStats.numberOfPlayers + combinedStats.numberOfCoaches
                : 0
            }
          />
          <LicenseStats
            label={"Questions Answered"}
            value={combinedStats?.numberOfQuestionsAnswered || 0}
          />
          <LicenseStats
            label={"Avg. Readiness"}
            value={averageReadiness.toFixed(2) + "%"}
          />
          <LicenseStats
            label={"Plays"}
            value={combinedStats?.numberOfPlays || 0}
          />
          <LicenseStats
            label={"Flashcards"}
            value={combinedStats?.numberOfCustomQuizzes || 0}
          />
          <LicenseStats
            label={"Lessons"}
            value={combinedStats?.numberOfInstalls || 0}
          />
        </div>
      </div>
      <div className={styles.manageLicenseModal__Footer}>
        <div className={styles.manageLicenseModal__ButtonsWrapper}>
          <Button
            theme={"secondary"}
            size={"small"}
            onClick={() => closeCallback(updateCount + 1)}
          >
            Close
          </Button>
          <Button
            theme={"primary"}
            size={"small"}
            onClick={handleImpersonation}
          >
            Admin Browse
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageLicenseModal2;
