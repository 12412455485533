import React, { useContext } from "react";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import styles from "./EditRoster.module.scss";
import Button from "../../../components/Button/Button";

interface DeleteProps {
  type: string;
  onSubmit: (e: React.FormEvent<Element>) => void;
}

export const DeleteModal = (props: DeleteProps): JSX.Element => {
  const { closeModal } = useContext(UIContext);
  const { type, onSubmit } = props;
  return (
    <form onSubmit={onSubmit} className={styles.DeleteModal}>
      Are you sure you want to remove this {type} from the team?
      <span className={styles.buttonSpacing}>
        <Button
          className={styles.capitalize}
          theme="tertiary"
          size={"small"}
          buttonType={"submit"}
          destructive
        >{`Remove ${type}`}</Button>
        <Button
          className={styles.capitalize}
          size={"small"}
          onClick={() => closeModal()}
        >{`Keep ${type}`}</Button>
      </span>
    </form>
  );
};
