import "react";
import styles from "./Playbook.module.scss";
import { PlayModel } from "../../generated/from-api/models/play.model";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Tooltip from "../../components/Tooltip/Tooltip";

interface Props {
  play: PlayModel;
  noLink?: boolean;
  isPlaySetEditModal?: boolean;
}

const PlaybookTags: React.FC<Props> = ({
  play,
  noLink = false,
  isPlaySetEditModal = false,
}) => {
  const rowRef: any = useRef(null);
  const [tags, setTags] = useState<string[]>([]);
  const [hiddenTags, setHiddenTags] = useState<string[]>([]);
  useEffect(() => {
    const context = document?.createElement("canvas")?.getContext("2d");
    const font = "400 11pt sans-serif"; // Approximate font size to compare against
    if (context) context.font = font;
    const measureLength = (plays: string[]) => {
      if (!context) return 9999; // If for some reason that didn't initialize just return a large value
      const str = plays.join("  -  "); // Approximate the spacing between elements
      const text = context.measureText(str);
      return text.width;
    };
    const playTags: string[] = [];
    if (play.scheme) playTags.push(play.scheme);
    if (play.situation) playTags.push(play.situation);
    if (play.personnel) playTags.push(play.personnel);
    playTags.sort((a, b) => a.length - b.length);
    let rowLength = 200; // Default to 200px
    if (rowRef?.current) {
      rowLength = rowRef?.current?.offsetWidth;
    }
    // Calculate which tags to hide, note that this won't recalculate
    // on window resize, though we'll avoid a performance hit this way.
    for (let i = 1; i < playTags.length; i++) {
      if (measureLength(playTags.slice(0, i + 1)) > rowLength - 24) {
        setTags(playTags.slice(0, i));
        setHiddenTags(playTags.slice(i, playTags.length));
        return;
      }
    }
    setTags(playTags);
  }, []);
  const mapTags = () => {
    return (
      <>
        {tags.map((tag) => (
          <div key={tag} className={styles.tag}>
            {tag}
          </div>
        ))}
        {hiddenTags?.length > 0 && (
          <div key={"hiddenTags"} className={styles.tag}>
            <Tooltip tip={hiddenTags.join(", ")}>
              {"+" + hiddenTags.length}
            </Tooltip>
          </div>
        )}
      </>
    );
  };
  return !noLink ? (
    <Link
      ref={rowRef}
      to={{
        pathname: `edit-play/${play.id}`,
        state: { isPlaySetEditModal: isPlaySetEditModal },
      }}
      // to={`edit-play/${play.id}`}
      className={styles.tagsRow}
    >
      {mapTags()}
    </Link>
  ) : (
    <div ref={rowRef} className={styles.tagsRow}>
      {mapTags()}
    </div>
  );
};

export default PlaybookTags;
