import React, { useEffect, useState } from "react";

import { PlayModel } from "../../../generated/from-api/models/play.model";
import { processDataUpdates } from "../utilities/DataSync/DataSync";

export const PlaybookContext = React.createContext<{
  currentPlaybook?: PlayModel[];
  globalPlaybook?: PlayModel[];
  gamifiedPlays: PlayModel[];
  setGamifiedPlays: (plays: PlayModel[]) => void;
  refreshPlay: (play: PlayModel) => void;
  addPlays: (play: PlayModel[]) => void;
  setCurrentPlaybook: (plays: PlayModel[]) => void;
  setGlobalPlaybook: (plays: PlayModel[]) => void;
  clearCurrentPlaybook: () => void;
  removePlay: (playId: string) => void;
  removePlays: (playIds: string[]) => void;
  playItemsMap: Record<string, PlayModel>;
  consumeUpdatesForCurrentPlaybook: (plays: PlayModel[]) => void;
}>({
  currentPlaybook: [],
  globalPlaybook: [],
  gamifiedPlays: [],
  setGamifiedPlays: () => null,
  refreshPlay: () => null,
  addPlays: () => null,
  setCurrentPlaybook: () => null,
  setGlobalPlaybook: () => null,
  clearCurrentPlaybook: () => null,
  removePlay: () => null,
  removePlays: () => null,
  playItemsMap: {},
  consumeUpdatesForCurrentPlaybook: () => null,
});

export const PlaybookProvider: React.FC = ({ children }) => {
  const [currentPlaybook, setCurrentPlaybook] = useState<
    PlayModel[] | undefined
  >(undefined);
  const [globalPlaybook, setGlobalPlaybook] = useState<PlayModel[] | undefined>(
    undefined
  );

  const [playItemsMap, setPlayItemsMap] = useState<Record<string, PlayModel>>(
    {}
  );

  const [gamifiedPlays, setGamifiedPlays] = useState<PlayModel[]>([]);

  useEffect(() => {
    const plays: PlayModel[] = [];
    if (currentPlaybook) plays.push(...currentPlaybook);
    if (globalPlaybook) plays.push(...globalPlaybook);
    if (plays.length === 0) return;
    const nextPlayItemsMap: Record<string, PlayModel> = {};
    for (const play of plays) {
      if (play.id) {
        nextPlayItemsMap[play.id] = play;
      }
    }
    setPlayItemsMap(nextPlayItemsMap);
  }, [currentPlaybook, globalPlaybook]);

  const refreshPlay = (playToRefresh: PlayModel) => {
    const nextCurrentPlaybook =
      currentPlaybook &&
      currentPlaybook.map((play) => {
        if (playToRefresh.id !== play.id) {
          return play;
        }

        return playToRefresh;
      });

    if (nextCurrentPlaybook && nextCurrentPlaybook.length > 0) {
      setCurrentPlaybook(nextCurrentPlaybook);
    }
  };

  const addPlays = (playsToAdd: PlayModel[]) => {
    const currentPlaybookCopy = currentPlaybook ? [...currentPlaybook] : [];
    const nextCurrentPlaybook = [...currentPlaybookCopy, ...playsToAdd];
    setCurrentPlaybook(nextCurrentPlaybook);
  };

  const clearCurrentPlaybook = () => {
    setCurrentPlaybook([]);
    setGamifiedPlays([]);
  };

  const removePlay = (playId: string) => {
    const playToDeleteIndex =
      currentPlaybook &&
      currentPlaybook.findIndex((play) => play.id === playId);
    const currentPlaybookCopy = currentPlaybook ? [...currentPlaybook] : [];
    const nextCurrentPlaybook = [...currentPlaybookCopy];
    if (playToDeleteIndex !== undefined && playToDeleteIndex >= 0) {
      nextCurrentPlaybook.splice(playToDeleteIndex, 1);
    }
    setCurrentPlaybook(nextCurrentPlaybook);
  };

  const removePlays = (playIds: string[]) => {
    const currentPlaybookCopy = currentPlaybook ? [...currentPlaybook] : [];
    const nextCurrentPlaybook = currentPlaybookCopy.filter(
      (play) => play.id && !playIds.includes(play.id)
    );
    setCurrentPlaybook(nextCurrentPlaybook);
  };

  const consumeUpdatesForCurrentPlaybook = (updates: PlayModel[]) => {
    const newDataSet = processDataUpdates(updates, currentPlaybook);
    setCurrentPlaybook(newDataSet);
  };

  return (
    <PlaybookContext.Provider
      value={{
        currentPlaybook,
        globalPlaybook,
        refreshPlay,
        addPlays,
        setCurrentPlaybook,
        setGlobalPlaybook,
        clearCurrentPlaybook,
        removePlay,
        removePlays,
        consumeUpdatesForCurrentPlaybook,
        playItemsMap,
        gamifiedPlays,
        setGamifiedPlays,
      }}
    >
      {children}
    </PlaybookContext.Provider>
  );
};
