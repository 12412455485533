import React, { useContext, useState, useEffect } from "react";
import styles from "./InstallSlide.module.scss";
import Switch from "../../../../components/Switch/Switch";
import textIcon from "../../../../resources/images/text.svg";
import mediaIcon from "../../../../resources/images/media.svg";
import playIcon from "../../../../resources/images/play.svg";
import MediaGallery from "../../../../components/MediaGallery/MediaGallery";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import { MediaModel } from "../../../../generated/from-api/models/media.model";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";
import { InstallSlideModel } from "../../../../generated/from-api/models/install/install-slide.model";
import MediaThumbnail from "../../../../components/media/MediaThumbnail/MediaThumbnail";
import { MediaContext } from "../../../../shared/shared-with-mobile/providers/media.provider";
import Wysiwyg from "../../../../components/Wysiwyg/Wysiwyg";
import { RawDraftContentState } from "draft-js";
import PlaysGallery from "../../../../components/PlaysGallery/PlaysGallery";
import { PlayModel } from "../../../../generated/from-api/models/play.model";
import { PlaybookContext } from "../../../../shared/shared-with-mobile/providers/playbook.provider";
import PlayThumbnail from "../../../../components/drawing/PlayThumbnail";
import InstallSlideControls from "../InstallSlideControls/InstallSlideControls";
import { DraggableProvided } from "react-beautiful-dnd";
import DragIcon from "../../../../resources/icons/DragIcon";
import { QuizzesContext } from "../../../../shared/shared-with-mobile/providers/quizzes.provider";
import PencilIcon from "../../../../resources/icons/PencilIcon";
import CloseIcon from "../../../../resources/icons/CloseIcon";
import { CustomQuizQuestionModel } from "../../../../generated/from-api/models/custom-quiz/custom-quiz-question.model";
import { EditorTextAlignment } from "../../../../../../api/src/shared-with-mobile-and-web/models/install/slide-element.model";
import PlusIcon from "../../../../resources/icons/PlusIcon";
import TrashIcon from "../../../../resources/icons/TrashIcon";
import Button from "../../../../components/Button/Button";

interface Props {
  slide?: InstallSlideModel;
  slideIndex: number;
  updateTextHandler: (
    editorData: RawDraftContentState | null,
    textAlignment: EditorTextAlignment,
    slideIndex: number,
    slideSectionIndex: number
  ) => void;
  updateMediaHandler: (
    media: MediaModel,
    slideIndex: number,
    slideSectionIndex?: number
  ) => void;
  updateNarrationHandler: (media: MediaModel, slideIndex: number) => void;
  updatePlayHandler: (
    play: PlayModel,
    slideIndex: number,
    slideSectionIndex?: number
  ) => void;
  updateQuizHandler: (
    slideIndex: number,
    activeTab?: "flashcard" | "question",
    currentQuestionObject?: CustomQuizQuestionModel
  ) => void;
  deleteSlideHandler: (slideIndex: number) => void;
  deleteNarrationHandler: (slideIndex: number) => void;
  deleteSlideSectionHandler: (
    slideIndex: number,
    slideSectionIndex: number
  ) => void;
  moveSlideSectionHandler: (
    direction: "up" | "down",
    slideIndex: number,
    slideSectionIndex: number
  ) => void;
  provided: DraggableProvided;
  onFocus?: (slideIndex: number) => void;
}

const InstallSlide: React.FC<Props> = ({
  slide,
  slideIndex,
  updateTextHandler,
  updateMediaHandler,
  updateNarrationHandler,
  updatePlayHandler,
  updateQuizHandler,
  deleteSlideHandler,
  deleteNarrationHandler,
  deleteSlideSectionHandler,
  moveSlideSectionHandler,
  provided,
  onFocus,
}) => {
  const { mediaItemsMap } = useContext(MediaContext);
  const { playItemsMap } = useContext(PlaybookContext);
  const { quizItemsMap } = useContext(QuizzesContext);
  const { dispatchModal, closeModal } = useContext(UIContext);

  const [isSlideNarration, setSlideNarration] = useState<boolean>(false);
  const [isSectionDropdownOpen, setSectionDropdownState] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (slide?.narrationMediaId) {
      setSlideNarration(true);
    }
  }, [slide]);

  const isSlideElements =
    !slide?.question && !slide?.customQuizId && slide?.slideElements;
  const isSlideQuiz = !!slide?.customQuizId;
  const isSlideCustomQuestion = !!slide?.question;

  const handleSlideNarrationSwitch = () => {
    setSlideNarration(!isSlideNarration);
  };

  const openMediaGalleryModal = (slideSectionIndex?: number) => {
    dispatchModal({
      open: true,
      className: styles.mediaGallerySetupModal,
      body: (
        <MediaGallery
          type={"Modal"}
          allowBulkSelect={false}
          handleAddMedia={(media: MediaModel[]) => {
            updateMediaHandler(media[0], slideIndex, slideSectionIndex);
          }}
          addMediaButtonText="Add Media to Slide"
        />
      ),
    });
  };

  const openPlaysModal = (slideSectionIndex?: number) => {
    dispatchModal({
      className: styles.InstallSlidePlaysModal,
      open: true,
      size: "large",
      body: (
        <PlaysGallery
          addPlayCallback={(play: PlayModel) => {
            updatePlayHandler(play, slideIndex, slideSectionIndex);
            closeModal();
          }}
          options={{ draftPlays: true }}
        />
      ),
    });
  };

  return (
    <div
      className={styles.InstallSlide}
      onFocus={() => onFocus && onFocus(slideIndex)}
    >
      <div className={styles.InstallSlideHeader}>
        <div className={styles.InstallSlideHeaderLeftSide}>
          <div
            className={styles.InstallSlideDragButton}
            onClick={(e) => e.preventDefault()}
            {...provided.dragHandleProps}
          >
            <DragIcon />
          </div>
          <span className={styles.InstallSlideHeaderTitle}>
            Slide {slideIndex + 1}
          </span>
        </div>
        <div className={styles.InstallSlideHeaderRightSide}>
          <div className={styles.InstallSlideNarrationSwitchWrapper}>
            <Switch
              theme={"yellow"}
              toggled={isSlideNarration}
              onToggle={handleSlideNarrationSwitch}
              disabled={!isSlideElements}
            />
            <span
              className={`${styles.InstallSlideNarrationSwitchLabel} ${
                !isSlideElements &&
                styles.InstallSlideNarrationSwitchLabelDisabled
              }`}
            >
              Slide Narration
            </span>
          </div>
          <Button
            theme={"transparent"}
            size={"small"}
            icon={<TrashIcon />}
            onClick={() => deleteSlideHandler(slideIndex)}
          />
        </div>
      </div>

      <div className={styles.InstallSlideBody}>
        {isSlideNarration && (
          <div className={styles.InstallSlideAudioPlayerWrapper}>
            <AudioPlayer
              mediaId={slide?.narrationMediaId}
              slideId={slide?.id}
              updateHandler={(mediaModel) => {
                updateNarrationHandler(mediaModel, slideIndex);
              }}
              deleteNarrationHandler={() => {
                deleteNarrationHandler(slideIndex);
              }}
            />
          </div>
        )}

        {/* Slide Type Elements */}
        {isSlideElements && (
          <>
            {slide?.slideElements && slide.slideElements.length === 0 && (
              <div className={styles.InstallSlideMediaSelectWrapper}>
                <div className={styles.InstallSlideMediaSelectButtonWrapper}>
                  <button
                    className={styles.InstallSlideMediaSelectButton}
                    type="button"
                    onClick={() =>
                      updateTextHandler(
                        null,
                        "left",
                        slideIndex,
                        slide?.slideElements ? slide.slideElements.length : 0
                      )
                    }
                  >
                    <img
                      className={styles.InstallSlideMediaSelectButtonIcon}
                      src={textIcon}
                      alt="Add Text"
                    />
                    <span className={styles.InstallSlideMediaSelectButtonText}>
                      Text
                    </span>
                  </button>
                </div>
                <div className={styles.InstallSlideMediaSelectButtonWrapper}>
                  <button
                    className={styles.InstallSlideMediaSelectButton}
                    type="button"
                    onClick={() => openMediaGalleryModal()}
                  >
                    <img
                      className={styles.InstallSlideMediaSelectButtonIcon}
                      src={mediaIcon}
                      alt="Add Media"
                    />
                    <span className={styles.InstallSlideMediaSelectButtonText}>
                      Media
                    </span>
                  </button>
                </div>
                <div className={styles.InstallSlideMediaSelectButtonWrapper}>
                  <button
                    className={styles.InstallSlideMediaSelectButton}
                    type="button"
                    onClick={() => openPlaysModal()}
                  >
                    <img
                      className={styles.InstallSlideMediaSelectButtonIcon}
                      src={playIcon}
                      alt="Add Play"
                    />
                    <span className={styles.InstallSlideMediaSelectButtonText}>
                      Play
                    </span>
                  </button>
                </div>
              </div>
            )}

            {slide?.slideElements &&
              slide.slideElements.length > 0 &&
              slide.slideElements.map((slideElement, slideSectionIndex) => (
                <div
                  className={styles.InstallSlideUploadedMediaContainer}
                  key={slideSectionIndex}
                >
                  <div className={styles.InstallSlideUploadedMediaWrapper}>
                    {slideElement &&
                      slideElement.type === "MEDIA" &&
                      slideElement.mediaId && (
                        <div className={styles.InstallSlideUploadedMedia}>
                          <MediaThumbnail
                            mediaItem={mediaItemsMap[slideElement.mediaId]}
                          />
                        </div>
                      )}

                    {slideElement &&
                      slideElement.type === "PLAY" &&
                      slideElement.playId && (
                        <div className={styles.InstallSlideUploadedPlay}>
                          <div className={styles.InstallSlidePlayCard}>
                            <div
                              className={
                                styles.InstallSlidePlayCardThumbnailWrapper
                              }
                            >
                              <PlayThumbnail
                                play={playItemsMap[slideElement.playId]}
                              />
                            </div>
                            <div
                              className={
                                styles.InstallSlidePlayCardTitleWrapper
                              }
                            >
                              <span
                                className={styles.InstallSlidePlayCardTitle}
                              >
                                {playItemsMap[slideElement.playId]?.name || ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                    {slideElement && slideElement.type === "TEXT" && (
                      <div className={styles.InstallSlideUploadedText}>
                        <Wysiwyg
                          editorData={{
                            content: slideElement.textValue!.data,
                            textAlignment: slideElement.textValue!
                              .textAlignment,
                          }}
                          onContentUpdate={(
                            data: RawDraftContentState,
                            textAlignment: EditorTextAlignment
                          ) => {
                            updateTextHandler(
                              data,
                              textAlignment,
                              slideIndex,
                              slideSectionIndex
                            );
                          }}
                          placeholderValue={"Write content here..."}
                        />
                      </div>
                    )}
                  </div>
                  <InstallSlideControls
                    slideType={slideElement.type}
                    sectionsCount={slide.slideElements!.length}
                    isSlideSectionFirst={slideSectionIndex === 0}
                    isSlideSectionLast={
                      slideSectionIndex === slide.slideElements!.length - 1
                    }
                    onMoveSlideSection={(direction) =>
                      moveSlideSectionHandler(
                        direction,
                        slideIndex,
                        slideSectionIndex
                      )
                    }
                    onEditCallback={() => {
                      if (slideElement.type === "MEDIA") {
                        openMediaGalleryModal(slideSectionIndex);
                      } else if (slideElement.type === "PLAY") {
                        openPlaysModal(slideSectionIndex);
                      }
                    }}
                    onDeleteCallback={() =>
                      deleteSlideSectionHandler(slideIndex, slideSectionIndex)
                    }
                  />
                </div>
              ))}
          </>
        )}

        {/* Slide Type Quiz */}
        {isSlideQuiz && slide && slide.customQuizId && (
          <div className={styles.InstallSlideQuizCard}>
            <div className={styles.InstallSlideQuizCardTitle}>
              <span className={styles.InstallSlideQuizCardTitleLine1}>
                Quiz
              </span>
              <span className={styles.InstallSlideQuizCardTitleLine2}>
                {quizItemsMap[slide.customQuizId]?.name || ""}
              </span>
            </div>
            <div className={styles.InstallSlideQuizCardButtons}>
              <button
                className={styles.InstallSlideQuizCardButton}
                type="button"
                onClick={() => updateQuizHandler(slideIndex)}
              >
                <PencilIcon />
              </button>
              <button
                className={styles.InstallSlideQuizCardButton}
                type="button"
                onClick={() => deleteSlideHandler(slideIndex)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        )}

        {/* Slide Type Custom Question */}
        {isSlideCustomQuestion && slide && slide.question && (
          <div className={styles.InstallSlideQuestionCard}>
            <div className={styles.InstallSlideQuestionCardPreTitle}>
              Question
            </div>
            <div className={styles.InstallSlideQuestionCardTitleWrapper}>
              <div className={styles.InstallSlideQuestionCardTitle}>
                {slide.question.questionMediaId && (
                  <div className={styles.InstallSlideQuestionCardTitleImage}>
                    <MediaThumbnail
                      mediaItem={mediaItemsMap[slide.question.questionMediaId]}
                    />
                  </div>
                )}
                <span>{slide.question.question}</span>
              </div>
              <div className={styles.InstallSlideQuestionCardButtonsWrapper}>
                <button
                  className={styles.InstallSlideQuizCardButton}
                  type="button"
                  onClick={() =>
                    updateQuizHandler(slideIndex, "question", slide.question)
                  }
                >
                  <PencilIcon />
                </button>
                <button
                  className={styles.InstallSlideQuizCardButton}
                  type="button"
                  onClick={() => deleteSlideHandler(slideIndex)}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            <div className={styles.InstallSlideAnswersWrapper}>
              {slide.question.correctOptions &&
                slide.question.correctOptions
                  .filter((item) => !(item.optionType === "TEXT" && !item.text))
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.InstallSlideQuestionCardItem} ${
                        styles.InstallSlideQuestionCardItemCorrect
                      } ${
                        item.optionType === "TEXT"
                          ? styles.InstallSlideQuestionCardItemText
                          : styles.InstallSlideQuestionCardItemImage
                      }`}
                    >
                      {item.mediaId && (
                        <MediaThumbnail
                          className={
                            styles.InstallSlideQuestionCardItemThumbnailWrapper
                          }
                          mediaItem={mediaItemsMap[item.mediaId]}
                        />
                      )}
                      {item.text && <span>{item.text}</span>}
                    </div>
                  ))}

              {slide.question.incorrectOptions &&
                slide.question.incorrectOptions
                  .filter((item) => !(item.optionType === "TEXT" && !item.text))
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.InstallSlideQuestionCardItem} ${
                        item.optionType === "TEXT"
                          ? styles.InstallSlideQuestionCardItemText
                          : styles.InstallSlideQuestionCardItemImage
                      }`}
                    >
                      {item.mediaId && (
                        <MediaThumbnail
                          className={
                            styles.InstallSlideQuestionCardItemThumbnailWrapper
                          }
                          mediaItem={mediaItemsMap[item.mediaId]}
                        />
                      )}
                      {item.text && <span>{item.text}</span>}
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>

      {isSlideElements && (
        <div className={styles.InstallSlideFooter}>
          <div className={styles.InstallSlideAddSectionButtonWrapper}>
            <Button
              theme={"tertiary"}
              size={"small"}
              icon={<PlusIcon />}
              onClick={() => setSectionDropdownState(!isSectionDropdownOpen)}
              onBlur={() =>
                setTimeout(() => {
                  setSectionDropdownState(false);
                }, 150)
              }
            >
              Add Section
            </Button>
            {isSectionDropdownOpen && (
              <ul className={styles.InstallSlideAddSectionButtonDropdown}>
                <li className={styles.InstallSlideAddSectionButtonDropdownItem}>
                  <button
                    className={
                      styles.InstallSlideAddSectionButtonDropdownButton
                    }
                    type={"button"}
                    onClick={() => {
                      updateTextHandler(
                        null,
                        "left",
                        slideIndex,
                        slide?.slideElements ? slide.slideElements.length : 0
                      );
                    }}
                  >
                    <img
                      className={
                        styles.InstallSlideAddSectionButtonDropdownButtonImage
                      }
                      src={textIcon}
                      alt="Add Text"
                    />
                    <span
                      className={
                        styles.InstallSlideAddSectionButtonDropdownButtonText
                      }
                    >
                      Text
                    </span>
                  </button>
                </li>
                <li className={styles.InstallSlideAddSectionButtonDropdownItem}>
                  <button
                    className={
                      styles.InstallSlideAddSectionButtonDropdownButton
                    }
                    type={"button"}
                    onClick={() => openMediaGalleryModal()}
                  >
                    <img
                      className={
                        styles.InstallSlideAddSectionButtonDropdownButtonImage
                      }
                      src={mediaIcon}
                      alt="Add Media"
                    />
                    <span
                      className={
                        styles.InstallSlideAddSectionButtonDropdownButtonText
                      }
                    >
                      Media
                    </span>
                  </button>
                </li>
                <li className={styles.InstallSlideAddSectionButtonDropdownItem}>
                  <button
                    className={
                      styles.InstallSlideAddSectionButtonDropdownButton
                    }
                    type={"button"}
                    onClick={() => openPlaysModal()}
                  >
                    <img
                      className={
                        styles.InstallSlideAddSectionButtonDropdownButtonImage
                      }
                      src={playIcon}
                      alt="Add Play"
                    />
                    <span
                      className={
                        styles.InstallSlideAddSectionButtonDropdownButtonText
                      }
                    >
                      Play
                    </span>
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InstallSlide;
