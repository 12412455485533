import React, { useContext, useEffect, useState } from "react";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { MediaModel } from "../../generated/from-api/models/media.model";
import { validateFileSize, uploadFile } from "../../utils/mediaHelpers.utils";
import Recorder from "js-audio-recorder";
import WaveSurfer from "wavesurfer.js";

import styles from "./AudioPlayer.module.scss";

import MicrophoneIcon from "../../resources/icons/MicrophoneIcon";
import PlayArrowIcon from "../../resources/icons/PlayArrowIcon";
import PauseIcon from "../../resources/icons/Pause";
import TrashIcon from "../../resources/icons/TrashIcon";
import MediaIcon from "../../resources/icons/MediaIcon";
import MediaGallery from "../MediaGallery/MediaGallery";
import { MediaContext } from "../../shared/shared-with-mobile/providers/media.provider";
import AudioPlayerCustomRender from "./AudioPlayerCustomRender";

interface AudioPlayerProps {
  updateHandler?: (mediaModel: MediaModel) => void;
  mediaId?: string;
  slideId?: string;
  deleteNarrationHandler?: () => void;
  listenOnly?: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  updateHandler,
  mediaId,
  slideId,
  deleteNarrationHandler,
  listenOnly,
}) => {
  const { dispatchToast, dispatchModal } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);
  const {
    addMediaItems,
    mediaItems,
    removeMediaItems,
    mediaItemsMap,
  } = useContext(MediaContext);
  const emptyWaveform =
    "data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA==";

  const [isRecording, setIsRecording] = useState(false);
  const [recordingBlocked, setRecordingBlocked] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackBlocked, setPlaybackBlocked] = useState(false);

  const [audioTime, setAudioTime] = useState(0);
  const [audioLength, setAudioLength] = useState(0);
  const [recordingPeak, setRecordingPeak] = useState(0.0);
  const [recordingVolumeData, setRecordingVolumeData] = useState([0.0]);
  const [waveSteps, setWaveSteps] = useState(255);

  const [recordedEncodedUri, setRecordedEncodedUri] = useState(emptyWaveform);
  const [recordedFile, setRecordedFile] = useState(
    new File([], `default placeholder recording.wav`, { type: "audio/wav" })
  );
  const [waveform, setWaveform] = useState<WaveSurfer>();

  const [waveformEl] = useState<string>(
    `waveform-el-slide-${slideId || "undefined"}`
  );
  const [canSave, setCanSave] = useState<boolean>(false);
  const [audioCleared, setAudioCleared] = useState<boolean>(false);

  useEffect(() => {
    document.querySelector(`#${waveformEl}`)!.innerHTML = "";
    const waveformCreated = WaveSurfer.create({
      container: document.querySelector(`#${waveformEl}`) as HTMLElement,
      backend: "WebAudio",
      renderer: AudioPlayerCustomRender as any,
      barWidth: 2,
      barGap: 2,
      barRadius: 2,
      barMinHeight: 1,
      height: 20,
      cursorWidth: 0,
      cursorColor: "transparent",
      interact: false,
      normalize: true,
      progressColor: "#ffea2d", // #ffbc17
      waveColor: "#ababb7",
      responsive: true,
      hideScrollbar: true,
    });
    setWaveform(waveformCreated);
    return () => {
      waveformCreated?.pause();
      waveformCreated?.destroy();
    };
  }, []);

  useEffect(() => {
    if (recordedEncodedUri && waveform) {
      waveform.load(recordedEncodedUri as string);
      waveform.on("ready", () => {
        setAudioTime(0);
        setAudioLength(waveform.getDuration());
        setRecordingVolumeData([0.0]);
      });
    }
  }, [recordedEncodedUri]);

  useEffect(() => {
    if (mediaId && mediaItems && !audioCleared) {
      if (audioLength < 0.002) setAudioLength(0.001);
      const correctMediaWithAudio = mediaItemsMap[mediaId];
      const savedAudio = correctMediaWithAudio && correctMediaWithAudio.url;
      if (savedAudio) {
        setRecordedEncodedUri(savedAudio as string);
      }
    }
  }, [mediaId, slideId, waveform, mediaItemsMap]);

  // There are times we need to access the audio element directly, so we need an id to do that.
  const elementId =
    `audio-player-` +
    (Date.now().toString(36) + Math.random().toString(36)).replace(
      /[^a-zA-Z]/g,
      ""
    );
  // Media Gallery Modal Managment
  const openMediaGalleryModal = () => {
    setAudioCleared(false);
    dispatchModal({
      open: true,
      size: "large",
      body: (
        <MediaGallery
          type={"Modal"}
          defaultFilters={["AUDIO"]}
          handleAddMedia={(media: MediaModel[]) => {
            if (media.length !== 1 || media[0].type !== "AUDIO") {
              dispatchToast({
                type: "error",
                message: `Narration must be a single audio file.`,
              });
              return false;
            }

            // Reset for the selected audio (if it is indeed audio);
            const audioDetailGetter = new Audio();
            audioDetailGetter.onloadedmetadata = () => {
              setAudioTime(0.0);
              setAudioLength(audioDetailGetter.duration);
              setRecordingVolumeData([0.0]);
            };
            waveform?.load(media[0].url as string);
            setRecordedEncodedUri(media[0].url as string);
            if (updateHandler !== undefined) updateHandler(media[0]);
            setCanSave(false);
          }}
          addMediaButtonText="Add Audio to Slide"
        />
      ),
    });
  };

  // Start the recorder instance and bind the additional events needed.
  const startRecording = async () => {
    setAudioCleared(false);
    setPlaybackBlocked(true);
    const recorder = new Recorder();
    let thisRecordVolumes: number[] = [];
    setRecordingPeak(0.0);

    // Update voice data and duration for the UI
    recorder.onprogress = (params) => {
      thisRecordVolumes.push(params.vol);
      thisRecordVolumes = thisRecordVolumes.slice(0, thisRecordVolumes.length);
      setAudioTime(params.duration);
      setRecordingPeak(
        Math.max(recordingPeak * 0.5, ...thisRecordVolumes.slice(0, waveSteps))
      );
      setRecordingVolumeData(thisRecordVolumes);
    };

    // The stop action, bound after definition
    const stopRecording = (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      // Update the duration, just to make sure
      setAudioTime(0.0);
      setAudioLength(recorder.duration);
      setPlaybackBlocked(false);

      // Officially stop recording, save the blob
      recorder.stop();
      setRecordedFile(
        new File(
          [recorder.getWAVBlob()],
          `recording-from-${new Date().toLocaleString("en-us", {
            dateStyle: "medium",
            timeStyle: "short",
          })}.wav`,
          { type: "audio/wav" }
        )
      );

      // Shuttle the recording into the player
      const fr = new FileReader();
      fr.onload = function (e) {
        setRecordedEncodedUri(e?.target?.result + "");
      };
      fr.readAsDataURL(recorder.getWAVBlob());

      // Remove the stop button binding
      document
        .getElementById("stopRecording")
        ?.removeEventListener("click", stopRecording);
      setIsRecording(false);

      // Allow saving
      setCanSave(true);
      recorder.destroy();
    };
    document
      .getElementById("stopRecording")
      ?.addEventListener("click", stopRecording);

    // Update state and start recording
    setIsRecording(true);
    recorder.start().then(
      () => {
        document
          .getElementById("stopRecording")
          ?.addEventListener("click", stopRecording);
      },
      (err) => {
        console.log("There was an error initializing the audio recorder", err);
      }
    );
  };

  // Toggles the play state, resetting to the start when you hit play regardless
  const playPauseRecording = () => {
    if (isPlaying) {
      setIsPlaying(false);
      setRecordingBlocked(false);
      waveform?.pause();
      waveform?.un("finish", handlePlaybackEnd);
      waveform?.un("audioprocess", handlePlaybackProgress);
    } else if (audioLength > 0) {
      setIsPlaying(true);
      setRecordingBlocked(true);
      waveform?.play(0);
      waveform?.on("finish", handlePlaybackEnd);
      waveform?.on("audioprocess", handlePlaybackProgress);
    }
  };
  const handlePlaybackProgress = () => {
    if (typeof waveform !== "undefined") {
      setAudioTime(waveform.getCurrentTime());
    }
  };
  const handlePlaybackEnd = () => {
    setIsPlaying(false);
    setRecordingBlocked(false);
  };

  // The number of lines is dependent on the width of the area, lets calculate that
  const getWaveLines = () => {
    const waveSpace = document.querySelector(
      `#${elementId} .${styles.AudioPlayerRecordBarWrapper}, #${elementId} .${styles.AudioPlayerSeekBarWrapper}`
    );
    const waveSpaceSize = waveSpace?.clientWidth;
    if (waveSpaceSize !== undefined && waveSpaceSize > 0) {
      const newWaveCount = Math.floor(waveSpaceSize / 4);
      setWaveSteps(newWaveCount);
    }
  };
  if (typeof window !== "undefined") {
    window.onresize = getWaveLines;
  }
  useEffect(() => {
    getWaveLines();
  }, [isRecording]);

  // This function will render the wave steps out, it also controls when they turn yellow
  const renderLines = () => {
    const waves: any[] = new Array(waveSteps);
    // If we are recording we want to fill all empty spaces with empty points and always pull the latest info from stack
    if (isRecording) {
      for (
        let i = recordingVolumeData.length - waves.length;
        i < recordingVolumeData.length;
        i++
      ) {
        if (recordingVolumeData[i] === undefined) {
          waves[i < 0 ? 255 + i : i] = (
            <span key={`audio-recorder-animation-blank-${i}`}></span>
          );
        } else if (isRecording) {
          const height = Math.sqrt(
            Math.abs(recordingVolumeData[i]) / Math.abs(recordingPeak)
          );
          waves[i] = (
            <span
              key={`audio-recorder-animation-line-${i}`}
              className={"recorded"}
              style={{
                height: height * 100 + "%",
              }}
            ></span>
          );
        }
      }

      // If we are not recording we need to adjust the bar to the total length of the data, compressing or expanding as required.
    } else {
      for (let i = 0; i < waves.length; i++) {
        const adjustedI = Math.round(
          (recordingVolumeData.length / waveSteps) * i
        );
        waves[i] = (
          <span
            key={`audio-recorder-animation-line-${i}`}
            className={
              i < Math.round((audioTime / audioLength) * waveSteps)
                ? "recorded"
                : "unrecorded"
            }
            style={{
              height:
                (recordingVolumeData[adjustedI] / recordingPeak) * 100 + "%",
            }}
          ></span>
        );
      }
    }
    return waves;
  };

  const clearRecording = () => {
    setAudioCleared(true);
    setAudioTime(0);
    setAudioLength(0);
    setRecordingPeak(0.0);
    setRecordingVolumeData([0.0]);
    setRecordedEncodedUri(emptyWaveform);
    if (mediaId) removeMediaItems([mediaId]);
    if (deleteNarrationHandler) {
      deleteNarrationHandler();
    }
    setCanSave(false);
  };

  const saveRecording = async () => {
    const obj = validateFileSize(recordedFile);
    if (!obj.isValid) {
      dispatchToast({
        type: "error",
        message: obj.errorMessage,
      });
      return;
    }

    const uploadedRecording = await uploadFile(recordedFile, currentTeam?.id);
    if (uploadedRecording !== undefined) {
      addMediaItems([uploadedRecording]);
      if (updateHandler !== undefined) {
        updateHandler(uploadedRecording);
      }
      dispatchToast({
        type: "success",
        message: "Recording Saved.",
      });
    }
    setCanSave(false);
    return uploadedRecording;
  };

  // Time is stored as seconds in a float, this will convert that into a pretty string
  const formatAudioTime = (time: number) => {
    const timeInSec = Math.round(time);
    const minutes = Math.floor(timeInSec / 60);
    const seconds = (timeInSec % 60).toString().padStart(2, "0");
    return minutes + ":" + seconds;
  };

  // The element itself
  return (
    <>
      <div className={styles.AudioPlayer} id={elementId}>
        <div className={styles.AudioPlayerTitleWrapper}>
          <div className={styles.AudioPlayerTitle}>Slide Narration</div>
        </div>
        {!listenOnly && (
          <div className={styles.AudioPlayerRecordButtonWrapper}>
            {isRecording ? (
              <button
                className={styles.AudioPlayerRecordButton}
                type={"button"}
                id="stopRecording"
              >
                <PauseIcon />
              </button>
            ) : (
              <button
                className={styles.AudioPlayerRecordButton}
                type={"button"}
                onClick={startRecording}
                disabled={recordingBlocked}
              >
                <MicrophoneIcon />
              </button>
            )}
          </div>
        )}
        <div className={styles.AudioPlayerPlayButtonWrapper}>
          <button
            className={styles.AudioPlayerPlayButton}
            type={"button"}
            onClick={playPauseRecording}
            disabled={playbackBlocked}
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </button>
        </div>

        <div
          className={styles.AudioPlayerRecordBarWrapper}
          style={{ display: isRecording ? "block" : "none" }}
        >
          {renderLines()}
        </div>
        <div
          className={styles.AudioPlayerSeekBarWrapper}
          style={{
            display: !isRecording && audioLength > 0 ? "block" : "none",
          }}
        >
          <div id={waveformEl}></div>
        </div>
        <div
          className={styles.AudioPlayerSeekBarWrapper}
          style={{
            display: !isRecording && audioLength <= 0 ? "block" : "none",
          }}
        >
          <hr />
        </div>

        <div className={styles.AudioPlayerTimeWrapper}>
          <div className={styles.AudioPlayerTime}>
            {formatAudioTime(
              isRecording || isPlaying ? audioTime : audioLength
            )}
          </div>
        </div>
        {!listenOnly && (
          <div className={styles.AudioPlayerFileControlsWrapper}>
            <button
              className={`${styles.AudioPlayerDeleteButton} ${
                isRecording ? styles.Recording : ""
              }`}
              type={"button"}
              onClick={clearRecording}
              disabled={isRecording}
            >
              <TrashIcon />
            </button>
            <button
              className={`${styles.AudioPlayerSaveButton} ${
                isRecording || !canSave ? styles.Recording : ""
              }`}
              type={"button"}
              onClick={() => {
                setTimeout(() => {
                  saveRecording();
                }, 50);
              }}
              disabled={isRecording || !canSave}
            >
              Save
            </button>
            <button
              className={`${styles.AudioPlayerMediaGalleryButton} ${
                isRecording ? styles.Recording : ""
              }`}
              type={"button"}
              onClick={openMediaGalleryModal}
              disabled={isRecording}
            >
              <MediaIcon />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default AudioPlayer;
