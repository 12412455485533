import * as React from "react";

const FileUploadIcon: React.FC = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 17.167V11.167H19.5L12.5 4.16699L5.5 11.167H9.5V17.167H15.5ZM12.5 6.99699L14.67 9.16699H13.5V15.167H11.5V9.16699H10.33L12.5 6.99699ZM19.5 21.167V19.167H5.5V21.167H19.5Z"
        fill="#ABABB7"
      />
    </g>
  </svg>
);

export default FileUploadIcon;
