import React, { useContext, useEffect, useState } from "react";
import CharacterInput from "../../../components/Input/CharacterInput";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { DeleteModal } from "./DeleteModal";
import { InvitationsContext } from "../../../shared/shared-with-mobile/providers/invitations.provider";
import { CoachesContext } from "../../../shared/shared-with-mobile/providers/coaches.provider";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { invitationTypes } from "../../../shared/shared-with-mobile/constants";
import { coachPositions, INPUT_TYPES } from "../../../utils/web-only-constants";
import { CoachOrInvitee, ROSTER_TYPE } from "./EditRoster";
import { CoachRole } from "../../../generated/from-api/models/enums/coach-role.enum";
import ContentLimitModal from "../../../components/ContentLimitModal/ContentLimitModal";
import Button from "../../../components/Button/Button";

interface FormElements extends HTMLFormControlsCollection {
  fName: HTMLInputElement;
  lName: HTMLInputElement;
  email: HTMLInputElement;
  role: HTMLInputElement;
}

interface Props {
  coach?: CoachOrInvitee;
}

const InviteCoach: React.FC<Props> = ({ coach }) => {
  const {
    closeModal,
    dispatchToast,
    dispatchSingleModal,
    handleCreateError,
  } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);
  const { addOrUpdateInvitation, removeInvitation } = useContext(
    InvitationsContext
  );
  const { addOrUpdateCoach, removeCoach } = useContext(CoachesContext);
  const { currentCoaches } = useContext(CoachesContext);

  const inviteOrEditCoach = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!currentTeam) {
      return;
    }

    const {
      fName: { value: firstName },
      lName: { value: lastName },
      email: { value: toEmail },
      role,
    } = (event.currentTarget as HTMLFormElement).elements as FormElements;

    const payload = {
      type: invitationTypes.COACH,
      firstName,
      lastName,
      toEmail,
      teamId: currentTeam.id as string,
      id: coach?.id,
      coachRole:
        role.value === "ASSISTANT"
          ? ("DEFAULT" as CoachRole)
          : (role.value as CoachRole),
    };

    try {
      if (coach && coach.type === ROSTER_TYPE.COACH) {
        const updatedCoach = await APIService.COACH.PUT(coach.id, {
          firstName: payload.firstName,
          lastName: payload.lastName,
          role: payload.coachRole,
        });
        addOrUpdateCoach(updatedCoach);
      } else {
        let newCoachInvitation;
        if (coach && coach.type === ROSTER_TYPE.INVITE) {
          newCoachInvitation = await APIService.INVITATION.PUT(payload);
        } else {
          newCoachInvitation = await APIService.INVITATION.POST(payload);
        }
        if (newCoachInvitation) {
          addOrUpdateInvitation(newCoachInvitation);
        }
      }
      closeModal();
    } catch (error) {
      handleCreateError(
        error,
        ContentLimitModal,
        "coach / coach invitation",
        "coaches / coach invitations"
      );
    }
  };

  const deleteCoachOrInvite = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (coach && coach.type === ROSTER_TYPE.COACH) {
        const deletedCoach = await APIService.COACH.DELETE(coach.id);
        if (deletedCoach.deleted) {
          removeCoach(coach.id);
        }
      } else if (coach && coach.type === ROSTER_TYPE.INVITE) {
        const deletedInvitation = await APIService.INVITATION.DELETE(coach.id);
        if (deletedInvitation) {
          removeInvitation(coach.id);
        }
      }
      closeModal();
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "There's been an error. Please try again.",
      });
    }
  };

  // Determine if we can allow this user to be an assistant coach
  const [headCoaches, setHeadCoaches] = useState<string[]>([]);
  useEffect(() => {
    const newHeadCoaches: string[] = [];
    if (currentCoaches) {
      currentCoaches.forEach((coach) => {
        if (coach.id && coach.role == "HEAD") {
          newHeadCoaches.push(coach.id);
        }
      });
      setHeadCoaches(newHeadCoaches);
    } else {
      setHeadCoaches([]);
    }
  }, [currentCoaches]);

  return (
    <form onSubmit={inviteOrEditCoach}>
      <CharacterInput
        type={INPUT_TYPES.TEXT}
        placeholder="First Name"
        id="fName"
        value={coach && coach.firstName}
        autofocus={true}
      />
      <CharacterInput
        type={INPUT_TYPES.TEXT}
        placeholder="Last Name"
        value={coach && coach.lastName}
        id="lName"
      />
      <CharacterInput
        type={INPUT_TYPES.EMAIL}
        id="email"
        placeholder="Email"
        value={coach && coach.toEmail}
        disabled={coach && coach.type === ROSTER_TYPE.INVITE}
      />
      <SingleSelect
        placeholder="Role"
        id="role"
        options={[...coachPositions].map((roleOption) => {
          let disabled = false;
          // we must disable the "DEFAULT/ASSISTANT" role option if this coach is the only head coach on the team
          if (roleOption === "DEFAULT") {
            const currentCoachIsTheOnlyHeadCoach =
              headCoaches.length == 1 &&
              !!headCoaches.find((coachId) => coachId == coach?.id);
            disabled = currentCoachIsTheOnlyHeadCoach;
          }
          return {
            label: roleOption === "DEFAULT" ? "ASSISTANT" : roleOption,
            value: roleOption,
            disabled: disabled,
          };
        })}
        value={coach?.role}
      />
      <div className="formFooter">
        {coach && (
          <Button
            theme={"tertiary"}
            size={"small"}
            onClick={() =>
              dispatchSingleModal({
                title: "Remove coach from team",
                size: "medium",
                body: (
                  <DeleteModal
                    type="coach"
                    onSubmit={(event) => deleteCoachOrInvite(event)}
                  />
                ),
                open: true,
              })
            }
            destructive
          >
            Delete
          </Button>
        )}
        <Button theme={"secondary"} size={"small"} onClick={closeModal}>
          Cancel
        </Button>
        <Button size={"small"} buttonType="submit">
          {coach ? "Edit" : "Invite"}
        </Button>
      </div>
    </form>
  );
};

export default InviteCoach;
