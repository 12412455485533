import { ResponsiveLine } from "@nivo/line";

interface Props {
  data: any;
  indexBy: string;
  valueKey: string;
  xLabel?: string;
  yLabel?: string;
  ySymbol?: string;
}

const LineChart: React.FC<Props> = ({
  xLabel = "",
  yLabel = "",
  ySymbol = "",
  data,
}) => {
  const theme = {
    textColor: "#ffffff",
    axis: {
      fontSize: "14px",
      tickColor: "#eee",
      ticks: {
        line: {
          stroke: "transparent",
        },
        text: {
          fill: "#7E7E85",
          fontSize: "13px",
          fontFamily: "QUANTICO",
        },
      },
    },
    grid: {
      line: {
        stroke: "#7E7E85",
        strokeWidth: 0.3,
      },
    },
  };

  // compute max yValue to help determine a reasonable number of y-axis ticks
  //   const yValues = data.map((datum: any) => Number(datum[valueKey]));
  //   const maxValue = Math.max(...yValues);

  // Nivo uses this as a general target for the number of y-axis tick marks
  // and chooses a nearby value that works with the data
  //   const yAxisNumTicks = maxValue > 10 ? Math.ceil(maxValue / 10) : 4;

  return (
    <ResponsiveLine
      data={data}
      margin={{
        top: 10,
        right: 30,
        bottom: 50,
        left: yLabel && ySymbol ? 65 : yLabel || ySymbol ? 50 : 30,
      }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      theme={theme}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 2,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 40,
        legend: xLabel,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 0,
        tickValues: 3,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -60,
        legend: yLabel,
        legendPosition: "middle",
        format: (value) => `${Number(value)} ${ySymbol}`,
      }}
      pointSize={5}
      colors={["#00b2ff"]}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      useMesh={false}
      enableGridX={false}
      gridYValues={2}
    />
  );
};

export default LineChart;
