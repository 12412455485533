import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { VersionModel } from "../../../../generated/from-api/models/version.model";
import { API_BASE_URL } from "../../constants";

export const VersionService = {
  /**
   * Use to get app version from backend
   */
  GET_APP_VERSION: async function (): Promise<VersionModel> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.VERSION}/get-version`))
      .data;
  },
};
