import React, { useContext, useRef } from "react";
import CharacterInput from "../../../components/Input/CharacterInput";
import FileUpload from "../../../components/FileUpload/FileUpload";
import debounce from "lodash/debounce";

import { INPUT_TYPES } from "../../../utils/web-only-constants";
import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";
import { UserProfileModel } from "../../../generated/from-api/models/user-profile.model";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import ChangePassword from "../account-pages/ChangePassword";

import camera_outlined from "../../../resources/images/camera_outlined.svg";
import backArrow from "../../../resources/images/arrowLeft.svg";

import styles from "../Account.module.scss";
import PhoneInput from "react-phone-input-2";

const AccountSettingsTab: React.FC = () => {
  const { dispatchToast, dispatchModal } = useContext(UIContext);
  const { userProfile, updateProfile } = useContext(UserContext);

  // Handle the mass of the field updates
  const updateAccountsSettings = async (event: any) => {
    const thisUpdatedField = event.target.name as string; // as keyof UserProfileModel;
    const thisUpdatedValue = event.target.value as any; // as string;
    const updatedUserProfile: UserProfileModel = {
      ...userProfile,
      [thisUpdatedField]: thisUpdatedValue,
    } as UserProfileModel;
    updateProfile(updatedUserProfile);
    updateAccountsSettingsDebounced.current(updatedUserProfile);
  };
  const updateAccountsSettingsDebounced = useRef(
    debounce(async (userProfile: UserProfileModel) => {
      const profile = await APIService.USER_PROFILE.PUT(
        userProfile.id,
        userProfile
      );
      updateProfile(profile as UserProfileModel);
      dispatchToast({
        type: "success",
        message: "Account settings updated.",
      });
    }, 500)
  );

  const handlePasswordClick = () => {
    dispatchModal({
      title: "Change your password",
      open: true,
      className: styles.changePasswordModal,
      body: <ChangePassword />,
    });
  };

  return (
    <div>
      <h1>Account Settings</h1>
      <fieldset className={styles.AccountSettingsFields}>
        <label htmlFor="picture">Picture</label>
        <FileUpload
          id="photo"
          icon={camera_outlined}
          helperText="UPLOAD"
          value={userProfile?.profileImageUrl || ""}
        />
        <label htmlFor="firstName">First Name</label>
        <CharacterInput
          type={INPUT_TYPES.TEXT}
          placeholder=""
          id="firstName"
          name="firstName"
          onChange={updateAccountsSettings}
          value={userProfile?.firstName}
          maxLength={255}
        />
        <label htmlFor="lastName">Last Name</label>
        <CharacterInput
          type={INPUT_TYPES.TEXT}
          placeholder=""
          id="lastName"
          name="lastName"
          onChange={updateAccountsSettings}
          value={userProfile?.lastName}
          maxLength={255}
        />
        <label htmlFor="email">Email</label>
        <CharacterInput
          disabled
          type={INPUT_TYPES.TEXT}
          placeholder=""
          id="email"
          name="email"
          onChange={updateAccountsSettings}
          value={userProfile?.email}
          maxLength={255}
        />
        {userProfile?.phoneNumber && (
          <>
            <label htmlFor="phone">Phone</label>
            <PhoneInput
              buttonStyle={{ height: "35px" }}
              disabled
              containerClass="phoneInputContainer"
              inputClass="phoneInput"
              buttonClass="phoneInputButton"
              dropdownClass="phoneInputDropdown"
              placeholder="+1234567890"
              value={userProfile?.phoneNumber}
              country={"us"}
            />
          </>
        )}
        <label htmlFor="password">Password</label>
        <CharacterInput
          type={INPUT_TYPES.PASSWORD}
          placeholder=""
          id="password"
          name="password"
          onFocus={handlePasswordClick}
          value={"*********"}
        />
        <a
          target="_blank"
          href="https://www.teamnationsports.com/privacy"
          className={styles.AccountSettingsPrivacyPolicyLink}
          rel="noreferrer"
        >
          Privacy Policy Link
          <img src={backArrow} />
        </a>
      </fieldset>
    </div>
  );
};

export default AccountSettingsTab;
