import CharacterInput from "../Input/CharacterInput";
import { DrawablePlayerModel } from "../../generated/from-api/models/drawable/drawablePlayer.model";
import { PlayModel } from "../../generated/from-api/models/play.model";
import styles from "./PlayNotes.module.scss";
import { sortPlayersComparator } from "../../shared/shared-with-mobile/utilities/SortPlayersComparator";

interface Props {
  play: Omit<PlayModel, "drawablePlayers">;
  setPlay: React.Dispatch<
    React.SetStateAction<Omit<PlayModel, "drawablePlayers">>
  >;
  players: DrawablePlayerModel[];
  setPlayers: (players: DrawablePlayerModel[]) => void;
  isMediaPlay?: boolean;
}

const PlayNotes: React.FC<Props> = ({
  play,
  setPlay,
  players,
  setPlayers,
  isMediaPlay,
}) => {
  const updateTeamNotes = (teamNotes: string) => {
    if (!play) {
      return;
    }

    setPlay({
      ...play,
      notes: teamNotes,
    });
  };

  const updatePlayerNotes = (
    playerNotes: string,
    playerToUpdate: DrawablePlayerModel
  ) => {
    const nextPlayers = players.map((player) => {
      if (player.id !== playerToUpdate.id) {
        return player;
      }

      return {
        ...player,
        notes: playerNotes,
      };
    });

    setPlayers(nextPlayers);
  };

  return (
    <div className={styles.playNotes}>
      <div
        className={styles.noteSection}
        style={isMediaPlay ? { width: "100%" } : {}}
      >
        <div className={styles.title}>Team Notes</div>
        <div className={styles.notesContainer}>
          <CharacterInput
            isTextArea
            maxLength={500}
            placeholder={
              !play.notes ? "Notes for the whole team..." : undefined
            }
            value={play.notes}
            onChange={(e) => {
              updateTeamNotes(e.target.value);
            }}
          />
        </div>
      </div>
      {!isMediaPlay && (
        <div className={styles.noteSection}>
          <div className={styles.title}>Position Notes</div>
          <div className={styles.notesContainer}>
            {players
              .filter(
                (player) =>
                  player.id !== "football" && player.playType === play.playType
              )
              .sort(sortPlayersComparator)
              .map((player) => (
                <div key={player.id} className={styles.playerNotes}>
                  <div
                    style={{ background: player.color }}
                    className={`${styles.playerRoleWrapper} ${
                      player.playType !== play.playType
                        ? styles.opponentPlayer
                        : ""
                    }`}
                  >
                    <div className={styles.playerRole}>
                      {player.role || player.position || ""}
                    </div>
                  </div>
                  <CharacterInput
                    maxLength={255}
                    placeholder="Player notes"
                    value={player.notes}
                    size="x-small"
                    onChange={(e) => {
                      updatePlayerNotes(e.target.value, player);
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayNotes;
