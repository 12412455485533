import styles from "../../../Dashboard.module.scss";
import CharacterInput from "../../../../../components/Input/CharacterInput";
import searchIcon from "../../../../../resources/images/search.svg";
import keyboard_arrow_right from "../../../../../resources/images/keyboard_arrow_right.svg";
import { INPUT_TYPES } from "../../../../../utils/web-only-constants";
import ToggleSelect from "../../../../../shared/shared-with-mobile/components/ToggleSelect/ToggleSelect";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReadinessScoresContext } from "../../../../../shared/shared-with-mobile/providers/readinessScores.provider";
import { PlayersContext } from "../../../../../shared/shared-with-mobile/providers/players.provider";
import { DashboardStatsGrid } from "../../../../../components/DashboardStatsGrid/DashboardStatsGrid";
import dashboardStatsStyles from "../../../../../components/DashboardStatsGrid/DashboardStatsGrid.module.scss";
import Avatar from "../../../../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import { isEmpty } from "lodash";
import ReadinessGraph from "../../../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/ReadinessGraph";
import {
  formatReadinessByUnitChartData,
  hasPositionType,
  PlayerRow,
  PositionFiltersMap,
} from "../../../../../shared/shared-with-mobile/functions";
import { getAbbreviatedDisplayName } from "../../../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import { BarDatum } from "@nivo/bar";
import { PlaybookContext } from "../../../../../shared/shared-with-mobile/providers/playbook.provider";
import { FormationsContext } from "../../../../../shared/shared-with-mobile/providers/formations.provider";
import { PlayModel } from "../../../../../generated/from-api/models/play.model";
import PlayThumbnail from "../../../../../components/drawing/PlayThumbnail";
import { PlayerModel } from "../../../../../generated/from-api/models/relationships/player.model";
import PlayerModal from "../../../Components/PlayerModal/PlayerModal";
import { FormationModel } from "../../../../../generated/from-api/models/drawable/formation.model";

interface Props {
  playId?: string;
  formationId?: string;
  close: () => void;
}

const PlayPlaybook: React.FC<Props> = ({ playId, close, formationId }) => {
  const chartDefaultDataForUnit: BarDatum[] = [
    { category: "Offense", averageScore: 0 },
    { category: "Defense", averageScore: 0 },
    { category: "S.T.", averageScore: 0 },
  ];
  const [chartDataForUnit, setChartDataForUnit] = useState<BarDatum[]>(
    chartDefaultDataForUnit
  );

  const { playerPlayMetrics, playerFormationMetrics } = useContext(
    ReadinessScoresContext
  );
  const { currentPlayersAsArray } = useContext(PlayersContext);
  const { currentPlaybook, globalPlaybook } = useContext(PlaybookContext);
  const { currentFormations, globalFormations } = useContext(FormationsContext);
  const [selectedFilter, setSelectedFilter] = useState(PositionFiltersMap[0]);
  const [searchText, setSearchText] = useState("");
  const [playbook, setPlaybook] = useState<PlayModel | null>(null);
  const [formation, setFormation] = useState<FormationModel | null>(null);
  const [player, setPlayer] = useState<PlayerModel>();
  const [showPlayerModal, setShowPlayerModal] = useState(false);

  const [dataForPlayerStatsGrid, setDataForPlayerStatsGrid] = useState<
    PlayerRow[]
  >();

  useEffect(() => {
    searchGridResult(searchText);
  }, [selectedFilter]);

  useEffect(() => {
    if (currentPlaybook && globalPlaybook && playId) {
      const matchPlaybook =
        currentPlaybook?.find((play) => play.id === playId) ||
        globalPlaybook?.find((play) => play.id === playId);
      if (matchPlaybook) {
        setPlaybook(matchPlaybook);
      } else {
        setPlaybook(null);
      }
    }

    return () => setPlaybook(null);
  }, [currentPlaybook, globalPlaybook, playId, playbook]);

  useEffect(() => {
    if (currentFormations && globalFormations && formationId) {
      const matchFormation =
        currentFormations?.find((formation) => formation.id === formationId) ||
        globalFormations?.find((formation) => formation.id === formationId);
      if (matchFormation) {
        setFormation(matchFormation);
      } else {
        setFormation(null);
      }
    }
    return () => {
      setFormation(null);
    };
  }, [currentFormations, globalFormations, formationId, formation]);

  useEffect(() => {
    if (
      currentPlayersAsArray &&
      !isEmpty(currentPlayersAsArray) &&
      playerPlayMetrics &&
      !isEmpty(playerPlayMetrics) &&
      playId
    ) {
      const playerRows = playerPlayMetrics
        .filter((p) => p.playId === playId)
        .map((p) => {
          return {
            playerFirstName: p.playerFirstName,
            playerLastName: p.playerLastName,
            playerId: p.playerId ? p.playerId : "",
            playerUserId: p.playerUserId,
            positions: p.playerPositions ? p.playerPositions : "",
            readiness: Number(p.readinessScore),
          };
        });

      setChartDataForUnit(formatReadinessByUnitChartData(playerRows));

      setDataForPlayerStatsGrid(playerRows);
    }
  }, [currentPlayersAsArray, playerPlayMetrics, playId]);

  useEffect(() => {
    if (
      currentPlayersAsArray &&
      !isEmpty(currentPlayersAsArray) &&
      playerFormationMetrics &&
      !isEmpty(playerFormationMetrics) &&
      !isEmpty(formationId) &&
      !!formationId
    ) {
      const playerRows = playerFormationMetrics
        .filter((f) => f.formationId === formationId)
        .map((p) => {
          return {
            playerFirstName: p.playerFirstName,
            playerLastName: p.playerLastName,
            playerId: p.playerId ? p.playerId : "",
            playerUserId: p.playerUserId,
            positions: p.playerPositions ? p.playerPositions : "",
            readiness: Number(p.readinessScore),
          };
        });

      setChartDataForUnit(formatReadinessByUnitChartData(playerRows));

      setDataForPlayerStatsGrid(playerRows);
    }
  }, [currentPlayersAsArray, playerFormationMetrics, formationId]);

  const searchGridResult = (event: any) => {
    setSearchText(event);
  };

  const onRowClick = (id: string) => {
    setPlayer(
      currentPlayersAsArray.find((player: PlayerModel) => player.id === id)
    );
    setShowPlayerModal(true);
  };

  const columnsForPlayers = useMemo(
    () => [
      {
        name: "PLAYER",
        selector: (row: PlayerRow) => row.playerId,
        cell: (row: PlayerRow) => {
          return (
            <div
              className={`${dashboardStatsStyles.clickableCell} ${dashboardStatsStyles.flexRow}`}
              onClick={() => onRowClick(row.playerId)}
            >
              <div className={dashboardStatsStyles.usreListImg}>
                <Avatar userId={row.playerId} small type="player" />
              </div>

              <span className={dashboardStatsStyles.playerName}>
                {getAbbreviatedDisplayName(
                  row.playerFirstName,
                  row.playerLastName
                )}
              </span>
              {row.positions && (
                <div className={dashboardStatsStyles.playerPosition}>
                  {row.positions}
                </div>
              )}
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: `${playId ? "PLAY" : formationId ? "FORMATION" : ""} READINESS`,
        selector: (row: PlayerRow) => +row.readiness,
        format: (row: PlayerRow) => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [currentPlayersAsArray]
  );

  return (
    <div>
      <p className={styles.breadcrumb}>
        <span className={styles.drillInTitle} onClick={() => close()}>
          {`${playbook ? "Playbook" : "Formation"} Readiness`}
        </span>
        <img
          src={keyboard_arrow_right}
          alt="keyboard_arrow_right"
          className={styles.arrowRight}
        />
        {playbook ? playbook?.name : formation?.name}
      </p>

      <div className={styles.chartCardRow}>
        <div className={styles.mediaForPlayDrillIn}>
          {playbook && <PlayThumbnail play={playbook} />}
          {formation && <PlayThumbnail formation={formation} />}
        </div>
        <div className={styles.graphs}>
          <ReadinessGraph
            title={`Average ${playId ? "Play" : "Formation"} Readiness`}
            type="Line"
            xLabel="Date"
            yLabel="Readiness"
            ySymbol="%"
            data={chartDataForUnit}
            indexBy="category"
            valueKey="averageScore"
          />

          <ReadinessGraph
            title={`${playId ? "Play" : "Formation"} Readiness by Unit`}
            type="Bar"
            xLabel="Unit"
            yLabel="Readiness"
            ySymbol="%"
            data={chartDataForUnit}
            indexBy="category"
            valueKey="averageScore"
          />
        </div>
      </div>

      <div className={styles.searchAndFilterBtnForDrillIn}>
        <div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Search"
            id="search"
            size="x-small"
            icon={searchIcon}
            clearButton
            value={searchText}
            onChange={(e) => searchGridResult(e.currentTarget.value)}
          />
        </div>

        <div className={styles.filterBtns}>
          <ToggleSelect
            options={PositionFiltersMap.map((f) => f.label)}
            selectedOption={selectedFilter.label}
            background="#151724"
            borderColor="#151724"
            onChange={(label: string) => {
              const newFilter = PositionFiltersMap.find(
                (f) => f.label == label
              );
              if (newFilter) {
                setSelectedFilter(newFilter);
              }
            }}
          />
        </div>
      </div>
      <br />

      <div>
        <>
          <DashboardStatsGrid
            data={dataForPlayerStatsGrid?.filter((m) => {
              return (
                hasPositionType(
                  m.positions ? m.positions.split("|") : [],
                  selectedFilter.value
                ) &&
                (
                  "" +
                  m.playerFirstName +
                  m.playerLastName +
                  m.readiness.toFixed(2) +
                  getAbbreviatedDisplayName(m.playerFirstName, m.playerLastName)
                )
                  .toLowerCase()
                  .indexOf(searchText ? searchText.toLowerCase() : "", 0) !== -1
              );
            })}
            columns={columnsForPlayers}
          />
          {player && (
            <PlayerModal
              player={player}
              visible={showPlayerModal}
              defaultTab={"playbook"}
              close={() => setShowPlayerModal(false)}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default PlayPlaybook;
