import { useContext } from "react";
import { NavLink } from "react-router-dom";

import routes from "../../routes";
import { userRoles } from "../../shared/shared-with-mobile/constants";
import styles from "./Nav.module.scss";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";

const Nav: React.FC = () => {
  // const { userProfile } = useContext(UserContext);
  // const { currentTeam } = useContext(TeamContext);

  return (
    <div className={styles.nav}>
      <NavLinks />
      {/* {(userProfile?.role === userRoles.ADMIN ||
        (userProfile?.role === userRoles.USER && currentTeam)) &&
        routes
          .filter((route) => route.display)
          .filter(
            (route) => route.isAdmin === (userProfile?.role === userRoles.ADMIN)
          )
          .map((route) => (
            <NavLink key={route.path} to={route.path}>
              {route.display}
            </NavLink>
          ))} */}
    </div>
  );
};

export default Nav;

export const NavLinks: React.FC = () => {
  const { userProfile } = useContext(UserContext);
  const { currentTeam } = useContext(TeamContext);

  return (
    <>
      {(userProfile?.role === userRoles.ADMIN ||
        (userProfile?.role === userRoles.USER && currentTeam)) &&
        routes
          .filter((route) => route.display)
          .filter(
            (route) => route.isAdmin === (userProfile?.role === userRoles.ADMIN)
          )
          .map((route) => (
            <NavLink key={route.path} to={route.path}>
              {route.display}
            </NavLink>
          ))}
    </>
  );
};
