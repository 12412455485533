import React, { useContext } from "react";
import styles from "./QuestionCard.module.scss";
import { QuestionAnswerType } from "./QuestionCard";
import CircleCheckedIcon from "../../../resources/icons/CircleCheckedIcon";
import CircleNotAllowedIcon from "../../../resources/icons/CircleNotAllowwedIcon";
import MediaDropzone from "./MediaDropzone";
import { MediaModel } from "../../../generated/from-api/models/media.model";
import { CustomQuizQuestionOption } from "../../../generated/from-api/models/custom-quiz/custom-quiz-question-option.model";
import MediaThumbnail from "../../media/MediaThumbnail/MediaThumbnail";
import { MediaContext } from "../../../shared/shared-with-mobile/providers/media.provider";
import PencilIcon from "../../../resources/icons/PencilIcon";
import TrashIcon from "../../../resources/icons/TrashIcon";
import MediaGallery from "../../MediaGallery/MediaGallery";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";

interface Props {
  type: QuestionAnswerType;
  questionMediaOptions?: CustomQuizQuestionOption[];
  uploadMediaCallback?: (
    actionType: QuestionAnswerType,
    media: MediaModel,
    index?: number
  ) => void;
  removeMediaCallback?: (actionType: QuestionAnswerType, index: number) => void;
  dropActionCallback?: (type: QuestionAnswerType, file: any) => void;
}

const MediaAnswersCard: React.FC<Props> = ({
  type,
  questionMediaOptions,
  dropActionCallback,
  uploadMediaCallback,
  removeMediaCallback,
}) => {
  const { dispatchModal } = useContext(UIContext);
  const { mediaItemsMap } = useContext(MediaContext);
  const isTypeCorrect = type === "correct";

  const handleOnEditClick = (index: number) => {
    dispatchModal({
      open: true,
      size: "large",
      className: styles.mediaGallerySetupModal,
      body: (
        <MediaGallery
          type={"Modal"}
          allowBulkSelect={false}
          handleAddMedia={(media: MediaModel[]) => {
            uploadMediaCallback && uploadMediaCallback(type, media[0], index);
          }}
          addMediaButtonText="Add Media to Answer"
        />
      ),
    });
  };

  return (
    <div className={styles.questionCardMediaAnswersCard}>
      <div className={styles.questionCardMediaAnswersCardIconWrapper}>
        <div
          className={`${styles.questionCardMediaAnswersCardIcon} ${
            isTypeCorrect
              ? styles.questionCardAnswerLineIconCorrect
              : styles.questionCardAnswerLineIconIncorrect
          }`}
        >
          {isTypeCorrect ? <CircleCheckedIcon /> : <CircleNotAllowedIcon />}
        </div>
        <div className={styles.questionCardAnswerMediaLineIconText}>
          {isTypeCorrect ? "Correct Answers" : "Incorrect Answers"}
        </div>
      </div>
      <div className={styles.questionCardMediaAnswersLineWrapper}>
        {questionMediaOptions &&
          questionMediaOptions.map(
            (media, index) =>
              media.mediaId && (
                <div
                  key={index}
                  className={`${styles.questionCardMediaThumbnailWrapper} ${
                    index > 2 ? styles.questionCardMediaThumbnailMargin : ""
                  }`}
                >
                  <MediaThumbnail
                    className={`${styles.questionCardMediaThumbnail}`}
                    mediaItem={mediaItemsMap[media.mediaId]}
                  />

                  <div className={styles.questionCardMediaThumbnailButtons}>
                    <button
                      type="button"
                      className={styles.questionCardMediaThumbnailButton}
                      onClick={() => handleOnEditClick(index)}
                    >
                      <PencilIcon />
                    </button>
                    <button
                      type="button"
                      className={styles.questionCardMediaThumbnailButton}
                      onClick={() =>
                        removeMediaCallback && removeMediaCallback(type, index)
                      }
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </div>
              )
          )}

        <div
          className={`${styles.questionCardDropzoneWrapper} ${
            questionMediaOptions && questionMediaOptions.length > 2
              ? styles.questionCardMediaThumbnailMargin
              : ""
          }`}
        >
          <MediaDropzone
            defaultFilters={["IMAGE"]}
            uploadMediaCallback={(media) =>
              uploadMediaCallback && uploadMediaCallback(type, media)
            }
            dropActionCallback={(file: any) =>
              dropActionCallback && dropActionCallback(type, file)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MediaAnswersCard;
