import React, { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import styles from "./PrintPlays.module.scss";
import CloseIcon from "../../../resources/images/close.svg";

import Print1Preview from "../../../resources/images/Print1Preview.png";
import Print2Preview from "../../../resources/images/Print2Preview.png";
import Print8Preview from "../../../resources/images/Print8Preview.png";
import Exclamation from "../../../resources/images/exclamation.png";

import Checkbox from "../../../components/Checkbox/Checkbox";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { PrintPreview } from "./PrintPreview";
import Button from "../../../components/Button/Button";

interface PrintPlaysProps {
  visible: boolean;
  onClose: () => void;
  playsToPrint: PlayModel[];
  showMessage: boolean;
}

const PRINT_OPTIONS = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "8",
    value: 8,
  },
];

interface PrintLayoutPreviewProps {
  plays: number;
  pages: number;
}

const PrintLayoutPreview = ({ plays, pages }: PrintLayoutPreviewProps) => {
  return (
    <div className={styles.preview}>
      <img
        src={
          pages == 1
            ? Print1Preview
            : pages == 2
            ? Print2Preview
            : Print8Preview
        }
      />
      <p>
        {plays} Plays / {Math.ceil(plays / pages)} Pages
      </p>
    </div>
  );
};

const PrintPlays: React.FC<PrintPlaysProps> = ({
  visible,
  onClose,
  playsToPrint,
  showMessage,
}) => {
  const [closing, setClosing] = useState<boolean>(false);
  const [finalPlaysToPrint, setFinalPlaysToPrint] = useState<PlayModel[]>(
    playsToPrint
  );
  const [playsPerPage, setPlaysPerPage] = useState<number>(
    PRINT_OPTIONS[0].value
  );
  const [checked, setChecked] = useState<boolean>(false);
  const printSinglePlayRef = React.useRef<HTMLDivElement>(null);
  const printTwoPlaysRef = React.useRef<HTMLDivElement>(null);
  const printEightPlaysRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        onClose();
        setClosing(false);
      }, 350);
    }
  }, [closing]);

  useEffect(() => {
    if (!checked) {
      setFinalPlaysToPrint(
        playsToPrint.filter((play: PlayModel) => play.published)
      );
    } else {
      setFinalPlaysToPrint(playsToPrint);
    }
  }, [checked, playsPerPage, playsToPrint]);

  return (
    <>
      <div
        className={`
            ${styles.printPlaysWrapper}
            ${visible ? styles.open : ""}
            ${closing ? styles.closing : ""}
            `}
        data-lockfocus={visible}
      >
        <div
          className={`
                ${styles.printPlays}
            `}
        >
          <div className={styles.modalHeader}>
            Print Playbooks{" "}
            <span className={styles.closeIcon} onClick={() => setClosing(true)}>
              <img src={CloseIcon} />
            </span>
          </div>
          <div className={styles.printPlaysBody}>
            <div>
              <div className={styles.printsPerPage}>
                <p>Plays per Page</p>
                <SingleSelect
                  placeholder="2"
                  className="singleselect"
                  id="positions"
                  value={playsPerPage}
                  options={PRINT_OPTIONS}
                  onChange={(e) => {
                    setPlaysPerPage(Number(e.currentTarget.value));
                  }}
                />
              </div>
              <div className={styles.printDraftPlays}>
                <p>Options</p>{" "}
                <ul>
                  {" "}
                  <li>
                    {" "}
                    <Checkbox
                      checked={checked}
                      onChange={(e) => setChecked(e.currentTarget.checked)}
                    />{" "}
                    Print Draft Plays
                  </li>
                </ul>
              </div>
              {showMessage && (
                <div className={styles.printPlaysMessage}>
                  <img src={Exclamation} alt="exclamation" />
                  <p>
                    Note: To print part of the playbook or individual plays,
                    select them in the playbook page.
                  </p>
                </div>
              )}
              <div className={styles.printButtons}>
                <Button
                  theme="tertiary"
                  size="small"
                  onClick={() => {
                    setChecked(false);
                    setPlaysPerPage(1);
                    setClosing(true);
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <ReactToPrint
                  trigger={() => <Button size={"small"}>Print</Button>}
                  content={() =>
                    playsPerPage == 1
                      ? printSinglePlayRef.current
                      : playsPerPage == 2
                      ? printTwoPlaysRef.current
                      : printEightPlaysRef.current
                  }
                />
              </div>
            </div>
            <div className={styles.printsFinal}>
              <PrintLayoutPreview
                plays={finalPlaysToPrint.length}
                pages={playsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div ref={printSinglePlayRef}>
          <PrintPreview playsToPrint={finalPlaysToPrint} playsPerPage={1} />
        </div>
        <div ref={printTwoPlaysRef}>
          <PrintPreview playsToPrint={finalPlaysToPrint} playsPerPage={2} />
        </div>
        <div ref={printEightPlaysRef}>
          <PrintPreview playsToPrint={finalPlaysToPrint} playsPerPage={8} />
        </div>
      </div>
      <div
        className={`${styles.printPlaysOverlay} ${visible ? styles.open : ""} ${
          closing ? styles.closing : ""
        }`}
        onClick={() => setClosing(true)}
      />
    </>
  );
};

export default PrintPlays;
