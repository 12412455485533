import React, { useEffect, useRef, useState } from "react";
import styles from "./TeamDetails.module.scss";
import CharacterInput from "../../Input/CharacterInput";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import SingleSelect from "../../SingleSelect/SingleSelect";
import ImagePlaceholderIcon from "../../../resources/icons/ImagePlaceholderIcon";
import ColorPicker from "../../ColorPicker/ColorPicker";
import { TeamModel } from "../../../generated/from-api/models/team.model";
import {
  TEAM_LEVELS,
  US_STATES,
} from "../../../shared/shared-with-mobile/constants";
import Button from "../../Button/Button";
import CloseIconWhite from "../../../resources/icons/CloseIconWhite";

interface Props {
  className?: string;
  team?: TeamModel;
  teamLogoChangeCallback?: (value: string | Blob) => void;
  teamLogo?: string | Blob;
  teamNameChangeCallback?: (value: string) => void;
  teamLevelChangeCallback?: (value: string) => void;
  teamPrimaryColorChangeCallback?: (value: string) => void;
  teamSecondaryColorChangeCallback?: (value: string) => void;
  teamCityChangeCallback?: (value: string) => void;
  teamStateChangeCallback?: (value: string) => void;
  teamPostalCodeChangeCallback?: (value: string) => void;
}

const TeamDetails: React.FC<Props> = ({
  className = "",
  team,
  teamLogoChangeCallback,
  teamLogo,
  teamNameChangeCallback,
  teamLevelChangeCallback,
  teamPrimaryColorChangeCallback,
  teamSecondaryColorChangeCallback,
  teamCityChangeCallback,
  teamStateChangeCallback,
  teamPostalCodeChangeCallback,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [logoPreview, setLogoPreview] = useState<string>("");

  useEffect(() => {
    if (!teamLogo) {
      setLogoPreview("");
      return;
    }

    if (typeof teamLogo === "string") {
      setLogoPreview(teamLogo);
    } else {
      const objectUrl = URL.createObjectURL(teamLogo);
      setLogoPreview(objectUrl);
    }

    return () => URL.revokeObjectURL(logoPreview);
  }, [teamLogo]);

  return (
    <div className={`${styles.teamDetails} ${className}`}>
      <div className={styles.teamDetails_LeftSide}>
        <div className={styles.teamDetails_FileUpload}>
          <div
            className={styles.teamDetails_LogoButton}
            onClick={() => inputFileRef?.current?.click()}
          >
            {logoPreview ? (
              <>
                <Button
                  className={styles.teamDetails_LogoButtonCloseIcon}
                  theme="transparent"
                  size="small"
                  icon={<CloseIconWhite />}
                  onClick={(e) => {
                    e.stopPropagation();
                    teamLogoChangeCallback && teamLogoChangeCallback("");
                  }}
                />
                <img
                  className={styles.teamDetails_LogoButtonImage}
                  src={logoPreview}
                  alt="Team Logo"
                />
              </>
            ) : (
              <>
                <span>
                  <ImagePlaceholderIcon />
                </span>
                <span>Team Logo</span>
              </>
            )}
          </div>
          <input
            ref={inputFileRef}
            className={styles.teamDetails_FileUploadInput}
            type="file"
            accept="image/jpeg, image/png, image/gif"
            onChange={(e) =>
              teamLogoChangeCallback &&
              teamLogoChangeCallback(e.target.files ? e.target.files[0] : "")
            }
          />
        </div>
      </div>
      <div className={styles.teamDetails_RightSide}>
        <div className={styles.teamDetails_RowSmall}>
          <CharacterInput
            name="name"
            type={INPUT_TYPES.TEXT}
            placeholder="Team Name"
            maxLength={255}
            onChange={(event) =>
              teamNameChangeCallback &&
              teamNameChangeCallback(event.target.value)
            }
            value={team ? team.name : ""}
          />
        </div>
        <div className={styles.teamDetails_RowSmall}>
          <SingleSelect
            name="level"
            placeholder="Team Level"
            options={TEAM_LEVELS.map((level) => ({
              label: level,
              value: level.toUpperCase(),
            }))}
            onChange={(event) =>
              teamLevelChangeCallback &&
              teamLevelChangeCallback(event.currentTarget.value)
            }
            value={team ? team.level : ""}
          />
        </div>
        <div className={styles.teamDetails_RowMedium}>
          <div className={styles.teamDetails_FlexRow_Columns}>
            <div className={styles.teamDetails_Label}>Team Colors</div>
            <div className={styles.teamDetails_FlexRow}>
              <ColorPicker
                name="primaryColor"
                className={styles.teamDetails_ColorPicker}
                text="Primary"
                color={team?.primaryColor || "#fff"}
                onColorChangeCallback={teamPrimaryColorChangeCallback}
              />
              <ColorPicker
                name="secondaryColor"
                className={styles.teamDetails_ColorPicker}
                text="Secondary"
                color={team?.secondaryColor || "#fff"}
                onColorChangeCallback={teamSecondaryColorChangeCallback}
              />
            </div>
          </div>
        </div>
        <div className={styles.teamDetails_RowMedium}>
          <div className={styles.teamDetails_FlexRow_Columns}>
            <div className={styles.teamDetails_Label}>Team Location</div>
            <div className={styles.teamDetails_FlexRow}>
              <div className={styles.teamDetails_City}>
                <CharacterInput
                  type={INPUT_TYPES.TEXT}
                  name="city"
                  placeholder="City"
                  maxLength={50}
                  onChange={(e) =>
                    teamCityChangeCallback &&
                    teamCityChangeCallback(e.currentTarget.value)
                  }
                  value={team?.city || ""}
                />
              </div>
              <div className={styles.teamDetails_State}>
                <SingleSelect
                  name="state"
                  placeholder="State"
                  options={US_STATES}
                  onChange={(e) =>
                    teamStateChangeCallback &&
                    teamStateChangeCallback(e.currentTarget.value)
                  }
                  value={team?.state || ""}
                />
              </div>
              <div className={styles.teamDetails_Zip}>
                <CharacterInput
                  type={INPUT_TYPES.TEXT}
                  name="postalCode"
                  placeholder="Zip Code"
                  maxLength={12}
                  onChange={(e) =>
                    teamPostalCodeChangeCallback &&
                    teamPostalCodeChangeCallback(e.currentTarget.value)
                  }
                  value={team?.postalCode || ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
