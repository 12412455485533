import { useContext } from "react";

import styles from "./MediaThumbnail.module.scss";
import { MediaModel } from "../../../generated/from-api/models/media.model";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import MediaPlayerModal from "../MediaPlayerModal/MediaPlayerModal";
import PlayVideoIcon from "../../../resources/images/yellowPlayVideoIcon.svg";
import AudioIcon from "../../../resources/images/audioFileIcon.svg";

interface Props {
  className?: string;
  mediaItem?: MediaModel;
}

const MediaThumbnail: React.FC<Props> = ({ className = "", mediaItem }) => {
  const { dispatchModal } = useContext(UIContext);

  if (!mediaItem) {
    return (
      <div className={`${styles.mediaThumbnail} ${styles.deletedMedia}`}>
        This item has been deleted from the Media Gallery.
      </div>
    );
  }

  const openMediaPlayerModal = () => {
    dispatchModal({
      open: true,
      size: "medium",
      body: <MediaPlayerModal mediaItem={mediaItem} />,
    });
  };

  return (
    <div
      className={`${styles.mediaThumbnail} ${className}`}
      onClick={openMediaPlayerModal}
    >
      {mediaItem.type === "IMAGE" && (
        <img
          src={mediaItem.url}
          alt={mediaItem.name}
          className={styles.mediaImage}
        />
      )}
      {mediaItem.type === "VIDEO" && mediaItem.thumbnailUrl && (
        <>
          <img
            src={mediaItem.thumbnailUrl}
            alt={mediaItem.name}
            className={styles.mediaImage}
          />
          <div className={styles.videoThumbnailOverlay}>
            <img src={PlayVideoIcon} className={styles.playIcon} />
          </div>
        </>
      )}
      {mediaItem.type === "AUDIO" && (
        <img
          src={AudioIcon}
          alt={mediaItem.name}
          className={styles.audioIcon}
        />
      )}
    </div>
  );
};

export default MediaThumbnail;
