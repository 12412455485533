import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

import "./styles/index.scss";
import App from "./App/App";
import { UserProvider } from "./shared/shared-with-mobile/providers/user.provider";
import { PlaybookProvider } from "./shared/shared-with-mobile/providers/playbook.provider";
import { FormationsProvider } from "./shared/shared-with-mobile/providers/formations.provider";
import { TeamProvider } from "./shared/shared-with-mobile/providers/team.provider";
import { LicenseHolderProvider } from "./shared/shared-with-mobile/providers/licenseHolder.provider";
import { UIProvider } from "./shared/shared-with-mobile/providers/ui.provider";
import { muiTheme } from "./shared/shared-with-mobile/utilities/mui-constants";
import { LicensesProvider } from "./shared/shared-with-mobile/providers/licenses.provider";
import { PlayersProvider } from "./shared/shared-with-mobile/providers/players.provider";
import { CoachesProvider } from "./shared/shared-with-mobile/providers/coaches.provider";
import { InvitationsProvider } from "./shared/shared-with-mobile/providers/invitations.provider";
import { TagsProvider } from "./shared/shared-with-mobile/providers/tags.provider";
import { GamePlansProvider } from "./shared/shared-with-mobile/providers/gamePlans.provider";
import { ReadinessScoresProvider } from "./shared/shared-with-mobile/providers/readinessScores.provider";
import { QuizzesProvider } from "./shared/shared-with-mobile/providers/quizzes.provider";
import { MediaProvider } from "./shared/shared-with-mobile/providers/media.provider";
import { InstallsProvider } from "./shared/shared-with-mobile/providers/installs.provider";
import { LicenseTiersProvider } from "./shared/shared-with-mobile/providers/licenseTiers";
import { PlaySetsProvider } from "./shared/shared-with-mobile/providers/playSets.provider";
import { TagFoldersProvider } from "./shared/shared-with-mobile/providers/tagFolders.provider";
import { MediaTagProvider } from "./shared/shared-with-mobile/providers/mediaTags.provider";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <UIProvider>
        <UserProvider>
          <LicensesProvider>
            <TeamProvider>
              <InvitationsProvider>
                <CoachesProvider>
                  <PlayersProvider>
                    <PlaySetsProvider>
                      <FormationsProvider>
                        <PlaybookProvider>
                          <LicenseHolderProvider>
                            <TagFoldersProvider>
                              <TagsProvider>
                                <GamePlansProvider>
                                  <ReadinessScoresProvider>
                                    <QuizzesProvider>
                                      <MediaTagProvider>
                                        <MediaProvider>
                                          <InstallsProvider>
                                            <LicenseTiersProvider>
                                              <App />
                                            </LicenseTiersProvider>
                                          </InstallsProvider>
                                        </MediaProvider>
                                      </MediaTagProvider>
                                    </QuizzesProvider>
                                  </ReadinessScoresProvider>
                                </GamePlansProvider>
                              </TagsProvider>
                            </TagFoldersProvider>
                          </LicenseHolderProvider>
                        </PlaybookProvider>
                      </FormationsProvider>
                    </PlaySetsProvider>
                  </PlayersProvider>
                </CoachesProvider>
              </InvitationsProvider>
            </TeamProvider>
          </LicensesProvider>
        </UserProvider>
      </UIProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
