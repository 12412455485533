import { useContext, useState } from "react";

import { TeamModel } from "../../../generated/from-api/models/team.model";
import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { LicensesContext } from "../../../shared/shared-with-mobile/providers/licenses.provider";
import TeamDetails from "../../../components/Team/TeamDetails/TeamDetails";
import Button from "../../../components/Button/Button";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";

import styles from "../Account.module.scss";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { TeamLevelEnum } from "../../../generated/from-api/models/enums/team-level.enum";

interface TeamAddEditModalProps {
  team?: TeamModel;
}

const TeamAddEditModal: React.FC<TeamAddEditModalProps> = ({ team }) => {
  const { userProfile } = useContext(UserContext);
  const { currentLicenses, updateLicenses } = useContext(LicensesContext);
  const { updateTeam, addTeams } = useContext(TeamContext);
  const { dispatchToast, closeModal, dispatchModal } = useContext(UIContext);

  // If we weren't provided an ID prepare to create a new team
  if (team === undefined) {
    team = {
      name: "",
      level: "" as TeamLevelEnum,
      logo: "",
      createdBy: userProfile?.id || "",
      licenseId: currentLicenses ? currentLicenses[0].id : "", // This will probably need to be refined to determine a specific license to use, not just blindly the first one.
      deleted: false,
    };
  }

  const [tempTeam, setTempTeam] = useState<TeamModel>(team);
  const [tempTeamLogo, setTempTeamLogo] = useState<string | Blob | undefined>(
    team.logo
  );

  const saveUpdates = async () => {
    if (!isValidPostalCode(tempTeam)) {
      dispatchToast({
        type: "error",
        message: "Invalid postal code.",
      });
      return;
    }

    if (tempTeam.id) {
      const teamToUpdate = { ...tempTeam };

      // unset team logo
      if (team?.logo && !tempTeamLogo) {
        teamToUpdate.logo = "";
      }

      // add or change team logo
      if (tempTeamLogo && typeof tempTeamLogo !== "string") {
        const newFormData = new FormData();
        newFormData.append("image", tempTeamLogo);
        const newTeamLogo = await APIService.TEAM.POST_UPLOAD_LOGO(
          tempTeam.id as string,
          newFormData
        );

        teamToUpdate.logo = newTeamLogo.url;
      }

      updateTeam(teamToUpdate);
      await APIService.TEAM.PUT(teamToUpdate);
    } else {
      // create new team
      const response = await APIService.TEAM.POST(tempTeam);
      if (response) {
        const newTeam = { ...response } as TeamModel;

        if (tempTeamLogo && typeof tempTeamLogo !== "string") {
          const newFormData = new FormData();
          newFormData.append("image", tempTeamLogo);
          const newTeamLogo = await APIService.TEAM.POST_UPLOAD_LOGO(
            response.id as string,
            newFormData
          );

          newTeam.logo = newTeamLogo.url;
        }

        addTeams([newTeam]);
        updateAvailableLicenses();
      }
    }

    closeModal();
  };

  const isValidTeam = (team: TeamModel) => team.name && team.level;

  const updateAvailableLicenses = async () => {
    if (userProfile && userProfile.email) {
      const userLicenses = await APIService.LICENSE.LIST(userProfile.email);
      updateLicenses(userLicenses);
    }
  };

  const updateTeamProperty = (propertyName: keyof TeamModel, value: string) => {
    const nextTempTeam = { ...tempTeam };
    nextTempTeam[propertyName] = value as never;
    setTempTeam(nextTempTeam);
  };

  const isValidPostalCode = (team: TeamModel) => {
    if (!team.postalCode) {
      return true;
    }

    const isValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(team.postalCode || "");
    return isValid;
  };

  return (
    <>
      <hr className={styles.teamEditLine} />
      <TeamDetails
        className={styles.teamEditWrap}
        team={tempTeam}
        teamLogo={tempTeamLogo}
        teamLogoChangeCallback={(value: string | Blob | undefined) => {
          setTempTeamLogo(value);
        }}
        // teamLogo?: string | Blob;
        teamNameChangeCallback={(value: string) => {
          updateTeamProperty("name", value);
        }}
        teamLevelChangeCallback={(value: string) => {
          updateTeamProperty("level", value);
        }}
        teamPrimaryColorChangeCallback={(value: string) => {
          updateTeamProperty("primaryColor", value);
        }}
        teamSecondaryColorChangeCallback={(value: string) => {
          updateTeamProperty("secondaryColor", value);
        }}
        teamCityChangeCallback={(value: string) => {
          updateTeamProperty("city", value);
        }}
        teamStateChangeCallback={(value: string) => {
          updateTeamProperty("state", value);
        }}
        teamPostalCodeChangeCallback={(value: string) => {
          updateTeamProperty("postalCode", value);
        }}
      />
      <hr className={styles.teamEditLine} />
      <div className={styles.teamEditButtons}>
        <span>
          {team.id && userProfile && team.createdBy == userProfile?.id ? (
            <Button
              theme={"tertiary"}
              destructive={true}
              onClick={() =>
                dispatchModal({
                  size: "medium",
                  body: <TeamAddEditDeleteModal team={team} />,
                  open: true,
                })
              }
            >
              Delete
            </Button>
          ) : (
            <></>
          )}
        </span>
        <span>
          <Button
            theme={"primary"}
            onClick={saveUpdates}
            disabled={!isValidTeam(tempTeam)}
          >
            {team.id ? "Update" : "Create"}
          </Button>
        </span>
      </div>
    </>
  );
};

export default TeamAddEditModal;

const TeamAddEditDeleteModal: React.FC<TeamAddEditModalProps> = ({ team }) => {
  const { closeModal, closeModals, dispatchToast } = useContext(UIContext);
  const { userProfile } = useContext(UserContext);
  const { updateLicenses } = useContext(LicensesContext);
  const { removeTeam } = useContext(TeamContext);
  const [
    showingSecondaryConfirmation,
    setShowingSecondaryConfirmation,
  ] = useState(false);

  const deleteTeam = async () => {
    const response = await APIService.TEAM.DELETE((team && team.id) || "");
    if (response?.deleted) {
      closeModals();
      dispatchToast({
        type: "success",
        message: `${team?.name} deleted.`,
      });
      team && team.id && removeTeam(team.id);
      updateAvailableLicenses();
    } else {
      dispatchToast({
        type: "error",
        message: `There was a problem deleting the Team.`,
      });
    }
  };

  const updateAvailableLicenses = async () => {
    if (userProfile && userProfile.email) {
      const userLicenses = await APIService.LICENSE.LIST(userProfile.email);
      updateLicenses(userLicenses);
    }
  };

  return (
    <div className={styles.accountTeamDeleteModal}>
      {!showingSecondaryConfirmation
        ? "Are you sure you want to delete this team? This action cannot be undone."
        : "Please confirm this action. A deleted team and its content is not accessible by any players or coaches, and all data associated with it will be lost permanently."}
      <div className={styles.accountTeamDeleteModalButtons}>
        <span>
          <Button theme={"secondary"} onClick={closeModal}>
            Cancel
          </Button>
        </span>
        <span>
          <Button
            theme={"tertiary"}
            destructive={true}
            onClick={() => {
              if (!showingSecondaryConfirmation) {
                setShowingSecondaryConfirmation(true);
              } else {
                deleteTeam();
              }
            }}
          >
            {!showingSecondaryConfirmation ? "Delete Team" : "Confirm Deletion"}
          </Button>
        </span>
      </div>
    </div>
  );
};
