import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import PenantIcon from "../../resources/images/penant.svg";
import ImagePlaceholderIcon from "../../resources/icons/ImagePlaceholderIcon";
import { TeamModel } from "../../generated/from-api/models/team.model";
import styles from "./TeamDetails.module.scss";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import CharacterInput from "../../components/Input/CharacterInput";
import SingleSelect from "../../components/SingleSelect/SingleSelect";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import {
  TEAM_LEVELS,
  US_STATES,
} from "../../shared/shared-with-mobile/constants";
import CloseIconWhite from "../../resources/icons/CloseIconWhite";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { LicensesContext } from "../../shared/shared-with-mobile/providers/licenses.provider";
import { LicenseTiersContext } from "../../shared/shared-with-mobile/providers/licenseTiers";
import { LicenseTierModel } from "../../generated/from-api/models/license-tier.model";
import LicenseCard from "../../components/LicenseCard/LicenseCard";

interface TeamDetailsProps {
  onBackButtonClick: () => void;
}

const TeamDetails: React.FC<TeamDetailsProps> = ({ onBackButtonClick }) => {
  const { dispatchToast } = useContext(UIContext);
  const { teams, updateTeam, currentTeam, setCurrentTeam } = useContext(
    TeamContext
  );

  const { currentLicenses } = useContext(LicensesContext);
  const { licenseTiers } = useContext(LicenseTiersContext);

  const [showLoading] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [logoPreview, setLogoPreview] = useState<string>("");
  const [tempTeam, setTempTeam] = useState<any>(currentTeam);

  const [tempTeamLogo, setTempTeamLogo] = useState<string | Blob | undefined>(
    currentTeam?.logo
  );

  const [licenseTier, setLicenseTier] = useState<
    LicenseTierModel | undefined
  >();

  useEffect(() => {
    if (currentLicenses && currentLicenses.length > 0 && licenseTiers) {
      const licenseTier = licenseTiers.filter((tier) => {
        return tier.id == currentLicenses[0].licenseTierId;
      })[0] as LicenseTierModel;

      setLicenseTier(licenseTier);
    }
  }, [currentLicenses, licenseTiers]);

  useEffect(() => {
    if (!currentTeam?.logo) {
      setLogoPreview("");
      return;
    }

    if (typeof currentTeam?.logo === "string") {
      setLogoPreview(currentTeam?.logo);
    } else {
      const objectUrl = URL.createObjectURL(currentTeam?.logo);
      setLogoPreview(objectUrl);
    }

    return () => URL.revokeObjectURL(logoPreview);
  }, [currentTeam]);

  const updateTeamProperty = (propertyName: keyof TeamModel, value: string) => {
    const nextTempTeam = { ...tempTeam };
    nextTempTeam[propertyName] = value as never;
    setTempTeam(nextTempTeam);
  };

  const isValidPostalCode = (team: TeamModel) => {
    if (!team.postalCode) {
      return true;
    }

    const isValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(team.postalCode || "");
    return isValid;
  };

  const saveUpdates = async () => {
    if (!isValidPostalCode(tempTeam)) {
      dispatchToast({
        type: "error",
        message: "Invalid postal code.",
      });
      return;
    }

    if (tempTeam.id) {
      const teamToUpdate = { ...tempTeam };

      // unset team logo
      if (currentTeam?.logo && !tempTeamLogo) {
        teamToUpdate.logo = "";
      }

      // add or change team logo
      if (tempTeamLogo && typeof tempTeamLogo !== "string") {
        const newFormData = new FormData();
        newFormData.append("image", tempTeamLogo);
        const newTeamLogo = await APIService.TEAM.POST_UPLOAD_LOGO(
          tempTeam.id as string,
          newFormData
        );

        teamToUpdate.logo = newTeamLogo.url;
      }

      await APIService.TEAM.PUT(teamToUpdate);
      updateTeam(teamToUpdate);
      setCurrentTeam(teamToUpdate);
      onBackButtonClick();
    }
  };

  return (
    <div className={styles.teamDetails}>
      {showLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <h1 className={styles.mainTitle}>{"Team Details"}</h1>
            </div>
            <img
              style={{ margin: "30px 0" }}
              src={PenantIcon}
              className={styles.image}
            />
            <LicenseCard
              className={styles.subscriptionCard}
              licenseTier={licenseTier}
              teamsLimit={licenseTier?.maxTeams}
              availableLicensesCount={
                (licenseTier?.maxTeams || 0) - (teams?.size || 0)
              }
              coachesLimit={licenseTier?.maxCoaches}
              playersLimit={licenseTier?.maxPlayers}
              playsLimit={licenseTier?.maxPlays}
              gamifiedPlaysLimit={licenseTier?.maxGamifiedPlays}
              installsLimit={licenseTier?.maxInstalls}
              quizzesLimit={licenseTier?.maxCustomQuizzes}
            />
            <div className={styles.teamDetails_FileUpload}>
              <div
                className={styles.teamDetails_LogoButton}
                onClick={() => inputFileRef?.current?.click()}
              >
                {logoPreview ? (
                  <>
                    <Button
                      className={styles.teamDetails_LogoButtonCloseIcon}
                      theme="transparent"
                      size="small"
                      icon={<CloseIconWhite />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTempTeamLogo("");
                      }}
                    />
                    <img
                      className={styles.teamDetails_LogoButtonImage}
                      src={logoPreview}
                      alt="Team Logo"
                    />
                  </>
                ) : (
                  <>
                    <span>
                      <ImagePlaceholderIcon />
                    </span>
                    <span>Team Logo</span>
                  </>
                )}
              </div>
              <input
                ref={inputFileRef}
                className={styles.teamDetails_FileUploadInput}
                type="file"
                accept="image/jpeg, image/png, image/gif"
                onChange={(e) =>
                  setTempTeamLogo(e.target.files ? e.target.files[0] : "")
                }
              />
            </div>
            <div className={styles.teamDetails_RowSmall}>
              <CharacterInput
                name="name"
                type={INPUT_TYPES.TEXT}
                placeholder="Team Name"
                maxLength={255}
                onChange={(event) =>
                  updateTeamProperty("name", event.target.value)
                }
                value={currentTeam ? currentTeam.name : ""}
              />
            </div>
            <div className={styles.teamDetails_RowSmall}>
              <SingleSelect
                name="level"
                placeholder="Team Level"
                options={TEAM_LEVELS.map((level) => ({
                  label: level,
                  value: level.toUpperCase(),
                }))}
                onChange={(event) =>
                  updateTeamProperty("level", event.currentTarget.value)
                }
                value={currentTeam ? currentTeam.level : ""}
                size="large"
              />
            </div>

            <div className={styles.teamDetails_RowSmall}>
              <div className={styles.teamDetails_Label}>Team Location</div>
              <div className={styles.teamDetails_City}>
                <CharacterInput
                  type={INPUT_TYPES.TEXT}
                  name="city"
                  placeholder="City"
                  maxLength={50}
                  onChange={(e) =>
                    updateTeamProperty("city", e.currentTarget.value)
                  }
                  value={currentTeam?.city || ""}
                />
              </div>
              <div className={styles.teamDetails_State}>
                <SingleSelect
                  name="state"
                  placeholder="State"
                  options={US_STATES}
                  onChange={(e) =>
                    updateTeamProperty("state", e.currentTarget.value)
                  }
                  value={currentTeam?.state || ""}
                  size="large"
                />
              </div>
              <div className={styles.teamDetails_Zip}>
                <CharacterInput
                  type={INPUT_TYPES.TEXT}
                  name="postalCode"
                  placeholder="Postal Code"
                  maxLength={12}
                  onChange={(e) =>
                    updateTeamProperty("postalCode", e.currentTarget.value)
                  }
                  value={currentTeam?.postalCode || ""}
                />
              </div>
            </div>
            <div className={styles.teamDetails_RowSmall}>
              <div className={styles.teamDetails_Label}>Team Colors</div>
              <div className={styles.teamDetails_RowSmall}>
                <ColorPicker
                  name="primaryColor"
                  className={styles.teamDetails_ColorPicker}
                  text="Primary"
                  color={currentTeam?.primaryColor || "#fff"}
                  onColorChangeCallback={(value) =>
                    updateTeamProperty("primaryColor", value)
                  }
                />
                <ColorPicker
                  name="secondaryColor"
                  className={styles.teamDetails_ColorPicker}
                  text="Secondary"
                  color={currentTeam?.secondaryColor || "#fff"}
                  onColorChangeCallback={(value) =>
                    updateTeamProperty("secondaryColor", value)
                  }
                />
              </div>
            </div>
            <div className={styles.bottomButtons}>
              <Button
                onClick={() => saveUpdates()}
                width="full"
                theme="primary"
              >
                {"Save Changes"}
              </Button>
              <Button
                onClick={() => onBackButtonClick()}
                width="full"
                theme="secondary"
              >
                {"Back"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamDetails;
