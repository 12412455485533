import { useEffect, useRef, useState } from "react";
import { Stage, Layer, Line, Rect } from "react-konva";
import { Link } from "react-router-dom";

import styles from "./PlayThumbnail.module.scss";
import {
  convertRelativeLineToAbsolute,
  convertPlayerToShape,
  useContainerDimensions,
  playerOrderSort,
} from "../../shared/shared-with-mobile/play-editor/playEditor.utils";
import Shape from "../../shared/shared-with-mobile/play-editor/Shape";
import CoverageZone from "../../shared/shared-with-mobile/play-editor/CoverageZone";
import FieldBackground from "../../shared/shared-with-mobile/play-editor/FieldBackground";
import Checkbox from "../Checkbox/Checkbox";
import { colors } from "../../shared/shared-with-mobile/play-editor/play-editor-colors";
import { PlayModel } from "../../generated/from-api/models/play.model";
import { DrawablePlayerModel } from "../../generated/from-api/models/drawable/drawablePlayer.model";
import { lineOfScrimmageY } from "../../shared/shared-with-mobile/play-editor/playEditor.constants";
import { FormationModel } from "../../generated/from-api/models/drawable/formation.model";
import MediaPlayThumbnail from "./MediaPlayThumbnail/MediaPlayThumbnail";

interface Props {
  play?: PlayModel;
  formation?: FormationModel;
  useCheckbox?: boolean;
  showCheckbox?: boolean;
  playSelected?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  link?: boolean;
  useLoader?: boolean;
  closePlaySetEditModal?: () => void;
  isPlaySetEditModal?: boolean;
  printableView?: boolean;
}

const PlayThumbnail: React.FC<Props> = ({
  play,
  formation,
  onChange,
  useCheckbox = false,
  showCheckbox = false,
  playSelected = false,
  link = false,
  useLoader = false,
  isPlaySetEditModal = false,
  closePlaySetEditModal,
  printableView = false,
}) => {
  const [showPlay, setShowPlay] = useState<boolean>(!useLoader);
  const stageContainerRef = useRef<HTMLDivElement>(null);
  const {
    containerWidth: stageWidth,
    containerHeight: stageHeight,
  } = useContainerDimensions(stageContainerRef);

  const playOrFormation: any = play || formation;

  useEffect(() => {
    if (!useLoader && !showPlay) {
      setTimeout(() => {
        setShowPlay(true);
      }, 500);
    }
  }, [useLoader]);

  const thumbnail = !(!!play && play.mediaId) ? (
    <Stage width={stageWidth} height={stageHeight} listening={false}>
      <Layer>
        <Rect
          x={0}
          y={0}
          width={stageWidth}
          height={stageHeight}
          fill={printableView ? "white" : colors.fieldBackgroundGreen}
        />
        <FieldBackground
          stageWidth={stageWidth}
          stageHeight={stageHeight}
          ballOn={playOrFormation.ballOn || 50}
          printableView={printableView}
        />
        <Line
          stroke={colors.lineOfScrimmageGray}
          strokeWidth={2.5}
          points={convertRelativeLineToAbsolute(
            [0, lineOfScrimmageY, 1, lineOfScrimmageY],
            stageWidth,
            stageHeight
          )}
        />
        {playOrFormation.drawablePlayers
          .filter((player: DrawablePlayerModel) => {
            if (!player.coverageZoneRadiusX) {
              return false;
            }

            if (!playOrFormation.hideOpponent) {
              return true;
            }

            return playOrFormation.playType === player.playType;
          })
          .map((player: DrawablePlayerModel) => (
            <CoverageZone
              key={player.id}
              shape={convertPlayerToShape(player)}
              stageHeight={stageHeight}
              stageWidth={stageWidth}
            />
          ))}

        {playOrFormation.drawablePlayers
          .filter((player: DrawablePlayerModel) => {
            if (!playOrFormation.hideOpponent) {
              return true;
            }

            return playOrFormation.playType === player.playType;
          })
          .sort((a: DrawablePlayerModel, b: DrawablePlayerModel) =>
            playerOrderSort(a, b, playOrFormation.playType)
          )
          .map((player: DrawablePlayerModel) => (
            <Shape
              key={player.id}
              shape={convertPlayerToShape(
                player,
                playOrFormation.playType || playOrFormation.type
              )}
              stageHeight={stageHeight}
              stageWidth={stageWidth}
              draggable={false}
              isSelected={false}
              isDragging={false}
              printableView={printableView}
            />
          ))}
      </Layer>
    </Stage>
  ) : (
    <div style={{ width: `${stageWidth}px`, height: `${stageHeight}px` }}>
      <MediaPlayThumbnail play={play} />
    </div>
  );

  return (
    <div className={styles.playThumbnail} ref={stageContainerRef}>
      {showPlay ? (
        useCheckbox && play ? (
          <Checkbox
            showCheckbox={showCheckbox}
            addExtraPadding={true}
            onChange={onChange}
            checked={playSelected}
          >
            {!!play && link ? (
              <Link
                to={{
                  pathname: `edit-play/${play.id}`,
                  state: { isPlaySetEditModal: isPlaySetEditModal },
                }}
                onClick={() => {
                  isPlaySetEditModal &&
                    closePlaySetEditModal &&
                    closePlaySetEditModal();
                }}
              >
                {thumbnail}
              </Link>
            ) : (
              thumbnail
            )}
          </Checkbox>
        ) : (
          thumbnail
        )
      ) : (
        <div className={styles.loader}></div>
      )}
    </div>
  );
};

export default PlayThumbnail;
