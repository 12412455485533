import * as React from "react";

const HamburgerIcon: React.FC = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2V0H18V2H0ZM0 7H18V5H0V7ZM0 12H18V10H0V12Z"
      fill="url(#paint0_linear_1421_4519)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1421_4519"
        x1="5.16738"
        y1="8.52277e-08"
        x2="11.0966"
        y2="10.8548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFBC17" />
        <stop offset="1" stopColor="#FFEA2D" />
      </linearGradient>
    </defs>
  </svg>
);

export default HamburgerIcon;
