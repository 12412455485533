import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { CustomQuizStatisticModel } from "../../../../generated/from-api/models/custom-quiz-statistic.model";
import { API_BASE_URL } from "../../constants";

export const CustomQuizStatisticService = {
  /**
   * Use to retrieve a list of CustomQuizStatistics for a team
   */
  LIST: async function (teamId?: string): Promise<CustomQuizStatisticModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ_STATISTIC}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },
  /**
   * Use to create a CustomQuizStatistic, and also update a quiz statistic column on the related license_usage record
   * @param
   */
  POST: async function (
    CustomQuizStatisticData: Pick<
      CustomQuizStatisticModel,
      "customQuizId" | "startedAt" | "completedAt" | "score"
    > & {
      questionsAnswered?: number;
    }
  ): Promise<CustomQuizStatisticModel | undefined> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ_STATISTIC}`,
        CustomQuizStatisticData
      )
    ).data;
  },
  /**
   * Use to update a CustomQuizStatistic, and also update a quiz statistic column on the related license_usage record
   * @param
   */
  PUT: async function (
    CustomQuizStatisticData: Pick<
      CustomQuizStatisticModel,
      "id" | "completedAt" | "score"
    > & {
      questionsAnswered?: number;
    }
  ): Promise<CustomQuizStatisticModel | undefined> {
    return (
      await axios.put(
        `${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ_STATISTIC}`,
        CustomQuizStatisticData
      )
    ).data;
  },

  /**
   * Reset the team's scores
   * @param teamId
   * @returns
   */
  RESET_SCORES: async function (
    teamId: string
  ): Promise<CustomQuizStatisticModel[] | undefined> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ_STATISTIC}/reset`,
        {
          teamId,
        }
      )
    ).data;
  },
};
