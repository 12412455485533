import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { InstallModel } from "../../../../generated/from-api/models/install.model";
import { API_BASE_URL } from "../../constants";

export const InstallService = {
  /**
   * Use to retrieve a list of Installs for a team or from the global library if no team id is specified
   *
   * Coaches/Players must belong to the team in order to view installs from their team.
   * Players will only see published installs.
   *
   * Drafted Global installs are visible to all admins.
   * Published global installs are visible to all coaches or players.
   */
  LIST: async function (
    teamId?: string,
    onlyIfModifiedSince?: string
  ): Promise<InstallModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.INSTALL}`, {
        params: {
          teamId: teamId,
          onlyIfModifiedSince,
        },
      })
    ).data;
  },
  /**
   * Use to retrieve a specific Install
   */
  GET: async function (installId: string): Promise<InstallModel> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.INSTALL}/${installId}`))
      .data;
  },
  /**
   * Use to create one or many Install(es). If the Install was not created, returns undefined.
   * @param
   * @returns InstallModel[] | undefined
   */
  POST: async function (
    installData: Pick<
      InstallModel,
      | "teamId" // "teamId" is present when the user is creating an Install for a specific team
      | "name"
      | "categoryTagId"
      | "showInstallToPositions"
      | "slides"
      | "dueDate"
      | "archived"
      | "published"
      | "autoInclude"
    >[]
  ): Promise<InstallModel[] | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.INSTALL}`, installData)
    ).data;
  },
  /**
   * Use to update a Install
   * @param
   */
  PUT: async function (
    installData: Pick<
      InstallModel,
      | "id"
      | "name"
      | "categoryTagId"
      | "showInstallToPositions"
      | "slides"
      | "dueDate"
      | "archived"
      | "published"
      | "autoInclude"
    >
  ): Promise<InstallModel | undefined> {
    return (await axios.put(`${API_BASE_URL}${APIRoutes.INSTALL}`, installData))
      .data;
  },
  /**
   * Use to delete a Install
   * @param installId
   * @returns
   */
  DELETE: async function (installId: string): Promise<{ deleted: boolean }> {
    return (
      await axios.delete(`${API_BASE_URL}${APIRoutes.INSTALL}/${installId}`)
    ).data;
  },
};
