import { PlayerOverallReadinessModel } from "../dtos/player-to-play.readiness.model";
import { TeamReadinessSnapshotModel } from "./team-readiness-snapshot.model";

export const CalculateTeamReadiness = (
  teamId: string,
  playerSnapshots: PlayerOverallReadinessModel[]
): TeamReadinessSnapshotModel => {
  const ps = playerSnapshots;
  const size = ps.length;
  if (ps.length === 0) {
    return <TeamReadinessSnapshotModel>{
      created: new Date().toISOString(),
      teamId: teamId,
      overallScore: 0,
      playbookReadinessScore: 0,
      installReadinessScore: 0,
      quizReadinessScore: 0,
    };
  }
  return <TeamReadinessSnapshotModel>{
    created: new Date().toISOString(),
    teamId: teamId,
    overallScore: ps.reduce((p, c) => p + +c.overallScore, 0) / size,
    playbookReadinessScore:
      ps.reduce((p, c) => p + +c.playbookReadinessScore, 0) / size,
    installReadinessScore:
      ps.reduce((p, c) => p + +c.installReadinessScore, 0) / size,
    quizReadinessScore:
      ps.reduce((p, c) => p + +c.quizReadinessScore, 0) / size,
  };
};
