import React from "react";
import styles from "./DynamicTimerIcon.module.scss";
import YellowTimerIcon from "../../resources/images/YellowTimerIcon.svg";

interface Props {
  timerNum?: number;
}

const DynamicTimerIcon: React.FC<Props> = ({ timerNum = 0 }) => {
  return (
    <div className={styles.dynamicTimerIcon}>
      <img src={YellowTimerIcon} />
      <div
        className={`${styles.textOverlay} ${
          timerNum <= 0 ? styles.redText : ""
        }`}
      >
        {String(Math.max(timerNum, 0)).padStart(2, "0")}
      </div>
    </div>
  );
};

export default DynamicTimerIcon;
