import { useContext } from "react";
import Button from "../../Button/Button";
import styles from "./MediaGalleryTags.module.scss";
import CloseIcon from "../../../resources/icons/CloseIcon";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import TagCategoryTab from "../TagCategoryTab/TagCategoryTab";
import {
  TagFolderModel,
  TagModel,
} from "../../../generated/from-api/models/tag.model";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { TagFoldersContext } from "../../../shared/shared-with-mobile/providers/tagFolders.provider";

export interface TagCategory {
  id: string;
  name: string;
  tagIdOrder: TagModel[];
}

const MediaGalleryTags: React.FC = () => {
  const { closeModal } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);
  const { userProfile } = useContext(UserContext);
  const { addTagFolder, tagFolders } = useContext(TagFoldersContext);

  const createTagFolder = async (folderName: string) => {
    const teamId = currentTeam?.id || undefined;

    const newTagFolder: TagFolderModel = {
      name: folderName,
      teamId,
      deleted: false,
      tagOrder: null,
      createdBy: userProfile?.id || "",
    };

    const created = await APIService.TAG_FOLDER.POST(newTagFolder);

    if (created) {
      addTagFolder(created);
    }
  };

  const CreateNewFolder = () => {
    const count = 1;
    const folderName = "New Folder";
    const newValue = folderName;

    const validFolderName = checkValidFolderName(
      tagFolders,
      folderName,
      newValue,
      count
    );
    createTagFolder(validFolderName);
  };

  const checkValidFolderName = (
    array: any,
    oldValue: string,
    newValue: string,
    count: number
  ): string => {
    const found = array.filter(
      (e: any) => e.name.toLowerCase() === newValue.toLowerCase()
    );

    if (found.length > 0) {
      newValue = oldValue
        .substring(
          0,
          oldValue.lastIndexOf("-") === -1
            ? oldValue.length
            : oldValue.lastIndexOf("-")
        )
        .concat("-" + count);

      return checkValidFolderName(array, oldValue, newValue, count + 1);
    }
    return newValue;
  };

  return (
    <>
      <div className={styles.mediaGalleryTags}>
        <div className={styles.modalHeader}>
          Tags
          <Button
            className={styles.closeIcon}
            theme={"transparent"}
            size={"small"}
            icon={<CloseIcon />}
            onClick={() => closeModal()}
          />
        </div>
        <div className={styles.modalBody}>
          {tagFolders.map((tagFolder: TagFolderModel) => (
            <TagCategoryTab key={tagFolder.id} tagFolder={tagFolder} />
          ))}
        </div>

        <div className={styles.footer}>
          <Button size="small" onClick={CreateNewFolder}>
            New Folder
          </Button>
        </div>
      </div>
    </>
  );
};

export default MediaGalleryTags;
