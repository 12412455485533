import React, { useContext } from "react";

import styles from "../Installs.module.scss";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import helmet from "../../../resources/images/helmet.svg";
interface PlayerScoresListProps {
  stats: any;
}

const PlayerScoresList: React.FC<PlayerScoresListProps> = ({ stats }) => {
  const { currentPlayersAsArray } = useContext(PlayersContext);

  stats.sort((a: any, b: any) => {
    const sortVal = b.score - a.score;
    return sortVal === 0 ? ("" + a.name).localeCompare(b.name) : sortVal;
  });

  return (
    <ol
      className={`${styles.playerToolTipList} ${styles.playerToolTipListScores}`}
    >
      {stats.map((playerStat: any) => {
        const statPlayer = currentPlayersAsArray.filter(
          (player) => player.id === playerStat.playerId
        );

        let player;
        if (statPlayer.length === 1) {
          player = statPlayer[0];
        } else {
          player = {
            id: "unknown",
            firstName: "Unknown",
            lastName: "",
          };
        }

        return (
          <li
            key={`install-player-stat-${playerStat.id}-player-${player.id}-score`}
          >
            <img
              className={styles.playerToolTipListImage}
              src={player.profileImageUrl || helmet}
              alt={player.firstName + " " + player.lastName}
            />
            <span className={styles.playerToolTipListName}>
              {player.firstName + " " + player.lastName}
            </span>
            <span className={styles.playerToolTipListScore}>
              {parseFloat(playerStat.score) < 0
                ? "-"
                : parseFloat("" + playerStat.score * 100)
                    .toFixed(2)
                    .replace(/\.?0+$/, "") + "%"}
            </span>
          </li>
        );
      })}
      {/* {playerScores.map((player: any) => {
        if (player.score < 0) {
          return null;
        }
      })} */}
    </ol>
  );
};

export default PlayerScoresList;
