/* eslint-disable no-console */
import { useContext, useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import { VirtuosoGrid } from "react-virtuoso";

import styles from "./MediaGallery.module.scss";
import CharacterInput from "../Input/CharacterInput";
import {
  EmptySideBar,
  LeftSideBar,
  MediaPreviewSideBar,
  MultipleSelectedSidebar,
} from "./Sidebars";
import MediaGalleryThumbnail from "./MediaGalleryThumbnail/MediaGalleryThumbnail";
import search from "../../resources/images/search.svg";
import { MediaContext } from "../../shared/shared-with-mobile/providers/media.provider";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import {
  MediaModel,
  TN_MEDIA_TYPE,
} from "../../generated/from-api/models/media.model";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { MediaUtility } from "../../generated/from-api/shared-utilities/media.utility";
import { QuizzesContext } from "../../shared/shared-with-mobile/providers/quizzes.provider";
import SelectedImageEdit from "./SelectedImageEdit/SelectedImageEdit";
import { validateFileSize, uploadFile } from "../../utils/mediaHelpers.utils";
import DropUpload from "../DropUpload/DropUpload";
import ContentLimitModal from "../ContentLimitModal/ContentLimitModal";
import CloseIcon from "../../resources/icons/CloseIcon";
import Button from "../Button/Button";
import Tooltip from "../Tooltip/Tooltip";
import GridIcon from "../../resources/icons/GridIcon";
import ListWithoutBorderIcon from "../../resources/icons/ListWithoutBorderIcon";
import MediaListItem from "./ListItem/MediaListItem";
import { MediaTagContext } from "../../shared/shared-with-mobile/providers/mediaTags.provider";
import MediaPreview from "./MediaPreview/MediaPreview";
import Tabs from "../Tabs/Tabs";
import { PlaybookContext } from "../../shared/shared-with-mobile/providers/playbook.provider";
import { InstallsContext } from "../../shared/shared-with-mobile/providers/installs.provider";

type componentType = "Modal" | "Page";
type MediaTab = "Selected Items" | "Preview";
const defaultTab = "Selected Items";
interface Props {
  type: componentType;
  handleAddMedia: (param: MediaModel[]) => void;
  allowBulkSelect?: boolean;
  stayOpenOnAdd?: boolean;
  defaultFilters?: Array<TN_MEDIA_TYPE>;
  addMediaButtonText?: string;
}

const MediaGallery: React.FC<Props> = ({
  type = "Page",
  handleAddMedia,
  stayOpenOnAdd = false,
  defaultFilters = ["IMAGE", "VIDEO", "AUDIO"],
  addMediaButtonText = "Add Media",
  allowBulkSelect = true,
}) => {
  const {
    mediaItems,
    addMediaItems,
    removeMediaItems,
    updateMediaItem,
  } = useContext(MediaContext);
  const {
    dispatchToast,
    closeModal,
    handleCreateError,
    dispatchModal,
  } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);
  const { quizzes } = useContext(QuizzesContext);
  const { installs } = useContext(InstallsContext);
  const { mediaItemTags } = useContext(MediaTagContext);
  const { currentPlaybook } = useContext(PlaybookContext);

  const [selectedMediaMap, setSelectedMediaMap] = useState<
    Record<string, MediaModel>
  >({});
  const [previewMedia, setPreviewMedia] = useState<MediaModel>();
  const [filters, setFilters] = useState<Array<TN_MEDIA_TYPE>>(
    defaultFilters || []
  );

  const [selectedTagFilter, setSelectedTagFilter] = useState<string | null>(
    null
  );
  const [selectedTagFolderFilter, setSelectedTagFolderFilter] = useState<
    string | null
  >(null);

  const [searchText, setSearchText] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [bulkSelectEnabled, setBulkSelectEnabled] = useState(false);

  const [remainingUploadsCount, setRemainingUploadsCount] = useState<number>(0);

  const [selectedImage, setSelectedImage] = useState<MediaModel>(mediaItems[0]);
  const [isImageEditing, setIsImageEditing] = useState<boolean>(false);
  const [
    mediaIdForMediaPreview,
    setMediaIdForMediaPreview,
  ] = useState<string>();
  const [isMediaPreview, setIsMediaPreview] = useState<boolean>(false);
  const [newlyUploadedMediaIds, setNewlyUploadedMediaIds] = useState<string[]>(
    []
  );
  const [allSelected, setAllSelected] = useState<boolean>(false);
  type MEDIA_VIEW_TYPE = "Grid" | "List";
  const [mediaViewType, setMediaViewType] = useState<MEDIA_VIEW_TYPE>("Grid");
  const selectedMedia = Object.values(selectedMediaMap);
  const [currentTab, setCurrentTab] = useState<MediaTab>(defaultTab);

  const hasDefaultFilters = defaultFilters && defaultFilters.length > 0;

  const isSelectedTag = (
    item: MediaModel,
    selectedTagFilter: string | null
  ) => {
    if (selectedTagFilter) {
      const tagsAssociatedToMediaItems = mediaItemTags
        .filter(
          (media) =>
            media.tags.filter((e: any) => e.id === selectedTagFilter).length
        )
        .map((media) => media.mediaId);

      return tagsAssociatedToMediaItems.includes(item.id as string);
    } else {
      return true;
    }
  };

  const isSearchMatch = (item: MediaModel, searchText: string) => {
    if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }

    if (item.description?.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }

    return false;
  };

  const filteredMediaItems = (mediaItems: MediaModel[]) => {
    return mediaItems
      .sort((a, b) => {
        if (!a.created) {
          return 1;
        }
        if (!b.created) {
          return -1;
        }
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      })
      .filter((item) => {
        if (!filters.length && !searchText && !selectedTagFilter) {
          return true;
        } else if (!filters.length && searchText && !selectedTagFilter) {
          return isSearchMatch(item, searchText);
        } else if (filters.length && !searchText && !selectedTagFilter) {
          return filters.includes(item.type);
        } else if (!filters.length && !searchText && selectedTagFilter) {
          return isSelectedTag(item, selectedTagFilter);
        } else if (!filters.length && searchText && selectedTagFilter) {
          // both searchText and tagFilter are present
          const searchTextMatched = isSearchMatch(item, searchText);
          const filterByTag = isSelectedTag(item, selectedTagFilter);
          return searchTextMatched && filterByTag;
        } else if (filters.length && !searchText && selectedTagFilter) {
          // both type filters and tagFilter are present
          const typeFilterMatched = filters.includes(item.type);
          const filterByTag = isSelectedTag(item, selectedTagFilter);
          return typeFilterMatched && filterByTag;
        } else if (filters.length && searchText && !selectedTagFilter) {
          // both type filters and searchText are present
          const typeFilterMatched = filters.includes(item.type);
          const searchTextMatched = isSearchMatch(item, searchText);
          return typeFilterMatched && searchTextMatched;
        } else {
          // all type filters and searchText and tagFilter are present
          const typeFilterMatched = filters.includes(item.type);
          const searchTextMatched = isSearchMatch(item, searchText);
          const filterByTag = isSelectedTag(item, selectedTagFilter);

          return typeFilterMatched && searchTextMatched && filterByTag;
        }
      });
  };
  const [media, setMedia] = useState<MediaModel[]>(
    filteredMediaItems(mediaItems)
  );

  useEffect(() => {
    const items = filteredMediaItems(mediaItems);
    setMedia(items);
  }, [mediaItems, searchText, selectedTagFilter, filters]);

  // keep selectedMediaMap up to date with mediaItems context
  useEffect(() => {
    const nextSelectedMediaMap = cloneDeep(selectedMediaMap);
    for (const mediaItem of mediaItems) {
      if (mediaItem.id && nextSelectedMediaMap[mediaItem.id]) {
        nextSelectedMediaMap[mediaItem.id] = mediaItem;
      }
    }
    setSelectedMediaMap(nextSelectedMediaMap);
  }, [mediaItems]);

  useEffect(() => {
    // reset this value any time the selection changes
    setShowDeleteConfirmation(false);
    if (selectedMedia.length > 0 && allowBulkSelect) {
      setBulkSelectEnabled(true);
    } else {
      setBulkSelectEnabled(false);
    }
  }, [selectedMediaMap]);

  const handleMediaSelect = (media: MediaModel): void => {
    if (!media.id) {
      return;
    }
    setCurrentTab("Selected Items");
    setBulkSelectEnabled(true);

    const nextSelectedMediaMap = cloneDeep(selectedMediaMap);

    if (nextSelectedMediaMap[media.id]) {
      delete nextSelectedMediaMap[media.id];
    } else {
      nextSelectedMediaMap[media.id] = media;
    }

    setSelectedMediaMap(nextSelectedMediaMap);
  };

  const handleSingleTap = (media: MediaModel) => {
    if (!media.id) {
      return;
    }
    setCurrentTab("Preview");
    if (!allowBulkSelect) {
      setSelectedMediaMap({ [media.id]: media });
    } else {
      if (previewMedia && media.id == previewMedia.id) {
        setPreviewMedia(undefined);
      } else {
        setPreviewMedia(media);
      }
    }
  };

  const handleDoubleTap = (media: MediaModel) => {
    if (!media.id) {
      return;
    }
    if (type !== "Modal") {
      setMediaIdForMediaPreview(media.id);
      setIsMediaPreview(true);
    } else {
      dispatchModal({
        open: true,
        className: styles.mediaGallerySetupModal,
        body: (
          <MediaPreview
            type="Modal"
            mediaId={media.id}
            handleAddMedia={(media: MediaModel[]) => {
              addMediaAndClose(media);
            }}
            addMediaButtonText={addMediaButtonText}
            setSelectedImage={setSelectedImage}
            setIsImageEditing={setIsImageEditing}
            setIsMediaPreview={setIsMediaPreview}
          />
        ),
      });
    }
  };

  const addMediaAndClose = (media: MediaModel[]) => {
    handleAddMedia(media);
    if (!stayOpenOnAdd) closeModal();
  };

  const uploadMedia = async (file: File) => {
    const obj = validateFileSize(file);
    if (!obj.isValid) {
      dispatchToast({
        type: "error",
        message: obj.errorMessage,
      });
      return;
    }

    try {
      return await uploadFile(file, currentTeam?.id);
    } catch (error) {
      handleCreateError(error, ContentLimitModal, "media item", "media items");
    }
  };

  const deleteSelectedMedia = async (mediaIdForPreview?: string) => {
    // Open question: should we also be checking for lessons / installs here? There is already a utility function for it...
    const usedMediaIdsInQuizzes = MediaUtility.getListOfMediaIdsUsedByQuizzes(
      quizzes
    );
    const usedMediaIdsInPlaybook = MediaUtility.getListOfMediaIdsUsedByPlays(
      currentPlaybook
    );
    const usedMediaIdsInInstalls = MediaUtility.getListOfMediaIdsUsedByInstalls(
      installs
    );
    const usedMediaIds = [
      ...usedMediaIdsInQuizzes,
      ...usedMediaIdsInPlaybook,
      ...usedMediaIdsInInstalls,
    ];
    const idsToDelete =
      typeof mediaIdForPreview === "string"
        ? [mediaIdForPreview]
        : Object.keys(selectedMediaMap);

    // check if any media is used in quizzes or playbook
    // skip check if it has already been performed once, and we are already showing confirmation
    if (!showDeleteConfirmation) {
      for (const id of idsToDelete) {
        if (usedMediaIds.indexOf(id) !== -1) {
          setShowDeleteConfirmation(true);
          return;
        }
      }
    }

    // delete the media
    const promises = idsToDelete.map((mediaId: string) =>
      APIService.MEDIA.DELETE(mediaId)
    );
    const results = await Promise.all(promises);
    console.log("APIService.MEDIA.DELETE results = ", results);

    dispatchToast({
      type: "success",
      message: `${idsToDelete.length} file(s) deleted.`,
    });

    // remove deleted media from context
    removeMediaItems(idsToDelete);

    // reset the selection
    if (typeof mediaIdForPreview === "string") {
      // reset the preview, switch to default tab
      setCurrentTab("Selected Items");
      setPreviewMedia(undefined);
      // reset the selected items
      const nextSelectedMediaMap = cloneDeep(selectedMediaMap);
      if (nextSelectedMediaMap[mediaIdForPreview]) {
        delete nextSelectedMediaMap[mediaIdForPreview];
      }
      setSelectedMediaMap(nextSelectedMediaMap);
    } else {
      setSelectedMediaMap({});
    }
  };

  const submitUpdatedImage = async (data: FormData) => {
    try {
      const result = await APIService.MEDIA.POST_UPDATE_IMAGE(data);
      setSelectedImage(result);
      updateMediaItem(result);
      setTimeout(() => {
        dispatchToast({
          type: "success",
          message: `Image Updated`,
        });
      }, 100);
      return result;
    } catch (err) {
      dispatchToast({
        type: "error",
        message: `There was an error updating the image.`,
      });
      console.log(err);
    }
  };

  const notifyUploadFinished = (promises: boolean[]) => {
    setRemainingUploadsCount(promises.filter((completed) => !completed).length);
  };

  const uploadFiles = async (files: any) => {
    const promises = [];
    const promisesToComplete: boolean[] = [];
    for (let i = 0; i < files.length; i++) {
      const uploadPromise = async () => {
        try {
          let result: MediaModel | undefined = undefined;
          if (files && files[i]) {
            result = await uploadMedia(files[i]);
          }
          promisesToComplete[i] = true;
          notifyUploadFinished(promisesToComplete);
          return result;
        } catch (err) {
          promisesToComplete[i] = true;
          notifyUploadFinished(promisesToComplete);
          return undefined;
        }
      };
      promisesToComplete.push(false);
      promises.push(uploadPromise());
    }
    setRemainingUploadsCount(promises.length);
    const results = await Promise.all(promises);
    setRemainingUploadsCount(0);

    // some results might be undefined, if file size limit was exceeded
    const validResults: MediaModel[] = [];
    for (const result of results) {
      if (result !== undefined) {
        validResults.push(result);
      }
    }

    addMediaItems(validResults);
    setNewlyUploadedMediaIds(validResults.map((m) => (m.id ? m.id : "")));
  };

  const handleSelectAll = () => {
    setBulkSelectEnabled(true);
    setAllSelected(true);

    const nextSelectedMediaMap = cloneDeep(selectedMediaMap);

    media.map((item: MediaModel) => {
      if (item.id) {
        if (!nextSelectedMediaMap[item.id]) {
          nextSelectedMediaMap[item.id] = item;
        }
      }
    });

    setSelectedMediaMap(nextSelectedMediaMap);
  };

  const handleDeselectAll = () => {
    setAllSelected(false);
    setSelectedMediaMap({});
  };

  return (
    <>
      {!isImageEditing && !isMediaPreview ? (
        <div className={styles.mediaGallery}>
          <div className={styles.modalHeader}>
            Media Gallery
            {remainingUploadsCount > 0 && (
              <span className={styles.uploadInProgress}>
                {`Uploading ${remainingUploadsCount} file${
                  remainingUploadsCount > 1 ? "s" : ""
                }`}
              </span>
            )}
            {type === "Modal" && (
              <Button
                className={styles.closeIcon}
                theme={"transparent"}
                size={"small"}
                icon={<CloseIcon />}
                onClick={() => closeModal()}
              />
            )}
          </div>
          <div className={styles.mediaAndSidebarGrid}>
            <div className={styles.leftSidebar}>
              <LeftSideBar
                selectedTag={selectedTagFilter}
                selectedTagFolder={selectedTagFolderFilter}
                setFilters={setFilters}
                setSelectedTagFolder={setSelectedTagFolderFilter}
                setSelectedTag={setSelectedTagFilter}
                defaultFilters={defaultFilters}
              />
            </div>
            <div
              className={styles.mediaContainer}
              style={{ marginBottom: type == "Modal" ? 0 : 55 }}
            >
              <div className={styles.mediaToolbar}>
                <div className={styles.mediaToolbarRight}>
                  <Button
                    className={`${styles.gridIconButton} ${
                      mediaViewType === "Grid" ? styles.active : ""
                    }`}
                    theme={"transparent"}
                    size={"medium"}
                    type={"button"}
                    onClick={() => {
                      setMediaViewType("Grid");
                    }}
                    icon={
                      <GridIcon
                        iconColor={
                          mediaViewType === "Grid" ? "#E7E7ED" : undefined
                        }
                      />
                    }
                  />
                  <Button
                    className={`${styles.listIconButton} ${
                      mediaViewType === "List" ? styles.active : ""
                    }`}
                    theme={"transparent"}
                    size={"medium"}
                    type={"button"}
                    onClick={() => {
                      setMediaViewType("List");
                    }}
                    icon={
                      <ListWithoutBorderIcon
                        iconColor={
                          mediaViewType === "List" ? "#E7E7ED" : undefined
                        }
                      />
                    }
                  />
                </div>
                <div className={styles.mediaToolbarCenter}>
                  <CharacterInput
                    placeholder="Search"
                    id="media-search"
                    icon={search}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className={styles.mediaToolbarLeft}>
                  <label
                    htmlFor="media-gallery-file-uploader"
                    className={`${styles.fileUploader} ${
                      hasDefaultFilters ? styles.first : ""
                    }`}
                  >
                    <input
                      multiple
                      id="media-gallery-file-uploader"
                      accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime, video/avi, video/x-ms-asf, video/hevc, audio/mp3, audio/wav, application/pdf"
                      type="file"
                      className={styles.fileUploaderInput}
                      onChange={async (e) => {
                        setNewlyUploadedMediaIds([]);
                        if (
                          e !== null &&
                          e.target.files &&
                          e.target.files.length
                        ) {
                          uploadFiles(e.target.files);
                        }
                        e.target.value = null as any;
                      }}
                    />
                    <Tooltip tip="Supported File Types: .jpg, .png, .gif, .mp4, .mov, .avi, .wmv, .hevc, .mp3, .wav">
                      <Button disabled={remainingUploadsCount > 0}>
                        {remainingUploadsCount > 0
                          ? `Uploading...`
                          : "Upload Media"}
                      </Button>
                    </Tooltip>
                  </label>
                </div>
              </div>
              <DropUpload
                dropAction={(files: any) => {
                  uploadFiles(files);
                }}
                hoverClass={styles.uploadDropOver}
              >
                {mediaViewType == "Grid" && media.length > 0 ? (
                  <VirtuosoGrid
                    className={styles.mediaList}
                    totalCount={media.length}
                    overscan={{ main: 250, reverse: 250 }}
                    itemContent={(index) => {
                      const mediaItem = media[index];
                      return media.length > 0 ? (
                        <div
                          className={`${styles.mediaItem} ${
                            !!selectedMediaMap[mediaItem.id as string] ||
                            (previewMedia && previewMedia.id == mediaItem.id)
                              ? styles.selected
                              : ""
                          } ${
                            newlyUploadedMediaIds.findIndex(
                              (v) => v === mediaItem.id
                            ) !== -1
                              ? styles.newlyUploaded
                              : ""
                          }`}
                        >
                          <MediaGalleryThumbnail
                            key={mediaItem.id}
                            mediaItem={mediaItem}
                            selected={
                              !!selectedMediaMap[mediaItem.id as string]
                            }
                            onTap={handleSingleTap}
                            onDoubleTap={handleDoubleTap}
                            onChange={() => handleMediaSelect(mediaItem)}
                            showCheckbox={bulkSelectEnabled}
                            hideCheckbox={!allowBulkSelect}
                            previewMedia={previewMedia}
                          />
                        </div>
                      ) : (
                        <div className={styles.grid}>
                          {[...Array(12)].map((e, index) => (
                            <div
                              key={index}
                              className={`${styles.mediaItem} ${styles.loader}`}
                            ></div>
                          ))}
                        </div>
                      );
                    }}
                  />
                ) : (
                  <ul className={`${styles.listContainer}`}>
                    {filteredMediaItems(mediaItems).length > 0
                      ? filteredMediaItems(mediaItems).map(
                          (mediaItem: MediaModel) => {
                            return (
                              <MediaListItem
                                key={mediaItem.id as string}
                                className={`${styles.mediaItem} ${
                                  !!selectedMediaMap[mediaItem.id as string] ||
                                  (previewMedia &&
                                    previewMedia.id == mediaItem.id)
                                    ? styles.selected
                                    : ""
                                } ${
                                  newlyUploadedMediaIds.findIndex(
                                    (v) => v === mediaItem.id
                                  ) !== -1
                                    ? styles.newlyUploaded
                                    : ""
                                }`}
                                mediaItem={mediaItem}
                                handleMediaSelect={handleMediaSelect}
                                handleSingleTap={handleSingleTap}
                                handleDoubleTap={handleDoubleTap}
                                selected={
                                  !!selectedMediaMap[mediaItem.id as string]
                                }
                                showCheckbox={allowBulkSelect}
                                previewMedia={previewMedia}
                              />
                            );
                          }
                        )
                      : null}
                  </ul>
                )}
              </DropUpload>
            </div>
            <div className={styles.sidebar}>
              {allowBulkSelect ? (
                <>
                  <Tabs
                    onSelect={(selection) =>
                      setCurrentTab(selection as MediaTab)
                    }
                    selectedTab={currentTab}
                    theme={"primary"}
                  >
                    {["Selected Items", "Preview"]}
                  </Tabs>
                  {currentTab === "Selected Items" &&
                    selectedMedia.length == 1 && (
                      <MediaPreviewSideBar
                        mediaItem={selectedMedia[0]}
                        deleteSelectedMedia={deleteSelectedMedia}
                        showDeleteConfirmation={showDeleteConfirmation}
                        setShowDeleteConfirmation={setShowDeleteConfirmation}
                        setSelectedImage={setSelectedImage}
                        setIsImageEditing={setIsImageEditing}
                      />
                    )}
                  {currentTab === "Selected Items" && selectedMedia.length > 1 && (
                    <MultipleSelectedSidebar
                      numberSelected={selectedMedia.length}
                      clearSelection={() => {
                        setSelectedMediaMap({});
                      }}
                      selectedMediaItems={selectedMedia}
                      deleteSelectedMedia={deleteSelectedMedia}
                      showDeleteConfirmation={showDeleteConfirmation}
                      setShowDeleteConfirmation={setShowDeleteConfirmation}
                    />
                  )}
                  {currentTab === "Selected Items" &&
                    selectedMedia.length == 0 && <EmptySideBar />}
                  {currentTab === "Preview" &&
                    (!!previewMedia ? (
                      <MediaPreviewSideBar
                        mediaItem={previewMedia}
                        deleteSelectedMedia={deleteSelectedMedia}
                        showDeleteConfirmation={showDeleteConfirmation}
                        setShowDeleteConfirmation={setShowDeleteConfirmation}
                        setSelectedImage={setSelectedImage}
                        setIsImageEditing={setIsImageEditing}
                      />
                    ) : (
                      selectedMedia.length === 0 && <EmptySideBar />
                    ))}
                </>
              ) : selectedMedia.length > 0 ? (
                <MediaPreviewSideBar
                  mediaItem={selectedMedia[0]}
                  deleteSelectedMedia={deleteSelectedMedia}
                  showDeleteConfirmation={showDeleteConfirmation}
                  setShowDeleteConfirmation={setShowDeleteConfirmation}
                  setSelectedImage={setSelectedImage}
                  setIsImageEditing={setIsImageEditing}
                />
              ) : (
                <EmptySideBar />
              )}
            </div>
          </div>
          {type === "Modal" && (
            <div className={styles.footer}>
              <div>
                <Button
                  className={styles.deleteBtn}
                  theme={"primary"}
                  onClick={() => deleteSelectedMedia()}
                  destructive
                  disabled={selectedMedia.length == 0}
                >
                  Delete File
                </Button>
                {allowBulkSelect && (
                  <Button
                    theme={"secondary"}
                    onClick={allSelected ? handleDeselectAll : handleSelectAll}
                  >
                    {allSelected ? "Deselect All" : "Select All"}
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={() => addMediaAndClose(selectedMedia)}
                  disabled={!selectedMedia.length}
                >
                  {addMediaButtonText}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : isImageEditing && !isMediaPreview ? (
        <SelectedImageEdit
          setIsImageEditing={setIsImageEditing}
          image={selectedImage}
          updateImage={submitUpdatedImage}
        />
      ) : (
        <MediaPreview
          type="Page"
          mediaId={mediaIdForMediaPreview}
          addMediaButtonText={addMediaButtonText}
          setSelectedImage={setSelectedImage}
          setIsImageEditing={setIsImageEditing}
          setIsMediaPreview={setIsMediaPreview}
        />
      )}
      {selectedMedia.length > 0 && type !== "Modal" && (
        <div className={styles.gamePlanPlaysToolbar}>
          <div>
            <span className={styles.mediaSelected}>
              {selectedMedia.length} item
              {selectedMedia.length > 1 ? "s" : ""} selected
            </span>
            <Button
              theme={"transparent"}
              size={"small"}
              className={`${styles.selectButtons} ${styles.selectAllBtn}`}
              onClick={handleSelectAll}
            >
              Select all
            </Button>
            <Button
              className={styles.selectButtons}
              theme={"transparent"}
              size={"small"}
              onClick={handleDeselectAll}
            >
              De-select all
            </Button>
          </div>
          {
            <div className={styles.submitButtonContainer}>
              <Button
                className={styles.deleteBtn}
                theme="tertiary"
                destructive
                size="small"
                onClick={() => deleteSelectedMedia()}
              >
                Delete
              </Button>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default MediaGallery;
