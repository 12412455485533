import DateFnsUtils from "@date-io/luxon";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import styles from "../Input/Input.module.scss";

interface Props {
  label: string;
  onChange: (event: MaterialUiPickersDate) => void;
  value: string;
  autofocus?: boolean;
  id?: string;
  format?: string;
  theme?: "light" | "dark";
  size?: "medium" | "x-small";
  disablePast?: boolean;
}

const DatePickerInput: React.FC<Props> = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        value={props.value}
        onChange={props.onChange}
        format={props.format || "MMMM dd, yyyy"}
        label={props.label}
        disablePast={props.disablePast}
        TextFieldComponent={(params) => (
          <label
            htmlFor={props.id}
            className={`${styles.label} ${styles[props.theme ?? ""]} ${
              styles[props.size ?? "medium"]
            } ${params.value && styles.hasValue} ${
              params.value ? styles.hasPlaceholder : ""
            }`}
          >
            <input
              id={params.id}
              type="text"
              className={`${styles.input}`}
              onClick={params.onClick}
              onKeyDown={params.onKeyDown}
              value={params.value as string}
              autoFocus={props.autofocus}
              readOnly
            />
            <span>{params.label}</span>
          </label>
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerInput;
