import * as React from "react";

const ToolbarFontColorIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path d="M0 15.417H20V18.7503H0V15.417Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5835 12.9167L9.16683 1.25H10.8335L15.4085 12.9167H13.5335L12.6002 10.4167H7.39183L6.4585 12.9167H4.5835ZM10.0001 3.47503L8.01681 8.75003H11.9835L10.0001 3.47503Z"
    />
  </svg>
);

export default ToolbarFontColorIcon;
