import mainStyles from "../Dashboard.module.scss";
import CharacterInput from "../../../components/Input/CharacterInput";
import searchIcon from "../../../resources/images/search.svg";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import ToggleSelect from "../../../shared/shared-with-mobile/components/ToggleSelect/ToggleSelect";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReadinessScoresContext } from "../../../shared/shared-with-mobile/providers/readinessScores.provider";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import { DashboardStatsGrid } from "../../../components/DashboardStatsGrid/DashboardStatsGrid";
import dashboardStatsStyles from "../../../components/DashboardStatsGrid/DashboardStatsGrid.module.scss";
import OverallReadiness from "../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/OverallReadiness";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import PlayerModal from "../Components/PlayerModal/PlayerModal";
import Avatar from "../../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import CircularSpinner from "../../../components/Loaders/CircularSpinner";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { isEmpty } from "lodash";
import { getAbbreviatedDisplayName } from "../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import { ReadinessTypeEnum } from "../../../generated/from-api/models/enums/readiness-type.enum";
import { PlayCategoryEnum } from "../../../generated/from-api/models/enums/play-category.enum";
import { PositionFiltersMap } from "../../../shared/shared-with-mobile/functions";
import PlayPlaybook from "./Playbook-components/PlayDrillIn/PlayPlaybook";
import { playersFilter } from "../../../utils/playerFilter.utils";
import ReadinessOverTime from "../../../shared/shared-with-mobile/dashboard-widgets/ReadinessOverTime/ReadinessOverTime";
import {
  match,
  RouteComponentProps,
  useHistory,
  withRouter,
} from "react-router";
import { PlayTypeEnum } from "../../../generated/from-api/models/enums/play-type.enum";
import GenericConfirmationModal from "../../../components/GenericConfirmationModal/ConfirmationModal";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import Button from "../../../components/Button/Button";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";

const playsFilter = [
  ReadinessTypeEnum.RUN,
  ReadinessTypeEnum.PASS,
  ReadinessTypeEnum.DEFENSE,
];
const formationsFilter = [
  ReadinessTypeEnum.OFFENSIVE_FORMATIONS,
  ReadinessTypeEnum.DEFENSIVE_FORMATIONS,
];

type PlayerRow = {
  playerFirstName: string;
  playerLastName: string;
  playerId: string;
  playerUserId: string;
  positions: string;
  Run: number;
  Pass: number;
  Defense: number;
  OffensiveFormation: number;
  DefensiveFormation: number;
  // SpecialTeams: number;
  Overall: number;
};
type PlayRow = {
  playName: string;
  playId: string;
  category: PlayCategoryEnum;
  readiness: number;
};
type FormationRow = {
  formationName: string;
  formationId: string;
  formationType: PlayTypeEnum;
  readiness: number;
};

interface Props extends RouteComponentProps {
  match: match<MatchParams>;
}
interface MatchParams {
  id: string;
}

enum PlaybookFilters {
  Players = "Players",
  Plays = "Plays",
  Formations = "Formations",
}

const DashboardPlaybook: React.FC<Props> = ({ match }) => {
  const { currentTeam } = useContext(TeamContext);
  const { dispatchModal, dispatchToast } = useContext(UIContext);
  const {
    loading,
    playerPlayMetrics,
    playerFormationMetrics,
    readinessScores,
    teamMetricsOverTime,
    getReadinessScoreForUserByType,
    setPlayerOverallMetrics,
    setPlayerPlayMetrics,
    setReadinessScores,
  } = useContext(ReadinessScoresContext);
  const { currentPlayersAsArray } = useContext(PlayersContext);
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState(PositionFiltersMap[0]);
  const [searchText, setSearchText] = useState("");

  const [
    selectedFilterForPlayersAndPlays,
    setSelectedFilterForPlayersAndPlays,
  ] = useState<PlaybookFilters>(PlaybookFilters.Players);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [playbookCategories] = useState([
    {
      label: "All",
      value: "",
    },
    {
      label: ReadinessTypeEnum.RUN,
      value: ReadinessTypeEnum.RUN,
    },
    {
      label: ReadinessTypeEnum.PASS,
      value: ReadinessTypeEnum.PASS,
    },
    {
      label: ReadinessTypeEnum.DEFENSE,
      value: ReadinessTypeEnum.DEFENSE,
    },
    {
      label: "Offensive Formations",
      value: ReadinessTypeEnum.OFFENSIVE_FORMATIONS,
    },
    {
      label: "Defensive Formations",
      value: ReadinessTypeEnum.DEFENSIVE_FORMATIONS,
    },
  ]);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [showPlayDrillIn, setShowPlayDrillIn] = useState(false);
  const [playId, setPlayId] = useState<string>("");
  const [formationId, setFormationId] = useState<string>("");

  const [player, setPlayer] = useState<PlayerModel>();
  const [dataForPlayerStatsGrid, setDataForPlayerStatsGrid] = useState<
    PlayerRow[]
  >();
  const [dataForPlaysStatsGrid, setDataForPlayStatsGrid] = useState<
    PlayRow[]
  >();
  const [dataForFormationsStatsGrid, setDataForFormationsStatsGrid] = useState<
    FormationRow[]
  >();

  const PlayTypeToReadinessType: any = {
    Offensive: "OffensiveFormations",
    Defensive: "DefensiveFormations",
  };

  useEffect(() => {
    if (
      currentPlayersAsArray &&
      playerPlayMetrics &&
      readinessScores &&
      !isEmpty(playerPlayMetrics) &&
      playerFormationMetrics &&
      !isEmpty(playerFormationMetrics)
    ) {
      const allPlayers = currentPlayersAsArray;
      const playerRows: PlayerRow[] = allPlayers
        .filter((player: PlayerModel) => !player.hideOnLeaderBoard)
        .map((p) => {
          return {
            playerFirstName: p.firstName,
            playerLastName: p.lastName,
            playerId: p.id ? p.id : "",
            playerUserId: p.userId,
            positions: p.positions ? p.positions : "",
            Run: getReadinessScoreForUserByType(
              "category",
              ReadinessTypeEnum.RUN,
              p.userId
            ),
            Pass: getReadinessScoreForUserByType(
              "category",
              ReadinessTypeEnum.PASS,
              p.userId
            ),
            Defense: getReadinessScoreForUserByType(
              "category",
              ReadinessTypeEnum.DEFENSE,
              p.userId
            ),
            OffensiveFormation: getReadinessScoreForUserByType(
              "formation",
              ReadinessTypeEnum.OFFENSIVE_FORMATIONS,
              p.userId
            ),
            DefensiveFormation: getReadinessScoreForUserByType(
              "formation",
              ReadinessTypeEnum.DEFENSIVE_FORMATIONS,
              p.userId
            ),
            Overall: getReadinessScoreForUserByType(
              "category",
              ReadinessTypeEnum.OVERALL,
              p.userId
            ),
          };
        });
      setDataForPlayerStatsGrid(playerRows);

      const formationsMap: Map<
        string,
        { id: string; formationName: string; formationType: string }
      > = new Map();
      playerFormationMetrics.forEach((f) => {
        formationsMap.set(f.formationId, {
          id: f.formationId,
          formationName: f.formationName,
          formationType: f.formationType,
        });
      });
      const formationsArray = Array.from(formationsMap.values());
      const formationsRows: FormationRow[] = formationsArray.map(
        (formation) => {
          const scoreSum = playerFormationMetrics.reduce((prev, cur) => {
            if (cur.formationId === formation.id) {
              return prev + +cur.readinessScore;
            } else {
              return prev;
            }
          }, 0);
          return {
            formationId: formation.id,
            formationName: formation.formationName,
            formationType: formation.formationType as PlayTypeEnum,
            readiness:
              currentPlayersAsArray.length == 0
                ? 0
                : scoreSum / currentPlayersAsArray.length,
          };
        }
      );
      setDataForFormationsStatsGrid(formationsRows);

      const playsMap: Map<
        string,
        { id: string; playName: string; category: string }
      > = new Map();
      playerPlayMetrics.forEach((p) => {
        playsMap.set(p.playId, {
          id: p.playId,
          playName: p.playName,
          category: p.category,
        });
      });
      const playsArray = Array.from(playsMap.values());
      const playRows: PlayRow[] = playsArray.map((play) => {
        const scoreSum = playerPlayMetrics.reduce((prev, cur) => {
          if (cur.playId == play.id) {
            return prev + +cur.readinessScore;
          } else {
            return prev;
          }
        }, 0);
        const currentPlayersToDisplay = currentPlayersAsArray.filter(
          (player: PlayerModel) => !player.hideOnLeaderBoard
        );
        return {
          playId: play.id,
          playName: play.playName,
          category: play.category as PlayCategoryEnum,
          readiness:
            currentPlayersToDisplay.length == 0
              ? 0
              : scoreSum / currentPlayersToDisplay.length,
        };
      });
      setDataForPlayStatsGrid(playRows);
    }
  }, [
    currentPlayersAsArray,
    readinessScores,
    playerPlayMetrics,
    playerFormationMetrics,
  ]);

  useEffect(() => {
    searchGridResult(searchText);
  }, [selectedFilterForPlayersAndPlays, selectedFilter]);

  const searchGridResult = (event: any) => {
    setSearchText(event);
  };

  const onRowClick = (id: string) => {
    setPlayer(
      currentPlayersAsArray.find((player: PlayerModel) => player.id === id)
    );
    setShowPlayerModal(true);
  };

  const onPlayOrFormationRowClick = (
    id: string,
    indicator: PlaybookFilters
  ) => {
    if (indicator === PlaybookFilters.Players) {
      return;
    }
    history.push(`/dashboard/playbook/${id}`);
    if (indicator === PlaybookFilters.Plays) {
      setPlayId(id);
      setFormationId("");
    } else {
      setFormationId(id);
      setPlayId("");
    }
    setShowPlayDrillIn(true);
  };

  useEffect(() => {
    if (match.params.id) {
      if (selectedFilterForPlayersAndPlays === PlaybookFilters.Plays) {
        setPlayId(match.params.id);
      } else {
        setFormationId(match.params.id);
      }
      setShowPlayDrillIn(true);
    } else {
      setShowPlayDrillIn(false);
    }
  }, [match.params.id]);

  const columnsForPlayers = useMemo(
    () => [
      {
        name: "PLAYER",
        selector: (row: PlayerRow) => row.playerId,
        cell: (row: PlayerRow) => {
          return (
            <div
              className={`${dashboardStatsStyles.clickableCell} ${dashboardStatsStyles.flexRow}`}
              onClick={() => onRowClick(row.playerId)}
            >
              <div className={dashboardStatsStyles.usreListImg}>
                <Avatar userId={row.playerId} small type="player" />
              </div>

              <span className={dashboardStatsStyles.playerName}>
                {getAbbreviatedDisplayName(
                  row.playerFirstName,
                  row.playerLastName
                )}
              </span>
              {row.positions && (
                <div className={dashboardStatsStyles.playerPosition}>
                  {row.positions}
                </div>
              )}
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "RUN PLAYS",
        selector: (row: PlayerRow) => +row.Run,
        format: (row: PlayerRow) => `${row.Run.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
      {
        name: "PASS PLAYS",
        selector: (row: PlayerRow) => +row.Pass,
        format: (row: PlayerRow) => `${row.Pass.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
      {
        name: "DEFENSE PLAYS",
        selector: (row: PlayerRow) => +row.Defense,
        format: (row: PlayerRow) => `${row.Defense.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
      {
        name: "FORMATIONS",
        selector: (row: PlayerRow) =>
          +row.OffensiveFormation + +row.DefensiveFormation,
        format: (row: PlayerRow) =>
          `${((row.OffensiveFormation + row.DefensiveFormation) / 2).toFixed(
            2
          )}%`,
        sortable: true,
        right: true,
      },
      {
        name: "PLAYBOOK READINESS",
        selector: (row: PlayerRow) => +row.Overall,
        format: (row: PlayerRow) => `${row.Overall.toFixed(2)}%`,
        sortable: true,
        right: true,
        grow: 2,
      },
    ],
    [currentPlayersAsArray]
  );

  const columnsForFormations = useMemo(
    () => [
      {
        name: "FORMATION NAME",
        selector: (row: FormationRow) => row.formationName,
        cell: (row: FormationRow) => {
          return (
            <div
              className={dashboardStatsStyles.clickableCell}
              onClick={() =>
                onPlayOrFormationRowClick(
                  row.formationId,
                  PlaybookFilters.Formations
                )
              }
            >
              <span className={dashboardStatsStyles.playerName}>
                {row.formationName}
              </span>
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "UNIT",
        selector: (row: FormationRow) => row.formationType,
        sortable: true,
        right: true,
      },
      {
        name: "TEAM PERFORMANCE (ALL TIME)",
        selector: (row: FormationRow) => row.readiness,
        format: (row: FormationRow) => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [currentPlayersAsArray]
  );

  const columnsForPlays = useMemo(
    () => [
      {
        name: "PLAY NAME",
        selector: (row: PlayRow) => row.playName,
        cell: (row: PlayRow) => {
          return (
            <div
              className={dashboardStatsStyles.clickableCell}
              onClick={() =>
                onPlayOrFormationRowClick(row.playId, PlaybookFilters.Plays)
              }
            >
              <span className={dashboardStatsStyles.playerName}>
                {row.playName}
              </span>
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "UNIT",
        selector: (row: PlayRow) => row.category,
        sortable: true,
        right: true,
      },
      {
        name: "TEAM PERFORMANCE (ALL TIME)",
        selector: (row: PlayRow) => row.readiness,
        format: (row: PlayRow) => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [currentPlayersAsArray]
  );

  const onClose = () => {
    history.push("/dashboard/playbook");
  };

  const openResetScoresModal = () => {
    if (currentTeam && currentTeam.id) {
      dispatchModal({
        title: "Reset Playbook Scores for this Team",
        open: true,
        body: (
          <GenericConfirmationModal
            message="Are you sure you want to reset the team's performance for Playbook? Players will lose their progress on all Playbook, but Flashcards & Lessons performance will not be affected. This may take up to 24 hours to reflect across all dashboards."
            confirmLabel="Reset Scores"
            cancelLabel="Cancel"
            actionCallback={async () => {
              try {
                await APIService.DASHBOARD.RESET_SCORES(
                  currentTeam.id as string
                );
                const playerOverallMetrics = await APIService.DASHBOARD.GET_PLAYER_OVERALL_METRICS(
                  currentTeam.id as string
                );
                if (playerOverallMetrics) {
                  setPlayerOverallMetrics(
                    playerOverallMetrics.overallReadinessList
                  );
                }
                const playerPlayMetrics = await APIService.DASHBOARD.GET_PLAYER_PLAY_METRICS(
                  currentTeam.id as string
                );
                if (playerPlayMetrics) {
                  setPlayerPlayMetrics(playerPlayMetrics);
                }
                const dashboardMetrics = await APIService.DASHBOARD.GET_DASHBOARD_METRICS(
                  currentTeam.id as string
                );
                if (dashboardMetrics.playersWithReadinessScores) {
                  // data for dashboard v1
                  setReadinessScores(
                    dashboardMetrics.playersWithReadinessScores
                  );
                }
                dispatchToast({
                  type: "success",
                  message: "Scores reset.",
                });
              } catch (e) {
                dispatchToast({
                  type: "error",
                  message: "Error resetting scores.",
                });
              }
            }}
          />
        ),
      });
    }
  };

  return loading ? (
    <CircularSpinner />
  ) : !showPlayDrillIn ? (
    <div>
      <div className={mainStyles.dashboardHeader}>
        <p className={mainStyles.dashboardHeader__Title}>Playbook Readiness</p>
        <Button
          type={"button"}
          theme={"secondary"}
          size={"small"}
          onClick={openResetScoresModal}
        >
          Reset Playbook Scores
        </Button>
      </div>

      <div className={mainStyles.chartCardRow}>
        <ReadinessOverTime
          title="Team Playbook Readiness"
          xLabel="Date"
          yLabel="Readiness"
          ySymbol="%"
          data={
            teamMetricsOverTime
              ? [
                  {
                    id: "line1",
                    data: teamMetricsOverTime.map((t) => {
                      return {
                        x: t.created,
                        y: +t.playbookReadinessScore,
                      };
                    }),
                  },
                ]
              : []
          }
        />
        <OverallReadiness
          title="Playbook Readiness Distribution"
          type="Bar-Distribution"
          isLabel={true}
          xLabel="Ready"
          yLabel="% of Players"
          ySymbol="%"
          scoreType="playbookReadinessScore"
        />
        <OverallReadiness
          title="Playbook Readiness by Unit"
          type="Bar-Unit"
          xLabel="Unit"
          yLabel="Readiness"
          ySymbol="%"
          scoreType="playbookReadinessScore"
        />
      </div>

      <div className={mainStyles.searchAndFilterBtn}>
        <div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Search"
            id="search"
            size="x-small"
            icon={searchIcon}
            clearButton
            value={searchText}
            onChange={(e) => searchGridResult(e.currentTarget.value)}
          />
        </div>
        <div className={mainStyles.toggleTableData}>
          <ToggleSelect
            options={Object.values(PlaybookFilters)}
            selectedOption={selectedFilterForPlayersAndPlays}
            background="#151724"
            borderColor="#151724"
            onChange={(newSelection: string) => {
              setSelectedCategory("");
              setSelectedFilterForPlayersAndPlays(
                newSelection as PlaybookFilters
              );
            }}
          />
        </div>
        <div className={mainStyles.filterBtns}>
          {selectedFilterForPlayersAndPlays === PlaybookFilters.Players ? (
            <ToggleSelect
              options={PositionFiltersMap.map((f) => f.label)}
              selectedOption={selectedFilter.label}
              background="#151724"
              borderColor="#151724"
              onChange={(label: string) => {
                const newFilter = PositionFiltersMap.find(
                  (f) => f.label == label
                );
                if (newFilter) {
                  setSelectedFilter(newFilter);
                }
              }}
            />
          ) : selectedFilterForPlayersAndPlays ===
            PlaybookFilters.Formations ? (
            <SingleSelect
              id="playbookCategory"
              placeholder="Category *"
              alwaysOpen={false}
              options={playbookCategories.filter(
                (category) =>
                  isEmpty(category.value) ||
                  formationsFilter.includes(category.value as ReadinessTypeEnum)
              )}
              className={mainStyles.selectCategories}
              onChange={(e) => {
                setSelectedCategory(e.currentTarget.value);
              }}
              value={selectedCategory}
            />
          ) : (
            <SingleSelect
              id="playbookCategory"
              placeholder="Category *"
              alwaysOpen={false}
              options={playbookCategories.filter(
                (category) =>
                  isEmpty(category.value) ||
                  playsFilter.includes(category.value as ReadinessTypeEnum)
              )}
              className={mainStyles.selectCategories}
              onChange={(e) => {
                setSelectedCategory(e.currentTarget.value);
              }}
              value={selectedCategory}
            />
          )}
        </div>
      </div>
      <br />
      {selectedFilterForPlayersAndPlays === PlaybookFilters.Players ? (
        <>
          <div>
            <DashboardStatsGrid
              data={dataForPlayerStatsGrid?.filter(
                playersFilter({
                  searchString: searchText,
                  filterByPosition: selectedFilter.value,
                })
              )}
              columns={columnsForPlayers}
            />
          </div>
          {player && (
            <PlayerModal
              player={player}
              visible={showPlayerModal}
              defaultTab={"playbook"}
              close={() => setShowPlayerModal(false)}
            />
          )}
        </>
      ) : selectedFilterForPlayersAndPlays === PlaybookFilters.Formations ? (
        <div>
          <DashboardStatsGrid
            data={dataForFormationsStatsGrid?.filter((m: any) => {
              return (
                (selectedCategory
                  ? PlayTypeToReadinessType[m.formationType] == selectedCategory
                  : true) &&
                (searchText
                  ? ("" + m.formationName)
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase(), 0) !== -1
                  : true)
              );
            })}
            columns={columnsForFormations}
          />
        </div>
      ) : (
        <div>
          <DashboardStatsGrid
            data={dataForPlaysStatsGrid?.filter((m: any) => {
              return (
                (selectedCategory ? m.category == selectedCategory : true) &&
                (searchText
                  ? ("" + m.playName)
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase(), 0) !== -1
                  : true)
              );
            })}
            columns={columnsForPlays}
          />
        </div>
      )}
    </div>
  ) : (
    <PlayPlaybook
      playId={playId}
      formationId={formationId}
      close={() => onClose()}
    />
  );
};

export default withRouter(DashboardPlaybook);
