import * as React from "react";

const MicrophoneIcon: React.FC = () => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 12.5C8.66 12.5 9.99 11.16 9.99 9.5L10 3.5C10 1.84 8.66 0.5 7 0.5C5.34 0.5 4 1.84 4 3.5V9.5C4 11.16 5.34 12.5 7 12.5ZM5.8 3.4C5.8 2.74 6.34 2.2 7 2.2C7.66 2.2 8.2 2.74 8.2 3.4L8.19 9.6C8.19 10.26 7.66 10.8 7 10.8C6.34 10.8 5.8 10.26 5.8 9.6V3.4ZM7 14.6C9.76 14.6 12.3 12.5 12.3 9.5H14C14 12.92 11.28 15.74 8 16.22V19.5H6V16.22C2.72 15.73 0 12.91 0 9.5H1.7C1.7 12.5 4.24 14.6 7 14.6Z"
    />
  </svg>
);

export default MicrophoneIcon;
