import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import styles from "./LoginSignup.module.scss";
import Logo from "../../resources/images/Logo.svg";
import React from "react";
import CharacterInput from "../../components/Input/CharacterInput";
import Button from "../../components/Button/Button";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import { parseQuery } from "../../utils/functions";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";

interface FormElements extends HTMLFormControlsCollection {
  newPassword: HTMLInputElement;
  newPasswordCopy: HTMLInputElement;
}

const ResetPassword: React.FC<RouteComponentProps> = ({ history }) => {
  const { dispatchToast } = useContext(UIContext);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordCopy, setNewPasswordCopy] = useState<string>("");
  const [passwordMatchError, setPasswordMatchError] = useState<boolean>(false);

  useEffect(() => {
    if (
      (newPassword.length > 0,
      newPasswordCopy.length > 0 && newPassword !== newPasswordCopy)
    ) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  }, [newPassword, newPasswordCopy]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const search = parseQuery(history.location.search.replace("?", "")) as {
      token: string;
      email: string;
    };
    const {
      newPassword: { value: password },
      newPasswordCopy: { value: passwordCopy },
    } = (event.currentTarget as HTMLFormElement).elements as FormElements;

    if (
      (password.length > 0,
      passwordCopy.length > 0 && password !== passwordCopy)
    ) {
      setPasswordMatchError(true);
      return null;
    } else {
      setPasswordMatchError(false);
      const response = await APIService.USER.RESET_PASSWORD(
        search.email,
        search.token,
        password
      );

      if (response.verified) {
        dispatchToast({
          type: "success",
          message: "Password successfully reset.",
        });
        history.push("/login");
      } else {
        dispatchToast({
          type: "error",
          message: response.expired
            ? "Token expired. Check your email for new link."
            : "Verification failed. Please try again.",
        });
      }
    }
  };

  return (
    <div className={styles.login}>
      <section className={styles.content}>
        <img src={Logo} alt="Team Nation" className={styles.logo} />
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.title}>Enter New Password</div>
          <div className={styles.inputWrapper}>
            <CharacterInput
              id="newPassword"
              type={INPUT_TYPES.PASSWORD}
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.currentTarget.value)}
            />

            <CharacterInput
              id="newPasswordCopy"
              type={INPUT_TYPES.PASSWORD}
              placeholder="Confirm Password"
              onChange={(e) => setNewPasswordCopy(e.currentTarget.value)}
            />
            {passwordMatchError && (
              <span className={styles.passwordMatchError}>
                Passwords do not match
              </span>
            )}
          </div>
          <div className={styles.formFooter}>
            <Button
              type={"link"}
              theme="secondary"
              size={"small"}
              linkOptions={{ to: "/login" }}
            >
              Cancel
            </Button>
            <Button size={"small"} buttonType={"submit"}>
              Save
            </Button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ResetPassword;
