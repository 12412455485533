import { CustomQuizModel } from "../models/custom-quiz.model";
import { MediaModel } from "../models/media.model";
import { InstallModel } from "../models/install.model";
import { PlayModel } from "../models/play.model";

export const MediaUtility = {
  /**
   * Use this method to identify which media is not being used by your quizzes.
   *
   * @param fullMediaList The list of your team's media (or the global media - if you are checking against the global quiz library)
   * @param listOfAllQuizzes The list of your team's quizzes (or the global quizzes)
   * @returns
   */
  getListOfUnusedMedia: (
    fullMediaList: MediaModel[],
    listOfAllQuizzes: CustomQuizModel[]
  ): string[] => {
    const unusedMediaIds: string[] = [];
    const mediaIdsUsedByQuizzes = MediaUtility.getListOfMediaIdsUsedByQuizzes(
      listOfAllQuizzes
    );
    const mediaIdList = fullMediaList.map((m) => <string>m.id);

    mediaIdList.forEach((id) => {
      if (mediaIdsUsedByQuizzes.findIndex((val) => val === id) == -1) {
        unusedMediaIds.push(id);
      }
    });
    return unusedMediaIds;
  },

  /**
   *
   * Use this method to detect media that no longer exists which is being referenced by a quiz in the quizzesToCheck.
   *
   * @param fullMediaList
   * @param quizzesToCheck
   * @returns
   */
  identifyMediaThatNoLongerExists: (
    fullMediaList: MediaModel[],
    itemsToCheck: CustomQuizModel[] | InstallModel[],
    quizMap?: Record<string, CustomQuizModel>
  ): string[] => {
    const nonExistentMediaIds: string[] = [];
    let mediaIdsUsed;
    if (!quizMap) {
      mediaIdsUsed = MediaUtility.getListOfMediaIdsUsedByQuizzes(
        itemsToCheck as CustomQuizModel[]
      );
    } else {
      mediaIdsUsed = MediaUtility.getListOfMediaIdsUsedByInstalls(
        itemsToCheck as InstallModel[],
        quizMap
      );
    }
    const mediaIdList = fullMediaList.map((m) => <string>m.id);
    mediaIdsUsed.forEach((id) => {
      if (mediaIdList.findIndex((val) => val === id) == -1) {
        nonExistentMediaIds.push(id);
      }
    });
    return nonExistentMediaIds;
  },

  /**
   * Use this method to get the list of unique IDs of all media referenced by the quizzes.
   *
   * @param quizzes
   * @returns
   */
  getListOfMediaIdsUsedByQuizzes: (quizzes: CustomQuizModel[]): string[] => {
    const ids: Record<string, string> = {};
    quizzes.forEach((quiz) => {
      if (!quiz) return;
      quiz.questions.forEach((question) => {
        const allOptions = [
          ...question.correctOptions,
          ...question.incorrectOptions,
        ];
        // extract all media ids (we will ignore null/undefined media ids)
        const mediaIds = allOptions.map((opt) => opt.mediaId);
        mediaIds.forEach((id) => {
          if (id) {
            ids[id] = id;
          }
        });
      });
    });
    const resultArray: string[] = [];
    for (const [key] of Object.entries(ids)) {
      if (key) {
        resultArray.push(key);
      }
    }
    return resultArray;
  },
  getListOfMediaIdsUsedByInstalls: (
    installs: InstallModel[],
    quizMap?: Record<string, CustomQuizModel>
  ): string[] => {
    const resultArray: string[] = [];
    installs.forEach((install) => {
      install.slides.forEach(async (slide) => {
        if (!slide.customQuizId) {
          if (slide.narrationMediaId) {
            resultArray.push(slide.narrationMediaId);
          }
          if (slide.question) {
            if (slide.question.questionMediaId) {
              resultArray.push(slide.question.questionMediaId);
            }
            const allOptions = [
              ...slide.question.correctOptions,
              ...slide.question.incorrectOptions,
            ];
            allOptions.forEach((option) => {
              if (option.mediaId) {
                resultArray.push(option.mediaId);
              }
            });
          }
          slide?.slideElements?.forEach(
            (slideElement) =>
              slideElement.mediaId && resultArray.push(slideElement.mediaId)
          );
        } else {
          if (quizMap) {
            const quiz = quizMap[slide.customQuizId];
            if (quiz) {
              const quizResult = MediaUtility.getListOfMediaIdsUsedByQuizzes([
                quiz,
              ]);
              resultArray.concat(quizResult);
            }
          }
        }
      });
    });
    return resultArray;
  },
  getListOfMediaIdsUsedByPlays: (plays?: PlayModel[]): string[] => {
    const resultArray: string[] = [];

    if (plays) {
      for (const play of plays) {
        if (play.mediaId) {
          resultArray.push(play.mediaId);
        }
      }
    }

    return resultArray;
  },
};
