import { useLayoutEffect, useState } from "react";
import omit from "lodash/omit";

import { ShapeModel, Dimension } from "./drawing.types";
import { DrawablePlayerModel } from "../../../generated/from-api/models/drawable/drawablePlayer.model";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { FormationModel } from "../../../generated/from-api/models/drawable/formation.model";
import { PlayTypeEnum } from "../../../generated/from-api/models/enums/play-type.enum";

/**
 * @description Takes in absolute coordinates and the canvas' container element, returns relative coordinates
 * @param {number} clientX - x coordinate of mouse or touch event, relative to the entire screen
 * @param {number} clientY - y coordinate of mouse or touch event, relative to the entire screen
 * @param {HTMLElement} containerElement - the element for which you want to compute relative coordinates
 * @returns {number[]} [x, y] representing where click fell within containerElement
 * and x and y are both between 0 and 1, inclusive
 */
export const getRelativeCoordinates: (
  clientX: number,
  clientY: number,
  containerElement: HTMLElement
) => number[] = (clientX, clientY, containerElement) => {
  // The actual canvas element lives nested in a Konva "Stage" component
  // so we can't attach a ref directly to the canvas element
  const canvasElement = containerElement.querySelector("canvas");

  if (!canvasElement) {
    return [0, 0];
  }

  const canvasRect = canvasElement.getBoundingClientRect();
  const layerX = clientX - canvasRect.left;
  const layerY = clientY - canvasRect.top;

  let x = layerX / canvasRect.width;
  x = Math.max(x, 0);
  x = Math.min(x, 1);

  let y = layerY / canvasRect.height;
  y = Math.max(y, 0);
  y = Math.min(y, 1);

  return [x, y];
};

/**
 * @description Takes in a line of relative coordinates and converts to absolute
 * All lines (whether relative or absolute) are formatted like [x1, y1, x2, y2, ..., xn, yn]
 * e.g. convert [0, 0, 1, 1] to [0, 0, 100, 200] if width = 100 and height = 200
 * @param {number} line - array of relative coordinates (numbers between 0 and 1)
 * @param {number} width - width of canvas in pixels
 * @param {number} height - height of canvas in pixels
 * @returns {number[]} array of absolute coordinates in pixels
 */
export const convertRelativeLineToAbsolute: (
  line: number[],
  width: number,
  height: number
) => number[] = (line, width, height) => {
  return line.map((coordinate, index) => {
    const multiplier = index % 2 === 0 ? width : height;
    return coordinate * multiplier;
  });
};

export const convertAbsoluteLineToRelative: (
  line: number[],
  width: number,
  height: number
) => number[] = (line, width, height) => {
  return line.map((coordinate, index) => {
    const multiplier = 1 / (index % 2 === 0 ? width : height);
    return coordinate * multiplier;
  });
};

/**
 * @description Given a wrapping template ref, return a width/height (5:3) ratio that will fit inside it. Also, keep track of screen resizes and update the canvasWidth and canvasHeight accordingly.
 * @param {React.RefObject<HTMLDivElement>} containerRef A ref of the container that will contain a play canvas
 * @returns {{containerWidth: number; containerHeight: number;}} {containerWidth, containerHeight} representing the 5:3 width to height ratio that fits within the current container
 */
export const useContainerDimensions = (
  containerRef: React.RefObject<HTMLDivElement>,
  shouldUseTimeout = false
): {
  containerWidth: number;
  containerHeight: number;
} => {
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const playAspectRatio = 5 / 3; // width to height ration of the play drawer/viewer canvas

  useLayoutEffect(() => {
    let isCancelled = false;
    const updateStageDimensions = () => {
      const width = containerRef?.current?.clientWidth;
      const height = containerRef?.current?.clientHeight;
      const topToolbarHeight = 60;
      const viewportHeight = window.innerHeight;

      if (width) {
        const generatedHeight = width / playAspectRatio;
        const generatedWidth =
          (height || viewportHeight - topToolbarHeight) * playAspectRatio;

        setContainerWidth(
          generatedHeight + topToolbarHeight > viewportHeight
            ? generatedWidth
            : width
        );
        setContainerHeight(
          generatedHeight + topToolbarHeight > viewportHeight
            ? viewportHeight - topToolbarHeight
            : generatedHeight
        );
      } else {
        // if animating, sometimes we need to wait another while before calling updateStateDimensions..
        // we might have to call this multiple times.. so we check if width has finally been set ,
        // if so, we will get valid values eventually

        if (!isCancelled) {
          setTimeout(() => {
            updateStageDimensions();
          }, 200);
        }
      }
    };

    if (shouldUseTimeout) {
      // An Ionic bug causes containerRef to still have 0 width and 0 height
      // even after initial render and paint (when containerRef is nested in an IonContent).
      // setTimeout fixes it. TODO: Find better solution.
      setTimeout(() => {
        updateStageDimensions();
      }, 0);
    } else {
      updateStageDimensions();
    }

    window.addEventListener("resize", updateStageDimensions);

    return () => {
      isCancelled = true;
      window.removeEventListener("resize", updateStageDimensions);
    };
  }, []);

  return { containerWidth, containerHeight };
};

/**
 * @description Translates each point in a line by a given amount
 * @param {number} line - flat array of coordinates
 * @param {number[]} translation - amount to change each point by e.g. [xChange, yChange]
 * @returns {number[]} flat array of coordinates
 */
export const translateLine: (
  line: number[],
  translation: number[]
) => number[] = (line, translation) => {
  const [xShift, yShift] = translation;

  return line.map((coordinate, index) => {
    const shift = index % 2 === 0 ? xShift : yShift;
    return coordinate + shift;
  });
};

/**
 * @description Translates each point in a line, anchoring it to a given point
 * @param {number} line - flat array of coordinates
 * @param {number[]} point - anchor point, first point in line will be set to this point e.g. [x, y]
 * @returns {number[]} flat array of coordinates
 */
export const translateLineByPoint: (
  line: number[],
  point: number[]
) => number[] = (line, point) => {
  const [xStart, yStart] = line;
  const [x, y] = point;
  const xDiff = x - xStart;
  const yDiff = y - yStart;
  return translateLine(line, [xDiff, yDiff]);
};

/**
 * @description Flip a line horizontally or vertically
 * @param {number} line - flat array of coordinates
 * @param {string} dimension - x or y
 * @returns {number[]} flat array of coordinates
 */
export const flipLineByDimension: (
  line: number[],
  dimension: "x" | "y"
) => number[] = (line, dimension) => {
  if (dimension === "x") {
    return line.map((coordinate, index) =>
      index % 2 === 0 ? 1 - coordinate : coordinate
    );
  } else {
    return line.map((coordinate, index) =>
      index % 2 === 0 ? coordinate : 1 - coordinate
    );
  }
};

/**
 * @description Apply both a lower limit and and upper limit to a value
 * @param {number} value
 * @param {number} min - lower limit, inclusive
 * @param {number} max - upper limit, inclusive
 * @returns {number} a number which is guaranteed to fall within the passed in min and max
 */
export const lockNumberInRange: (
  value: number,
  min: number,
  max: number
) => number = (value, min, max) => {
  let locked = Math.max(value, min);
  locked = Math.min(locked, max);
  return locked;
};

/**
 * @description Convert player data to shape data
 * @param player {DrawablePlayerModel}
 * @param playType {PlayTypeEnum}
 * @returns {ShapeModel}
 */
export const convertPlayerToShape: (
  player: DrawablePlayerModel,
  playType?: PlayTypeEnum
) => ShapeModel = (player, playType) => {
  let shapeType: string;

  if (player.id === "football") {
    shapeType = "football";
  } else if (player.playType === "Offensive") {
    shapeType = player.position === "C" ? "square" : "circle";
  } else {
    shapeType = "triangle";
  }

  let verticalFlip = false;
  if (shapeType === "triangle" && playType && playType !== "Defensive") {
    verticalFlip = true;
  }

  return {
    ...player,
    ellipseRadiusX: player.coverageZoneRadiusX || player.coverageZoneRadius,
    ellipseRadiusY: player.coverageZoneRadiusY || player.coverageZoneRadius,
    type: shapeType,
    text: player.role,
    secondaryText: player.position,
    verticalFlip,
  };
};

/**
 * @description Takes a point, an angle, and a distance to generate another point
 * @param {x} number - x coordinate of a point
 * @param {y} number - y coordinate of a point
 * @param {distance} number - distance, how far the returned point should be from the original point
 * @param {angle} number - in radians. If a vector were drawn beween the input point and the returned point
 * that angle between that vector and the horizontal axis should be this angle
 * @returns {number[]} [x, y] of generated point
 */
export const getNextPoint: (
  x: number,
  y: number,
  distance: number,
  angle: number
) => number[] = (x, y, distance, angle) => {
  const nextX = x + distance * Math.cos(angle);
  const nextY = y + distance * Math.sin(angle);
  return [nextX, nextY];
};

/**
 * @description convert line to segments
 * @param {number[]} line - e.g. [x1, y1, x2, y2, x3, y3]
 * @returns {number[][]} an array of lines, e.g. [[x1, y1, x2, y2], [x2, y2, x3, y3]]
 */
export const convertLineToSegments: (line: number[]) => number[][] = (line) => {
  const segments = [];

  let index = 0;
  while (index < line.length - 2) {
    const segment = [];

    segment.push(line[index]);
    segment.push(line[index + 1]);
    index += 2;
    segment.push(line[index]);
    segment.push(line[index + 1]);

    segments.push(segment);
  }

  return segments;
};

/**
 * @description convert a play to a formation by stripping out player routes, player notes, and various top level play properties
 * @param {PlayModel} play
 * @returns {FormationModel}
 */
export const convertPlayToFormation: (
  play: PlayModel,
  formationName: string
) => FormationModel = (play, formationName) => {
  const formationToSave: FormationModel = {
    id: "",
    teamId: play.teamId,
    name: formationName,
    type: play.playType,
    drawablePlayers: play.drawablePlayers.map((player: DrawablePlayerModel) =>
      omit(player, [
        "notes",
        "route",
        "coverageZoneRadius",
        "coverageZoneRadiusX",
        "coverageZoneRadiusY",
      ])
    ),
    deleted: false,
  };

  return formationToSave;
};

export const flipPlayersByDimension: (
  players: DrawablePlayerModel[],
  dimension: Dimension,
  optionalDimension?: Dimension
) => DrawablePlayerModel[] = (players, dimension, optionalDimension) => {
  const nextPlayers = players.map((shape) => ({
    ...shape,
    [dimension]: 1 - shape[dimension],
    [optionalDimension as Dimension]:
      optionalDimension && 1 - shape[optionalDimension],
    route: shape.route
      ? {
          ...shape.route,
          line: flipLineByDimension(shape.route.line, dimension),
        }
      : shape.route,
  }));

  return nextPlayers;
};

// Taken from: https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
export const hexToRgbA = (hex: string): string => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  return "";
};

/**
 * @description take a rgbA string and return a new rgbA string with a reduced alpha value
 * @param {rgbA} string in the fomat of "rgba(255, 255, 255, 1)"
 * @returns {string} in the format of "rgba(255, 255, 255, 1)"
 */
export const reduceAlpha = (rgbA: string, newAlpha: number): string => {
  const split = rgbA.split(",");
  split[split.length - 1] = `${newAlpha})`;
  return split.join(",");
};

/**
 * @description Takes in a line, and snaps the last two points drawn to specific angles, if within a threshold
 * @param {number[]} line - line is in standard Konva format x1, y1, x2, y2, ..., xn, yn
 * @returns {number[]} line is in standard Konva format x1, y1, x2, y2, ..., xn, yn
 */
export const snapLineAngle: (
  line: number[],
  width: number,
  height: number
) => number[] = (line, width, height) => {
  const lastTwoPoints = line.slice(-4); // slice(-4) gets last 4 items of array
  // convert to standard [x, y] format for each point
  const [xStart, yStart, xEnd, yEnd] = convertRelativeLineToAbsolute(
    lastTwoPoints,
    width,
    height
  );
  const lineDistance = Math.sqrt(
    Math.pow(xEnd - xStart, 2) + Math.pow(yEnd - yStart, 2)
  );

  const lineAngle = Math.atan2(yEnd - yStart, xEnd - xStart);
  const lineAngleDegrees = (lineAngle * 180) / Math.PI;
  const snapAngles = [-180, -90, 0, 90, 180];
  const thresholdAngle = 6;
  // if new point places line within thresholdAngle of one of the snapAngles
  // then adjust new point to ensure the angle ends being exactly one of the snapAngles
  // note, snapping to -180 is effectively the same as snapping to 180, but we need both for the threshold detection

  for (const angle of snapAngles) {
    const min = angle - thresholdAngle;
    const max = angle + thresholdAngle;

    if (lineAngleDegrees >= min && lineAngleDegrees <= max) {
      const angleRadians = (angle * Math.PI) / 180;
      const pointWithCorrectedAngle = getNextPoint(
        xStart,
        yStart,
        lineDistance,
        angleRadians
      );
      const relativePoint = convertAbsoluteLineToRelative(
        pointWithCorrectedAngle,
        width,
        height
      );
      const correctedLine = line
        .slice(0, line.length - 2)
        .concat(relativePoint);
      return correctedLine;
    }
  }

  return line;
};

/**
 * @description Takes in a line, and snaps the last two points drawn to specific angles, if within a threshold
 * this function will mutate the line param
 * @param {number[]} line - line is in standard Konva format x1, y1, x2, y2, ..., xn, yn
 * @returns {number[]} line is in standard Konva format x1, y1, x2, y2, ..., xn, yn
 */
export const snapLineToNearestVerticalYard = (
  line: number[],
  yards: number
): number[] => {
  const [, yValue] = line.slice(-2);
  const oneYardVertically = 1 / yards;
  const newYValue = roundNearest(yValue, oneYardVertically);
  const indexOfLastYValue = line.length - 1;
  line[indexOfLastYValue] = newYValue;
  return line;
};

const roundNearest = (value: number, nearest: number) =>
  Math.round(value / nearest) * nearest;

export const playerOrderSort = (
  a: DrawablePlayerModel,
  b: DrawablePlayerModel,
  type: PlayTypeEnum
): number => {
  return playerPositionValue(a, type) - playerPositionValue(b, type);
};

const playerPositionValue = (
  player: DrawablePlayerModel,
  type: PlayTypeEnum
) => {
  if (player.id === "football") return 2;
  if (player.playType === type) return 1;
  return 0;
};
