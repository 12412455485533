import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { UserProfileModel } from "../../../../generated/from-api/models/user-profile.model";
import { UserRepresentativeModel } from "../../../../generated/from-api/models/user-representative.model";
import { API_BASE_URL } from "../../constants";

export const UserRepresentativeService = {
  GET_LIST_OF_REPRESENTATIVES: async function (): Promise<UserProfileModel[]> {
    return (
      await axios.get(
        `${API_BASE_URL}${APIRoutes.USER_REPRESENTATIVE}/getListOfRepresentatives`
      )
    ).data;
  },

  GET_REPRESENTATIVE_FOR_USER: async function (
    userId: string
  ): Promise<UserRepresentativeModel | undefined> {
    return (
      await axios.get(
        `${API_BASE_URL}${APIRoutes.USER_REPRESENTATIVE}/getRepresentativeForUser`,
        {
          params: {
            id: userId,
          },
        }
      )
    ).data;
  },

  /**
   * Use this to set the representative id for a user
   * Use NULL to unset
   * @param userId
   * @param tnRepresentativeUserId
   * @returns
   */
  POST_REPRESENTATIVE_FOR_USER: async function (
    userId: string,
    tnRepresentativeUserId: string | null
  ): Promise<UserRepresentativeModel | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.USER_REPRESENTATIVE}`, {
        userId,
        tnRepresentativeUserId,
      })
    ).data;
  },
};
