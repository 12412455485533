import React, { useContext } from "react";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";

import styles from "./ConfirmationModal.module.scss";
import Button from "../Button/Button";

interface Props {
  actionCallback: () => void;
  message?: string;
  confirmLabel?: string;
  cancelLabel?: string;
}

const GenericConfirmationModal: React.FC<Props> = ({
  actionCallback,
  message,
  confirmLabel,
  cancelLabel,
}) => {
  const { closeModal } = useContext(UIContext);

  return (
    <div className={styles.ConfirmationModal}>
      <p>{message}</p>
      <div className={styles.buttonRow}>
        <Button
          theme={"tertiary"}
          onClick={() => {
            actionCallback();
            closeModal();
          }}
          destructive
        >
          {confirmLabel}
        </Button>
        <Button onClick={closeModal}>{cancelLabel}</Button>
      </div>
    </div>
  );
};

export default GenericConfirmationModal;
