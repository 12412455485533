import {
  DrawablePlayerModel,
  Position,
} from "../../../generated/from-api/models/drawable/drawablePlayer.model";

export const sortPlayersComparator = (
  playerA: DrawablePlayerModel,
  playerB: DrawablePlayerModel
): number => {
  const orderedPositions: Position[] = [
    "QB",
    "RB",
    "WR",
    "TE",
    "OT",
    "OG",
    "C",
    "DE",
    "DT",
    "LB",
    "CB",
    "FS",
    "SS",
  ];

  for (const position of orderedPositions) {
    if (playerB.position === position && playerA.position !== position) {
      return 1;
    } else if (playerA.position === position && playerB.position !== position) {
      return -1;
    }
  }

  if (playerB.role && !playerA.role) {
    return 1;
  } else if (playerA.role && !playerB.role) {
    return -1;
  }

  return -1;
};
