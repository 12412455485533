import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { CreateGamePlanModel } from "../../../../generated/from-api/models/dtos/create-gamePlan.model";
import { GamePlanContentModel } from "../../../../generated/from-api/models/dtos/gamePlan-content.model";
import { GamePlanModel } from "../../../../generated/from-api/models/gamePlan.model";
import { API_BASE_URL } from "../../constants";

export const GamePlanService = {
  /**
   * Retrieve a list of all gamePlans for a team,
   * @param teamId
   * @returns gamePlan[]
   */
  GET_ALL_GAME_PLANS_FOR_TEAM: async function (
    teamId: string
  ): Promise<GamePlanModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.GAME_PLAN}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   * Retrieve a gamePlan by id with its plays, if the user has permission to view the coach
   * @param gamePlanId
   * @returns gamePlan
   */
  GET: async function (gamePlanId: string): Promise<GamePlanContentModel> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.GAME_PLAN}/${gamePlanId}`)
    ).data;
  },

  /**
   * Use to create a gamePlan with plays
   * @param
   */
  POST: async function (
    gamePlanData: Pick<
      CreateGamePlanModel,
      | "name"
      | "teamId"
      | "displayOnMenu"
      | "expirationDate"
      | "plays"
      | "playSets"
    >
  ): Promise<GamePlanContentModel | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.GAME_PLAN}`, gamePlanData)
    ).data;
  },
  /**
   * Update a gamePlan,also add/remove plays of the gamePlan
   * @param gamePlanId
   * @param dataToUpdate
   * @returns gamePlan with plays
   */
  PUT: async function (
    gamePlanId: string,
    dataToUpdate: Pick<
      CreateGamePlanModel,
      "name" | "displayOnMenu" | "expirationDate" | "plays" | "playSets"
    >
  ): Promise<GamePlanContentModel> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.GAME_PLAN}`, {
        id: gamePlanId,
        ...dataToUpdate,
      })
    ).data;
  },

  /**
   * Delete a gamePlan by id,
   * @param gamePlanId
   * @returns
   */
  DELETE: async function (gamePlanId: string): Promise<{ deleted: boolean }> {
    return (
      await axios.delete(`${API_BASE_URL}${APIRoutes.GAME_PLAN}/${gamePlanId}`)
    ).data;
  },
};
