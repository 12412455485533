import { useEffect, useState } from "react";
import PlayThumbnail from "../../../components/drawing/PlayThumbnail";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { Grid } from "@material-ui/core";
import styles from "./PrintPlays.module.scss";

interface PrintPreviewProps {
  playsPerPage: number;
  playsToPrint: PlayModel[];
}

export const PrintPreview: React.FC<PrintPreviewProps> = ({
  playsPerPage,
  playsToPrint,
}) => {
  const [playsToPrintByPage, setPlaysToPrintByPage] = useState<any>([]);

  useEffect(() => {
    if (playsPerPage > 1) {
      const playsByPage = playsToPrint.reduce(
        (resultArray: any, item, index) => {
          const chunkIndex = Math.floor(index / playsPerPage);

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }

          resultArray[chunkIndex].push(item);

          return resultArray;
        },
        []
      );

      setPlaysToPrintByPage(playsByPage);
    }
  }, [playsPerPage, playsToPrint]);

  if (playsPerPage > 1) {
    return (
      <div className={styles.mainPrintPage}>
        <Grid container direction="column" spacing={3}>
          {playsToPrintByPage.map((playsByPage: [], index: number) => {
            return (
              <div key={index}>
                <Grid
                  key={index}
                  style={{ height: 1080, width: 794 }}
                  className={styles.multiPreview}
                  container
                >
                  {playsByPage.map((play: PlayModel) => (
                    <Grid item key={play.id} xs={playsPerPage === 2 ? 12 : 6}>
                      <h2
                        className={styles.playTitle}
                        style={
                          playsPerPage !== 2
                            ? play.name.length > 58
                              ? {
                                  fontSize: "12px",
                                  lineHeight: "0.8rem",
                                  marginBottom: 0,
                                }
                              : play.name.length > 30
                              ? { fontSize: "12px" }
                              : {}
                            : {}
                        }
                      >
                        {play.name}
                      </h2>
                      <PlayThumbnail play={play} printableView={true} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            );
          })}
        </Grid>
      </div>
    );
  }
  return (
    <div
      className={styles.mainPrintPage}
      style={{ height: playsToPrint.length * 1100 }}
    >
      {playsToPrint.map((play: PlayModel) => (
        <div key={play.id} className={styles.singlePreview}>
          <p>{play.name}</p>

          <PlayThumbnail play={play} printableView={true} />

          <section>
            <h2>Team Notes:</h2>
            <p>{play.notes ? play.notes : "-"}</p>
          </section>
        </div>
      ))}
    </div>
  );
};
