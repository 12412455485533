import React, { useState, useContext, useEffect } from "react";
import classNames from "classnames";

import { LicensesContext } from "../../shared/shared-with-mobile/providers/licenses.provider";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import PdfImport from "../PdfImport/PdfImport";
import RunningProgressBar from "../RunningProgressBar/RunningProgressBar";
import yellowOnboardingGraphic1 from "../../resources/images/yellowOnboardingGraphic1.svg";
import yellowOnboardingGraphic2 from "../../resources/images/yellowOnboardingGraphic2.svg";
import yellowOnboardingGraphicThumb from "../../resources/images/yellowOnboardingGraphicThumb.svg";
import onboardingGraphicAppScreenshots from "../../resources/images/onboardingGraphicAppScreenshots.svg";
import appleAppStoreLink from "../../resources/images/appleAppStoreLinkButtonGraphic.svg";
import googlePlayStoreLink from "../../resources/images/googlePlayStoreLinkButtonGraphic.svg";
import {
  TEAM_LEVELS,
  US_STATES,
} from "../../shared/shared-with-mobile/constants";

import styles from "./OnboardingModal.module.scss";
import CharacterInput from "../Input/CharacterInput";
import Button from "../Button/Button";
import SingleSelect from "../SingleSelect/SingleSelect";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import { TeamLevelEnum } from "../../generated/from-api/models/enums/team-level.enum";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { TeamModel } from "../../generated/from-api/models/team.model";
import { UserProfileModel } from "../../generated/from-api/models/user-profile.model";

export enum OnboardingStep {
  Completed = 0,
  TeamCreation = 1,
  PdfImportChoice = 2,
  PdfImportStart = 3,
  PdfImportChooseFile = 4,
  PdfImportFileFormat = 5,
  PdfImportProcessing = 6,
  PdfImportProcessed = 6.1,
  PdfImportSkipped = 6.2,
  MobileAppLinks = 7,
}

interface Props {
  closeOnboardingModal?: () => void;
  startingStep?: OnboardingStep;
  handlePdfImportCancel?: () => void;
  hideRunningProgressBar?: boolean;
  showBackButtonOnFinalScreen?: boolean;
  showMobileAppLinks?: boolean;
}

const OnboardingModal: React.FC<Props> = ({
  closeOnboardingModal,
  startingStep = OnboardingStep.TeamCreation,
  handlePdfImportCancel,
  hideRunningProgressBar = false,
  showBackButtonOnFinalScreen = false,
  showMobileAppLinks = true,
}) => {
  const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(
    startingStep
  );
  const [teamLevel, setTeamLevel] = useState<TeamLevelEnum | undefined>();
  const [teamName, setTeamName] = useState<string>("");
  const [teamState, setTeamState] = useState<string>("");
  const [teamPostalCode, setTeamPostalCode] = useState<string>("");
  const { updateLicenses, currentLicenses } = useContext(LicensesContext);
  const { userProfile, updateProfile } = useContext(UserContext);
  const { addTeams } = useContext(TeamContext);
  const { closeModal } = useContext(UIContext);
  const [isUserOnIOS, setIsUserOnIOS] = useState<boolean>(false);

  useEffect(() => {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    setIsUserOnIOS(iOS);
  }, []);

  // activate a free/trial license if none exists
  useEffect(() => {
    const activateTrialLicense = async () => {
      // really there should be only one new license, but I named it "newLicenses" because the api returns an array
      const newLicenses = await APIService.LICENSE.ACTIVATE_TRIAL_LICENSE();

      if (newLicenses && !!newLicenses.length) {
        updateLicenses(newLicenses);
      }
    };

    if (currentLicenses && currentLicenses.length === 0) {
      activateTrialLicense();
    }
  }, [currentLicenses]);

  const skipPdfImport = () => {
    setOnboardingStep(OnboardingStep.PdfImportSkipped);
  };

  // TODO: verify if this is still necessary in this flow (took this from previous AccountSetupFlow)
  const updateAvailableLicenses = async () => {
    if (userProfile && userProfile.email) {
      const userLicenses = await APIService.LICENSE.LIST(userProfile.email);
      updateLicenses(userLicenses);
    }
  };

  const markStepAsVisited = async (step: OnboardingStep) => {
    if (!userProfile) {
      return;
    }

    const updatedUserProfile: UserProfileModel = {
      ...userProfile,
      tooltipsInfo: {
        ...userProfile.tooltipsInfo,
        webOnboardingStep: step,
      },
    } as UserProfileModel;

    const updated = await APIService.USER_PROFILE.PUT(
      userProfile.id,
      updatedUserProfile
    );

    if (updated) {
      updateProfile(updated);
      setOnboardingStep(step);
    }
  };

  const createTeam = async () => {
    if (!teamName || !teamLevel || !userProfile?.id) {
      return;
    }

    if (!currentLicenses || !currentLicenses.length) {
      return;
    }

    try {
      const licenseToUse = currentLicenses[0];

      const teamToCreate: TeamModel = {
        name: teamName,
        level: teamLevel,
        state: teamState,
        postalCode: teamPostalCode,
        createdBy: userProfile.id,
        licenseId: licenseToUse.id,
      };

      // create new team
      const createdTeam = await APIService.TEAM.POST(teamToCreate);
      if (createdTeam && createdTeam.id) {
        addTeams([createdTeam]);
        updateAvailableLicenses();
        markStepAsVisited(OnboardingStep.PdfImportChoice);
      } else {
        console.log("Failed to activate a license and/or create a team.");
      }
    } catch (e) {
      console.log("Something went wrong.", e);
    }
  };

  const handleCloseModal = () => {
    if (closeOnboardingModal) {
      closeOnboardingModal();
    } else {
      closeModal();
    }
  };

  return (
    <div
      className={classNames(
        styles.onboardingModal,
        onboardingStep === OnboardingStep.MobileAppLinks &&
          styles.finalStepContainer
      )}
    >
      {!hideRunningProgressBar && (
        <RunningProgressBar
          step={onboardingStep}
          className={styles.progressBar}
        />
      )}
      <div
        className={classNames(
          styles.stepContainer,
          hideRunningProgressBar && styles.fullHeight
        )}
      >
        {onboardingStep === OnboardingStep.TeamCreation && (
          <div className={styles.onboardingStep}>
            <div className={classNames(styles.header, styles.limitWidth)}>
              Welcome to Team Nation!
              <br className={styles.hideOnMobile} /> Let&apos;s start by
              creating your team.
            </div>
            <img src={yellowOnboardingGraphic1} />
            <div className={styles.group}>
              <CharacterInput
                name="name"
                type={INPUT_TYPES.TEXT}
                placeholder="Team Name"
                maxLength={255}
                onChange={(event) => {
                  setTeamName(event.target.value);
                }}
                value={teamName}
                className={styles.limitWidth}
              />
              <SingleSelect
                name="level"
                placeholder="Team Level"
                options={TEAM_LEVELS.map((level) => ({
                  label: level,
                  value: level.toUpperCase(),
                }))}
                onChange={(event) => {
                  setTeamLevel(event.currentTarget.value as TeamLevelEnum);
                }}
                value={teamLevel}
                className={styles.limitWidth}
              />
              <div className={`${styles.stateAndZip} ${styles.limitWidth}`}>
                <SingleSelect
                  name="state"
                  placeholder="State"
                  options={US_STATES}
                  onChange={(e) => setTeamState(e.currentTarget.value)}
                  value={teamState}
                />
                <CharacterInput
                  name="postalCode"
                  type={INPUT_TYPES.TEXT}
                  placeholder="Zip Code"
                  maxLength={255}
                  onChange={(event) => {
                    setTeamPostalCode(event.target.value);
                  }}
                  value={teamPostalCode}
                />
              </div>
            </div>
            <Button
              width="full"
              onClick={createTeam}
              disabled={
                !teamName || !teamLevel || !teamState || !teamPostalCode
              }
              className={styles.limitWidth}
            >
              Create Team
            </Button>
          </div>
        )}
        {onboardingStep === OnboardingStep.PdfImportChoice && (
          <div className={styles.onboardingStep}>
            <div className={styles.header}>
              Team Nation instantly turns your playbook into learning games.
            </div>
            <img src={yellowOnboardingGraphic2} />
            <div className={styles.group}>
              <Button
                width="full"
                theme="primary"
                onClick={() => {
                  setOnboardingStep(OnboardingStep.PdfImportStart);
                }}
                className={styles.limitWidth}
              >
                Import my Hudl Playbook
              </Button>
              <Button
                width="full"
                theme="secondary"
                onClick={skipPdfImport}
                className={styles.limitWidth}
              >
                Continue without Hudl Import
              </Button>
            </div>
          </div>
        )}
        {onboardingStep >= OnboardingStep.PdfImportStart &&
          onboardingStep < OnboardingStep.PdfImportSkipped && (
            <PdfImport
              handleCancel={() => {
                if (handlePdfImportCancel) {
                  handlePdfImportCancel();
                } else {
                  skipPdfImport();
                }
              }}
              setOnboardingStep={setOnboardingStep}
              onboardingStep={onboardingStep}
              showMobileAppLinks={showMobileAppLinks}
            />
          )}
        {onboardingStep === OnboardingStep.PdfImportSkipped && (
          <div className={styles.onboardingStep}>
            <div className={styles.header}>We&apos;ve got you covered.</div>
            <img
              src={yellowOnboardingGraphicThumb}
              className={styles.onboardingGraphicThumb}
            />
            <div className={`${styles.group} ${styles.noGap}`}>
              <div className={styles.subheader}>
                Your new account includes:{" "}
              </div>
              <ul className={styles.bulletList}>
                <li>Ready to play games & lessons</li>
                <li>Pro-built playbook library</li>
                <li>World class play drawing tool</li>
              </ul>
            </div>
            <Button
              theme="primary"
              width="full"
              onClick={() => {
                setOnboardingStep(OnboardingStep.MobileAppLinks);
                markStepAsVisited(OnboardingStep.MobileAppLinks);
              }}
              className={styles.limitWidth}
            >
              Continue
            </Button>
          </div>
        )}
        {onboardingStep === OnboardingStep.MobileAppLinks && (
          <div className={classNames(styles.onboardingStep, styles.finalStep)}>
            <div className={classNames(styles.header, styles.altHeader)}>
              Your team is ready!
            </div>
            <div className={styles.finalStepInner}>
              <div className={styles.header}>
                Download the app <br className={styles.hideOnDesktop} /> to play
                your games.
              </div>
              <img
                className={styles.appScreenshots}
                src={onboardingGraphicAppScreenshots}
              />
              <div className={styles.appStoreLinks}>
                <a
                  href="https://apps.apple.com/us/app/team-nation/id1569080509"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleAppStoreLink} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.moonshotsports.teamnation&hl=en_US&gl=US"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlayStoreLink} />
                </a>
              </div>
              {showBackButtonOnFinalScreen && (
                <Button
                  theme="secondary"
                  width="auto"
                  className={styles.backButton}
                  onClick={handleCloseModal}
                >
                  Back
                </Button>
              )}
              <button
                className={styles.closeModalBtn}
                onClick={handleCloseModal}
              >
                Done
              </button>
            </div>
          </div>
        )}
        <div
          className={classNames(
            styles.emptySpaceToFixScrollingBug,
            isUserOnIOS && styles.show
          )}
        />
      </div>
    </div>
  );
};

export default OnboardingModal;
