import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { API_BASE_URL } from "../../constants";
import { NotificationModel } from "../../../../generated/from-api/models/notification.model";

export const NotificationService = {
  /**
   * Get a list of notifications for the current user
   * @returns
   */
  GET: async function (): Promise<NotificationModel[]> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.NOTIFICATIONS}`)).data;
  },

  /**
   * Update a notification (mark it read/unread)
   * @param notificationId
   * @param read
   * @returns
   */
  PUT: async function (
    notificationId: string,
    read: boolean
  ): Promise<NotificationModel> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.NOTIFICATIONS}`, {
        notificationId,
        read,
      })
    ).data;
  },
};
