import { PlayTypeEnum } from "../../generated/from-api/models/enums/play-type.enum";
import styles from "./PositionSelectorRow.module.scss";

interface Props {
  options: string[];
  selectedOptions: string[];
  playType: PlayTypeEnum;
  togglePositions: (positions: string[], toggled: boolean) => void;
}

const PositionSelectorRow: React.FC<Props> = ({
  options,
  selectedOptions,
  playType,
  togglePositions,
}) => {
  const playTypeToLabelMap: Record<PlayTypeEnum, string> = {
    Offensive: "Offense",
    Defensive: "Defense",
    SpecialTeams: "Special Teams",
  };

  const toggleAll = () => {
    if (options.length === selectedOptions.length) {
      togglePositions(options, false);
    } else {
      togglePositions(options, true);
    }
  };

  return (
    <div className={styles.positionSelectorRow}>
      <div className={styles.positionType}>{playTypeToLabelMap[playType]}</div>
      <div
        className={`${styles.toggle} ${styles.pillToggle} ${
          selectedOptions.length === options.length ? styles.selected : ""
        }`}
        onClick={toggleAll}
      >
        All
      </div>
      {options.map((option) => (
        <div
          key={option}
          className={`${styles.toggle} ${styles.circleToggle} ${
            selectedOptions.includes(option) ? styles.selected : ""
          }`}
          onClick={() => {
            const newVal = !selectedOptions.includes(option);
            togglePositions([option], newVal);
          }}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default PositionSelectorRow;
