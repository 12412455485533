import React from "react";

import styles from "./PercentageBar.module.scss";

interface PercentageBarProps {
  type?: "standard" | "segmented";
  complete?: number;
  positive?: number;
  total: number;
  hideText?: boolean;
}

// Component starts here
const PercentageBar: React.FC<PercentageBarProps> = ({
  type = "standard",
  complete = 0,
  positive = 0,
  total,
  hideText = false,
}) => {
  if (type === "segmented") {
    const cells = new Array(total).fill(styles.percentageBarSegmentedSegment);
    cells[0] += " " + styles.percentageBarSegmentedFirst;
    cells[total - 1] += " " + styles.percentageBarSegmentedLast;
    for (let i = 0; i < complete; i++) {
      cells[i] += " " + styles.percentageBarSegmentedFilled;
    }
    return (
      <div
        className={styles.percentageBarSegmented}
        style={{
          gridTemplateColumns: `repeat(${total}, 1fr)`,
        }}
      >
        {cells.map((cellClass, index) => {
          return (
            <span key={`cell-${index}`} className={cellClass}>
              &nbsp;
            </span>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className={styles.percentageBar}>
        <div className={styles.percentageBarContainer}>
          <div
            className={styles.percentageBarFill}
            style={{
              // This kicks 0/0 to empty rather than full
              width: positive === 0 ? "0%" : (positive / total) * 100 + "%",
            }}
          ></div>
        </div>
        {!hideText && (
          <span>
            {total === 0
              ? `Nothing.`
              : `${positive} of ${total} (${((positive / total) * 100).toFixed(
                  0
                )})%`}
          </span>
        )}
      </div>
    );
  }
};

export default PercentageBar;
