import stylesPlayerOverall from "./PlayerOverall.module.scss";
import stylesDashboardGeneral from "../Dashboard.module.scss";
const styles = { ...stylesDashboardGeneral, ...stylesPlayerOverall };

import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReadinessScoresContext } from "../../../shared/shared-with-mobile/providers/readinessScores.provider";
import ReadinessGraph from "../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/ReadinessGraph";
import { isEmpty, isUndefined } from "lodash";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import { BarDatum } from "@nivo/bar";
import { DashboardStatsGrid } from "../../../components/DashboardStatsGrid/DashboardStatsGrid";
import { PlayerReadinessSnapshotModel } from "../../../generated/from-api/models/readiness-snapshot/player-readiness-snapshot.model";
import ReadinessOverTime from "../../../shared/shared-with-mobile/dashboard-widgets/ReadinessOverTime/ReadinessOverTime";

interface Props {
  player: PlayerModel;
  playerReadinessOverTime: PlayerReadinessSnapshotModel[];
}

const PlayerOverall: React.FC<Props> = ({
  player,
  playerReadinessOverTime,
}) => {
  const { playerOverallMetrics } = useContext(ReadinessScoresContext);
  const [dataForPlayerStatsGrid, setDataForPlayerStatsGrid] = useState<
    {
      name: string;
      readiness: number;
    }[]
  >();

  const chartDefaultDataForUnit: BarDatum[] = [
    { category: "LESSONS", readinesss: 0 },
    { category: "PLAYBOOK", readinesss: 0 },
    { category: "FLASHCARDS", readinesss: 0 },
  ];

  const [chartDataForUnit, setChartDataForUnit] = useState<BarDatum[]>(
    chartDefaultDataForUnit
  );

  useEffect(() => {
    if (!isEmpty(playerOverallMetrics)) {
      const selectedDataForTablee = playerOverallMetrics.find(
        (players) => players.playerId === player.id
      );

      if (selectedDataForTablee) {
        const tableData = [
          {
            name: "Lessons",
            readiness: +selectedDataForTablee.installReadinessScore,
          },
          {
            name: "Playbook",
            readiness: +selectedDataForTablee.playbookReadinessScore,
          },
          {
            name: "Flashcards",
            readiness: +selectedDataForTablee.quizReadinessScore,
          },
        ];

        const chartData = [
          {
            category: "LESSONS",
            readiness: isUndefined(selectedDataForTablee.installReadinessScore)
              ? 0
              : selectedDataForTablee.installReadinessScore,
          },
          {
            category: "PLAYBOOK",
            readiness: isUndefined(selectedDataForTablee.playbookReadinessScore)
              ? 0
              : selectedDataForTablee.playbookReadinessScore,
          },
          {
            category: "FLASHCARDS",
            readiness: isUndefined(selectedDataForTablee.quizReadinessScore)
              ? 0
              : selectedDataForTablee.quizReadinessScore,
          },
        ];

        setChartDataForUnit(chartData);
        setDataForPlayerStatsGrid(tableData);
      }
    }
  }, [playerOverallMetrics, player]);

  const columns = useMemo(
    () => [
      {
        name: "CATEGORY",
        selector: (row: any) => row.name,
        cell: (row: any) => {
          return (
            <div className={styles.nameColumn}>
              <span className={styles.categoryName}>{row.name}</span>
            </div>
          );
        },
        sortable: true,
        right: false,
      },
      {
        name: "READINESS",
        selector: (row: any) => +row.readiness,
        format: (row: any) => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    []
  );

  return (
    <div>
      <div className={styles.chartCardRow}>
        <ReadinessOverTime
          title="Overall Readiness"
          xLabel="Date"
          yLabel="Readiness"
          ySymbol="%"
          data={
            playerReadinessOverTime
              ? [
                  {
                    id: "line1",
                    data: playerReadinessOverTime.map((t) => {
                      return {
                        x: t.created,
                        y: +t.overallScore,
                      };
                    }),
                  },
                ]
              : []
          }
        />

        <ReadinessGraph
          title="Player Readiness by Category"
          type="Bar"
          xLabel="Category"
          yLabel="Readiness"
          ySymbol="%"
          data={chartDataForUnit}
          indexBy="category"
          valueKey="readiness"
        />
      </div>

      <br />
      <div>
        <DashboardStatsGrid data={dataForPlayerStatsGrid} columns={columns} />
      </div>
    </div>
  );
};

export default PlayerOverall;
