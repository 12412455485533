import ReactAudioPlayer from "react-audio-player";

import styles from "./MediaPlayerModal.module.scss";
import { MediaModel } from "../../../generated/from-api/models/media.model";
import { useEffect, useState } from "react";

interface Props {
  mediaItem: MediaModel;
}

const MediaPlayerModal: React.FC<Props> = ({ mediaItem }) => {
  const [audioUrl, setAudioUrl] = useState("");

  useEffect(() => {
    return () => {
      if (!!audioUrl) URL.revokeObjectURL(audioUrl);
    };
  }, []);

  useEffect(() => {
    const fetchAudio = async (media: MediaModel) => {
      if (!media) return;
      try {
        const response = await fetch(media.url || "");
        if (!response.ok) {
          console.error("Error retrieving audio file", response.statusText);
          return;
        }
        const blob = await response.blob();
        setAudioUrl(URL.createObjectURL(blob));
      } catch (e) {
        console.error(e);
      }
    };

    if (!!audioUrl) URL.revokeObjectURL(audioUrl);
    if (mediaItem.type !== "AUDIO") return;
    fetchAudio(mediaItem);
  }, [mediaItem]);

  return (
    <div className={styles.mediaPlayerModal}>
      {mediaItem.type === "IMAGE" && (
        <img src={mediaItem.url} alt={mediaItem.name} />
      )}
      {mediaItem.type === "VIDEO" && (
        <video src={mediaItem.url} controls autoPlay />
      )}
      {mediaItem.type === "AUDIO" && (
        <ReactAudioPlayer src={audioUrl} controls autoPlay />
      )}
    </div>
  );
};

export default MediaPlayerModal;
