import { useContext } from "react";
import { useHistory } from "react-router";

import styles from "../EditPlayDetails/DeletePlayModal.module.scss";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { FormationsContext } from "../../../shared/shared-with-mobile/providers/formations.provider";
import Button from "../../Button/Button";

interface Props {
  formationId: string;
}

const DeleteFormationModal: React.FC<Props> = ({ formationId }) => {
  const { removeFormation } = useContext(FormationsContext);
  const { closeModal, dispatchToast } = useContext(UIContext);
  const history = useHistory();

  const deleteFormation = async () => {
    const response = await APIService.FORMATION.DELETE(formationId);
    if (response && response.deleted) {
      removeFormation(formationId);
      dispatchToast({
        type: "success",
        message: "Formation deleted.",
      });
      closeModal();
      history.goBack();
    }
  };

  return (
    <div className={styles.deletePlayModal}>
      <p>
        Are you sure you want to remove this formation? This action cannot be
        undone.
      </p>
      <div className={styles.buttonRow}>
        <Button
          theme="tertiary"
          size={"small"}
          onClick={deleteFormation}
          destructive
        >
          Delete Formation
        </Button>
        <Button size={"small"} onClick={closeModal}>
          Keep Formation
        </Button>
      </div>
    </div>
  );
};

export default DeleteFormationModal;
