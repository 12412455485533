/* eslint-disable no-console */
import { createContext, useRef, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { ContentLimitModalProps } from "../utilities/ContentLimitModalProps";
export interface ModalModel {
  open: boolean;
  closing?: boolean;
  size?: "small" | "medium" | "large" | "responsive";
  body?: JSX.Element;
  title?: string;
  className?: string;
  depth?: number;
  disableBackdropClick?: boolean;
}

export interface ToastModel {
  id: string;
  type: "success" | "error" | "info";
  message: string;
}

interface UIContext {
  modal: ModalModel;
  modal2nd: ModalModel | undefined;
  dispatchModal: (modalData: ModalModel) => void;
  dispatchSingleModal: (modalData: ModalModel) => void;
  closeModal: () => void;
  closeModals: () => void;
  toasts: ToastModel[];
  dispatchToast: (toastData: Pick<ToastModel, "type" | "message">) => void;
  removeToast: (toastId: string) => void;
  handleCreateError: (
    error: any,
    body: React.FC<ContentLimitModalProps>,
    itemName?: string,
    itemNamePlural?: string
  ) => void;
}

const modalDefaults: ModalModel = {
  open: false,
  size: "small",
};

export const UIContext = createContext<UIContext>({
  modal: modalDefaults,
  modal2nd: undefined,
  dispatchModal: () => null,
  dispatchSingleModal: () => null,
  closeModal: () => null,
  closeModals: () => null,
  toasts: [],
  dispatchToast: () => null,
  removeToast: () => null,
  handleCreateError: () => null,
});

export const UIProvider: React.FC = ({ children }) => {
  const [modal, setModal] = useState<ModalModel>(modalDefaults);
  const [modal2nd, setModal2nd] = useState<ModalModel>();
  const [toasts, setToasts] = useState<ToastModel[]>([]);

  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;

  const dispatchModal = (modalData: ModalModel) => {
    modalData.size = modalData.size ?? "small";
    if (modal !== modalDefaults) {
      modalData.depth = 2;
      setModal2nd(modalData);
    } else {
      setModal(modalData);
    }
  };

  const dispatchSingleModal = (modalData: ModalModel) => {
    modalData.size = modalData.size ?? "small";
    setModal(modalData);
    setModal2nd(undefined);
  };

  const closeModal = () => {
    if (modal2nd !== undefined) {
      const closingModal = { ...modal2nd };
      closingModal.closing = true;
      setModal2nd(closingModal);
      setTimeout(() => {
        setModal2nd(undefined);
      }, 500);
    } else {
      const closingModal = { ...modal };
      closingModal.closing = true;
      setModal(closingModal);
      setTimeout(() => {
        setModal(modalDefaults);
      }, 500);
    }
  };

  const closeModals = () => {
    if (modal2nd !== undefined) {
      const closingModal2nd = { ...modal2nd };
      closingModal2nd.closing = true;
      setModal2nd(closingModal2nd);
    }
    const closingModal = { ...modal };
    closingModal.closing = true;
    setModal(closingModal);
    setTimeout(() => {
      setModal2nd(undefined);
      setModal(modalDefaults);
    }, 500);
  };

  const dispatchToast = (toastData: Pick<ToastModel, "type" | "message">) => {
    const id = uuidv4();
    const newToast: ToastModel = { ...toastData, id };
    setToasts([...toastsRef.current, newToast]);
  };

  const removeToast = (toastId: string) => {
    const currentToasts = [...toastsRef.current];
    const toastIndex = currentToasts.findIndex((toast) => toast.id === toastId);
    currentToasts.splice(toastIndex, 1);
    setToasts(currentToasts);
  };

  const handleCreateError = (
    error: any,
    ContentLimitModalBody: React.FC<ContentLimitModalProps>,
    itemName?: string,
    itemNamePlural?: string
  ) => {
    const QUOTA_EXCEEDED_CODE = 422;
    if (error?.response?.status === QUOTA_EXCEEDED_CODE) {
      dispatchModal({
        open: true,
        size: "medium",
        body: (
          <ContentLimitModalBody
            itemName={itemName}
            itemNamePlural={itemNamePlural}
          ></ContentLimitModalBody>
        ),
        title: "Content Limit Reached",
      });
    } else {
      console.error(`Error creating ${itemName}/${itemNamePlural}: `, error);
      dispatchToast({
        type: "error",
        message: `Error creating ${itemName}`,
      });
    }
  };

  return (
    <UIContext.Provider
      value={{
        modal,
        modal2nd,
        dispatchModal,
        dispatchSingleModal,
        closeModal,
        closeModals,
        toasts,
        dispatchToast,
        removeToast,
        handleCreateError,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
