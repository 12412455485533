import React, { ChangeEvent } from "react";
import styles from "./QuestionCard.module.scss";
import CircleCheckedIcon from "../../../resources/icons/CircleCheckedIcon";
import CircleNotAllowedIcon from "../../../resources/icons/CircleNotAllowwedIcon";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import CharacterInput from "../../Input/CharacterInput";
import PlusIcon from "../../../resources/icons/PlusIcon";
import { QuestionAnswerActionType, QuestionAnswerType } from "./QuestionCard";
import TrashIcon from "../../../resources/icons/TrashIcon";

interface Props {
  type: QuestionAnswerType;
  actionType: QuestionAnswerActionType;
  text?: string;
  onChangeCallback?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  buttonClickCallback?: (action: QuestionAnswerActionType) => void;
}

const AnswerTextLine: React.FC<Props> = ({
  type,
  actionType,
  text,
  buttonClickCallback,
  onChangeCallback,
}) => {
  const isTypeCorrect = type === "correct";

  return (
    <div className={styles.questionCardAnswerLine}>
      <div
        className={`${styles.questionCardAnswerLineIcon} ${
          isTypeCorrect
            ? styles.questionCardAnswerLineIconCorrect
            : styles.questionCardAnswerLineIconIncorrect
        }`}
      >
        {isTypeCorrect ? <CircleCheckedIcon /> : <CircleNotAllowedIcon />}
      </div>
      <div className={styles.questionCardAnswerInputWrapper}>
        <CharacterInput
          type={INPUT_TYPES.TEXT}
          placeholder={isTypeCorrect ? "Correct Answer" : "Incorrect answer"}
          value={text ? text : ""}
          onChange={onChangeCallback}
        />
      </div>
      <div className={styles.questionCardAnswerButtonWrapper}>
        {actionType === "add" ? (
          <button
            type="button"
            className={styles.questionCardAnswerButton}
            onClick={() => buttonClickCallback && buttonClickCallback("add")}
          >
            <PlusIcon />
          </button>
        ) : (
          <button
            type="button"
            className={styles.questionCardAnswerButton}
            onClick={() => buttonClickCallback && buttonClickCallback("delete")}
          >
            <TrashIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default AnswerTextLine;
