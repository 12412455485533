import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./SpreadsheetUpload.module.scss";
import Button from "../Button/Button";
import CloseIconWhite from "../../resources/icons/CloseIconWhite";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import UploadIcon from "../../resources/icons/UploadIcon";
import DropUpload from "../DropUpload/DropUpload";
import { CSVToJSON } from "../../shared/shared-with-mobile/functions";
import CharacterInput from "../Input/CharacterInput";
import { coachPositions, INPUT_TYPES } from "../../utils/web-only-constants";
import PhoneInput from "react-phone-input-2";
import CloseIcon from "../../resources/icons/CloseIcon";
import cloneDeep from "lodash/cloneDeep";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { InvitationsContext } from "../../shared/shared-with-mobile/providers/invitations.provider";
import SingleSelect from "../SingleSelect/SingleSelect";
import { read, utils } from "xlsx";
import { InvitationModel } from "../../generated/from-api/models/invitation.model";
import { invitationTypes } from "../../shared/shared-with-mobile/constants";

interface Props {
  className?: string;
  teamId?: string;
  dataType: invitationTypes;
  templateFilePath?: string;
}

interface Player {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface Coach {
  firstName: string;
  lastName: string;
  toEmail: string;
  coachRole: string;
}

export enum OnboardingImportDataFromFile {
  UploadFile = 1,
  ManageUploadedData = 2,
}

const SpreadsheetUpload: React.FC<Props> = ({
  className = "",
  teamId,
  dataType,
  templateFilePath,
}) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const { dispatchToast, closeModal } = useContext(UIContext);
  const { addOrUpdateInvitations } = useContext(InvitationsContext);

  const [step, setStep] = useState<number>(
    OnboardingImportDataFromFile.UploadFile
  );
  const [importedData, setImportedData] = useState<any[]>([]);

  useEffect(() => {
    if (importedData.length === 0) {
      setStep(OnboardingImportDataFromFile.UploadFile);
    } else {
      setStep(OnboardingImportDataFromFile.ManageUploadedData);
    }
  }, [importedData]);

  const handleUploadedFile = (files: any) => {
    if (files.length > 1) {
      dispatchToast({
        type: "error",
        message: "File Upload failed. Please upload one file at a time.",
      });

      return;
    }

    const uploadedFile = files[0];
    const uploadedFileExtension = uploadedFile
      ? uploadedFile.name.split(".").pop()
      : "";
    const supportedFileTypes = ["csv", "xlsx", "xls"];
    const isCSV = uploadedFileExtension.toLowerCase() === "csv";

    if (
      !supportedFileTypes.some(
        (fileType) => uploadedFileExtension.indexOf(fileType) > -1
      )
    ) {
      dispatchToast({
        type: "error",
        message: "File Upload failed. Wrong file type.",
      });

      return;
    }

    const reader = new FileReader();
    reader.readAsBinaryString(uploadedFile);
    reader.onload = () => {
      const fileData = reader.result;
      let fileDataRows: any = [];

      if (isCSV) {
        fileDataRows = CSVToJSON(fileData);
      } else {
        const workbook = read(fileData, { type: "binary" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        fileDataRows = utils.sheet_to_json(sheet);
      }

      // Filter out empty rows or cells
      if (fileDataRows) {
        fileDataRows = fileDataRows.filter(
          (data: any) =>
            (dataType === invitationTypes.PLAYER &&
              data.firstName &&
              data.lastName &&
              data.phoneNumber) ||
            (dataType === invitationTypes.COACH &&
              data.firstName &&
              data.lastName &&
              data.toEmail &&
              data.coachRole)
        );
      }

      setImportedData(fileDataRows);
      dispatchToast({
        type: fileDataRows ? "success" : "error",
        message: fileDataRows
          ? `${fileDataRows.length} ${
              dataType === invitationTypes.PLAYER ? "players" : "coaches"
            } have been imported`
          : "Something went wrong. Please try again.",
      });
    };
  };

  const removeRow = (index: number) => {
    const clonedImportedData = cloneDeep(importedData);
    clonedImportedData.splice(index, 1);

    setImportedData(clonedImportedData);
  };

  const updateRow = (index: number, coachOrPlayer: any) => {
    const clonedImportedData = cloneDeep(importedData);
    clonedImportedData.splice(index, 1, coachOrPlayer);

    setImportedData(clonedImportedData);
  };

  const invitePlayers = async () => {
    if (dataType === invitationTypes.PLAYER) {
      try {
        const promises = importedData.map(async (player) => {
          try {
            return await APIService.INVITATION.POST({
              type: "PLAYER",
              teamId: teamId ? teamId : "",
              firstName: player.firstName,
              lastName: player.lastName,
              toPhoneNumber: player.phoneNumber,
            });
          } catch (e) {
            // catch error and do not rethrow, or else even successful invitations will not be added to context
            console.log("Error adding an invitation", e);
          }
        });

        const responses = await Promise.all(promises);

        const newPlayerInvites = responses.filter(
          (response) => !!response
        ) as InvitationModel[];
        addOrUpdateInvitations(newPlayerInvites);

        dispatchToast({
          type: "success",
          message: `${newPlayerInvites.length} player(s) have been invited.`,
        });
        closeModal();
      } catch (e) {
        dispatchToast({
          type: "error",
          message: "Something Failed. Please try again.",
        });
      }
    }
  };

  const inviteCoaches = async () => {
    if (dataType === invitationTypes.COACH) {
      try {
        const promises = importedData.map(async (coach) => {
          try {
            return await APIService.INVITATION.POST({
              type: "COACH",
              teamId: teamId ? teamId : "",
              firstName: coach.firstName,
              lastName: coach.lastName,
              toEmail: coach.toEmail,
              coachRole: coach.coachRole,
            });
          } catch (e) {
            // catch error and do not rethrow, or else even successful invitations will not be added to context
            console.log("Error adding an invitation", e);
          }
        });

        const responses = await Promise.all(promises);
        const newCoachInvites = responses.filter(
          (response) => !!response
        ) as InvitationModel[];
        addOrUpdateInvitations(newCoachInvites);

        dispatchToast({
          type: "success",
          message: `${newCoachInvites.length} coach(es) have been invited.`,
        });
        closeModal();
      } catch (e) {
        dispatchToast({
          type: "error",
          message: "Something Failed. Please try again.",
        });
      }
    }
  };

  return (
    <div className={`${styles.spreadsheetUpload} ${className}`}>
      <div className={styles.spreadsheetUpload__Header}>
        <Button
          theme="transparent"
          size="small"
          icon={<CloseIconWhite />}
          onClick={() => closeModal()}
        />
      </div>
      <div
        className={`${styles.spreadsheetUpload__Body} ${
          step === OnboardingImportDataFromFile.ManageUploadedData
            ? styles.spreadsheetUpload__BodyStep2
            : ""
        }`}
      >
        {step === OnboardingImportDataFromFile.UploadFile && (
          <div className={styles.spreadsheetUpload__Step1}>
            <div className={styles.spreadsheetUpload__Step1Title}>
              Upload a spreadsheet
            </div>
            <div className={styles.spreadsheetUpload__Step1Dropbox}>
              <div className={styles.spreadsheetUpload__Dropbox}>
                <button
                  type="button"
                  className={styles.spreadsheetUpload__DropboxUpload}
                  onClick={() => inputFileRef.current?.click()}
                >
                  <DropUpload
                    hoverClass={styles.spreadsheetUpload__DropboxUploadHover}
                    dropAction={(files: React.MouseEvent<HTMLButtonElement>) =>
                      handleUploadedFile(files)
                    }
                  >
                    <div
                      className={styles.spreadsheetUpload__DropboxUploadIcon}
                    >
                      <UploadIcon />
                    </div>
                    <div
                      className={styles.spreadsheetUpload__DropboxUploadText}
                    >
                      Upload Spreadsheet
                    </div>
                  </DropUpload>
                </button>
                <div className={styles.spreadsheetUpload__DropboxDescription}>
                  Supported formats: .csv, .xlsx, .xls
                </div>
                <div className={styles.spreadsheetUpload__DropboxInputFile}>
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    ref={inputFileRef}
                    onChange={(event) =>
                      event.target.files &&
                      handleUploadedFile(event.target.files)
                    }
                  />
                </div>
              </div>
            </div>
            {templateFilePath && (
              <div className={styles.spreadsheetUpload__Step1Download}>
                <div className={styles.spreadsheetUpload__Step1DownloadText}>
                  Download our template below to ensure correct formatting.
                </div>
                <div className={styles.spreadsheetUpload__Step1DownloadButton}>
                  <Button
                    type="external link"
                    theme="secondary"
                    size="small"
                    externalLinkOptions={{ href: templateFilePath }}
                  >
                    Download Template
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        {step === OnboardingImportDataFromFile.ManageUploadedData && (
          <div className={styles.spreadsheetUpload__Step2}>
            <div className={styles.spreadsheetUpload__Step2Title}>
              Review the information before adding:
            </div>

            {importedData && (
              <div className={styles.spreadsheetUpload__Step2Data}>
                {dataType === invitationTypes.PLAYER &&
                  importedData.map((player: Player, index: number) => (
                    <div
                      key={index}
                      className={styles.spreadsheetUpload__Step2Row}
                    >
                      <CharacterInput
                        type={INPUT_TYPES.TEXT}
                        value={player.firstName}
                        onChange={(e) =>
                          updateRow(index, {
                            ...player,
                            firstName: e.target?.value,
                          })
                        }
                      />
                      <CharacterInput
                        type={INPUT_TYPES.TEXT}
                        value={player.lastName}
                        onChange={(e) =>
                          updateRow(index, {
                            ...player,
                            lastName: e.target?.value,
                          })
                        }
                      />
                      <div
                        className={
                          styles.spreadsheetUpload__Step2PhoneInputWrapper
                        }
                      >
                        <PhoneInput
                          containerClass="phoneInputContainer"
                          inputClass="phoneInput phoneInput--heightNormal"
                          buttonClass="phoneInputButton"
                          dropdownClass="phoneInputDropdown"
                          country="us"
                          value={player.phoneNumber}
                          onChange={(e) =>
                            updateRow(index, { ...player, phoneNumber: e })
                          }
                        />
                      </div>
                      <Button
                        theme={"transparent"}
                        icon={<CloseIcon />}
                        onClick={() => removeRow(index)}
                      />
                    </div>
                  ))}

                {dataType === invitationTypes.COACH &&
                  importedData.map((coach: Coach, index: number) => (
                    <div
                      key={index}
                      className={styles.spreadsheetUpload__Step2Row}
                    >
                      <CharacterInput
                        type={INPUT_TYPES.TEXT}
                        value={coach.firstName}
                        onChange={(e) =>
                          updateRow(index, {
                            ...coach,
                            firstName: e.target?.value,
                          })
                        }
                      />
                      <CharacterInput
                        type={INPUT_TYPES.TEXT}
                        value={coach.lastName}
                        onChange={(e) =>
                          updateRow(index, {
                            ...coach,
                            lastName: e.target?.value,
                          })
                        }
                      />
                      <CharacterInput
                        type={INPUT_TYPES.EMAIL}
                        value={coach.toEmail}
                        onChange={(e) =>
                          updateRow(index, {
                            ...coach,
                            toEmail: e.target?.value,
                          })
                        }
                      />
                      <SingleSelect
                        placeholder="Role"
                        options={[...coachPositions].map((roleOption) => {
                          return {
                            label:
                              roleOption === "DEFAULT"
                                ? "ASSISTANT"
                                : roleOption,
                            value: roleOption,
                          };
                        })}
                        value={coach.coachRole ? coach.coachRole : ""}
                        onChange={(e) =>
                          updateRow(index, {
                            ...coach,
                            coachRole: e.currentTarget?.value,
                          })
                        }
                      />
                      <Button
                        theme={"transparent"}
                        icon={<CloseIcon />}
                        onClick={() => removeRow(index)}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
      {step === OnboardingImportDataFromFile.ManageUploadedData && (
        <div className={styles.spreadsheetUpload__Footer}>
          <Button
            size="small"
            onClick={() =>
              dataType === invitationTypes.PLAYER
                ? invitePlayers()
                : inviteCoaches()
            }
          >
            Add to Roster
          </Button>
        </div>
      )}
    </div>
  );
};

export default SpreadsheetUpload;
