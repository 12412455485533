import React, { useState } from "react";

import { FormationModel } from "../../../generated/from-api/models/drawable/formation.model";

export const FormationsContext = React.createContext<{
  globalFormations: FormationModel[];
  currentFormations: FormationModel[];
  addFormations: (f: FormationModel[]) => void;
  updateFormation: (f: FormationModel) => void;
  setFormations: (f: FormationModel[]) => void;
  clearFormations: () => void;
  removeFormation: (playId: string) => void;
}>({
  globalFormations: [],
  currentFormations: [],
  addFormations: () => null,
  updateFormation: () => null,
  setFormations: () => null,
  clearFormations: () => null,
  removeFormation: () => null,
});

export const FormationsProvider: React.FC = ({ children }) => {
  const [currentFormations, setCurrentFormations] = useState<FormationModel[]>(
    []
  );
  const [globalFormations, setGlobalFormations] = useState<FormationModel[]>(
    []
  );

  const updateFormation = (playToUpdate: FormationModel) => {
    if (!!playToUpdate.teamId) {
      const nextCurrentFormations = currentFormations.map((formation) => {
        if (playToUpdate.id === formation.id) {
          return playToUpdate;
        }

        return formation;
      });

      setCurrentFormations(nextCurrentFormations);
    } else {
      const nextGlobalFormations = globalFormations.map((formation) => {
        if (playToUpdate.id === formation.id) {
          return playToUpdate;
        }

        return formation;
      });

      setGlobalFormations(nextGlobalFormations);
    }
  };

  const addFormations = (formationsToAdd: FormationModel[]) => {
    const nextGlobalFormations = globalFormations.concat(
      formationsToAdd.filter((f) => !f.teamId)
    );
    const nextFormations = currentFormations.concat(
      formationsToAdd.filter((f) => !!f.teamId)
    );

    setGlobalFormations(nextGlobalFormations);
    setCurrentFormations(nextFormations);
  };

  const clearFormations = () => {
    setGlobalFormations([]);
    setCurrentFormations([]);
  };

  const setFormations = (formations: FormationModel[]) => {
    const nextGlobalFormations = formations.filter((f) => !f.teamId);
    const nextFormations = formations.filter((f) => !!f.teamId);

    setGlobalFormations(nextGlobalFormations);
    setCurrentFormations(nextFormations);
  };

  const removeFormation = (formationId: string) => {
    const formationToDeleteIndex = currentFormations.findIndex(
      (formation) => formation.id === formationId
    );
    if (formationToDeleteIndex > -1) {
      const nextCurrentFormations = [...currentFormations];
      if (formationToDeleteIndex >= 0) {
        nextCurrentFormations.splice(formationToDeleteIndex, 1);
      }
      setCurrentFormations(nextCurrentFormations);
    } else {
      const globalFormationToDeleteIndex = globalFormations.findIndex(
        (formation) => formation.id === formationId
      );
      const nextGlobalFormations = [...globalFormations];
      if (formationToDeleteIndex >= 0) {
        nextGlobalFormations.splice(globalFormationToDeleteIndex, 1);
      }
      setCurrentFormations(nextGlobalFormations);
    }
  };

  return (
    <FormationsContext.Provider
      value={{
        globalFormations,
        currentFormations,
        addFormations,
        updateFormation,
        setFormations,
        clearFormations,
        removeFormation,
      }}
    >
      {children}
    </FormationsContext.Provider>
  );
};
