import React, { useContext } from "react";
import styles from "./TeamCard.module.scss";
import TeamLogoPlaceholderIcon from "../../../resources/icons/TeamLogoPlaceholderIcon";
import Button from "../../Button/Button";
import { TeamModel } from "../../../generated/from-api/models/team.model";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";

interface Props {
  className?: string;
  team: TeamModel;
}

const TeamCard: React.FC<Props> = ({ className = "", team }) => {
  const { closeModal } = useContext(UIContext);
  const { switchTeam } = useContext(TeamContext);

  return (
    <div className={`${styles.teamCard} ${className}`}>
      <div className={styles.teamCard__LogoWrapper}>
        {team.logo ? (
          <img src={team.logo} alt={team.name} />
        ) : (
          <TeamLogoPlaceholderIcon />
        )}
      </div>
      {team.primaryColor && team.secondaryColor && (
        <div className={styles.teamCard__ColorsBar}>
          <span style={{ backgroundColor: team.primaryColor }} />
          <span style={{ backgroundColor: team.secondaryColor }} />
        </div>
      )}
      <div className={styles.teamCard__NameWrapper}>{team.name}</div>
      <div className={styles.teamCard__LevelWrapper}>{team.level}</div>
      <div className={styles.teamCard__LinkWrapper}>
        <Button
          width="full"
          onClick={() => {
            team.id && switchTeam(team.id);
            closeModal();
          }}
        >
          Go to Team
        </Button>
      </div>
    </div>
  );
};

export default TeamCard;
