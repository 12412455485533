import { useContext, useEffect, useState } from "react";

import CharacterInput from "../../Input/CharacterInput";
import SingleSelect from "../../SingleSelect/SingleSelect";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { PlayCategoryEnum } from "../../../generated/from-api/models/enums/play-category.enum";
import { FormationsContext } from "../../../shared/shared-with-mobile/providers/formations.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { flipPlayersByDimension } from "../../../shared/shared-with-mobile/play-editor/playEditor.utils";
import { DrawablePlayerModel } from "../../../generated/from-api/models/drawable/drawablePlayer.model";
import styles from "./EditPlayDetails.module.scss";
import DuplicatePlayModal from "./DuplicatePlayModal";
import DeletePlayModal from "./DeletePlayModal";
import PlayModeSelector from "../PlayModeSelector/PlayModeSelector";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import { mapTagsToSelectOptions } from "../../../utils/functions";
import { SingleSelectOption } from "../../SingleSelect/SingleSelect";
import Button from "../../Button/Button";

interface Props {
  play: Omit<PlayModel, "drawablePlayers">;
  setPlay: React.Dispatch<
    React.SetStateAction<Omit<PlayModel, "drawablePlayers">>
  >;
  players: DrawablePlayerModel[];
  setPlayers: (players: DrawablePlayerModel[]) => void;
}

const EditPlayDetails: React.FC<Props> = ({
  play,
  setPlay,
  players,
  setPlayers,
}) => {
  const { globalFormations, currentFormations } = useContext(FormationsContext);
  const { dispatchModal } = useContext(UIContext);
  const {
    situationTags,
    personnelPackageTags,
    runSchemeTags,
    passSchemeTags,
    defenseSchemeTags,
    blitzTags,
    coverageTags,
  } = useContext(TagsContext);
  const [schemeOptions, setSchemeOptions] = useState<SingleSelectOption[]>([]);
  const [situationOptions, setSituationOptions] = useState<
    SingleSelectOption[]
  >(mapTagsToSelectOptions(situationTags));
  const [personnelPackageOptions, setPersonnelPackageOptions] = useState<
    SingleSelectOption[]
  >(mapTagsToSelectOptions(personnelPackageTags));
  const [blitzOptions, setBlitzOptions] = useState<SingleSelectOption[]>(
    mapTagsToSelectOptions(blitzTags)
  );
  const [coverageOptions, setCoverageOptions] = useState<SingleSelectOption[]>(
    mapTagsToSelectOptions(coverageTags)
  );
  const runSchemeOptions = mapTagsToSelectOptions(runSchemeTags);
  const passSchemeOptions = mapTagsToSelectOptions(passSchemeTags);
  const defenseSchemeOptions = mapTagsToSelectOptions(defenseSchemeTags);

  // TODO (low priority): keep all category options present
  // and if a user tries to switch playType from Offensive to Defensive or vice versa
  // show a modal to warn user that this will replace ALL players, routes, player notes, etc.
  // before allowing them to switch playType
  // Additional context - playType is derived from playCategory
  const categoryOptions =
    play.playType === "Offensive"
      ? [
          { value: "Run", label: "Run" },
          { value: "Pass", label: "Pass" },
        ]
      : [{ value: "Defense", label: "Defense " }];

  const formationOptions = [...globalFormations, ...currentFormations]
    .filter((formation) => formation.type === play.playType)
    .map((formation) => ({
      label: formation.name,
      value: formation.id,
    }));

  const selectedFormationId = [...globalFormations, ...currentFormations].find(
    (formation) => formation.name === play.formationName
  )?.id;

  const selectedOpponentFormationId =
    [...globalFormations, ...currentFormations].find(
      (formation) => formation.name === play.opponentFormationName
    )?.id || "none";

  const opponentFormationOptions = [...globalFormations, ...currentFormations]
    .filter((formation) => formation.type !== play.playType)
    .map((formation) => ({
      label: formation.name,
      value: formation.id,
    }));

  opponentFormationOptions.unshift({
    label: "None",
    value: "none",
  });

  const updateFormationName = (formationId: string) => {
    const selectedFormation = [...globalFormations, ...currentFormations].find(
      (formation) => formation.id === formationId
    );

    setPlay({
      ...play,
      formationName: selectedFormation?.name,
    });
  };

  const updateOpponentFormation = (formationId: string) => {
    const opponentFormation = [...globalFormations, ...currentFormations].find(
      (formation) => formation.id === formationId
    );

    // remove opponent players
    let nextPlayers = players.filter(
      (player) => player.playType === play.playType
    );

    // add new opponent formation if one is selected
    if (opponentFormation) {
      const opponentPlayers = flipPlayersByDimension(
        opponentFormation.drawablePlayers,
        "y",
        "x"
      );
      nextPlayers = nextPlayers.concat(opponentPlayers);
    }

    setPlayers(nextPlayers);
    setPlay({
      ...play,
      opponentFormationName: opponentFormation?.name || null,
    });
  };

  const openDuplicatePlayModal = () => {
    dispatchModal({
      title: "Duplicate Play",
      open: true,
      body: (
        <DuplicatePlayModal
          play={{
            ...play,
            drawablePlayers: players,
          }}
        />
      ),
    });
  };

  const openDeletePlayModal = () => {
    if (!play.id) {
      return;
    }

    dispatchModal({
      title: "Delete Play from Playbook",
      open: true,
      body: <DeletePlayModal playIds={[play.id]} />,
    });
  };

  useEffect(() => {
    let nextSchemeOptions: any[] = [];
    switch (play.playCategory) {
      case "Run":
        nextSchemeOptions = runSchemeOptions;
        break;
      case "Pass":
        nextSchemeOptions = passSchemeOptions;
        break;
      case "Defense":
        nextSchemeOptions = defenseSchemeOptions;
        break;
    }
    const schemeIndex = nextSchemeOptions.findIndex(
      (scheme) => scheme.value === play.scheme
    );
    if (schemeIndex === -1 && play.scheme) {
      nextSchemeOptions.push({ label: play.scheme, value: play.scheme });
    }
    setSchemeOptions(nextSchemeOptions);

    const nextSituationOptions = [...situationOptions];
    const situationIndex = nextSituationOptions.findIndex(
      (situation) => situation.value === play.situation
    );
    if (situationIndex === -1 && play.situation) {
      nextSituationOptions.push({
        label: play.situation,
        value: play.situation,
      });
    }
    setSituationOptions(nextSituationOptions);

    const nextPersonnelPackageOptions = [...personnelPackageOptions];
    const personnelPackageIndex = nextPersonnelPackageOptions.findIndex(
      (personnel) => personnel.value === play.personnel
    );
    if (personnelPackageIndex === -1 && play.personnel) {
      nextPersonnelPackageOptions.push({
        label: play.personnel,
        value: play.personnel,
      });
    }
    setPersonnelPackageOptions(nextPersonnelPackageOptions);

    const nextBlitzOptions = [...blitzOptions];
    const blitzIndex = nextBlitzOptions.findIndex(
      (blitz) => blitz.value === play.blitz
    );
    if (blitzIndex === -1 && play.blitz) {
      nextBlitzOptions.push({
        label: play.blitz,
        value: play.blitz,
      });
    }
    setBlitzOptions(nextBlitzOptions);

    const nextCoverageOptions = [...coverageOptions];
    const coverageIndex = nextCoverageOptions.findIndex(
      (coverage) => coverage.value === play.coverage
    );
    if (coverageIndex === -1 && play.coverage) {
      nextCoverageOptions.push({
        label: play.coverage,
        value: play.coverage,
      });
    }
    setCoverageOptions(nextCoverageOptions);
  }, [play]);

  return (
    <div className={styles.editPlayDetails}>
      <div className={styles.topRow}>
        <PlayModeSelector play={play} setPlay={setPlay} />
        <CharacterInput
          placeholder={!play.name ? "Name of the play" : undefined}
          value={play.name}
          maxLength={100}
          size="x-small"
          onChange={(e) => {
            setPlay({
              ...play,
              name: e.currentTarget.value,
            });
          }}
        />
        <Button
          theme="tertiary"
          size={"small"}
          onClick={openDuplicatePlayModal}
        >
          Duplicate
        </Button>
        <Button
          theme="tertiary"
          size={"small"}
          onClick={openDeletePlayModal}
          destructive
        >
          Delete Play
        </Button>
      </div>
      <div className={styles.selectsContainer}>
        <div className={styles.selectsContainerRow}>
          <SingleSelect
            id="playCategory"
            placeholder="Category"
            alwaysOpen
            options={categoryOptions}
            value={play.playCategory}
            onChange={(e) => {
              setPlay({
                ...play,
                playCategory: e.currentTarget.value as PlayCategoryEnum,
                scheme: null,
              });
            }}
          />
          <SingleSelect
            id="formation"
            placeholder={
              play.playType === "Offensive"
                ? "Offensive Formation *"
                : "Defensive Front *"
            }
            alwaysOpen
            options={formationOptions}
            value={selectedFormationId}
            onChange={(e) => {
              // only update formation name
              // or else it would be too easy for a user to accidentally delete player routes and notes
              updateFormationName(e.currentTarget.value);
            }}
          />
          <SingleSelect
            id="opponentFormation"
            placeholder={
              play.playType === "Offensive"
                ? "Defensive Front"
                : "Offensive Formation"
            }
            alwaysOpen
            options={opponentFormationOptions}
            value={selectedOpponentFormationId}
            onChange={(e) => {
              // TODO: Add a modal to warn user this will replace opponent formation and any modifications will be lost
              // before actually making this update
              updateOpponentFormation(e.currentTarget.value);
            }}
          />
          <SingleSelect
            id="scheme"
            placeholder="Subcategory"
            alwaysOpen
            options={schemeOptions}
            value={play.scheme || undefined}
            onChange={(e) => {
              setPlay({
                ...play,
                scheme: e.currentTarget.value || null,
              });
            }}
          />
        </div>
        <div
          className={`${styles.selectsContainerRow} ${
            play.playType === "Defensive"
              ? styles.secondChildDefense
              : styles.secondChildOffense
          }`}
        >
          {play.playType === "Defensive" && (
            <SingleSelect
              id="blitz"
              placeholder="Blitz"
              alwaysOpen
              options={blitzOptions}
              value={play.blitz || undefined}
              onChange={(e) => {
                setPlay({
                  ...play,
                  blitz: e.currentTarget.value || null,
                });
              }}
            />
          )}

          <SingleSelect
            id="personnel"
            placeholder={
              play.playType === "Offensive"
                ? "Personnel"
                : "Opponent's Personnel"
            }
            alwaysOpen
            options={personnelPackageOptions}
            value={play.personnel || undefined}
            onChange={(e) => {
              setPlay({
                ...play,
                personnel: e.currentTarget.value || null,
              });
            }}
          />
          <SingleSelect
            id="coverage"
            placeholder={
              play.playType === "Defensive" ? "Coverage" : "Opponent's Coverage"
            }
            alwaysOpen
            options={coverageOptions}
            value={play.coverage || undefined}
            onChange={(e) => {
              setPlay({
                ...play,
                coverage: e.currentTarget.value || null,
              });
            }}
          />
          <SingleSelect
            id="situation"
            placeholder="Situation"
            alwaysOpen
            options={situationOptions}
            value={play.situation || undefined}
            onChange={(e) => {
              setPlay({
                ...play,
                situation: e.currentTarget.value || null,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPlayDetails;
