import React, { useContext, useEffect, useState } from "react";

import styles from "./EditRoster.module.scss";
import Tabs from "../../../components/Tabs/Tabs";
import InvitePlayer from "./InvitePlayerModal";
import InviteCoach from "./InviteCoachModal";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { InvitationsContext } from "../../../shared/shared-with-mobile/providers/invitations.provider";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";
import { CoachesContext } from "../../../shared/shared-with-mobile/providers/coaches.provider";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import Avatar from "../../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import CloseIcon from "../../../resources/images/close.svg";
import Checkbox from "../../../components/Checkbox/Checkbox";
import DatePickerInput from "../../../components/DatePickerInput/DatePickerInput";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import PencilIcon from "../../../resources/icons/PencilIcon";
import Button from "../../../components/Button/Button";
import { InvitationCodeModel } from "../../../generated/from-api/models/invitation-code.model";

export enum ROSTER_TYPE {
  PLAYER = "PLAYER",
  INVITE = "INVITE",
  COACH = "COACH",
}

interface Props {
  visible: boolean;
  close: () => void;
}

export interface PlayerOrInvitee {
  type: ROSTER_TYPE.PLAYER | ROSTER_TYPE.INVITE;
  firstName: string;
  lastName: string;
  id: string;
  positions?: string[];
  toPhoneNumber?: string;
  schoolYear?: string;
  weightInLBS?: number;
  heightInInches?: number;
  jerseyNumber?: string;
  hideOnLeaderBoard?: boolean;
}

export interface CoachOrInvitee {
  type: ROSTER_TYPE.COACH | ROSTER_TYPE.INVITE;
  firstName: string;
  lastName: string;
  id: string;
  role?: string;
  toEmail?: string;
}

type RosterTab = "coaches" | "players" | "invitation code";

const EditRoster: React.FC<Props> = ({ visible, close }) => {
  const {
    replaceInvitations,
    currentCoachInvitations,
    currentPlayerInvitations,
  } = useContext(InvitationsContext);
  const { dispatchSingleModal, dispatchToast } = useContext(UIContext);
  const { currentPlayers } = useContext(PlayersContext);
  const { currentCoaches } = useContext(CoachesContext);
  const { currentTeam } = useContext(TeamContext);
  const { userProfile } = useContext(UserContext);
  const [closing, setClosing] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<RosterTab>("coaches");
  const [playerRoster, setPlayerRoster] = useState<PlayerOrInvitee[]>([]);
  const [coachRoster, setCoachRoster] = useState<CoachOrInvitee[]>([]);
  const [currentCoachRole, setCurrentCoachRole] = useState<string>("");

  const [currentInvitationCode, setCurrentInvitationCode] = useState<
    InvitationCodeModel | undefined
  >(undefined);
  const [
    showExpirationDatePicker,
    setShowExpirationDatePicker,
  ] = useState<boolean>(false);
  const [newCodeLoading, setNewCodeLoading] = useState<boolean>(false);
  const currentDate = new Date();
  const tomorrowDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(currentDate.setDate(currentDate.getDate() + 1));

  const [expiryDate, setExpiryDate] = useState<string>(tomorrowDate);
  useEffect(() => {
    if (!currentTeam || !visible) {
      return;
    }

    let isCancelled = false;
    const loadInvitations = async () => {
      const invitations = await APIService.INVITATION.GET(
        currentTeam.id as string
      );

      if (!isCancelled && invitations) {
        replaceInvitations(invitations);
      }
    };

    const loadInvitationCode = async () => {
      let existingCode = await APIService.INVITATION_CODE.GET(
        currentTeam.id as string
      );
      if (!existingCode) {
        existingCode = await APIService.INVITATION_CODE.POST({
          teamId: currentTeam.id as string,
        });
      }
      if (!isCancelled && existingCode) {
        setCurrentInvitationCode(existingCode);
        const expDate: any = existingCode.expirationDate;
        if (!expDate) {
          setShowExpirationDatePicker(false);
        } else {
          setShowExpirationDatePicker(true);
          setExpiryDate(expDate);
        }
      }
    };
    loadInvitationCode();
    loadInvitations();

    return () => {
      isCancelled = true;
    };
  }, [currentTeam, visible]);

  useEffect(() => {
    const newPlayerRoster: PlayerOrInvitee[] = [];

    currentPlayerInvitations?.forEach((invitation) => {
      if (invitation.accepted) {
        return;
      }

      newPlayerRoster.push({
        type: ROSTER_TYPE.INVITE,
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        positions: invitation.playerPositions?.split("|"),
        id: invitation.id as string,
        toPhoneNumber: invitation.toPhoneNumber,
      });
    });
    currentPlayers?.forEach((player) => {
      newPlayerRoster.push({
        type: ROSTER_TYPE.PLAYER,
        firstName: player.firstName,
        lastName: player.lastName,
        positions: player.positions?.split("|"),
        id: player.id as string,
        schoolYear: player.schoolYear as string,
        weightInLBS: player.weightInLBS,
        heightInInches: player.heightInInches,
        jerseyNumber: player.jerseyNumber,
        toPhoneNumber: player.phoneNumber,
        hideOnLeaderBoard: player.hideOnLeaderBoard,
      });
    });

    setPlayerRoster(newPlayerRoster);
  }, [currentPlayers, currentPlayerInvitations]);

  useEffect(() => {
    const newCoachRoster: CoachOrInvitee[] = [];

    currentCoachInvitations?.forEach((invitation) => {
      if (invitation.accepted) {
        return;
      }

      newCoachRoster.push({
        type: ROSTER_TYPE.INVITE,
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        role: invitation.coachRole,
        id: invitation.id as string,
        toEmail: invitation.toEmail,
      });
    });
    currentCoaches?.forEach((coach) => {
      newCoachRoster.push({
        type: ROSTER_TYPE.COACH,
        firstName: coach.firstName,
        lastName: coach.lastName,
        role: coach.role,
        id: coach.id as string,
      });
    });

    setCoachRoster(newCoachRoster);
  }, [currentCoaches, currentCoachInvitations]);

  useEffect(() => {
    if (currentCoaches && userProfile) {
      const COACHES = Array.from(currentCoaches.values());
      for (let i = 0; i < COACHES.length; i++) {
        if (COACHES[i].userId === userProfile.id) {
          if (COACHES[i].id) {
            setCurrentCoachRole(COACHES[i].role);
          }
        }
      }
    }
  }, [currentCoaches, userProfile]);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        close();
        setClosing(false);
      }, 350);
    }
  }, [closing]);

  const onExpirationDateSelected = (event: MaterialUiPickersDate) => {
    if (!currentInvitationCode) {
      return;
    }
    if (event) {
      const d = new Date(event.toString());
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      const newExpirationDate = `${ye}/${mo}/${da}`;
      updateInvitationCode(
        newExpirationDate,
        false,
        currentInvitationCode.disabled
      );
      setExpiryDate(event.toString());
    }
  };

  const toggleShowExpirationDatePicker = async (expires: boolean) => {
    if (!currentInvitationCode) {
      return;
    }
    setShowExpirationDatePicker(expires);
    if (!expires) {
      // if the code does not expire, we should clear the expiration date
      await updateInvitationCode(null, false, currentInvitationCode.disabled);
      // reset datepicker
      setExpiryDate(tomorrowDate);
    } else {
      // if the code should expire, we set the default expiry to tomorrow
      await updateInvitationCode(
        tomorrowDate,
        false,
        currentInvitationCode.disabled
      );
    }
  };

  const updateInvitationCode = async (
    expirationDate: string | null,
    generateANewCode: boolean,
    disabled: boolean | undefined
  ) => {
    if (!currentInvitationCode || !currentTeam) {
      return;
    }
    setNewCodeLoading(true);
    try {
      const updatedCode = await APIService.INVITATION_CODE.PUT(
        {
          teamId: currentTeam.id as string,
          expirationDate: expirationDate,
          disabled: disabled,
        },
        generateANewCode
      );
      setCurrentInvitationCode(updatedCode);
      setShowExpirationDatePicker(
        updatedCode ? !!updatedCode.expirationDate : false
      );
      setNewCodeLoading(false);
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "There's been an error. Please try again.",
      });
      setNewCodeLoading(false);
    }
  };

  const handleAddPlayerOrCoach = () => {
    dispatchSingleModal({
      title: currentTab === "coaches" ? "Invite Coach" : "Invite Player",
      open: true,
      body: currentTab === "coaches" ? <InviteCoach /> : <InvitePlayer />,
    });
  };

  const handleEditRoster = (
    player?: PlayerOrInvitee,
    coach?: CoachOrInvitee
  ) => {
    dispatchSingleModal({
      title: currentTab === "coaches" ? "Edit Coach" : "Edit Player",
      open: true,
      body:
        currentTab === "coaches" ? (
          <InviteCoach coach={coach} />
        ) : (
          <InvitePlayer player={player} />
        ),
    });
  };

  return (
    <>
      <div
        className={`
          ${styles.editRosterWrapper}
          ${visible ? styles.open : ""}
          ${closing ? styles.closing : ""}
        `}
        data-lockfocus={visible}
      >
        <div
          className={`
            ${styles.editRoster}
          `}
        >
          <div className={styles.modalHeader}>
            Edit Roster{" "}
            <span className={styles.closeIcon} onClick={() => setClosing(true)}>
              <img src={CloseIcon} />
            </span>
          </div>
          <div className={styles.editRosterBody}>
            <Tabs
              onSelect={(selection) => setCurrentTab(selection as RosterTab)}
            >
              {["coaches", "players", "invitation code"]}
            </Tabs>
            <div className={styles.rosterTab}>
              {currentTab === "coaches" && (
                <>
                  <div className={styles.rosterListWrapper}>
                    <ul className={styles.rosterList}>
                      {coachRoster.length > 0 &&
                        coachRoster.map((coach) => (
                          <li key={coach.id} className={styles.rosterItem}>
                            <span>
                              <Avatar userId={coach.id} type="coach" small />
                            </span>
                            <span>
                              {coach.firstName} {coach.lastName}
                            </span>
                            <span>
                              {coach.role === "DEFAULT"
                                ? "ASSISTANT"
                                : coach.role}
                            </span>
                            <span>
                              {coach.type === ROSTER_TYPE.INVITE && (
                                <Button size={"small"} disabled>
                                  Invited
                                </Button>
                              )}
                            </span>
                            <span>
                              {currentCoachRole === "HEAD" ? (
                                <Button
                                  theme={"transparent"}
                                  icon={<PencilIcon />}
                                  onClick={() =>
                                    handleEditRoster(undefined, coach)
                                  }
                                />
                              ) : (
                                <Button
                                  theme={"transparent"}
                                  icon={<PencilIcon />}
                                  onClick={() =>
                                    handleEditRoster(undefined, coach)
                                  }
                                  disabled
                                  destructive
                                />
                              )}
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className={styles.rosterTabFooter}>
                    <Button
                      size={"small"}
                      onClick={() => handleAddPlayerOrCoach()}
                    >
                      Add Coach
                    </Button>
                  </div>
                </>
              )}
              {currentTab === "players" && (
                <>
                  <div className={styles.rosterListWrapper}>
                    <ul className={styles.rosterList}>
                      {playerRoster.length > 0 &&
                        playerRoster.map((player) => (
                          <li key={player.id} className={styles.rosterItem}>
                            <span>
                              <Avatar userId={player.id} type="player" small />
                            </span>
                            <span>
                              {player.firstName} {player.lastName}
                            </span>
                            <span>
                              {player.positions
                                ?.toString()
                                .replaceAll(",", ", ")}
                            </span>
                            <span>
                              {player.type === ROSTER_TYPE.INVITE && (
                                <Button size={"small"} disabled>
                                  Invited
                                </Button>
                              )}
                            </span>
                            <span>
                              <Button
                                theme={"transparent"}
                                icon={<PencilIcon />}
                                onClick={() => handleEditRoster(player)}
                              />
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className={styles.rosterTabFooter}>
                    <Button
                      size={"small"}
                      onClick={() => handleAddPlayerOrCoach()}
                    >
                      Add Player
                    </Button>
                  </div>
                </>
              )}
              {currentTab === "invitation code" && currentInvitationCode && (
                <>
                  <div className={styles.container}>
                    <div className={styles.inviteText}>
                      {!currentInvitationCode.disabled
                        ? "This code will allow players to join your team by providing it in the Team Nation mobile app. Copy the code below and share it with your players."
                        : "Activating this code will allow players to join your team by providing it in the Team Nation mobile app."}
                    </div>

                    <p>Your code:</p>
                    <div
                      className={`
                                  ${styles.codeContainer}
                                  ${
                                    !currentInvitationCode.disabled
                                      ? styles.codeActivated
                                      : styles.codeDeactivated
                                  }
                                `}
                    >
                      {currentInvitationCode.disabled
                        ? ""
                        : currentInvitationCode.invitationCode}
                    </div>
                    {!currentInvitationCode.disabled && (
                      <div className={styles.expiryDateSection}>
                        <div className={styles.expiryDateCheckBox}>
                          <Checkbox
                            id="display-game"
                            onChange={(e) =>
                              toggleShowExpirationDatePicker(
                                e.currentTarget.checked
                              )
                            }
                            checked={showExpirationDatePicker}
                          >
                            Code Expires
                          </Checkbox>
                        </div>
                        {showExpirationDatePicker && (
                          <DatePickerInput
                            label="Expiration Date"
                            onChange={(event) =>
                              onExpirationDateSelected(event)
                            }
                            value={expiryDate}
                            format="D"
                            theme="dark"
                            disablePast={true}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className={styles.rosterTabFooter}>
                    <Button
                      theme={"secondary"}
                      size={"small"}
                      onClick={() => updateInvitationCode(null, true, false)}
                      disabled={newCodeLoading}
                    >
                      New Code
                    </Button>
                    <Button
                      size={"small"}
                      theme={
                        !currentInvitationCode.disabled
                          ? "secondary"
                          : undefined
                      }
                      onClick={() =>
                        updateInvitationCode(
                          null,
                          false,
                          !currentInvitationCode.disabled
                        )
                      }
                      disabled={newCodeLoading}
                    >
                      {currentInvitationCode.disabled
                        ? "Activate Code"
                        : "Deactivate Code"}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.editRosterOverlay} ${visible ? styles.open : ""} ${
          closing ? styles.closing : ""
        }`}
        onClick={() => setClosing(true)}
      />
    </>
  );
};

export default EditRoster;
