import mainStyles from "../Dashboard.module.scss";
import CharacterInput from "../../../components/Input/CharacterInput";
import searchIcon from "../../../resources/images/search.svg";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import ToggleSelect from "../../../shared/shared-with-mobile/components/ToggleSelect/ToggleSelect";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReadinessScoresContext } from "../../../shared/shared-with-mobile/providers/readinessScores.provider";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import { DashboardStatsGrid } from "../../../components/DashboardStatsGrid/DashboardStatsGrid";
import OverallReadiness from "../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/OverallReadiness";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import PlayerModal from "../Components/PlayerModal/PlayerModal";
import dashboardStatsStyles from "../../../components/DashboardStatsGrid/DashboardStatsGrid.module.scss";
import Avatar from "../../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import CircularSpinner from "../../../components/Loaders/CircularSpinner";
import { isEmpty } from "lodash";
import { getAbbreviatedDisplayName } from "../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import { PositionFiltersMap } from "../../../shared/shared-with-mobile/functions";
import QuizQuizzes from "./Quiz-components/QuizDrillIn/QuizQuizzes";
import { playersFilter } from "../../../utils/playerFilter.utils";
import ReadinessOverTime from "../../../shared/shared-with-mobile/dashboard-widgets/ReadinessOverTime/ReadinessOverTime";
import {
  match,
  RouteComponentProps,
  useHistory,
  withRouter,
} from "react-router";
import { DashboardUtility } from "../../../shared/shared-with-mobile/utilities/dashboard.utility";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import GenericConfirmationModal from "../../../components/GenericConfirmationModal/ConfirmationModal";
import Button from "../../../components/Button/Button";

const filtersForPlayersAndLessons = ["Players", "Flashcards"];

type PlayerRow = {
  playerFirstName: string;
  playerLastName: string;
  playerId: string;
  playerUserId: string;
  positions: string;
  readiness: number;
};
type QuizRow = {
  quizName: string;
  quizId: string;
  categoryId: string;
  readiness: number;
};

interface Props extends RouteComponentProps {
  match: match<MatchParams>;
}
interface MatchParams {
  id: string;
}

const DashboardQuizzes: React.FC<Props> = ({ match }) => {
  const { currentTeam } = useContext(TeamContext);
  const { dispatchModal, dispatchToast } = useContext(UIContext);
  const {
    loading,
    playerQuizMetrics,
    teamMetricsOverTime,
    setPlayerOverallMetrics,
    setPlayerQuizMetrics,
  } = useContext(ReadinessScoresContext);
  const { currentPlayersAsArray } = useContext(PlayersContext);
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState(PositionFiltersMap[0]);
  const [searchText, setSearchText] = useState("");

  const { customQuizCategoryTags } = useContext(TagsContext);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [
    selectedFilterForPlayersAndLessons,
    setSelectedFilterForPlayersAndLessons,
  ] = useState(filtersForPlayersAndLessons[0]);
  const [quizCategories, setQuizCategories] = useState([
    {
      label: "All",
      value: "",
    },
  ]);

  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [showQuizDrillIn, setShowQuizDrillIn] = useState(false);
  const [quizId, setQuizId] = useState<string>("");
  const [player, setPlayer] = useState<PlayerModel>();
  const [dataForPlayerStatsGrid, setDataForPlayerStatsGrid] = useState<
    PlayerRow[]
  >();
  const [dataForQuizzestatsGrid, setDataForQuizzestatsGrid] = useState<
    QuizRow[]
  >();

  useEffect(() => {
    if (
      currentPlayersAsArray &&
      !isEmpty(currentPlayersAsArray) &&
      playerQuizMetrics &&
      !isEmpty(playerQuizMetrics) &&
      customQuizCategoryTags
    ) {
      const newCategories = customQuizCategoryTags.map((c) => {
        return { label: c.name, value: c.id ? c.id : "" };
      });
      newCategories.unshift({ label: "All", value: "" });
      setQuizCategories(newCategories);

      const quizzesMap: Map<
        string,
        {
          id: string;
          quizName: string;
          categoryId: string;
          quizPositions: string;
        }
      > = new Map();
      playerQuizMetrics.forEach((p) => {
        quizzesMap.set(p.quizId, {
          id: p.quizId,
          quizName: p.quizName,
          categoryId: p.categoryId,
          quizPositions: p.quizPositions,
        });
      });
      const quizzesArray = Array.from(quizzesMap.values());

      const allPlayers = currentPlayersAsArray;
      const playerRows: PlayerRow[] = allPlayers
        .filter((player: PlayerModel) => !player.hideOnLeaderBoard)
        .map((p) => {
          const playerQuizzes = DashboardUtility.selectPlayerQuizzes(
            p,
            playerQuizMetrics
          );
          const playerQuizCount = playerQuizzes.length;
          let playerScoreSum = 0;
          playerQuizzes.forEach((quiz) => {
            playerScoreSum = playerScoreSum + +quiz.readinessScore;
          });

          return {
            playerFirstName: p.firstName,
            playerLastName: p.lastName,
            playerId: p.id ? p.id : "",
            playerUserId: p.userId,
            positions: p.positions ? p.positions : "",
            readiness:
              playerQuizCount === 0 ? 0 : playerScoreSum / playerQuizCount,
          };
        });
      setDataForPlayerStatsGrid(playerRows);

      const quizRows: QuizRow[] = quizzesArray.map((quiz) => {
        const scoreSum = playerQuizMetrics.reduce((prev, cur) => {
          if (cur.quizId == quiz.id) {
            return prev + +cur.readinessScore;
          } else {
            return prev;
          }
        }, 0);
        const currentPlayersToDisplay = currentPlayersAsArray.filter(
          (player: PlayerModel) => !player.hideOnLeaderBoard
        );
        return {
          quizId: quiz.id,
          quizName: quiz.quizName,
          categoryId: quiz.categoryId,
          readiness:
            currentPlayersToDisplay.length == 0
              ? 0
              : scoreSum / currentPlayersToDisplay.length,
        };
      });
      setDataForQuizzestatsGrid(quizRows);
    }
  }, [currentPlayersAsArray, playerQuizMetrics, customQuizCategoryTags]);

  const onRowClick = (id: string) => {
    setPlayer(
      currentPlayersAsArray.find((player: PlayerModel) => player.id === id)
    );
    setShowPlayerModal(true);
  };

  const onQuizRowClick = (id: string) => {
    history.push(`/dashboard/flashcards/${id}`);
    setQuizId(id);
    setShowQuizDrillIn(true);
  };

  useEffect(() => {
    if (match.params.id) {
      setQuizId(match.params.id);
      setShowQuizDrillIn(true);
    } else {
      setShowQuizDrillIn(false);
    }
  }, [match.params.id]);

  const getCategoryName = (id: string) => {
    if (customQuizCategoryTags) {
      const existing = customQuizCategoryTags.find((lc) => lc.id === id);
      if (existing) {
        return existing.name;
      }
    }
    return "Uncategorized";
  };

  const columnsForPlayers = useMemo(
    () => [
      {
        name: "PLAYER",
        selector: (row: PlayerRow) => row.playerId,
        cell: (row: PlayerRow) => {
          return (
            <div
              className={`${dashboardStatsStyles.clickableCell} ${dashboardStatsStyles.flexRow}`}
              onClick={() => onRowClick(row.playerId)}
            >
              <div className={dashboardStatsStyles.usreListImg}>
                <Avatar userId={row.playerId} small type="player" />
              </div>

              <span className={dashboardStatsStyles.playerName}>
                {getAbbreviatedDisplayName(
                  row.playerFirstName,
                  row.playerLastName
                )}
              </span>
              {row.positions && (
                <div className={dashboardStatsStyles.playerPosition}>
                  {row.positions}
                </div>
              )}
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "FLASHCARD READINESS",
        selector: (row: PlayerRow) => +row.readiness,
        format: (row: PlayerRow) => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [currentPlayersAsArray]
  );

  const columnsForQuizzes = useMemo(
    () => [
      {
        name: "Flashcard",
        selector: (row: QuizRow) => row.quizName,
        cell: (row: QuizRow) => {
          return (
            <div
              className={dashboardStatsStyles.clickableCell}
              onClick={() => onQuizRowClick(row.quizId)}
            >
              <span className={dashboardStatsStyles.playerName}>
                {row.quizName}
              </span>
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "CATEGORY",
        selector: (row: QuizRow) => row.categoryId,
        format: (row: QuizRow): string => `${getCategoryName(row.categoryId)}`,
        sortable: true,
        right: true,
      },
      {
        name: "TEAM PERFORMANCE (ALL TIME)",
        selector: (row: QuizRow) => +row.readiness,
        format: (row: QuizRow): string => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [dataForQuizzestatsGrid]
  );

  const openResetScoresModal = () => {
    if (currentTeam && currentTeam.id) {
      dispatchModal({
        title: "Reset Quiz Scores for this Team",
        open: true,
        body: (
          <GenericConfirmationModal
            message="Are you sure you want to reset the team's performance for Flashcards? Players will lose their progress on all Flashcards, but Playbook & Lessons performance will not be affected. This may take up to 24 hours to reflect across all dashboards."
            confirmLabel="Reset Scores"
            cancelLabel="Cancel"
            actionCallback={async () => {
              try {
                await APIService.CUSTOM_QUIZ_STATISTIC.RESET_SCORES(
                  currentTeam.id as string
                );
                const playerOverallMetrics = await APIService.DASHBOARD.GET_PLAYER_OVERALL_METRICS(
                  currentTeam.id as string
                );
                if (playerOverallMetrics) {
                  setPlayerOverallMetrics(
                    playerOverallMetrics.overallReadinessList
                  );
                }
                const playerQuizMetrics = await APIService.DASHBOARD.GET_PLAYER_QUIZ_METRICS(
                  currentTeam.id as string
                );
                if (playerQuizMetrics) {
                  setPlayerQuizMetrics(playerQuizMetrics);
                }
                dispatchToast({
                  type: "success",
                  message: "Scores reset.",
                });
              } catch (e) {
                dispatchToast({
                  type: "error",
                  message: "Error resetting scores.",
                });
              }
            }}
          />
        ),
      });
    }
  };

  return loading ? (
    <CircularSpinner />
  ) : !showQuizDrillIn ? (
    <div>
      <div className={mainStyles.dashboardHeader}>
        <p className={mainStyles.dashboardHeader__Title}>Flashcard Readiness</p>
        <Button
          type={"button"}
          theme={"secondary"}
          size={"small"}
          onClick={openResetScoresModal}
        >
          Reset Flashcard Scores
        </Button>
      </div>

      <div className={mainStyles.chartCardRow}>
        <ReadinessOverTime
          title="Team Flashcard Readiness"
          xLabel="Date"
          yLabel="Readiness"
          ySymbol="%"
          data={
            teamMetricsOverTime
              ? [
                  {
                    id: "line1",
                    data: teamMetricsOverTime.map((t) => {
                      return {
                        x: t.created,
                        y: +t.quizReadinessScore,
                      };
                    }),
                  },
                ]
              : []
          }
        />
        <OverallReadiness
          title="Flashcard Readiness Distribution"
          type="Bar-Distribution"
          isLabel={true}
          xLabel="Ready"
          yLabel="% of Players"
          ySymbol="%"
          scoreType="quizReadinessScore"
        />
        <OverallReadiness
          title="Flashcard Readiness by Unit"
          type="Bar-Unit"
          xLabel="Unit"
          yLabel="Readiness"
          ySymbol="%"
          scoreType="quizReadinessScore"
        />
      </div>

      <div className={mainStyles.searchAndFilterBtn}>
        <div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Search"
            id="search"
            size="x-small"
            icon={searchIcon}
            clearButton
            value={searchText}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
        </div>
        <div className={mainStyles.toggleTableData}>
          <ToggleSelect
            options={filtersForPlayersAndLessons}
            selectedOption={selectedFilterForPlayersAndLessons}
            background="#151724"
            borderColor="#151724"
            onChange={(newSelection1: string) => {
              setSelectedFilterForPlayersAndLessons(newSelection1);
            }}
          />
        </div>
        <div className={mainStyles.filterBtns}>
          {selectedFilterForPlayersAndLessons == "Players" ? (
            <ToggleSelect
              options={PositionFiltersMap.map((f) => f.label)}
              selectedOption={selectedFilter.label}
              background="#151724"
              borderColor="#151724"
              onChange={(label: string) => {
                const newFilter = PositionFiltersMap.find(
                  (f) => f.label == label
                );
                if (newFilter) {
                  setSelectedFilter(newFilter);
                }
              }}
            />
          ) : (
            <SingleSelect
              id="quizCategory"
              placeholder="Category *"
              alwaysOpen={false}
              options={quizCategories}
              className={mainStyles.selectCategories}
              onChange={(e) => {
                setSelectedCategory(e.currentTarget.value);
              }}
            />
          )}
        </div>
      </div>
      <br />
      {selectedFilterForPlayersAndLessons == "Players" ? (
        <>
          <div>
            <DashboardStatsGrid
              data={dataForPlayerStatsGrid?.filter(
                playersFilter({
                  searchString: searchText,
                  filterByPosition: selectedFilter.value,
                })
              )}
              columns={columnsForPlayers}
            />
          </div>
          {player && (
            <PlayerModal
              player={player}
              visible={showPlayerModal}
              defaultTab={"flashcards"}
              close={() => setShowPlayerModal(false)}
            />
          )}
        </>
      ) : (
        <div>
          <DashboardStatsGrid
            data={dataForQuizzestatsGrid?.filter((m) => {
              return (
                (selectedCategory ? m.categoryId == selectedCategory : true) &&
                (searchText
                  ? ("" + m.quizName)
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase(), 0) !== -1
                  : true)
              );
            })}
            columns={columnsForQuizzes}
          />
        </div>
      )}
    </div>
  ) : (
    <QuizQuizzes
      quizId={quizId}
      close={() => history.push("/dashboard/flashcards")}
    />
  );
};

export default withRouter(DashboardQuizzes);
