/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from "react";
import styles from "./QuizImportModal.module.scss";
import { APIService } from "../../../../shared/shared-with-mobile/api-client/api.service";
import { QuizzesContext } from "../../../../shared/shared-with-mobile/providers/quizzes.provider";
import { TeamContext } from "../../../../shared/shared-with-mobile/providers/team.provider";
import { UserContext } from "../../../../shared/shared-with-mobile/providers/user.provider";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import { userRoles } from "../../../../shared/shared-with-mobile/constants";
import { TagTypeEnum } from "../../../../generated/from-api/models/enums/tag-type.enum";
import { TagModel } from "../../../../generated/from-api/models/tag.model";
import { CustomQuizModel } from "../../../../generated/from-api/models/custom-quiz.model";
import QuizImportToolbar from "./QuizImportToolbar/QuizImportToolbar";
import SelectedQuiz from "./SelectedQuiz/SelectedQuiz";
import QuizImportRename from "./QuizImportRename/QuizImportRename";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { generateCategoryNameById } from "../../../../utils/functions";
import CloseIconWhite from "../../../../resources/icons/CloseIconWhite";
import Button from "../../../../components/Button/Button";

interface Props {
  importQuiz: (q: CustomQuizModel) => void;
  specificQuiz?: CustomQuizModel;
}

const QuizImportModal = ({ importQuiz, specificQuiz }: Props): JSX.Element => {
  const { quizzes } = useContext(QuizzesContext);
  const { currentTeam } = useContext(TeamContext);
  const { userProfile } = useContext(UserContext);
  const { closeModal, dispatchToast, dispatchModal } = useContext(UIContext);
  const [closing, setClosing] = useState<boolean>(false);
  const [globalQuizzes, setGlobalQuizzes] = useState<CustomQuizModel[]>();
  const [globalCategories, setGlobalCategories] = useState<any>({ All: "" });
  const [selectedTab, setSelectedTab] = useState<any>({ name: "All", id: "" });
  const [searchedQuiz, setSearchedQuiz] = useState<string>("");
  const [positions, setPositions] = useState<string[]>([]);
  const [filters] = useState<string[]>(["Flashcard Name", "Date Created"]);
  const [selectedSortFilter, setSelectedSortFilter] = useState<string>("");
  const [disabledQuizzes, setDisabledQuizzes] = useState<any>({});
  const [clearInputs, setClearInputs] = useState<boolean>(false);
  const [selectedQuiz, setSelectedQuiz] = useState<
    CustomQuizModel | undefined
  >();
  const [isQuizSelected, setIsQuizSelected] = useState<boolean>(false);
  const [globalMediaMap, setGlobalMediaMap] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!currentTeam || userProfile?.role !== userRoles.USER) {
      return;
    }
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await APIService.CUSTOM_QUIZ.LIST();
        setGlobalQuizzes(response);
        setSelectedSortFilter("Date Created");
        const categories = await (
          await APIService.TAG.GET_TAGS_FOR_TEAM(null)
        ).filter(
          (tag: TagModel) => tag.type === TagTypeEnum.CUSTOM_QUIZ_CATEGORY
        );

        const obj: any = {};

        categories.forEach((category) => {
          obj[trim(category.name as string)] = category.id as string;
        });

        setGlobalCategories({ ...globalCategories, ...obj });
        const mediaItems = await APIService.MEDIA.LIST();
        setGlobalMediaMap(mediaItems);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [currentTeam]);

  useEffect(() => {
    if (specificQuiz && !isLoading) {
      setSelectedQuiz(specificQuiz);
      setIsQuizSelected(true);
    }
  }, [specificQuiz]);

  useEffect(() => {
    if (!selectedTab && globalCategories) {
      setSelectedTab({ name: "All", id: "" });
    }
  }, [globalCategories]);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        close();
        setClosing(false);
      }, 350);
    }
  }, [closing]);

  useEffect(() => {
    if (!isEmpty(globalQuizzes)) {
      if (selectedSortFilter === "Flashcard Name") {
        const alphabeticallySortedData = [
          ...(globalQuizzes as any),
        ].sort((a: any, b: any) =>
          trim(a.name.toLowerCase()) === trim(b.name.toLowerCase())
            ? 0
            : trim(a.name.toLowerCase()) < trim(b.name.toLowerCase())
            ? -1
            : 1
        );
        setGlobalQuizzes(alphabeticallySortedData);
      }
      if (selectedSortFilter === "Date Created") {
        const sortedDataByTime: any = [...(globalQuizzes as any)].sort(
          (a, b) => {
            if (a.created && b.created) {
              return (
                new Date(a.created).getTime() - new Date(b.created).getTime()
              );
            }
            return -1;
          }
        );
        setGlobalQuizzes(sortedDataByTime);
      }
    }
  }, [selectedSortFilter]);

  const areQuizzesShowableByPosition = (
    positionsToShowArray: any,
    filteredPositionsArray: any
  ) => {
    const obj: any = {};

    positionsToShowArray.forEach((position: string) => {
      if (!obj[position]) {
        obj[position] = true;
      }
    });

    return filteredPositionsArray.every((item: string) =>
      obj.hasOwnProperty(item)
    );
  };

  const resetFiltersHandler = () => {
    setClearInputs(true);
    setSearchedQuiz("");
  };

  const onRenameOpen = (quiz: CustomQuizModel) => {
    dispatchModal({
      open: true,
      size: "small",
      body: <QuizImportRename quiz={quiz} onSubmitCallback={onRenameSubmit} />,
    });
  };

  const copyGlobalQuiz = (quiz: CustomQuizModel) => {
    importQuiz(quiz);
    if (!disabledQuizzes.hasOwnProperty(quiz.name)) {
      setDisabledQuizzes({
        ...disabledQuizzes,
        [quiz.name]: true,
      });
    }
  };

  const onRenameSubmit = (quiz: CustomQuizModel) => {
    addGlobalQuiz(quiz);
  };

  const addGlobalQuiz = async (quiz: CustomQuizModel) => {
    if (!currentTeam || !currentTeam.id) {
      return;
    }

    try {
      const newQuiz = {
        ...quiz,
        id: "",
        teamId: currentTeam?.id,
        created: undefined,
        modified: undefined,
        archived: undefined,
        published: true,
      };
      if (
        !quizzes ||
        quizzes.every((item: CustomQuizModel) => item.name !== quiz.name)
      ) {
        copyGlobalQuiz(newQuiz);
      } else {
        onRenameOpen(newQuiz);
      }
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "There's been an error. Please try again.",
      });
    }
  };

  return (
    <>
      <div
        className={`
          ${styles.quizImportModalWrapper}
          ${styles.open}

        `}
      >
        <div
          className={`
            ${styles.quizImportModal}
          `}
        >
          {!isQuizSelected ? (
            <>
              <div className={styles.modalHeader}>
                <span>Team Nation Flashcard Library</span>
                <Button
                  theme={"transparent"}
                  size={"small"}
                  icon={<CloseIconWhite />}
                  onClick={() => closeModal()}
                />
              </div>
              <div className={styles.quizModalContent}>
                <div className={styles.sidebar}>
                  {Object.keys(globalCategories)?.map((category) => (
                    <div
                      key={category}
                      className={`
                    ${styles.tab}
                    ${selectedTab.name === category ? styles.selected : ""}
                  `}
                      onClick={() => {
                        setSelectedTab({
                          name: category,
                          id: globalCategories[category],
                        });
                      }}
                    >
                      {category}
                    </div>
                  ))}
                </div>
                <div className={styles.quizImportTab}>
                  <div className={styles.toolbar}>
                    <QuizImportToolbar
                      onPositionsChangeCallback={setPositions}
                      onSearchChangeCallback={setSearchedQuiz}
                      onSortChangeCallback={setSelectedSortFilter}
                      positions={positions}
                      filters={filters}
                      selectedFilter={selectedSortFilter}
                      searchTerm={searchedQuiz}
                      clearInputs={clearInputs}
                    />
                  </div>

                  <div className={styles.quizImportWrapper}>
                    {isLoading ? (
                      <div className={styles.loader} />
                    ) : (
                      <ul className={styles.quizList}>
                        {globalQuizzes &&
                          globalQuizzes.length > 0 &&
                          globalQuizzes
                            .filter((item) =>
                              !isEmpty(selectedTab.id)
                                ? item.categoryTagId === selectedTab.id
                                : item
                            )
                            .map((quiz) => (
                              <li
                                key={quiz.id}
                                className={styles.quizItem}
                                style={{
                                  display:
                                    !quiz.name
                                      .toString()
                                      .toLowerCase()
                                      .includes(
                                        searchedQuiz.toString().toLowerCase()
                                      ) ||
                                    (!isEmpty(
                                      Object.values(
                                        positions[positions.length - 1]
                                      )[0]
                                    ) &&
                                      !areQuizzesShowableByPosition(
                                        quiz.showQuizToPositions.split("|"),
                                        Object.values(
                                          positions[positions.length - 1]
                                        )[0]
                                      ))
                                      ? "none"
                                      : "grid",
                                }}
                              >
                                <span>{quiz.name}</span>
                                <div className={styles.tagContainer}>
                                  <span className={styles.tag}>
                                    {generateCategoryNameById(
                                      quiz.categoryTagId
                                        ? quiz.categoryTagId
                                        : "Uncategorized",
                                      globalCategories
                                    )}
                                  </span>
                                </div>
                                <div className={styles.buttonGroup}>
                                  <span>
                                    <Button
                                      size="small"
                                      theme="tertiary"
                                      onClick={() => {
                                        setSelectedQuiz(quiz);
                                        setIsQuizSelected(true);
                                      }}
                                    >
                                      Preview
                                    </Button>
                                  </span>
                                  <span>
                                    <Button
                                      size={"small"}
                                      theme="secondary"
                                      onClick={(event: React.MouseEvent) => {
                                        event.stopPropagation();
                                        addGlobalQuiz(quiz);
                                      }}
                                      disabled={disabledQuizzes.hasOwnProperty(
                                        quiz.name
                                      )}
                                    >
                                      {disabledQuizzes.hasOwnProperty(quiz.name)
                                        ? "Copied"
                                        : "Add Flashcard"}
                                    </Button>
                                  </span>
                                </div>
                              </li>
                            ))}
                      </ul>
                    )}
                    {!globalQuizzes
                      ?.filter((item) =>
                        !isEmpty(selectedTab.id)
                          ? item.categoryTagId === selectedTab.id
                          : item
                      )
                      .find((quiz) =>
                        quiz.name.toLowerCase().includes(searchedQuiz)
                      ) &&
                      !isEmpty(
                        globalQuizzes?.filter((item) =>
                          !isEmpty(selectedTab.id)
                            ? item.categoryTagId === selectedTab.id
                            : item
                        )
                      ) && (
                        <ErrorDisplay
                          body1Message="flashcards"
                          onClickCallback={resetFiltersHandler}
                          resetFilterBoolean={setClearInputs}
                        />
                      )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {selectedQuiz && (
                <SelectedQuiz
                  quiz={selectedQuiz}
                  setIsQuizSelected={setIsQuizSelected}
                  generateCategoryNameById={generateCategoryNameById}
                  globalCategories={globalCategories}
                  globalMediaMap={globalMediaMap}
                  addGlobalQuiz={addGlobalQuiz}
                  disabledQuizzes={disabledQuizzes}
                  setDisabledQuizzes={setDisabledQuizzes}
                />
              )}
            </>
          )}
        </div>
      </div>

      <div
        className={`${styles.quizImportModalOverlay} ${styles.open} `}
        onClick={() => setClosing(true)}
      />
    </>
  );
};

export default QuizImportModal;
