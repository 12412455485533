import * as React from "react";

const PlayArrowIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="10"
    viewBox="0 0 8 10"
  >
    <path d="M0.333313 0.333313V9.66665L7.66665 4.99998L0.333313 0.333313Z" />
  </svg>
);

export default PlayArrowIcon;
