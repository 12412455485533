import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { TagFolderModel } from "../../../../generated/from-api/models/tag.model";
import { API_BASE_URL } from "../../constants";

export const TagFolderService = {
  /**
   *Retrieve a list of tagFolders for a team or for the global Media, also can filter with query param "?type="
   *@param teamId
   *@returns TagFolderModel[]
   */

  GET_TAG_FOLDERS_FOR_TEAM: async function (
    teamId: string | undefined
  ): Promise<TagFolderModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.TAG_FOLDER}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   * Retrieve a tagFolder by id
   * @param tagFolderId
   * @returns tagFolder
   */
  GET: async function (tagFolderId: string): Promise<TagFolderModel> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.TAG_FOLDER}/${tagFolderId}`)
    ).data;
  },

  /**
   * Use to create a tag folder in team
   * if TeamId is null, it is created in global TagFolder (Admin only)
   * @param tagFolderData
   * @returns tagFolder
   */
  POST: async function (
    tagFolderData: Pick<TagFolderModel, "name" | "teamId">
  ): Promise<TagFolderModel | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.TAG_FOLDER}`, tagFolderData)
    ).data;
  },

  /**
   * Update a tag folder
   * @param tagFolderId
   * @param dataToUpdate
   * @returns updated tag folder
   */
  PUT: async function (
    tagFolderId: string,
    dataToUpdate: Pick<TagFolderModel, "name">
  ): Promise<TagFolderModel> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.TAG_FOLDER}`, {
        id: tagFolderId,
        ...dataToUpdate,
      })
    ).data;
  },

  /**
   * Delete a tag folder by id,
   * @param tagFolderId
   * @returns
   */
  DELETE: async function (tagFolderId: string): Promise<{ deleted: boolean }> {
    return (
      await axios.delete(
        `${API_BASE_URL}${APIRoutes.TAG_FOLDER}/${tagFolderId}`
      )
    ).data;
  },
};
