import {
  PlayerToQuizReadinessModel,
  PlayerToInstallReadinessModel,
} from "../../../generated/from-api/models/dtos/player-to-play.readiness.model";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import { getPositionArray } from "../functions";

export const DashboardUtility = {
  selectPlayerQuizzes: (
    player: PlayerModel | undefined,
    playerQuizMetrics: PlayerToQuizReadinessModel[]
  ): PlayerToQuizReadinessModel[] => {
    if (!player) return [];
    const playerPositions = getPositionArray(player.positions);
    const quizPositionMap = new Map<string, string[]>();
    for (const quiz of playerQuizMetrics) {
      if (quizPositionMap.has(quiz.quizId)) continue;
      quizPositionMap.set(quiz.quizId, getPositionArray(quiz.quizPositions));
    }
    const playerQuizzes = playerQuizMetrics.filter((p) => {
      if (p.playerId !== player.id) return false;
      const quizPositions = quizPositionMap.get(p.quizId) || [];
      if (quizPositions.length === 0 || playerPositions.length === 0)
        return true;
      return quizPositions.some((position) => {
        return playerPositions.includes(position);
      });
    });
    return playerQuizzes;
  },

  selectPlayerInstalls: (
    player: PlayerModel | undefined,
    playerInstallMetrics: PlayerToInstallReadinessModel[]
  ): PlayerToInstallReadinessModel[] => {
    if (!player) return [];
    const playerPositions = getPositionArray(player.positions);
    const lessonPositionMap = new Map<string, string[]>();
    for (const install of playerInstallMetrics) {
      if (lessonPositionMap.has(install.installId)) continue;
      lessonPositionMap.set(
        install.installId,
        getPositionArray(install.installPositions)
      );
    }
    const playerInstalls = playerInstallMetrics.filter((p) => {
      if (p.playerId !== player.id) return false;
      const installPositions = lessonPositionMap.get(p.installId) || [];
      if (installPositions.length === 0 || playerPositions.length === 0)
        return true;
      return installPositions.some((position) => {
        return playerPositions.includes(position);
      });
    });
    return playerInstalls;
  },
};
