import React, { useContext, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";

import styles from "./Account.module.scss";
import { LicensesContext } from "../../shared/shared-with-mobile/providers/licenses.provider";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { Link, useParams } from "react-router-dom";
import { TeamModel } from "../../generated/from-api/models/team.model";
import { userRoles } from "../../shared/shared-with-mobile/constants";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";

import AccountSetupModal, {
  OnboardingProgress,
} from "./account-components/AccountSetupModal/AccountSetupModal";
import TeamTab from "./account-components/TeamsTab";
import SubscriptionsTab from "./account-components/SubscriptionsTab";
import AccountSettingsTab from "./account-components/AccountSettingsTab";
import NotificationsTab from "./account-components/NotificationsTab";
import { InvitationModel } from "../../generated/from-api/models/invitation.model";
import PendingInvitationModal from "./account-components/PendingInvitationModal/PendingInvitationModal";
import OnboardingModal, {
  OnboardingStep,
} from "../../components/OnboardingModal/OnboardingModal";
import { UserProfileModel } from "../../generated/from-api/models/user-profile.model";
import WelcomeCarousel from "../../components/WelcomeCarousel/WelcomeCarousel";

const Account: React.FC = () => {
  const tab = useParams<{ tab: string }>().tab || "account-settings";
  const { currentLicenses } = useContext(LicensesContext);
  const { teams, addTeams, teamsAreLoaded } = useContext(TeamContext);
  const { dispatchModal, dispatchSingleModal, closeModal } = useContext(
    UIContext
  );
  const { userProfile, updateProfile } = useContext(UserContext);
  const [teamsInvitedTo, setTeamsInvitedTo] = useState<TeamModel[]>([]);
  const userIsAdmin = userProfile?.role === userRoles.ADMIN;

  const prevPendingInvitationsRef = useRef<InvitationModel[]>([]);
  const [pendingInvitations, setPendingInvitations] = useState<
    InvitationModel[]
  >([]);
  const [invitationsAreLoaded, setInvitationsAreLoaded] = useState<boolean>(
    false
  );
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState<boolean>(
    false
  );

  useEffect(() => {
    // request pending invitations
    let isCancelled = false;
    const fetchInvitations = async () => {
      try {
        const invitations = await APIService.INVITATION.LIST();

        if (!isCancelled && invitations) {
          prevPendingInvitationsRef.current = invitations;
          setPendingInvitations(invitations);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setInvitationsAreLoaded(true);
      }
    };

    fetchInvitations();

    return () => {
      isCancelled = true;
      setInvitationsAreLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (
      !isEmpty(pendingInvitations) &&
      !isEmpty(teamsInvitedTo) &&
      invitationsAreLoaded
    ) {
      const invitation = pendingInvitations[0];
      openInvitationModal(invitation);
    }
  }, [pendingInvitations, teamsInvitedTo]);

  useEffect(() => {
    if (!userProfile) {
      return;
    }

    let isCancelled = false;
    const loadCurrentTeams = async () => {
      if (
        prevPendingInvitationsRef.current.length !== pendingInvitations.length
      ) {
        const visibleTeams = await APIService.TEAM.GET_ALL_TEAMS_VISIBLE_TO_USER(
          userProfile.id
        );

        if (!isCancelled) {
          // Since the web app is only for coaches, we don't need to worry about teams.playsFor
          addTeams([...visibleTeams.coachesFor, ...visibleTeams.created]);
        }
      }
    };

    const loadTeamsFromInvite = async () => {
      if (pendingInvitations) {
        const teamsFromInvites: TeamModel[] = [];
        await Promise.all(
          pendingInvitations.map(async (invitation) => {
            const teamFromInvite = await APIService.TEAM.GET(invitation.teamId);
            teamsFromInvites.push(teamFromInvite);
          })
        );

        if (!isCancelled) {
          setTeamsInvitedTo(teamsFromInvites);
        }
      }
    };

    loadCurrentTeams();
    loadTeamsFromInvite();

    if (
      userProfile?.tooltipsInfo?.webOnboardingStep ===
      OnboardingStep.MobileAppLinks
    ) {
      updateOnboardingFlow(OnboardingStep.Completed);
    }
    return () => {
      isCancelled = true;
    };
  }, [pendingInvitations, userProfile]);

  const handleAcceptInvite = async (inviteId: string) => {
    const acceptedInvite = await APIService.INVITATION.ACCEPT(inviteId);
    if (acceptedInvite) {
      let pendingInvitationsCopy = [...pendingInvitations];
      pendingInvitationsCopy = pendingInvitationsCopy.filter(
        (invite) => invite.id !== acceptedInvite.data.id
      );
      setPendingInvitations(pendingInvitationsCopy);
      setInvitationsAreLoaded(false);
    }
  };

  const handleDeclineInvite = async (inviteId: string) => {
    const declinedInvite = await APIService.INVITATION.DECLINE(inviteId);
    if (declinedInvite) {
      let pendingInvitationsCopy = [...pendingInvitations];
      pendingInvitationsCopy = pendingInvitationsCopy.filter(
        (invite) => invite.id !== inviteId
      );
      setPendingInvitations(pendingInvitationsCopy);
      setInvitationsAreLoaded(false);
    }
  };

  const openInvitationModal = (invitation: InvitationModel) => {
    const teamInvitedTo = teamsInvitedTo.find(
      (team) => team.id === invitation.teamId
    );
    if (teamInvitedTo) {
      dispatchModal({
        open: true,
        className: styles.accountSetupModal,
        body: (
          <PendingInvitationModal
            invitation={invitation}
            teamInvitedTo={teamInvitedTo}
            handleAcceptInvite={handleAcceptInvite}
          />
        ),
      });
    }
  };

  const openAccountSetup = (initialProgress?: OnboardingProgress) => {
    dispatchModal({
      open: true,
      className: styles.accountSetupModal,
      body: <AccountSetupModal initialProgress={initialProgress} />,
    });
  };

  const closeOnboardingModal = () => {
    setIsOnboardingModalOpen(false);
    closeModal();
    setTimeout(() => {
      openWelcomeCarousel();
    }, 1000);
  };

  const openOnboarding = () => {
    dispatchModal({
      open: true,
      size: "responsive",
      disableBackdropClick: true,
      body: (
        <OnboardingModal
          startingStep={userProfile?.tooltipsInfo?.webOnboardingStep}
          closeOnboardingModal={closeOnboardingModal}
        />
      ),
    });
  };

  const openWelcomeCarousel = () => {
    // do not open on mobile, as the tutorial is intended for desktop users only
    const userIsOnIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const userIsOnAndroid = /android/i.test(navigator.userAgent);
    const userIsOnMobileDevice = userIsOnIOS || userIsOnAndroid;
    if (userIsOnMobileDevice) {
      return;
    }

    dispatchSingleModal({
      open: true,
      disableBackdropClick: true,
      body: <WelcomeCarousel />,
    });
  };

  const updateOnboardingFlow = async (step: OnboardingStep) => {
    if (!userProfile) {
      return;
    }

    const updatedUserProfile: UserProfileModel = {
      ...userProfile,
      tooltipsInfo: {
        ...userProfile.tooltipsInfo,
        webOnboardingStep: step,
      },
    } as UserProfileModel;

    const updated = await APIService.USER_PROFILE.PUT(
      userProfile.id,
      updatedUserProfile
    );

    if (updated) {
      updateProfile(updated);
    }
    return updated;
  };

  useEffect(() => {
    if (
      userProfile &&
      !userIsAdmin &&
      currentLicenses &&
      currentLicenses.length <= 1 &&
      teamsAreLoaded &&
      teams?.size === 0 &&
      pendingInvitations &&
      isEmpty(pendingInvitations) &&
      invitationsAreLoaded &&
      !isOnboardingModalOpen
    ) {
      if (
        teams?.size === 0 &&
        userProfile.tooltipsInfo?.webOnboardingStep !==
          OnboardingStep.TeamCreation
      ) {
        // If all the Teams are deleted then reopen the onboarding modal from step 1.
        (async () => {
          const updated = await updateOnboardingFlow(
            OnboardingStep.TeamCreation
          );
          if (updated) {
            setIsOnboardingModalOpen(true);
            openOnboarding();
          }
        })();
      } else {
        setIsOnboardingModalOpen(true);
        openOnboarding();
      }
    } else {
      // Reopen onboarding modal if final step is not completed.
      if (
        userProfile &&
        !userIsAdmin &&
        currentLicenses &&
        currentLicenses.length <= 1 &&
        teamsAreLoaded &&
        teams?.size !== 0 &&
        !isOnboardingModalOpen &&
        !!userProfile?.tooltipsInfo?.webOnboardingStep &&
        userProfile?.tooltipsInfo?.webOnboardingStep !==
          OnboardingStep.TeamCreation &&
        userProfile?.tooltipsInfo?.webOnboardingStep !==
          OnboardingStep.Completed
      ) {
        setIsOnboardingModalOpen(true);
        openOnboarding();
      }
    }
  }, [currentLicenses, invitationsAreLoaded, teamsAreLoaded, userProfile]);

  useEffect(() => {
    if (userProfile && userProfile.tooltipsInfo) {
      const toolTipInfo = userProfile.tooltipsInfo;

      if (
        !isOnboardingModalOpen &&
        toolTipInfo.webOnboardingStep === 0 &&
        toolTipInfo.welcomeCarouselStep === 1
      ) {
        openWelcomeCarousel();
      }
    }
  }, [userProfile]);

  return (
    <div className={styles.accountPage}>
      <ul className={styles.accountPageSidebar}>
        <li className={styles.accountPageSidebarLink}>
          <Link
            to="/account/account-settings"
            className={tab == "account-settings" ? "active" : ""}
          >
            Account Settings
          </Link>
        </li>
        {!userIsAdmin && (
          <>
            <li className={styles.accountPageSidebarLink}>
              <Link
                to="/account/teams"
                className={tab == "teams" ? "active" : ""}
              >
                Teams
              </Link>
            </li>
            <li className={styles.accountPageSidebarLink}>
              <Link
                to="/account/subscriptions"
                className={tab == "subscriptions" ? "active" : ""}
              >
                Subscriptions
              </Link>
            </li>
            {/* <li className={styles.accountPageSidebarLink}>
              <Link
                to="/account/notifications"
                className={tab == "notifications" ? "active" : ""}
              >
                Notifications
              </Link>
            </li> */}
          </>
        )}
      </ul>

      <div className={styles.accountPageContent}>
        {(() => {
          switch (tab) {
            case "teams":
              return (
                <TeamTab
                  openAccountSetup={openAccountSetup}
                  pendingInvitations={pendingInvitations}
                  teamsInvitedTo={teamsInvitedTo}
                  handleAcceptInvite={handleAcceptInvite}
                  handleDeclineInvite={handleDeclineInvite}
                />
              );
            case "subscriptions":
              return <SubscriptionsTab openAccountSetup={openAccountSetup} />;
            case "account-settings":
              return <AccountSettingsTab />;
            case "notifications":
              return <NotificationsTab />;
            default:
              return (
                <div>
                  How did you end up here?{" "}
                  <Link to="/account/">Return to Account Settings.</Link>
                </div>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default Account;
