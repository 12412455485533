export enum TagTypeEnum {
  SCHEME = "SCHEME",
  PERSONNEL_PACKAGE = "PERSONNEL PACKAGE",
  SITUATION = "SITUATION",
  BLITZ = "BLITZ",
  COVERAGE = "COVERAGE",
  CUSTOM_QUIZ_CATEGORY = "CUSTOM_QUIZ_CATEGORY",
  INSTALL_CATEGORY = "INSTALL_CATEGORY",
  MEDIA_TAG = "MEDIA_TAG",
}
