import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { CustomQuizModel } from "../../../../generated/from-api/models/custom-quiz.model";
import { API_BASE_URL } from "../../constants";

export const CustomQuizService = {
  /**
   * Use to retrieve a list of CustomQuizes for a team or from the global library if no team id is specified
   *
   * Coaches/Players must belong to the team in order to view custom quizes from their team.
   * Players will only see published quizes.
   *
   * Drafted Global quizes are visible to all admins.
   * Published global quizes are visible to all coaches or players.
   */
  LIST: async function (
    teamId?: string,
    onlyIfModifiedSince?: string
  ): Promise<CustomQuizModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ}`, {
        params: {
          teamId: teamId,
          onlyIfModifiedSince,
        },
      })
    ).data;
  },
  /**
   * Use to retrieve a specific CustomQuiz
   */
  GET: async function (customquizId: string): Promise<CustomQuizModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ}/${customquizId}`)
    ).data;
  },
  /**
   * Use to create one or many CustomQuiz(es). If the CustomQuiz was not created, returns undefined.
   * @param
   * @returns CustomQuizModel[] | undefined
   */
  POST: async function (
    customquizData: Pick<
      CustomQuizModel,
      | "teamId" // "teamId" is present when the user is creating a CustomQuiz for a specific team
      | "name"
      | "description"
      | "showQuizToPositions"
      | "questions"
      | "published"
      | "categoryTagId"
      | "autoGenerateQuestions"
      | "autoInclude"
    >[]
  ): Promise<CustomQuizModel[] | undefined> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ}`,
        customquizData
      )
    ).data;
  },
  /**
   * Use to update a CustomQuiz
   * @param
   */
  PUT: async function (
    customquizData: Pick<
      CustomQuizModel,
      | "id"
      | "name"
      | "description"
      | "showQuizToPositions"
      | "questions"
      | "published"
      | "categoryTagId"
      | "autoGenerateQuestions"
      | "autoInclude"
    >
  ): Promise<CustomQuizModel | undefined> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ}`, customquizData)
    ).data;
  },
  /**
   * Use to delete a CustomQuiz
   * @param customQuizId
   * @returns
   */
  DELETE: async function (customQuizId: string): Promise<{ deleted: boolean }> {
    return (
      await axios.delete(
        `${API_BASE_URL}${APIRoutes.CUSTOM_QUIZ}/${customQuizId}`
      )
    ).data;
  },
};
