import classNames from "classnames";
import styles from "./RunningProgressBar.module.scss";
import ProgressStage1 from "../../resources/images/ProgressStage1.svg";
import ProgressStage2 from "../../resources/images/ProgressStage2.svg";
import ProgressStage3 from "../../resources/images/ProgressStage3.svg";
import ProgressStage4 from "../../resources/images/ProgressStage4.svg";
import ProgressStage5 from "../../resources/images/ProgressStage5.svg";
import ProgressStage6 from "../../resources/images/ProgressStage6.svg";
import ProgressStage6b from "../../resources/images/ProgressStage6b.svg";
import ProgressStage7 from "../../resources/images/ProgressStage7.svg";

import { OnboardingStep } from "../OnboardingModal/OnboardingModal";

interface Props {
  step: OnboardingStep;
  className?: string;
}

const RunningProgressBar: React.FC<Props> = ({ step, className }) => {
  const getImage = () => {
    switch (step) {
      case OnboardingStep.TeamCreation:
        return ProgressStage1;
      case OnboardingStep.PdfImportChoice:
        return ProgressStage2;
      case OnboardingStep.PdfImportStart:
        return ProgressStage3;
      case OnboardingStep.PdfImportChooseFile:
        return ProgressStage4;
      case OnboardingStep.PdfImportFileFormat:
        return ProgressStage5;
      case OnboardingStep.PdfImportProcessing:
        return ProgressStage6;
      case OnboardingStep.PdfImportProcessed:
        return ProgressStage6b;
      case OnboardingStep.PdfImportSkipped:
        return ProgressStage6;
      case OnboardingStep.MobileAppLinks:
        return ProgressStage7;
      default:
        return ProgressStage1;
    }
  };

  return (
    <div className={classNames(styles.container, !!className && className)}>
      <img src={getImage()} alt="progress stage image" />
    </div>
  );
};

export default RunningProgressBar;
