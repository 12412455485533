import stylesDashboardGeneral from "../../../Dashboard.module.scss";
import dashboardStatsStyles from "../../../../../components/DashboardStatsGrid/DashboardStatsGrid.module.scss";
const styles = {
  ...stylesDashboardGeneral,
  ...dashboardStatsStyles,
};
import CharacterInput from "../../../../../components/Input/CharacterInput";
import searchIcon from "../../../../../resources/images/search.svg";
import keyboard_arrow_right from "../../../../../resources/images/keyboard_arrow_right.svg";
import { INPUT_TYPES } from "../../../../../utils/web-only-constants";
import ToggleSelect from "../../../../../shared/shared-with-mobile/components/ToggleSelect/ToggleSelect";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReadinessScoresContext } from "../../../../../shared/shared-with-mobile/providers/readinessScores.provider";
import { PlayersContext } from "../../../../../shared/shared-with-mobile/providers/players.provider";
import { DashboardStatsGrid } from "../../../../../components/DashboardStatsGrid/DashboardStatsGrid";

import Avatar from "../../../../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import { isEmpty } from "lodash";
import ReadinessGraph from "../../../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/ReadinessGraph";
import {
  doPositionsOverlap,
  formatReadinessByUnitChartData,
  hasPositionType,
  PlayerRow,
  PositionFiltersMap,
} from "../../../../../shared/shared-with-mobile/functions";
import { getAbbreviatedDisplayName } from "../../../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import { BarDatum } from "@nivo/bar";
import { CustomQuizModel } from "../../../../../generated/from-api/models/custom-quiz.model";
import { QuizzesContext } from "../../../../../shared/shared-with-mobile/providers/quizzes.provider";
import QuizPreview from "../../../../Quizzes/quiz-components/QuizPreview/QuizPreview";
import PhonePreview from "../../../../../components/PhonePreview/PhonePreview";
import { PlayerModel } from "../../../../../generated/from-api/models/relationships/player.model";
import PlayerModal from "../../../Components/PlayerModal/PlayerModal";

interface Props {
  quizId: string;
  close: () => void;
}

const QuizQuizzes: React.FC<Props> = ({ quizId, close }) => {
  const chartDefaultDataForUnit: BarDatum[] = [
    { category: "Offense", averageScore: 0 },
    { category: "Defense", averageScore: 0 },
    { category: "S.T.", averageScore: 0 },
  ];
  const [chartDataForUnit, setChartDataForUnit] = useState<BarDatum[]>(
    chartDefaultDataForUnit
  );

  const { playerQuizMetrics } = useContext(ReadinessScoresContext);
  const { currentPlayersAsArray } = useContext(PlayersContext);
  const { quizzes, globalQuizzes } = useContext(QuizzesContext);
  const [selectedFilter, setSelectedFilter] = useState(PositionFiltersMap[0]);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [player, setPlayer] = useState<PlayerModel>();

  const [searchText, setSearchText] = useState("");
  const [quiz, setQuiz] = useState<CustomQuizModel | null>(null);

  const [dataForPlayerStatsGrid, setDataForPlayerStatsGrid] = useState<
    PlayerRow[]
  >();

  useEffect(() => {
    searchGridResult(searchText);
  }, [selectedFilter]);

  useEffect(() => {
    const matchQuiz =
      quizzes.find((quiz) => quiz.id === quizId) ||
      globalQuizzes.find((quiz) => quiz.id === quizId);

    if (matchQuiz) {
      setQuiz(matchQuiz);
    } else {
      setQuiz(null);
    }
  }, [quizzes, globalQuizzes, quizId]);

  useEffect(() => {
    if (
      currentPlayersAsArray &&
      !isEmpty(currentPlayersAsArray) &&
      playerQuizMetrics &&
      !isEmpty(playerQuizMetrics)
    ) {
      const playerRows = playerQuizMetrics
        .filter(
          (p) =>
            p.quizId === quizId &&
            doPositionsOverlap(p.quizPositions, p.playerPositions)
        )
        .map((p) => {
          return {
            playerFirstName: p.playerFirstName,
            playerLastName: p.playerLastName,
            playerId: p.playerId ? p.playerId : "",
            playerUserId: p.playerUserId,
            positions: p.playerPositions ? p.playerPositions : "",
            readiness: Number(p.readinessScore),
          };
        });

      setChartDataForUnit(formatReadinessByUnitChartData(playerRows));

      setDataForPlayerStatsGrid(playerRows);
    }
  }, [currentPlayersAsArray, playerQuizMetrics]);

  const searchGridResult = (event: any) => {
    setSearchText(event);
  };

  const onRowClick = (id: string) => {
    setPlayer(
      currentPlayersAsArray.find((player: PlayerModel) => player.id === id)
    );
    setShowPlayerModal(true);
  };

  const columnsForPlayers = useMemo(
    () => [
      {
        name: "PLAYER",
        selector: (row: PlayerRow) => row.playerId,
        cell: (row: PlayerRow) => {
          return (
            <div
              className={`${styles.clickableCell} ${styles.flexRow}`}
              onClick={() => onRowClick(row.playerId)}
            >
              <div className={styles.usreListImg}>
                <Avatar userId={row.playerId} small type="player" />
              </div>

              <span className={styles.playerName}>
                {getAbbreviatedDisplayName(
                  row.playerFirstName,
                  row.playerLastName
                )}
              </span>
              {row.positions && (
                <div className={styles.playerPosition}>{row.positions}</div>
              )}
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "FLASHCARD READINESS",
        selector: (row: PlayerRow) => +row.readiness,
        format: (row: PlayerRow) => `${row.readiness.toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [currentPlayersAsArray]
  );

  return (
    <div>
      <p className={styles.breadcrumb}>
        <span className={styles.drillInTitle} onClick={() => close()}>
          Flashcard Readiness
        </span>
        <img
          src={keyboard_arrow_right}
          alt="keyboard_arrow_right"
          className={styles.drillInArrowRight}
        />
        {quiz?.name}
      </p>

      <div className={styles.chartCardRow}>
        <div className={styles.media}>
          {quiz !== null && (
            <PhonePreview containerClass={styles.media} heightRatio={0.85}>
              <QuizPreview quiz={quiz} />
            </PhonePreview>
          )}
        </div>
        <div className={styles.graphs}>
          <ReadinessGraph
            title="Average Flashcard Readiness"
            type="Line"
            xLabel="Date"
            yLabel="Readiness"
            ySymbol="%"
            data={chartDataForUnit}
            indexBy="category"
            valueKey="averageScore"
          />

          <ReadinessGraph
            title="Flashcard Readiness by Unit"
            type="Bar"
            xLabel="Unit"
            yLabel="Readiness"
            ySymbol="%"
            data={chartDataForUnit}
            indexBy="category"
            valueKey="averageScore"
          />
        </div>
      </div>

      <div className={styles.searchAndFilterBtnForDrillIn}>
        <div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Search"
            id="search"
            size="x-small"
            icon={searchIcon}
            clearButton
            value={searchText}
            onChange={(e) => searchGridResult(e.currentTarget.value)}
          />
        </div>

        <div className={styles.filterBtns}>
          <ToggleSelect
            options={PositionFiltersMap.map((f) => f.label)}
            selectedOption={selectedFilter.label}
            background="#151724"
            borderColor="#151724"
            onChange={(label: string) => {
              const newFilter = PositionFiltersMap.find(
                (f) => f.label == label
              );
              if (newFilter) {
                setSelectedFilter(newFilter);
              }
            }}
          />
        </div>
      </div>
      <br />

      <div>
        <>
          <DashboardStatsGrid
            data={dataForPlayerStatsGrid?.filter((m) => {
              return (
                hasPositionType(
                  m.positions ? m.positions.split("|") : [],
                  selectedFilter.value
                ) &&
                (
                  "" +
                  m.playerFirstName +
                  m.playerLastName +
                  m.readiness.toFixed(2) +
                  getAbbreviatedDisplayName(m.playerFirstName, m.playerLastName)
                )
                  .toLowerCase()
                  .indexOf(searchText ? searchText.toLowerCase() : "", 0) !== -1
              );
            })}
            columns={columnsForPlayers}
          />
          {player && (
            <PlayerModal
              player={player}
              visible={showPlayerModal}
              defaultTab={"flashcards"}
              close={() => setShowPlayerModal(false)}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default QuizQuizzes;
