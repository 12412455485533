import React, { useContext } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import styles from "./InstallBuilder.module.scss";
import CharacterInput from "../../../../components/Input/CharacterInput";
import SingleSelect from "../../../../components/SingleSelect/SingleSelect";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import plusIcon from "../../../../resources/images/grayPlus.svg";
import InstallSlide from "../installSlide/InstallSlide";
import DatePickerInput from "../../../../components/DatePickerInput/DatePickerInput";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { InstallModel } from "../../../../generated/from-api/models/install.model";
import { InstallSlideModel } from "../../../../generated/from-api/models/install/install-slide.model";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";
import { MediaModel } from "../../../../generated/from-api/models/media.model";
import {
  EditorTextAlignment,
  SlideElementModel,
} from "../../../../generated/from-api/models/install/slide-element.model";
import { RawDraftContentState } from "draft-js";
import { PlayModel } from "../../../../generated/from-api/models/play.model";
import { useHistory } from "react-router-dom";
import ChevronLeftIcon from "../../../../resources/icons/ChevronLeftIcon";
import DeleteModal from "../DeleteModal";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import isNumber from "lodash/isNumber";
import QuizzesQuestionsGallery from "../../../../components/QuizzesQuestionsGallery/QuizzesQuestionsGallery";
import { CustomQuizQuestionModel } from "../../../../generated/from-api/models/custom-quiz/custom-quiz-question.model";
import PositionSelector from "../../../../components/PositionSelector/PositionSelector";
import { TagsContext } from "../../../../shared/shared-with-mobile/providers/tags.provider";
import { TagModel } from "../../../../generated/from-api/models/tag.model";
import {
  INPUT_TYPES,
  INSTALL_SETTINGS,
} from "../../../../utils/web-only-constants";
import QuizCategoriesModal from "../../../Quizzes/quiz-components/QuizCategoriesModal/QuizCategoriesModal";
import PlusIcon from "../../../../resources/icons/PlusIcon";
import Button from "../../../../components/Button/Button";
import Switch from "../../../../components/Switch/Switch";

interface Props {
  install: InstallModel;
  updateInstall: (updatedInstall: InstallModel) => void;
  currentRoute: string;
  userIsAdmin?: boolean;
  onSlidefocus?: (slideIndex: number) => void;
}

const InstallBuilder: React.FC<Props> = ({
  install,
  updateInstall,
  currentRoute,
  onSlidefocus,
  userIsAdmin = false,
}) => {
  const { LABELS } = INSTALL_SETTINGS;
  const history = useHistory();
  const { dispatchModal, closeModals, dispatchToast } = useContext(UIContext);

  const { customQuizCategoryTags } = useContext(TagsContext);
  const installCategory: TagModel | undefined = customQuizCategoryTags
    ? customQuizCategoryTags.filter(
        (tag) => tag.id === install.categoryTagId
      )[0]
    : undefined;

  const updateInstallName = (newNameValue: string) => {
    const newInstall = cloneDeep(install);

    newInstall.name = newNameValue;
    updateInstall(newInstall);
  };

  const updateInstallPublishedState = (value: string) => {
    const newInstall = cloneDeep(install);

    newInstall.published = value === "published";
    updateInstall(newInstall);
  };

  const updateInstallDueDate = (event: MaterialUiPickersDate) => {
    const newInstall = cloneDeep(install);

    newInstall.dueDate = event?.toString();
    updateInstall(newInstall);
  };

  const updateInstallCategory = (value: string) => {
    const newInstall = cloneDeep(install);

    newInstall.categoryTagId = value;
    updateInstall(newInstall);
  };

  const addInstallSlide = () => {
    const newInstall = cloneDeep(install);
    const newSlide: InstallSlideModel = {
      id: uuidv4(),
      slideElements: [],
    };

    newInstall.slides.push(newSlide);
    updateInstall(newInstall);
  };

  const addQuizToInstall = (quizId: string, slideIndex?: number) => {
    const newInstall = cloneDeep(install);
    const newSlide: InstallSlideModel = {
      id: uuidv4(),
      slideElements: [],
      customQuizId: quizId,
    };

    if (isNumber(slideIndex)) {
      newInstall.slides[slideIndex] = newSlide;
    } else {
      newInstall.slides.push(newSlide);
    }

    updateInstall(newInstall);
  };

  const addCustomQuestionToInstall = (
    customQuestion: CustomQuizQuestionModel,
    slideIndex?: number,
    isImagesAsAnswers?: boolean
  ) => {
    const newInstall = cloneDeep(install);
    const newCustomQuestion = cloneDeep(customQuestion);

    if (isImagesAsAnswers) {
      newCustomQuestion.correctOptions = customQuestion.correctOptions.filter(
        (item, index) => item.optionType === "IMAGE" || index === 0
      );

      newCustomQuestion.incorrectOptions = customQuestion.incorrectOptions.filter(
        (item, index) => item.optionType === "IMAGE" || index === 0
      );
    } else {
      newCustomQuestion.correctOptions = customQuestion.correctOptions.filter(
        (item) =>
          item.optionType === "TEXT" && item.text && item.text.trim().length > 0
      );

      newCustomQuestion.incorrectOptions = customQuestion.incorrectOptions.filter(
        (item) =>
          item.optionType === "TEXT" && item.text && item.text.trim().length > 0
      );
    }

    if (!newCustomQuestion.correctOptions.length) {
      dispatchToast({
        type: "error",
        message: "At least one correct answer is required.",
      });
      return false;
    } else if (!newCustomQuestion.incorrectOptions.length) {
      dispatchToast({
        type: "error",
        message: "At least one incorrect answer is required.",
      });
      return false;
    }

    const newSlide: InstallSlideModel = {
      id: uuidv4(),
      slideElements: [],
      question: newCustomQuestion,
    };

    if (isNumber(slideIndex)) {
      newInstall.slides[slideIndex] = newSlide;
    } else {
      newInstall.slides.push(newSlide);
    }

    updateInstall(newInstall);
    return true;
  };

  const openQuizzesQuestionsGallery = (
    slideIndex?: number,
    activeTab?: "flashcard" | "question",
    currentQuestionObject?: CustomQuizQuestionModel
  ) => {
    const isCurrentQuestionImagesAsAnswers =
      currentQuestionObject &&
      (currentQuestionObject.correctOptions.some(
        (item) =>
          item.optionType === "IMAGE" ||
          (item.optionType === "TEXT" && !item.text)
      ) ||
        currentQuestionObject.incorrectOptions.some(
          (item) =>
            item.optionType === "IMAGE" ||
            (item.optionType === "TEXT" && !item.text)
        ));

    dispatchModal({
      className: styles.InstallBuilderQuizzesQuestionsModal,
      open: true,
      size: "large",
      body: (
        <QuizzesQuestionsGallery
          activeTab={activeTab ? activeTab : "flashcard"}
          isCurrentQuestionImagesAsAnswers={isCurrentQuestionImagesAsAnswers}
          currentQuestionObject={currentQuestionObject && currentQuestionObject}
          addQuizCallback={(quizId) => {
            addQuizToInstall(quizId, slideIndex);
            closeModals();
          }}
          addCustomQuestionCallback={(
            customQuestion,
            isImagesAsAnswers: boolean
          ) => {
            const wasQuestionAdded = addCustomQuestionToInstall(
              customQuestion,
              slideIndex,
              isImagesAsAnswers
            );
            if (wasQuestionAdded) {
              closeModals();
            }
          }}
        />
      ),
    });
  };

  const updateInstallSlideText = (
    data: RawDraftContentState | null,
    textAlignment: EditorTextAlignment,
    slideIndex: number,
    slideSectionIndex: number
  ) => {
    const newInstall = cloneDeep(install);
    const newInstallSlideElements = newInstall.slides[slideIndex].slideElements;
    if (!newInstallSlideElements) return;

    if (data && textAlignment) {
      newInstallSlideElements[slideSectionIndex].textValue = {
        data: data,
        textAlignment: textAlignment,
      };
    } else {
      newInstallSlideElements[slideSectionIndex] = {
        id: uuidv4(),
        type: "TEXT",
        textValue: { data: null, textAlignment: "left" },
      };
    }

    updateInstall(newInstall);
  };

  const updateInstallSlideNarration = (
    media: MediaModel,
    slideIndex: number
  ) => {
    const newInstall = cloneDeep(install);
    newInstall.slides[slideIndex].narrationMediaId = media.id;
    updateInstall(newInstall);
  };

  const updateInstallSlideMedia = (
    media: MediaModel,
    slideIndex: number,
    slidesSectionIndex?: number
  ) => {
    const newInstall = cloneDeep(install);
    const newSlideMedia: SlideElementModel = {
      id: uuidv4(),
      type: "MEDIA",
      mediaId: media.id,
    };

    if (isNumber(slidesSectionIndex)) {
      newInstall.slides[slideIndex].slideElements?.splice(
        slidesSectionIndex,
        1,
        newSlideMedia
      );
    } else {
      newInstall.slides[slideIndex].slideElements?.push(newSlideMedia);
    }

    updateInstall(newInstall);
  };

  const updateInstallSlidePlay = (
    play: PlayModel,
    slideIndex: number,
    slidesSectionIndex?: number
  ) => {
    const newInstall = cloneDeep(install);
    const newPlay: SlideElementModel = {
      id: uuidv4(),
      type: "PLAY",
      playId: play.id,
    };

    if (isNumber(slidesSectionIndex)) {
      newInstall.slides[slideIndex].slideElements?.splice(
        slidesSectionIndex,
        1,
        newPlay
      );
    } else {
      newInstall.slides[slideIndex].slideElements?.push(newPlay);
    }

    updateInstall(newInstall);
  };

  const deleteInstallSlide = (slideIndex: number) => {
    const newInstall = cloneDeep(install);
    newInstall.slides.splice(slideIndex, 1);

    updateInstall(newInstall);
  };

  const deleteInstallNarration = (slideIndex: number) => {
    const newInstall = cloneDeep(install);
    newInstall.slides[slideIndex].narrationMediaId = undefined;

    updateInstall(newInstall);
  };

  const deleteInstallSlideSection = (
    slideIndex: number,
    slideSectionIndex: number
  ) => {
    const newInstall = cloneDeep(install);
    newInstall.slides[slideIndex].slideElements?.splice(slideSectionIndex, 1);

    updateInstall(newInstall);
  };

  const deleteInstall = () => {
    dispatchModal({
      title: `Delete ${LABELS.singular_capitalized}`,
      open: true,
      size: "medium",
      className: styles.installDeleteModal,
      body: (
        <DeleteModal
          install={install}
          onDeleteCallback={() => history.push(`/${currentRoute}`)}
          onArchiveCallback={() => history.push(`/${currentRoute}`)}
        />
      ),
    });
  };

  const updateInstallArchiveState = (archiveState: boolean) => {
    const newInstall = cloneDeep(install);

    newInstall.archived = archiveState;
    updateInstall(newInstall);
  };

  const archiveInstall = () => {
    updateInstallArchiveState(true);
    history.push(`/${currentRoute}`);
  };

  const moveSlideSection = (
    direction: "up" | "down",
    slideIndex: number,
    slideSectionIndex: number
  ) => {
    const newInstall = cloneDeep(install);
    const newInstallSlideElements = newInstall.slides[slideIndex].slideElements;
    if (!newInstallSlideElements) return;

    const nextSectionIndex = direction === "up" ? 1 : -1;

    [
      newInstallSlideElements[slideSectionIndex],
      newInstallSlideElements[slideSectionIndex - nextSectionIndex],
    ] = [
      newInstallSlideElements[slideSectionIndex - nextSectionIndex],
      newInstallSlideElements[slideSectionIndex],
    ];

    updateInstall(newInstall);
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "#484A59" : "#303241",
    borderRadius: "6px",
  });

  const handleSlideDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }

    const startIndex = dropResult.source.index;
    const endIndex = dropResult.destination.index;
    if (startIndex === endIndex) {
      return;
    }

    const newInstall = cloneDeep(install);
    const movedSlide = newInstall.slides.slice(startIndex, startIndex + 1)[0];

    newInstall.slides.splice(startIndex, 1, { id: "REMOVE_ME" });
    newInstall.slides.splice(endIndex, 0, movedSlide);

    newInstall.slides = newInstall.slides.filter((hunter) => {
      return hunter.id != "REMOVE_ME";
    });

    updateInstall(newInstall);
  };

  const updatePositions = (positions: string) => {
    const newInstall = cloneDeep(install);
    newInstall.showInstallToPositions = positions;
    updateInstall(newInstall);
  };

  const updateIncludeToTeamsValue = (value: boolean) => {
    const newInstall = cloneDeep(install);
    newInstall.autoInclude = value;
    updateInstall(newInstall);
  };

  return (
    <div className={styles.InstallBuilder}>
      <div className={styles.InstallBuilderTopSection}>
        <div className={styles.InstallBuilderRow}>
          <div className={styles.InstallBuilderTitleWrapper}>
            {currentRoute && (
              <Button
                className={styles.InstallBuilderBackLink}
                type={"link"}
                theme={"transparent"}
                size="small"
                icon={<ChevronLeftIcon />}
                linkOptions={{ to: `/${currentRoute}` }}
              />
            )}
            <div className={styles.InstallBuilderTitle}>
              {LABELS.singular_capitalized} Builder
            </div>
          </div>
          <div className={styles.InstallBuilderManageButtonsWrapper}>
            <Button theme="tertiary" size="small" onClick={deleteInstall}>
              Delete
            </Button>
            <Button theme="tertiary" size="small" onClick={archiveInstall}>
              Archive
            </Button>
          </div>
        </div>
        <div
          className={`${styles.InstallBuilderRow} ${styles.InstallBuilderRowSpacingNormal}`}
        >
          <div className={styles.InstallBuilderFullWidthWrapper}>
            <CharacterInput
              type={INPUT_TYPES.TEXT}
              placeholder={"Name"}
              value={install.name}
              onChange={(event) => updateInstallName(event.currentTarget.value)}
              autofocus
              maxLength={255}
            />
          </div>
          <div className={styles.InstallBuilderPublishToggleRow}>
            <Switch
              theme="yellow"
              toggled={install.published ? true : false}
              onToggle={() => {
                updateInstallPublishedState(
                  install.published ? "draft" : "published"
                );
              }}
            />
            {install.published ? "Published" : "Unpublished"}
          </div>
        </div>
        <div
          className={`${styles.InstallBuilderRow} ${styles.InstallBuilderRowSpacingNormal}`}
        >
          <div className={styles.InstallBuilderFullWidthWrapper}>
            <DatePickerInput
              label={"Due Date"}
              value={install.dueDate ?? ""}
              format="DD"
              onChange={updateInstallDueDate}
              theme="dark"
            />
          </div>
        </div>
        <div
          className={`${styles.InstallBuilderRow} ${styles.InstallBuilderRowSpacingNormal}`}
        >
          <div className={styles.InstallBuilderFullWidthWrapper}>
            <SingleSelect
              placeholder={"Category"}
              options={customQuizCategoryTags.map((tag) => ({
                label: tag.name ? tag.name : "",
                value: tag.id ? tag.id : "",
              }))}
              value={installCategory?.id}
              onChange={(event) =>
                updateInstallCategory(event.currentTarget.value)
              }
            />
          </div>
          <div className={styles.categoryEditButton}>
            <Button
              theme="tertiary"
              width="full"
              onClick={() => {
                dispatchModal({
                  body: <QuizCategoriesModal />,
                  size: "medium",
                  open: true,
                });
              }}
            >
              Edit Categories
            </Button>
          </div>
        </div>
        <div
          className={`${styles.InstallBuilderRow} ${styles.InstallBuilderRowSpacingLarge}`}
        >
          <PositionSelector
            checkboxLabel={`Show ${LABELS.singular} to all player positions`}
            showToPositions={install.showInstallToPositions}
            updatePositions={updatePositions}
          />
        </div>
        {userIsAdmin && (
          <div
            className={`${styles.InstallBuilderRow} ${styles.InstallBuilderRowSpacingLarge}`}
          >
            <Checkbox
              onChange={(event) =>
                updateIncludeToTeamsValue(event.currentTarget.checked)
              }
              checked={!!install.autoInclude}
            >
              Automatically include this {LABELS.singular} to all new teams
            </Checkbox>
          </div>
        )}
      </div>
      <div className={styles.InstallBuilderBottomSection}>
        <div className={styles.InstallBuilderSlidesHeader}>
          <div className={styles.InstallBuilderRow}>
            <div className={styles.InstallBuilderSlidesTitle}>Slides</div>
            <Button
              theme={"transparent"}
              size={"small"}
              icon={<PlusIcon />}
              onClick={addInstallSlide}
            />
          </div>
        </div>
        <div
          className={`${styles.InstallBuilderSlidesBody} ${styles.InstallBuilderRowSpacingLarge}`}
        >
          <DragDropContext onDragEnd={handleSlideDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {install.slides &&
                      install.slides.length > 0 &&
                      install.slides.map((slide, slideIndex) => {
                        return (
                          <Draggable
                            key={slide.id}
                            draggableId={slide.id as string}
                            index={slideIndex}
                          >
                            {(provided) => (
                              <div
                                className={styles.InstallBuilderSlideWrapper}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <InstallSlide
                                  slideIndex={slideIndex}
                                  slide={slide}
                                  updateTextHandler={updateInstallSlideText}
                                  updateMediaHandler={updateInstallSlideMedia}
                                  updatePlayHandler={updateInstallSlidePlay}
                                  updateQuizHandler={
                                    openQuizzesQuestionsGallery
                                  }
                                  updateNarrationHandler={
                                    updateInstallSlideNarration
                                  }
                                  deleteNarrationHandler={
                                    deleteInstallNarration
                                  }
                                  deleteSlideHandler={deleteInstallSlide}
                                  deleteSlideSectionHandler={
                                    deleteInstallSlideSection
                                  }
                                  moveSlideSectionHandler={moveSlideSection}
                                  provided={provided}
                                  onFocus={onSlidefocus}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
        <div
          className={`${styles.InstallBuilderSlidesFooter} ${styles.InstallBuilderRowSpacingLarge}`}
        >
          <div className={styles.InstallBuilderAddSlideButtonsWrapper}>
            <button
              type="button"
              className={styles.InstallBuilderAddSlideButton}
              onClick={addInstallSlide}
            >
              <img src={plusIcon} alt="plus icon" />
              <span>Add slide</span>
            </button>
            <button
              type="button"
              className={styles.InstallBuilderAddSlideButton}
              onClick={() => openQuizzesQuestionsGallery()}
            >
              <img src={plusIcon} alt="plus icon" />
              <span>Add quiz or question</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallBuilder;
