import * as React from "react";

const ListIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 0.5H1.25C0.833333 0.5 0.5 0.833333 0.5 1.25V14.75C0.5 15.0833 0.833333 15.5 1.25 15.5H14.75C15.0833 15.5 15.5 15.0833 15.5 14.75V1.25C15.5 0.833333 15.0833 0.5 14.75 0.5ZM5.5 3.83333H3.83333V5.5H5.5V3.83333ZM12.1667 3.83333H7.16667V5.5H12.1667V3.83333ZM12.1667 7.16667H7.16667V8.83333H12.1667V7.16667ZM7.16667 10.5H12.1667V12.1667H7.16667V10.5ZM3.83333 7.16667H5.5V8.83333H3.83333V7.16667ZM5.5 10.5H3.83333V12.1667H5.5V10.5ZM2.16667 13.8333H13.8333V2.16667H2.16667V13.8333Z"
    />
  </svg>
);

export default ListIcon;
