import React, { useContext, useEffect, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import styles from "./EditPlaySet.module.scss";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import PlayThumbnail from "../../../components/drawing/PlayThumbnail";
import _, { isEmpty, truncate } from "lodash";
import PlaybookTags from "../PlaybookTags";
import Button from "../../../components/Button/Button";
import CharacterInput from "../../../components/Input/CharacterInput";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import CloseIconSvg from "../../../resources/images/close.svg";
import PencilIcon from "../../../resources/icons/PencilIcon";
import CheckIcon from "../../../resources/icons/CheckIcon";
import CloseIcon from "../../../resources/icons/CloseIcon";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import {
  PlayOrder,
  PlaySetModel,
} from "../../../generated/from-api/models/play-set.model";
import { PlaybookContext } from "../../../shared/shared-with-mobile/providers/playbook.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { PlaySetsContext } from "../../../shared/shared-with-mobile/providers/playSets.provider";
import DeletePlayModal from "../../../components/drawing/EditPlayDetails/DeletePlayModal";
import DeletePlayFromPlaySetModal from "./DeletePlayFromPlaySetModal";
import PlayDetails from "../../../components/drawing/PlayDetails";
import { Link, useHistory } from "react-router-dom";
import { useHover } from "../../../shared/shared-with-mobile/custom-hook/useHover";
import GamifiedIcon from "../../../resources/icons/GamifiedIcon";
import { Tooltip } from "@material-ui/core";

window.addEventListener("error", (e) => {
  if (
    e.message ===
      "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation();
  }
});

interface Props {
  getNonGamifiedPlaysTooltipContent?: any;
  selectedPlaysetModal: PlaySetModel;
  clearSelectedPlays?: () => void;
  clearSelectedPlaySets?: () => void;
  openPlaySetEditModal?: (playSetId: string) => void;
}
const EditPlaySet: React.FC<Props> = ({
  getNonGamifiedPlaysTooltipContent,
  selectedPlaysetModal,
  clearSelectedPlays,
  clearSelectedPlaySets,
  openPlaySetEditModal,
}) => {
  const { closeModal, closeModals, dispatchModal, dispatchToast } = useContext(
    UIContext
  );
  const [playsetContent, setPlaysetContent] = useState<PlayModel[]>([]);

  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const [playsSelected, setPlaysSelected] = useState<string[]>([]);
  const [selectedPlayset, setSelectedPlayset] = useState<PlaySetModel>(
    selectedPlaysetModal
  );
  const [playSetName, setPlaySetName] = useState<string>(selectedPlayset.name);
  const {
    playSets,
    updatePlaySet,
    removePlaySet,
    setReopenPlaySetModal,
  } = useContext(PlaySetsContext);

  const {
    addPlays,
    currentPlaybook,
    setCurrentPlaybook,
    setGlobalPlaybook,
    gamifiedPlays,
  } = useContext(PlaybookContext);
  const history = useHistory();

  useEffect(() => {
    setSelectedPlayset(
      playSets.find(
        (playSet) => playSet.id === selectedPlayset.id
      ) as PlaySetModel
    );

    if (currentPlaybook) {
      const findPlayUsingPlayId: PlayModel[] = [];
      selectedPlayset.playOrder
        .map((playId) => playId.playId)
        .filter((e) => {
          const play = currentPlaybook.find((play) => play.id === e);
          if (play) {
            findPlayUsingPlayId.push(play);
          }
        });

      setPlaysetContent(findPlayUsingPlayId);
    }
  }, [playSets, selectedPlayset]);

  const onDragEnd = (sourceIndex: number, destinationIndex: number) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const items = [...playsetContent];
    [items[sourceIndex], items[destinationIndex]] = [
      items[destinationIndex],
      items[sourceIndex],
    ];
    setPlaysetContent(items);

    if (items) {
      updatePlaysToPlaySet(selectedPlayset, items);
    }
  };

  const playChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    play: PlayModel
  ) => {
    if (!play.id) {
      return;
    }

    let newPlaysSelected = playsSelected;

    if (e.currentTarget.checked) {
      newPlaysSelected = [...playsSelected, ...[play.id]];
    } else {
      newPlaysSelected = _.remove(
        playsSelected,
        (playModel: string) => play.id !== playModel
      );
    }
    setPlaysSelected(newPlaysSelected);
  };

  useEffect(() => {
    if (openPlaySetEditModal && selectedPlayset && selectedPlayset.id) {
      openPlaySetEditModal(selectedPlayset.id);
    }
  }, [playsSelected]);

  const handleDeselectAll = () => {
    setPlaysSelected([]);
  };

  const ListElement = (props: any) => {
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    const item = props.item;
    const chosenPlays = playsSelected;
    return (
      <div
        key={item.id}
        ref={hoverRef}
        className={`${styles.item} ${
          item.id && chosenPlays.includes(item.id) ? styles.selected : ""
        }`}
      >
        <div
          className={`${
            item.id && !chosenPlays.includes(item.id)
              ? styles.thumbnailNotSelected
              : styles.thumbnailSelected
          }
            ${isHovered ? styles.thumbnailHover : ""}
          `}
        >
          <div
            className={`${
              item.id && chosenPlays.includes(item.id)
                ? styles.dragIndicator
                : styles.hide
            } ${
              item.id && !chosenPlays.includes(item.id) && styles.hoverOutline
            } ${isHovered ? styles.hoverOutline : styles.hideDragIndicator}`}
          >
            <span>..</span>
            <span>..</span>
            <span>..</span>
          </div>
        </div>
        {gamifiedPlays.length > 0 &&
          !gamifiedPlays.some(
            (gamifiedPlay) => gamifiedPlay.id === item.id
          ) && (
            <div className={styles.gamifiedPlay}>
              <Tooltip
                title={
                  <div>
                    <p
                      style={{
                        margin: "5px",
                      }}
                    >
                      {getNonGamifiedPlaysTooltipContent &&
                        getNonGamifiedPlaysTooltipContent.content}
                    </p>
                    <span style={{ margin: "5px", float: "right" }}>
                      <Button
                        type={"external link"}
                        externalLinkOptions={{
                          href: getNonGamifiedPlaysTooltipContent
                            ? getNonGamifiedPlaysTooltipContent.link
                            : `#`,
                        }}
                        theme="primary"
                        size={"small"}
                      >
                        {getNonGamifiedPlaysTooltipContent &&
                          getNonGamifiedPlaysTooltipContent.btnTitle}
                      </Button>
                    </span>
                  </div>
                }
                classes={{
                  tooltip: `${styles.gamifiedPlayTooltip} light`,
                  arrow: `${styles.gamifiedPlayArrow} light`,
                }}
                interactive
                arrow
              >
                <div className={styles.gamifiedIconContainer}>
                  <GamifiedIcon />
                </div>
              </Tooltip>
            </div>
          )}
        <PlayThumbnail
          play={item}
          playSelected={
            (item.id && chosenPlays && chosenPlays.includes(item.id)) || false
          }
          useCheckbox
          onChange={(e) => playChecked(e, item)}
          showCheckbox={!isEmpty(chosenPlays)}
          link
          isPlaySetEditModal
          closePlaySetEditModal={closeModalWithStateChange}
        />
        <Link
          to={{
            pathname: `edit-play/${item.id}`,
            state: { isPlaySetEditModal: true },
          }}
          onClick={() => closeModalWithStateChange()}
          className={`${styles.playTitle} ${
            !item.published ? styles.draftTitleColor : ""
          }`}
        >
          {!item.published
            ? item.name.length > 50 && item.name.split(" ").length === 1
              ? `[Draft] ${truncate(item.name, {
                  length: 28,
                  separator: " ",
                })}`
              : `[Draft] ${item.name}`
            : item.name.length > 50 && item.name.split(" ").length === 1
            ? truncate(item.name, {
                length: 28,
                separator: " ",
              })
            : item.name}
        </Link>
        <span onClick={() => closeModalWithStateChange()}>
          <PlaybookTags isPlaySetEditModal play={item} />
        </span>
      </div>
    );
  };

  const openDeletePlayModal = () => {
    const playIds = playsSelected;

    if (isEmpty(playIds)) {
      return;
    }

    dispatchModal({
      title: `Delete Play${playIds.length > 1 ? "s" : ""} from Playbook`,
      open: true,

      body: (
        <DeletePlayModal
          playIds={playIds}
          playSet={selectedPlayset}
          clearSelectedPlays={handleDeselectAll}
        />
      ),
    });
  };

  const openPlaysRemoveFromPlaySetModal = () => {
    const playIds = playsSelected;

    if (isEmpty(playIds)) {
      return;
    }

    dispatchModal({
      title: `Remove Play${playIds.length > 1 ? "s" : ""} from PlaySet`,
      open: true,

      body: (
        <DeletePlayFromPlaySetModal
          playIds={playIds}
          playSet={selectedPlayset}
          clearSelectedPlays={handleDeselectAll}
          clearSelectedPlaySets={clearSelectedPlaySets}
        />
      ),
    });
  };

  const onDeletePlayset = async (playSet: PlaySetModel) => {
    if (playSet.id) {
      try {
        const response = await APIService.PLAY_SET.DELETE(playSet.id as string);
        if (response) {
          if (playSet.teamId) {
            const plays = await APIService.PLAY.LIST(playSet.teamId);
            if (plays) {
              setCurrentPlaybook(plays);
            }
            const globalPlays = await APIService.PLAY.LIST();
            if (globalPlays) {
              setGlobalPlaybook(globalPlays);
            }
            clearSelectedPlaySets && clearSelectedPlaySets();
            closeModals();
          }

          dispatchToast({
            type: "success",
            message: `Playset Deleted.`,
          });

          if (clearSelectedPlays) {
            clearSelectedPlays();
          }

          setTimeout(
            () => playSet && playSet.id && removePlaySet(playSet.id),
            500
          );
        }
      } catch (e) {
        dispatchToast({
          type: "error",
          message: "There's been an error. Please try again.",
        });
      }
    }
  };

  const updatePlaysToPlaySet = async (
    playSet: PlaySetModel,
    playsetContent?: PlayModel[]
  ) => {
    const playIdsToAdd =
      playsetContent && playsetContent.map((a) => a.id as string);

    if (playSet.id && playSetName.length > 0) {
      try {
        const response = await APIService.PLAY_SET.PUT({
          id: playSet.id as string,
          name: playSetName,
          teamId: playSet.teamId,
          playOrder:
            playsetContent && playIdsToAdd
              ? playIdsToAdd &&
                playIdsToAdd.map((playId) => {
                  const properties = {
                    playId: playId,
                  };
                  return properties;
                })
              : playSet.playOrder,
        });
        if (response) {
          const updatedPlaySet = await APIService.PLAY_SET.GET(
            playSet.id as string
          );
          if (updatedPlaySet) {
            updatePlaySet(updatedPlaySet);
          }
          if (playSet.teamId) {
            const plays = await APIService.PLAY.LIST(playSet.teamId);
            if (plays) {
              setCurrentPlaybook(plays);
            }
            const globalPlays = await APIService.PLAY.LIST();
            if (globalPlays) {
              setGlobalPlaybook(globalPlays);
            }
          }
          if (playsetContent && playIdsToAdd) {
            dispatchToast({
              type: "success",
              // message: `${playIdsToAdd.length} play${
              //   playIdsToAdd.length === 1 ? "" : "s"
              // } added to play set.`,
              message: `Play Set updated`,
            });
          } else {
            dispatchToast({
              type: "success",
              message: `Play Set name changed.`,
            });
            // closeModalWithStateChange();
          }
        }
      } catch (e) {
        dispatchToast({
          type: "error",
          message: "There's been an error. Please try again.",
        });
      }
    }
  };

  const onDeleteSet = (closing: boolean) => {
    dispatchModal({
      title: "Delete Play Set",
      open: !closing,
      closing: closing,
      body: <DeletePlaySetConfirmationModal />,
    });
  };

  const DeletePlaySetConfirmationModal = () => {
    return (
      <>
        <div>
          Are you sure you want to delete the play set? This cannot be undone.
        </div>
        <div className={styles.modalBtnWrapper}>
          <Button
            size="x-small"
            theme="secondary"
            className="normalBtn"
            onClick={() => onDeleteSet(true)}
          >
            Cancel
          </Button>
          <Button
            size="x-small"
            theme="secondary"
            className={styles.btn}
            onClick={() => onDeletePlayset(selectedPlayset)}
            destructive
          >
            Delete Play Set
          </Button>
        </div>
      </>
    );
  };
  const closeModalWithStateChange = () => {
    closeModal();
    setReopenPlaySetModal(false);
  };

  const onAddPlay = () => {
    closeModalWithStateChange();
    dispatchModal({
      title: "Create New Play",
      open: true,
      size: "large",
      closing:
        history.location.pathname.indexOf("edit-play") !== -1 ? true : false,
      body: <PlayDetails createForPlayset={true} playset={selectedPlayset} />,
    });
  };

  const onDuplicatePlay = async (playId: string) => {
    try {
      const playToDuplicate =
        currentPlaybook &&
        currentPlaybook.find((play: PlayModel) => play.id === playId);
      if (playToDuplicate) {
        const duplicatedPlayToAdd: PlayModel = {
          ...playToDuplicate,
          name:
            playToDuplicate.name +
            `-` +
            Math.random().toString(20).substr(2, 3),
        };

        const created = await APIService.PLAY.POST([duplicatedPlayToAdd]);

        if (created) {
          addPlays(created);
          if (selectedPlayset && selectedPlayset.id) {
            const properties: PlayOrder[] = [
              {
                playId: created[0].id as string,
              },
            ];
            const updatedPlayOrder = [
              ...selectedPlayset.playOrder,
              ...properties,
            ];

            const payLoad: any = {
              id: selectedPlayset.id as string,
              teamId: selectedPlayset.teamId,
              name: selectedPlayset.name,
              playOrder: updatedPlayOrder,
            };
            const newPlayAddedToPlaySet = await APIService.PLAY_SET.PUT(
              payLoad
            );

            if (newPlayAddedToPlaySet) {
              updatePlaySet(newPlayAddedToPlaySet);
            }
          }

          closeModalWithStateChange();
          history.push({
            pathname: `edit-play/${created[0].id}`,
            state: { isPlaySetEditModal: true },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.editPlaySetContainer}>
      <div className={styles.modalHeader}>
        {!editingTitle && (
          <span className={styles.playSetTitle}>
            {playSetName}
            <Button
              theme={"transparent"}
              size={"small"}
              icon={<PencilIcon />}
              onClick={() => setEditingTitle(true)}
            />
          </span>
        )}
        {editingTitle && (
          <div className={styles.modelEditedTitle}>
            <CharacterInput
              type={INPUT_TYPES.TEXT}
              placeholder="Play Set Name"
              size="x-small"
              id="pSetName"
              value={playSetName}
              onChange={(e) => setPlaySetName(e.currentTarget.value)}
              autofocus={true}
            />
            <Button
              theme={"transparent"}
              size={"small"}
              icon={<CloseIcon />}
              onClick={() => {
                setPlaySetName(selectedPlayset.name);
                setEditingTitle(false);
              }}
            />
            <Button
              theme={"transparent"}
              size={"small"}
              icon={<CheckIcon />}
              onClick={() => {
                setEditingTitle(false);
                updatePlaysToPlaySet(selectedPlayset);
              }}
            />
          </div>
        )}
        <span
          className={styles.closeIcon}
          onClick={() => closeModalWithStateChange()}
        >
          <img src={CloseIconSvg} alt="close-icon" />
        </span>
      </div>
      <div className={styles.playsListContainer}>
        <ListManager
          items={playsetContent}
          direction="horizontal"
          maxItems={2}
          render={(item) => <ListElement item={item} />}
          onDragEnd={onDragEnd}
        />
      </div>

      <div className={styles.modalFooter}>
        <span>
          <Button
            size="x-small"
            theme="secondary"
            onClick={() => onDeleteSet(false)}
            destructive
          >
            Delete Set
          </Button>
        </span>
        <span>
          {playsSelected.length > 0 && (
            <>
              <Button
                size="x-small"
                theme="secondary"
                onClick={() => openPlaysRemoveFromPlaySetModal()}
                destructive
              >
                Remove from Set
              </Button>
              <Button
                size="x-small"
                theme="secondary"
                className={styles.btn}
                onClick={() => openDeletePlayModal()}
                destructive
              >
                Delete Play
              </Button>
            </>
          )}
          {playsSelected.length === 1 ? (
            <Button
              className={styles.btn}
              size={"small"}
              onClick={() => onDuplicatePlay(playsSelected[0])}
            >
              Duplicate
            </Button>
          ) : (
            <Button
              className={styles.btn}
              size={"small"}
              onClick={() => onAddPlay()}
            >
              Add Play
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

export default EditPlaySet;
