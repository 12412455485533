import styles from "../Dashboard.module.scss";

import mainStyles from "../Dashboard.module.scss";
import CharacterInput from "../../../components/Input/CharacterInput";
import searchIcon from "../../../resources/images/search.svg";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import ToggleSelect from "../../../shared/shared-with-mobile/components/ToggleSelect/ToggleSelect";
import React, { useContext, useMemo, useState } from "react";
import { ReadinessScoresContext } from "../../../shared/shared-with-mobile/providers/readinessScores.provider";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import { DashboardStatsGrid } from "../../../components/DashboardStatsGrid/DashboardStatsGrid";
import OverallReadiness from "../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/OverallReadiness";
import PlayerModal from "../Components/PlayerModal/PlayerModal";
import { PlayerModel } from "../../../generated/from-api/models/relationships/player.model";
import Avatar from "../../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import dashboardStatsStyles from "../../../components/DashboardStatsGrid/DashboardStatsGrid.module.scss";
import CircularSpinner from "../../../components/Loaders/CircularSpinner";
import { PlayerOverallReadinessModel } from "../../../generated/from-api/models/dtos/player-to-play.readiness.model";
import { getAbbreviatedDisplayName } from "../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import { PositionFiltersMap } from "../../../shared/shared-with-mobile/functions";
import ReadinessOverTime from "../../../shared/shared-with-mobile/dashboard-widgets/ReadinessOverTime/ReadinessOverTime";
import { playersFilter } from "../../../utils/playerFilter.utils";

const DashboardOverall: React.FC = () => {
  const { playerOverallMetrics, teamMetricsOverTime, loading } = useContext(
    ReadinessScoresContext
  );
  const playersOverallMetricsToDisplay = playerOverallMetrics.filter(
    (player: PlayerOverallReadinessModel) => !player.playerHideOnLeaderBoard
  );
  const { currentPlayersAsArray } = useContext(PlayersContext);
  const [selectedFilter, setSelectedFilter] = useState(PositionFiltersMap[0]);
  const [searchText, setSearchText] = useState("");
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [player, setPlayer] = useState<PlayerModel>();

  const onRowClick = (id: string) => {
    setPlayer(
      currentPlayersAsArray.find((player: PlayerModel) => player.id === id)
    );
    setShowPlayerModal(true);
  };

  const columns = useMemo(
    () => [
      {
        name: "PLAYER",
        selector: (row: PlayerOverallReadinessModel) =>
          getAbbreviatedDisplayName(row.playerFirstName, row.playerLastName),
        cell: (row: PlayerOverallReadinessModel) => {
          return (
            <div
              className={`${dashboardStatsStyles.clickableCell} ${dashboardStatsStyles.flexRow}`}
              onClick={() => onRowClick(row.playerId)}
            >
              <div className={dashboardStatsStyles.usreListImg}>
                <Avatar userId={row.playerId} small type="player" />
              </div>

              <span className={dashboardStatsStyles.playerName}>
                {getAbbreviatedDisplayName(
                  row.playerFirstName,
                  row.playerLastName
                )}
              </span>
              {row.playerPositions && (
                <div className={dashboardStatsStyles.playerPosition}>
                  {row.playerPositions}
                </div>
              )}
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "PLAYBOOK READINESS",
        selector: (row: PlayerOverallReadinessModel) =>
          +row.playbookReadinessScore,
        format: (row: PlayerOverallReadinessModel): string =>
          `${row.playbookReadinessScore}%`,
        sortable: true,
        right: true,
      },
      {
        name: "LESSON READINESS",
        selector: (row: PlayerOverallReadinessModel) =>
          +row.installReadinessScore,
        format: (row: PlayerOverallReadinessModel) =>
          `${row.installReadinessScore}%`,
        sortable: true,
        right: true,
      },
      {
        name: "FLASHCARD READINESS",
        selector: (row: PlayerOverallReadinessModel) => +row.quizReadinessScore,
        format: (row: PlayerOverallReadinessModel): string =>
          `${row.quizReadinessScore}%`,
        sortable: true,
        right: true,
      },
      {
        name: "OVERALL READINESS",
        selector: (row: PlayerOverallReadinessModel) => +row.overallScore,
        format: (row: PlayerOverallReadinessModel): string =>
          `${parseFloat(row.overallScore).toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    [currentPlayersAsArray]
  );

  return loading ? (
    <CircularSpinner />
  ) : (
    <div>
      <div className={mainStyles.dashboardHeader}>
        <p className={mainStyles.dashboardHeader__Title}>Overall Readiness</p>
      </div>

      <div className={mainStyles.chartCardRow}>
        <ReadinessOverTime
          title="Overall Readiness Over Time"
          xLabel="Date"
          yLabel="Readiness"
          ySymbol="%"
          data={
            teamMetricsOverTime
              ? [
                  {
                    id: "line1",
                    data: teamMetricsOverTime.map((t) => {
                      return {
                        x: t.created,
                        y: +t.overallScore,
                      };
                    }),
                  },
                ]
              : []
          }
        />
        <OverallReadiness
          title="Overall Readiness Distribution"
          type="Bar-Distribution"
          isLabel={true}
          xLabel="Ready"
          yLabel="% of Players"
          ySymbol="%"
          scoreType="overallScore"
        />
        <OverallReadiness
          title="Overall Readiness by Unit"
          type="Bar-Unit"
          xLabel="Unit"
          yLabel="Readiness"
          ySymbol="%"
          scoreType="overallScore"
        />
      </div>

      <div className={styles.searchAndFilterBtn}>
        <div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Search"
            id="search"
            size="x-small"
            icon={searchIcon}
            clearButton
            value={searchText}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
        </div>
        <div className={styles.filterBtns}>
          <ToggleSelect
            options={PositionFiltersMap.map((f) => f.label)}
            selectedOption={selectedFilter.label}
            background="#151724"
            borderColor="#151724"
            onChange={(label: string) => {
              const newFilter = PositionFiltersMap.find(
                (f) => f.label == label
              );
              if (newFilter) {
                setSelectedFilter(newFilter);
              }
            }}
          />
        </div>
      </div>
      <br />
      <div>
        <DashboardStatsGrid
          data={playersOverallMetricsToDisplay.filter(
            playersFilter({
              searchString: searchText,
              filterByPosition: selectedFilter.value,
            })
          )}
          columns={columns}
        />
      </div>
      {player && (
        <PlayerModal
          player={player}
          visible={showPlayerModal}
          defaultTab={"overall"}
          close={() => setShowPlayerModal(false)}
        />
      )}
    </div>
  );
};

export default DashboardOverall;
