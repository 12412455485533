import * as React from "react";

const MediaIcon: React.FC = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2H14L12 0H6C4.9 0 4.01 0.9 4.01 2L4 14C4 15.1 4.9 16 6 16H22C23.1 16 24 15.1 24 14V4C24 2.9 23.1 2 22 2ZM0 4H2V18H20V20H2C0.9 20 0 19.1 0 18L0.01 9H0V4ZM7 13L11.5 7L15 11.51L17.5 8.5L21 13H7ZM6 14H22V4H13.17L12.58 3.41L11.17 2H6V14Z"
    />
  </svg>
);

export default MediaIcon;
