import { useContext } from "react";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";

import styles from "./ContentLimitModal.module.scss";
import { ContentLimitModalProps } from "../../shared/shared-with-mobile/utilities/ContentLimitModalProps";
import { useHistory } from "react-router";
import Button from "../Button/Button";

const ContentLimitModal: React.FC<ContentLimitModalProps> = ({
  itemName = "item",
  itemNamePlural = "items",
}) => {
  const { closeModal } = useContext(UIContext);
  const history = useHistory();

  return (
    <div className={styles.contentLimitModal}>
      <p>{`The maximum number of ${itemNamePlural} for this account has been reached. To create a ${itemName}, you can delete an existing one or upgrade your license.`}</p>
      <div className={styles.buttonRow}>
        <Button
          theme={"secondary"}
          onClick={() => {
            history.push(`/account`);
            closeModal();
          }}
        >
          Manage Licenses
        </Button>
        <Button onClick={closeModal}>Okay</Button>
      </div>
    </div>
  );
};

export default ContentLimitModal;
