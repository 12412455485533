import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VirtuosoGrid } from "react-virtuoso";

import styles from "./Playbook.module.scss";
import PlayThumbnail from "../../components/drawing/PlayThumbnail";
import PlayDetails from "../../components/drawing/PlayDetails";
import plus from "../../resources/images/plus.svg";
import close from "../../resources/images/close.svg";
import ImportPlays from "./create-play-components/ImportPlays";
import PlaybookSidebar from "./PlaybookSidebar/PlaybookSidebar";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { PlaybookContext } from "../../shared/shared-with-mobile/providers/playbook.provider";
import { PlayModel } from "../../generated/from-api/models/play.model";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { PlayCategoryEnum } from "../../generated/from-api/models/enums/play-category.enum";
import { FormationsContext } from "../../shared/shared-with-mobile/providers/formations.provider";
import { FormationOption } from "../../shared/shared-with-mobile/play-editor/drawing.types";
import {
  filterPlaybook,
  mapTagToOption,
  mapGamePlanToOption,
} from "../../utils/functions";
import { TagsContext } from "../../shared/shared-with-mobile/providers/tags.provider";
import { GamePlansContext } from "../../shared/shared-with-mobile/providers/gamePlans.provider";
import SingleSelect from "../../components/SingleSelect/SingleSelect";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { MultiSelectOption } from "../../components/MultiSelect/MultiSelect";
import CreateGamePlan from "./gamePlan-components/CreateGamePlan";
import AddToGamePlan from "./gamePlan-components/AddToGamePlan";
import { truncate, isEmpty, isUndefined } from "lodash";
import ListIcon from "../../resources/icons/ListIcon";
import PencilIcon from "../../resources/icons/PencilIcon";
import Button from "../../components/Button/Button";
import PlaybookTags from "./PlaybookTags";
import DeletePlayModal from "../../components/drawing/EditPlayDetails/DeletePlayModal";
import AddToPlaySet from "./play-set-components/AddToPlaySet";
import { PlaySetsContext } from "../../shared/shared-with-mobile/providers/playSets.provider";
import PlaySetThumbnail from "./play-set-components/PlaySetThumbnail";
import EditPlaySet from "./play-set-components/EditPlaySet";
import { PlaySetModel } from "../../generated/from-api/models/play-set.model";
import PlaybookPlaySetTags from "./PlaybookPlaySetTags";
import PrintIcon from "../../resources/icons/PrintIcon";
import PrintPlays from "./create-play-components/PrintPlays";
import OnboardingModal, {
  OnboardingStep,
} from "../../components/OnboardingModal/OnboardingModal";
import { Tooltip } from "@material-ui/core";
import GamifiedIcon from "../../resources/icons/GamifiedIcon";
import { LicensesContext } from "../../shared/shared-with-mobile/providers/licenses.provider";
import { LicenseTiersContext } from "../../shared/shared-with-mobile/providers/licenseTiers";
import { LicenseTierModel } from "../../generated/from-api/models/license-tier.model";
import {
  INTEGER_USED_FOR_NO_LIMIT,
  NON_GAMIFIED_PLAYS_TOOLTIP_CONTENT,
} from "../../shared/shared-with-mobile/constants";

export interface PlaybookFiltersModel {
  category: PlayCategoryEnum | null;
  scheme: string | null;
  searchText: string;
  formationIds: string[];
  defensiveSetIds: string[];
  situationNames: string[];
  personnelPackageNames: string[];
  gamePlanId: string | null;
}

const initialFilters: PlaybookFiltersModel = {
  category: null,
  scheme: null,
  searchText: "",
  formationIds: [],
  defensiveSetIds: [],
  situationNames: [],
  personnelPackageNames: [],
  gamePlanId: null,
};

const Playbook: React.FC = () => {
  const {
    currentPlaybook,
    setCurrentPlaybook,
    setGlobalPlaybook,
    gamifiedPlays,
    setGamifiedPlays,
  } = useContext(PlaybookContext);
  const { currentLicenses } = useContext(LicensesContext);
  const { licenseTiers } = useContext(LicenseTiersContext);

  const { globalFormations, currentFormations } = useContext(FormationsContext);
  const { personnelPackageTags, situationTags } = useContext(TagsContext);

  const [activeLicenseTier, setActiveLicenseTier] = useState<
    LicenseTierModel | undefined
  >();

  const {
    playSets,
    addPlaySet,
    selectedPlaySetId,
    setSelectedPlaySetId,
    reopenPlaySetModal,
  } = useContext(PlaySetsContext);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const {
    gamePlans,
    removePlaysFromGamePlan,
    removePlaySetsFromGamePlan,
    removeGamePlan,
  } = useContext(GamePlansContext);

  const {
    dispatchModal,
    dispatchSingleModal,
    dispatchToast,
    closeModal,
  } = useContext(UIContext);
  const [filteredPlaybook, setFilteredPlaybook] = useState<PlayModel[]>([]);

  const [playbookFilters, setPlaybookFilters] = useState<PlaybookFiltersModel>({
    ...initialFilters,
  });
  const [selectedPlays, setSelectedPlays] = useState<Map<string, PlayModel>>(
    new Map()
  );

  const [selectedPlaySets, setSelectedPlaySets] = useState<
    Map<string, PlayModel>
  >(new Map());

  const [playSetPlays, setPlaySetPlays] = useState<PlayModel[]>([]);
  const [allPlaysToPrint, setAllPlaysToPrint] = useState<PlayModel[]>([]);

  const [formationOptions, setFormationOptions] = useState<FormationOption[]>(
    []
  );
  const [defensiveSetOptions, setDefensiveSetOptions] = useState<
    FormationOption[]
  >([]);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState<boolean>(false);
  const [playSetDropDownChoice, setPlaySetDropDownChoice] = useState<
    string | undefined
  >();

  const playSetOptions = [
    { value: "AddToExistingSet", label: "Add to Existing Set" },
    { value: "CreateNewSet", label: "Create New Set" },
  ];

  useEffect(() => {
    if (currentPlaybook) {
      const nextFilteredPlaybook = filterPlaybook(
        playbookFilters,
        currentPlaybook,
        // team plays may use global formations, or current formations & should be able to filter by both
        [...globalFormations, ...currentFormations],
        gamePlans
      ).filter((play) => play.playSetId === null);

      if (playSets.length > 0) {
        const playSetWithFirstPlayThumbnail = playSets
          .filter(function (obj) {
            return obj.playOrder.length > 0;
          })
          .map((playSet) => {
            const getPlaySetPlayOrderFirstPlay = currentPlaybook?.find(
              (play) => (play.id as string) === playSet.playOrder[0].playId
            );

            const replacePlayIdAndNameWithPlaySet =
              getPlaySetPlayOrderFirstPlay?.playSetId === playSet.id
                ? {
                    ...getPlaySetPlayOrderFirstPlay,
                    id: playSet.id,
                    name: playSet.name,
                  }
                : getPlaySetPlayOrderFirstPlay;
            return replacePlayIdAndNameWithPlaySet;
          });

        if (playSetWithFirstPlayThumbnail) {
          const playSetsWithPlays = [
            ...playSetWithFirstPlayThumbnail,
            ...nextFilteredPlaybook,
          ];

          const nextFilteredPlaybookwithPlaySets = filterPlaybook(
            playbookFilters,
            playSetsWithPlays as PlayModel[],
            // team plays may use global formations, or current formations & should be able to filter by both
            [...globalFormations, ...currentFormations],
            gamePlans
          );

          setFilteredPlaybook(nextFilteredPlaybookwithPlaySets);
        }
      } else {
        setFilteredPlaybook(nextFilteredPlaybook);
      }
    }
  }, [playSets, currentPlaybook, playbookFilters]);

  useEffect(() => {
    if (currentLicenses && currentLicenses.length > 0 && licenseTiers) {
      const licenseTier = licenseTiers.filter((tier) => {
        return tier.id == currentLicenses[0].licenseTierId;
      })[0] as LicenseTierModel;

      // Getting Gamified Plays as per license tier.
      if (
        currentPlaybook &&
        licenseTier &&
        licenseTier.maxGamifiedPlays !== INTEGER_USED_FOR_NO_LIMIT
      ) {
        const sortedPlays = currentPlaybook.sort((a, b) => {
          return a.id && b.id ? (a.id < b.id ? -1 : 1) : -1;
        });

        if (currentPlaybook.length <= licenseTier.maxGamifiedPlays) {
          setGamifiedPlays(sortedPlays);
        } else {
          setGamifiedPlays(sortedPlays.slice(0, licenseTier.maxGamifiedPlays));
        }
      }

      setActiveLicenseTier(licenseTier);
    }
  }, [currentLicenses, licenseTiers, currentPlaybook]);

  const getNonGamifiedPlaysTooltipContent = NON_GAMIFIED_PLAYS_TOOLTIP_CONTENT.find(
    (nonGamifiedPlayTooltipContent) =>
      nonGamifiedPlayTooltipContent.tier === activeLicenseTier?.tier
  );

  const addAllPlaysToPrint = () => {
    const playsInPlaysets: PlayModel[] = [];
    setShowMessage(true);
    const playIdsInPlaySets: any = playSets.map(
      (playSet: PlaySetModel) => playSet.playOrder
    );

    playIdsInPlaySets
      .flat()
      .map((playId: any) => playId.playId)
      .filter((e: any) => {
        const play = currentPlaybook?.find((play) => play.id === e);
        if (play) {
          playsInPlaysets.push(play);
        }
      });
    const printablePlays = filterPlaybook(
      playbookFilters,
      playsInPlaysets,
      [...globalFormations, ...currentFormations],
      gamePlans
    );
    setAllPlaysToPrint([
      ...printablePlays,
      ...filteredPlaybook.filter((playObj: PlayModel) => !playObj.playSetId),
    ]);
  };

  useEffect(() => {
    const nextFormationOptions = [...currentFormations, ...globalFormations]
      .filter((formation) => formation.type === "Offensive")
      .map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));
    const nextDefensiveSetOptions = [...currentFormations, ...globalFormations]
      .filter((formation) => formation.type === "Defensive")
      .map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));
    setFormationOptions(nextFormationOptions);
    setDefensiveSetOptions(nextDefensiveSetOptions);
  }, [currentFormations, globalFormations]);

  useEffect(() => {
    setPlaySetDropDownChoice(undefined);
  }, [playSetDropDownChoice]);

  const openCreatePlayModal = () => {
    dispatchModal({
      title: "Create New Play",
      open: true,
      size: "large",
      body: <PlayDetails />,
    });
  };

  const selectPlaySetOption = (value: any) => {
    setPlaySetDropDownChoice("");
    if (value === "CreateNewSet") {
      createNewPlaySet();
    } else {
      openAddPlaySet();
    }
  };

  const openImportPlaysModal = () => {
    dispatchSingleModal({
      title: "Playbook Library",
      open: true,
      size: "large",
      className: styles.importPlaysModal,
      body: <ImportPlays />,
    });
  };

  const playChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    play: PlayModel
  ) => {
    if (!play.id) {
      return;
    }

    const selectedPlaysCopy = selectedPlays
      ? new Map(selectedPlays)
      : new Map<string, PlayModel>();

    if (e.currentTarget.checked) {
      selectedPlaysCopy.set(play.id, play);
      if (
        !allPlaysToPrint.find((playObj: PlayModel) => playObj.id == play.id)
      ) {
        setAllPlaysToPrint([...[play], ...allPlaysToPrint]);
      } else {
        setAllPlaysToPrint([play]);
      }
    } else {
      if (
        allPlaysToPrint.find((playObj: PlayModel) => playObj.id === play.id)
      ) {
        const modifiedPlaysToPrint = allPlaysToPrint.filter(
          (playObj: PlayModel) => playObj.id !== play.id
        );
        setAllPlaysToPrint(modifiedPlaysToPrint);
      }
      selectedPlaysCopy.delete(play.id);
    }
    setSelectedPlays(selectedPlaysCopy);
  };

  const playSetChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    playset: PlayModel
  ) => {
    if (!playset.id) {
      return;
    }

    const selectedPlaysCopy = selectedPlaySets
      ? new Map(selectedPlaySets)
      : new Map<string, PlayModel>();

    if (e.currentTarget.checked) {
      if (
        !allPlaysToPrint.find(
          (playObj: PlayModel) => playObj.playSetId === playset.playSetId
        ) &&
        currentPlaybook
      ) {
        const playsInPlaySet: PlayModel[] = [];
        const checkedPlaysetPlayOrder = playSets.find(
          (playSet: PlaySetModel) => playSet.id === playset.playSetId
        )?.playOrder;
        checkedPlaysetPlayOrder &&
          checkedPlaysetPlayOrder
            .map((playId) => playId.playId)
            .filter((e) => {
              const play = currentPlaybook.find((play) => play.id === e);
              if (play) {
                playsInPlaySet.push(play);
              }
            });
        setAllPlaysToPrint([...playsInPlaySet, ...allPlaysToPrint]);
      } else {
        const playsInPlaySet: PlayModel[] = [];
        const checkedPlaysetPlayOrder = playSets.find(
          (playSet: PlaySetModel) => playSet.id === playset.playSetId
        )?.playOrder;
        checkedPlaysetPlayOrder &&
          checkedPlaysetPlayOrder
            .map((playId) => playId.playId)
            .filter((e) => {
              const play = currentPlaybook?.find((play) => play.id === e);
              if (play) {
                playsInPlaySet.push(play);
              }
            });
        setAllPlaysToPrint([...playsInPlaySet]);
      }
      selectedPlaysCopy.set(playset.id, playset);
    } else {
      if (
        allPlaysToPrint.find(
          (playObj: PlayModel) => playObj.playSetId === playset.playSetId
        ) &&
        currentPlaybook
      ) {
        const modifiedPlaysToPrint = allPlaysToPrint.filter(
          (playObj: PlayModel) => playObj.playSetId !== playset.playSetId
        );
        setAllPlaysToPrint(modifiedPlaysToPrint);
      }
      selectedPlaysCopy.delete(playset.id);
    }
    setSelectedPlaySets(selectedPlaysCopy);
    if (!selectedPlaySets.has(playset.id) && playSets && currentPlaybook) {
      const selectedPlaySetModel = playSets.find(
        (set: PlaySetModel) => set.id === playset.id
      );
      const playIdsInPlaySet =
        selectedPlaySetModel && selectedPlaySetModel.playOrder;
      const playsInPlaySet: PlayModel[] = [];
      playIdsInPlaySet &&
        playIdsInPlaySet
          .map((playId) => playId.playId)
          .filter((e) => {
            const play = currentPlaybook.find((play) => play.id === e);
            if (play) {
              playsInPlaySet.push(play);
            }
          });

      setPlaySetPlays([...playSetPlays, ...playsInPlaySet]);
    } else {
      setPlaySetPlays(
        playSetPlays.filter((obj) => obj.playSetId !== playset.id)
      );
    }
  };

  const handleSelectAll = () => {
    const allSelectedPlays = new Map<string, PlayModel>();
    const allSelectedPlaysets = new Map<string, PlayModel>();
    let printablePlays: PlayModel[] = [];
    filteredPlaybook.forEach((play: PlayModel) => {
      if ((!play.id || play.id === play.playSetId) && currentPlaybook) {
        if (play && play.id && play.id === play.playSetId) {
          const playsInPlaysets: PlayModel[] = [];
          const playIdsInPlaySets: any = playSets.map(
            (playSet: PlaySetModel) => playSet.playOrder
          );

          playIdsInPlaySets
            .flat()
            .map((playId: any) => playId.playId)
            .filter((e: any) => {
              const play = currentPlaybook.find((play) => play.id === e);
              if (play) {
                playsInPlaysets.push(play);
              }
            });
          printablePlays = filterPlaybook(
            playbookFilters,
            playsInPlaysets,
            [...globalFormations, ...currentFormations],
            gamePlans
          );
          allSelectedPlaysets.set(play.id, play);
        }
        return;
      }
      allSelectedPlays.set(play.id as string, play);
    });
    setAllPlaysToPrint([
      ...printablePlays,
      ...Array.from(allSelectedPlays.values()),
    ]);
    setSelectedPlays(allSelectedPlays);
    setSelectedPlaySets(allSelectedPlaysets);
  };

  const handleDeselectAll = () => {
    const clearedSelectedPlays = new Map();
    setSelectedPlays(clearedSelectedPlays);
    setAllPlaysToPrint([]);
    handleDeselectAllPlaySets();
  };

  const handleDeselectAllPlaySets = () => {
    const clearedSelectedPlaySets = new Map();
    setSelectedPlaySets(clearedSelectedPlaySets);
  };

  useEffect(() => {
    if (reopenPlaySetModal && !!selectedPlaySetId) {
      openPlaySetEditModal(selectedPlaySetId);
    }
  }, [reopenPlaySetModal]);

  const openPlaySetEditModal = (playSetId: string) => {
    const playSet = playSets.find((playSet) => playSet.id === playSetId);
    playSet && openEditPlaySet(playSet);
  };

  const openEditPlaySet = (selectedPlaySet: PlaySetModel) => {
    setSelectedPlaySetId(selectedPlaySet.id);
    dispatchModal({
      open: true,
      size: "medium",
      className: styles.editPlaySetModal,
      body: (
        <EditPlaySet
          getNonGamifiedPlaysTooltipContent={getNonGamifiedPlaysTooltipContent}
          openPlaySetEditModal={openPlaySetEditModal}
          selectedPlaysetModal={selectedPlaySet}
          clearSelectedPlays={handleDeselectAll}
          clearSelectedPlaySets={handleDeselectAllPlaySets}
        />
      ),
    });
  };

  const createNewPlaySet = async () => {
    const playIdsToAdd = Array.from(selectedPlays.keys());
    const selectedPlaysValues = Array.from(selectedPlays.values());

    if (selectedPlays.size > 0) {
      try {
        const response = await APIService.PLAY_SET.POST({
          name: selectedPlaysValues[0].name,
          teamId: selectedPlaysValues[0].teamId,
          playOrder: playIdsToAdd.map((playId) => {
            const properties = {
              playId: playId,
            };
            return properties;
          }),
        });

        if (response) {
          addPlaySet(response);
          if (response.teamId) {
            const plays = await APIService.PLAY.LIST(response.teamId);

            if (plays) {
              setCurrentPlaybook(plays);
            }

            const globalPlays = await APIService.PLAY.LIST();

            if (globalPlays) {
              setGlobalPlaybook(globalPlays);
            }
          }
          handleDeselectAll();
          response && openEditPlaySet(response);
        }
      } catch (e) {
        dispatchToast({
          type: "error",
          message: "There's been an error. Please try again.",
        });
      }
    }
  };

  const openAddPlaySet = () => {
    dispatchModal({
      open: true,
      size: "medium",
      className: styles.createPlaySetModal,
      body: (
        <AddToPlaySet
          selectedPlays={selectedPlays}
          clearSelectedPlays={handleDeselectAll}
        />
      ),
      disableBackdropClick: false,
    });
  };

  const openCreateGamePlan = () => {
    dispatchModal({
      title: "Create Play Group",
      open: true,
      size: "medium",
      className: styles.createGamePlanModal,
      body: (
        <CreateGamePlan
          selectedPlaySets={selectedPlaySets}
          selectedPlays={selectedPlays}
          clearSelectedPlaysAndPlaySets={handleDeselectAll}
        />
      ),
    });
  };

  const openEditGamePlan = () => {
    if (playbookFilters.gamePlanId) {
      dispatchModal({
        title: "Edit Play Group",
        open: true,
        size: "medium",
        className: styles.createGamePlanModal,
        body: (
          <CreateGamePlan
            selectedPlaySets={selectedPlaySets}
            selectedPlays={selectedPlays}
            clearSelectedPlaysAndPlaySets={handleDeselectAll}
            gamePlanId={playbookFilters.gamePlanId}
            clearGamePlanFilter={unselectGamePlan}
          />
        ),
      });
    }
  };

  const openAddGamePlan = () => {
    dispatchModal({
      title: "Select Play Group",
      open: true,
      size: "medium",
      className: styles.createGamePlanModal,
      body: (
        <AddToGamePlan
          selectedPlaySets={selectedPlaySets}
          selectedPlays={selectedPlays}
          clearSelectedPlaysAndPlaySets={handleDeselectAll}
        />
      ),
    });
  };

  const filterByGamePlan = (gamePlanId: string) => {
    // clear all filters when a gamePlan is selected, per design
    setPlaybookFilters({
      ...initialFilters,
      gamePlanId,
    });

    // also clear all selectedPlays to avoid a situation where selectedPlays contains
    // both plays that are in the gamePlan and plays that are not
    setSelectedPlays(new Map());
  };

  const unselectGamePlan = () => {
    setPlaybookFilters({
      ...playbookFilters,
      gamePlanId: null,
    });
  };

  const removeSelectedPlaysFromGamePlan = async () => {
    const selectedGamePlan = gamePlans.find(
      (gamePlan) => gamePlan.id === playbookFilters.gamePlanId
    );

    const playIdsToRemove = Array.from(selectedPlays.keys());
    const playSetIdsToRemove = Array.from(selectedPlaySets.keys());

    if (selectedGamePlan && selectedGamePlan.id) {
      const preUpdateGamePlan = await APIService.GAME_PLAN.GET(
        selectedGamePlan.id
      );
      const remainingPlays = preUpdateGamePlan.plays
        .filter((play) => {
          if (!play.id) return false;
          return !playIdsToRemove.includes(play.id);
        })
        .map((play) => {
          return play.id || "";
        });
      const remainingPlaySets = preUpdateGamePlan.playSets
        .filter((playset) => {
          if (!playset.id) return false;
          return !playSetIdsToRemove.includes(playset.id);
        })
        .map((playset) => {
          return playset.id || "";
        });
      const updated = await APIService.GAME_PLAN.PUT(selectedGamePlan.id, {
        name: selectedGamePlan.name,
        displayOnMenu: selectedGamePlan.displayOnMenu,
        expirationDate: selectedGamePlan.expirationDate,
        plays: remainingPlays,
        playSets: remainingPlaySets,
      });

      if (updated) {
        removePlaysFromGamePlan(selectedGamePlan.id, playIdsToRemove);
        removePlaySetsFromGamePlan(selectedGamePlan.id, playSetIdsToRemove);
        setSelectedPlays(new Map());
        setSelectedPlaySets(new Map());
        dispatchToast({
          type: "success",
          message: `${playIdsToRemove.length} play${
            playIdsToRemove.length === 1 ? "" : "s"
          } and ${playSetIdsToRemove.length} playset${
            playSetIdsToRemove.length === 1 ? "" : "s"
          } removed from play group.`,
        });
      }
    }
  };

  const deleteGamePlan = async () => {
    const gamePlanIdToDelete = playbookFilters.gamePlanId as string;
    const response = await APIService.GAME_PLAN.DELETE(gamePlanIdToDelete);

    if (response && response.deleted) {
      removeGamePlan(gamePlanIdToDelete);
      setSelectedPlays(new Map());
      setPlaybookFilters({
        ...playbookFilters,
        gamePlanId: null,
      });
      dispatchToast({
        type: "success",
        message: "Play Group deleted",
      });
    }
  };

  useEffect(() => {
    if (playbookFilters.gamePlanId) {
      // after gamePlans stored in context have updated, trigger the playbookFilters useEffect again
      setPlaybookFilters({
        ...playbookFilters,
      });
    }
  }, [gamePlans]);

  const openDeletePlayModal = () => {
    const playIds = Array.from(selectedPlays.keys());

    if (isEmpty(playIds)) {
      return;
    }

    dispatchModal({
      title: `Delete Play${playIds.length > 1 ? "s" : ""} from Playbook`,
      open: true,
      body: (
        <DeletePlayModal
          setSelectedPlays={setSelectedPlays}
          playIds={playIds}
        />
      ),
    });
  };

  return (
    <div className={styles.playbook}>
      <section className="pageHeader pageHeaderSlim">
        <h1 className="pageTitle">Playbook</h1>
        <div className={styles.buttonContainer}>
          <div>
            {playbookFilters.gamePlanId && (
              <Button
                theme="tertiary"
                size={"small"}
                icon={<PencilIcon />}
                onClick={openEditGamePlan}
              >
                Edit Play Group
              </Button>
            )}
          </div>
          <div className={styles.mainButtons}>
            <Button
              className={styles.printButton}
              theme="secondary"
              size={"small"}
              icon={<PrintIcon />}
              onClick={() => {
                if (allPlaysToPrint.length === 0) {
                  addAllPlaysToPrint();
                }
                setIsPrintModalOpen(true);
              }}
            >
              Print
            </Button>
            <Button
              className={styles.libraryButton}
              theme="secondary"
              size={"small"}
              icon={<ListIcon />}
              onClick={openImportPlaysModal}
            >
              Library
            </Button>

            <Button
              className={styles.createPlayButton}
              theme="primary"
              size={"small"}
              onClick={openCreatePlayModal}
            >
              Draw Play
            </Button>
            <Button
              theme="primary"
              size={"small"}
              onClick={() => {
                dispatchModal({
                  open: true,
                  size: "responsive",
                  body: (
                    <OnboardingModal
                      startingStep={OnboardingStep.PdfImportStart}
                      handlePdfImportCancel={() => {
                        closeModal();
                      }}
                      showMobileAppLinks={false}
                    />
                  ),
                });
              }}
            >
              Hudl Import
            </Button>
          </div>
        </div>
      </section>
      <section className={styles.plays}>
        <PlaybookSidebar
          playbookFilters={playbookFilters}
          setPlaybookFilters={setPlaybookFilters}
          playbook={currentPlaybook}
        />
        <div className={styles.listWrapper}>
          <div className={styles.filterBar}>
            {playbookFilters.gamePlanId === null && !!gamePlans.length && (
              <SingleSelect
                placeholder="PLAY GROUP"
                options={gamePlans.map(mapGamePlanToOption)}
                onChange={(e) => {
                  filterByGamePlan(e.currentTarget.value);
                }}
              />
            )}
            {playbookFilters.gamePlanId !== null && (
              <div className={styles.selectedGamePlanLabel}>
                <span>
                  {
                    gamePlans.find(
                      (gamePlan) => gamePlan.id === playbookFilters.gamePlanId
                    )?.name
                  }
                </span>
                <img
                  src={close}
                  role="button"
                  alt="close"
                  onClick={unselectGamePlan}
                />
              </div>
            )}
            <MultiSelect
              placeholder="OFFENSIVE FORMATION"
              options={formationOptions}
              onChange={(value) => {
                setPlaybookFilters({
                  ...playbookFilters,
                  formationIds: (value.currentTarget as any).selectedList,
                });
              }}
              filter
            />
            <MultiSelect
              placeholder="PERSONNEL"
              options={
                personnelPackageTags.map(mapTagToOption) as MultiSelectOption[]
              }
              onChange={(value) => {
                setPlaybookFilters({
                  ...playbookFilters,
                  personnelPackageNames: (value.currentTarget as any)
                    .selectedList,
                });
              }}
              filter
            />
            <MultiSelect
              placeholder="SITUATION"
              options={situationTags.map(mapTagToOption) as MultiSelectOption[]}
              onChange={(value) => {
                setPlaybookFilters({
                  ...playbookFilters,
                  situationNames: (value.currentTarget as any).selectedList,
                });
              }}
              filter
            />
            <MultiSelect
              placeholder="DEFENSIVE FRONT"
              options={defensiveSetOptions}
              onChange={(value) => {
                setPlaybookFilters({
                  ...playbookFilters,
                  defensiveSetIds: (value.currentTarget as any).selectedList,
                });
              }}
              filter
            />
          </div>
          <div className={styles.playsList}>
            {currentPlaybook && currentPlaybook.length ? (
              filteredPlaybook.length ? (
                <VirtuosoGrid
                  totalCount={filteredPlaybook.length}
                  overscan={{ main: 250, reverse: 250 }}
                  isScrolling={(e) => setIsScrolling(e)}
                  itemContent={(index: number) => {
                    const play = filteredPlaybook[index];

                    return play &&
                      !isUndefined(play.id) &&
                      play.id !== play.playSetId ? (
                      <div
                        key={play.id}
                        className={`${styles.play} ${
                          play.id && selectedPlays.has(play.id)
                            ? styles.selected
                            : ""
                        }`}
                      >
                        {gamifiedPlays.length > 0 &&
                          !gamifiedPlays.some(
                            (gamifiedPlay) => gamifiedPlay.id === play.id
                          ) && (
                            <div className={styles.gamifiedPlay}>
                              <Tooltip
                                title={
                                  <div>
                                    <p
                                      style={{
                                        margin: "5px",
                                      }}
                                    >
                                      {getNonGamifiedPlaysTooltipContent &&
                                        getNonGamifiedPlaysTooltipContent.content}
                                    </p>
                                    <span
                                      style={{ margin: "5px", float: "right" }}
                                    >
                                      <Button
                                        type={"external link"}
                                        externalLinkOptions={{
                                          href: getNonGamifiedPlaysTooltipContent
                                            ? getNonGamifiedPlaysTooltipContent.link
                                            : `#`,
                                        }}
                                        theme="primary"
                                        size={"small"}
                                      >
                                        {getNonGamifiedPlaysTooltipContent &&
                                          getNonGamifiedPlaysTooltipContent.btnTitle}
                                      </Button>
                                    </span>
                                  </div>
                                }
                                classes={{
                                  tooltip: `${styles.gamifiedPlayTooltip} light`,
                                  arrow: `${styles.gamifiedPlayArrow} light`,
                                }}
                                interactive
                                arrow
                              >
                                <div className={styles.gamifiedIconContainer}>
                                  <GamifiedIcon />
                                </div>
                              </Tooltip>
                            </div>
                          )}

                        <PlayThumbnail
                          play={play}
                          useLoader={isScrolling}
                          playSelected={
                            (play.id && selectedPlays.has(play.id)) || false
                          }
                          onChange={(e) => playChecked(e, play)}
                          useCheckbox
                          showCheckbox={!isEmpty(selectedPlays)}
                          link
                        />
                        <Link
                          to={`edit-play/${play.id}`}
                          className={`${styles.playTitle} ${
                            !play.published ? styles.draftTitleColor : ""
                          }`}
                        >
                          {!play.published
                            ? play.name.length > 50 &&
                              play.name.split(" ").length === 1
                              ? `[Draft] ${truncate(play.name, {
                                  length: 28,
                                  separator: " ",
                                })}`
                              : `[Draft] ${play.name}`
                            : play.name.length > 50 &&
                              play.name.split(" ").length === 1
                            ? truncate(play.name, {
                                length: 28,
                                separator: " ",
                              })
                            : play.name}
                        </Link>
                        <PlaybookTags play={play} />
                      </div>
                    ) : (
                      <div
                        key={play?.id}
                        className={`${styles.play} ${
                          play?.id && selectedPlays.has(play?.id)
                            ? styles.selected
                            : ""
                        }`}
                      >
                        <PlaySetThumbnail
                          play={play}
                          useLoader={isScrolling}
                          playSelected={
                            (play?.id && selectedPlaySets.has(play?.id)) ||
                            false
                          }
                          onChange={(e) => playSetChecked(e, play)}
                          useCheckbox
                          showCheckbox={!isEmpty(selectedPlaySets)}
                          modal
                          openEditPlaySet={openEditPlaySet}
                        />
                        <span
                          onClick={() =>
                            play?.id && openPlaySetEditModal(play?.id)
                          }
                          className={`${styles.playTitle} ${
                            !play.published ? styles.draftTitleColor : ""
                          }`}
                        >
                          {!play?.published
                            ? play?.name.length > 50 &&
                              play?.name.split(" ").length === 1
                              ? `[Draft] ${truncate(play?.name, {
                                  length: 28,
                                  separator: " ",
                                })}`
                              : `[Draft] ${play?.name}`
                            : play?.name.length > 50 &&
                              play?.name.split(" ").length === 1
                            ? truncate(play?.name, {
                                length: 28,
                                separator: " ",
                              })
                            : play?.name}
                        </span>
                        <span
                          onClick={() =>
                            play?.id && openPlaySetEditModal(play?.id)
                          }
                        >
                          <PlaybookPlaySetTags playsetId={play?.id as string} />
                        </span>
                      </div>
                    );
                  }}
                />
              ) : (
                <div className={styles.grid}>
                  <div>0 plays matching applied filters.</div>
                </div>
              )
            ) : currentPlaybook !== undefined ? (
              <div className={styles.grid}>
                <div className={`card ${styles.noPlaybook}`}>
                  <img src={plus} alt="add" />
                  <Button
                    theme="transparent"
                    size={"small"}
                    onClick={openCreatePlayModal}
                  >
                    Draw Play
                  </Button>
                  or
                  <Button
                    theme="transparent"
                    size={"small"}
                    onClick={openImportPlaysModal}
                  >
                    Add from Library
                  </Button>
                </div>
                <div className={`card ${styles.noPlaybook}`}>
                  <img src={plus} alt="add" />
                  <Button
                    theme="transparent"
                    size={"small"}
                    onClick={() => {
                      dispatchModal({
                        open: true,
                        size: "responsive",
                        body: (
                          <OnboardingModal
                            startingStep={OnboardingStep.PdfImportStart}
                            handlePdfImportCancel={() => {
                              closeModal();
                            }}
                            showMobileAppLinks={false}
                          />
                        ),
                      });
                    }}
                  >
                    Hudl Import
                  </Button>
                </div>
              </div>
            ) : (
              <div className={styles.grid}>
                {[...Array(12)].map((e, index) => (
                  <div
                    key={index}
                    className={`${styles.play} ${styles.loader}`}
                  ></div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
      {(selectedPlaySets.size > 0 || selectedPlays.size > 0) && (
        <div className={styles.gamePlanPlaysToolbar}>
          <div>
            <span className={styles.playsSelected}>
              {selectedPlaySets.size} playset
              {selectedPlaySets.size > 1 ? "s" : ""} / {selectedPlays.size} play
              {selectedPlays.size > 1 ? "s" : ""} selected
            </span>
            <Button
              theme={"transparent"}
              size={"small"}
              className={`${styles.selectButtons} ${styles.selectAllBtn}`}
              onClick={handleSelectAll}
            >
              Select all
            </Button>
            <Button
              className={styles.selectButtons}
              theme={"transparent"}
              size={"small"}
              onClick={handleDeselectAll}
            >
              De-select all
            </Button>
          </div>
          {playbookFilters.gamePlanId === null && (
            <div className={styles.submitButtonContainer}>
              <Button
                className={styles.deleteBtn}
                theme="tertiary"
                destructive
                size="small"
                onClick={openDeletePlayModal}
              >
                Delete
              </Button>
              {selectedPlaySets.size < 1 && (
                <SingleSelect
                  id="playSetDropDown"
                  placeholder="Play Set"
                  options={playSetOptions}
                  className={styles.playSet}
                  value={playSetDropDownChoice}
                  onChange={(e) => {
                    selectPlaySetOption(e.currentTarget.value);
                  }}
                />
              )}

              {!!gamePlans.length && (
                <Button
                  size={"small"}
                  className={styles.addBtn}
                  onClick={openAddGamePlan}
                >
                  Add to Play Group
                </Button>
              )}
              <Button size={"small"} onClick={openCreateGamePlan}>
                Create Play Group
              </Button>
            </div>
          )}
          {playbookFilters.gamePlanId !== null && (
            <div className={styles.submitButtonContainer}>
              {Array.from(selectedPlays.keys()).length <
                filteredPlaybook.length && (
                <Button
                  size={"small"}
                  onClick={removeSelectedPlaysFromGamePlan}
                >
                  Remove from Play Group
                </Button>
              )}
              {Array.from(selectedPlays.keys()).length >=
                filteredPlaybook.length && (
                <Button size={"small"} onClick={deleteGamePlan}>
                  Delete Play Group
                </Button>
              )}
            </div>
          )}
        </div>
      )}
      <PrintPlays
        visible={isPrintModalOpen}
        onClose={() => {
          setIsPrintModalOpen(false);
          setShowMessage(false);
        }}
        playsToPrint={allPlaysToPrint}
        showMessage={showMessage}
      />
    </div>
  );
};

export default Playbook;
