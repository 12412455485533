import axios from "axios";

import {
  MediaTagListModel,
  MediaTagModel,
} from "../../../../generated/from-api/models/media.model";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { API_BASE_URL } from "../../constants";

export const MediaTagsService = {
  /**
   * Use to retrieve a list of media and its tags for a team or from the global library if no team id is specified
   */
  LIST: async function (teamId?: string): Promise<MediaTagListModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.MEDIA_TAGS}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  POST: async function (
    data: Pick<MediaTagModel, "teamId" | "mediaId" | "tagId" | "tagFolderId">
  ): Promise<MediaTagListModel> {
    return (await axios.post(`${API_BASE_URL}${APIRoutes.MEDIA_TAGS}`, data))
      .data;
  },

  DELETE: async function (
    mediaId: string,
    tagId: string
  ): Promise<MediaTagListModel[]> {
    return (
      await axios.delete(
        `${API_BASE_URL}${APIRoutes.MEDIA_TAGS}/${mediaId}/${tagId}`
      )
    ).data;
  },
};
