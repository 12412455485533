import * as React from "react";

const TeamLogoPlaceholderIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
  >
    <rect width="60" height="60" rx="30" fill="#353849" />
    <path
      d="M18.167 20.8248L29.667 15.6451L41.167 20.8248V28.5702C41.167 32.2773 40.0358 35.653 37.7397 38.7385C35.5274 41.7114 32.8417 43.5877 29.667 44.4508C26.4923 43.5877 23.8066 41.7114 21.5943 38.7385C19.2982 35.653 18.167 32.2773 18.167 28.5702V20.8248Z"
      stroke="#303241"
      strokeWidth="3"
    />
  </svg>
);

export default TeamLogoPlaceholderIcon;
