import * as React from "react";

const ToolbarUnorderedListIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.291748 2C0.291748 1.30833 0.850081 0.75 1.54175 0.75C2.23341 0.75 2.79175 1.30833 2.79175 2C2.79175 2.69167 2.23341 3.25 1.54175 3.25C0.850081 3.25 0.291748 2.69167 0.291748 2ZM0.291748 7C0.291748 6.30833 0.850081 5.75 1.54175 5.75C2.23341 5.75 2.79175 6.30833 2.79175 7C2.79175 7.69167 2.23341 8.25 1.54175 8.25C0.850081 8.25 0.291748 7.69167 0.291748 7ZM1.54175 10.75C0.850081 10.75 0.291748 11.3167 0.291748 12C0.291748 12.6833 0.858415 13.25 1.54175 13.25C2.22508 13.25 2.79175 12.6833 2.79175 12C2.79175 11.3167 2.23341 10.75 1.54175 10.75ZM15.7084 12.8333H4.04175V11.1667H15.7084V12.8333ZM4.04175 7.83333H15.7084V6.16667H4.04175V7.83333ZM4.04175 2.83333V1.16667H15.7084V2.83333H4.04175Z"
    />
  </svg>
);

export default ToolbarUnorderedListIcon;
