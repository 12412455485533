import React, { useState, useEffect, useContext } from "react";

import styles from "./Avatar.module.scss";
import helmet from "../../resources/images/helmet.svg";
import whistle from "../../resources/images/whistle.svg";
import { PlayersContext } from "../../providers/players.provider";
import { CoachesContext } from "../../providers/coaches.provider";
import { UserContext } from "../../providers/user.provider";

interface Props {
  userId: string;
  rankingData?: number;
  type?: "player" | "coach";
  small?: boolean;
  extraSmall?: boolean;
  rankCircleStyle?: boolean;
}

const Avatar: React.FC<Props> = ({
  userId,
  rankingData,
  type,
  small = false,
  extraSmall = false,
  rankCircleStyle = false,
}) => {
  const { currentPlayers } = useContext(PlayersContext);
  const { currentCoaches } = useContext(CoachesContext);
  const { userProfile } = useContext(UserContext);
  const [altText, setAltText] = useState<string>("User avatar");
  const [imgURL, setImgURL] = useState<string | undefined>(undefined);
  const [imgAvailable, setImgAvailable] = useState(false);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const getUserImage = async () => {
      const backup = type === "player" ? helmet : whistle;
      let user;
      if (type === "player") {
        user = currentPlayers?.get(userId);
      } else if (type === "coach") {
        user = currentCoaches?.get(userId);
      } else {
        user = userProfile;
      }
      setImgURL(user?.profileImageUrl || backup);
      setImgAvailable(!!user?.profileImageUrl);
      setAltText(`${user?.firstName} ${user?.lastName}`);
    };

    getUserImage();
  }, [userId, userProfile, currentCoaches, currentPlayers]);

  const sizeToUse = extraSmall
    ? styles.extraSmallIconContainer
    : small
    ? styles.smallIconContainer
    : styles.regularIconContainer;

  return (
    <div className={styles.avatar}>
      <div
        className={`${sizeToUse} ${styles.iconContainer} ${
          imgAvailable ? "" : `${styles.hasBorder}`
        }`}
      >
        <img
          className={imgAvailable ? styles.image : styles.backup}
          src={imgURL}
          alt={altText}
        />
      </div>
      {rankingData !== undefined && (
        <div
          className={`${
            rankCircleStyle
              ? styles.circle
              : small
              ? styles.smallHexagon
              : styles.hexagon
          }`}
        >
          {rankingData}
        </div>
      )}
    </div>
  );
};

export default Avatar;
