import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { GameDataModel } from "../../../../generated/from-api/models/dtos/game-data.model";
import { PostGameResponseModel } from "../../../../generated/from-api/models/dtos/post-game-response.model";
import { QuestionStatisticModel } from "../../../../generated/from-api/models/question-statistic.model";
import { API_BASE_URL } from "../../constants";

export const GameService = {
  /**
   * Retrieve a data of game (i.e. different plays list by gamePlanId or by playCategory) ,
   * @param teamId
   * @param gamePlanId
   * @param playCategory
   * @returns GameData
   */
  GET_GAME_DATA: async function (
    teamId: string,
    gamePlanId?: string,
    playCategory?: string
  ): Promise<GameDataModel> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.GAME}`, {
        params: {
          teamId: teamId, // mandatory
          gamePlanId: gamePlanId,
          playCategory: playCategory,
        },
      })
    ).data;
  },

  /**
   * Use to create question_statistics
   * @param
   */
  POST: async function (
    quizStatistics: Pick<
      QuestionStatisticModel,
      | "playerId"
      | "playId"
      | "formationId"
      | "readinessType"
      | "questionType"
      | "correct"
      | "timeTaken"
    >[],
    gamePlanId?: string
  ): Promise<PostGameResponseModel | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.GAME}`, {
        quizStatistics: quizStatistics,
        gamePlanId: gamePlanId, // this is required when player plays game from gamePlan
      })
    ).data;
  },
};
