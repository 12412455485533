import { DateTime } from "luxon";
import Tooltip from "../../components/Tooltip/Tooltip";
import { LICENSE_TIERS } from "../../generated/from-api/api-constants/license-tier-constants";
import { LicenseUsageModel } from "../../generated/from-api/models/license-usage.model";
import { LicenseHolderModel } from "../../generated/from-api/models/license.model";
import { PaymentStatus } from "./ManageLicensesAdmin";
export const TRIAL_DURATION_IN_DAYS = 30;
export const MAX_DAYS_BEFORE_PAYMENT = 30;
export const AdminUtility = {
  normalizeTier: (tier: string): string => {
    return tier.charAt(0) + tier.slice(1).toLowerCase();
  },

  getEarliestCreationDate: (licenses: LicenseHolderModel[]): DateTime => {
    const creationDates = licenses.map((license) => {
      return DateTime.fromISO(license.created);
    });
    return DateTime.min(...creationDates);
  },

  createdOverXDaysAgo: (created: string, days: number): boolean => {
    const createdDate = new Date(created);
    createdDate.setHours(0, 0, 0, 0);
    const createBeforeDate = new Date();
    createBeforeDate.setDate(createBeforeDate.getDate() - days);
    createBeforeDate.setHours(0, 0, 0, 0);
    return createdDate < createBeforeDate;
  },

  getPaymentCategory: (
    license: LicenseHolderModel,
    created: string,
    orderId: string,
    type: "MANUAL" | "HUBSPOT" = "MANUAL"
  ): PaymentStatus => {
    const isPaid =
      type === "MANUAL" ? license.paid : license.hubspotPaymentStatus;

    // Free tier
    if (license.licenseTierId === LICENSE_TIERS.FREE.id) {
      return PaymentStatus.Free;
    }
    // Trial Tiers
    if (
      license.licenseTierId === LICENSE_TIERS.TRIAL.id ||
      license.licenseTierId === LICENSE_TIERS.PREMIER.id
    ) {
      if (!AdminUtility.createdOverXDaysAgo(created, TRIAL_DURATION_IN_DAYS)) {
        // still on 30 day trial
        return PaymentStatus.Trial;
      } else {
        // 30 days are up
        return PaymentStatus.TrialExpired;
      }
    }
    // Bronze/Silver/Gold/Platinum - PAID
    if (isPaid) {
      // For hubspot, we make an exception and show paid, regardless of the invoice number.
      // the "manual" payment column will show an error that will alert sales of the discrepency
      if (type === "HUBSPOT") {
        return PaymentStatus.Paid;
      }
      if (license.invoiceNumber) {
        return PaymentStatus.Paid; // paid and has valid invoice
      } else {
        return PaymentStatus.PaidNoInvoice;
      }
    }
    // Bronze/Silver/Gold/Platinum - Not Paid
    if (!AdminUtility.createdOverXDaysAgo(created, MAX_DAYS_BEFORE_PAYMENT)) {
      // still within the allowable period
      return PaymentStatus.UnpaidOk;
    } else {
      // overdue payment
      return PaymentStatus.UnpaidAlert;
    }
  },

  countFiltersInUse: (filters: Map<string, string[]>): number => {
    if (!filters) return 0;
    let count = 0;
    filters.forEach((category) => {
      count += category.length;
    });
    return count;
  },

  getPaymentIcon: (
    license: LicenseHolderModel,
    created: string,
    green: JSX.Element,
    yellow: JSX.Element,
    red: JSX.Element,
    trial: JSX.Element,
    type: "MANUAL" | "HUBSPOT" = "MANUAL"
  ): JSX.Element => {
    const isPaid =
      type === "MANUAL" ? license.paid : license.hubspotPaymentStatus;

    // Free tier
    if (license.licenseTierId === LICENSE_TIERS.FREE.id) {
      return <Tooltip tip="Account is on the Free license">{trial}</Tooltip>;
    }
    // Trial Tiers
    if (
      license.licenseTierId === LICENSE_TIERS.TRIAL.id ||
      license.licenseTierId === LICENSE_TIERS.PREMIER.id
    ) {
      if (!AdminUtility.createdOverXDaysAgo(created, TRIAL_DURATION_IN_DAYS)) {
        // still on 30 day trial
        return (
          <Tooltip tip="Account is still on 30 day trial">{trial}</Tooltip>
        );
      } else {
        // 30 days are up
        return (
          <Tooltip tip="Unpaid, account is over 30 days old and is still using Trial license">
            {red}
          </Tooltip>
        );
      }
    }
    // Bronze/Silver/Gold/Platinum - PAID
    if (isPaid) {
      // for hubspot only, we make an exception and show paid, even if the invoice number is missing.
      // the "manual" payment column will show an error if the invoice is left blank
      if (type === "HUBSPOT") {
        return <Tooltip tip="Marked Paid in HubSpot.">{green}</Tooltip>;
      }

      if (license.invoiceNumber) {
        return green; // paid and has valid invoice
      } else {
        return (
          <Tooltip tip="Missing invoice number, but was marked paid">
            {red}
          </Tooltip>
        );
      }
    }
    // Bronze/Silver/Gold/Platinum - Not Paid
    if (!AdminUtility.createdOverXDaysAgo(created, MAX_DAYS_BEFORE_PAYMENT)) {
      // still within the allowable period
      return (
        <Tooltip tip="Unpaid, but account is less than 30 days old">
          {yellow}
        </Tooltip>
      );
    } else {
      // overdue payment
      return (
        <Tooltip tip="Unpaid, and account is over 30 days old">{red}</Tooltip>
      );
    }
  },

  getPaymentBoolean: (paid: string): boolean => {
    if (paid === "Paid") return true;
    return false;
  },

  getActiveTeams: (licenses: LicenseHolderModel[]): number => {
    let activeTeams = 1;
    if (licenses) {
      activeTeams = licenses.filter((data) => {
        return data.assigned;
      }).length;
    }
    return activeTeams;
  },

  getAggregateStats: (stats: LicenseUsageModel[]): LicenseUsageModel => {
    return {
      teamId: "",
      licenseId: "",
      numberOfCoaches: stats.reduce(
        (total, curr) => total + curr.numberOfCoaches,
        0
      ),
      numberOfPlayers: stats.reduce(
        (total, curr) => total + curr.numberOfPlayers,
        0
      ),
      numberOfPlays: stats.reduce(
        (total, curr) => total + curr.numberOfPlays,
        0
      ),
      numberOfGamePlans: 0,
      numberOfCustomQuizzes: stats.reduce(
        (total, curr) => total + curr.numberOfCustomQuizzes,
        0
      ),
      numberOfMediaItems: 0,
      numberOfInstalls: stats.reduce(
        (total, curr) => total + curr.numberOfInstalls,
        0
      ),
      numberOfQuestionsAnswered: stats.reduce(
        (total, curr) => total + curr.numberOfQuestionsAnswered,
        0
      ),
      numberOfQuizQuestionsAnswered: stats.reduce(
        (total, curr) => total + curr.numberOfQuizQuestionsAnswered,
        0
      ),
      numberOfInstallQuestionsAnswered: stats.reduce(
        (total, curr) => total + curr.numberOfInstallQuestionsAnswered,
        0
      ),
      lastDateRecalculatedFromScratch: "",
    };
  },
};
