import * as React from "react";

const ToolbarOrderedListIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.74992 3.66659H0.916585V1.16659H0.083252V0.333252H1.74992V3.66659ZM1.74992 11.5833V11.1666H0.083252V10.3333H2.58325V13.6666H0.083252V12.8333H1.74992V12.4166H0.916585V11.5833H1.74992ZM0.083252 6.16658H1.58325L0.083252 7.91658V8.66658H2.58325V7.83325H1.08325L2.58325 6.08325V5.33325H0.083252V6.16658ZM4.24992 2.83325V1.16658H15.9166V2.83325H4.24992ZM4.24992 12.8333H15.9166V11.1666H4.24992V12.8333ZM15.9166 7.83325H4.24992V6.16659H15.9166V7.83325Z"
    />
  </svg>
);

export default ToolbarOrderedListIcon;
