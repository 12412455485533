import { useState, createContext } from "react";
import { LicenseHolderModel } from "../../../generated/from-api/models/license.model";

export const LicenseHolderContext = createContext<{
  licenseHolders: Map<string, LicenseHolderModel[]> | undefined;
  setLicenseHolders: (licenseHolders: LicenseHolderModel[]) => void;
  clearLicenseHolders: () => void;
}>({
  licenseHolders: undefined,
  setLicenseHolders: () => null,
  clearLicenseHolders: () => null,
});

export const LicenseHolderProvider: React.FC = ({ children }) => {
  const [licenseHolders, setHolders] = useState<
    Map<string, LicenseHolderModel[]> | undefined
  >(undefined);

  const setLicenseHolders = (newLicenseHolders: LicenseHolderModel[]) => {
    const licenseHoldersCopy =
      licenseHolders ?? new Map<string, LicenseHolderModel[]>();

    newLicenseHolders.forEach((newLicenseHolderLicense: LicenseHolderModel) => {
      const existingLicenses = licenseHoldersCopy.get(
        newLicenseHolderLicense.userId
      );
      if (existingLicenses) {
        const licenseIndex = existingLicenses.findIndex(
          (license) => license.id === newLicenseHolderLicense.id
        );
        if (licenseIndex >= 0) {
          existingLicenses[licenseIndex] = newLicenseHolderLicense;
          licenseHoldersCopy.set(
            newLicenseHolderLicense.userId,
            existingLicenses
          );
        } else {
          licenseHoldersCopy.set(newLicenseHolderLicense.userId, [
            ...existingLicenses,
            newLicenseHolderLicense,
          ]);
        }
      } else {
        licenseHoldersCopy.set(newLicenseHolderLicense.userId, [
          newLicenseHolderLicense,
        ]);
      }
    });
    setHolders(licenseHoldersCopy);
  };

  const clearLicenseHolders = () => {
    setHolders(undefined);
  };

  return (
    <LicenseHolderContext.Provider
      value={{
        licenseHolders,
        setLicenseHolders,
        clearLicenseHolders,
      }}
    >
      {children}
    </LicenseHolderContext.Provider>
  );
};
