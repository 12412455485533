import React, { useContext } from "react";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";

import styles from "../Installs.module.scss";
import { InstallsContext } from "../../../shared/shared-with-mobile/providers/installs.provider";
import { InstallModel } from "../../../generated/from-api/models/install.model";
import { INSTALL_SETTINGS } from "../../../utils/web-only-constants";
import Button from "../../../components/Button/Button";

interface InstallDeleteModalProps {
  install: InstallModel;
  onDeleteCallback?: () => void;
  onArchiveCallback?: () => void;
}

const DeleteModal: React.FC<InstallDeleteModalProps> = ({
  install,
  onDeleteCallback,
  onArchiveCallback,
}) => {
  const { LABELS } = INSTALL_SETTINGS;
  const { closeModal, dispatchToast } = useContext(UIContext);
  const { deleteInstall, updateInstall } = useContext(InstallsContext);

  const deleteInstallAction = async () => {
    const response = await APIService.INSTALL.DELETE(install.id || "");
    if (response?.deleted) {
      deleteInstall(install.id || "");
      closeModal();
      dispatchToast({
        type: "success",
        message: `${LABELS.singular_capitalized} deleted.`,
      });
      onDeleteCallback && onDeleteCallback();
    } else {
      dispatchToast({
        type: "error",
        message: `There was a problem deleting the ${LABELS.singular}.`,
      });
    }
  };

  const archiveInstallAction = async () => {
    const updatedInstall = Object.assign({}, install);
    updatedInstall.archived = true;
    const response = await APIService.INSTALL.PUT(updatedInstall);
    if (response?.archived) {
      updateInstall(updatedInstall);
      closeModal();
      dispatchToast({
        type: "success",
        message: `${LABELS.singular_capitalized} archived.`,
      });
      onArchiveCallback && onArchiveCallback();
    } else {
      dispatchToast({
        type: "error",
        message: `There was a problem archiving the ${LABELS.singular}.`,
      });
    }
  };

  const keepAction = () => {
    closeModal();
  };

  return (
    <div>
      <div className={styles.installDeleteModalMessage}>
        <p>
          Are you sure you want to delete the {LABELS.singular}{" "}
          <strong>{install.name}</strong>? This cannot be undone.
        </p>
        <p>
          Tip: You can archive {LABELS.multiple} to hide them from the{" "}
          {LABELS.multiple} list.
        </p>
      </div>
      <div className={styles.installDeleteModalActions}>
        <Button
          theme={"tertiary"}
          size={"small"}
          onClick={deleteInstallAction}
          destructive
        >
          Delete
        </Button>
        <Button theme={"primary"} size={"small"} onClick={archiveInstallAction}>
          Archive
        </Button>
        <Button theme={"primary"} size={"small"} onClick={keepAction}>
          Keep
        </Button>
      </div>
    </div>
  );
};

export default DeleteModal;
