import React, { ReactNode, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import "./Globals.scss";
import styles from "./App.module.scss";
import Login from "../pages/LoginSignup/Login";
import Signup from "../pages/LoginSignup/Signup";
import routes from "../routes";
import PrivateRoute from "./PrivateRoute";
import Modal from "../components/Modal/Modal";
import AppContainer from "../components/AppContainer/AppContainer";
import EmailVerification from "../pages/LoginSignup/EmailVerification";
import ForgotPassword from "../pages/LoginSignup/ForgotPassword";
import ResetPassword from "../pages/LoginSignup/ResetPassword";
import ToastList from "../components/Toast/ToastList";
import { userRoles } from "../shared/shared-with-mobile/constants";
import { APIService } from "../shared/shared-with-mobile/api-client/api.service";
import { TeamContext } from "../shared/shared-with-mobile/providers/team.provider";
import { UIContext } from "../shared/shared-with-mobile/providers/ui.provider";
import { UserContext } from "../shared/shared-with-mobile/providers/user.provider";
import { PlaybookContext } from "../shared/shared-with-mobile/providers/playbook.provider";
import { FormationsContext } from "../shared/shared-with-mobile/providers/formations.provider";
import { TagsContext } from "../shared/shared-with-mobile/providers/tags.provider";
import { GamePlansContext } from "../shared/shared-with-mobile/providers/gamePlans.provider";
import { PlaySetsContext } from "../shared/shared-with-mobile/providers/playSets.provider";
// findPathName remove all url params and path extensions for a url string
// it's called in the map method that creates the adminRoutes variable and also
// on the location variable in order to check for equality between routes.
import { findPathName } from "../utils/functions";
import { LicensesContext } from "../shared/shared-with-mobile/providers/licenses.provider";
import { GamePlanModel } from "../generated/from-api/models/gamePlan.model";
import { ReadinessScoresContext } from "../shared/shared-with-mobile/providers/readinessScores.provider";
import { CoachesContext } from "../shared/shared-with-mobile/providers/coaches.provider";
import { PlayersContext } from "../shared/shared-with-mobile/providers/players.provider";
import { QuizzesContext } from "../shared/shared-with-mobile/providers/quizzes.provider";
import { MediaContext } from "../shared/shared-with-mobile/providers/media.provider";
import { InstallsContext } from "../shared/shared-with-mobile/providers/installs.provider";
import { LicenseHolderContext } from "../shared/shared-with-mobile/providers/licenseHolder.provider";
import { LicenseTiersContext } from "../shared/shared-with-mobile/providers/licenseTiers";
import { auth } from "../shared/shared-with-mobile/api-client/firebase.utils";
import { TagFoldersContext } from "../shared/shared-with-mobile/providers/tagFolders.provider";
import { MediaTagContext } from "../shared/shared-with-mobile/providers/mediaTags.provider";
import MobileMenu from "../components/MobileMenu/MobileMenu";
import ShareCode from "../pages/ShareCode/ShareCode";
import TeamDetails from "../pages/TeamDetails/TeamDetails";
import GlobalModalContainer from "../components/GlobalModalContainer/GlobalModalContainer";

APIService.initAPIService();

const App: React.FC = () => {
  const { modal, modal2nd } = useContext(UIContext);
  const { userProfile, isLoggingOut, setIsLoggingOut } = useContext(
    UserContext
  );
  const {
    teams,
    addTeams,
    currentTeam,
    teamsAreLoaded,
    switchTeam,
    clearTeams,
    setTeamsAreLoaded,
    setLicenseForCurrentTeam,
  } = useContext(TeamContext);
  const {
    setCurrentPlaybook,
    setGlobalPlaybook,
    clearCurrentPlaybook,
  } = useContext(PlaybookContext);
  const {
    setQuizzes,
    setGlobalQuizzes,
    setQuizStats,
    clearQuizzes,
  } = useContext(QuizzesContext);
  const { setFormations, clearFormations } = useContext(FormationsContext);
  const { setAllLicenses, updateLicenses, clearLicenses } = useContext(
    LicensesContext
  );
  const { storeAllTags, storeAllGlobalTags, clearAllTags } = useContext(
    TagsContext
  );
  const { setGamePlans, clearGamePlans } = useContext(GamePlansContext);
  const { setPlaySets, clearPlaySets } = useContext(PlaySetsContext);
  const {
    setPlayerOverallMetrics,
    setReadinessScores,
    setPlayerPlayMetrics,
    setPlayerFormationMetrics,
    setPlayerInstallMetrics,
    setPlayerQuizMetrics,
    setTeamMetricsOverTime,
    setLoading,
    clearReadinessScores,
  } = useContext(ReadinessScoresContext);
  const { replaceCoaches, clearCoaches } = useContext(CoachesContext);
  const { replacePlayers, clearPlayers, currentPlayersAsArray } = useContext(
    PlayersContext
  );
  const {
    setMediaItems,
    setGlobalMediaItems,
    setGlobalAndTeamMediaLoaded,
    clearMediaItems,
  } = useContext(MediaContext);
  const {
    setInstalls,
    setGlobalInstalls,
    setInstallStats,
    clearInstalls,
  } = useContext(InstallsContext);
  const { setLicenseHolders, clearLicenseHolders } = useContext(
    LicenseHolderContext
  );
  const { updateLicenseTiers } = useContext(LicenseTiersContext);

  const { storeAllTagFolders, clearAllTagFolders } = useContext(
    TagFoldersContext
  );

  const { setMediaItemTags, clearMediaItemsTags } = useContext(MediaTagContext);
  const isUserAdmin = userProfile?.role === userRoles.ADMIN;

  const adminRoutes = routes
    .filter((route) => route.isAdmin)
    .map((route) => findPathName(route.path));
  const location = window.location.pathname;
  const [redirect, setRedirect] = useState<ReactNode>(null);

  const [isAdminBrowse, setIsAdminBrowse] = useState<boolean>(false);

  useEffect(() => {
    const admin = localStorage.getItem("isAdminBrowsing");
    if (admin) {
      setIsAdminBrowse(true);
    }
  }, []);

  const handleLogout = () => {
    const adminToken = localStorage.getItem("isAdminBrowsing");
    if (adminToken) {
      localStorage.removeItem("isAdminBrowsing");
    }
    setIsAdminBrowse(false);

    auth.signOut();
    setGlobalAndTeamMediaLoaded(false);
    setIsLoggingOut(true);
    clearContexts();
    clearLicenseHolders();
    clearTeams();
  };

  // get license for current team, store it in context
  const getLicenseForCurrentTeam = async () => {
    if (currentTeam) {
      const limitedLicense = await APIService.LICENSE.GET(
        currentTeam.licenseId
      );

      if (limitedLicense) {
        setLicenseForCurrentTeam(limitedLicense);
      }
    }
  };

  useEffect(() => {
    if (userProfile && currentTeam !== undefined) {
      const pathName = findPathName(location);
      if (
        userProfile.role === userRoles.ADMIN &&
        !adminRoutes.includes(pathName)
      ) {
        // Admins can only visit admin pages
        setRedirect(<Redirect to="/admin" />);
      } else if (
        userProfile.role === userRoles.USER &&
        currentTeam === null &&
        pathName !== "/account"
      ) {
        // Users without teams can only view their account page
        setRedirect(<Redirect to="/account" />);
      } else if (
        userProfile.role === userRoles.USER &&
        adminRoutes.includes(pathName)
      ) {
        // Users can not view admin pages
        setRedirect(<Redirect to="/playbook" />);
      }
    }
  }, [userProfile, currentTeam]);

  useEffect(() => {
    // check local storage for a previously selected team
    let teamId = localStorage.getItem("selectedTeam") || undefined;
    if (teamId && teams.has(teamId)) {
      if (currentTeam?.id !== teamId) {
        switchTeam(teamId);
      }
    } else {
      teamId = Array.from(teams.keys())[0];
      if (teamId) {
        switchTeam(teamId);
        localStorage.setItem("selectedTeam", teamId);
      } else {
        if (teamsAreLoaded) {
          switchTeam(null);
        }
      }
    }
  }, [teams, teamsAreLoaded]);

  useEffect(() => {
    if (!userProfile || isLoggingOut) {
      return;
    }

    let isCancelled = false;
    const loadTeams = async () => {
      const teams = await APIService.TEAM.GET_ALL_TEAMS_VISIBLE_TO_USER(
        userProfile.id
      );

      if (!isCancelled) {
        // Since the web app is only for coaches, we don't need to worry about teams.playsFor
        addTeams([...teams.coachesFor, ...teams.created]);
        setTeamsAreLoaded(true);
      }
    };

    userProfile && loadTeams();

    return () => {
      isCancelled = true;
    };
  }, [userProfile, isLoggingOut]);

  const fetchReadinessScores = async () => {
    try {
      if (currentTeam?.id) {
        const dashboardMetrics = await APIService.DASHBOARD.GET_DASHBOARD_METRICS(
          currentTeam?.id
        );

        const playerOverallMetrics = await APIService.DASHBOARD.GET_PLAYER_OVERALL_METRICS(
          currentTeam?.id
        );

        const playerPlayMetrics = await APIService.DASHBOARD.GET_PLAYER_PLAY_METRICS(
          currentTeam?.id
        );

        const playerFormationMetrics = await APIService.DASHBOARD.GET_PLAYER_FORMATION_METRICS(
          currentTeam?.id
        );

        const playerQuizMetrics = await APIService.DASHBOARD.GET_PLAYER_QUIZ_METRICS(
          currentTeam?.id
        );

        const playerInstallMetrics = await APIService.DASHBOARD.GET_PLAYER_INSTALL_METRICS(
          currentTeam?.id
        );
        const fiveWeeksInMS = 1000 * 60 * 60 * 24 * 7 * 5;
        const teamReadinessOverTime = await APIService.DASHBOARD.GET_TEAM_READINESS_OVER_TIME(
          currentTeam?.id,
          new Date(new Date().getTime() - fiveWeeksInMS).toISOString(),
          new Date().toISOString()
        );
        if (teamReadinessOverTime) {
          setTeamMetricsOverTime(teamReadinessOverTime);
        }

        if (dashboardMetrics.playersWithReadinessScores) {
          // data for dashboard v1
          setReadinessScores(dashboardMetrics.playersWithReadinessScores);
        }

        // data for dashboard v2
        if (playerOverallMetrics) {
          setPlayerOverallMetrics(playerOverallMetrics.overallReadinessList);
        }

        if (playerPlayMetrics) {
          setPlayerPlayMetrics(playerPlayMetrics);
        }

        if (playerFormationMetrics) {
          setPlayerFormationMetrics(playerFormationMetrics);
        }

        if (playerInstallMetrics) {
          setPlayerInstallMetrics(playerInstallMetrics);
        }

        if (playerQuizMetrics) {
          setPlayerQuizMetrics(playerQuizMetrics);
        }

        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      fetchReadinessScores();
    } catch (e) {
      console.log(e);
    }
  }, [currentPlayersAsArray]);

  useEffect(() => {
    if (isLoggingOut) {
      return;
    }

    let isCancelled = false;
    const loadLicenses = async () => {
      if (userProfile && userProfile.email) {
        const allLicenses = isUserAdmin
          ? await APIService.LICENSE.LIST()
          : await APIService.LICENSE.LIST(userProfile.email);

        const userLicenses = allLicenses.filter(
          (license) => license.userId === userProfile.id
        );

        if (!isCancelled) {
          setLicenseHolders(allLicenses);
          updateLicenses(userLicenses);

          if (isUserAdmin) {
            setAllLicenses(allLicenses);
          }
        }
      }
    };

    const loadLicenseTiers = async () => {
      const licenseTiers = await APIService.LICENSE_TIER.LIST();
      if (!isCancelled) {
        updateLicenseTiers(licenseTiers);
      }
    };

    if (userProfile) {
      loadLicenses();
      loadLicenseTiers();
    }

    return () => {
      isCancelled = true;
    };
  }, [teams, isLoggingOut, userProfile, currentTeam]);

  useEffect(() => {
    if (currentTeam === undefined || isLoggingOut) {
      return;
    }

    let isCancelled = false;
    const teamId =
      userProfile?.role === userRoles.USER ? currentTeam?.id : undefined;

    // check license status for this team...

    getLicenseForCurrentTeam();

    const fetchPlaybook = async () => {
      const plays = await APIService.PLAY.LIST(teamId);

      if (!isCancelled) {
        setCurrentPlaybook(plays);
      }

      if (teamId) {
        const globalPlays = await APIService.PLAY.LIST();

        if (!isCancelled) {
          setGlobalPlaybook(globalPlays);
        }
      }
    };

    const fetchFormations = async () => {
      const fetchedTeamFormations = teamId
        ? await APIService.FORMATION.LIST(teamId)
        : [];
      const fetchedGlobalFormations = await APIService.FORMATION.LIST();

      if (!isCancelled) {
        setFormations([...fetchedGlobalFormations, ...fetchedTeamFormations]);
      }
    };

    const fetchTags = async () => {
      if (teamId) {
        const fetchedTags = await APIService.TAG.GET_TAGS_FOR_TEAM(teamId);
        if (fetchedTags && !isCancelled) {
          storeAllTags(fetchedTags);
        }
        const globalTags = await APIService.TAG.GET_TAGS_FOR_TEAM(null);
        if (globalTags && !isCancelled) {
          storeAllGlobalTags(globalTags);
        }
      } else if (!teamId && userProfile?.role === "ADMIN") {
        const globalTags = await APIService.TAG.GET_TAGS_FOR_TEAM(null);
        if (globalTags && !isCancelled) {
          storeAllTags(globalTags);
        }
      }
    };

    const fetchTagFolders = async () => {
      const fetchedTagFolderss = await APIService.TAG_FOLDER.GET_TAG_FOLDERS_FOR_TEAM(
        teamId ? teamId : undefined
      );
      if (fetchedTagFolderss && !isCancelled) {
        storeAllTagFolders(fetchedTagFolderss);
      }
    };

    const fetchMediaTags = async () => {
      const mediaTagsData = await APIService.MEDIA_TAG.LIST(
        teamId ? teamId : undefined
      );
      if (mediaTagsData && !isCancelled) {
        setMediaItemTags(mediaTagsData);
      }
    };

    const fetchGamePlans = async () => {
      if (teamId) {
        const gamePlanData = await APIService.GAME_PLAN.GET_ALL_GAME_PLANS_FOR_TEAM(
          teamId
        );

        // TODO: make a new API route to return all gamePlans as GamePlanContentModel
        // to prevent having to make a seprate API call for each gamePlan
        if (gamePlanData) {
          const promises = gamePlanData.map(
            async (gamePlan: GamePlanModel) =>
              await APIService.GAME_PLAN.GET(gamePlan.id as string)
          );

          const gamePlans = await Promise.all(promises);

          if (gamePlans && !isCancelled) {
            setGamePlans(gamePlans);
          }
        }
      }
    };

    const fetchPlaySets = async () => {
      if (teamId) {
        const playSetData = await APIService.PLAY_SET.GET_ALL_PLAY_SETS_FOR_TEAM(
          teamId
        );
        if (playSetData && !isCancelled) {
          setPlaySets(playSetData);
        }
      }
    };

    const fetchCoaches = async () => {
      if (teamId) {
        const coaches = await APIService.COACH.GET_ALL_COACHES_FOR_TEAM(teamId);

        if (!isCancelled) {
          // Since the web app is only for coaches, we don't need to worry about teams.playsFor
          replaceCoaches(coaches);
        }
      }
    };

    const fetchPlayers = async () => {
      if (teamId) {
        const players = await APIService.PLAYER.GET_ALL_PLAYERS_FOR_TEAM(
          teamId
        );

        if (!isCancelled) {
          // Since the web app is only for coaches, we don't need to worry about teams.playsFor
          replacePlayers(players);
        }
      }
    };

    const fetchQuizzes = async () => {
      const quizzes = await APIService.CUSTOM_QUIZ.LIST(teamId);
      const globalQuizzes = await APIService.CUSTOM_QUIZ.LIST();

      if (!isCancelled) {
        quizzes.sort((a, b) => {
          return a.created && b.created
            ? new Date(a.created).getTime() - new Date(b.created).getTime()
            : -1;
        });
        globalQuizzes.sort((a, b) => {
          return ("" + a.name).localeCompare(b.name);
        });

        setQuizzes(quizzes);
        setGlobalQuizzes(globalQuizzes);
        if (teamId) {
          const quizStats = await APIService.CUSTOM_QUIZ_STATISTIC.LIST(teamId);
          setQuizStats(quizStats);
        }
      }

      if (teamId) {
        const globalQuizzes = await APIService.CUSTOM_QUIZ.LIST();
        if (!isCancelled) {
          globalQuizzes.sort((a, b) => {
            return a.created && b.created
              ? new Date(a.created).getTime() - new Date(b.created).getTime()
              : -1;
          });

          setGlobalQuizzes(globalQuizzes);
        }
      }
    };

    const fetchMedia = async () => {
      const mediaItems = await APIService.MEDIA.LIST(teamId);
      const globalMediaItems = await APIService.MEDIA.LIST();

      if (!isCancelled) {
        // mediaItems may be a team's set, or the global set, depending on if user is a coach or an admin
        setMediaItems(mediaItems);

        if (teamId) {
          // globalMediaItems is only used for coaches to access global media on imported quizzes
          setGlobalMediaItems(globalMediaItems);
        }
        setGlobalAndTeamMediaLoaded(true);
      }
    };

    const fetchInstalls = async () => {
      const installs = await APIService.INSTALL.LIST(teamId);
      const globalInstalls = await APIService.INSTALL.LIST();

      if (!isCancelled) {
        // Sort by due date (to the day) then by title but globalInstalls (or admin) just by name (they aren't really "due")
        installs.sort((a, b) => {
          const sortValue =
            userProfile?.role === "ADMIN"
              ? 0
              : new Date(a.dueDate?.substr(0, 10) || 0).getTime() -
                new Date(b.dueDate?.substr(0, 10) || 0).getTime();
          return sortValue === 0
            ? ("" + a.name).localeCompare(b.name)
            : sortValue;
        });
        globalInstalls.sort((a, b) => {
          return ("" + a.name).localeCompare(b.name);
        });

        setInstalls(installs);
        if (teamId) {
          const installStats = await APIService.INSTALL_STATISTIC.LIST(teamId);
          setInstallStats(installStats);
          setGlobalInstalls(globalInstalls);
        }
      }
    };

    // Clear context prior to re-fetching
    clearContexts();

    const loadAll = async () => {
      try {
        // execute all request in parallel, make sure if one fails - the rest will still execute
        const promises = [
          fetchPlaybook,
          fetchFormations,
          fetchTags,
          fetchGamePlans,
          fetchReadinessScores,
          fetchCoaches,
          fetchPlayers,
          fetchQuizzes,
          fetchMedia,
          fetchInstalls,
          fetchPlaySets,
          fetchTagFolders,
          fetchMediaTags,
        ];
        const wrapInTryCatch = promises.map(async (p) => {
          try {
            // execute request
            await p();
          } catch (e) {
            // catch error - so it does not cancel remaining tasks
          }
        });
        await Promise.all(wrapInTryCatch);
      } catch (e) {}
    };

    loadAll();
    if (currentTeam?.id) {
      localStorage.setItem("selectedTeam", currentTeam.id);
    }

    return () => {
      isCancelled = true;
    };
  }, [currentTeam, isLoggingOut]);

  // Clear out most contexts, except session specific ones (as opposed to team)
  const clearContexts = () => {
    clearCurrentPlaybook();
    clearFormations();
    clearReadinessScores();
    clearGamePlans();
    clearMediaItems();
    clearQuizzes();
    setQuizStats([]);
    clearCoaches();
    clearPlayers();
    clearInstalls();
    clearLicenses();
    clearAllTags();
    clearPlaySets();
    clearAllTagFolders();
    clearMediaItemsTags();
  };

  return (
    <div className={`${styles.app} ${modal.open ? styles.modalOpen : ""}`}>
      <ToastList />
      <Router>
        <Modal {...modal} />
        {modal2nd && <Modal {...modal2nd} />}
        <AppContainer isAdminBrowse={isAdminBrowse} handleLogout={handleLogout}>
          <GlobalModalContainer>
            {redirect}
            <MobileMenu handleLogout={handleLogout} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/shareCode" component={ShareCode} />
            <Route path="/teamDetails" component={TeamDetails} />
            <Route path="/signup" component={Signup} />
            <Route
              path="/signup-verification"
              component={EmailVerification}
              exact
            />
            <Route path="/forgot-password" component={ForgotPassword} exact />
            <Route path="/reset-password" component={ResetPassword} exact />
            <Route
              path="/ios"
              component={() => {
                window.location.href =
                  "https://apps.apple.com/us/app/team-nation/id1569080509";
                return null;
              }}
            />
            <Route
              path="/android"
              component={() => {
                window.location.href =
                  "https://play.google.com/store/apps/details?id=com.moonshotsports.teamnation";
                return null;
              }}
            />
            <Route
              path="/download-mobile-app"
              component={() => {
                const isUserOnIOS = /iPad|iPhone|iPod/.test(
                  navigator.userAgent
                );

                if (isUserOnIOS) {
                  window.location.href =
                    "https://apps.apple.com/us/app/team-nation/id1569080509";
                } else {
                  window.location.href =
                    "https://play.google.com/store/apps/details?id=com.moonshotsports.teamnation";
                }
                return null;
              }}
            />
            <div className={styles.routeContainer}>
              {routes.map((route) => (
                <PrivateRoute
                  isAdminBrowse={isAdminBrowse}
                  handleLogout={handleLogout}
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  offCenter={route.isOffCenter}
                  widePage={route.isWide}
                />
              ))}
            </div>
          </GlobalModalContainer>
        </AppContainer>
      </Router>
    </div>
  );
};

export default App;
