import React, { useEffect, useRef, useState } from "react";
import {
  Editor,
  EditorState,
  RawDraftContentState,
  convertFromRaw,
  convertToRaw,
  RichUtils,
  EditorCommand,
  Modifier,
} from "draft-js";
import "draft-js/dist/Draft.css";
import styles from "./Wysiwyg.module.scss";
import InlineStyleControls from "./InlineStyleControls/InlineStyleControls";
import ListStyleControls from "./ListStyleControls/ListStyleControls";
import FontStyleControls from "./FontStyleControls/FontStyleControls";
import AlignStyleControls from "./AlignStyleControls/AlignStyleControls";
import { EditorTextAlignment } from "../../../../api/src/shared-with-mobile-and-web/models/install/slide-element.model";

interface Props {
  className?: string;
  editorData?: {
    content: RawDraftContentState | null;
    textAlignment: EditorTextAlignment;
  };
  onContentUpdate: (
    content: RawDraftContentState,
    textAlignment: EditorTextAlignment
  ) => void;
  placeholderValue?: string;
  readOnly?: boolean;
  key?: string;
}

const editorCustomStyleMap = {
  white: {
    color: "rgba(255, 255, 255, 1)",
  },
  grey: {
    color: "rgba(171, 171, 171, 1)",
  },
  red: {
    color: "rgba(255, 88, 78, 1)",
  },
  orange: {
    color: "rgba(251, 120, 0, 1)",
  },
  yellow: {
    color: "rgba(255, 204, 61, 1)",
  },
  reef: {
    color: "rgba(200, 255, 175, 1)",
  },
  green: {
    color: "rgba(109, 204, 48, 1)",
  },
  java: {
    color: "rgba(35, 170, 193, 1)",
  },
  blue: {
    color: "rgba(40, 103, 244, 1)",
  },
  rose: {
    color: "rgba(255, 56, 199, 1)",
  },
};

const Wysiwyg: React.FC<Props> = ({
  className = "",
  editorData = { content: null, textAlignment: "left" },
  onContentUpdate,
  placeholderValue = "",
  readOnly = false,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [editorState, setEditorState] = useState<EditorState>(
    editorData.content
      ? EditorState.createWithContent(convertFromRaw(editorData.content))
      : EditorState.createEmpty()
  );

  const [isToolbarVisible, setToolbarVisibility] = useState<boolean>(false);
  const [textAlignment, setTextAlignment] = useState<EditorTextAlignment>(
    editorData.textAlignment
  );

  useEffect(() => {
    const updatedData = editorState.getCurrentContent();
    const updatedRawData = convertToRaw(updatedData);

    onContentUpdate(updatedRawData, textAlignment);
  }, [editorState.getCurrentContent(), textAlignment]);

  const handleKeyCommand = (
    command: EditorCommand,
    editorState: EditorState
  ): any => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return true;
    }

    return false;
  };

  const onEditorFocus = () => {
    setToolbarVisibility(true);
  };

  const onEditorBlur = (event: any) => {
    setToolbarVisibility(
      editorRef.current
        ? editorRef.current.contains(event.relatedTarget)
        : false
    );
  };

  const handleInlineControlsMouseDown = (
    styleType: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, styleType));
  };

  const handleBlockStyleButtonsMouseDown = (
    blockType: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleFontControlsSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, event.currentTarget.value)
    );
  };

  const handleFontColorSelect = (
    styleType: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    const selection = editorState.getSelection();
    const currentStyle = editorState.getCurrentInlineStyle();

    const nextContentState = Object.keys(editorCustomStyleMap).reduce(
      (contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color);
      },
      editorState.getCurrentContent()
    );

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );

    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state: any, color: any) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }

    if (!currentStyle.has(styleType)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, styleType);
    }

    setEditorState(nextEditorState);
  };

  const handleAlignStyleControlsMouseDown = (
    alignType: EditorTextAlignment
  ) => {
    setTextAlignment(alignType);
  };

  return (
    <div
      ref={editorRef}
      className={`${styles.wysiwyg} ${className}`}
      onFocus={onEditorFocus}
      onBlur={onEditorBlur}
      tabIndex={0}
    >
      <Editor
        editorState={editorState}
        onChange={setEditorState}
        handleKeyCommand={handleKeyCommand}
        spellCheck={true}
        placeholder={placeholderValue}
        textAlignment={textAlignment}
        customStyleMap={editorCustomStyleMap}
        readOnly={readOnly}
      />
      {!readOnly && isToolbarVisible && (
        <div className={styles.wysiwygToolbar}>
          <FontStyleControls
            editorState={editorState}
            onSelectHandler={handleFontControlsSelect}
            onColorSelectHandler={handleFontColorSelect}
          />
          <InlineStyleControls
            editorState={editorState}
            mouseDownHandler={handleInlineControlsMouseDown}
          />
          <AlignStyleControls
            alignType={textAlignment}
            mouseDownHandler={handleAlignStyleControlsMouseDown}
          />
          <ListStyleControls
            editorState={editorState}
            mouseDownHandler={handleBlockStyleButtonsMouseDown}
          />
        </div>
      )}
    </div>
  );
};

export default Wysiwyg;
