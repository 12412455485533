import { useContext, useEffect, useState } from "react";

import styles from "../Playbook.module.scss";
import CharacterInput from "../../../components/Input/CharacterInput";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { PlaybookContext } from "../../../shared/shared-with-mobile/providers/playbook.provider";
import ContentLimitModal from "../../../components/ContentLimitModal/ContentLimitModal";
import CloseIcon from "../../../resources/icons/CloseIcon";
import Button from "../../../components/Button/Button";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";

interface Props {
  plays: Map<string, PlayModel>;
  duplicateNames: string[];
}

const RenamePlays: React.FC<Props> = ({ plays, duplicateNames }) => {
  const { closeModals, dispatchSingleModal, handleCreateError } = useContext(
    UIContext
  );
  const { addPlays } = useContext(PlaybookContext);
  const [selectedPlays, setSelectedPlays] = useState<Map<string, PlayModel>>(
    plays
  );
  const [duplicatePlays, setDuplicatePlays] = useState<Map<string, PlayModel>>(
    new Map()
  );

  useEffect(() => {
    const duplicates = new Map<string, PlayModel>();
    for (const key of Array.from(plays.keys())) {
      const play = plays.get(key);
      if (play && duplicateNames.includes(play.name)) {
        duplicates.set(key, play);
      }
    }
    setDuplicatePlays(duplicates);
  }, []);

  const changePlayName = (newName: string, playId: string) => {
    const duplicatePlaysCopy = new Map(duplicatePlays);
    const playToUpdate = duplicatePlaysCopy.get(playId);

    if (playToUpdate) {
      playToUpdate.name = newName;
      duplicatePlaysCopy.set(playId, playToUpdate);
      setDuplicatePlays(duplicatePlaysCopy);
    }
  };

  const removePlay = (playId: string) => {
    const duplicatePlaysCopy = new Map(duplicatePlays);
    const selectedPlaysCopy = new Map(selectedPlays);
    duplicatePlaysCopy.delete(playId);
    selectedPlaysCopy.delete(playId);
    setDuplicatePlays(duplicatePlaysCopy);
    setSelectedPlays(selectedPlaysCopy);
  };

  const importPlays = async (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedPlays.size === 0) {
      closeModals();
      return;
    }

    const selectedPlaysCopy = new Map(selectedPlays);
    // combine all selected plays for import with the newly renamed plays
    for (const key of Array.from(duplicatePlays.keys())) {
      const play = duplicatePlays.get(key);
      if (play) {
        selectedPlaysCopy.set(key, play);
      }
    }

    const playsArray = Array.from(selectedPlaysCopy.values());

    try {
      const createdPlays = await APIService.PLAY.POST(playsArray);
      if (createdPlays && createdPlays.length > 0) {
        addPlays(createdPlays);
        closeModals();
      }
    } catch (error: any) {
      if (error?.response?.status === 422) {
        handleCreateError(error, ContentLimitModal, "play", "plays");
      } else if (error?.response?.data?.length) {
        const duplicates: string[] = error.response.data;
        dispatchSingleModal({
          title: "We found duplicates",
          open: true,
          size: "medium",
          body: <RenamePlays plays={plays} duplicateNames={duplicates} />,
        });
      }
    }
  };

  return (
    <form onSubmit={importPlays}>
      <span className={styles.duplicatePlaysText}>
        Some of the plays you selected are already in the playbook. Please
        rename the duplicates or remove them.
      </span>
      <div className={styles.duplicatePlaysContainer}>
        {Array.from(duplicatePlays.values()).map((play) => {
          return (
            <div key={play.id} className={styles.duplicatePlay}>
              <CharacterInput
                value={play.name}
                maxLength={100}
                onChange={(e) =>
                  play.id && changePlayName(e.currentTarget.value, play.id)
                }
              />
              <Button
                theme={"transparent"}
                icon={<CloseIcon />}
                onClick={() => play.id && removePlay(play.id)}
              />
            </div>
          );
        })}
      </div>
      <div className="formFooter">
        <Button theme="secondary" size={"small"} onClick={closeModals}>
          Cancel
        </Button>
        <Button size={"small"} buttonType="submit">
          Add Plays
        </Button>
      </div>
    </form>
  );
};

export default RenamePlays;
