import React from "react";
import wysiwygStyles from "../Wysiwyg.module.scss";
import ToolbarAlignLeftIcon from "../../../resources/icons/ToolbarAlignLeftIcon";
import ToolbarAlignCenterIcon from "../../../resources/icons/ToolbarAlignCenterIcon";
import ToolbarAlignRightIcon from "../../../resources/icons/ToolbarAlignRightIcon";
import { EditorTextAlignment } from "../../../generated/from-api/models/install/slide-element.model";

interface Props {
  alignType: EditorTextAlignment;
  mouseDownHandler: (alignType: EditorTextAlignment) => void;
}

const AlignStyleControls: React.FC<Props> = ({
  alignType,
  mouseDownHandler,
}) => {
  return (
    <div>
      <button
        className={`${wysiwygStyles.wysiwygButton} ${
          alignType === "left" && wysiwygStyles.wysiwygButtonActive
        }`}
        type={"button"}
        onMouseDown={() => mouseDownHandler("left")}
      >
        <ToolbarAlignLeftIcon />
      </button>
      <button
        className={`${wysiwygStyles.wysiwygButton} ${
          alignType === "center" && wysiwygStyles.wysiwygButtonActive
        }`}
        type={"button"}
        onMouseDown={() => mouseDownHandler("center")}
      >
        <ToolbarAlignCenterIcon />
      </button>
      <button
        className={`${wysiwygStyles.wysiwygButton} ${
          alignType === "right" && wysiwygStyles.wysiwygButtonActive
        }`}
        type={"button"}
        onMouseDown={() => mouseDownHandler("right")}
      >
        <ToolbarAlignRightIcon />
      </button>
    </div>
  );
};

export default AlignStyleControls;
