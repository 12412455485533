import React, { useState, useContext } from "react";
import classNames from "classnames";
import FileUploadIcon from "../../resources/icons/FileUploadIcon";
import HudlFormatGuideImage from "../../resources/images/hudlFormatGuide.svg";
import FormatOnePlayUnselectedImage from "../../resources/images/formatOnePlayUnselected.svg";
import FormatOnePlaySelectedImage from "../../resources/images/formatOnePlaySelected.svg";
import FormatEightPlayUnselectedImage from "../../resources/images/formatEightPlayUnselected.svg";
import FormatEightPlaySelectedImage from "../../resources/images/formatEightPlaySelected.svg";
import Button from "../Button/Button";
import { FileSizeValidity } from "../../utils/mediaHelpers.utils";

import styles from "./PdfImport.module.scss";
import { PlayTypeEnum } from "../../generated/from-api/models/enums/play-type.enum";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { PlaybookContext } from "../../shared/shared-with-mobile/providers/playbook.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { MediaContext } from "../../shared/shared-with-mobile/providers/media.provider";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import TrashIcon from "../../resources/icons/TrashIcon";
import { PlayModel } from "../../generated/from-api/models/play.model";
import PdfInstructionsSwiper from "./PdfInstructionsSwiper";
import { OnboardingStep } from "../OnboardingModal/OnboardingModal";

interface Props {
  handleCancel: () => void;
  onboardingStep: OnboardingStep;
  setOnboardingStep: (newStep: OnboardingStep) => void;
  showMobileAppLinks?: boolean;
}

const PdfImport: React.FC<Props> = ({
  handleCancel,
  onboardingStep,
  setOnboardingStep,
  showMobileAppLinks = true,
}) => {
  const { dispatchToast } = useContext(UIContext);
  const { addPlays, removePlay } = useContext(PlaybookContext);
  const { currentTeam } = useContext(TeamContext);
  const { addMediaItems, mediaItemsMap, removeMediaItems } = useContext(
    MediaContext
  );

  const [selectedPDF, setSelectedPDF] = useState<File | null>(null);
  const [numPlaysPerPage, setNumPlaysPerPage] = useState<number | null>(null);
  const [playType, setPlayType] = useState<PlayTypeEnum | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [addedMediaPlays, setAddedMediaPlays] = useState<PlayModel[]>([]);

  const validatePdfFileSize: (file: File) => FileSizeValidity = (
    file: File
  ) => {
    const pdfSizeMax = 30 * 1024 * 1024; // 30MB
    const fileType = file.type.split("/")[0]; // application
    const allowedFileTypes = ["application"];

    let errorMessage = "";
    if (fileType === "application" && file.size > pdfSizeMax) {
      errorMessage = "Uploaded PDFs cannot exceed 30MB.";
    } else if (!allowedFileTypes.includes(fileType)) {
      errorMessage = "File type not allowed.";
    }

    return {
      isValid: !errorMessage,
      errorMessage,
    };
  };

  const uploadPdf = async () => {
    const file = selectedPDF as File; // todo refactor
    const obj = validatePdfFileSize(file);
    if (!obj.isValid) {
      dispatchToast({
        type: "error",
        message: obj.errorMessage,
      });
      return;
    }

    if (!currentTeam?.id) {
      dispatchToast({
        type: "error",
        message: "You must be part of a team to import a HUDL PDF.",
      });
      return;
    }

    try {
      const data = new FormData();
      data.append("name", file.name || "");
      data.append("application", file);
      data.append("teamId", currentTeam.id);
      data.append("playType", String(playType));
      data.append("numPlaysPerPage", String(numPlaysPerPage));
      setIsProcessing(true);
      setOnboardingStep(OnboardingStep.PdfImportProcessing);

      const results = await APIService.MEDIA.POST_HUDL_PDF(data);

      if (results.plays) {
        addPlays(results.plays);
        setAddedMediaPlays(results.plays);
      }

      if (results.media) {
        addMediaItems(results.media);
      }
    } catch (error) {
      console.log("Error uploading or processing the PDF.", error);
    } finally {
      setOnboardingStep(OnboardingStep.PdfImportProcessed);
      setIsProcessing(false);
      setSelectedPDF(null);
      setPlayType(null);
      setNumPlaysPerPage(null);
    }
  };

  const deletePlayAndAssociatedMediaItem = async (play: PlayModel) => {
    if (!play || !play.id || !play.mediaId) {
      return;
    }

    try {
      const deletePlayResponse = await APIService.PLAY.DELETE(play.id);

      if (deletePlayResponse.deleted) {
        removePlay(play.id); // (from context)
        removePlayFromList(play.id); // remove from the list UI in this component

        const deleteMediaResponse = await APIService.MEDIA.DELETE(play.mediaId);

        if (deleteMediaResponse.deleted) {
          removeMediaItems([play.mediaId]);
        }
      }
    } catch (e) {
      console.log("Unable to delete play and / or associated media item.", e);
    }
  };

  const removePlayFromList = (playId: string) => {
    const updated = addedMediaPlays.filter((play) => play.id !== playId);
    setAddedMediaPlays(updated);
  };

  const retryPdfImport = () => {
    setOnboardingStep(OnboardingStep.PdfImportStart);
  };

  return (
    <div
      className={classNames(
        styles.pdfImport,
        (onboardingStep === OnboardingStep.PdfImportProcessing ||
          onboardingStep === OnboardingStep.PdfImportProcessed) &&
          styles.noHorizontalPadding
      )}
    >
      {onboardingStep === OnboardingStep.PdfImportStart && (
        <div className={styles.pdfImportStart}>
          <div className={styles.instructions}>
            <div className={styles.header}>Please note:</div>
            <div>
              Export your playbook as a PDF using Hudl’s Print Playbook feature.
            </div>
            <div>
              <img src={HudlFormatGuideImage} />
            </div>
            <div>
              Import that PDF into Team Nation using a single format, either 1
              or 8 cards per page.
            </div>
          </div>
          <div className={styles.bottomButtons}>
            <Button
              width="full"
              onClick={() => {
                setOnboardingStep(OnboardingStep.PdfImportChooseFile);
              }}
            >
              Continue
            </Button>
            <Button theme="tertiary" width="full" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {onboardingStep === OnboardingStep.PdfImportChooseFile && (
        <div className={styles.pdfImportChooseFile}>
          <div className={styles.header}>Upload your playbook from Hudl</div>
          <div className={styles.slideBoxContainer}>
            <PdfInstructionsSwiper />
          </div>
          <label htmlFor="hudl-pdf-importer" className={styles.pdfImportLabel}>
            <input
              multiple
              id="hudl-pdf-importer"
              accept="application/pdf"
              type="file"
              className={styles.pdfImportInput}
              onChange={async (e) => {
                if (e !== null && e.target.files && e.target.files.length) {
                  setSelectedPDF(e.target.files[0]);
                }
              }}
            />
            <div
              className={classNames(
                styles.fileHandler,
                !!selectedPDF && styles.fileSelected
              )}
            >
              {!selectedPDF && <FileUploadIcon />}
              {selectedPDF ? `${selectedPDF.name}` : "Select file for upload"}
            </div>
            <div className={styles.helperText}>
              Supported formats: .pdf, 1 or 8 plays per page
            </div>
          </label>
          <div className={styles.bottomButtons}>
            <Button
              width="full"
              disabled={!selectedPDF}
              onClick={() => {
                setOnboardingStep(OnboardingStep.PdfImportFileFormat);
              }}
            >
              Upload Playbook
            </Button>
            <Button theme="tertiary" width="full" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {onboardingStep === OnboardingStep.PdfImportFileFormat && (
        <div className={styles.pdfImportFileFormat}>
          <div>
            <div className={styles.header}>How are these plays formatted?</div>
            <div className={styles.formatChoices}>
              <img
                src={
                  numPlaysPerPage === 1
                    ? FormatOnePlaySelectedImage
                    : FormatOnePlayUnselectedImage
                }
                onClick={() => {
                  setNumPlaysPerPage(1);
                }}
              />
              <img
                src={
                  numPlaysPerPage === 8
                    ? FormatEightPlaySelectedImage
                    : FormatEightPlayUnselectedImage
                }
                onClick={() => {
                  setNumPlaysPerPage(8);
                }}
              />
            </div>
            <div className={styles.header}>
              Which side of the ball are these plays for?
            </div>
            <div className={styles.playTypeChoices}>
              <Button
                theme="secondary"
                width="full"
                onClick={() => {
                  setPlayType("Offensive");
                }}
                className={classNames(
                  playType === "Offensive" && styles.selected
                )}
              >
                Offense
              </Button>
              <Button
                theme="secondary"
                width="full"
                onClick={() => {
                  setPlayType("Defensive");
                }}
                className={classNames(
                  playType === "Defensive" && styles.selected
                )}
              >
                Defense
              </Button>
            </div>
          </div>
          <div className={styles.bottomButtons}>
            <Button
              width="full"
              onClick={uploadPdf}
              disabled={playType === null || numPlaysPerPage === null}
            >
              Continue
            </Button>
            <Button theme="tertiary" width="full" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {(onboardingStep === OnboardingStep.PdfImportProcessing ||
        onboardingStep === OnboardingStep.PdfImportProcessed) && (
        <div className={styles.pdfImportProcessing}>
          <div className={styles.header}>
            {onboardingStep === OnboardingStep.PdfImportProcessing
              ? "Processing Plays..."
              : `${addedMediaPlays.length} Play${
                  addedMediaPlays.length === 1 ? "" : "s"
                } Processed`}
          </div>
          <div
            className={classNames(
              styles.contentContainer,
              onboardingStep === OnboardingStep.PdfImportProcessed &&
                styles.playsContainer
            )}
          >
            {onboardingStep === OnboardingStep.PdfImportProcessing && (
              <>
                <div className={styles.spinner} />
                <div className={styles.loadingText}>Est. Time: 1 - 2 min</div>
              </>
            )}
            {onboardingStep === OnboardingStep.PdfImportProcessed && (
              <>
                {addedMediaPlays.map((play) => {
                  return (
                    <div className={styles.deleteMediaPlayButton} key={play.id}>
                      <div className={styles.playName}>{play.name}</div>
                      <div className={styles.mediaPlayThumbnailContainer}>
                        <img
                          className={styles.mediaPlayThumbnail}
                          src={mediaItemsMap[play.mediaId as string]?.url}
                        />
                      </div>
                      <div
                        className={styles.trashIconContainer}
                        role="button"
                        onClick={() => {
                          deletePlayAndAssociatedMediaItem(play);
                        }}
                      >
                        <TrashIcon className={styles.trashIcon} />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {!isProcessing && addedMediaPlays.length === 0 && (
              <div className={styles.tryAgain}>
                <div>Something doesn&apos;t look right...</div>
                <Button onClick={retryPdfImport}>Try Again</Button>
              </div>
            )}
          </div>
          <div className={styles.helperText}>
            <div className={styles.mobileText}>
              You can further customize your playbook by logging into Team
              Nation on a desktop computer.
            </div>
            <div className={styles.desktopText}>
              You can further customize your plays from the playbook tab of Team
              Nation.
            </div>
          </div>
          <div className={styles.bottomButtons}>
            <Button
              width="full"
              onClick={() => {
                if (showMobileAppLinks) {
                  setOnboardingStep(OnboardingStep.MobileAppLinks);
                } else {
                  handleCancel(); // not actually cancelling in this case, just closing the modal
                }
              }}
              disabled={isProcessing}
            >
              {showMobileAppLinks ? "Continue" : "Done"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfImport;
