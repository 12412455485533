import { memo, useContext, useEffect, useState } from "react";

import styles from "./ImportInstallsModal.module.scss";
import { InstallModel } from "../../../../generated/from-api/models/install.model";
import CharacterInput from "../../../../components/Input/CharacterInput";
import { InstallsContext } from "../../../../shared/shared-with-mobile/providers/installs.provider";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import { INSTALL_SETTINGS } from "../../../../utils/web-only-constants";
import Button from "../../../../components/Button/Button";

interface Props {
  install: InstallModel | undefined;
  onSubmitCallback: (install: InstallModel) => void;
}

const ImportInstallRename: React.FC<Props> = ({
  install,
  onSubmitCallback,
}) => {
  const { LABELS } = INSTALL_SETTINGS;
  const { installs } = useContext(InstallsContext);
  const { closeModal } = useContext(UIContext);
  const [renamed, setRenamed] = useState<string>(install ? install.name : "");
  const [canAdd, setCanAdd] = useState<boolean>(false);

  const handleOnClose = () => {
    closeModal();
  };

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (install) {
      install.name = renamed;
      onSubmitCallback(install);
      closeModal();
    }
  };

  const handleRename = (name: string) => {
    setRenamed(name);
  };

  useEffect(() => {
    if (install) {
      setRenamed(install.name);
    }
  }, [install]);

  useEffect(() => {
    const addable: boolean =
      install &&
      renamed.length > 0 &&
      installs.every((item: InstallModel) => item.name !== renamed)
        ? true
        : false;
    setCanAdd(addable);
  }, [renamed]);

  return (
    <form
      className={`${styles.renameInstallForm} 
      ${install ? styles.open : ""}
      ${!install ? styles.closing : ""}`}
      onSubmit={handleOnSubmit}
    >
      <span className={styles.renameInstallHeader}>
        This {LABELS.singular} already exists
      </span>
      <span className={styles.renameInstallText}>
        The {LABELS.singular} you selected is already in your playbook. Give it
        a new name to avoid duplicates.
      </span>
      <div className={styles.renameInstallContainer}>
        <CharacterInput
          value={renamed}
          onChange={(e) => handleRename(e.currentTarget.value)}
          maxLength={255}
        />
      </div>
      <div className="formFooter">
        <Button theme="secondary" size="small" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          size="small"
          buttonType={"submit"}
          disabled={!canAdd}
        >{`Add ${LABELS.singular_capitalized}`}</Button>
      </div>
    </form>
  );
};

export default memo(ImportInstallRename);
