import { KonvaEventObject } from "konva/types/Node";
import { useContext, useRef, useState, useEffect } from "react";
import { Stage, Layer, Line, Rect, Group } from "react-konva";
import { RouteComponentProps, useHistory } from "react-router";
import { match } from "react-router-dom";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import noop from "lodash/noop";
import { v4 as uuidv4 } from "uuid";

import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import {
  getRelativeCoordinates,
  useContainerDimensions,
  translateLineByPoint,
  lockNumberInRange,
  convertRelativeLineToAbsolute,
  convertPlayerToShape,
  convertPlayToFormation,
  flipPlayersByDimension,
  snapLineAngle,
  snapLineToNearestVerticalYard,
  playerOrderSort,
  hexToRgbA,
  reduceAlpha,
} from "../../shared/shared-with-mobile/play-editor/playEditor.utils";
import Shape from "../../shared/shared-with-mobile/play-editor/Shape";
import FieldBackground from "../../shared/shared-with-mobile/play-editor/FieldBackground";
import EditPlayToolbar from "../../components/drawing/EditPlayToolbar";
import PlaySettingsToolbar from "../../components/drawing/PlaySettingsToolbar";
import { colors } from "../../shared/shared-with-mobile/play-editor/play-editor-colors";
import { PlaybookContext } from "../../shared/shared-with-mobile/providers/playbook.provider";
import {
  KonvaDragHandler,
  ShapeModel,
} from "../../shared/shared-with-mobile/play-editor/drawing.types";
import styles from "./EditPlay.module.scss";
import {
  DrawablePlayerModel,
  FillPattern,
} from "../../generated/from-api/models/drawable/drawablePlayer.model";
import {
  LineCapEnum,
  LineSegmentsModel,
  LineTypeEnum,
} from "../../generated/from-api/models/drawable/lineSegments.model";
import { PlayModel } from "../../generated/from-api/models/play.model";
import { FormationModel } from "../../generated/from-api/models/drawable/formation.model";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import {
  BASE_PLAY,
  lineOfScrimmageY,
} from "../../shared/shared-with-mobile/play-editor/playEditor.constants";
import SaveAsFormationModal from "./SaveAsFormationModal/SaveAsFormationModal";
import CoverageZone from "../../shared/shared-with-mobile/play-editor/CoverageZone";
import { PlaySetsContext } from "../../shared/shared-with-mobile/providers/playSets.provider";
import EditMediaPlay from "./EditMediaPlay";
import { PlayTypeEnum } from "../../generated/from-api/models/enums/play-type.enum";
import PlayerChangingFieldOverlay from "../../components/drawing/PlayerChangingFieldOverlay/PlayerChangingFieldOverlay";
import { useLocallySyncedState } from "../../utils/locallySyncedState";

interface Props extends RouteComponentProps {
  match: match<MatchParams>;
  isAdminBrowse: boolean;
}
interface MatchParams {
  id: string;
}

const lineTypeOptions: LineTypeEnum[] = ["solid", "dashed", "zigzag"];
const lineCapOptions: LineCapEnum[] = ["arrow", "flat", "circle"];
const debounceTime = 2000;

const EditPlay: React.FC<Props> = ({ match, isAdminBrowse }) => {
  const { currentPlaybook, refreshPlay } = useContext(PlaybookContext);
  const playId = match.params.id;
  const history = useHistory();
  const stateData = history.location.state as any;

  const loadedPlay =
    currentPlaybook &&
    currentPlaybook.find((play: PlayModel) => play.id === playId);

  const [hasLoadedPlay, setHasLoadedPlay] = useState<boolean>(!!loadedPlay);

  const [play, setPlay] = useState<Omit<PlayModel, "drawablePlayers">>(
    loadedPlay || BASE_PLAY
  );
  const [players, setPlayers] = useState<DrawablePlayerModel[]>(
    loadedPlay ? loadedPlay.drawablePlayers : []
  );

  const [isRouteDrawingEnabled, setIsRouteDrawingEnabled] = useState<boolean>(
    false
  );

  const [routePreviewCoordinates, setRoutePreviewCoordinates] = useState<
    number[] | null
  >(null);

  const [selectedLineType, setSelectedLineType] = useState<LineTypeEnum>(
    lineTypeOptions[0]
  );
  const [selectedLineCap, setSelectedLineCap] = useState<LineCapEnum>(
    lineCapOptions[0]
  );
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<string[] | null>(
    null
  );
  const [draggedPlayerId, setDraggedPlayerId] = useState<string | null>(null);

  const [
    shouldSnapLineToAngle,
    setShouldSnapLineToAngle,
  ] = useLocallySyncedState("shouldSnapToLineAngle", true);
  const [
    shouldSnapLineToNearestYard,
    setShouldSnapLineToNearestYard,
  ] = useLocallySyncedState("shouldSnapLineToNearestYard", true);

  // selection box state
  const [isDrawingSelectionBox, setIsDrawingSelectionBox] = useState(false);
  const [selectionBoxFirstCorner, setSelectionBoxFirstCorner] = useState<
    number[] | null
  >(null);
  const [selectionBoxSecondCorner, setSelectionBoxSecondCorner] = useState<
    number[] | null
  >(null);

  // not actually application state, but we need to save a snapshot of data for reference when repositioning all players
  const [playersCopyOnDragStart, setPlayersCopyOnDragStart] = useState<
    DrawablePlayerModel[] | null
  >(null);

  const stageContainerRef = useRef<HTMLDivElement>(null);
  const {
    containerWidth: stageWidth,
    containerHeight: stageHeight,
  } = useContainerDimensions(stageContainerRef);

  const { dispatchModal } = useContext(UIContext);
  const { setReopenPlaySetModal } = useContext(PlaySetsContext);

  const [locationKeys, setLocationKeys] = useState<any>([]);

  const [isPlayerChanging, setIsPlayerChanging] = useState<boolean>(false);
  const [
    playerToBeAdded,
    setPlayerToBeAdded,
  ] = useState<DrawablePlayerModel | null>(null);

  const [
    hasTransformedPlayerIds,
    setHasTransformedPlayerIds,
  ] = useState<boolean>(false);

  // As of March 2023, we are beginning to support more or less than 11 players, or 22 players on the field at one time
  // So we need to start using actual unique ids for each player, instead of just integers starting at 1, to prevent bugs
  // this useEffect will transform player ids to uuidv4 if needed
  useEffect(() => {
    if (!players || hasTransformedPlayerIds) {
      return;
    }

    const nextPlayers = players.map((player) => ({
      ...player,
      id:
        player.id.length < 10 && player.id !== "football"
          ? uuidv4()
          : player.id,
    }));

    setHasTransformedPlayerIds(true);
    setPlayers(nextPlayers);
  }, [players, hasTransformedPlayerIds]);

  // Not the author, but this effect appears to be responsible for managing the state of a play set modal
  // I believe the intended effect is that if a user navigates to EditPlay screen by way of clicking on a play from the play set modal
  // then navigating BACK to the playbook screen from that play should trigger a re-open of the same playset modal
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]: any) => keys);
          // Handle forward event
        } else {
          setLocationKeys((keys: any) => [location.key, ...keys]);
          // Handle back event

          if (stateData && stateData.isPlaySetEditModal) {
            setReopenPlaySetModal(stateData.isPlaySetEditModal);
          } else {
            setReopenPlaySetModal(false);
          }
        }
      }
    });
  }, [locationKeys]);

  // this useEffect is resposible for initializing state from the matching play in the playbook
  useEffect(() => {
    if (currentPlaybook && !hasLoadedPlay) {
      const loadedPlay =
        currentPlaybook &&
        currentPlaybook.find((play: PlayModel) => play.id === playId);

      if (loadedPlay) {
        setPlay(loadedPlay);
        setPlayers(loadedPlay.drawablePlayers);
        setHasLoadedPlay(true);
      }
    }
  }, [currentPlaybook]);

  // purpose of this useEffect is to select the appropriate lineCap
  // as different players (with different positions) are selected
  useEffect(() => {
    if (!selectedPlayerIds || selectedPlayerIds.length !== 1) {
      return;
    }

    // set selected line cap equal to the selected player's existing line cap if a player route exists
    const selectedPlayer = players.find((p) => p.id === selectedPlayerIds[0]);
    if (selectedPlayer?.route) {
      setSelectedLineCap(selectedPlayer.route.lineCap);
      return;
    }

    // here we make an assumption that any player without a role will likely be blocking
    if (
      selectedPlayer?.role ||
      selectedPlayer?.position === "QB" ||
      selectedPlayer?.id === "football"
    ) {
      setSelectedLineCap("arrow");
    } else {
      setSelectedLineCap("flat");
    }
  }, [selectedPlayerIds]);

  // this use effect calls the selectPlayers function when it detects the user has just finished drawing a selection box
  useEffect(() => {
    if (!isDrawingSelectionBox) {
      if (selectionBoxFirstCorner && selectionBoxSecondCorner) {
        selectPlayers(selectionBoxFirstCorner, selectionBoxSecondCorner);
      }

      setSelectionBoxFirstCorner(null);
      setSelectionBoxSecondCorner(null);
    }
  }, [isDrawingSelectionBox]);

  // this use effect will make sure state for routeDrawingEnabled toggles itself off when it is not relevant
  useEffect(() => {
    if (selectedPlayerIds?.length !== 1) {
      setIsRouteDrawingEnabled(false);
    }
  }, [selectedPlayerIds]);

  // this effect saves the play (with a debounce), when either state "play" or "players" changes
  useEffect(() => {
    if (play.id) {
      debouncedUpdatePlay.current({
        ...play,
        drawablePlayers: players,
      });
    }
  }, [play, players]);

  const updatePlay = async (playToUpdate: PlayModel) => {
    const updated = await APIService.PLAY.PUT(playToUpdate);
    if (updated) {
      refreshPlay(updated);
    }
  };
  const debouncedUpdatePlay = useRef(
    debounce((playToUpdate: PlayModel) => {
      updatePlay(playToUpdate);
    }, debounceTime)
  );

  const handleStageMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const { clientX, clientY } = e.evt;
    handleStageDown(clientX, clientY, e.target);
  };

  const handleStageMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const { clientX, clientY } = e.evt;
    handleStageMove(clientX, clientY, e.target);
  };

  const handleStageMouseUp = (e: KonvaEventObject<MouseEvent>) => {
    const { clientX, clientY } = e.evt;
    handleStageUp(clientX, clientY, e.target);
  };

  const handleStageMouseLeave = () => {
    setRoutePreviewCoordinates(null);
    setIsDrawingSelectionBox(false);
    setPlayerToBeAdded(null);
  };

  const handleStageMove = (clientX: number, clientY: number, target: any) => {
    if (
      !stageContainerRef.current ||
      (!isDrawingSelectionBox && !isPlayerChanging && !isRouteDrawingEnabled)
    ) {
      return;
    }

    const coordinates = getRelativeCoordinates(
      clientX,
      clientY,
      stageContainerRef.current
    );

    if (isDrawingSelectionBox) {
      setSelectionBoxSecondCorner(coordinates);
    } else if (isPlayerChanging) {
      showPlayerToBeAdded(coordinates, target);
    } else if (isRouteDrawingEnabled) {
      const targetName = target.__proto__.nodeType;

      if (targetName === "Stage") {
        setRoutePreviewCoordinates(coordinates);
      } else {
        setRoutePreviewCoordinates(null);
      }
    }
  };

  const showPlayerToBeAdded = (coordinates: number[], target: any) => {
    const targetName = target.__proto__.nodeType;

    if (targetName === "Stage") {
      const newPlayerPlayType: PlayTypeEnum =
        coordinates[1] >= lineOfScrimmageY
          ? play.playType === "Offensive"
            ? "Offensive"
            : "Defensive"
          : play.playType === "Offensive"
          ? "Defensive"
          : "Offensive";

      const tempPlayer: DrawablePlayerModel = {
        x: coordinates[0],
        y: coordinates[1],
        id: uuidv4(),
        fillPattern: getMostCommonFill(newPlayerPlayType),
        color: reduceAlpha(
          hexToRgbA(getMostCommonColor(newPlayerPlayType)),
          0.5
        ),
        playType: newPlayerPlayType,
      };

      setPlayerToBeAdded(tempPlayer);
    } else {
      setPlayerToBeAdded(null);
    }
  };

  const handleStageTouchStart = (e: KonvaEventObject<TouchEvent>) => {
    const { clientX, clientY } = e.evt.changedTouches[0];
    handleStageDown(clientX, clientY, e.target);
  };

  const handleStageTouchEnd = (e: KonvaEventObject<TouchEvent>) => {
    const { clientX, clientY } = e.evt.changedTouches[0];
    handleStageUp(clientX, clientY, e.target);
  };

  const handleStageTouchMove = (e: KonvaEventObject<TouchEvent>) => {
    const { clientX, clientY } = e.evt.changedTouches[0];
    handleStageMove(clientX, clientY, e.target);
  };

  const getMostCommonColor = (playType: PlayTypeEnum): string => {
    const counts: Record<string, number> = {};
    let maxNum = 0;
    let whichColor = colors.gray;

    for (const player of players.filter((p) => p.playType === playType)) {
      if (counts[player.color] === undefined) {
        counts[player.color] = 1;
      } else {
        counts[player.color]++;
      }

      if (counts[player.color] > maxNum) {
        maxNum = counts[player.color];
        whichColor = player.color;
      }
    }

    return whichColor;
  };

  const getMostCommonFill = (playType: PlayTypeEnum): FillPattern => {
    const counts: Record<string, number> = {};
    let maxNum = 0;
    let whichFill: FillPattern = "filled";

    for (const player of players.filter((p) => p.playType === playType)) {
      if (counts[player.fillPattern] === undefined) {
        counts[player.fillPattern] = 1;
      } else {
        counts[player.fillPattern]++;
      }

      if (counts[player.fillPattern] > maxNum) {
        maxNum = counts[player.fillPattern];
        whichFill = player.fillPattern;
      }
    }

    return whichFill;
  };

  const addOrRemovePlayer = (target: any, coordinates: number[]) => {
    const targetName = target.__proto__.nodeType;

    if (targetName === "Shape" && target?.id()) {
      const nextPlayers = players.filter((player) => {
        if (player.id === target.id()) {
          if (
            player.playType === play.playType &&
            players.filter((p) => p.playType === play.playType).length <= 1
          ) {
            // prevent removing the last offensive player of an offensive play (or the last defensive player of a defensive play)
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

      setPlayers(nextPlayers);
      return;
    }

    if (targetName === "Stage") {
      const newPlayerPlayType: PlayTypeEnum =
        coordinates[1] >= lineOfScrimmageY
          ? play.playType === "Offensive"
            ? "Offensive"
            : "Defensive"
          : play.playType === "Offensive"
          ? "Defensive"
          : "Offensive";

      const newPlayer: DrawablePlayerModel = {
        x: coordinates[0],
        y: coordinates[1],
        id: uuidv4(),
        fillPattern: getMostCommonFill(newPlayerPlayType),
        color: getMostCommonColor(newPlayerPlayType),
        playType: newPlayerPlayType,
      };
      const nextPlayers = [...players, newPlayer];
      setPlayers(nextPlayers);
    }
  };

  // handleStageUp will either
  // select a single player, or unselect a player if the singly selected player is clicked again
  // OR draw a route when a single player is selected
  // OR finish drawing a selection box to select multiple players
  // OR do nothing if no player is selected or clicked
  const handleStageUp = (clientX: number, clientY: number, target: any) => {
    if (!stageContainerRef.current) {
      return;
    }

    setIsDrawingSelectionBox(false);

    const targetName = target.__proto__.nodeType;

    const coordinates = getRelativeCoordinates(
      clientX,
      clientY,
      stageContainerRef.current
    );

    if (isPlayerChanging) {
      addOrRemovePlayer(target, coordinates);
      return;
    }

    if (targetName === "Shape") {
      if (
        !selectedPlayerIds || // none are selected
        selectedPlayerIds.length !== 1 || // multiple are selected
        selectedPlayerIds[0] !== target.id() // one is selected, and it's different than the clicked target
      ) {
        setSelectedPlayerIds([target.id()]);
      } else {
        // exactly one is selected, and it's the same as the click target
        setSelectedPlayerIds(null);
      }
      return;
    }
  };

  // handleStageDown will either
  // draw a route when a player is already selected
  // or start drawing a selection box
  const handleStageDown = (clientX: number, clientY: number, target: any) => {
    if (!stageContainerRef.current) {
      return;
    }

    const targetName = target.__proto__.nodeType;

    const coordinates = getRelativeCoordinates(
      clientX,
      clientY,
      stageContainerRef.current
    );

    if (isPlayerChanging) {
      return;
    }

    if (
      targetName === "Stage" &&
      selectedPlayerIds &&
      selectedPlayerIds.length === 1 &&
      isRouteDrawingEnabled
    ) {
      const nextPlayers = players.map((player) => {
        if (player.id !== selectedPlayerIds[0]) {
          return player;
        }

        return addOrExtendPlayerRoute(player, coordinates);
      });

      setPlayers(nextPlayers);
      return;
    }

    // start drawing selection box to select multiple players
    if (targetName === "Stage") {
      setIsDrawingSelectionBox(true);
      setSelectionBoxFirstCorner(coordinates);
      setSelectionBoxSecondCorner(coordinates);
    }
  };

  const addOrExtendPlayerRoute = (
    player: DrawablePlayerModel,
    coordinates: number[]
  ): DrawablePlayerModel => {
    let nextRoute: LineSegmentsModel;
    if (!player.route) {
      // form a new route
      const newRouteLine = [player.x, player.y].concat(coordinates);
      nextRoute = {
        line: snapLine(newRouteLine),
        lineTypes: [selectedLineType],
        lineCap: selectedLineCap,
      };
    } else {
      // extend an existing route
      const nextRouteLine = player.route.line.concat(coordinates);
      nextRoute = {
        line: snapLine(nextRouteLine),
        lineTypes: [...player.route.lineTypes, selectedLineType],
        lineCap: selectedLineCap,
      };
    }

    return {
      ...player,
      route: nextRoute,
    };
  };

  const selectPlayers = (firstCorner: number[], secondCorner: number[]) => {
    let nextSelectedPlayerIds: string[] = [];

    const [x1, y1] = firstCorner;
    const [x2, y2] = secondCorner;

    const leftEdgeX = Math.min(x1, x2);
    const rightEdgeX = Math.max(x1, x2);
    const topEdgeY = Math.min(y1, y2);
    const bottomEdgeY = Math.max(y1, y2);

    // select all players
    for (const player of players) {
      const leeway = 0.01;

      if (
        player.x >= leftEdgeX - leeway &&
        player.x <= rightEdgeX + leeway &&
        player.y >= topEdgeY - leeway &&
        player.y <= bottomEdgeY + leeway
      ) {
        nextSelectedPlayerIds.push(player.id);
      }
    }

    // manipulate selection to ensure that we are only selecting either all team players or all opponent players
    // and not a mix of both
    const teamPlayerIds = players
      .filter((player) => player.playType === play.playType)
      .map((player) => player.id);
    const opponentPlayerIds = players
      .filter((player) => player.playType !== play.playType)
      .map((player) => player.id);

    let selectedTeamCount = 0;
    let selectedOpponentCount = 0;
    for (const id of nextSelectedPlayerIds) {
      if (teamPlayerIds.includes(id)) {
        selectedTeamCount++;
      } else if (opponentPlayerIds.includes(id)) {
        selectedOpponentCount++;
      }
    }

    const shouldSelectOwnTeam = selectedTeamCount >= selectedOpponentCount;

    nextSelectedPlayerIds = nextSelectedPlayerIds.filter((id) =>
      shouldSelectOwnTeam
        ? teamPlayerIds.includes(id)
        : opponentPlayerIds.includes(id)
    );

    setSelectedPlayerIds(
      !!nextSelectedPlayerIds.length ? nextSelectedPlayerIds : null
    );
  };

  const snapLine = (line: number[]) => {
    let snappedLine = line.slice();

    if (shouldSnapLineToAngle) {
      snappedLine = snapLineAngle(snappedLine, stageWidth, stageHeight);
    }

    if (shouldSnapLineToNearestYard) {
      snappedLine = snapLineToNearestVerticalYard(snappedLine, 30);
    }

    return snappedLine;
  };

  const onPlayerDragStart = (e: KonvaEventObject<DragEvent>) => {
    const id = e.target.id();
    setDraggedPlayerId(id);

    let isDraggingMultiplePlayers =
      !!selectedPlayerIds && selectedPlayerIds?.length > 1;

    if (
      !selectedPlayerIds ||
      (!!selectedPlayerIds?.length && !selectedPlayerIds.includes(id))
    ) {
      setSelectedPlayerIds([id]);
      isDraggingMultiplePlayers = false;
    }

    const center = players.find((p) => p.position === "C");
    const isDraggingCenter = center?.id === id;

    if (isDraggingCenter || isDraggingMultiplePlayers) {
      setPlayersCopyOnDragStart(cloneDeep(players));
    }
  };

  const onPlayerDragEnd = () => {
    setDraggedPlayerId(null);
    setPlayersCopyOnDragStart(null);
  };

  const onPlayerDrag: KonvaDragHandler = (position) => {
    const isDraggingCenter = playersCopyOnDragStart !== null;
    if (selectedPlayerIds?.length && selectedPlayerIds.length > 1) {
      return onMultiplePlayerDrag(position);
    } else if (isDraggingCenter) {
      return onCenterDrag(position);
    } else {
      return onRegularPlayerDrag(position);
    }
  };

  const onMultiplePlayerDrag: KonvaDragHandler = (position) => {
    if (!playersCopyOnDragStart) {
      return position;
    }

    const draggedPlayerAtDragStart = playersCopyOnDragStart.find(
      (player) => player.id === draggedPlayerId // huh? what was this doing, and will it still work
    );

    const draggedPlayer = players.find(
      (player) => player.id === draggedPlayerId // huh? what was this doing, and will it still work
    );
    if (!draggedPlayerAtDragStart || !draggedPlayer) {
      return position;
    }

    const isDraggingOpponentPlayer = draggedPlayer?.playType !== play.playType;
    const relXMin = 0;
    const relXMax = 1;
    const relYMin = isDraggingOpponentPlayer ? 0 : lineOfScrimmageY;
    const relYMax = isDraggingOpponentPlayer ? lineOfScrimmageY : 1;

    const selectedPlayers = players.filter((player) =>
      selectedPlayerIds?.includes(player.id)
    );

    const playersXMin = Math.min(...selectedPlayers.map((p) => p.x));
    const playersXMax = Math.max(...selectedPlayers.map((p) => p.x));
    const playersYMin = Math.min(...selectedPlayers.map((p) => p.y));
    const playersYMax = Math.max(...selectedPlayers.map((p) => p.y));
    // if any one of the players is at a boundary
    // we should prevent further movement towards that side
    const shouldPreventXDecrease = playersXMin <= relXMin;
    const shouldPreventXIncrease = playersXMax >= relXMax;
    const shouldPreventYDecrease = playersYMin <= relYMin;
    const shouldPreventYIncrease = playersYMax >= relYMax;

    const { x, y } = position;
    const xIncreasing = x / stageWidth > draggedPlayer.x;
    const xDecreasing = x / stageWidth < draggedPlayer.x;
    const yIncreasing = y / stageHeight > draggedPlayer.y;
    const yDecreasing = y / stageHeight < draggedPlayer.y;

    const lockHorizontalMovement =
      (shouldPreventXIncrease && xIncreasing) ||
      (shouldPreventXDecrease && xDecreasing);

    const lockVerticalMovement =
      (shouldPreventYIncrease && yIncreasing) ||
      (shouldPreventYDecrease && yDecreasing);

    const absXMin = relXMin * stageWidth;
    const absXMax = relXMax * stageWidth;
    const absYMin = relYMin * stageHeight;
    const absYMax = relYMax * stageHeight;

    const absoluteX = lockHorizontalMovement
      ? draggedPlayer.x * stageWidth
      : lockNumberInRange(x, absXMin, absXMax);

    const absoluteY = lockVerticalMovement
      ? draggedPlayer.y * stageHeight
      : lockNumberInRange(y, absYMin, absYMax);

    const relativeX = absoluteX / stageWidth;
    const relativeY = absoluteY / stageHeight;

    // total change in X (relative) since the start of dragging
    const diffX = relativeX - draggedPlayerAtDragStart.x;
    const diffY = relativeY - draggedPlayerAtDragStart.y;

    // use the difference that the dragged player has moved, to apply that same x and y delta to all selected players
    const nextPlayers = playersCopyOnDragStart.map((player) => {
      const nextX = lockNumberInRange(player.x + diffX, relXMin, relXMax);
      const nextY = lockNumberInRange(player.y + diffY, relYMin, relYMax);
      const playerIsSelected = selectedPlayerIds?.includes(player.id);

      return {
        ...player,
        x: playerIsSelected ? nextX : player.x,
        y: playerIsSelected ? nextY : player.y,
        route:
          playerIsSelected && player.route
            ? {
                ...player.route,
                line: translateLineByPoint(player.route.line, [nextX, nextY]),
              }
            : player.route,
      };
    });

    setPlayers(nextPlayers);

    // required by Konva, must return the absoluteX and absoluteY value of dragged entity
    return {
      x: absoluteX,
      y: absoluteY,
    };
  };

  const onRegularPlayerDrag: KonvaDragHandler = ({ x, y }) => {
    const draggedPlayer = players.find((p) => p.id === draggedPlayerId);

    // as of 3/24/2023, this if block should no longer be necessary to prevent bugs
    // but I'm leaving it here anyway as a safeguard
    if (!selectedPlayerIds || selectedPlayerIds.length !== 1) {
      setSelectedPlayerIds(draggedPlayerId ? [draggedPlayerId] : null);
      return {
        x: (draggedPlayer?.x || 0) * stageWidth,
        y: (draggedPlayer?.y || 0) * stageHeight,
      };
    }

    const isDraggingOpponentPlayer = draggedPlayer?.playType !== play.playType;

    const xMin = 0;
    const xMax = stageWidth;
    const yMin = isDraggingOpponentPlayer ? 0 : lineOfScrimmageY * stageHeight;
    const yMax = isDraggingOpponentPlayer
      ? lineOfScrimmageY * stageHeight
      : stageHeight;

    const absoluteX = lockNumberInRange(x, xMin, xMax);
    const absoluteY = lockNumberInRange(y, yMin, yMax);

    const nextX = absoluteX / stageWidth;
    const nextY = absoluteY / stageHeight;

    const nextPlayers = players.map((player) => ({
      ...player,
      x: player.id === selectedPlayerIds[0] ? nextX : player.x,
      y: player.id === selectedPlayerIds[0] ? nextY : player.y,
      route:
        player.id === selectedPlayerIds[0] && player.route
          ? {
              ...player.route,
              line: translateLineByPoint(player.route.line, [nextX, nextY]),
            }
          : player.route,
    }));

    setPlayers(nextPlayers);

    return {
      x: absoluteX,
      y: absoluteY,
    };
  };

  // special behavior when user moves the shape representing the Center
  const onCenterDrag: KonvaDragHandler = (position) => {
    if (!playersCopyOnDragStart) {
      return position;
    }

    const draggedPlayerAtDragStart = playersCopyOnDragStart.find(
      (player) => player.id === selectedPlayerIds?.[0]
    );

    const draggedPlayer = players.find(
      (player) => player.id === selectedPlayerIds?.[0]
    );
    if (!draggedPlayerAtDragStart || !draggedPlayer) {
      return position;
    }

    const playersXMin = Math.min(...players.map((p) => p.x));
    const playersXMax = Math.max(...players.map((p) => p.x));
    // if any one of the players is at a side boundary
    // we should prevent further movement towards that side
    const shouldPreventXDecrease = playersXMin === 0;
    const shouldPreventXIncrease = playersXMax === 1;

    const { x } = position;
    const xIncreasing = x / stageWidth > draggedPlayer.x;
    const xDecreasing = x / stageWidth < draggedPlayer.x;

    const lockHorizontalMovement =
      (shouldPreventXIncrease && xIncreasing) ||
      (shouldPreventXDecrease && xDecreasing);

    const absoluteX = lockHorizontalMovement
      ? draggedPlayer.x * stageWidth
      : lockNumberInRange(x, 0, stageWidth);

    const absoluteY = draggedPlayerAtDragStart.y * stageHeight;

    const relativeX = absoluteX / stageWidth;

    // total change in X (relative) since the start of dragging
    const diffX = relativeX - draggedPlayerAtDragStart.x;

    if (!lockHorizontalMovement) {
      const nextPlayers = playersCopyOnDragStart.map((player) => {
        const nextX = lockNumberInRange(player.x + diffX, 0, 1);

        if (player.playType !== "Offensive") {
          return player;
        }

        return {
          ...player,
          x: nextX,
          route: player.route
            ? {
                ...player.route,
                line: translateLineByPoint(player.route.line, [
                  nextX,
                  player.y,
                ]),
              }
            : player.route,
        };
      });

      setPlayers(nextPlayers);
    }

    return {
      x: draggedPlayer.x * stageWidth,
      y: absoluteY,
    };
  };

  const openSaveAsFormationModal = () => {
    const teamPlayers = players.filter(
      (player) => player.playType === play.playType
    );

    const playToSaveAsFormation = {
      ...play,
      drawablePlayers: teamPlayers,
    };

    const formationToSave: FormationModel = convertPlayToFormation(
      playToSaveAsFormation,
      ""
    );

    dispatchModal({
      title: "Save as Formation",
      open: true,
      body: <SaveAsFormationModal formation={formationToSave} />,
    });
  };

  const flipPlay = () => {
    const nextPlayers = flipPlayersByDimension(players, "x");
    setPlayers(nextPlayers);
  };

  const togglePlayerChanging = () => {
    setSelectedPlayerIds(null);
    setIsPlayerChanging(!isPlayerChanging);
  };

  const getSelectionBoxRectProps = (): {
    x: number; // in Konva, x supplied to Rect will be used for the x value of the top left corner
    y: number; // and y supplied to Rect will be used for the y value of the top left corner
    width: number;
    height: number;
  } => {
    if (!selectionBoxFirstCorner || !selectionBoxSecondCorner) {
      return { x: 0, y: 0, width: 0, height: 0 };
    }

    const [x1, y1] = selectionBoxFirstCorner;
    const [x2, y2] = selectionBoxSecondCorner;

    const topLeftCornerX = Math.min(x1, x2);
    const topLeftCornerY = Math.min(y1, y2);
    const bottomRightCornerX = Math.max(x1, x2);
    const bottomRightCornerY = Math.max(y1, y2);

    const topLeftCornerXAbsolute = topLeftCornerX * stageWidth;
    const topLeftCornerYAbsolute = topLeftCornerY * stageHeight;
    const width = (bottomRightCornerX - topLeftCornerX) * stageWidth;
    const height = (bottomRightCornerY - topLeftCornerY) * stageHeight;

    return {
      x: topLeftCornerXAbsolute,
      y: topLeftCornerYAbsolute,
      width: width,
      height: height,
    };
  };

  const convertPlayerToShapeWithRoutePreview = (
    player: DrawablePlayerModel,
    playType: PlayTypeEnum
  ): ShapeModel => {
    if (
      isRouteDrawingEnabled &&
      player.id === selectedPlayerIds?.[0] &&
      routePreviewCoordinates !== null
    ) {
      const playerWithRoutePreview = addOrExtendPlayerRoute(
        player,
        routePreviewCoordinates
      );

      return convertPlayerToShape(playerWithRoutePreview, playType);
    } else {
      return convertPlayerToShape(player, playType);
    }
  };

  return !play.mediaId ? (
    <div
      className={`${styles.editPlay} ${
        isAdminBrowse ? styles.adminBrowse : ""
      }`}
    >
      <PlaySettingsToolbar
        players={players}
        setPlayers={(players: DrawablePlayerModel[]) => {
          setSelectedPlayerIds(null);
          setPlayers(players);
        }}
        play={play}
        setPlay={setPlay}
        saveAsFormation={openSaveAsFormationModal}
        isPlaySetEditModal={
          stateData && stateData.isPlaySetEditModal
            ? stateData.isPlaySetEditModal
            : false
        }
      />
      <div className={styles.playEditor} style={{ height: `${stageHeight}px` }}>
        {hasLoadedPlay && (
          <EditPlayToolbar
            selectedPlayerIds={selectedPlayerIds || undefined}
            setSelectedPlayerIds={setSelectedPlayerIds}
            players={players}
            flipPlay={flipPlay}
            setPlayers={setPlayers}
            close={() => {
              setSelectedPlayerIds(null);
            }}
            lineTypeOptions={lineTypeOptions}
            lineCapOptions={lineCapOptions}
            selectedLineType={selectedLineType}
            setSelectedLineType={setSelectedLineType}
            selectedLineCap={selectedLineCap}
            setSelectedLineCap={setSelectedLineCap}
            shouldSnapLineToAngle={shouldSnapLineToAngle}
            setShouldSnapLineToAngle={setShouldSnapLineToAngle}
            shouldSnapLineToNearestYard={shouldSnapLineToNearestYard}
            setShouldSnapLineToNearestYard={setShouldSnapLineToNearestYard}
            togglePlayerChanging={togglePlayerChanging}
            isPlayerChanging={isPlayerChanging}
            isRouteDrawingEnabled={isRouteDrawingEnabled}
            setIsRouteDrawingEnabled={setIsRouteDrawingEnabled}
          />
        )}
        <div
          className={styles.stageContainer}
          ref={stageContainerRef}
          style={{ height: `${stageHeight}px` }}
        >
          <div className={styles.stageAbsoluteWrapper}>
            <Stage
              width={stageWidth}
              height={stageHeight}
              onMouseDown={handleStageMouseDown}
              onTouchStart={handleStageTouchStart}
              onTouchMove={handleStageTouchMove}
              onTouchEnd={handleStageTouchEnd}
              onMouseMove={handleStageMouseMove}
              onMouseUp={handleStageMouseUp}
              onMouseLeave={handleStageMouseLeave}
            >
              <Layer>
                <Rect
                  listening={false}
                  fill={colors.fieldBackgroundGreen}
                  x={0}
                  y={0}
                  width={stageWidth}
                  height={stageHeight}
                />
                <FieldBackground
                  stageWidth={stageWidth}
                  stageHeight={stageHeight}
                  ballOn={play.ballOn}
                />
                {isPlayerChanging && (
                  <PlayerChangingFieldOverlay
                    playType={play.playType}
                    stageWidth={stageWidth}
                    stageHeight={stageHeight}
                  />
                )}
                <Line
                  stroke={colors.lineOfScrimmageGray}
                  strokeWidth={3}
                  points={convertRelativeLineToAbsolute(
                    [0, lineOfScrimmageY, 1, lineOfScrimmageY],
                    stageWidth,
                    stageHeight
                  )}
                  listening={false}
                />
                {playerToBeAdded !== null && isPlayerChanging && (
                  <Group listening={false}>
                    <Shape
                      shape={convertPlayerToShape(
                        playerToBeAdded,
                        play.playType
                      )}
                      stageWidth={stageWidth}
                      stageHeight={stageHeight}
                      draggable={false}
                      isDragging={false}
                      isSelected={false}
                    />
                  </Group>
                )}
                {players
                  .filter((player: DrawablePlayerModel) => {
                    if (
                      !player.coverageZoneRadius &&
                      !player.coverageZoneRadiusX
                    ) {
                      return false;
                    }

                    if (!play.hideOpponent) {
                      return true;
                    }

                    return play.playType === player.playType;
                  })
                  .map((player: DrawablePlayerModel) => (
                    <CoverageZone
                      key={player.id}
                      shape={convertPlayerToShape(player)}
                      stageHeight={stageHeight}
                      stageWidth={stageWidth}
                      selectedPlayer={players.find(
                        (s) => s.id === selectedPlayerIds?.[0]
                      )}
                      players={players}
                      setPlayers={setPlayers}
                    />
                  ))}
                {players
                  .filter((player: DrawablePlayerModel) => {
                    if (!play.hideOpponent) {
                      return true;
                    }

                    return play.playType === player.playType;
                  })
                  .sort((a, b) => playerOrderSort(a, b, play.playType))
                  .map((player: DrawablePlayerModel) => (
                    <Shape
                      key={player.id}
                      shape={convertPlayerToShapeWithRoutePreview(
                        player,
                        play.playType
                      )}
                      isPreviewingRoute={
                        isRouteDrawingEnabled &&
                        player.id === selectedPlayerIds?.[0] &&
                        routePreviewCoordinates !== null
                      }
                      stageHeight={stageHeight}
                      stageWidth={stageWidth}
                      draggable={!isPlayerChanging}
                      onDragStart={onPlayerDragStart}
                      onDragEnd={onPlayerDragEnd}
                      dragBoundFunc={onPlayerDrag}
                      isSelected={!!selectedPlayerIds?.includes(player.id)}
                      isDragging={player.id === draggedPlayerId}
                      isPlayerChanging={isPlayerChanging}
                      cannotDelete={
                        player.playType === play.playType &&
                        players.filter((p) => p.playType === play.playType)
                          .length <= 1
                      }
                    />
                  ))}
                {!!selectionBoxFirstCorner && !!selectionBoxSecondCorner && (
                  <Rect
                    listening={false}
                    fill={reduceAlpha(hexToRgbA(colors.lightning), 0.25)}
                    strokeWidth={1}
                    stroke={colors.lightning}
                    {...getSelectionBoxRectProps()}
                  />
                )}
              </Layer>
            </Stage>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${styles.editPlay} ${
        isAdminBrowse ? styles.adminBrowse : ""
      }`}
    >
      <EditMediaPlay
        play={play}
        players={players}
        setPlay={setPlay}
        saveAsFormation={noop}
        setPlayers={setPlayers}
      />
    </div>
  );
};

export default EditPlay;
