import { Arrow } from "react-konva";

import {
  convertRelativeLineToAbsolute,
  getNextPoint,
} from "./playEditor.utils";

interface Props {
  line: number[];
  stageWidth: number;
  stageHeight: number;
  stroke: string;
  strokeWidth: number;
  showArrow: boolean;
}

const ZigzagLine: React.FC<Props> = ({
  line,
  stageWidth,
  stageHeight,
  showArrow,
  strokeWidth,
  stroke,
}) => {
  if (!stageWidth || !stageHeight) {
    return null;
  }

  const [xStart, yStart, xEnd, yEnd] = convertRelativeLineToAbsolute(
    line,
    stageWidth,
    stageHeight
  );

  const zigzagDistance = Math.round(stageWidth / 60); // ~16. Decrease this number for shorter, more frequent zigs and zags
  const arrowCapLength = Math.ceil(stageWidth / 160); // ~ 6
  const arrowCapWidth = Math.ceil(stageWidth / 110); // ~ 9

  const lineDistance = Math.sqrt(
    Math.pow(xEnd - xStart, 2) + Math.pow(yEnd - yStart, 2)
  );

  // prevent infinite loop if xStart === xEnd and yStart === yEnd
  if (lineDistance === 0) {
    return null;
  }

  const xIncreasing = xEnd - xStart > 0;
  const yIncreasing = yEnd - yStart > 0;

  const lineAngle = Math.atan2(yEnd - yStart, xEnd - xStart);
  const zigAngle = lineAngle - Math.PI / 4; // 45 degree angle from lineAngle
  const zagAngle = zigAngle + Math.PI / 2; // perpendicular to zigAngle

  let points = [xStart, yStart];

  // generate our "second to last point" so that the last segment of the zig zag line will be straight
  // i.e. the last segment will follow the original line angle, so that the arrow is pointed correctly
  const secondToLastPoint = getNextPoint(
    xEnd,
    yEnd,
    Math.min(lineDistance * 0.075, 25), // 0.075 here means the last 7.5% of the line will follow the original line angle
    lineAngle + Math.PI
  );

  const [secondToLastX, secondToLastY] = secondToLastPoint;

  let isFirstIteration = true;

  while (true) {
    const useZigAngle = (points.length / 2) % 2 === 0;
    const lastX = points[points.length - 2];
    const lastY = points[points.length - 1];

    // very first zig should only go half the distance, so the original line will perfectly bisect the zigzag
    const distance = isFirstIteration ? zigzagDistance / 2 : zigzagDistance;

    const angle = useZigAngle ? zigAngle : zagAngle;
    const [nextX, nextY] = getNextPoint(lastX, lastY, distance, angle);

    // break the loop when we are generating zig zag points past the end of the line
    if (
      (xIncreasing && nextX > secondToLastX) ||
      (!xIncreasing && nextX < secondToLastX)
    ) {
      if (
        (yIncreasing && nextY > secondToLastY) ||
        (!yIncreasing && nextY < secondToLastY)
      ) {
        break;
      }
    }

    points = points.concat([nextX, nextY]);
    isFirstIteration = false;
  }

  points.splice(-2, 2); // not necessary, but looks better visually with the last zigzag point removed
  points = points.concat([secondToLastX, secondToLastY, xEnd, yEnd]);

  return (
    <Arrow
      points={points}
      listening={false}
      strokeWidth={strokeWidth}
      pointerLength={showArrow ? arrowCapLength : 0}
      pointerWidth={showArrow ? arrowCapWidth : 0}
      stroke={stroke}
      fill={stroke}
    />
  );
};

export default ZigzagLine;
