import React from "react";
import styles from "../installSlide/InstallSlide.module.scss";
import TrashIcon from "../../../../resources/icons/TrashIcon";
import PencilIcon from "../../../../resources/icons/PencilIcon";
import ChevronUpIcon from "../../../../resources/icons/ChevronUpIcon";
import ChevronDownIcon from "../../../../resources/icons/ChevronDownIcon";
import Button from "../../../../components/Button/Button";

interface Props {
  slideType: "TEXT" | "MEDIA" | "PLAY";
  sectionsCount: number;
  isSlideSectionFirst: boolean;
  isSlideSectionLast: boolean;
  onMoveSlideSection: (direction: "up" | "down") => void;
  onEditCallback: () => void;
  onDeleteCallback: () => void;
}

const InstallSlideControls: React.FC<Props> = ({
  slideType,
  sectionsCount,
  isSlideSectionFirst,
  isSlideSectionLast,
  onMoveSlideSection,
  onEditCallback,
  onDeleteCallback,
}) => {
  return (
    <div className={styles.InstallSlideControls}>
      {(slideType === "MEDIA" || slideType === "PLAY") && (
        <div className={styles.InstallSlideControlsButtonWrapper}>
          <Button
            theme={"tertiary"}
            size={"x-small"}
            icon={<PencilIcon />}
            onClick={onEditCallback}
          />
        </div>
      )}
      <div className={styles.InstallSlideControlsButtonWrapper}>
        <Button
          theme={"tertiary"}
          size={"x-small"}
          icon={<TrashIcon />}
          onClick={onDeleteCallback}
        />
      </div>
      {sectionsCount > 1 && (
        <>
          <div className={styles.InstallSlideControlsButtonWrapper}>
            <Button
              theme={"tertiary"}
              size={"x-small"}
              icon={<ChevronUpIcon />}
              onClick={() => onMoveSlideSection("up")}
              disabled={isSlideSectionFirst}
            />
          </div>
          <div className={styles.InstallSlideControlsButtonWrapper}>
            <Button
              theme={"tertiary"}
              size={"x-small"}
              icon={<ChevronDownIcon />}
              onClick={() => onMoveSlideSection("down")}
              disabled={isSlideSectionLast}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default InstallSlideControls;
