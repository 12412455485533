import * as React from "react";

const ToolbarAlignLeftIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 2.16667V0.5H15.5V2.16667H0.5ZM10.5 3.83333H0.5V5.5H10.5V3.83333ZM10.5 10.5H0.5V12.1667H10.5V10.5ZM15.5 8.83333H0.5V7.16667H15.5V8.83333ZM0.5 15.5H15.5V13.8333H0.5V15.5Z"
    />
  </svg>
);

export default ToolbarAlignLeftIcon;
