import React from "react";
import { CircularProgress } from "@material-ui/core";
import styles from "./CircularSpinner.module.scss";

const CircularSpinner: React.FC = () => {
  return (
    <div className={styles.loaderWrapper}>
      <CircularProgress />
    </div>
  );
};

export default CircularSpinner;
