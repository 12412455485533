/* eslint-disable no-console */
import axios from "axios";
import { auth } from "./firebase.utils";
import { UserProfileService } from "./services/user-profile.service";
import { LicenseService } from "./services/license.service";
import { PlayService } from "./services/play.service";
import { PlaySetService } from "./services/play-set.service";
import { UserService } from "./services/user.service";
import { FormationService } from "./services/formation.service";
import { TeamService } from "./services/team.service";
import { CoachService } from "./services/coach.service";
import { PlayerService } from "./services/player.service";
import { InvitationService } from "./services/invitation.service";
import { GameService } from "./services/game.service";
import { TagService } from "./services/tag.service";
import { GamePlanService } from "./services/gamePlan.service";
import { DashboardService } from "./services/dashboard.service";
import { CustomQuizService } from "./services/custom-quiz.service";
import { MediaService } from "./services/media.service";
import { InstallService } from "./services/install.service";
import { InstallStatisticService } from "./services/install-statistic.service";
import { CustomQuizStatisticService } from "./services/custom-quiz-statistic.service";
import { InvitationCodeService } from "./services/invitation-code.service";
import { LicenseTierService } from "./services/license-tier.service";
import { DeviceRegistrationService } from "./services/device-registration.service";
import { UserRepresentativeService } from "./services/user-representative.service";
import { ImpersonationService } from "./services/impersonation.service";
import { NotificationService } from "./services/notification.service";
import { LicenseQuotaService } from "./services/license-quota.service";
import { TagFolderService } from "./services/tag-folder.service";
import { MediaTagsService } from "./services/media-tags.service";
import { VersionService } from "./services/version.service";

export const APIService = {
  USER: UserService,
  USER_PROFILE: UserProfileService,
  LICENSE: LicenseService,
  LICENSE_QUOTA: LicenseQuotaService,
  TEAM: TeamService,
  COACH: CoachService,
  PLAYER: PlayerService,
  PLAY: PlayService,
  PLAY_SET: PlaySetService,
  FORMATION: FormationService,
  INVITATION: InvitationService,
  GAME: GameService,
  TAG: TagService,
  GAME_PLAN: GamePlanService,
  DASHBOARD: DashboardService,
  CUSTOM_QUIZ: CustomQuizService,
  MEDIA: MediaService,
  MEDIA_TAG: MediaTagsService,
  INSTALL: InstallService,
  INSTALL_STATISTIC: InstallStatisticService,
  CUSTOM_QUIZ_STATISTIC: CustomQuizStatisticService,
  INVITATION_CODE: InvitationCodeService,
  LICENSE_TIER: LicenseTierService,
  DEVICE_REGISTRATION: DeviceRegistrationService,
  USER_REPRESENTATIVE: UserRepresentativeService,
  IMPERSONATION: ImpersonationService,
  NOTIFICATION: NotificationService,
  TAG_FOLDER: TagFolderService,
  VERSION: VersionService,

  // utility methods
  initAPIService: (): any => {
    console.log("AUTH INTERCEPTOR SET");
    axios.interceptors.request.use(
      async (config) => {
        if (!auth.currentUser) {
          return config;
        }
        config.headers.Authorization = await auth.currentUser.getIdToken();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};
