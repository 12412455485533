import { memo, useContext, useEffect, useState } from "react";

import styles from "./QuizImportRename.module.scss";
import { CustomQuizModel } from "../../../../../generated/from-api/models/custom-quiz.model";
import CharacterInput from "../../../../../components/Input/CharacterInput";
import { QuizzesContext } from "../../../../../shared/shared-with-mobile/providers/quizzes.provider";
import { UIContext } from "../../../../../shared/shared-with-mobile/providers/ui.provider";
import Button from "../../../../../components/Button/Button";

interface Props {
  quiz?: CustomQuizModel;
  onSubmitCallback: (quiz: CustomQuizModel) => void;
}

const QuizImportRename: React.FC<Props> = ({ quiz, onSubmitCallback }) => {
  const { quizzes } = useContext(QuizzesContext);
  const { closeModal } = useContext(UIContext);
  const [renamed, setRenamed] = useState<string>(quiz ? quiz.name : "");
  const [canAdd, setCanAdd] = useState<boolean>(false);

  const handleOnClose = () => {
    closeModal();
  };

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (quiz) {
      quiz.name = renamed;
      onSubmitCallback(quiz);
      closeModal();
    }
  };

  const handleRename = (name: string) => {
    setRenamed(name);
  };

  useEffect(() => {
    if (quiz) {
      setRenamed(quiz.name);
    }
  }, [quiz]);

  useEffect(() => {
    const addable: boolean =
      quiz &&
      renamed.length > 0 &&
      quizzes.every((item: CustomQuizModel) => item.name !== renamed)
        ? true
        : false;
    setCanAdd(addable);
  }, [renamed]);

  return (
    <form
      className={`${styles.renameQuizForm} 
      ${quiz ? styles.open : ""}
      ${!quiz ? styles.closing : ""}`}
      onSubmit={handleOnSubmit}
    >
      <span className={styles.renameQuizHeader}>This quiz already exists</span>
      <span className={styles.renameQuizText}>
        The quiz you selected is already in your team library. Give it a new
        name to avoid duplicates.
      </span>
      <div className={styles.renameQuizContainer}>
        <CharacterInput
          value={renamed}
          onChange={(e) => handleRename(e.currentTarget.value)}
          maxLength={255}
        />
      </div>
      <div className="formFooter">
        <Button theme="secondary" size="small" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button size="small" buttonType={"submit"} disabled={!canAdd}>
          Add Quiz
        </Button>
      </div>
    </form>
  );
};

export default memo(QuizImportRename);
