import React from "react";
import { Toolbar } from "@material-ui/core";
import Input from "../../../../../components/Input/CharacterInput";
import search from "../../../../../resources/images/search.svg";
import SingleSelect from "../../../../../components/SingleSelect/SingleSelect";
import MultiSelect from "../../../../../components/MultiSelect/MultiSelect";
import styles from "./QuizImportToolbar.module.scss";

import {
  defensivePositions,
  offensivePositions,
  specialTeamsPositions,
} from "../../../../../shared/shared-with-mobile/play-editor/playEditor.constants";

interface TableToolbarProps {
  onSearchChangeCallback: React.Dispatch<React.SetStateAction<string>>;
  clearField?: boolean;
  onPositionsChangeCallback: React.Dispatch<React.SetStateAction<string[]>>;
  positions: string[];
  filters: string[];
  onSortChangeCallback: React.Dispatch<React.SetStateAction<string>>;
  selectedFilter: string;
  searchTerm: string;
  clearInputs: boolean;
}

const QuizImportToolbar = ({
  onSearchChangeCallback,
  clearField,
  onPositionsChangeCallback,
  positions,
  filters,
  onSortChangeCallback,
  selectedFilter,
  searchTerm,
  clearInputs,
}: TableToolbarProps): JSX.Element => {
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const quiz = event.target.value;
    onSearchChangeCallback(quiz);
  };

  return (
    <Toolbar>
      <div className={styles.toolbar}>
        <div className={styles.searchHeight}>
          <Input
            onChange={handleInputChange}
            icon={search}
            iconAlign="left"
            placeholder="Search"
            size="x-small"
            value={searchTerm}
          />
        </div>
        <div className={styles.selects}>
          <MultiSelect
            placeholder="Position Groups: "
            id="positions"
            options={[
              ...offensivePositions,

              ...defensivePositions,

              ...specialTeamsPositions,
            ].map((position) => ({ label: position, value: position }))}
            onChange={(value) => {
              onPositionsChangeCallback([
                ...positions,
                value.currentTarget as any,
              ]);
            }}
            clearField={clearField || clearInputs}
            className={styles.paddingBottom}
          />
          <SingleSelect
            placeholder={`Sort By: ${selectedFilter}`}
            id="sort"
            options={[...filters].map((filter) => {
              return {
                label: filter,
                value: filter,
              };
            })}
            onChange={(e) => onSortChangeCallback(e.currentTarget.value as any)}
            value={selectedFilter}
          />
        </div>
      </div>
    </Toolbar>
  );
};

export default QuizImportToolbar;
