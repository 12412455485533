import React from "react";
import { useContext } from "react";
import styles from "../Installs.module.scss";
import PositionsList from "../../../components/PositionsList/PositionsList";
import PercentageBar from "../../../components/PercentageBar/PercentageBar";
import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";
import { InstallModel } from "../../../generated/from-api/models/install.model";
import { InstallStatisticModel } from "../../../generated/from-api/models/install-statistic.model";
import kebabMenu from "../../../resources/images/kebabMenu.svg";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { InstallsContext } from "../../../shared/shared-with-mobile/providers/installs.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { INSTALL_SETTINGS } from "../../../utils/web-only-constants";

interface ArchiveItemProps {
  data: InstallModel;
  stats: InstallStatisticModel[];
}

const ArchiveItem: React.FC<ArchiveItemProps> = ({ data, stats }) => {
  const { userProfile } = useContext(UserContext);
  const { updateInstall, deleteInstall } = useContext(InstallsContext);
  const { dispatchToast } = useContext(UIContext);
  const { LABELS } = INSTALL_SETTINGS;

  const preparedStats = {
    avgScore: 0,
    finished: 0,
    assigned: 0,
  };
  stats.forEach((statPoint: InstallStatisticModel) => {
    preparedStats.avgScore += statPoint.score * 100;
    preparedStats.finished += statPoint.completedAt === null ? 0 : 1;
    preparedStats.assigned++;
  });
  preparedStats.avgScore = preparedStats.avgScore / stats.length || 0;

  const deleteInstallAction = async () => {
    const response = await APIService.INSTALL.DELETE(data.id || "");
    if (response?.deleted) {
      deleteInstall(data.id || "");
      dispatchToast({
        type: "success",
        message: `${LABELS.singular_capitalized} deleted.`,
      });
    } else {
      dispatchToast({
        type: "error",
        message: `There was a problem deleting the ${LABELS.singular}.`,
      });
    }
  };

  const unarchiveInstall = async () => {
    const installUpdateData = data;
    installUpdateData.archived = false;
    const response = await APIService.INSTALL.PUT(installUpdateData);
    if (response) {
      updateInstall(response);
      dispatchToast({
        type: "success",
        message: `${LABELS.singular_capitalized} Unarchived.`,
      });
    }
  };

  return (
    <li
      key={`archive item ${data.name}`}
      className={`
        ${styles.installsPageArchiveItem}
        ${userProfile?.role === "ADMIN" ? "simplified-admin" : ""}
      `}
    >
      <span className={styles.installsPageArchiveItemCell}>{data.name}</span>

      {userProfile?.role !== "ADMIN" ? (
        <>
          <span className={styles.installsPageArchiveItemCell}>
            {preparedStats.avgScore === 0 ? "—" : preparedStats.avgScore + "%"}
          </span>
          <span
            className={`${styles.installsPageArchiveItemCell} ${styles.installsPageArchiveItemCellBar}`}
          >
            <PercentageBar
              hideText
              positive={preparedStats.finished}
              total={preparedStats.assigned}
            />
          </span>
          <span className={styles.installsPageArchiveItemCell}>
            {preparedStats.finished}
          </span>
          <span className={styles.installsPageArchiveItemCell}>
            {preparedStats.assigned}
          </span>
        </>
      ) : null}

      <span className={styles.installsPageArchiveItemCell}>
        {new Date(data.created || "").toLocaleString("en-US", {
          timeZone: "UTC",
          month: "short",
          day: "numeric",
        })}
      </span>

      {userProfile?.role === "ADMIN" ? (
        <span className={styles.installsPageArchiveItemCell}>
          {new Date(data.modified || "").toLocaleString("en-US", {
            timeZone: "UTC",
            month: "short",
            day: "numeric",
          })}
        </span>
      ) : null}

      {userProfile?.role !== "ADMIN" ? (
        <>
          <span className={styles.installsPageArchiveItemCell}>
            {new Date(data.dueDate || "").toLocaleString("en-US", {
              timeZone: "UTC",
              month: "short",
              day: "numeric",
            })}
          </span>
          <span
            className={`${styles.installsPageArchiveItemCell}  ${styles.installsPageArchiveItemCellList}`}
          >
            <PositionsList
              positions={data.showInstallToPositions}
              short={true}
              theme={"transparent"}
            />
          </span>
        </>
      ) : null}

      <Tooltip
        tip={
          <ol className={styles.installsPageListItemActionOverflow}>
            <li
              key={`archived-install-popup-unarchive`}
              onClick={unarchiveInstall}
            >
              Unarchive
            </li>
            <li
              key={`archived-install-popup-delete`}
              onClick={deleteInstallAction}
            >
              Delete
            </li>
          </ol>
        }
        clickTip
        noArrow
        noPadding
        color={"dark"}
        placement={"bottom"}
      >
        <img
          className={`${styles.installsPageListItemActionButton}`}
          src={kebabMenu}
        />
      </Tooltip>
    </li>
  );
};

export default ArchiveItem;
