import React, { useContext } from "react";
import styles from "./InstallSlide.module.scss";
import textIcon from "../../../../resources/images/text.svg";
import mediaIcon from "../../../../resources/images/media.svg";
import playIcon from "../../../../resources/images/play.svg";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";
import { InstallSlideModel } from "../../../../generated/from-api/models/install/install-slide.model";
import MediaThumbnail from "../../../../components/media/MediaThumbnail/MediaThumbnail";
import { MediaContext } from "../../../../shared/shared-with-mobile/providers/media.provider";
import Wysiwyg from "../../../../components/Wysiwyg/Wysiwyg";
import { PlaybookContext } from "../../../../shared/shared-with-mobile/providers/playbook.provider";
import PlayThumbnail from "../../../../components/drawing/PlayThumbnail";
import { QuizzesContext } from "../../../../shared/shared-with-mobile/providers/quizzes.provider";

interface Props {
  slide?: InstallSlideModel;
  slideIndex: number;
}

const InstallSlideViewOnly: React.FC<Props> = ({ slide, slideIndex }) => {
  const { mediaItemsMap } = useContext(MediaContext);
  const { playItemsMap } = useContext(PlaybookContext);
  const { quizItemsMap } = useContext(QuizzesContext);

  const isSlideElements =
    !slide?.question && !slide?.customQuizId && slide?.slideElements;
  const isSlideQuiz = !!slide?.customQuizId;
  const isSlideCustomQuestion = !!slide?.question;

  return (
    <div className={styles.InstallSlide}>
      <div className={styles.InstallSlideHeader}>
        <div className={styles.InstallSlideHeaderLeftSide}>
          <span className={styles.InstallSlideHeaderTitle}>
            Slide {slideIndex + 1}
          </span>
        </div>
      </div>

      <div className={styles.InstallSlideBody}>
        {slide?.narrationMediaId && (
          <div className={styles.InstallSlideAudioPlayerWrapper}>
            <AudioPlayer
              mediaId={slide.narrationMediaId}
              slideId={slide.id}
              listenOnly
            />
          </div>
        )}

        {/* Slide Type Elements */}
        {isSlideElements && (
          <>
            {slide?.slideElements && slide.slideElements.length === 0 && (
              <div className={styles.InstallSlideMediaSelectWrapper}>
                <div className={styles.InstallSlideMediaSelectButtonWrapper}>
                  <button
                    className={styles.InstallSlideMediaSelectButton}
                    type="button"
                  >
                    <img
                      className={styles.InstallSlideMediaSelectButtonIcon}
                      src={textIcon}
                      alt="Add Text"
                    />
                    <span className={styles.InstallSlideMediaSelectButtonText}>
                      Text
                    </span>
                  </button>
                </div>
                <div className={styles.InstallSlideMediaSelectButtonWrapper}>
                  <button
                    className={styles.InstallSlideMediaSelectButton}
                    type="button"
                  >
                    <img
                      className={styles.InstallSlideMediaSelectButtonIcon}
                      src={mediaIcon}
                      alt="Add Media"
                    />
                    <span className={styles.InstallSlideMediaSelectButtonText}>
                      Media
                    </span>
                  </button>
                </div>
                <div className={styles.InstallSlideMediaSelectButtonWrapper}>
                  <button
                    className={styles.InstallSlideMediaSelectButton}
                    type="button"
                  >
                    <img
                      className={styles.InstallSlideMediaSelectButtonIcon}
                      src={playIcon}
                      alt="Add Play"
                    />
                    <span className={styles.InstallSlideMediaSelectButtonText}>
                      Play
                    </span>
                  </button>
                </div>
              </div>
            )}

            {slide?.slideElements &&
              slide.slideElements.length > 0 &&
              slide.slideElements.map((slideElement, slideSectionIndex) => (
                <div
                  className={styles.InstallSlideUploadedMediaContainer}
                  key={slideSectionIndex}
                >
                  <div className={styles.InstallSlideUploadedMediaWrapper}>
                    {slideElement &&
                      slideElement.type === "MEDIA" &&
                      slideElement.mediaId && (
                        <div className={styles.InstallSlideUploadedMedia}>
                          <MediaThumbnail
                            mediaItem={mediaItemsMap[slideElement.mediaId]}
                          />
                        </div>
                      )}

                    {slideElement &&
                      slideElement.type === "PLAY" &&
                      slideElement.playId && (
                        <div className={styles.InstallSlideUploadedPlay}>
                          {playItemsMap[slideElement.playId] ? (
                            <div className={styles.InstallSlidePlayCard}>
                              <div
                                className={
                                  styles.InstallSlidePlayCardThumbnailWrapper
                                }
                              >
                                <PlayThumbnail
                                  play={playItemsMap[slideElement.playId]}
                                />
                              </div>
                              <div
                                className={
                                  styles.InstallSlidePlayCardTitleWrapper
                                }
                              >
                                <span
                                  className={styles.InstallSlidePlayCardTitle}
                                >
                                  {playItemsMap[slideElement.playId]
                                    ? playItemsMap[slideElement.playId].name
                                    : "The Play is unavailable"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            "The play is unavailable"
                          )}
                        </div>
                      )}

                    {slideElement && slideElement.type === "TEXT" && (
                      <div className={styles.InstallSlideUploadedText}>
                        <Wysiwyg
                          editorData={{
                            content: slideElement.textValue!.data,
                            textAlignment: slideElement.textValue!
                              .textAlignment,
                          }}
                          onContentUpdate={() => {
                            return;
                          }}
                          readOnly
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </>
        )}

        {/* Slide Type Quiz */}
        {isSlideQuiz && slide && slide.customQuizId && (
          <div className={styles.InstallSlideQuizCard}>
            <div className={styles.InstallSlideQuizCardTitle}>
              <span className={styles.InstallSlideQuizCardTitleLine1}>
                Quiz
              </span>
              <span className={styles.InstallSlideQuizCardTitleLine2}>
                {quizItemsMap[slide.customQuizId]
                  ? quizItemsMap[slide.customQuizId].name
                  : "The Quiz is unavailable"}
              </span>
            </div>
          </div>
        )}

        {/* Slide Type Custom Question */}
        {isSlideCustomQuestion && slide && slide.question && (
          <div className={styles.InstallSlideQuestionCard}>
            <div className={styles.InstallSlideQuestionCardPreTitle}>
              Question
            </div>
            <div className={styles.InstallSlideQuestionCardTitleWrapper}>
              <div className={styles.InstallSlideQuestionCardTitle}>
                {slide.question.questionMediaId && (
                  <div className={styles.InstallSlideQuestionCardTitleImage}>
                    <MediaThumbnail
                      mediaItem={mediaItemsMap[slide.question.questionMediaId]}
                    />
                  </div>
                )}
                <span>{slide.question.question}</span>
              </div>
              <div className={styles.InstallSlideQuestionCardButtonsWrapper} />
            </div>
            <div className={styles.InstallSlideAnswersWrapper}>
              {slide.question.correctOptions &&
                slide.question.correctOptions
                  .filter((item) => !(item.optionType === "TEXT" && !item.text))
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.InstallSlideQuestionCardItem} ${
                        styles.InstallSlideQuestionCardItemCorrect
                      } ${
                        item.optionType === "TEXT"
                          ? styles.InstallSlideQuestionCardItemText
                          : styles.InstallSlideQuestionCardItemImage
                      }`}
                    >
                      {item.mediaId && (
                        <MediaThumbnail
                          className={
                            styles.InstallSlideQuestionCardItemThumbnailWrapper
                          }
                          mediaItem={mediaItemsMap[item.mediaId]}
                        />
                      )}
                      {item.text && <span>{item.text}</span>}
                    </div>
                  ))}

              {slide.question.incorrectOptions &&
                slide.question.incorrectOptions
                  .filter((item) => !(item.optionType === "TEXT" && !item.text))
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.InstallSlideQuestionCardItem} ${
                        item.optionType === "TEXT"
                          ? styles.InstallSlideQuestionCardItemText
                          : styles.InstallSlideQuestionCardItemImage
                      }`}
                    >
                      {item.mediaId && (
                        <MediaThumbnail
                          className={
                            styles.InstallSlideQuestionCardItemThumbnailWrapper
                          }
                          mediaItem={mediaItemsMap[item.mediaId]}
                        />
                      )}
                      {item.text && <span>{item.text}</span>}
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>

      {isSlideElements && <div className={styles.InstallSlideFooter} />}
    </div>
  );
};

export default InstallSlideViewOnly;
