import React, { useState, useEffect } from "react";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import styles from "./PlayModeSelector.module.scss";
import { publishMode } from "../../../utils/web-only-constants";
import RadioButton from "../../RadioButton/RadioButton";

interface ModeSelectorProps {
  play?: Omit<PlayModel, "drawablePlayers">;
  setPlay?: React.Dispatch<
    React.SetStateAction<Omit<PlayModel, "drawablePlayers">>
  >;
  setPlayToCreate?: React.Dispatch<React.SetStateAction<PlayModel>>;
  playToCreate?: PlayModel;
}

const PlayModeSelector: React.FC<ModeSelectorProps> = ({
  play,
  setPlay,
  setPlayToCreate,
  playToCreate,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const playMode = play
    ? play.published
      ? "Published"
      : "Draft"
    : playToCreate
    ? playToCreate.published
      ? "Published"
      : "Draft"
    : null;

  useEffect(() => {
    setTimeout(() => {
      if (open) {
        window.addEventListener("click", handleClose);
      } else {
        window.removeEventListener("click", handleClose);
      }
    }, 0);
  }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (ev: MouseEvent) => {
    ev.stopPropagation();
    setOpen(false);
  };

  const handlePlayModeChange = (
    event: React.MouseEvent<HTMLDivElement>,
    value: string
  ) => {
    event.stopPropagation();
    if (value === playMode) return;
    if (play && setPlay) {
      setPlay({ ...play, published: value === "Draft" ? false : true });
    }
    if (setPlayToCreate && playToCreate) {
      setPlayToCreate({
        ...playToCreate,
        published: value === "Draft" ? false : true,
      });
    }
    setOpen(false);
  };

  return (
    <div className={styles.playModeSelector}>
      <button
        onClick={(e) => handleClick(e)}
        className={`${styles.button} ${
          open ? styles.popupButtonColor : styles.defaultButtonColor
        } ${
          play ? styles.editPlayButtonPadding : styles.createPlayButtonPadding
        }  `}
      >
        <span>{playMode}</span>
        <div className={styles.icon}></div>
      </button>

      {open ? (
        <div className={`${open ? styles.open : styles.close}`}>
          {publishMode.map((mode) => (
            <div
              key={mode}
              className={` ${styles.rowItem} ${styles.ripple}`}
              onClick={(e) => handlePlayModeChange(e, mode)}
            >
              <div className={styles.labelPlacement}>
                <RadioButton selected={mode === playMode} />
                <div>
                  <span className={styles.descriptionSpacing}>
                    <span className={styles.header}>{mode}</span>
                    <span className={styles.description}>
                      {mode === "Draft"
                        ? "Plays set to draft don't appear in players' playbooks, and are excluded from games. Draft plays can be used in lessons, if selected by a coach."
                        : "Published plays are seen by players and are included in games"}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default PlayModeSelector;
