import React, { ReactNode, useContext, useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { LicensesContext } from "../../shared/shared-with-mobile/providers/licenses.provider";
import LicenseExpirationModal from "../LicenseExpirationModal/LicenseExpirationModal";
import styles from "./GlobalModalContainer.module.scss";
import {
  GRACE_PERIOD_DAYS,
  LICENSE_EXPIRATION_DISMISS_LOCAL_STORAGE_KEY,
} from "../../shared/shared-with-mobile/constants";
import { PAID_PRODUCTS } from "../../generated/from-api/api-constants/license-tier-constants";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";

interface Props {
  children: JSX.Element | ReactNode;
  handleLogout?: () => void;
}

/* 
/ This wrapper component adds no UI by default
/ it contains only the opening and closing business logic for various "global" modals
/ such as the License Expiration Modal for starters, and later it would be good to move the OnboardingModal and WelcomeModal as well
*/
const GlobalModalContainer: React.FC<Props & RouteComponentProps> = ({
  children,
  location,
}) => {
  const { dispatchModal, closeModal } = useContext(UIContext);
  const { currentLicenses } = useContext(LicensesContext);
  const { userProfile } = useContext(UserContext);
  const [isExpirationModalOpen, setIsExpirationModalOpen] = useState<boolean>(
    false
  );

  const { licenseForCurrentTeam, updateSuspensionStatus } = useContext(
    TeamContext
  );

  // this useEffect is responsible for opening the LicenseExpirationModal
  // this modal serves to either warn users that their license will soon be expired (this version is dismissable)
  // or it informs that their license is already expired / suspended (this version is not dismissable)
  useEffect(() => {
    if (!userProfile) {
      // if no userProfile, then no user is currently authenticated
      closeModal();
      return;
    }

    if (isExpirationModalOpen) {
      return;
    }

    if (!licenseForCurrentTeam || !licenseForCurrentTeam.expires) {
      return;
    }

    if (!currentLicenses) {
      return;
    }

    const licenseForCurrentTeamIsOwnedByCurrentUser = currentLicenses
      .map((lic) => lic.id)
      .includes(licenseForCurrentTeam.id);

    const paidProductIds = PAID_PRODUCTS.map((product) => product.id);
    const licenseIsPaid = paidProductIds.includes(
      licenseForCurrentTeam.licenseTierId
    );

    if (!licenseIsPaid) {
      return;
    }

    // calculate how many days until license expires
    const expirationDateTime = new Date(
      licenseForCurrentTeam.expires
    ).getTime();
    const now = new Date().getTime();
    const difference = expirationDateTime - now;
    const MS_IN_A_DAY = 24 * 60 * 60 * 1000;
    const differenceInDays = Math.ceil(difference / MS_IN_A_DAY);

    const gracePeriodMS = GRACE_PERIOD_DAYS * 24 * 60 * 60 * 1000;
    const isLicenseAutomaticallySuspendedByExpiration =
      expirationDateTime + gracePeriodMS < now;

    // This (isSuspended state in Team Context) is likely no longer relevant/used by the time this refactor is over
    updateSuspensionStatus(isLicenseAutomaticallySuspendedByExpiration);

    const routesForWhichThisModalShouldNotShow = ["/account"]; // a user with suspended license still needs to navigate their account settings page (from where they can renew their suspended license)

    for (const route of routesForWhichThisModalShouldNotShow) {
      if (location.pathname.includes(route)) {
        return;
      }
    }

    // start showing modal to the license owner when 90 days are left before expiration
    if (differenceInDays > 90) {
      return;
    }

    // unless license is suspended (expired and past 30 day grace period), only show this modal to license holder
    if (
      !licenseForCurrentTeamIsOwnedByCurrentUser &&
      !isLicenseAutomaticallySuspendedByExpiration
    ) {
      return;
    }

    // determine if this modal open should be prevented due to prior user dismissal
    const dismissUntilDateString = window.localStorage.getItem(
      LICENSE_EXPIRATION_DISMISS_LOCAL_STORAGE_KEY
    );

    if (dismissUntilDateString) {
      const dismissUntilDateTime = new Date(dismissUntilDateString).getTime();

      const diff = dismissUntilDateTime - now;

      if (diff > 0 && !isLicenseAutomaticallySuspendedByExpiration) {
        // dismiss logic does not apply for a suspended team/license
        const MS_IN_AN_HOUR = 60 * 60 * 1000;
        const diffInHours = diff / MS_IN_AN_HOUR;
        console.log(
          "Not showing license expiration modal because it has been dismissed. Hours remaining for dismissal:  ",
          diffInHours
        );
        return;
      } else {
        // failure to remove shouldn't cause any bugs, but we might as well clean it up if the date has passed
        window.localStorage.removeItem(
          LICENSE_EXPIRATION_DISMISS_LOCAL_STORAGE_KEY
        );
      }
    }

    setIsExpirationModalOpen(true);

    dispatchModal({
      open: true,
      size: "medium",
      disableBackdropClick: true,
      className: styles.licenseExpirationModal,
      body: (
        <LicenseExpirationModal
          doesLicenseBelongToUser={licenseForCurrentTeamIsOwnedByCurrentUser}
          isSuspended={isLicenseAutomaticallySuspendedByExpiration}
          license={licenseForCurrentTeam}
          daysRemaining={differenceInDays}
          handleClose={() => {
            closeModal();
            setIsExpirationModalOpen(false);
          }}
        />
      ),
    });
  }, [userProfile, licenseForCurrentTeam, currentLicenses, location]);

  return <>{children}</>;
};

export default withRouter(GlobalModalContainer);
