import * as React from "react";

const TeamSetupIconCheck: React.FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-tag="allowRowEvents"
  >
    <path
      d="M19 10.5C19 15.1467 15.0195 19 10 19C4.98048 19 1 15.1467 1 10.5C1 5.85329 4.98048 2 10 2C15.0195 2 19 5.85329 19 10.5Z"
      stroke="#FFD523"
      strokeWidth="2"
      data-tag="allowRowEvents"
    />
    <path
      d="M5.45455 10.6378L8.3871 13.3891L14.5455 7.61133"
      stroke="#FFD523"
      strokeWidth="2"
      strokeLinecap="round"
      data-tag="allowRowEvents"
    />
  </svg>
);

export default TeamSetupIconCheck;
