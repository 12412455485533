import { useContext } from "react";
import { PlayerOverallReadinessModel } from "../../../../generated/from-api/models/dtos/player-to-play.readiness.model";

import Avatar from "../../components/PlayerAvatar/Avatar";
import { ReadinessScoresContext } from "../../providers/readinessScores.provider";
import pageStyles from "../PlaybookIQ.module.scss";
import styles from "./CurrentLeadersBoard.module.scss";

const CurrentLeadersBoard: React.FC = () => {
  const { playerOverallMetrics } = useContext(ReadinessScoresContext);
  const playersMetricsToDisplay = playerOverallMetrics.filter(
    (player: PlayerOverallReadinessModel) => !player.playerHideOnLeaderBoard
  );
  const sortedPlayers = [...playersMetricsToDisplay].sort((a, b) => {
    return Number(b.overallScore) - Number(a.overallScore);
  });

  return (
    <div className={pageStyles.dashboardCard} style={{ overflow: "hidden" }}>
      <div className={styles.cardSubtitle}>This Week&apos;s Leaderboard</div>
      <div className={styles.currentLeaders}>
        {sortedPlayers
          .filter((i, index) => index < 10)
          .map((player, index) => (
            <div key={index} className={styles.playerContainer}>
              {player.playerId && (
                <Avatar
                  userId={player.playerId}
                  rankingData={index + 1}
                  rankCircleStyle={true}
                  small
                  type="player"
                />
              )}

              <div className={styles.playerName}>
                {player.playerFirstName} {player.playerLastName}
              </div>
              <div className={styles.playerScore}>
                {Number(player.overallScore).toFixed(2)}%
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CurrentLeadersBoard;
