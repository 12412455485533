import { useContext } from "react";
import { Link } from "react-router-dom";

import { FormationsContext } from "../../../../shared/shared-with-mobile/providers/formations.provider";
import styles from "../../PlaybookSettings.module.scss";
import PlayThumbnail from "../../../../components/drawing/PlayThumbnail";
import { FormationModel } from "../../../../generated/from-api/models/drawable/formation.model";
import PlusIcon from "../../../../resources/images/grayPlus.svg";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import NewFormationModal from "./NewFormationModal";
import { PlayTypeEnum } from "../../../../generated/from-api/models/enums/play-type.enum";
import { UserContext } from "../../../../shared/shared-with-mobile/providers/user.provider";
import { userRoles } from "../../../../shared/shared-with-mobile/constants";
import {
  BASE_DEFENSIVE_FORMATION,
  BASE_OFFENSIVE_FORMATION,
} from "../../../../shared/shared-with-mobile/play-editor/playEditor.constants";

interface Props {
  type: PlayTypeEnum;
}

const FormationsTab: React.FC<Props> = ({ type }) => {
  const { userProfile } = useContext(UserContext);
  const { globalFormations, currentFormations } = useContext(FormationsContext);
  const { dispatchModal } = useContext(UIContext);

  const formationGroupName =
    type === "Offensive" ? "Offensive Formations" : "Defensive Fronts";
  const singularFormationName =
    type === "Offensive" ? "Offensive Formation" : "Defensive Front";

  const globalFormationsByType = globalFormations.filter(
    (formation: FormationModel) => formation.type === type
  );
  const formationsByType = currentFormations.filter(
    (formation: FormationModel) => formation.type === type
  );

  const isAdmin = userProfile && userProfile.role === userRoles.ADMIN;
  const editableFormationList = isAdmin
    ? globalFormationsByType
    : formationsByType;
  const baseFormation =
    type === "Offensive" ? BASE_OFFENSIVE_FORMATION : BASE_DEFENSIVE_FORMATION;

  const openNewFormationModal = () => {
    dispatchModal({
      title: "New Formation",
      open: true,
      body: (
        <NewFormationModal
          baseFormation={
            globalFormationsByType.length > 0
              ? globalFormationsByType[0]
              : baseFormation
          }
          formationOptions={
            [...formationsByType, ...globalFormationsByType].length > 0
              ? [...formationsByType, ...globalFormationsByType]
              : [baseFormation]
          }
        />
      ),
    });
  };

  return (
    <div className={styles.tabContent}>
      <div className={styles.tabHeader}>{`${
        !isAdmin ? "Custom" : ""
      } ${formationGroupName}`}</div>
      <div className={styles.formationsGroup}>
        {editableFormationList.map((formation: FormationModel) => (
          <Link
            to={`/edit-formation/${formation.id}`}
            key={formation.id}
            className={`card ${styles.formation}`}
          >
            <PlayThumbnail formation={formation} />
            <div className={styles.formationName}>{formation.name}</div>
          </Link>
        ))}
        <div
          className={styles.newFormationButton}
          role="button"
          onClick={openNewFormationModal}
        >
          <img src={PlusIcon} alt="plus icon" />
          <div>{singularFormationName}</div>
        </div>
      </div>
      {!isAdmin && (
        <>
          <div
            className={styles.tabHeader}
          >{`Pre-built ${formationGroupName}`}</div>
          <div className={styles.formationsGroup}>
            {globalFormationsByType.map((formation: FormationModel) => (
              <div key={formation.id} className={`card ${styles.formation}`}>
                <PlayThumbnail formation={formation} />
                <div className={styles.formationName}>{formation.name}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FormationsTab;
