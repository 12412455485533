import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { InvitationModel } from "../../../../generated/from-api/models/invitation.model";
import { CoachModel } from "../../../../generated/from-api/models/relationships/coach.model";
import { PlayerModel } from "../../../../generated/from-api/models/relationships/player.model";
import { API_BASE_URL } from "../../constants";

// TODO: Update this service to match the latest APIs
export const InvitationService = {
  /**
   * Use to retrieve invitations by team
   * @param teamId
   */
  GET: async function (teamId: string): Promise<InvitationModel[] | undefined> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.INVITATION}/team/${teamId}`)
    ).data;
  },

  /**
   * Use to retrieve invitations by user phone number or user email
   * @param teamId
   */
  LIST: async function (): Promise<InvitationModel[] | undefined> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.INVITATION}`)).data;
  },

  /**
   * Use to invite a player or coach
   * @param invitation the invitation to create
   */
  POST: async function (
    invitation: Pick<
      InvitationModel,
      | "type"
      | "teamId"
      | "toEmail"
      | "toPhoneNumber"
      | "firstName"
      | "lastName"
      | "playerPositions"
      | "coachRole"
      | "coachName"
    >
  ): Promise<InvitationModel | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.INVITATION}`, invitation)
    ).data;
  },

  /**
   * Use to accept or modify an invitation
   * @param invitation
   */
  PUT: async function (
    invitation: Pick<
      InvitationModel,
      | "id"
      | "firstName"
      | "lastName"
      | "playerPositions"
      | "coachRole"
      | "coachName"
    >
  ): Promise<InvitationModel | undefined> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.INVITATION}`, invitation)
    ).data;
  },

  /**
   * Accept an invitation
   * @param invitation
   */
  ACCEPT: async function (
    invitationId: string
  ): Promise<
    | { coach?: CoachModel; player?: PlayerModel; data: InvitationModel }
    | undefined
  > {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.INVITATION}/accept`, {
        invitationId,
      })
    ).data;
  },

  /**
   * Decline an invitation
   * @param invitation
   */
  DECLINE: async function (
    invitationId: string
  ): Promise<
    | { coach?: CoachModel; player?: PlayerModel; data: InvitationModel }
    | undefined
  > {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.INVITATION}/decline`, {
        invitationId,
      })
    ).data;
  },

  /**
   * Use to delete or cancel an invitation
   * @param invitation
   */
  DELETE: async function (invitationId: string): Promise<boolean> {
    return (
      await axios.delete(
        `${API_BASE_URL}${APIRoutes.INVITATION}/${invitationId}`
      )
    ).data;
  },
};
