import { useContext, useEffect } from "react";
import {
  ModalModel,
  UIContext,
} from "../../shared/shared-with-mobile/providers/ui.provider";
import styles from "./Modal.module.scss";

const Modal: React.FC<ModalModel> = ({
  open,
  closing,
  size,
  title,
  body,
  className,
  depth,
  disableBackdropClick,
}) => {
  const { closeModal } = useContext(UIContext);

  // This handles the tab focus jailing for both modals dispatched with this and modals with a
  // data attribute [data-lockfocus="true"], the "Edit Roster" modal for example.
  useEffect(() => {
    window.addEventListener("keydown", function (e) {
      if (open && e.key === "Tab") {
        // We need to determine what fields should in the in jail
        const focusIn =
          document.querySelector(`.${styles.modalWrapper}.${styles.open}`) ||
          this.document.querySelector(`[data-lockfocus="true"]`);

        // focusIn?.querySelectorAll(`[tabindex]`).forEach((el) => {
        //   el.removeAttribute("tabindex");
        // });

        const tabElements =
          focusIn?.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          ) || [];

        // If for whatever reason the focus is outside the modal grab it and lock it up
        let tabJailed = false;
        tabElements.forEach((tabElement: any) => {
          if (tabElement === document.activeElement) {
            tabJailed = true;
          }
        });

        // Handle looping if the focus is at the end or start
        if (tabElements.length > 0) {
          if (tabJailed) {
            if (e.shiftKey) {
              if (document.activeElement === tabElements[0]) {
                (tabElements[tabElements.length - 1] as HTMLElement).focus();
                e.preventDefault();
              }
            } else {
              if (
                document.activeElement === tabElements[tabElements.length - 1]
              ) {
                (tabElements[0] as HTMLElement).focus();
                e.preventDefault();
              }
            }
          } else {
            (tabElements[0] as HTMLElement).focus();
            e.preventDefault();
          }
        }
      }
    });
  });

  const onBackdropClickHandler = () => {
    if (disableBackdropClick) {
      return;
    }
    closeModal();
  };

  return (
    <>
      <div
        className={`
          ${styles.modalWrapper}
          ${open ? styles.open : ""}
          ${closing ? styles.closing : ""}
          ${depth && depth > 0 ? styles.second : ""}
        `}
      >
        <div
          className={`
            ${styles.modal}
            ${styles[size as string]}
            ${className}
          `}
        >
          {title && <div className={styles.modalHeader}>{title}</div>}
          {body && <div className={styles.modalBody}>{body}</div>}
        </div>
      </div>
      <div
        style={{ cursor: disableBackdropClick ? "auto" : "pointer" }}
        className={`${styles.modalOverlay}
          ${open ? styles.open : ""}
          ${closing ? styles.closing : ""}
          ${depth && depth > 0 ? styles.second : ""}`}
        onClick={onBackdropClickHandler}
      />
    </>
  );
};

export default Modal;
