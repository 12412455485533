import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import HudlFormatGuideImage from "../../resources/images/hudlFormatGuide.svg";
import styles from "./PdfInstructionsSwiper.module.scss";

const PdfInstructionsSwiper: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Swiper
          slidesPerView={1}
          modules={[Pagination]}
          spaceBetween={0}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          className={styles.swiper}
        >
          <SwiperSlide>
            <div className={styles.swiperSlide}>
              <div className={styles.slideNumber}>1</div>
              <div className={styles.slideText}>
                <div>
                  Login to{" "}
                  <a
                    href="https://www.hudl.com/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Hudl&apos;s website
                  </a>{" "}
                  in a new tab on your browser.
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.swiperSlide}>
              <div className={styles.slideNumber}>2</div>
              <div className={styles.slideText}>
                Tap the 3 lines in the top left, select teams, then select
                playbook.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.swiperSlide}>
              <div className={styles.slideNumber}>!</div>
              <div className={styles.slideText}>
                Note: Hudl limits PDF exports to 200 plays. If you have 200+
                plays, group by formation and select one with 6+ cards to print.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.swiperSlide}>
              <div className={styles.slideNumber}>3</div>
              <div className={styles.slideText}>
                Use the Print Plays button to export your playbook.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.swiperSlide}>
              <div className={styles.slideNumber}>!</div>
              <div className={styles.slideText}>
                Remember: Team Nation can import plays in either 1 card per page
                or 8 cards per page, but not a mix of both.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.swiperSlide}>
              <div className={styles.slideNumber}>4</div>
              <div className={styles.slideTextWithImage}>
                <div>Select either 1 or 8 cards per page to print.</div>
                <img src={HudlFormatGuideImage} />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.swiperSlide}>
              <div className={styles.slideNumber}>5</div>
              <div className={styles.slideText}>
                Save the .pdf of exported plays to your device.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.swiperSlide}>
              <div className={styles.slideNumber}>6</div>
              <div className={styles.slideText}>
                Return here and upload your Hudl playbook PDF below.
              </div>
            </div>
          </SwiperSlide>
          <div slot="container-end" className={styles.containerEnd}>
            <div className="swiper-pagination" />
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default PdfInstructionsSwiper;
