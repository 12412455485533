import { hasPositionType } from "../shared/shared-with-mobile/functions";

interface PlayerFilterSettings {
  searchString?: string;
  filterByPosition?: "" | "offensive" | "defensive" | "specialTeams";
  onFirstName?: boolean;
  onLastName?: boolean;
  onFirstLastName?: boolean;
  onFirstInitialLastName?: boolean;
  onPosition?: boolean;
}

export const playersFilter: (settings: PlayerFilterSettings) => any = (
  settings
) => {
  return function (player: any) {
    // check player position by for Filter Toggle
    const checkPositionByFilter = hasPositionType(
      player.playerPositions
        ? player.playerPositions.split("|")
        : player.positions
        ? player.positions.split("|")
        : [""],
      settings.filterByPosition ? settings.filterByPosition : ""
    );

    // The string for searching against names
    const searchRegExp = settings.searchString
      ? new RegExp(
          settings.searchString
            .toLowerCase()
            .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
          "g"
        )
      : "";

    // Check the First Name
    if (settings.onFirstName !== false && checkPositionByFilter == true) {
      if (player.playerFirstName.toLowerCase().search(searchRegExp) >= 0) {
        return true;
      }
    }

    // Check the Last Name
    if (settings.onLastName !== false && checkPositionByFilter == true) {
      if (player.playerLastName.toLowerCase().search(searchRegExp) >= 0) {
        return true;
      }
    }

    // Check the First Last Name
    if (settings.onFirstLastName !== false && checkPositionByFilter == true) {
      const searchAgainst =
        player.playerFirstName.toLowerCase() +
        " " +
        player.playerLastName.toLowerCase();
      if (searchAgainst.search(searchRegExp) >= 0) {
        return true;
      }
    }

    // Check the First Initial Last Name
    if (
      settings.onFirstInitialLastName !== false &&
      checkPositionByFilter == true
    ) {
      const searchAgainst =
        player.playerFirstName.substr(0, 1).toLowerCase() +
        ". " +
        player.playerLastName.toLowerCase();
      if (searchAgainst.search(searchRegExp) >= 0) {
        return true;
      }
    }

    // Check if the positions match
    if (settings.onPosition !== false && checkPositionByFilter == true) {
      const positions = player.playerPositions
        ? player.playerPositions
        : player.positions
        ? player.positions
        : "";
      if (positions.toLowerCase().search(searchRegExp) >= 0) {
        return true;
      }
    }

    // Well, it ain't in there so get it out of here
    return false;
  };
};
