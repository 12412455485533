import { SchoolYearEnum } from "../../generated/from-api/models/enums/school-year.enum";
import { TeamLevelEnum } from "../../generated/from-api/models/enums/team-level.enum";

export const devConfig = {
  apiKey: "AIzaSyC_0tNsV2QPPU2P-5d4p3DttDapnYBDzss",
  authDomain: "team-nation-dev.firebaseapp.com",
  projectId: "team-nation-dev",
  storageBucket: "team-nation-dev.appspot.com",
  messagingSenderId: "884366412748",
  appId: "1:884366412748:web:8f5307d8a3ae65bf7c2b69",
  measurementId: "G-R3J7TVNLS4",
};

export const stagingConfig = {
  apiKey: "AIzaSyCvMpLBKNaFF2knCHsNZQwqQkBFI0pg8hk",
  authDomain: "team-nation-staging.firebaseapp.com",
  projectId: "team-nation-staging",
  storageBucket: "team-nation-staging.appspot.com",
  messagingSenderId: "527730344941",
  appId: "1:527730344941:web:db3fc75efdc321ad395de4",
  measurementId: "G-PQ7K0GD0Y2",
};

export const productionConfig = {
  apiKey: "AIzaSyBQtQEitShM7hsMQLCsKW4Ju9jmBalHpkA",
  authDomain: "team-nation-production.firebaseapp.com",
  projectId: "team-nation-production",
  storageBucket: "team-nation-production.appspot.com",
  messagingSenderId: "965190917563",
  appId: "1:965190917563:web:15bad9807406ff4580f89e",
  measurementId: "G-CG7V6XK7P9",
};

export const API_BASE_URL_LOCAL = "http://localhost:8080";
export const API_BASE_URL_DEV = "https://team-nation-dev.uc.r.appspot.com";
export const API_BASE_URL_STAGING =
  "https://team-nation-staging.uc.r.appspot.com";
export const API_BASE_URL_PRODUCTION =
  "https://team-nation-production.uc.r.appspot.com";

export const PRIVACY_POLICY_URL = "https://www.teamnationsports.com/privacy";

export let serverConfig = {};
export let API_BASE_URL = "";
switch (process.env.REACT_APP_STAGE) {
  case "staging":
    serverConfig = stagingConfig;
    API_BASE_URL = API_BASE_URL_STAGING;
    break;
  case "production":
    serverConfig = productionConfig;
    API_BASE_URL = API_BASE_URL_PRODUCTION;
    break;
  case "dev":
    serverConfig = devConfig;
    API_BASE_URL = API_BASE_URL_DEV;
    break;
  case "local":
  default:
    serverConfig = devConfig;
    API_BASE_URL = API_BASE_URL_LOCAL;
    break;
}

export enum userRoles {
  ADMIN = "ADMIN",
  USER = "USER",
}

export const TEAM_LEVELS: TeamLevelEnum[] = [
  "JV",
  "VARSITY",
  "YOUTH",
  "FRESHMAN",
  "SOPHOMORE",
];

export enum invitationTypes {
  PLAYER = "PLAYER",
  COACH = "COACH",
}

export const SCHOOL_YEARS: SchoolYearEnum[] = [
  "Freshman",
  "Sophomore",
  "Junior",
  "Senior",
];

export const PLAYER_HEIGHT = [
  { name: `3'10"`, value: 46 },
  { name: `3'11"`, value: 47 },
  { name: `4'0"`, value: 48 },
  { name: `4'1"`, value: 49 },
  { name: `4'2"`, value: 50 },
  { name: `4'3"`, value: 51 },
  { name: `4'4"`, value: 52 },
  { name: `4'5"`, value: 53 },
  { name: `4'6"`, value: 54 },
  { name: `4'7"`, value: 55 },
  { name: `4'8"`, value: 56 },
  { name: `4'9"`, value: 57 },
  { name: `4'10"`, value: 58 },
  { name: `4'11"`, value: 59 },
  { name: `5'0"`, value: 60 },
  { name: `5'1"`, value: 61 },
  { name: `5'2"`, value: 62 },
  { name: `5'3"`, value: 63 },
  { name: `5'4"`, value: 64 },
  { name: `5'5"`, value: 65 },
  { name: `5'6"`, value: 66 },
  { name: `5'7"`, value: 67 },
  { name: `5'8"`, value: 68 },
  { name: `5'9"`, value: 69 },
  { name: `5'10"`, value: 70 },
  { name: `5'11"`, value: 71 },
  { name: `6'0"`, value: 72 },
  { name: `6'1"`, value: 73 },
  { name: `6'2"`, value: 74 },
  { name: `6'3"`, value: 75 },
  { name: `6'4"`, value: 76 },
  { name: `6'5"`, value: 77 },
  { name: `6'6"`, value: 78 },
  { name: `6'7"`, value: 79 },
  { name: `6'8"`, value: 80 },
  { name: `6'9"`, value: 81 },
  { name: `6'10"`, value: 82 },
  { name: `6'11"`, value: 83 },
  { name: `7'0"`, value: 84 },
  { name: `7'1"`, value: 85 },
  { name: `7'2"`, value: 86 },
];

export const PLAYER_WEIGHT = [
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  105,
  110,
  115,
  120,
  125,
  130,
  135,
  140,
  145,
  150,
  155,
  160,
  165,
  170,
  175,
  180,
  185,
  190,
  195,
  200,
  205,
  210,
  215,
  220,
  225,
  230,
  235,
  240,
  245,
  250,
  255,
  260,
  265,
  270,
  275,
  280,
  285,
  290,
  295,
  300,
  305,
  310,
  315,
  320,
  325,
  330,
  335,
  340,
  345,
  350,
  355,
  360,
  365,
  370,
  375,
  380,
  385,
  390,
  395,
];

export const US_STATES = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
];

export const INTEGER_USED_FOR_NO_LIMIT = 10000;

export const GRACE_PERIOD_DAYS = 30;

export const LICENSE_EXPIRATION_DISMISS_LOCAL_STORAGE_KEY =
  "dismissLicenseExpirationModalUntilDate";

export const NON_GAMIFIED_PLAYS_TOOLTIP_CONTENT = [
  // {
  //   tier: "FREE2023",
  //   content:
  //     "Your free trial gamifies the first 15 plays in your playbook. Purchase a subscription to unlock more games.",
  //   link: "https://teamnationsports.com/pricing/",
  //   btnTitle: "Upgrade",
  // },
  {
    tier: "BASIC2023",
    content:
      "The free version of Team Nation only gamifies 5 plays from your playbook. Upgrade to a paid license to unlock more.",
    link: "https://teamnationsports.com/pricing/",
    btnTitle: "Upgrade",
  },
  {
    tier: "FREE2023",
    content:
      "The free version of Team Nation only gamifies 15 plays from your playbook. Upgrade to a paid license to unlock more.",
    link: "https://teamnationsports.com/pricing/",
    btnTitle: "Upgrade",
  },
  {
    tier: "BRONZE2023",
    content:
      "Your Bronze subscription gamifies the first 100 plays in your playbook. Upgrade to unlock more games.",
    link: "https://teamnationsports.com/pricing/",
    btnTitle: "Upgrade",
  },
  {
    tier: "YOUTH2023",
    content:
      "Your Youth / Flag subscription gamifies the first 25 plays in your playbook. Upgrade to unlock more games.",
    link: "https://teamnationsports.com/pricing/",
    btnTitle: "Upgrade",
  },
  {
    tier: "SILVER2023",
    content:
      "Your High School subscription gamifies the first 250 plays in your playbook. Upgrade to unlock more games.",
    link: "https://teamnationsports.com/pricing/",
    btnTitle: "Upgrade",
  },
  {
    tier: "GOLD2023",
    content:
      "Your High School + subscription gamifies the first 500 plays in your playbook. Contact us for details on how to upgrade.",
    link: "https://knowledge.teamnationsports.com",
    btnTitle: "Contact Us",
  },
];
