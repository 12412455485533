import React, { RefObject, useEffect, useRef, useState } from "react";
import styles from "./MultiSelect.module.scss";

import Checkbox from "../Checkbox/Checkbox";

export interface MultiSelectOption {
  label: string;
  value: string;
}

interface Props {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: MultiSelectOption[];
  placeholder?: string;
  id?: string;
  value?: string[];
  theme?: "light" | "dark";
  size?: "large" | "medium" | "small" | "x-small";
  className?: string;
  alwaysOpen?: boolean;
  filter?: boolean;
  clearField?: boolean;
}

function useHandleOpenToggler(
  ref: RefObject<Element | null>,
  callback: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    function handleClickOutside(ev: any) {
      if (ref.current && !ref.current.contains(ev.target)) {
        callback(false);
      } else {
        callback(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const MultiSelect: React.FC<Props> = ({
  onChange,
  options,
  placeholder = "",
  id,
  value,
  className,
  theme = "dark",
  size = "medium",
  alwaysOpen = false,
  filter = false,
  clearField,
}) => {
  const [selectedList, setSelectedList] = useState<string[]>(value || []);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [listWidth, setListWidth] = useState<number>(0);
  const listRef = useRef<HTMLUListElement>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useHandleOpenToggler(wrapperRef, setIsListOpen);

  useEffect(() => {
    let largestItemWidth = 0;

    if (!!listRef.current && isListOpen) {
      Array.from(listRef.current.childNodes).map((listItem) => {
        const listWidth = listRef.current?.offsetWidth || 0;
        const listLabel = listItem.childNodes[0]
          .childNodes[1] as HTMLSpanElement;
        if (listLabel) {
          const labelWidth = listLabel.offsetWidth + 52; // Padding of parent element not accounted for in offsetWidth
          largestItemWidth =
            labelWidth > listWidth
              ? labelWidth > largestItemWidth
                ? labelWidth
                : largestItemWidth
              : 0;
        }
      });

      setListWidth(largestItemWidth);
    } else {
      setTimeout(() => {
        setListWidth(0);
      }, 200);
    }
  }, [isListOpen]);

  useEffect(() => {
    onChange &&
      onChange(({
        currentTarget: { selectedList: selectedList },
      } as unknown) as React.ChangeEvent<HTMLInputElement>);
  }, [selectedList]);

  useEffect(() => {
    if (clearField) {
      setListWidth(0);
      setSelectedList([]);
    }
  }, [clearField]);

  const handleChange = (value: string) => {
    if (selectedList.includes(value)) {
      setSelectedList(
        selectedList.filter((item: string, index) => {
          return selectedList.indexOf(value) !== index;
        })
      );
    } else {
      const removeBlank = selectedList.filter((word) => word.trim().length > 0);
      setSelectedList([...removeBlank, value]);
    }
  };

  return (
    <div
      className={`
        ${styles.label}
        ${styles[theme]}
        ${styles[size]}
        ${selectedList.length ? styles.hasValue : ""}
        ${isListOpen || alwaysOpen ? styles.listOpen : ""}
        ${className ? className : ""}
        ${alwaysOpen ? styles.alwaysOpen : ""}
      `}
      ref={wrapperRef}
    >
      {alwaysOpen && placeholder && (
        <div className={styles.selectLabel}>{placeholder}</div>
      )}
      <fieldset
        id={id}
        className={`
          ${styles.select}
          ${alwaysOpen ? styles.hide : ""}
        `}
        tabIndex={0}
      >
        <legend
          key={id}
          className={`
          ${styles.selectedValue}
          ${alwaysOpen ? styles.hide : ""}
          ${selectedList.length > 0 && filter ? styles.showNumberSelected : ""}
        `}
          tabIndex={-1}
          id={id}
        >
          {selectedList.length && selectedList[0] != "" && !filter
            ? options
                .filter((option) => selectedList.includes(option.value))
                .map((option) => option.label)
                .join(", ")
            : placeholder}
        </legend>
        {selectedList.length > 0 && filter && (
          <span className={styles.numberSelected}>{selectedList.length}</span>
        )}

        <ul
          ref={listRef}
          className={`
            ${styles.list}
            ${alwaysOpen ? styles.alwaysOpen : ""}
          `}
          style={{
            width: listWidth > 0 ? `${listWidth}px` : "100%",
          }}
        >
          {options.map((option) => {
            return (
              <li
                key={option.value}
                className={`
                ${styles.option}
                ${selectedList.includes(option.value) ? styles.selected : ""}
              `}
              >
                <Checkbox
                  onChange={() => handleChange(option.value)}
                  checked={selectedList.includes(option.value) ? true : false}
                >
                  {option.label.length > 40
                    ? `${option.label.slice(0, 40)}...`
                    : option.label}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </fieldset>
    </div>
  );
};

export default MultiSelect;
