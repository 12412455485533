import React from "react";
import {
  getUserFacingSubscriptionName,
  LICENSE_TIERS,
} from "../../generated/from-api/api-constants/license-tier-constants";
import {
  LicenseTierModel,
  LICENSE_TIER,
} from "../../generated/from-api/models/license-tier.model";
import { INTEGER_USED_FOR_NO_LIMIT } from "../../shared/shared-with-mobile/constants";
import styles from "./LicenseCard.module.scss";

interface Props {
  className?: string;
  licenseTier?: LicenseTierModel;
  availableLicensesCount?: number;
  upgradedLicensesCount?: number;
  teamsLimit?: number;
  coachesLimit?: number;
  playersLimit?: number;
  playsLimit?: number;
  gamifiedPlaysLimit?: number;
  installsLimit?: number;
  quizzesLimit?: number;
}

const LicenseCard: React.FC<Props> = ({
  className = "",
  licenseTier,
  availableLicensesCount = 0,
  upgradedLicensesCount = 0,
  teamsLimit = 0,
  coachesLimit = 0,
  playersLimit = 0,
  playsLimit = 0,
  gamifiedPlaysLimit = 0,
  installsLimit = 0,
  quizzesLimit = 0,
}) => {
  if (!licenseTier) {
    return null;
  }

  const NEW_LICENSES_TIERS: LICENSE_TIER[] = [
    "FREE2023", // technically sunsetted as of 3/31/2023, but for the context of this code specifically, it is still "new"
    "BRONZE2023", // technically sunsetted as of 3/31/2023, but for the context of this code specifically, it is still "new"
    "SILVER2023",
    "GOLD2023",
    "BASIC2023",
    "YOUTH2023",
    "COLLEGE2023",
  ];

  const licenseIsLegacy = !NEW_LICENSES_TIERS.includes(licenseTier.tier);

  return (
    <div
      className={`${styles.licenseCard} ${
        licenseTier && styles[licenseTier.tier.toLowerCase()]
      } ${className}`}
    >
      <div className={styles.licenseCardTop}>
        <span className={styles.licenseCardTopPreTitle}>Subscription</span>
        <span className={styles.licenseCardTopTitle}>
          {licenseTier
            ? `${getUserFacingSubscriptionName(licenseTier.tier)}`
            : "None"}
        </span>
      </div>
      {(function () {
        if (
          availableLicensesCount > 0 ||
          teamsLimit > 0 ||
          coachesLimit > 0 ||
          playersLimit > 0 ||
          playsLimit > 0
        ) {
          return (
            <div className={styles.licenseCardBottom}>
              {/* Shown when upgrading a "free" or "trial" license to a paid one */}
              {teamsLimit === 0 && availableLicensesCount > 0 && (
                <>
                  <div>Available Teams: {availableLicensesCount}</div>
                  {!!upgradedLicensesCount && (
                    <div>Upgraded Teams: {upgradedLicensesCount}</div>
                  )}
                </>
              )}

              {teamsLimit > 0 && (
                <div className={styles.licenseCardBottomHeaderText}>
                  Subscription Includes:
                </div>
              )}

              {licenseIsLegacy && (
                <>
                  {teamsLimit > 0 && <div>Teams: {teamsLimit}</div>}
                  {licenseTier?.tier !== LICENSE_TIERS.TRIAL.name &&
                    licenseTier?.tier !== LICENSE_TIERS.PREMIER.name && (
                      <>
                        {coachesLimit > 0 && (
                          <div>Coaches per team: {coachesLimit}</div>
                        )}
                        {playersLimit > 0 && (
                          <div>Players per team: {playersLimit}</div>
                        )}
                        {playsLimit > 0 && (
                          <div>Plays per team: {playsLimit}</div>
                        )}
                      </>
                    )}
                </>
              )}

              {!licenseIsLegacy && (
                <>
                  {playersLimit !== INTEGER_USED_FOR_NO_LIMIT && (
                    <div>
                      Players: {playersLimit < 300 ? playersLimit : "Custom"}
                    </div>
                  )}
                  <div>
                    Gamified plays:{" "}
                    {gamifiedPlaysLimit < INTEGER_USED_FOR_NO_LIMIT
                      ? gamifiedPlaysLimit
                      : "No Limit"}
                  </div>
                  <div>
                    Lessons:{" "}
                    {installsLimit !== INTEGER_USED_FOR_NO_LIMIT
                      ? installsLimit
                      : "No Limit"}
                  </div>
                  <div>
                    Flashcards:{" "}
                    {quizzesLimit !== INTEGER_USED_FOR_NO_LIMIT
                      ? quizzesLimit
                      : "No Limit"}
                  </div>
                </>
              )}
            </div>
          );
        }
      })()}
    </div>
  );
};

export default LicenseCard;
