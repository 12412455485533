import { useState, useContext } from "react";
import { useHistory } from "react-router";

import CharacterInput from "../../Input/CharacterInput";
import styles from "./DuplicatePlayModal.module.scss";
import { PlaybookContext } from "../../../shared/shared-with-mobile/providers/playbook.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import Button from "../../Button/Button";

interface Props {
  play: PlayModel;
}

const DuplicatePlayModal: React.FC<Props> = ({ play }) => {
  const { addPlays } = useContext(PlaybookContext);
  const { closeModal, dispatchToast } = useContext(UIContext);
  const history = useHistory();

  const [newPlayName, setNewPlayName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const duplicatePlay = async (newName: string) => {
    const duplicatedPlayToAdd: PlayModel = {
      ...play,
      name: newName,
    };

    try {
      const duplicated = await APIService.PLAY.POST([duplicatedPlayToAdd]);
      if (duplicated) {
        addPlays(duplicated);
        dispatchToast({
          type: "success",
          message: "Play duplicated.",
        });
        closeModal();
        history.goBack();
      }
    } catch (e: any) {
      if (e?.response?.status === 400) {
        setErrorMessage("That name was already taken. Please try another.");
      }
    }
  };

  return (
    <div className={styles.duplicatePlayModal}>
      <CharacterInput
        value={newPlayName}
        placeholder="Name"
        maxLength={100}
        onChange={(e) => {
          setNewPlayName(e.currentTarget.value);
        }}
      />
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      <div className={styles.buttonRow}>
        <Button theme="secondary" size={"small"} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          size={"small"}
          onClick={() => {
            duplicatePlay(newPlayName);
          }}
          disabled={!newPlayName}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default DuplicatePlayModal;
