export const getAbbreviatedDisplayName = (
  firstName: string,
  lastName: string
): string => {
  const characters = [];
  if (!firstName && !lastName) {
    return "No name";
  }
  if (firstName && firstName.length > 0) {
    characters.push(firstName.charAt(0));
    characters.push(". ");
  }
  if (lastName && lastName.length > 0) {
    for (let i = 0; i < lastName.length; i++) {
      characters.push(lastName.charAt(i));
    }
  }
  return characters.join("");
};
