export const colorPalette = {
  red: "#ff584e",
  darkRed: "#ab0a00",
  orange: "#e08645",
  yellow: "#ffcc3d",
  green: "#6dcc30",
  teal: "#23aac1",
  blue: "#2867f4",
  pink: "#ff38c7",
  purple: "#a13db6",
  gray: "#ababab",
};

export const colors = {
  ...colorPalette,
  white: "#fcfcfc",
  malibu: "#7ad7ff",
  lightning: "#ffbc17",
  footballBrown: "#3a2300",
  footballBrownBorder: "#9d5e00",
  fieldBackgroundGreen: "#142108",
  fieldMarkingsGreen: "#2b3722", // used for color of yard increment lines and yardage numbers
  endZoneBackgroundGreen: "#0b1204",
  lineOfScrimmageGray: "#ababb7",
  lightGray: "#dddddd",
  punch: "#da3731",
  cerulean: "#009ee1",
};
