import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { PlayerModel } from "../../../../generated/from-api/models/relationships/player.model";
import { API_BASE_URL } from "../../constants";

export const PlayerService = {
  /**
   * Retrieve a list of all players for a team, if the user has permission to view the players
   * @param teamId
   * @returns Player[]
   */
  GET_ALL_PLAYERS_FOR_TEAM: async function (
    teamId: string
  ): Promise<PlayerModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.PLAYER}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },

  /**
   * Retrieve a player by id, if the user has permission to view the player
   * @param playerId
   * @returns Player
   */
  GET: async function (playerId: string): Promise<PlayerModel> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.PLAYER}/${playerId}`))
      .data;
  },

  /**
   * Update a player, if the user has permission to do so
   * @param playerId
   * @param dataToUpdate
   * @returns Player
   */
  PUT: async function (
    playerId: string,
    dataToUpdate: Pick<
      PlayerModel,
      | "firstName"
      | "lastName"
      | "positions"
      | "heightInInches"
      | "weightInLBS"
      | "schoolYear"
      | "nickname"
      | "jerseyNumber"
      | "hideOnLeaderBoard"
    >
  ): Promise<PlayerModel> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.PLAYER}`, {
        id: playerId,
        ...dataToUpdate,
      })
    ).data;
  },

  // POST - intentionally left blank, players are created by the system when invitations are accepted

  /**
   * Delete a player by id, if the user has permission to do so
   * @param playerId
   * @returns
   */
  DELETE: async function (playerId: string): Promise<{ deleted: boolean }> {
    return (
      await axios.delete(`${API_BASE_URL}${APIRoutes.PLAYER}/${playerId}`)
    ).data;
  },

  /**
   * Use to upload a player profile image, it will be automatically added to your player profile.
   * @param data
   */
  POST_UPLOAD_PLAYER_PROFILE_IMAGE: async function (
    data: FormData
  ): Promise<{ url: string }> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.PLAYER}/upload-picture`,
        data
      )
    ).data;
  },

  /**
   * Use to create a player for Coach Login.
   * @param data
   */
  CREATE_PLAYER: async function (
    data: Pick<
      PlayerModel,
      | "firstName"
      | "lastName"
      | "positions"
      | "heightInInches"
      | "weightInLBS"
      | "schoolYear"
      | "nickname"
      | "jerseyNumber"
      | "hideOnLeaderBoard"
      | "teamId"
    >
  ): Promise<{ url: string }> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.PLAYER}/create`, {
        ...data,
      })
    ).data;
  },
};
