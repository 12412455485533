import invert from "lodash/invert";
import uniqueId from "lodash/uniqueId";

import { PlayCategoryEnum } from "../../../generated/from-api/models/enums/play-category.enum";
import { PlayTypeEnum } from "../../../generated/from-api/models/enums/play-type.enum";
import { CategoryOption } from "./drawing.types";
import {
  DefensivePosition,
  FillPattern,
  OffensivePosition,
  Position,
  SpecialTeamsPosition,
} from "../../../generated/from-api/models/drawable/drawablePlayer.model";
import { TagCategoryEnum } from "../../../generated/from-api/models/enums/tag-category.enum";
import { colors } from "./play-editor-colors";
import { PlayModel } from "../../../generated/from-api/models/play.model";

// Special Teams not yet designed
export const categoryOptions: CategoryOption[] = [
  { value: "Run", label: "Run" },
  { value: "Pass", label: "Pass" },
  { value: "Defense", label: "Defense" },
  {
    value: "SpecialTeams",
    label: "Special Teams [Coming Soon]",
    disabled: true,
  },
];

export const categoryToPlayTypeMap: Record<PlayCategoryEnum, PlayTypeEnum> = {
  Run: "Offensive",
  Pass: "Offensive",
  Defense: "Defensive",
  SpecialTeams: "SpecialTeams",
};

// omit SpecialTeams because not yet designed
export const categories: PlayCategoryEnum[] = ["Run", "Pass", "Defense"];

// per matt and design, we no longer want to use options "DEFENSE" and "SPECIAL TEAMS"
// for grouping scheme tags
export const tagCategories: TagCategoryEnum[] = [
  TagCategoryEnum.RUN,
  TagCategoryEnum.PASS,
  TagCategoryEnum.DEFENSE,
];

export const fillPatterns: FillPattern[] = [
  "empty",
  "filled",
  "semiLeft",
  "semiRight",
  "semiTop",
  "semiBottom",
];

export const offensivePositions: OffensivePosition[] = [
  "QB",
  "RB",
  "WR",
  "TE",
  "C",
  "OG",
  "OT",
];

export const defensivePositions: DefensivePosition[] = [
  "LB",
  "CB",
  "SS",
  "FS",
  "DE",
  "DT",
  "NT",
];

export const specialTeamsPositions: SpecialTeamsPosition[] = [
  "K",
  "H",
  "PR",
  "KR",
  "KOS",
  "P",
  "LS",
];

export const allPositions: Position[] = [
  ...offensivePositions,
  ...defensivePositions,
  ...specialTeamsPositions,
];

// these are the options we will feed to the "Ball On" slider
// values are chosen based on how far apart the slider marks are in designs
export const ballOnOptions = [
  { value: 0, label: "1" },
  { value: 2, label: "2" },
  { value: 4, label: "3" },
  { value: 6, label: "4" },
  { value: 8, label: "5" },
  { value: 11, label: "10" },
  { value: 14, label: "15" },
  { value: 17, label: "20" },
  { value: 20, label: "25" },
  { value: 23, label: "30" },
  { value: 27, label: "40" },
  { value: 31, label: "50" },
  { value: 35, label: "40" },
  { value: 39, label: "30" },
  { value: 42, label: "25" },
  { value: 45, label: "20" },
  { value: 48, label: "15" },
  { value: 51, label: "10" },
  { value: 54, label: "5" },
  { value: 56, label: "4" },
  { value: 58, label: "3" },
  { value: 60, label: "2" },
  { value: 62, label: "1" },
];

export const ballOnSliderValueMax = 62;

// convert slider value to actual value of "ballOn"
export const ballOnSliderValueToActualValueMap: Record<number, number> = {
  0: 1,
  2: 2,
  4: 3,
  6: 4,
  8: 5,
  11: 10,
  14: 15,
  17: 20,
  20: 25,
  23: 30,
  27: 40,
  31: 50,
  35: 60,
  39: 70,
  42: 75,
  45: 80,
  48: 85,
  51: 90,
  54: 95,
  56: 96,
  58: 97,
  60: 98,
  62: 99,
};

// convert actual value of "Ball On" back to slider value
export const ballOnActualValueToSliderValueMap = invert(
  ballOnSliderValueToActualValueMap
);

export const lineOfScrimmageY = 0.5;

export const baseOffensiveFormationPlayers = [
  {
    id: uniqueId(),
    x: 0.5,
    y: lineOfScrimmageY,
    color: colors.gray,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.44,
    y: lineOfScrimmageY,
    color: colors.gray,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.38,
    y: lineOfScrimmageY,
    color: colors.gray,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.56,
    y: lineOfScrimmageY,
    color: colors.gray,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.62,
    y: lineOfScrimmageY,
    color: colors.gray,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.5,
    y: lineOfScrimmageY + 0.14,
    color: colors.blue,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.46,
    y: lineOfScrimmageY + 0.17,
    color: colors.green,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.3,
    y: lineOfScrimmageY + 0.05,
    color: colors.green,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.2,
    y: lineOfScrimmageY,
    color: colors.green,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.1,
    y: lineOfScrimmageY + 0.05,
    color: colors.green,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.9,
    y: lineOfScrimmageY,
    color: colors.green,
    fillPattern: "empty" as FillPattern,
    playType: "Offensive" as PlayTypeEnum,
  },
];

export const baseDefensiveFormationPlayers = [
  {
    id: uniqueId(),
    x: 0.35,
    y: lineOfScrimmageY + 0.09,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.45,
    y: lineOfScrimmageY + 0.09,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.55,
    y: lineOfScrimmageY + 0.09,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.65,
    y: lineOfScrimmageY + 0.09,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.4,
    y: lineOfScrimmageY + 0.18,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.5,
    y: lineOfScrimmageY + 0.18,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.6,
    y: lineOfScrimmageY + 0.18,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.225,
    y: lineOfScrimmageY + 0.23,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.765,
    y: lineOfScrimmageY + 0.23,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.32,
    y: lineOfScrimmageY + 0.29,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
  {
    id: uniqueId(),
    x: 0.55,
    y: lineOfScrimmageY + 0.4,
    color: colors.gray,
    fillPattern: "filled" as FillPattern,
    playType: "Defensive" as PlayTypeEnum,
  },
];

export const BASE_OFFENSIVE_FORMATION = {
  id: "0",
  name: "Offensive",
  type: "Offensive" as PlayTypeEnum,
  drawablePlayers: baseOffensiveFormationPlayers,
  deleted: false,
};

export const BASE_DEFENSIVE_FORMATION = {
  id: "1",
  name: "Defensive",
  type: "Defensive" as PlayTypeEnum,
  drawablePlayers: baseDefensiveFormationPlayers,
  deleted: false,
};

export const BASE_PLAY: PlayModel = {
  name: "Loading...",
  playType: "Offensive",
  playCategory: "Run",
  notes: "",
  drawablePlayers: [],
  ballOn: 40,
  hideOpponent: false,
  published: false,
  deleted: false,
};

export const coverageZoneRadiusMin = 0.02;
export const coverageZoneRadiusMax = 0.25;
