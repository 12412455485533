import stylesDashboardGeneral from "../../../Dashboard.module.scss";
const styles = { ...stylesDashboardGeneral };

import ReadinessGraph from "../../../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/ReadinessGraph";
import CharacterInput from "../../../../../components/Input/CharacterInput";
import searchIcon from "../../../../../resources/images/search.svg";
import { INPUT_TYPES } from "../../../../../utils/web-only-constants";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReadinessScoresContext } from "../../../../../shared/shared-with-mobile/providers/readinessScores.provider";
import { DashboardStatsGrid } from "../../../../../components/DashboardStatsGrid/DashboardStatsGrid";
import { PlayerModel } from "../../../../../generated/from-api/models/relationships/player.model";
import { isEmpty } from "lodash";
import SingleSelect from "../../../../../components/SingleSelect/SingleSelect";
import { BarDatum } from "@nivo/bar";
import { TagsContext } from "../../../../../shared/shared-with-mobile/providers/tags.provider";
import { PlayerToQuizReadinessModel } from "../../../../../generated/from-api/models/dtos/player-to-play.readiness.model";
import { TagModel } from "../../../../../generated/from-api/models/tag.model";
import { TagTypeEnum } from "../../../../../generated/from-api/models/enums/tag-type.enum";
import { PlayerReadinessSnapshotModel } from "../../../../../generated/from-api/models/readiness-snapshot/player-readiness-snapshot.model";
import ReadinessOverTime from "../../../../../shared/shared-with-mobile/dashboard-widgets/ReadinessOverTime/ReadinessOverTime";
import useWindowDimensions from "../../../../../shared/shared-with-mobile/custom-hook/WindowDimensions.hook";
import { DashboardUtility } from "../../../../../shared/shared-with-mobile/utilities/dashboard.utility";
interface Props {
  player: PlayerModel;
  playerReadinessOverTime: PlayerReadinessSnapshotModel[];
}

const PlayerQuizzes: React.FC<Props> = ({
  player,
  playerReadinessOverTime,
}) => {
  const { playerQuizMetrics } = useContext(ReadinessScoresContext);
  const { customQuizCategoryTags } = useContext(TagsContext);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchText, setSearchText] = useState("");

  const [dataForPlayerStatsGrid, setDataForPlayerStatsGrid] = useState<
    PlayerToQuizReadinessModel[]
  >();

  const [quizCategories, setQuizCategories] = useState([
    {
      label: "All",
      value: "",
    },
  ]);
  const [readinessByCategory, setReadinessByCategory] = useState<BarDatum[]>(
    []
  );

  const { width } = useWindowDimensions();
  const [isFlipped, setIsFlip] = useState<boolean>(false);

  useEffect(() => {
    width < 1210 ? setIsFlip(true) : setIsFlip(false);
  }, [width]);

  useEffect(() => {
    if (
      player &&
      playerQuizMetrics &&
      !isEmpty(playerQuizMetrics) &&
      customQuizCategoryTags
    ) {
      const playerStatsOnly = DashboardUtility.selectPlayerQuizzes(
        player,
        playerQuizMetrics
      );
      setDataForPlayerStatsGrid(playerStatsOnly);

      const newCategories = customQuizCategoryTags.map((c) => {
        return { label: c.name, value: c.id ? c.id : "" };
      });
      newCategories.unshift({ label: "All", value: "" });
      setQuizCategories(newCategories);

      // modifiedCategoryTags allows us to group stats as "Uncategorized"
      const modifiedCategoryTags: TagModel[] = [
        ...customQuizCategoryTags,
        {
          id: undefined,
          type: TagTypeEnum.CUSTOM_QUIZ_CATEGORY,
          category: null,
          createdBy: "",
          name: "Uncategorized",
          teamId: null,
        },
      ];

      // currentCategoriesSet allows us to determine when a stat is using an orphaned categoryId
      const currentCategoriesSet = new Set(
        customQuizCategoryTags.map((tag) => tag.id)
      );

      const dataForCharts = modifiedCategoryTags.map((tag) => {
        const categoryId = tag.id;
        let numInCategory = 0;
        const scoreSum = playerStatsOnly.reduce((prev, cur) => {
          if (
            cur.categoryId === categoryId || // found a matching category, add score for matching category
            (categoryId === undefined && cur.categoryId === null) || // no category found, add score for "Uncategorized"
            (categoryId === undefined && // orphaned categoryId found, add score for "Uncategorized"
              !currentCategoriesSet.has(cur.categoryId))
          ) {
            numInCategory++;
            return prev + +cur.readinessScore;
          } else {
            return prev;
          }
        }, 0);

        return {
          category: tag.name,
          averageScore: numInCategory == 0 ? 0 : scoreSum / numInCategory,
        };
      });
      setReadinessByCategory(dataForCharts);
    }
  }, [playerQuizMetrics, player, customQuizCategoryTags]);

  const getCategoryName = (id: string) => {
    if (customQuizCategoryTags) {
      const existing = customQuizCategoryTags.find((lc) => lc.id === id);
      if (existing) {
        return existing.name;
      }
    }
    return "Uncategorized";
  };

  const columnsForQuizzes = useMemo(
    () => [
      {
        name: "FLASHCARD",
        selector: (row: PlayerToQuizReadinessModel) => row.quizName,
        cell: (row: PlayerToQuizReadinessModel) => {
          return (
            <div className={styles.nameColumn}>
              <span className={styles.lessonName}>{row.quizName}</span>
            </div>
          );
        },
        sortable: true,
        grow: 2,
      },
      {
        name: "CATEGORY",
        selector: (row: PlayerToQuizReadinessModel) => row.categoryId,
        format: (row: PlayerToQuizReadinessModel): string =>
          `${getCategoryName(row.categoryId)}`,
        sortable: true,
        right: true,
      },
      {
        name: "FLASHCARD READINESS",
        selector: (row: PlayerToQuizReadinessModel) => +row.readinessScore,
        format: (row: PlayerToQuizReadinessModel): string =>
          `${parseFloat(row.readinessScore).toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    []
  );

  return (
    <div>
      <div className={styles.chartCardRow}>
        <ReadinessOverTime
          title="Flashcard Readiness"
          xLabel="Date"
          yLabel="Readiness"
          ySymbol="%"
          data={
            playerReadinessOverTime
              ? [
                  {
                    id: "line1",
                    data: playerReadinessOverTime.map((t) => {
                      return {
                        x: t.created,
                        y: +t.quizReadinessScore,
                      };
                    }),
                  },
                ]
              : []
          }
        />
        <ReadinessGraph
          title="Flashcard Readiness by Category"
          type="Bar"
          isFlipped={isFlipped}
          xLabel="Category"
          yLabel="Readiness"
          ySymbol="%"
          data={readinessByCategory}
          indexBy="category"
          valueKey="averageScore"
        />
      </div>

      <div className={styles.searchAndFilterBtnForPlayerDrillIn}>
        <div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Search"
            id="search"
            size="x-small"
            icon={searchIcon}
            clearButton
            value={searchText}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
        </div>
        <div className={styles.filterBtns}>
          <SingleSelect
            id="quizPlayerDrillInCategory"
            placeholder="Category *"
            alwaysOpen={false}
            options={quizCategories}
            className={styles.selectCategories}
            onChange={(e) => {
              setSelectedCategory(e.currentTarget.value);
            }}
          />
        </div>
      </div>
      <br />
      <div>
        <DashboardStatsGrid
          data={dataForPlayerStatsGrid?.filter((m) => {
            return (
              (selectedCategory ? m.categoryId == selectedCategory : true) &&
              (searchText
                ? m.quizName
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase(), 0) !== -1
                : true)
            );
          })}
          columns={columnsForQuizzes}
        />
      </div>
    </div>
  );
};

export default PlayerQuizzes;
