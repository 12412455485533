import { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import styles from "./FileUpload.module.scss";
import Button from "../Button/Button";

interface Props {
  file: Blob;
  closeModal: () => void;
  croppingComplete: (event: FormData) => void;
  shape: "circle" | "square";
  teamId?: string;
  setFormData?: React.Dispatch<React.SetStateAction<FormData | undefined>>;
  setPreview?: React.Dispatch<React.SetStateAction<Blob | undefined>>;
}

interface cropInterface {
  aspect?: number;
  unit?: "px" | "%";
  width?: number;
  height?: number;
  x?: number;
  y?: number;
}

const CropImageModal: React.FC<Props> = ({
  file,
  closeModal,
  croppingComplete,
  shape,
  teamId,
  setFormData,
  setPreview,
}) => {
  const [image, setImage] = useState("");
  const [crop, setCrop] = useState<cropInterface>({
    aspect: 1,
    unit: "px",
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const imageRef = useRef<HTMLImageElement>();
  const [img, setImg] = useState<HTMLImageElement>();

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        setImage(e.target ? (e.target.result as string) : "");
      }
    };

    reader.readAsDataURL(file);
  }, [file]);

  const onImageLoaded = (image: HTMLImageElement) => {
    imageRef.current = image;
    setImg(image);
  };

  const handleCrop = async () => {
    if (imageRef.current) {
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      const data = new FormData();
      data.append("image", file);
      if (crop.x && crop.y && crop.width && crop.height) {
        data.append("x", `${Math.floor(crop.x * scaleX)}`);
        data.append("y", `${Math.floor(crop.y * scaleY)}`);
        data.append("width", `${Math.floor(crop.width * scaleX)}`);
        data.append("height", `${Math.floor(crop.height * scaleY)}`);
      }

      if (!teamId && setFormData && setPreview && img) {
        const canvas = document.createElement("canvas");
        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        canvas.width = crop.width ? crop.width : 0;
        canvas.height = crop.height ? crop.height : 0;
        const ctx = canvas.getContext("2d");
        if (crop.x && crop.y && crop.width && crop.height) {
          ctx?.drawImage(
            img,
            crop.x ? Math.floor(crop.x * scaleX) : 0,
            crop.y ? Math.floor(crop.y * scaleY) : 0,
            crop.width ? Math.floor(crop.width * scaleX) : 0,
            crop.height ? Math.floor(crop.height * scaleY) : 0,
            0,
            0,
            crop.width,
            crop.height
          );
        }
        const base64Data = canvas.toDataURL("image/png");
        const base64 = await fetch(base64Data);
        const blob = await base64.blob();
        if (blob.size === 0) {
          setPreview(file);
        } else {
          setPreview(blob);
        }

        setFormData(data);
        closeModal();
      } else {
        croppingComplete(data);
      }
    }
  };

  return (
    <>
      <form className={styles.form}>
        <ReactCrop
          style={{ maxHeight: "calc(100vh - 200px)" }}
          src={image}
          crop={crop}
          circularCrop={shape === "circle"}
          onChange={(newCrop) => setCrop(newCrop)}
          onImageLoaded={onImageLoaded}
        />
        <div className="formFooter" style={{ marginTop: "1rem" }}>
          <Button theme="secondary" size={"small"} onClick={closeModal}>
            Cancel
          </Button>
          <Button size={"small"} onClick={handleCrop}>
            Upload
          </Button>
        </div>
      </form>
    </>
  );
};

export default CropImageModal;
