import { Ellipse } from "react-konva";
import { Transformer } from "react-konva";
import { hexToRgbA, reduceAlpha } from "./playEditor.utils";
import { ShapeModel } from "./drawing.types";
import { useEffect, useRef, useState } from "react";
import { DrawablePlayerModel } from "../../../generated/from-api/models/drawable/drawablePlayer.model";
import {
  coverageZoneRadiusMax,
  coverageZoneRadiusMin,
} from "./playEditor.constants";
interface Props {
  shape: ShapeModel;
  stageWidth: number;
  stageHeight: number;
  selectedPlayer?: DrawablePlayerModel;
  players?: DrawablePlayerModel[];
  setPlayers?: React.Dispatch<React.SetStateAction<DrawablePlayerModel[]>>;
}

const CoverageZone: React.FC<Props> = ({
  shape,
  stageWidth,
  stageHeight,
  selectedPlayer,
  players,
  setPlayers,
}) => {
  const [isCircleOnDragStart, setIsCircleOnDragStart] = useState(false);
  const [isTransforming, setIsTransforming] = useState(false);
  const ellipseRef = useRef<any>();
  const transformerRef = useRef<any>();

  useEffect(() => {
    if (selectedPlayer && selectedPlayer?.id === shape.id) {
      transformerRef.current.nodes([ellipseRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedPlayer]);

  // by default, place the coverage zone circle to be centered where its parent shape is centered
  let centerX = shape.x;
  let centerY = shape.y;

  // if a route exists, then center coverage zone circle at the last point in route
  if (shape.route) {
    const { line } = shape.route;
    const lastPointInLine = line.slice(Math.max(line.length - 2, 0));
    if (lastPointInLine.length === 2) {
      centerX = lastPointInLine[0];
      centerY = lastPointInLine[1];
    }
  }

  const coverageZoneAlpha = 0.35;
  const rgbA = hexToRgbA(shape.color);
  const semitransparentFill = reduceAlpha(rgbA, coverageZoneAlpha);

  if (!shape.ellipseRadiusX || !shape.ellipseRadiusY) {
    return null;
  }

  const updateCoverageZone = (radiusX: number, radiusY: number) => {
    if (players && setPlayers) {
      const nextPlayers = players.map((player) => {
        if (player.id !== selectedPlayer?.id) {
          return player;
        }

        return {
          ...player,
          coverageZoneRadiusX: radiusX,
          coverageZoneRadiusY: radiusY,
          coverageZoneRadius: radiusX, // temporarily continue to store deprecated property "coverageZoneRadius" to support older clients
        };
      });
      setPlayers(nextPlayers);
    }
  };

  return (
    <>
      <Ellipse
        id={`coverage-zone-${shape.id}`}
        x={centerX * stageWidth}
        y={centerY * stageHeight}
        ref={ellipseRef}
        fill={semitransparentFill}
        stroke={!isTransforming ? semitransparentFill : "transparent"}
        listening={false}
        radiusX={shape.ellipseRadiusX * stageWidth}
        radiusY={shape.ellipseRadiusY * stageWidth}
        onTransformStart={() => {
          setIsTransforming(true);

          const node = ellipseRef.current;
          const width = node.width();
          const height = node.height();
          setIsCircleOnDragStart(width === height);
        }}
        onTransform={(e) => {
          const node = ellipseRef.current;
          const isHorizontalMovement = !!Math.abs((e as any)?.evt?.movementX);

          // manually force a circle to scale both horizontally and vertically at same time
          if (isCircleOnDragStart) {
            if (isHorizontalMovement) {
              // apply horizontal scaling to the vertical
              node.scaleY(node.scaleX());
            } else {
              // apply vertical scaling to the horizontal
              node.scaleX(node.scaleY());
            }
          }
        }}
        onTransformEnd={() => {
          setIsTransforming(false);
          const node = ellipseRef.current;

          // store scaleX and scaleY before resetting
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // reset scale
          node.scaleX(1);
          node.scaleY(1);

          // use scale to calculate new dimensions
          const newWidth = node.width() * scaleX;
          const newHeight = node.height() * scaleY;
          const newRadiusX = newWidth / 2 / stageWidth;
          const newRadiusY = newHeight / 2 / stageWidth;
          updateCoverageZone(newRadiusX, newRadiusY);
        }}
      />
      {selectedPlayer && selectedPlayer?.id === shape.id && (
        <Transformer
          ref={transformerRef}
          rotateEnabled={false}
          borderEnabled={false}
          anchorSize={6}
          centeredScaling={true}
          flipEnabled={false}
          enabledAnchors={[
            "top-center",
            "middle-right",
            "middle-left",
            "bottom-center",
          ]}
          onMouseDown={(e) => {
            e.cancelBubble = true; // prevent event from bubbling up to canvas stage element
          }}
          anchorStroke="transparent"
          anchorStrokeWidth={10} // high anchor stroke width with transparent color to increase clickable area
          boundBoxFunc={(prevEllipse, newEllipse) => {
            // limit resize, enforce a minimum and maximum radius
            const radiusX = newEllipse.width / 2;
            const radiusY = newEllipse.height / 2;
            const minRadius = coverageZoneRadiusMin * stageWidth;
            const maxRadius = coverageZoneRadiusMax * stageWidth;
            if (
              radiusX < minRadius ||
              radiusY < minRadius ||
              radiusX > maxRadius ||
              radiusY > maxRadius
            ) {
              return prevEllipse;
            }
            return newEllipse;
          }}
        />
      )}
    </>
  );
};

export default CoverageZone;
