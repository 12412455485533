import { useContext, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import styles from "./LoginSignup.module.scss";
import Logo from "../../resources/images/Logo.svg";
import CharacterInput from "../../components/Input/CharacterInput";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import Button from "../../components/Button/Button";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
}

const ForgotPassword: React.FC<RouteComponentProps> = () => {
  const { dispatchToast } = useContext(UIContext);
  const [success, setSuccess] = useState<boolean>(false);
  const submit = async (event: React.FormEvent) => {
    event.preventDefault();

    const {
      email: { value: email },
    } = (event.currentTarget as HTMLFormElement).elements as FormElements;

    await APIService.USER.FORGOT_PASSWORD(email)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        dispatchToast({
          type: "error",
          message: error,
        });
      });
  };

  return (
    <div className={styles.login}>
      <section className={styles.content}>
        <img src={Logo} alt="Team Nation" className={styles.logo} />
        <form className={styles.form} onSubmit={submit}>
          {success ? (
            <div className={styles.success}>
              <div className={styles.check} />
              Request received! Please check your email for a link to reset your
              password.
            </div>
          ) : (
            <>
              <div className={styles.title}>Change Password</div>
              <CharacterInput
                type={INPUT_TYPES.EMAIL}
                placeholder="Email"
                id="email"
              />
              <div className={styles.formFooter}>
                <Button
                  theme="secondary"
                  type={"link"}
                  linkOptions={{ to: "/login" }}
                  size={"small"}
                >
                  Cancel
                </Button>
                <Button size={"small"} buttonType={"submit"}>
                  Continue
                </Button>
              </div>
            </>
          )}
        </form>
      </section>
    </div>
  );
};

export default ForgotPassword;
