import { useContext, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";

import styles from "./LoginSignup.module.scss";
import Logo from "../../resources/images/Logo.svg";
import Hero from "../../resources/images/create-account-hero.png";
import Button from "../../components/Button/Button";
import CharacterInput from "../../components/Input/CharacterInput";
import Checkbox from "../../components/Checkbox/Checkbox";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import visibility from "../../resources/images/visibility.svg";
import visibilityOff from "../../resources/images/visibility_off.svg";
import { auth } from "../../shared/shared-with-mobile/api-client/firebase.utils";
import PhoneInput from "react-phone-input-2";

interface FormElements extends HTMLFormControlsCollection {
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  email: HTMLInputElement;
  password: HTMLInputElement;
  confirmPassword: HTMLInputElement;
  terms: HTMLInputElement;
}

const Signup: React.FC = () => {
  const history = useHistory();
  const { closeModal, dispatchModal } = useContext(UIContext);

  const { currentUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const entryUrl = localStorage.getItem("entryUrl");
  enum errorMessages {
    noUser = "This email is not associated with any accounts.",
  }

  const [phone, setPhone] = useState("");

  const login = async (email: string, password: string) => {
    try {
      const response = await auth.signInWithEmailAndPassword(email, password);

      return response;
    } catch (err: any) {
      const noUserErrorCode = "auth/user-not-found";
      const isEmailNotFoundError = err.code === noUserErrorCode;
      dispatchModal({
        title: "Login Error",
        open: true,
        size: "small",
        className: styles.errorModal,
        body: (
          <>
            <p>{isEmailNotFoundError ? errorMessages.noUser : err.message}</p>
            <Button
              size={"small"}
              onClick={() => goToLoginHandler(err.response.status)}
            >
              {err.response.status === 400 ? "Go to Login" : "Try Again"}
            </Button>
          </>
        ),
      });
    }
  };

  const goToLoginHandler = (status?: number) => {
    closeModal();
    if (status === 400) {
      history.push("/login");
    }
  };

  const signup = async (event: React.FormEvent) => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      password,
      terms,
      confirmPassword,
    } = (event.currentTarget as HTMLFormElement).elements as FormElements;

    const displayError = (message: string, status?: number) => {
      dispatchModal({
        title: "Account Creation Error",
        open: true,
        size: "small",
        className: styles.errorModal,
        body: (
          <>
            <p>{message}</p>
            <Button size={"small"} onClick={() => goToLoginHandler(status)}>
              {status === 400 ? "Go to Login" : "Try Again"}
            </Button>
          </>
        ),
      });
    };

    if (!terms.checked) {
      displayError("Please read and agree with the Terms and Conditions.");
      return;
    } else if (!email.value) {
      displayError("The email address is improperly formatted.");
      return;
    } else if (!password.value || password.value.length < 6) {
      displayError("The password must contain at least 6 characters.");
      return;
    } else if (password.value !== confirmPassword.value) {
      displayError("Passwords do not match. Please try again.");
      return;
      // a valid us phone string will look like +12224443333, which has length 12
    } else if (!phone || phone.length < 12) {
      displayError("Please provide a valid phone number.");
      return;
    } else if (!firstName.value || !lastName.value) {
      displayError("Please provide a first and last name.");
      return;
    }

    try {
      await APIService.USER.SIGNUP_WITH_EMAIL(
        firstName.value,
        lastName.value,
        email.value,
        password.value,
        phone
      );
      login(email.value, password.value);
    } catch (err: any) {
      displayError(err.response.data, err.response.status);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return currentUser ? (
    <Redirect to={entryUrl ? entryUrl : "/roster"} />
  ) : (
    <div className={styles.signup}>
      <section className={styles.content}>
        <img src={Logo} alt="Team Nation" className={styles.logo} />
        <form className={styles.form} onSubmit={signup}>
          <div className={styles.title}>Create Account</div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="First Name"
            id="firstName"
          />
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Last Name"
            id="lastName"
          />
          <CharacterInput
            type={INPUT_TYPES.EMAIL}
            placeholder="Email"
            id="email"
          />
          <PhoneInput
            containerClass="phoneInputContainer"
            inputClass="phoneInput"
            buttonClass="phoneInputButton"
            dropdownClass="phoneInputDropdown"
            placeholder="+1234567890"
            value={phone}
            country={"us"}
            onChange={(p) => setPhone(`+${p}`)}
            inputProps={{
              required: true,
            }}
          />
          <div className={styles.passwordContainer}>
            <CharacterInput
              type={showPassword ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
              placeholder="Password"
              id="password"
            />
            <span
              className={styles.showPassword}
              onClick={togglePasswordVisibility}
            >
              <img src={showPassword ? visibilityOff : visibility} />
            </span>
          </div>
          <div className={styles.passwordContainer}>
            <CharacterInput
              type={showPassword ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
              placeholder="Confirm Password"
              id="confirmPassword"
            />
            <span
              className={styles.showPassword}
              onClick={togglePasswordVisibility}
            >
              <img src={showPassword ? visibilityOff : visibility} />
            </span>
          </div>

          <Checkbox id="terms">
            <>
              I agree with the&nbsp;
              <a
                href="https://www.teamnationsports.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              .
            </>
          </Checkbox>
          <Button size={"small"} buttonType={"submit"}>
            Continue
          </Button>
        </form>
        <div className={styles.helpBlock}>
          <span className={styles.otherCta}>
            Already have an account?
            <br />
            <Link to="login">Login</Link>
          </span>
          <Button
            className={styles.helpButton}
            type={"external link"}
            externalLinkOptions={{
              href: "https://knowledge.teamnationsports.com",
            }}
            theme="tertiary"
            size={"x-small"}
          >
            Help
          </Button>
        </div>
      </section>
      <section className={styles.visuals}>
        <img src={Hero} alt="Product Shots" className={styles.hero} />
        <h1 className={styles.heroTitle}>Automatically gamify your playbook</h1>
        <p className={styles.heroText}>
          Teach your schemes through competitive learning games Team Nation
          automatically generates from your playbook.
        </p>
      </section>
    </div>
  );
};

export default Signup;
