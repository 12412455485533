import stylesDashboardGeneral from "../../../Dashboard.module.scss";
const styles = { ...stylesDashboardGeneral };

import React, { useContext, useEffect, useMemo, useState } from "react";
import { DashboardStatsGrid } from "../../../../../components/DashboardStatsGrid/DashboardStatsGrid";
import ReadinessGraph from "../../../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/ReadinessGraph";
import { PlayerModel } from "../../../../../generated/from-api/models/relationships/player.model";
import { BarDatum } from "@nivo/bar";
import SingleSelect from "../../../../../components/SingleSelect/SingleSelect";
import CharacterInput from "../../../../../components/Input/CharacterInput";
import searchIcon from "../../../../../resources/images/search.svg";
import { INPUT_TYPES } from "../../../../../utils/web-only-constants";
import { ReadinessScoresContext } from "../../../../../shared/shared-with-mobile/providers/readinessScores.provider";
import {
  PlayerToFormationReadinessModel,
  PlayerToPlayReadinessModel,
} from "../../../../../generated/from-api/models/dtos/player-to-play.readiness.model";
import { isEmpty } from "lodash";
import { PlayerReadinessSnapshotModel } from "../../../../../generated/from-api/models/readiness-snapshot/player-readiness-snapshot.model";
import ReadinessOverTime from "../../../../../shared/shared-with-mobile/dashboard-widgets/ReadinessOverTime/ReadinessOverTime";
import ToggleSelect from "../../../../../shared/shared-with-mobile/components/ToggleSelect/ToggleSelect";
interface Props {
  player: PlayerModel;
  playerReadinessOverTime: PlayerReadinessSnapshotModel[];
}

enum PlaybookFilters {
  Plays = "Plays",
  Formations = "Formations",
}

const PlayerPlaybook: React.FC<Props> = ({
  player,
  playerReadinessOverTime,
}) => {
  const { playerPlayMetrics, playerFormationMetrics } = useContext(
    ReadinessScoresContext
  );
  const [dataForPlayerStatsGrid, setDataForPlayerStatsGrid] = useState<
    PlayerToPlayReadinessModel[]
  >();
  const [
    dataForPlayerStatsGridFormation,
    setDataForPlayerStatsGridFormation,
  ] = useState<PlayerToFormationReadinessModel[]>();
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [playbookCategories, setPlaybookCategories] = useState([
    {
      label: "All",
      value: "",
    },
  ]);

  const chartDefaultDataForUnit: BarDatum[] = [
    { category: "Pass", averageScore: 0 },
    { category: "Run", averageScore: 0 },
    { category: "Defence", averageScore: 0 },
    { category: "S.T.", averageScore: 0 },
  ];

  const [readinessByCategory, setReadinessByCategory] = useState<BarDatum[]>(
    chartDefaultDataForUnit
  );

  const [
    selectedFilterForPlayersAndPlays,
    setSelectedFilterForPlayersAndPlays,
  ] = useState<PlaybookFilters>(PlaybookFilters.Plays);

  useEffect(() => {
    if (
      player &&
      playerFormationMetrics &&
      !isEmpty(playerFormationMetrics) &&
      selectedFilterForPlayersAndPlays === PlaybookFilters.Formations
    ) {
      const playerStatsOnly = playerFormationMetrics.filter(
        (f) => f.playerId === player.id
      );
      setDataForPlayerStatsGridFormation(playerStatsOnly);
      const formationTypes = Array.from(
        new Set(
          playerFormationMetrics.map((p) => p.formationType as string)
        ).keys()
      );
      const newCategories = formationTypes.map((c) => {
        return { label: c, value: c };
      });
      newCategories.unshift({ label: "All", value: "" });
      setPlaybookCategories(newCategories);
      const dataForCharts = formationTypes.map((c) => {
        let numInCategory = 0;
        const scoreSum = playerStatsOnly.reduce((prev, cur) => {
          if (cur.formationType == c) {
            numInCategory++;
            return prev + +cur.readinessScore;
          } else {
            return prev;
          }
        }, 0);

        return {
          category: c,
          averageScore: numInCategory == 0 ? 0 : scoreSum / numInCategory,
        };
      });
      setReadinessByCategory(dataForCharts);
    }
  }, [playerFormationMetrics, player, selectedFilterForPlayersAndPlays]);

  useEffect(() => {
    if (
      player &&
      playerPlayMetrics &&
      !isEmpty(playerPlayMetrics) &&
      selectedFilterForPlayersAndPlays === PlaybookFilters.Plays
    ) {
      const playerStatsOnly = playerPlayMetrics.filter(
        (p) => p.playerId == player.id
      );
      setDataForPlayerStatsGrid(playerStatsOnly);
      const categories = Array.from(
        new Set(playerPlayMetrics.map((p) => p.category as string)).keys()
      );

      const newCategories = categories.map((c) => {
        return { label: c, value: c };
      });
      newCategories.unshift({ label: "All", value: "" });
      setPlaybookCategories(newCategories);
      const dataForCharts = categories.map((c) => {
        let numInCategory = 0;
        const scoreSum = playerStatsOnly.reduce((prev, cur) => {
          if (cur.category == c) {
            numInCategory++;
            return prev + +cur.readinessScore;
          } else {
            return prev;
          }
        }, 0);

        return {
          category: c,
          averageScore: numInCategory == 0 ? 0 : scoreSum / numInCategory,
        };
      });
      setReadinessByCategory(dataForCharts);
    }
  }, [playerPlayMetrics, player, selectedFilterForPlayersAndPlays]);

  const formationColumns = useMemo(
    () => [
      {
        name: "FORMATION",
        selector: (row: PlayerToFormationReadinessModel) => row.formationName,
        cell: (row: PlayerToFormationReadinessModel) => {
          return (
            <div className={styles.nameColumn}>
              <span className={styles.playName}>{row.formationName}</span>
            </div>
          );
        },
        sortable: true,
        right: false,
      },
      {
        name: "TYPE",
        selector: (row: PlayerToFormationReadinessModel) => row.formationType,
        sortable: true,
        right: true,
      },
      {
        name: "PERFORMANCE (ALL TIME)",
        selector: (row: PlayerToFormationReadinessModel) =>
          parseFloat(row.readinessScore),
        format: (row: PlayerToFormationReadinessModel) =>
          `${parseFloat(row.readinessScore).toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    []
  );

  const playColumns = useMemo(
    () => [
      {
        name: "PLAY",
        selector: (row: PlayerToPlayReadinessModel) => row.playName,
        cell: (row: PlayerToPlayReadinessModel) => {
          return (
            <div className={styles.nameColumn}>
              <span className={styles.playName}>{row.playName}</span>
            </div>
          );
        },
        sortable: true,
        right: false,
      },
      {
        name: "CATEGORY",
        selector: (row: PlayerToPlayReadinessModel) => row.category,
        sortable: true,
        right: true,
      },
      {
        name: "PERFORMANCE (ALL TIME)",
        selector: (row: PlayerToPlayReadinessModel) =>
          parseFloat(row.readinessScore),
        format: (row: PlayerToPlayReadinessModel) =>
          `${parseFloat(row.readinessScore).toFixed(2)}%`,
        sortable: true,
        right: true,
      },
    ],
    []
  );

  return (
    <div>
      <div className={styles.chartCardRow}>
        <ReadinessOverTime
          title="Playbook Readiness"
          xLabel="Date"
          yLabel="Readiness"
          ySymbol="%"
          data={
            playerReadinessOverTime
              ? [
                  {
                    id: "line1",
                    data: playerReadinessOverTime.map((t) => {
                      return {
                        x: t.created,
                        y: +t.playbookReadinessScore,
                      };
                    }),
                  },
                ]
              : []
          }
        />
        <ReadinessGraph
          title="Playbook Readiness by Category"
          type="Bar"
          xLabel="Unit"
          yLabel="Readiness"
          ySymbol="%"
          data={readinessByCategory}
          indexBy="category"
          valueKey="averageScore"
        />
      </div>
      <div className={styles.searchAndFilterBtnForPlayerDrillIn}>
        <div>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Search"
            id="search"
            size="x-small"
            icon={searchIcon}
            clearButton
            value={searchText}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
        </div>
        <div>
          <ToggleSelect
            options={Object.values(PlaybookFilters)}
            selectedOption={selectedFilterForPlayersAndPlays}
            background="#151724"
            borderColor="#151724"
            onChange={(newSelection: string) => {
              setSelectedCategory("");
              setSelectedFilterForPlayersAndPlays(
                newSelection as PlaybookFilters
              );
            }}
          />
        </div>
        <div className={styles.filterBtns}>
          <SingleSelect
            id="playbookPlayerDrillInCategory"
            placeholder="Category *"
            alwaysOpen={false}
            options={playbookCategories}
            className={styles.selectCategories}
            onChange={(e) => {
              setSelectedCategory(e.currentTarget.value);
            }}
          />
        </div>
      </div>
      <br />
      <div>
        {selectedFilterForPlayersAndPlays === PlaybookFilters.Formations ? (
          <DashboardStatsGrid
            data={dataForPlayerStatsGridFormation?.filter((m) => {
              return (
                (selectedCategory
                  ? m.formationType == selectedCategory
                  : true) &&
                (searchText
                  ? m.formationName
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase(), 0) !== -1
                  : true)
              );
            })}
            columns={formationColumns}
          />
        ) : (
          <DashboardStatsGrid
            data={dataForPlayerStatsGrid?.filter((m) => {
              return (
                (selectedCategory ? m.category == selectedCategory : true) &&
                (searchText
                  ? m.playName
                      .toLowerCase()
                      .indexOf(searchText.toLowerCase(), 0) !== -1
                  : true)
              );
            })}
            columns={playColumns}
          />
        )}
      </div>
    </div>
  );
};

export default PlayerPlaybook;
