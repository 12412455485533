import * as React from "react";

const ChevronDownIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path d="M0.94 0.529785L4 3.58312L7.06 0.529785L8 1.46979L4 5.46979L0 1.46979L0.94 0.529785Z" />
  </svg>
);

export default ChevronDownIcon;
