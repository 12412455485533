import React, { useContext, useState } from "react";

import styles from "./FileUpload.module.scss";
import { FILE_UPLOAD_TYPES } from "../../utils/web-only-constants";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import CropImageModal from "./CropImageModal";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";

interface Props {
  id?: string;
  value?: string;
  icon?: string;
  helperText?: string;
  teamId?: string; // used to upload team logo instead of profile image
  uploadType?: FILE_UPLOAD_TYPES;
  setFormData?: React.Dispatch<React.SetStateAction<FormData | undefined>>;
  formData?: FormData;
}

const FileUpload: React.FC<Props> = ({
  id,
  value = "",
  icon,
  helperText,
  teamId, // used to upload team logo instead of profile image
  uploadType = FILE_UPLOAD_TYPES.USER_AVATAR,
  setFormData,
  formData,
}) => {
  const { dispatchModal, closeModal, dispatchToast } = useContext(UIContext);
  const { updateTeam } = useContext(TeamContext);
  const { userProfile, updateProfile } = useContext(UserContext);
  const [inputValue, setInputValue] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState(value || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preview, setPreview] = useState<Blob | undefined>();

  const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!event.currentTarget && event.currentTarget.files) {
      setInputValue(event.currentTarget ? event.currentTarget.value : "");
      const file: Blob = event.currentTarget.files[0];
      if (file) {
        dispatchModal({
          title: "Crop Image",
          open: true,
          size: "medium",
          body: (
            <CropImageModal
              setPreview={setPreview}
              setFormData={setFormData}
              teamId={teamId}
              file={file}
              croppingComplete={submitCroppedImage}
              closeModal={handleClose}
              shape={
                uploadType === FILE_UPLOAD_TYPES.USER_AVATAR
                  ? "circle"
                  : "square"
              }
            />
          ),
        });
        // Input value needs to be reset inside of the event in the case that a user wants to try to recrop the same image
        setInputValue("");
      }
    }
  };

  const handleClose = () => {
    closeModal();
    setInputValue("");
  };

  const submitCroppedImage = async (data: FormData) => {
    closeModal();

    try {
      if (data) {
        setIsLoading(true);
        let result: { url: string } = { url: "" };
        if (teamId) {
          result = await APIService.TEAM.POST_UPLOAD_LOGO(teamId, data);
          // make sure to sync latest data on team context
          const updatedTeam = await APIService.TEAM.GET(teamId);
          updateTeam(updatedTeam);
        } else if (!teamId && !formData) {
          result = await APIService.USER_PROFILE.POST_UPLOAD_PROFILE_IMAGE(
            data
          );
          // make sure to sync latest data on user context (user profile)
          if (userProfile) {
            const updatedUserProfile = await APIService.USER_PROFILE.GET(
              userProfile.id
            );
            updateProfile(updatedUserProfile || userProfile);
          }
        }
        setIsLoading(false);
        setProfileImageUrl(result.url);
      }
    } catch (err) {
      dispatchToast({
        type: "error",
        message: "There's been an error. Please try again.",
      });
    }
  };

  return (
    <>
      <label htmlFor={id} className={`${styles.label} ${styles[uploadType]}`}>
        {isLoading && <div className={styles.loader} />}
        {icon && <img src={icon} className={styles.icon} alt="icon" />}
        {(profileImageUrl || preview) && !isLoading && (
          <img
            id="logo-image"
            src={
              profileImageUrl ? profileImageUrl : URL.createObjectURL(preview)
            }
            className={styles.uploadedImage}
            alt={profileImageUrl ? "uploaded image" : "preview"}
          />
        )}
        <input
          id={id}
          accept="image/jpeg, image/png, image/gif"
          type="file"
          className={styles.input}
          onChange={handleAddImage}
          value={inputValue}
        />
        <span
          className={`${styles.helperText} ${
            profileImageUrl ? styles.imageUploaded : ""
          }`}
        >
          {!value ? helperText : "CHANGE"}
        </span>
      </label>
    </>
  );
};

export default FileUpload;
