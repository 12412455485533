import { Arrow, Line, Group, Circle } from "react-konva";
import { LineSegmentsModel } from "../../../generated/from-api/models/drawable/lineSegments.model";

import {
  convertRelativeLineToAbsolute,
  getNextPoint,
  convertLineToSegments,
  hexToRgbA,
  reduceAlpha,
} from "./playEditor.utils";
import ZigzagLine from "./ZigzagLine";

interface Props {
  lineData: LineSegmentsModel;
  stageWidth: number;
  stageHeight: number;
  stroke: string;
  strokeWidth: number;
  shouldHideLineCap: boolean;
  isPreviewingRoute?: boolean;
}

const LineSegments: React.FC<Props> = ({
  lineData,
  stageWidth,
  stageHeight,
  stroke,
  strokeWidth,
  shouldHideLineCap,
  isPreviewingRoute = true,
}) => {
  const { line, lineTypes, lineCap } = lineData;
  const circleLineCapRadius = Math.round(stageWidth / 160); // ~ 6
  const dashSize = Math.round(stageWidth / 135); // ~ 7
  const gapSize = Math.round(stageWidth / 190); // ~ 5
  const arrowCapLength = Math.ceil(stageWidth / 160); // ~ 6
  const arrowCapWidth = Math.ceil(stageWidth / 110); // ~ 9

  const segments = convertLineToSegments(line);

  let flatLineCapPoints: number[] = [];
  let circleLineCapCenter: number[] = [];

  // return a transparentized version if necessary
  const getColor = (color: string): string => {
    if (isPreviewingRoute) {
      return reduceAlpha(hexToRgbA(color), 0.6);
    } else {
      return color;
    }
  };

  return (
    <Group listening={false}>
      {segments.map((segment, index) => {
        // const lineAngle = Math.atan2(yEnd - yStart, xEnd - xStart);
        const isLastSegment = index === segments.length - 1;

        let modifiedStrokeColor: string = stroke;

        if (isLastSegment && isPreviewingRoute) {
          modifiedStrokeColor = getColor(stroke);
        }

        // caluclate how to draw the "flat" lineCap
        if (isLastSegment) {
          const absoluteSegment = convertRelativeLineToAbsolute(
            segment,
            stageWidth,
            stageHeight
          );

          const flatLineCapSize = stageWidth / 96; // ~ 10

          const [xStart, yStart, xEnd, yEnd] = absoluteSegment;
          const lineAngle = Math.atan2(yEnd - yStart, xEnd - xStart);
          const perpendicularAngle = lineAngle + Math.PI / 2;
          const otherPerpendicularAngle = lineAngle - Math.PI / 2;

          const lineCapPointOne = getNextPoint(
            xEnd,
            yEnd,
            flatLineCapSize,
            perpendicularAngle
          );
          const lineCapPointTwo = getNextPoint(
            xEnd,
            yEnd,
            flatLineCapSize,
            otherPerpendicularAngle
          );

          flatLineCapPoints = [...lineCapPointOne, ...lineCapPointTwo];
          circleLineCapCenter = [xEnd, yEnd];
        }

        const shouldShowArrowCap = isLastSegment && lineCap === "arrow";
        return lineTypes[index] !== "zigzag" ? (
          <Arrow
            key={index}
            points={convertRelativeLineToAbsolute(
              segment,
              stageWidth,
              stageHeight
            )}
            dash={lineTypes[index] === "dashed" ? [dashSize, gapSize] : []}
            pointerLength={
              shouldShowArrowCap && !shouldHideLineCap ? arrowCapLength : 0
            }
            pointerWidth={
              shouldShowArrowCap && !shouldHideLineCap ? arrowCapWidth : 0
            }
            fill={modifiedStrokeColor}
            stroke={modifiedStrokeColor}
            strokeWidth={strokeWidth}
          />
        ) : (
          <ZigzagLine
            key={index}
            line={segment}
            stageWidth={stageWidth}
            stageHeight={stageHeight}
            showArrow={shouldShowArrowCap}
            stroke={modifiedStrokeColor}
            strokeWidth={strokeWidth}
          />
        );
      })}
      {lineCap === "flat" && !shouldHideLineCap && (
        <Line
          points={flatLineCapPoints}
          stroke={getColor(stroke)}
          strokeWidth={strokeWidth}
        />
      )}
      {lineCap === "circle" && !shouldHideLineCap && (
        <Circle
          radius={circleLineCapRadius}
          x={circleLineCapCenter[0]}
          y={circleLineCapCenter[1]}
          fill={getColor(stroke)}
        />
      )}
    </Group>
  );
};

export default LineSegments;
