import React from "react";
import Button from "../../Button/Button";
import styles from "./FreeTrialBanner.module.scss";

interface Props {
  className?: string;
  width?: "full" | "auto";
}

const FreeTrialBanner: React.FC<Props> = ({
  className = "",
  width = "full",
}) => {
  return (
    <>
      <div
        className={`${styles.trialBanner} ${styles.primary} ${styles[width]}  ${
          className && className
        }`}
      >
        <span>
          Your free trial limits your team’s ability to master your playbook.
          Upgrade today to unlock your team’s full learning potential.
        </span>
        <Button
          className={styles.upgradeButton}
          type={"external link"}
          externalLinkOptions={{
            href: "https://teamnationsports.com/pricing/",
          }}
          theme="primary"
          size={"x-small"}
        >
          Upgrade Now
        </Button>
      </div>
    </>
  );
};

export default FreeTrialBanner;
