import React from "react";
import { useContext } from "react";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import styles from "../Installs.module.scss";
import helmet from "../../../resources/images/helmet.svg";
import { InstallStatisticModel } from "../../../generated/from-api/models/install-statistic.model";

interface PlayerParticipationListProps {
  stats: InstallStatisticModel[];
}

const PlayerParticipationList: React.FC<PlayerParticipationListProps> = ({
  stats,
}) => {
  const { currentPlayersAsArray } = useContext(PlayersContext);

  // Get the completd players and sort
  const completedPlayers = stats
    .filter((stat: InstallStatisticModel) => {
      return stat.completedAt !== null;
    })
    .sort((a: InstallStatisticModel, b: InstallStatisticModel) => {
      const aPlr = currentPlayersAsArray.filter((p) => p.id === a.playerId)[0];
      const bPlr = currentPlayersAsArray.filter((p) => p.id === b.playerId)[0];
      return (bPlr.firstName + " " + bPlr.lastName).localeCompare(
        aPlr.firstName + " " + aPlr.lastName
      );
    });

  // get uncompleted and sort
  let uncompletedPlayers = stats.filter((stat: InstallStatisticModel) => {
    return stat.completedAt === null;
  });

  for (const player of currentPlayersAsArray) {
    if (stats.filter((stat) => stat.playerId === player.id).length === 0) {
      uncompletedPlayers.push({
        installId: stats[0]?.installId,
        teamId: stats[0]?.teamId,
        playerId: player.id as string,
        currentProgress: 0,
        score: 0,
        correct: 0,
        incorrect: 0,
      });
    }
  }

  uncompletedPlayers = uncompletedPlayers.sort(
    (a: InstallStatisticModel, b: InstallStatisticModel) => {
      const aPlr = currentPlayersAsArray.filter((p) => p.id === a.playerId)[0];
      const bPlr = currentPlayersAsArray.filter((p) => p.id === b.playerId)[0];
      return (bPlr.firstName + " " + bPlr.lastName).localeCompare(
        aPlr.firstName + " " + aPlr.lastName
      );
    }
  );

  // The player line used by both sides of the list
  const playerLine = (playerStat: InstallStatisticModel) => {
    const statPlayer = currentPlayersAsArray.filter(
      (player) => player.id === playerStat.playerId
    );
    let player;
    if (statPlayer.length === 1) {
      player = statPlayer[0];
    } else {
      player = {
        id: "unknown",
        firstName: "Unknown",
        lastName: "",
      };
    }
    return (
      <li
        key={`install-player-stat-${playerStat.id}-player-${player.id}-score`}
      >
        <img
          className={styles.playerToolTipListImage}
          src={player.profileImageUrl || helmet}
          alt={player.firstName + " " + player.lastName}
        />
        <span className={styles.playerToolTipListName}>
          {player.firstName + " " + player.lastName}
        </span>
      </li>
    );
  };

  // The component
  return (
    <ol className={styles.playerToolTipList}>
      {completedPlayers.length > 0 ? (
        <li
          key={`install-player-completion-completed-header`}
          className={styles.playerToolTipListTitle}
        >
          Completed
        </li>
      ) : null}
      {completedPlayers.map(playerLine)}
      {uncompletedPlayers.length > 0 ? (
        <li
          key={`install-player-completion-not-completed-header`}
          className={styles.playerToolTipListTitle}
        >
          Not Completed
        </li>
      ) : null}
      {uncompletedPlayers.map(playerLine)}
    </ol>
  );
};

export default PlayerParticipationList;
