import { useLayoutEffect } from "react";
import styles from "./PhonePreview.module.scss";

interface PhonePreviewProps {
  containerClass: string;
  children: JSX.Element;
  widthRatio?: number;
  heightRatio?: number;
}

const PhonePreview: React.FC<PhonePreviewProps> = ({
  children,
  containerClass,
  widthRatio = 0.75, // defaulting to 0.75 means phone preview will occupy 75% the width of its container
  heightRatio = 0.75,
}) => {
  const updatePreviewSize = () => {
    const container = document.querySelector(
      `.${containerClass}`
    ) as HTMLElement;
    const phone = document.querySelector(
      `.${styles.phonePreviewContainer}`
    ) as HTMLElement;

    if (container === null || phone === null) {
      return;
    } else if (
      !phone.getBoundingClientRect().width ||
      !phone.getBoundingClientRect().height
    ) {
      return;
    }

    // If this is the first hit, store the base size in a data attribute for simpler math later.
    if (phone.dataset.originalW === undefined) {
      phone.dataset.originalW = String(phone.getBoundingClientRect().width);
      phone.dataset.originalH = String(phone.getBoundingClientRect().height);
    }

    // Then determine and apply best zoom from those numbers
    if (phone !== null && phone.dataset !== null) {
      const wScale =
        (container.getBoundingClientRect().width * widthRatio) /
        Number(phone.dataset.originalW);
      const hScale =
        (container.getBoundingClientRect().height * heightRatio) /
        Number(phone.dataset.originalH);

      const finalScale = Math.min(wScale, hScale);
      (document.querySelector(
        `.${styles.phonePreviewContainer}`
      ) as HTMLElement).style.transform = `scale(${String(finalScale)}`;
    }
  };

  useLayoutEffect(() => {
    updatePreviewSize();
    window.addEventListener("resize", updatePreviewSize);

    return () => {
      window.removeEventListener("resize", updatePreviewSize);
    };
  }, []);

  return (
    <div className={styles.phonePreviewContainer}>
      <div className={styles.phonePreview}>{children}</div>
    </div>
  );
};

export default PhonePreview;
