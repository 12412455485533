import { useContext, useState } from "react";

import CharacterInput from "../../../components/Input/CharacterInput";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { DeleteModal } from "./DeleteModal";
import { InvitationsContext } from "../../../shared/shared-with-mobile/providers/invitations.provider";
import { PlayersContext } from "../../../shared/shared-with-mobile/providers/players.provider";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import {
  invitationTypes,
  PLAYER_HEIGHT,
  PLAYER_WEIGHT,
  SCHOOL_YEARS,
} from "../../../shared/shared-with-mobile/constants";
import {
  defensivePositions,
  offensivePositions,
  specialTeamsPositions,
} from "../../../shared/shared-with-mobile/play-editor/playEditor.constants";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import { PlayerOrInvitee, ROSTER_TYPE } from "./EditRoster";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./InvitePlayerModal.scss";
import ContentLimitModal from "../../../components/ContentLimitModal/ContentLimitModal";
import Button from "../../../components/Button/Button";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { SchoolYearEnum } from "../../../generated/from-api/models/enums/school-year.enum";
import Switch from "../../../components/Switch/Switch";
import { UserContext } from "../../../shared/shared-with-mobile/providers/user.provider";

interface FormElements extends HTMLFormControlsCollection {
  fName: HTMLInputElement;
  lName: HTMLInputElement;
  JNumber?: HTMLInputElement;
  playerSchoolYear?: HTMLInputElement;
  playerWeight?: HTMLInputElement;
  positions: HTMLFieldSetElement;
}

interface Props {
  player?: PlayerOrInvitee;
}

const InvitePlayer: React.FC<Props> = ({ player }) => {
  const {
    closeModal,
    dispatchToast,
    dispatchSingleModal,
    handleCreateError,
  } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);
  const { addOrUpdateInvitation, removeInvitation } = useContext(
    InvitationsContext
  );
  const { userProfile } = useContext(UserContext);
  const { addOrUpdatePlayer, removePlayer } = useContext(PlayersContext);
  const [phone, setPhone] = useState(player ? player.toPhoneNumber : "");
  const [multiSelectPlaceholder] = useState<any>("Positions");
  const [playerHeight, setPlayerHeight] = useState(
    player ? player.heightInInches : null
  );
  const [
    hidePlayerFromLeaderboard,
    setHidePlayerFromLeaderboard,
  ] = useState<boolean>(
    player && player.hideOnLeaderBoard ? player.hideOnLeaderBoard : false
  );

  const inviteOrEditPlayer = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!currentTeam) {
      return;
    }

    const formValue = (event.currentTarget as HTMLFormElement)
      .elements as FormElements;
    const firstName = formValue.fName.value;
    const lastName = formValue.lName.value;
    const positions = formValue.positions.firstChild;
    // these fields below are for editing a player only
    const jerseyNumber = formValue.JNumber?.value;
    const weightInLBS = formValue.playerWeight?.value;
    const schoolYear = formValue.playerSchoolYear?.value;

    const isPlayer = player && player.type === ROSTER_TYPE.PLAYER;
    const isInvite = player && player.type === ROSTER_TYPE.INVITE;

    try {
      if (isPlayer) {
        const updatedPlayer = await APIService.PLAYER.PUT(
          player?.id as string,
          {
            firstName,
            lastName,
            positions:
              (positions as HTMLLegendElement).innerText !=
              multiSelectPlaceholder
                ? (positions as HTMLLegendElement).innerText.replaceAll(
                    ", ",
                    "|"
                  )
                : "",
            jerseyNumber,
            schoolYear: schoolYear ? (schoolYear as SchoolYearEnum) : null,
            heightInInches: Number(playerHeight),
            weightInLBS: Number(weightInLBS),
            hideOnLeaderBoard: hidePlayerFromLeaderboard,
          }
        );
        addOrUpdatePlayer(updatedPlayer);
      } else {
        let newPlayerInvitation;
        if (isInvite) {
          newPlayerInvitation = await APIService.INVITATION.PUT({
            firstName,
            lastName,
            id: player?.id,
            playerPositions:
              (positions as HTMLLegendElement).innerText !=
              multiSelectPlaceholder
                ? (positions as HTMLLegendElement).innerText.replaceAll(
                    ", ",
                    "|"
                  )
                : "",
            coachName:
              userProfile?.firstName || userProfile?.lastName
                ? userProfile?.firstName + " " + userProfile?.lastName
                : undefined,
          });
        } else {
          newPlayerInvitation = await APIService.INVITATION.POST({
            type: invitationTypes.PLAYER,
            firstName,
            lastName,
            toPhoneNumber: phone,
            teamId: currentTeam.id as string,
            playerPositions:
              (positions as HTMLLegendElement).innerText !=
              multiSelectPlaceholder
                ? (positions as HTMLLegendElement).innerText.replaceAll(
                    ", ",
                    "|"
                  )
                : "",
            coachName:
              userProfile?.firstName && userProfile?.lastName
                ? userProfile?.firstName + " " + userProfile?.lastName
                : undefined,
          });
        }

        if (newPlayerInvitation) {
          addOrUpdateInvitation(newPlayerInvitation);
        }
      }
      closeModal();
    } catch (error) {
      handleCreateError(
        error,
        ContentLimitModal,
        "player / player invitation",
        "players / player invitations"
      );
    }
  };

  const deletePlayerOrInvite = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (player && player.type === ROSTER_TYPE.PLAYER) {
        const deletedPlayer = await APIService.PLAYER.DELETE(player.id);
        if (deletedPlayer.deleted) {
          removePlayer(player.id);
        }
      } else if (player && player.type === ROSTER_TYPE.INVITE) {
        const deletedInvitation = await APIService.INVITATION.DELETE(player.id);
        if (deletedInvitation) {
          removeInvitation(player.id);
        }
      }
      closeModal();
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "There's been an error. Please try again.",
      });
    }
  };

  return (
    <form onSubmit={inviteOrEditPlayer}>
      <CharacterInput
        type={INPUT_TYPES.TEXT}
        placeholder="First Name"
        id="fName"
        value={player && player.firstName}
        autofocus={true}
      />
      <CharacterInput
        type={INPUT_TYPES.TEXT}
        placeholder="Last Name"
        value={player && player.lastName}
        id="lName"
      />
      <PhoneInput
        containerClass="phoneInputContainer"
        inputClass="phoneInput"
        buttonClass="phoneInputButton"
        dropdownClass="phoneInputDropdown"
        placeholder="+1234567890"
        disabled={!!player}
        value={phone}
        country={"us"}
        onChange={(p) => setPhone(`+${p}`)}
        inputProps={{
          required: true,
        }}
      />
      <MultiSelect
        placeholder={multiSelectPlaceholder}
        id="positions"
        options={[
          ...offensivePositions,
          ...defensivePositions,
          ...specialTeamsPositions,
        ].map((position) => ({ label: position, value: position }))}
        value={player?.positions}
      />
      {player && player.type !== ROSTER_TYPE.INVITE && (
        <>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Number"
            maxLength={2}
            max="99"
            value={player && player.jerseyNumber}
            id="JNumber"
          />
          <SingleSelect
            id="playerWeight"
            placeholder="Weight"
            options={PLAYER_WEIGHT.map((weight: any) => ({
              label: weight,
              value: weight,
            }))}
            value={player && player.weightInLBS}
          />
          <SingleSelect
            id="playerHeight"
            placeholder="Height"
            options={PLAYER_HEIGHT.map((height: any) => ({
              label: height.name,
              value: height.value,
            }))}
            value={player && player.heightInInches}
            onChange={(e) => setPlayerHeight(+e.currentTarget.value)}
          />
          <SingleSelect
            id="playerSchoolYear"
            placeholder="Year"
            options={SCHOOL_YEARS.map((schoolYear: any) => ({
              label: schoolYear,
              value: schoolYear,
            }))}
            value={player && player.schoolYear}
          />
          <div className="toggle">
            <div className="switch">
              <Switch
                theme={"yellow"}
                size={"normal"}
                toggled={!hidePlayerFromLeaderboard}
                onToggle={() =>
                  setHidePlayerFromLeaderboard(!hidePlayerFromLeaderboard)
                }
              />
            </div>
            <span className="help-text">
              <div>Appear on Leaderboard</div>
              <span>
                With this option active, your performance will be visible to all
                players, and will affect team statistics.
              </span>
            </span>
          </div>
        </>
      )}

      <div className="formFooter">
        {player && (
          <Button
            theme="tertiary"
            size={"small"}
            destructive
            onClick={() =>
              dispatchSingleModal({
                title: "Remove player from team",
                size: "medium",
                body: (
                  <DeleteModal
                    type="player"
                    onSubmit={(event) => deletePlayerOrInvite(event)}
                  />
                ),
                open: true,
              })
            }
          >
            Delete
          </Button>
        )}
        <Button theme="secondary" size={"small"} onClick={closeModal}>
          Cancel
        </Button>
        <Button size={"small"} buttonType="submit">
          {player ? "Edit" : "Invite"}
        </Button>
      </div>
    </form>
  );
};

export default InvitePlayer;
