import React, { useState } from "react";

import { TagFolderModel } from "../../../generated/from-api/models/tag.model";

export const TagFoldersContext = React.createContext<{
  storeAllTagFolders: (t: TagFolderModel[]) => void;
  clearAllTagFolders: () => void;
  addTagFolder: (tagFolder: TagFolderModel) => void;
  updateTagFolder: (tagFolder: TagFolderModel) => void;
  removeTagFolder: (tagFolderId: string) => void;
  tagFolders: TagFolderModel[];
}>({
  storeAllTagFolders: () => null,
  clearAllTagFolders: () => null,
  addTagFolder: () => null,
  updateTagFolder: () => null,
  removeTagFolder: () => null,
  tagFolders: [],
});

export const TagFoldersProvider: React.FC = ({ children }) => {
  const [tagFolders, setTagFolders] = useState<TagFolderModel[]>([]);

  const storeAllTagFolders = (tagFolders: TagFolderModel[]) => {
    setTagFolders(tagFolders);
  };

  const addTagFolder = (tagFolderToAdd: TagFolderModel) => {
    setTagFolders([...tagFolders, tagFolderToAdd]);
  };

  const updateTagFolder = (tagFolderToUpdate: TagFolderModel) => {
    const nextTagFolders = [...tagFolders];
    const indexToUpdate = nextTagFolders.findIndex(
      (tagFolder: TagFolderModel) => tagFolder.id === tagFolderToUpdate.id
    );
    nextTagFolders[indexToUpdate] = tagFolderToUpdate;
    setTagFolders(nextTagFolders);
  };

  const removeTagFolder = (tagFolderToRemoveId: string) => {
    const nextTagFolders = [...tagFolders];
    const indexToDelete = nextTagFolders.findIndex(
      (tagFolder: TagFolderModel) => tagFolder.id === tagFolderToRemoveId
    );
    nextTagFolders.splice(indexToDelete, 1);
    setTagFolders(nextTagFolders);
  };

  const clearAllTagFolders = () => {
    setTagFolders([]);
  };

  return (
    <TagFoldersContext.Provider
      value={{
        storeAllTagFolders,
        clearAllTagFolders,
        addTagFolder,
        updateTagFolder,
        removeTagFolder,
        tagFolders,
      }}
    >
      {children}
    </TagFoldersContext.Provider>
  );
};
