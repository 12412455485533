import { useState, useContext } from "react";

import styles from "./SaveAsFormationModal.module.scss";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { FormationsContext } from "../../../shared/shared-with-mobile/providers/formations.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { FormationModel } from "../../../generated/from-api/models/drawable/formation.model";
import CharacterInput from "../../../components/Input/CharacterInput";
import PlayThumbnail from "../../../components/drawing/PlayThumbnail";
import Button from "../../../components/Button/Button";

interface Props {
  formation: FormationModel;
}

const SaveAsFormationModal: React.FC<Props> = ({ formation }) => {
  const { addFormations } = useContext(FormationsContext);
  const { closeModal, dispatchToast } = useContext(UIContext);

  const [formationToCreate, setFormationToCreate] = useState(formation);
  const [errorMessage, setErrorMessage] = useState("");

  const createFormation = async () => {
    try {
      const created = await APIService.FORMATION.POST(formationToCreate);
      if (created) {
        addFormations([created]);
        dispatchToast({
          type: "success",
          message: "New formation saved.",
        });
        closeModal();
      }
    } catch (e: any) {
      if (e?.response?.status === 400) {
        setErrorMessage("That name was already taken. Please try another.");
      }
    }
  };

  return (
    <div className={styles.saveAsFormationModal}>
      <CharacterInput
        placeholder={
          !formationToCreate.name ? "Name of the formation" : undefined
        }
        maxLength={100}
        value={formationToCreate.name}
        onChange={(e) => {
          setFormationToCreate({
            ...formationToCreate,
            name: e.currentTarget.value,
          });
        }}
      />
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      <PlayThumbnail formation={formation} />
      <div className={styles.buttonRow}>
        <Button size={"small"} theme={"tertiary"} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          size={"small"}
          onClick={createFormation}
          disabled={!formationToCreate.name}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default SaveAsFormationModal;
