import React, { useState } from "react";
import { LicenseTierModel } from "../../../../../api/src/shared-with-mobile-and-web/models/license-tier.model";

export const LicenseTiersContext = React.createContext<{
  isLicenseTiersLoaded: boolean;
  licenseTiers: LicenseTierModel[];
  updateLicenseTiers: (licenseTiers: LicenseTierModel[]) => void;
  clearLicenseTiers: () => void;
}>({
  isLicenseTiersLoaded: false,
  licenseTiers: [],
  updateLicenseTiers: () => null,
  clearLicenseTiers: () => null,
});

export const LicenseTiersProvider: React.FC = ({ children }) => {
  const [isLicenseTiersLoaded, setIsLicenseTiersLoaded] = useState<boolean>(
    false
  );
  const [licenseTiers, setLicenseTiers] = useState<LicenseTierModel[]>([]);

  const updateLicenseTiers = (licenseTiers: LicenseTierModel[]) => {
    setLicenseTiers(licenseTiers);
    setIsLicenseTiersLoaded(true);
  };

  const clearLicenseTiers = () => {
    setLicenseTiers([]);
  };

  return (
    <LicenseTiersContext.Provider
      value={{
        isLicenseTiersLoaded,
        licenseTiers,
        updateLicenseTiers,
        clearLicenseTiers,
      }}
    >
      {children}
    </LicenseTiersContext.Provider>
  );
};
