import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { FormationModel } from "../../../../generated/from-api/models/drawable/formation.model";
import { API_BASE_URL } from "../../constants";

export const FormationService = {
  /**
   * Use to retrieve a list of formations for a team or from the global library if no team id is specified
   */
  LIST: async function (teamId?: string): Promise<FormationModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.FORMATION}`, {
        params: {
          teamId: teamId,
        },
      })
    ).data;
  },
  /**
   * Use to retrieve a specific formation
   */
  GET: async function (formationId: string): Promise<FormationModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.FORMATION}/${formationId}`)
    ).data;
  },
  /**
   * Use to create a formation
   * @param
   */
  POST: async function (
    formationData: Pick<
      FormationModel,
      | "teamId" // "teamId" is present when the user is creating a formation for the specific team
      | "name"
      | "type"
      | "drawablePlayers"
    >
  ): Promise<FormationModel | undefined> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.FORMATION}`, formationData)
    ).data;
  },
  /**
   * Use to update a formation
   * @param
   */
  PUT: async function (
    formationData: Pick<
      FormationModel,
      "id" | "name" | "type" | "drawablePlayers"
    >
  ): Promise<FormationModel | undefined> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.FORMATION}`, formationData)
    ).data;
  },
  /**
   * Use to delete a formation
   * @param formationId
   * @returns
   */
  DELETE: async function (formationId: string): Promise<{ deleted: boolean }> {
    return (
      await axios.delete(`${API_BASE_URL}${APIRoutes.FORMATION}/${formationId}`)
    ).data;
  },
};
