import axios from "axios";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { TeamModel } from "../../../../generated/from-api/models/team.model";
import { TeamMembership } from "../../../../generated/from-api/models/utility/team-membership.model";
import { API_BASE_URL } from "../../constants";

// UPDATED 5/14/21
export const TeamService = {
  /**
   * Use to retrieve the team membership for this user, does not retrieve deleted teams.
   */
  GET_ALL_TEAMS_VISIBLE_TO_USER: async function (
    userId: string
  ): Promise<TeamMembership> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.TEAM}`, {
        params: {
          userId: userId,
          playsFor: true,
          coachesFor: true,
          created: true,
        },
      })
    ).data;
  },
  /**
   * Use to retrieve the team membership for this user, does not retrieve deleted teams.
   */
  GET_ALL_TEAMS_THAT_USER_PLAYS_FOR: async function (
    userId: string
  ): Promise<Pick<TeamMembership, "playsFor">> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.TEAM}`, {
        params: { userId: userId, playsFor: true },
      })
    ).data;
  },
  /**
   * Use to retrieve the team membership for this user, does not retrieve deleted teams.
   */
  GET_ALL_TEAMS_THAT_USER_COACHES_FOR: async function (
    userId: string
  ): Promise<Pick<TeamMembership, "coachesFor">> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.TEAM}`, {
        params: { userId: userId, coachesFor: true },
      })
    ).data;
  },
  /**
   * Use to retrieve the team membership for this user, does not retrieve deleted teams.
   */
  GET_ALL_TEAMS_CREATED_BY_USER: async function (
    userId: string
  ): Promise<Pick<TeamMembership, "created">> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.TEAM}`, {
        params: { userId: userId, created: true },
      })
    ).data;
  },

  /**
   * Use to retrieve a specific team, does not retrieve deleted teams.
   */
  GET: async function (teamId: string): Promise<TeamModel> {
    return (await axios.get(`${API_BASE_URL}${APIRoutes.TEAM}/${teamId}`)).data;
  },
  /**
   * Use to create a team
   * @param teamData Data to use for creating the team
   * @returns Newly created Team
   */
  POST: async function (
    teamData: Pick<
      TeamModel,
      | "name"
      | "level"
      | "primaryColor"
      | "secondaryColor"
      | "city"
      | "state"
      | "postalCode"
    >
  ): Promise<TeamModel | undefined> {
    return (await axios.post(`${API_BASE_URL}${APIRoutes.TEAM}`, teamData))
      .data;
  },
  /**
   * Use to upload a team logo, it will be automatically added to the team.
   * @param data
   */
  POST_UPLOAD_LOGO: async function (
    teamId: string,
    data: FormData
  ): Promise<{ url: string }> {
    return (
      await axios.post(
        `${API_BASE_URL}${APIRoutes.TEAM}/upload-logo/${teamId}`,
        data
      )
    ).data;
  },
  /**
   * Use to update a team
   * @param teamData Data to update
   * @returns Updated Team
   */
  PUT: async function (
    teamData: Pick<
      TeamModel,
      | "id"
      | "name"
      | "level"
      | "primaryColor"
      | "secondaryColor"
      | "city"
      | "state"
      | "postalCode"
      | "logo"
    >
  ): Promise<TeamModel | undefined> {
    return (await axios.put(`${API_BASE_URL}${APIRoutes.TEAM}`, teamData)).data;
  },
  /**
   * Use to delete a team
   * @param teamId
   * @returns
   */
  DELETE: async function (teamId: string): Promise<{ deleted: boolean }> {
    return (await axios.delete(`${API_BASE_URL}${APIRoutes.TEAM}/${teamId}`))
      .data;
  },
};
