import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./LicenseExpirationModal.module.scss";
import CloseIconWhite from "../../resources/icons/CloseIconWhite";
import DynamicTimerIcon from "../DynamicTimerIcon/DynamicTimerIcon";
import { DateTime } from "luxon";
import Button from "../Button/Button";
import { LICENSE_TIERS } from "../../generated/from-api/api-constants/license-tier-constants";
import {
  GRACE_PERIOD_DAYS,
  LICENSE_EXPIRATION_DISMISS_LOCAL_STORAGE_KEY,
} from "../../shared/shared-with-mobile/constants";
import { LimitedLicenseModel } from "../../generated/from-api/models/license.model";

interface Props {
  doesLicenseBelongToUser: boolean;
  isSuspended: boolean;
  license: LimitedLicenseModel;
  daysRemaining: number; // this can be calculated based on the license.expires, but currently it's being passed in pre-calculated to avoid repeating the calculation
  handleClose: () => void;
}

const LicenseExpirationModal: React.FC<Props> = ({
  license,
  daysRemaining,
  handleClose,
  doesLicenseBelongToUser,
  isSuspended,
}) => {
  const history = useHistory();

  const licenseTierName = Object.values(LICENSE_TIERS).find(
    (tier) => tier.id === license.licenseTierId
  )?.userFacingSubscriptionName;

  const handleDismiss = () => {
    const dismissUntilDate = new Date();
    const NUM_DAYS_TO_DISMISS = daysRemaining <= 7 ? 1 : 3; // if one week remaining, dismiss for 1 day. otherwise dismiss for 3 days.
    dismissUntilDate.setDate(new Date().getDate() + NUM_DAYS_TO_DISMISS);
    const dismissUntilDateString = dismissUntilDate.toISOString();
    window.localStorage.setItem(
      LICENSE_EXPIRATION_DISMISS_LOCAL_STORAGE_KEY,
      dismissUntilDateString
    );
    handleClose();
  };

  const getShopifyProductURL = () => {
    const youthURL =
      "https://team-nation-sports.myshopify.com/products/youth-flag-renewal";
    const highSchoolURL =
      "https://team-nation-sports.myshopify.com/products/high-school-renewal";
    const highSchoolPlusURL =
      "https://team-nation-sports.myshopify.com/products/high-school-plus-renewal";
    const collegeURL =
      "https://team-nation-sports.myshopify.com/products/college-renewal";

    const licenseToUrlMap: Record<string, string> = {
      "20000000-0000-0000-0000-000000000000": youthURL,
      "20000000-1000-0000-0000-000000000000": youthURL,
      "20000000-2000-0000-0000-000000000000": youthURL,
      "30000000-0000-0000-0000-000000000000": highSchoolURL,
      "30000000-1000-0000-0000-000000000000": highSchoolURL,
      "40000000-0000-0000-0000-000000000000": highSchoolPlusURL,
      "40000000-1000-0000-0000-000000000000": highSchoolPlusURL,
      "50000000-0000-0000-0000-000000000000": collegeURL,
      "50000000-2000-0000-0000-000000000000": collegeURL,
    };

    // If everything works as intended, then it should never have to use the default url, it should always find one from the map.
    const productUrl =
      licenseToUrlMap[license.licenseTierId] ||
      "https://teamnationsports.com/pricing/";

    return productUrl;
  };

  return (
    <div className={styles.licenseExpirationModal}>
      <div className={styles.header}>
        <div>
          {daysRemaining > 0 ? "License Expiring Soon" : "License Expired"}
        </div>
        {!isSuspended && (
          <button onClick={handleDismiss} className={styles.closeModalBtn}>
            <CloseIconWhite />
          </button>
        )}
      </div>
      <div className={styles.body}>
        <DynamicTimerIcon timerNum={daysRemaining} />
        <div className={styles.bodyText}>
          {daysRemaining > 0 ? (
            <>
              <div>
                Your Team Nation {licenseTierName} subscription expires in:
              </div>
              <div className={styles.large}>
                {daysRemaining} DAY{daysRemaining !== 1 ? "S" : ""}
              </div>
              <div className={styles.centered}>
                Renew before{" "}
                {DateTime.fromISO(license.expires).toFormat("MM/dd/yyyy")} to
                preserve access for your team.
              </div>
            </>
          ) : (
            <div>
              Your team&apos;s {licenseTierName} subscription has expired.
              {doesLicenseBelongToUser ? (
                " Renew your subscription using the link below."
              ) : (
                <>
                  <span> Please contact the license holder, </span>
                  <a href={`mailto:${license.email}`}>{license.email}</a>
                  <span>, to renew.</span>
                </>
              )}
              {!isSuspended &&
                `This team will be automatically suspended in ${
                  daysRemaining + GRACE_PERIOD_DAYS
                } days.`}
            </div>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        {isSuspended && (
          <Button
            size="medium"
            theme="secondary"
            onClick={() => {
              handleClose();
              history.push("/account/subscriptions");
            }}
          >
            My Account
          </Button>
        )}
        {!isSuspended && (
          <Button size="medium" theme="secondary" onClick={handleDismiss}>
            Dismiss
          </Button>
        )}
        <Button
          size="medium"
          theme="secondary"
          onClick={() => {
            window.open(
              "https://knowledge.teamnationsports.com/submit-a-ticket",
              "_blank"
            );
          }}
        >
          Contact Team Nation
        </Button>
        {doesLicenseBelongToUser && (
          <>
            <Button
              size="medium"
              onClick={() => {
                const url = getShopifyProductURL();
                window.open(url, "_blank");
              }}
            >
              View Pricing
            </Button>
            <Button
              size="medium"
              onClick={() => {
                handleClose();
                history.push("/account/subscriptions", {
                  showRenewLicenseModal: true,
                });
              }}
            >
              Enter Renewal Code
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default LicenseExpirationModal;
