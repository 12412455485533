import { memo, useContext, useEffect } from "react";

import styles from "./Toast.module.scss";
import {
  ToastModel,
  UIContext,
} from "../../shared/shared-with-mobile/providers/ui.provider";

const Toast: React.FC<ToastModel> = ({ id, type, message }) => {
  const { removeToast } = useContext(UIContext);

  useEffect(() => {
    const remove = setTimeout(() => {
      removeToast(id);
    }, 4000);

    return () => {
      window.clearTimeout(remove);
    };
  }, []);

  return <div className={`${styles.toast} ${styles[type]}`}>{message}</div>;
};

export default memo(Toast);
