import React, { useContext, useEffect, useState } from "react";
import styles from "./MediaListItem.module.scss";
import { MediaModel } from "../../../generated/from-api/models/media.model";
import Checkbox from "../../Checkbox/Checkbox";
import VideoIcon from "../../../resources/images/yellowPlayVideoIcon.svg";
import AudioFileIcon from "../../../resources/images/audioFileIcon.svg";
import { Divider } from "@material-ui/core";
import { MediaTagContext } from "../../../shared/shared-with-mobile/providers/mediaTags.provider";
import { TagModel } from "../../../generated/from-api/models/tag.model";
import Tooltip from "../../Tooltip/Tooltip";

interface MediaListItemProps {
  mediaItem: MediaModel;
  handleMediaSelect: (mediaItem: MediaModel) => void;
  handleSingleTap: (mediaItem: MediaModel) => void;
  handleDoubleTap: (mediaItem: MediaModel) => void;
  selected: boolean;
  className: any;
  previewMedia?: MediaModel;
  showCheckbox: boolean;
}

const MediaListItem: React.FC<MediaListItemProps> = ({
  mediaItem,
  handleMediaSelect,
  handleSingleTap,
  handleDoubleTap,
  selected,
  className,
  showCheckbox,
  previewMedia = { id: undefined },
}) => {
  const { mediaItemTags } = useContext(MediaTagContext);
  const [tags, setTags] = useState<TagModel[]>([]);

  useEffect(() => {
    const result = mediaItemTags.filter(
      (media) => media.mediaId == mediaItem.id
    );

    setTags(result[0] === undefined ? [] : result[0].tags);
  }, [mediaItemTags, mediaItem]);
  return (
    <>
      <li
        key={`media-list-item-${mediaItem.id}`}
        className={`card list-item
          ${styles.mediaListItem} ${className} ${
          selected || previewMedia.id == mediaItem.id ? styles.selected : ``
        }
        `}
        onClick={(e: any) => {
          if (e.target.nodeName !== "SPAN") {
            handleSingleTap(mediaItem);
          }
        }}
        onDoubleClick={(e: any) => {
          if (e.target.nodeName !== "SPAN") {
            handleDoubleTap(mediaItem);
          }
        }}
      >
        {showCheckbox && (
          <span className={styles.checkbox}>
            <Checkbox
              onChange={() => {
                handleMediaSelect(mediaItem);
              }}
              checked={selected}
              showCheckbox={true}
            />
          </span>
        )}
        <div className={styles.mediaThumbnail}>
          {mediaItem.type === "IMAGE" && (
            <img
              src={mediaItem.url}
              alt={mediaItem.name}
              className={styles.mediaImage}
            />
          )}
          {mediaItem.type === "VIDEO" && (
            <div style={{ position: "relative" }}>
              <img
                src={mediaItem.thumbnailUrl}
                alt={mediaItem.name}
                className={styles.mediaImage}
              />
              <img
                src={VideoIcon}
                alt="video icon"
                className={styles.videoIcon}
              />
            </div>
          )}
          {mediaItem.type === "AUDIO" && (
            <img
              src={AudioFileIcon}
              alt="audio file icon"
              className={styles.audioIcon}
            />
          )}
        </div>

        <div className={styles.title}>{mediaItem.name}</div>

        <div className={styles.category}>
          <div className={styles.label}>Uploaded on</div>
          <div>
            {mediaItem &&
              mediaItem.fileDate &&
              new Date(mediaItem.fileDate)
                .toDateString()
                .split(" ")
                .slice(1)
                .join(" ")}
          </div>
        </div>
        <div className={styles.tags}>
          <div className={styles.label}>Tags</div>
          <div className={styles.tagsWrapper}>
            {tags.length > 0 ? (
              tags.map((tag: TagModel, i: number) => {
                if (i < 4) {
                  return <div className={styles.tagChip}>{tag.name}</div>;
                } else if (i == 5) {
                  return (
                    <div className={styles.highlighttagChip}>
                      <Tooltip
                        tip={
                          <React.Fragment>
                            <ul style={{ margin: 0, padding: "0 0 0 20px" }}>
                              {tags
                                .slice(4, tags.length)
                                .map((tag: TagModel) => {
                                  return <li key={tag.id}>{tag.name}</li>;
                                })}
                            </ul>
                          </React.Fragment>
                        }
                      >
                        {`+${tags.length - 4}`}
                      </Tooltip>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            ) : (
              <div>None</div>
            )}
          </div>
        </div>
      </li>
      <Divider />
    </>
  );
};

export default MediaListItem;
