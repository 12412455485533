import axios from "axios";

import { MediaModel } from "../../../../generated/from-api/models/media.model";
import { APIRoutes } from "../../../../generated/from-api/api-constants/route-constants";
import { API_BASE_URL } from "../../constants";
import { PlayModel } from "../../../../generated/from-api/models/play.model";

export const MediaService = {
  /**
   * Use to retrieve a list of media for a team or from the global library if no team id is specified
   */
  LIST: async function (
    teamId?: string,
    onlyIfModifiedSince?: string
  ): Promise<MediaModel[]> {
    return (
      await axios.get(`${API_BASE_URL}${APIRoutes.MEDIA}`, {
        params: {
          teamId: teamId,
          onlyIfModifiedSince,
        },
      })
    ).data;
  },
  /**
   * Use to upload an image
   * @param FormData
   * @returns ????? | undefined
   */
  POST_IMAGE: async function (data: FormData): Promise<MediaModel> {
    return (await axios.post(`${API_BASE_URL}${APIRoutes.MEDIA}/image`, data))
      .data;
  },

  POST_UPDATE_IMAGE: async function (data: FormData): Promise<MediaModel> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.MEDIA}/edit-image`, data)
    ).data;
  },

  POST_VIDEO: async function (data: FormData): Promise<MediaModel> {
    return (await axios.post(`${API_BASE_URL}${APIRoutes.MEDIA}/video`, data))
      .data;
  },

  POST_HUDL_PDF: async function (
    data: FormData
  ): Promise<{ plays: PlayModel[] | undefined; media: MediaModel[] }> {
    return (
      await axios.post(`${API_BASE_URL}${APIRoutes.MEDIA}/hudl-pdf`, data)
    ).data;
  },

  POST_AUDIO: async function (data: FormData): Promise<MediaModel> {
    return (await axios.post(`${API_BASE_URL}${APIRoutes.MEDIA}/audio`, data))
      .data;
  },

  PUT: async function (
    mediaData: Pick<MediaModel, "id" | "name" | "description" | "fileDate">
  ): Promise<MediaModel> {
    return (
      await axios.put(`${API_BASE_URL}${APIRoutes.MEDIA}/metadata`, mediaData)
    ).data;
  },

  DELETE: async function (mediaId: string): Promise<{ deleted: boolean }> {
    return (await axios.delete(`${API_BASE_URL}${APIRoutes.MEDIA}/${mediaId}`))
      .data;
  },
};
