import React, { useContext } from "react";
import styles from "./TagTab.module.scss";
import { TagTypeEnum } from "../../../generated/from-api/models/enums/tag-type.enum";
import {
  TagFolderModel,
  TagModel,
} from "../../../generated/from-api/models/tag.model";
import TrashIcon from "../../../resources/icons/TrashIcon";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { TagsContext } from "../../../shared/shared-with-mobile/providers/tags.provider";
import Button from "../../Button/Button";
import CharacterInput from "../../Input/CharacterInput";
import DragIcon from "../../../resources/icons/DragIcon";
import { DraggableProvided } from "react-beautiful-dnd";
import { TagFoldersContext } from "../../../shared/shared-with-mobile/providers/tagFolders.provider";
import { MediaTagContext } from "../../../shared/shared-with-mobile/providers/mediaTags.provider";
import { cloneDeep } from "lodash";
interface Props {
  tagType: TagTypeEnum;
  tag: TagModel;
  tagFolder: TagFolderModel;
  provided: DraggableProvided;
}

const TagTab: React.FC<Props> = ({ tagType, tag, provided, tagFolder }) => {
  const { updateTag, removeTag } = useContext(TagsContext);
  const { tagFolders, updateTagFolder } = useContext(TagFoldersContext);

  const { mediaItemTags, removeMediaItemsTags } = useContext(MediaTagContext);

  const deleteTag = async (tagToDelete: TagModel) => {
    if (tagToDelete.id) {
      const response = await APIService.TAG.DELETE(tagToDelete.id);
      if (response && response.deleted) {
        removeTag(tagToDelete.id, tagType);
        const getTagFolderDetail = tagFolders.filter(
          (tagFolder) => tagFolder.id === tagToDelete.tagFolderId
        );
        const newTagCategory = cloneDeep(getTagFolderDetail[0]);
        const newTagOrder =
          newTagCategory &&
          newTagCategory.tagOrder &&
          newTagCategory.tagOrder.filter(
            (tag: any) => tag.id !== tagToDelete.id
          );
        const updatedTagFolder: TagFolderModel = {
          ...newTagCategory,
          tagOrder: newTagOrder,
        };
        updateTagFolderApi(updatedTagFolder);
        updateMediaItemsTag(tagToDelete.id);
      }
    }
  };

  const updateMediaItemsTag = (tagId: string) => {
    const getMediaItemsassociatedToTag = mediaItemTags
      .filter((mediaItem) => mediaItem.tags.find(({ id }) => id === tagId))
      .map((e) => e.mediaId);

    removeMediaItemsTags(getMediaItemsassociatedToTag, [tagId]);
  };

  const updateTagFolderApi = async (data: TagFolderModel) => {
    const updateTagFolderTagOrder = await APIService.TAG_FOLDER.PUT(
      data.id as string,
      data
    );
    if (updateTagFolderTagOrder) {
      updateTagFolder(updateTagFolderTagOrder);
    }
  };

  const renameTag = async (tag: TagModel, newTagName: string) => {
    if (tag.id) {
      const updated = await APIService.TAG.PUT(tag.id, {
        name: newTagName,
      });
      if (updated) {
        updateTag(updated, tagType);

        if (tagFolder.id) {
          const existingTagOrders:
            | TagModel[]
            | null = tagFolder.tagOrder as TagModel[];
          let updatedTagOrder: TagModel[] = [updated];
          if (updatedTagOrder) {
            updatedTagOrder = [...updatedTagOrder, updated];
          }

          const updatedTagOrders = existingTagOrders.map(
            (obj) => updatedTagOrder.find((o) => o.id === obj.id) || obj
          );

          const updatedTagFolder: TagFolderModel = {
            ...tagFolder,
            tagOrder: updatedTagOrders,
          };

          const updateTagFolderTagOrder = await APIService.TAG_FOLDER.PUT(
            tagFolder.id,
            updatedTagFolder
          );

          if (updateTagFolderTagOrder) {
            updateTagFolder(updateTagFolderTagOrder);
          }
        }
      }
    }
  };

  return (
    <div className={`${styles.tabContent} ${styles.tagTab}`}>
      <div key={tag.id} className={styles.inputRow}>
        <div
          className={styles.dragButton}
          onClick={(e) => e.preventDefault()}
          {...provided.dragHandleProps}
        >
          <DragIcon />
        </div>
        <CharacterInput
          value={tag.name}
          onBlur={(e) => {
            renameTag(tag, e.target.value);
          }}
        />
        <Button
          className={styles.button}
          theme={"transparent"}
          icon={<TrashIcon />}
          onClick={() => {
            deleteTag(tag);
          }}
        />
      </div>
    </div>
  );
};

export default TagTab;
