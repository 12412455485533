import * as React from "react";

interface Props {
  iconColor?: string;
}

const GridIcon: React.FC<Props> = ({ iconColor = "#7E7E85" }) => (
  <svg
    width="16"
    height="16"
    viewBox="4 4 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8H8V4H4V8ZM8 14H4V10H8V14ZM8 20H4V16H8V20ZM14 20H10V16H14V20ZM10 14H14V10H10V14ZM14 8H10V4H14V8ZM16 20H20V16H16V20ZM20 14H16V10H20V14ZM16 8H20V4H16V8Z"
      fill={iconColor}
    />
  </svg>
);

export default GridIcon;
