import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VirtuosoGrid } from "react-virtuoso";

import styles from "../Playbook/Playbook.module.scss";
import PlayThumbnail from "../../components/drawing/PlayThumbnail";
import PlayDetails from "../../components/drawing/PlayDetails";
import plus from "../../resources/images/plus.svg";
import { PlaybookContext } from "../../shared/shared-with-mobile/providers/playbook.provider";
import { PlayModel } from "../../generated/from-api/models/play.model";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { FormationsContext } from "../../shared/shared-with-mobile/providers/formations.provider";
import { PlaybookFiltersModel } from "../Playbook/Playbook";
import PlaybookSidebar from "../Playbook/PlaybookSidebar/PlaybookSidebar";
import { filterPlaybook, mapTagToOption } from "../../utils/functions";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { FormationOption } from "../../shared/shared-with-mobile/play-editor/drawing.types";
import { TagsContext } from "../../shared/shared-with-mobile/providers/tags.provider";
import {
  BASE_DEFENSIVE_FORMATION,
  BASE_OFFENSIVE_FORMATION,
} from "../../shared/shared-with-mobile/play-editor/playEditor.constants";
import NewFormationModal from "../PlaybookSettings/tabs/FormationsTab/NewFormationModal";
import { MultiSelectOption } from "../../components/MultiSelect/MultiSelect";
import PlusIcon from "../../resources/icons/PlusIcon";
import Button from "../../components/Button/Button";

const GlobalPlaybook: React.FC = () => {
  const { currentPlaybook } = useContext(PlaybookContext);
  const { globalFormations } = useContext(FormationsContext);
  const { personnelPackageTags, situationTags } = useContext(TagsContext);
  const { dispatchModal } = useContext(UIContext);
  const [playbookFilters, setPlaybookFilters] = useState<PlaybookFiltersModel>({
    category: null,
    scheme: null,
    searchText: "",
    formationIds: [],
    defensiveSetIds: [],
    situationNames: [],
    personnelPackageNames: [],
    gamePlanId: null,
  });
  const [filteredPlaybook, setFilteredPlaybook] = useState<PlayModel[]>(
    currentPlaybook || []
  );
  const [formationOptions, setFormationOptions] = useState<FormationOption[]>(
    []
  );
  const [defensiveSetOptions, setDefensiveSetOptions] = useState<
    FormationOption[]
  >([]);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  useEffect(() => {
    if (currentPlaybook) {
      setFilteredPlaybook(currentPlaybook);
    }
  }, [currentPlaybook]);

  useEffect(() => {
    const nextFormationOptions = globalFormations
      .filter((formation) => formation.type === "Offensive")
      .map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));
    const nextDefensiveSetOptions = globalFormations
      .filter((formation) => formation.type === "Defensive")
      .map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));
    setFormationOptions(nextFormationOptions);
    setDefensiveSetOptions(nextDefensiveSetOptions);
  }, [globalFormations]);

  useEffect(() => {
    const nextFilteredPlaybook = filterPlaybook(
      playbookFilters,
      currentPlaybook || [],
      globalFormations // global plays will always use global formations (unlike team plays, which might use both global & current formations)
    );

    setFilteredPlaybook(nextFilteredPlaybook);
  }, [playbookFilters]);

  const openCreatePlayModal = () => {
    // If there are no formations, create a formation first
    if (globalFormations.length === 0) {
      dispatchModal({
        title: "New Formation",
        open: true,
        body: (
          <NewFormationModal
            baseFormation={BASE_OFFENSIVE_FORMATION}
            formationOptions={[
              BASE_OFFENSIVE_FORMATION,
              BASE_DEFENSIVE_FORMATION,
            ]}
          />
        ),
      });
    } else {
      dispatchModal({
        title: "Create New Play",
        open: true,
        size: "large",
        body: <PlayDetails />,
      });
    }
  };

  return (
    <div className={styles.playbook}>
      <section className="pageHeader">
        <h1 className="pageTitle">TN Playbook</h1>
        <div className={styles.buttonContainer}>
          <Button
            theme={"secondary"}
            size={"small"}
            icon={<PlusIcon />}
            onClick={openCreatePlayModal}
          >
            Create Play
          </Button>
        </div>
      </section>
      <section className={styles.plays}>
        <PlaybookSidebar
          playbookFilters={playbookFilters}
          setPlaybookFilters={setPlaybookFilters}
          playbook={currentPlaybook}
        />
        <div className={styles.listWrapper}>
          <div className={styles.filterBar}>
            <MultiSelect
              placeholder="OFFENSIVE FORMATION"
              options={formationOptions}
              onChange={(value) => {
                setPlaybookFilters({
                  ...playbookFilters,
                  formationIds: (value.currentTarget as any).selectedList,
                });
              }}
              filter
            />
            <MultiSelect
              placeholder="PERSONNEL"
              options={
                personnelPackageTags.map(mapTagToOption) as MultiSelectOption[]
              }
              onChange={(value) => {
                setPlaybookFilters({
                  ...playbookFilters,
                  personnelPackageNames: (value.currentTarget as any)
                    .selectedList,
                });
              }}
              filter
            />
            <MultiSelect
              placeholder="SITUATION"
              options={situationTags.map(mapTagToOption) as MultiSelectOption[]}
              onChange={(value) => {
                setPlaybookFilters({
                  ...playbookFilters,
                  situationNames: (value.currentTarget as any).selectedList,
                });
              }}
              filter
            />
            <MultiSelect
              placeholder="DEFENSIVE FRONT"
              options={defensiveSetOptions}
              onChange={(value) => {
                setPlaybookFilters({
                  ...playbookFilters,
                  defensiveSetIds: (value.currentTarget as any).selectedList,
                });
              }}
              filter
            />
          </div>
          <div className={styles.playsList}>
            {currentPlaybook && currentPlaybook.length ? (
              filteredPlaybook.length > 0 ? (
                <VirtuosoGrid
                  isScrolling={(e) => setIsScrolling(e)}
                  totalCount={filteredPlaybook.length}
                  overscan={{ main: 250, reverse: 250 }}
                  itemContent={(index: number) => {
                    const play = filteredPlaybook[index];
                    const titleLengthLimit = 20;
                    const playName =
                      play.name.length <= titleLengthLimit
                        ? play.name
                        : play.name.slice(0, titleLengthLimit) + "...";
                    const title = !play.published
                      ? `[Draft] ${playName}`
                      : playName;
                    return (
                      <Link
                        to={`edit-play-admin/${play.id}`}
                        key={play.id}
                        className={styles.play}
                      >
                        <PlayThumbnail play={play} useLoader={isScrolling} />
                        <div
                          className={`${styles.playTitle} ${
                            !play.published ? styles.draftTitleColor : ""
                          }`}
                        >
                          {title}
                        </div>
                        <div className={styles.tagsRow}>
                          {play.scheme && (
                            <div className={styles.tag}>{play.scheme}</div>
                          )}
                          {play.situation && (
                            <div className={styles.tag}>{play.situation}</div>
                          )}
                          {play.personnel && (
                            <div className={styles.tag}>{play.personnel}</div>
                          )}
                        </div>
                      </Link>
                    );
                  }}
                />
              ) : (
                <div>0 plays matching applied filters.</div>
              )
            ) : currentPlaybook !== undefined ? (
              <div className={styles.grid}>
                <div className={`card ${styles.noPlaybook}`}>
                  <img src={plus} alt="add" />
                  <Button
                    size={"small"}
                    theme={"transparent"}
                    onClick={openCreatePlayModal}
                  >
                    Create Play
                  </Button>
                </div>
              </div>
            ) : (
              <div className={styles.grid}>
                {[...Array(12)].map((e, index) => (
                  <div
                    key={index}
                    className={`${styles.play} ${styles.loader}`}
                  ></div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GlobalPlaybook;
