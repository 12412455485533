import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

export const muiTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: { main: "#FFE42A" },
    background: { default: "#151724", paper: "#282b3a" },
  },
  typography: {
    fontFamily: ["Quantico", "sans-serif"].join(","),
  },
  overrides: {
    MuiTouchRipple: {
      child: {
        backgroundColor: "#ffbc17",
      },
    },
  },
});
