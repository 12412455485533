import React, { useState } from "react";

import { GamePlanContentModel } from "../../../generated/from-api/models/dtos/gamePlan-content.model";

export const GamePlansContext = React.createContext<{
  gamePlans: GamePlanContentModel[];
  addGamePlan: (gamePlan: GamePlanContentModel) => void;
  updateGamePlan: (gamePlan: GamePlanContentModel) => void;
  setGamePlans: (gamePlans: GamePlanContentModel[]) => void;
  removePlaysFromGamePlan: (
    gamePlanId: string,
    playIdsToRemove: string[]
  ) => void;
  removePlaySetsFromGamePlan: (
    gamePlanId: string,
    playSetIdsToRemove: string[]
  ) => void;
  clearGamePlans: () => void;
  removeGamePlan: (gamePlanId: string) => void;
}>({
  gamePlans: [],
  addGamePlan: () => null,
  updateGamePlan: () => null,
  setGamePlans: () => null,
  removePlaysFromGamePlan: () => null,
  removePlaySetsFromGamePlan: () => null,
  clearGamePlans: () => null,
  removeGamePlan: () => null,
});

export const GamePlansProvider: React.FC = ({ children }) => {
  const [gamePlans, setGamePlans] = useState<GamePlanContentModel[]>([]);

  const updateGamePlan = (updatedGamePlan: GamePlanContentModel) => {
    const nextGamePlans = gamePlans.map((gamePlan) => {
      if (gamePlan.id === updatedGamePlan.id) {
        return updatedGamePlan;
      }

      return gamePlan;
    });

    setGamePlans(nextGamePlans);
  };

  const addGamePlan = (gamePlanToAdd: GamePlanContentModel) => {
    const nextGamePlans = [...gamePlans, gamePlanToAdd];
    setGamePlans(nextGamePlans);
  };

  const removePlaysFromGamePlan = (
    gamePlanId: string,
    playIdsToRemove: string[]
  ) => {
    const gamePlanIndex = gamePlans.findIndex(
      (gamePlan) => gamePlan.id === gamePlanId
    );

    const playIdsToRemoveSet = new Set(playIdsToRemove);

    const nextGamePlans = [...gamePlans];

    nextGamePlans[gamePlanIndex].plays = nextGamePlans[
      gamePlanIndex
    ].plays.filter((play) => !playIdsToRemoveSet.has(play.id as string));

    setGamePlans(nextGamePlans);
  };

  const removePlaySetsFromGamePlan = (
    gamePlanId: string,
    playSetIdsToRemove: string[]
  ) => {
    const gamePlanIndex = gamePlans.findIndex(
      (gamePlan) => gamePlan.id === gamePlanId
    );

    const playSetIdsToRemoveSet = new Set(playSetIdsToRemove);

    const nextGamePlans = [...gamePlans];

    nextGamePlans[gamePlanIndex].playSets = nextGamePlans[
      gamePlanIndex
    ].playSets.filter(
      (playSet) => !playSetIdsToRemoveSet.has(playSet.id as string)
    );

    setGamePlans(nextGamePlans);
  };

  const clearGamePlans = () => {
    setGamePlans([]);
  };

  const removeGamePlan = (gamePlanId: string) => {
    const gamePlanToDeleteIndex = gamePlans.findIndex(
      (gamePlan) => gamePlan.id === gamePlanId
    );

    const nextGamePlans = [...gamePlans];
    nextGamePlans.splice(gamePlanToDeleteIndex, 1);
    setGamePlans(nextGamePlans);
  };

  return (
    <GamePlansContext.Provider
      value={{
        gamePlans,
        addGamePlan,
        updateGamePlan,
        setGamePlans,
        removePlaysFromGamePlan,
        removePlaySetsFromGamePlan,
        clearGamePlans,
        removeGamePlan,
      }}
    >
      {children}
    </GamePlansContext.Provider>
  );
};
