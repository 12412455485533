import { CustomQuizModel } from "../../../../generated/from-api/models/custom-quiz.model";
import { InstallModel } from "../../../../generated/from-api/models/install.model";
import { MediaModel } from "../../../../generated/from-api/models/media.model";
import { PlayModel } from "../../../../generated/from-api/models/play.model";
import { APIService } from "../../api-client/api.service";
export const SYNC_STATE_DEFAULT = {
  plays: { lastSynced: new Date(), lastUpdates: [] },
  media: { lastSynced: new Date(), lastUpdates: [] },
  quizzes: { lastSynced: new Date(), lastUpdates: [] },
  installs: { lastSynced: new Date(), lastUpdates: [] },
};
interface SyncStateDatum<T> {
  lastSynced: Date;
  lastUpdates: T[];
}
export interface SyncState {
  plays: SyncStateDatum<PlayModel>;
  media: SyncStateDatum<MediaModel>;
  quizzes: SyncStateDatum<CustomQuizModel>;
  installs: SyncStateDatum<InstallModel>;
}
export enum DataSetType {
  "plays",
  "media",
  "flashcards",
  "installs",
}
export const syncData = async (
  syncState: SyncState,
  currentTeamId: string,
  dataToSync: DataSetType[]
): Promise<SyncState> => {
  const newSyncState = { ...syncState };
  const now = new Date();
  if (dataToSync.find((v) => v === DataSetType.plays) !== undefined) {
    await refreshPlays(syncState, newSyncState, now, currentTeamId);
  }
  if (dataToSync.find((v) => v === DataSetType.media) !== undefined) {
    await refreshMedia(syncState, newSyncState, now, currentTeamId);
  }
  if (dataToSync.find((v) => v === DataSetType.flashcards) !== undefined) {
    await refreshQuizzes(syncState, newSyncState, now, currentTeamId);
  }
  if (dataToSync.find((v) => v === DataSetType.installs) !== undefined) {
    await refreshInstalls(syncState, newSyncState, now, currentTeamId);
  }
  return newSyncState;
};
const shouldRefresh = (last: Date, current: Date): boolean => {
  const lastSynced = last.getTime();
  const currentTime = current.getTime();
  return lastSynced + 10000 < currentTime;
};
const refreshPlays = async (
  syncState: SyncState,
  newSyncState: SyncState,
  currentDatetime: Date,
  currentTeamId?: string
) => {
  if (shouldRefresh(syncState.plays.lastSynced, currentDatetime)) {
    const plays = await APIService.PLAY.LIST(
      currentTeamId,
      syncState.plays.lastSynced.toISOString()
    );
    newSyncState.plays.lastSynced = currentDatetime;
    newSyncState.plays.lastUpdates = plays;
  }
};
const refreshMedia = async (
  syncState: SyncState,
  newSyncState: SyncState,
  currentDatetime: Date,
  currentTeamId?: string
) => {
  if (shouldRefresh(syncState.media.lastSynced, currentDatetime)) {
    const media = await APIService.MEDIA.LIST(
      currentTeamId,
      syncState.media.lastSynced.toISOString()
    );
    newSyncState.media.lastSynced = currentDatetime;
    newSyncState.media.lastUpdates = media;
  }
};
const refreshQuizzes = async (
  syncState: SyncState,
  newSyncState: SyncState,
  currentDatetime: Date,
  currentTeamId?: string
) => {
  if (shouldRefresh(syncState.quizzes.lastSynced, currentDatetime)) {
    const quizzes = await APIService.CUSTOM_QUIZ.LIST(
      currentTeamId,
      syncState.media.lastSynced.toISOString()
    );
    newSyncState.quizzes.lastSynced = currentDatetime;
    newSyncState.quizzes.lastUpdates = quizzes;
  }
};
const refreshInstalls = async (
  syncState: SyncState,
  newSyncState: SyncState,
  currentDatetime: Date,
  currentTeamId?: string
) => {
  if (shouldRefresh(syncState.installs.lastSynced, currentDatetime)) {
    const installs = await APIService.INSTALL.LIST(
      currentTeamId,
      syncState.media.lastSynced.toISOString()
    );
    newSyncState.installs.lastSynced = currentDatetime;
    newSyncState.installs.lastUpdates = installs;
  }
};
/**
 * This generic function takes a list of recently updated or created data,
 * and applies the changes to an existing data set of the same type.
 * The data set must have the following properties:
 * - deleted (true/false), used to determine if the datum was deleted
 * - id, used to identify the datum and check if it already exists or is a new datum
 * @param updates
 * @param currentDataSet
 * @returns
 */
export function processDataUpdates<
  T extends { deleted?: boolean; id?: string }
>(updates: T[], currentDataSet?: T[]): T[] {
  if (updates.length === 0) {
    return currentDataSet ? currentDataSet : [];
  }
  // make a copy of the data set that we can modify
  let newDataSet = currentDataSet ? [...currentDataSet] : [];
  // process each individual update
  updates.forEach((updatedValue) => {
    if (updatedValue.deleted) {
      // remove any elements that were deleted
      const deletedElementId = updatedValue.id;
      newDataSet = newDataSet.filter((datum) => datum.id !== deletedElementId);
    } else {
      // check if the datum already exists
      const indexToUpdate = newDataSet.findIndex(
        (datum) => datum.id === updatedValue.id
      );
      if (indexToUpdate !== -1) {
        // update the element if it exists
        newDataSet[indexToUpdate] = updatedValue;
      } else {
        // insert the new element
        newDataSet.push(updatedValue);
      }
    }
  });
  return newDataSet;
}
