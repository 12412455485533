import * as React from "react";

const CircleInformationIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.75 0C5.23 0 0.75 4.48 0.75 10C0.75 15.52 5.23 20 10.75 20C16.27 20 20.75 15.52 20.75 10C20.75 4.48 16.27 0 10.75 0ZM9.75 5V7H11.75V5H9.75ZM9.75 9V15H11.75V9H9.75ZM2.75 10C2.75 14.41 6.34 18 10.75 18C15.16 18 18.75 14.41 18.75 10C18.75 5.59 15.16 2 10.75 2C6.34 2 2.75 5.59 2.75 10Z"
    />
  </svg>
);

export default CircleInformationIcon;
