import React from "react";
import styles from "./Checkbox.module.scss";

interface Props {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  showCheckbox?: boolean; // optionally hide checkbox icon and only render children
  addExtraPadding?: boolean; // adds extra clickable space around the checkbox
}

const Checkbox: React.FC<Props> = ({
  children,
  onChange,
  id,
  name,
  checked,
  disabled = false,
  addExtraPadding = false,
  showCheckbox = true,
}) => {
  const [inputValue, setInputValue] = React.useState(checked ?? false);

  React.useEffect(() => {
    if (checked !== undefined) {
      setInputValue(checked);
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.checked);
    onChange && onChange(event);
  };

  return (
    <label
      htmlFor={id}
      className={`${
        addExtraPadding ? styles.checkboxWithPadding : styles.checkbox
      } ${disabled && styles.disabled} ${
        !showCheckbox && styles.hideCheckbox
      } ${children === undefined ? styles.checkboxLabelLess : ""}`}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={handleChange}
        checked={inputValue}
        disabled={disabled}
      />
      <span>{children !== undefined ? <span>{children}</span> : null}</span>
    </label>
  );
};

export default Checkbox;
