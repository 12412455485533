import React, { useContext, useState } from "react";
import styles from "../Admin.module.scss";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { LicenseTiersContext } from "../../../shared/shared-with-mobile/providers/licenseTiers";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import CharacterInput from "../../../components/Input/CharacterInput";
import Button from "../../../components/Button/Button";
import { LicensesContext } from "../../../shared/shared-with-mobile/providers/licenses.provider";

interface Props {
  userEmail?: string;
  saveCallback?: (
    email: string,
    licenseTierId: string,
    licenseInvoiceNumber: string,
    firstName?: string,
    lastName?: string
  ) => void;
  cancelCallback?: () => void;
}

const AddLicenseModal: React.FC<Props> = ({
  userEmail,
  saveCallback,
  cancelCallback,
}) => {
  const { licenseTiers } = useContext(LicenseTiersContext);
  const { allLicenses } = useContext(LicensesContext);

  const [email, setEmail] = useState<string>(userEmail ? userEmail : "");
  const [licenseTierId, setLicenseTierId] = useState<string>("");
  const [licenseInvoiceNumber, setLicenseInvoiceNumber] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const existingLicense = (): boolean => {
    return !!allLicenses?.find(
      (lh) => lh.email?.toLowerCase() == email.toLowerCase()
    );
  };
  const existingLicenseBlurb =
    "This user already owns a license, please use the management tools to modify their subscription.";
  const validateModal = (): boolean => {
    return !email || !licenseTierId || existingLicense();
  };

  return (
    <div className={styles.addLicensesModalWrapper}>
      <div className={styles.addLicensesModal__Body}>
        <div className={styles.addLicensesModal__Row}>
          <CharacterInput
            type={INPUT_TYPES.EMAIL}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            disabled={!!userEmail}
            autofocus={!userEmail}
          />
        </div>
        <div className={styles.addLicensesModal__Row}>
          <CharacterInput
            type={INPUT_TYPES.EMAIL}
            placeholder="First Name - Optional"
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
          />
        </div>
        <div className={styles.addLicensesModal__Row}>
          <CharacterInput
            type={INPUT_TYPES.EMAIL}
            placeholder="Last Name - Optional"
            value={lastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
          />
        </div>
        <div className={styles.addLicensesModal__Row}>
          <SingleSelect
            placeholder="License Tier"
            options={licenseTiers.slice().map((licenseTier) => {
              return {
                label: licenseTier.tier,
                value: String(licenseTier.id),
              };
            })}
            value={licenseTierId}
            onChange={(e) => setLicenseTierId(e.currentTarget.value)}
          />
        </div>
        <div className={styles.addLicensesModal__Row}>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder="Order Number - Optional"
            value={licenseInvoiceNumber}
            onChange={(e) => setLicenseInvoiceNumber(e.currentTarget.value)}
            autofocus={!!userEmail}
          />
        </div>
        {existingLicense() && (
          <div className={styles.addLicensesModal__Row}>
            {existingLicenseBlurb}
          </div>
        )}
      </div>
      <div className={styles.addLicensesModal__Footer}>
        <div className={styles.addLicensesModal__Row}>
          <div className={styles.addLicensesModal__ButtonsWrapper}>
            <Button theme={"secondary"} size={"small"} onClick={cancelCallback}>
              Cancel
            </Button>
            <Button
              size={"small"}
              onClick={() =>
                saveCallback &&
                saveCallback(
                  email,
                  licenseTierId,
                  licenseInvoiceNumber,
                  firstName,
                  lastName
                )
              }
              disabled={validateModal()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLicenseModal;
