import styles from "../MediaGallery.module.scss";
import AudioFileIcon from "../../../resources/images/audioFileIcon.svg";
import { useContext, useEffect, useState } from "react";
import { MediaContext } from "../../../shared/shared-with-mobile/providers/media.provider";
import { useHistory } from "react-router";
import { MediaModel } from "../../../generated/from-api/models/media.model";
import ChevronLeftIcon from "../../../resources/icons/ChevronLeftIcon";
import Button from "../../Button/Button";
import { MediaPreviewSideBar } from "../Sidebars";
import ReactAudioPlayer from "react-audio-player";
import { useRouteMatch } from "react-router-dom";
import CloseIcon from "../../../resources/icons/CloseIcon";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";

type componentType = "Modal" | "Page";
interface Props {
  mediaId?: string;
  handleAddMedia?: (param: MediaModel[]) => void;
  addMediaButtonText: string;
  setIsImageEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedImage: React.Dispatch<React.SetStateAction<MediaModel>>;
  setIsMediaPreview: React.Dispatch<React.SetStateAction<boolean>>;
  type: componentType;
}

const MediaPreview: React.FC<Props> = ({
  type = "Page",
  mediaId,
  handleAddMedia,
  addMediaButtonText,
  setIsImageEditing,
  setSelectedImage,
  setIsMediaPreview,
}) => {
  const history: any = useHistory();

  const match: any = useRouteMatch();
  const { mediaItems } = useContext(MediaContext);
  const [mediaItem, setMediaItem] = useState(
    mediaItems.find(
      (item: MediaModel) => item.id === (match?.params.id || mediaId)
    )
  );
  const { closeModal } = useContext(UIContext);

  const [audioUrl, setAudioUrl] = useState("");

  const handleClose = () => {
    closeModal();
    setIsMediaPreview(false);
  };

  useEffect(() => {
    return () => {
      if (!!audioUrl) URL.revokeObjectURL(audioUrl);
    };
  }, []);

  useEffect(() => {
    setMediaItem(
      mediaItems.find(
        (item: MediaModel) => item.id === (match?.params.id || mediaId)
      )
    );
  }, [match, mediaId]);

  useEffect(() => {
    if (mediaItem) {
      const fetchAudio = async (media: MediaModel) => {
        if (!media) return;
        try {
          const response = await fetch(media.url || "");
          if (!response.ok) {
            console.error("Error retrieving audio file", response.statusText);
            return;
          }
          const blob = await response.blob();
          setAudioUrl(URL.createObjectURL(blob));
        } catch (e) {
          console.error(e);
        }
      };

      if (!!audioUrl) URL.revokeObjectURL(audioUrl);
      if (mediaItem.type !== "AUDIO") return;
      fetchAudio(mediaItem);
    }
  }, [mediaItem]);

  if (!mediaItem) {
    return null;
  }

  return (
    <div className={styles.mediaGallery}>
      <div className={styles.mediaAndSidebarGrid}>
        <div className={styles.thumbnailContainer}>
          <h1>
            <Button
              theme={"transparent"}
              size={"small"}
              type={"button"}
              onClick={() => {
                if (mediaId) {
                  closeModal();
                  setIsMediaPreview(false);
                } else {
                  history.goBack();
                }
              }}
              icon={<ChevronLeftIcon />}
            />
            {`  Back to Library`}
          </h1>
          {mediaItem.type === "IMAGE" && (
            <div style={{ height: "87%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "#151724",
                }}
              >
                <img
                  src={mediaItem.url}
                  alt={mediaItem.name}
                  className={styles.mediaImage}
                />
                {mediaItem.name}
              </div>
            </div>
          )}
          {mediaItem.type === "VIDEO" && (
            <div style={{ height: "87%" }}>
              <video
                src={mediaItem.url}
                controls
                autoPlay
                width="100%"
                height="100%"
              />
            </div>
          )}
          {mediaItem.type === "AUDIO" && (
            <div className={styles.audio} style={{ height: "87%" }}>
              <div
                style={{
                  height: mediaId
                    ? "calc(100vh - 280px)"
                    : "calc(100vh - 229px)",
                }}
                className={styles.audioFullScreen}
              >
                <img
                  src={AudioFileIcon}
                  alt="audio file icon"
                  className={styles.audioIcon}
                />
              </div>
              <ReactAudioPlayer
                src={audioUrl}
                controls
                autoPlay
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  backgroundColor: "#f1f3f4",
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.sidebar}>
          {mediaId && type === "Modal" && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                margin: "12px 24px",
              }}
            >
              <Button
                theme={"transparent"}
                icon={<CloseIcon />}
                onClick={() => closeModal()}
              />
            </div>
          )}
          <MediaPreviewSideBar
            hidePreview={true}
            mediaItem={mediaItem}
            deleteSelectedMedia={() => undefined}
            showDeleteConfirmation={false}
            setShowDeleteConfirmation={() => undefined}
            setSelectedImage={setSelectedImage}
            setIsImageEditing={setIsImageEditing}
            closeEditImageModal={handleClose}
          />
        </div>
      </div>
      {handleAddMedia && (
        <div className={styles.footer} style={{ marginTop: 16 }}>
          <Button
            onClick={() => {
              handleAddMedia([mediaItem]);
              closeModal();
            }}
          >
            {addMediaButtonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default MediaPreview;
