import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
} from "@material-ui/core";
import styles from "./Roster.module.scss";
import search from "../../resources/images/search.svg";
import Input from "../../components/Input/CharacterInput";
import Avatar from "../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import EditRoster from "./roster-components/EditRoster";
import { CoachesContext } from "../../shared/shared-with-mobile/providers/coaches.provider";
import { PlayersContext } from "../../shared/shared-with-mobile/providers/players.provider";
import { CoachModel } from "../../generated/from-api/models/relationships/coach.model";
import { hasPositionType } from "../../shared/shared-with-mobile/functions";
import { ReadinessScoresContext } from "../../shared/shared-with-mobile/providers/readinessScores.provider";
import PencilIcon from "../../resources/icons/PencilIcon";
import Button from "../../components/Button/Button";
import { PlayerModel } from "../../generated/from-api/models/relationships/player.model";
import PlayerModal from "../Dashboard/Components/PlayerModal/PlayerModal";
import { getPlayerRanking } from "../../shared/shared-with-mobile/utilities/getDataForPlayerDetailStats";

type RosterColumnData = {
  playerId: string;
  playerName: string | number;
  position: string;
  readiness: number;
  rank: number;
};

type Order = "asc" | "desc";

type PositionType = "" | "offensive" | "defensive" | "specialTeams";

interface HeadCell {
  disablePadding: boolean;
  id: keyof RosterColumnData;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof RosterColumnData
  ) => void;
  order: Order;
  orderBy: string;
}

interface TableToolbarProps {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  filterChange: (value: PositionType) => void;
  selectedFilter: PositionType;
}

function createData(
  playerId: string,
  playerName: string,
  position: string,
  readiness: number,
  rank: number
): RosterColumnData {
  return { playerId, playerName, position, readiness, rank };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;

    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function abbreviateName(s: string) {
  return s[0].concat(". ", s.slice(s.indexOf(" ") + 1));
}

function alignHeadCells(s: string) {
  if (s === "playerName" || s === "position") return "left";
  return "right";
}

const headCells: HeadCell[] = [
  {
    id: "playerName",
    numeric: false,
    disablePadding: false,
    label: "PLAYER",
  },

  { id: "position", numeric: false, disablePadding: true, label: "POS" },
  { id: "readiness", numeric: true, disablePadding: true, label: "READINESS" },
];

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onRequestSort, order, orderBy } = props;

  const createSortHandler = (property: keyof RosterColumnData) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={
              headCell.id === "playerName"
                ? `${styles.rosterTableHeadStyle} ${styles.PlayerCell} ${styles.TableHeadPadding}`
                : `${styles.rosterTableHeadStyle} ${styles.padding}`
            }
            size={headCell.id === "playerName" ? "medium" : "small"}
            key={headCell.id}
            align={alignHeadCells(headCell.id)}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={`${
                headCell.id != "playerName" && headCell.id != "position"
                  ? styles.marginLeft
                  : ""
              } ${headCell.id == "position" ? styles.marginLeftPos : ""}`}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}

              {orderBy === headCell.id ? (
                <span className={styles.VisuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props: TableToolbarProps) => {
  const { onChange, filterChange, selectedFilter } = props;

  return (
    <Toolbar className={styles.Toolbar}>
      <div className={styles.filterListAlignment}>
        <span
          className={`${styles.filter} ${
            selectedFilter === "" ? styles.selected : styles.unselected
          }`}
          onClick={() => filterChange("")}
        >
          ALL
        </span>
        <span
          className={`${styles.filter} ${
            selectedFilter === "offensive" ? styles.selected : styles.unselected
          }`}
          onClick={() => filterChange("offensive")}
        >
          OFFENSE
        </span>
        <span
          className={`${styles.filter} ${
            selectedFilter === "defensive" ? styles.selected : styles.unselected
          }`}
          onClick={() => filterChange("defensive")}
        >
          DEFENSE
        </span>
        <span
          className={`${styles.filter} ${
            selectedFilter === "specialTeams"
              ? styles.selected
              : styles.unselected
          }`}
          onClick={() => filterChange("specialTeams")}
        >
          S.TEAMS
        </span>
      </div>

      <Input
        onChange={onChange}
        icon={search}
        iconAlign="left"
        placeholder="Search"
        size="x-small"
      />
    </Toolbar>
  );
};

const Roster: React.FC = () => {
  const { currentCoaches } = useContext(CoachesContext);
  const { currentPlayers } = useContext(PlayersContext);
  const [editingRoster, setEditingRoster] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof RosterColumnData>("playerName");
  const [playerList, setPlayerList] = useState<RosterColumnData[]>([]);

  const [searchPlayerValue, setSearchPlayerValue] = useState<string | number>(
    ""
  );
  const [positionFilterValue, setPositionFilterValue] = useState<PositionType>(
    ""
  );
  const [player, setPlayer] = useState<PlayerModel>();
  const { playerOverallMetrics } = useContext(ReadinessScoresContext);
  const { currentPlayersAsArray } = useContext(PlayersContext);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const EmptyRoster: React.FC = () => {
    return (
      <div className={styles.emptyRosterContainer}>
        <h1>Your Roster is Empty</h1>
        <p>Add players or coaches to your team</p>
        <Button size={"small"} onClick={() => setEditingRoster(true)}>
          Add Team Members
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (
      currentPlayers &&
      currentPlayers.size > 0 &&
      playerOverallMetrics.length > 0
    ) {
      const players = Array.from(currentPlayers.values()).map((player) => {
        const readinessScore = playerOverallMetrics
          .filter((player) => !player.playerHideOnLeaderBoard)
          .find((metric) => {
            return player.id === metric.playerId;
          });

        return createData(
          player.id as string,
          `${player.firstName} ${player.lastName}`,
          player.positions ? player.positions : "",
          parseFloat(readinessScore ? readinessScore.overallScore : "0.00"),
          getPlayerRanking(player, playerOverallMetrics, "overallScore")
        );
      });

      setPlayerList(players);
    }
  }, [currentPlayers, playerOverallMetrics]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RosterColumnData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.value;
    setSearchPlayerValue(name);
  };

  const onRowClick = (id: string) => {
    setPlayer(
      currentPlayersAsArray.find((player: PlayerModel) => player.id === id)
    );

    setShowPlayerModal(true);
  };

  return (
    <>
      <EditRoster
        visible={editingRoster}
        close={() => setEditingRoster(false)}
      />
      {player && (
        <PlayerModal
          player={player}
          visible={showPlayerModal}
          defaultTab={"overall"}
          close={() => setShowPlayerModal(false)}
        />
      )}
      <div className={styles.roster}>
        <section className={`${styles.main} card`}>
          <div className={styles.rosterTableStarter}>
            <h1 className={styles.rosterTitle}>
              Team Players{" "}
              <Button
                theme={"transparent"}
                size={"small"}
                icon={<PencilIcon />}
                onClick={() => setEditingRoster(true)}
              />
            </h1>
            {!currentPlayers || currentPlayers.size === 0 ? (
              <EmptyRoster />
            ) : (
              <EnhancedTableToolbar
                onChange={handleInputChange}
                filterChange={(value: PositionType) =>
                  setPositionFilterValue(value)
                }
                selectedFilter={positionFilterValue}
              />
            )}
          </div>

          <div className={styles.rosterTable}>
            {!currentPlayers || currentPlayers.size === 0 ? null : (
              <TableContainer className={styles.TableContainer}>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody className={styles.rosterTableBody}>
                    {playerList.length > 0
                      ? stableSort(
                          playerList,
                          getComparator(order, orderBy)
                        ).map((row) => {
                          return (
                            <TableRow
                              key={row.playerName}
                              className={styles.row}
                              style={{
                                visibility:
                                  !hasPositionType(
                                    row.position ? row.position.split("|") : [],
                                    positionFilterValue
                                  ) ||
                                  !row.playerName
                                    .toString()
                                    .toLowerCase()
                                    .includes(
                                      searchPlayerValue.toString().toLowerCase()
                                    )
                                    ? "collapse"
                                    : "initial",
                              }}
                              onClick={() => onRowClick(row.playerId)}
                            >
                              <TableCell
                                align="center"
                                className={`${styles.PlayerCell} ${styles.TableCell}`}
                              >
                                <div className={styles.centerPlayerName}>
                                  <Avatar
                                    userId={row.playerId}
                                    rankingData={row.rank}
                                    small
                                    type="player"
                                  />

                                  <p>
                                    {abbreviateName(row.playerName.toString())}
                                  </p>
                                </div>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={styles.PosCell}
                              >
                                {row.position
                                  ? row.position.replaceAll("|", ",")
                                  : ""}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={styles.TableCell}
                              >{`${row.readiness.toFixed(2)}%`}</TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </section>
        <section className={styles.side}>
          <div className="card">
            <h2 className="cardTitle">Coaches</h2>
            {currentCoaches && currentCoaches.size > 0
              ? Array.from(currentCoaches.values()).map((coach: CoachModel) => {
                  return (
                    <div key={coach.id} className={styles.coachTable}>
                      <span className={styles.img}>
                        {coach.id && (
                          <Avatar userId={coach.id} type="coach" small />
                        )}
                      </span>
                      <span>
                        <p>
                          {coach.firstName} {coach.lastName}
                        </p>
                        <p className={styles.role}>
                          {coach.role === "DEFAULT" ? "ASSISTANT" : coach.role}
                        </p>
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="card">
            <h2 className="cardTitle">Pending Invitations</h2>
            <p className={styles.noInvitations}>No pending invitations.</p>
          </div>
        </section>
      </div>
    </>
  );
};

export default Roster;
