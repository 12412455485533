import React, { useState } from "react";
import styles from "./FontStyleControls.module.scss";
import SingleSelect from "../../SingleSelect/SingleSelect";
import wysiwygStyles from "../Wysiwyg.module.scss";
import ToolbarFontColorIcon from "../../../resources/icons/ToolbarFontColorIcon";
import { EditorState } from "draft-js";

interface Props {
  editorState: EditorState;
  onSelectHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onColorSelectHandler: (
    styleType: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

const colorPalette: {
  style: string;
  toolbarButtonClassName: string;
  paletteButtonClassName: string;
}[] = [
  {
    style: "white",
    toolbarButtonClassName: "wysiwygFontColorButtonWhite",
    paletteButtonClassName: "wysiwygPaletteButtonWhite",
  },
  {
    style: "grey",
    toolbarButtonClassName: "wysiwygFontColorButtonGrey",
    paletteButtonClassName: "wysiwygPaletteButtonGrey",
  },
  {
    style: "red",
    toolbarButtonClassName: "wysiwygFontColorButtonRed",
    paletteButtonClassName: "wysiwygPaletteButtonRed",
  },
  {
    style: "orange",
    toolbarButtonClassName: "wysiwygFontColorButtonOrange",
    paletteButtonClassName: "wysiwygPaletteButtonOrange",
  },
  {
    style: "yellow",
    toolbarButtonClassName: "wysiwygFontColorButtonYellow",
    paletteButtonClassName: "wysiwygPaletteButtonYellow",
  },
  {
    style: "reef",
    toolbarButtonClassName: "wysiwygFontColorButtonReef",
    paletteButtonClassName: "wysiwygPaletteButtonReef",
  },
  {
    style: "green",
    toolbarButtonClassName: "wysiwygFontColorButtonGreen",
    paletteButtonClassName: "wysiwygPaletteButtonGreen",
  },
  {
    style: "java",
    toolbarButtonClassName: "wysiwygFontColorButtonJava",
    paletteButtonClassName: "wysiwygPaletteButtonJava",
  },
  {
    style: "blue",
    toolbarButtonClassName: "wysiwygFontColorButtonBlue",
    paletteButtonClassName: "wysiwygPaletteButtonBlue",
  },
  {
    style: "rose",
    toolbarButtonClassName: "wysiwygFontColorButtonRose",
    paletteButtonClassName: "wysiwygPaletteButtonRose",
  },
];

const FontStyleControls: React.FC<Props> = ({
  editorState,
  onSelectHandler,
  onColorSelectHandler,
}) => {
  const currentEditorStyle = editorState.getCurrentInlineStyle();

  const colorPaletteArrayValue = colorPalette.filter((item) =>
    currentEditorStyle.has(item.style)
  );

  const colorPaletteValue =
    colorPaletteArrayValue && colorPaletteArrayValue.length > 0
      ? colorPaletteArrayValue[0].style
      : colorPalette[0].style;

  const toolbarButtonClass =
    colorPaletteArrayValue && colorPaletteArrayValue.length > 0
      ? colorPaletteArrayValue[0].toolbarButtonClassName
      : colorPalette[0].toolbarButtonClassName;

  const singleSelectSelection = editorState.getSelection();
  const singleSelectValue = editorState
    .getCurrentContent()
    .getBlockForKey(singleSelectSelection.getStartKey())
    .getType();

  const [isColorPaletteVisible, setColorPaletteState] = useState<boolean>(
    false
  );

  const handleFontColorMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setColorPaletteState(!isColorPaletteVisible);
  };

  return (
    <div className={styles.wysiwygToolbarFontStyleControls}>
      <SingleSelect
        className={styles.fontControlsSingleSelect}
        placeholder={"Paragraph"}
        size={"x-small"}
        theme={"light"}
        options={[
          {
            label: "Paragraph",
            value: "",
          },
          {
            label: "H1",
            value: "header-one",
          },
          {
            label: "H2",
            value: "header-two",
          },
          {
            label: "H3",
            value: "header-three",
          },
          {
            label: "H4",
            value: "header-four",
          },
          {
            label: "H5",
            value: "header-five",
          },
          {
            label: "H6",
            value: "header-six",
          },
        ]}
        onChange={onSelectHandler}
        value={singleSelectValue}
      />
      <div className={wysiwygStyles.wysiwygFontColorButtonWrapper}>
        <button
          className={`${wysiwygStyles.wysiwygButton} ${
            wysiwygStyles[toolbarButtonClass]
          } ${isColorPaletteVisible && wysiwygStyles.wysiwygButtonActive}`}
          type={"button"}
          onMouseDown={handleFontColorMouseDown}
        >
          <ToolbarFontColorIcon />
        </button>
        {isColorPaletteVisible && (
          <div className={wysiwygStyles.wysiwygFontColorButtonPalette}>
            {colorPalette.map((paletteItem, index) => (
              <div
                key={index}
                className={wysiwygStyles.wysiwygPaletteButtonWrapper}
              >
                <button
                  className={`${wysiwygStyles.wysiwygPaletteButton} ${
                    wysiwygStyles[paletteItem.paletteButtonClassName]
                  } ${
                    colorPaletteValue === paletteItem.style &&
                    wysiwygStyles.wysiwygPaletteButtonActive
                  }`}
                  type={"button"}
                  onMouseDown={(event) => {
                    onColorSelectHandler(paletteItem.style, event);
                    setColorPaletteState(false);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FontStyleControls;
