import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import findKey from "lodash/findKey";

import Button from "../../../components/Button/Button";
import PercentageBar from "../../../components/PercentageBar/PercentageBar";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { LicenseQuotaService } from "../../../shared/shared-with-mobile/api-client/services/license-quota.service";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { LicensesContext } from "../../../shared/shared-with-mobile/providers/licenses.provider";
import { LicenseTiersContext } from "../../../shared/shared-with-mobile/providers/licenseTiers";
import { LicenseTierModel } from "../../../../../api/src/shared-with-mobile-and-web/models/license-tier.model";
import styles from "../Account.module.scss";
import CheckmarkIcon from "../../../resources/icons/CheckmarkIcon";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import TeamAddEditModal from "./TeamAddEditModal";
import { TeamModel } from "../../../generated/from-api/models/team.model";
import { LicenseHolderModel } from "../../../generated/from-api/models/license.model";
import { InvitationModel } from "../../../generated/from-api/models/invitation.model";
import { LicenseUsageModel } from "../../../generated/from-api/models/license-usage.model";
import { LICENSE_TIERS } from "../../../generated/from-api/api-constants/license-tier-constants";

interface Props {
  openAccountSetup: () => void;
  pendingInvitations: InvitationModel[];
  teamsInvitedTo: TeamModel[];
  handleAcceptInvite: (inviteId: string) => void;
  handleDeclineInvite: (inviteId: string) => void;
}

const TeamTab: React.FC<Props> = ({
  openAccountSetup,
  pendingInvitations,
  teamsInvitedTo,
  handleAcceptInvite,
  handleDeclineInvite,
}) => {
  const { teams, switchTeam } = useContext(TeamContext);
  const { currentLicenses } = useContext(LicensesContext);
  const { licenseTiers } = useContext(LicenseTiersContext);
  const { dispatchModal } = useContext(UIContext);
  const [licenseType, setLicenseType] = useState<string>("");
  const [licenseTier, setLicenseTier] = useState<LicenseTierModel>();
  const [licenseUsages, setLicenseUsages] = useState<
    Record<string, LicenseUsageModel>
  >({});

  const teamsArray = Array.from(teams.values()).sort((a, b) => {
    if (!a.created || !b.created) {
      return -1;
    }

    // sort teams from oldest to newest
    return new Date(a.created).getTime() - new Date(b.created).getTime();
  });

  const myLicenseIds = currentLicenses?.map((license) => license.id);

  // teams that the current user owns the license for
  const myTeams = teamsArray.filter((team) =>
    myLicenseIds?.includes(team.licenseId)
  );

  // teams that the current user coaches for, but does not own the license for
  const otherTeams = teamsArray.filter(
    (team) => !myLicenseIds?.includes(team.licenseId)
  );

  const unassignedLicenses = currentLicenses?.filter(
    (license) => !license.assigned
  );

  useEffect(() => {
    const fetchLicenseQuotaUsage = async () => {
      const nextLicenseUsages: Record<string, LicenseUsageModel> = {};
      const arrayOfTeams = Array.from(teams.values());
      const promises = arrayOfTeams.map((team) => {
        return LicenseQuotaService.GET_USAGE_FOR_TEAM(team.id as string);
      });

      const responses = await Promise.all(promises);

      for (const usage of responses) {
        if (usage?.teamId) {
          nextLicenseUsages[usage.teamId] = usage;
        }
      }

      setLicenseUsages(nextLicenseUsages);
    };

    fetchLicenseQuotaUsage();
  }, [teams]);

  const preProcessTeamsData = () => {
    if (currentLicenses && currentLicenses.length > 0) {
      // The license key and tier, FREE is the fallback
      const licenseKey =
        findKey(LICENSE_TIERS, {
          id: currentLicenses[0].licenseTierId,
        }) || "FREE";
      const licenseTier = licenseTiers.filter((tier) => {
        return tier.tier == licenseKey;
      })[0] as LicenseTierModel;

      if (!licenseTier) {
        return;
      }

      // Save all the data we worked so hard to get
      setLicenseType(licenseTier.description as string);
      setLicenseTier(licenseTier);
    }
  };
  useEffect(preProcessTeamsData, [currentLicenses, licenseTiers]);

  const openCreateEditTeamModal = (editTeam?: TeamModel) => {
    dispatchModal({
      title: `${editTeam === undefined ? "Create" : "Edit"} Team`,
      open: true,
      size: "large",
      body: <TeamAddEditModal team={editTeam} />,
    });
  };

  const formatAddress = (team: TeamModel) => {
    const addressParts: string[] = [];
    if (team.city) {
      addressParts.push(team.city);
    }

    if (team.state) {
      addressParts.push(team.state);
    }

    if (team.postalCode) {
      addressParts.push(team.postalCode);
    }

    return addressParts.length
      ? addressParts.join(" ")
      : "No address provided.";
  };

  const renderTeamSetupWidget = (team: TeamModel) => {
    if (!team.id) {
      return <div></div>;
    }

    const usage = licenseUsages[team.id];

    if (!usage) {
      return <div></div>;
    }

    const teamSetupSteps = [
      {
        completed: true,
        label: "Create Team",
        link: undefined,
      },
      {
        completed: usage.numberOfCoaches >= 1 && usage.numberOfPlayers >= 1,
        label: "Invite Roster",
        link: "/roster",
      },
      {
        completed: usage.numberOfPlays >= 4,
        label: "Add 4 Plays",
        link: "/playbook",
      },
      {
        completed: usage.numberOfCustomQuizzes >= 3,
        label: "Add 3 Flashcards",
        link: "/flashcards",
      },
      {
        completed: usage.numberOfInstalls >= 3,
        label: "Add 3 Lessons",
        link: "/lessons",
      },
    ];

    const numStepsCompleted = teamSetupSteps.filter((step) => step.completed)
      .length;

    return (
      <div>
        <span className={styles.TeamListingSingleDetailsSmallHeader}>
          Team Setup
        </span>
        <Tooltip
          tip={
            <ol className={styles.TeamListingSingleDetailsTooltip}>
              {teamSetupSteps.map((step, stepIndex) => {
                return (
                  <li
                    key={`step-index-${stepIndex}-${team.id}`}
                    className={styles.TeamListingSingleDetailsTooltipLine}
                  >
                    <span
                      className={`${
                        styles.TeamListingSingleDetailsTooltipBullet
                      } ${step.completed ? "active" : ""}`}
                    >
                      <CheckmarkIcon />
                    </span>
                    {step.link && !step.completed ? (
                      <Link
                        onClick={() => {
                          if (team.id) {
                            switchTeam(team.id);
                          }
                        }}
                        to={step.link}
                      >
                        {step.label}
                      </Link>
                    ) : (
                      <>{step.label}</>
                    )}
                  </li>
                );
              })}
            </ol>
          }
          clickTip={true}
          placement={"bottom"}
          className={styles.TeamListingSingleDetailsSetup}
        >
          <PercentageBar
            type="segmented"
            complete={numStepsCompleted}
            total={5}
          />
        </Tooltip>
      </div>
    );
  };

  const renderTeamListing = (team: TeamModel, index: number) => {
    return (
      <li className={styles.TeamListingSingle} key={team?.id}>
        {team.logo ? (
          <img src={team.logo} alt={`${team.name} team logo`} />
        ) : (
          <div className={styles.TeamListingEmptyTeam}>
            Team
            <strong>{index + 1}</strong>
          </div>
        )}
        <div className={styles.TeamListingSingleDetails}>
          <strong>{team.name}</strong>
          {team?.level.substr(0, 1).toUpperCase()}
          {team?.level.substr(1).toLowerCase()}
          <div className={styles.TeamListingSingleDetailsAddress}>
            {formatAddress(team)}
          </div>
        </div>
        <div>
          <span className={styles.TeamListingSingleDetailsSmallHeader}>
            Team Colors
          </span>
          <div
            className={styles.TeamListingSingleDetailsColor}
            style={{ backgroundColor: team.primaryColor || "#fff" }}
          ></div>
          <div
            className={styles.TeamListingSingleDetailsColor}
            style={{ backgroundColor: team.secondaryColor || "#fff" }}
          ></div>
        </div>
        {renderTeamSetupWidget(team)}
        <Button
          onClick={() => {
            openCreateEditTeamModal(team);
          }}
        >
          Edit
        </Button>
      </li>
    );
  };

  return (
    <div>
      {(!!currentLicenses?.length || teamsArray.length === 0) && <h1>Teams</h1>}
      {!!currentLicenses?.length && (
        <h2>
          {licenseType} License - {licenseTier?.maxTeams || 0}{" "}
          {licenseTier?.maxTeams === 1 ? "Team" : "Teams"}
        </h2>
      )}
      {!currentLicenses?.length && teamsArray.length === 0 && (
        <>
          <p className={styles.NoLicenseText}>
            No teams available, activate your license to get started.
          </p>
          <Button theme="primary" onClick={() => openAccountSetup()}>
            Activate License
          </Button>
        </>
      )}
      <ol className={styles.TeamListing}>
        {myTeams.map((team, index) => renderTeamListing(team, index))}
        {unassignedLicenses?.map(
          (license: LicenseHolderModel, index: number) => (
            <li className={styles.TeamListingEmpty} key={license.id}>
              <div className={styles.TeamListingEmptyTeam}>
                Team
                <strong>{myTeams.length + index + 1}</strong>
              </div>
              <div className={styles.TeamListingEmptySpacer}></div>
              <Button
                onClick={() => {
                  openCreateEditTeamModal();
                }}
              >
                Create Team
              </Button>
            </li>
          )
        )}
      </ol>

      {!!otherTeams.length && (
        <>
          <h1>
            {!!currentLicenses?.length ? "Accepted Invitations" : "Teams"}
          </h1>
          <ol className={styles.TeamListing}>
            {otherTeams.map((team, index) => renderTeamListing(team, index))}
          </ol>
        </>
      )}

      {pendingInvitations.length > 0 && (
        <>
          <h1>Pending Invitations</h1>
          <ol className={styles.TeamListing}>
            {pendingInvitations.map((invitation, index) => {
              const teamInvitedTo = teamsInvitedTo.find(
                (team) => team.id === invitation.teamId
              );
              return (
                <li
                  className={styles.TeamListingPendingInvite}
                  key={invitation.id}
                >
                  {teamInvitedTo?.logo ? (
                    <img
                      src={teamInvitedTo.logo}
                      alt={`${teamInvitedTo.name} team logo`}
                    />
                  ) : (
                    <div className={styles.TeamListingEmptyTeam}>
                      Invitation
                      <strong>{index + 1}</strong>
                    </div>
                  )}
                  <div>
                    <div className={styles.TeamListingPendingInviteTeamName}>
                      {teamInvitedTo?.name}
                    </div>
                    <div>
                      To: {invitation.firstName} {invitation.lastName}
                    </div>
                  </div>
                  <Button
                    theme="tertiary"
                    destructive
                    onClick={() => {
                      handleDeclineInvite(invitation.id as string);
                    }}
                  >
                    Remove
                  </Button>
                  <Button
                    onClick={() => {
                      handleAcceptInvite(invitation.id as string);
                    }}
                  >
                    Accept
                  </Button>
                </li>
              );
            })}
          </ol>
        </>
      )}
    </div>
  );
};

export default TeamTab;
