import React from "react";
import DataTable from "react-data-table-component";
import styles from "./DashboardStatsGrid.module.scss";

interface Props {
  data: any;
  tableTitle?: string;
  columns?: any;
}

const primaryTextColor = "#ffffff";
const primaryBackgroundColor = "#303241";
const primaryDividerColor = "#424258";
export const DATA_TABLES_DEFAULT_STYLES = {
  table: {
    style: {
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
      borderWidth: "3px",
      borderColor: primaryDividerColor,
      borderStyle: "solid",
      borderRadius: "6px",
    },
  },
  header: {
    style: {
      fontSize: "16px",
      minHeight: "36px",
      paddingLeft: "0px",
      paddingRight: "0px",
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
    },
  },
  headRow: {
    style: {
      minHeight: "24px",
      backgroundColor: primaryBackgroundColor,
      borderBottomWidth: "1px",
      borderBottomColor: primaryDividerColor,
      borderBottomStyle: "solid",
    },
  },
  rows: {
    style: {
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: primaryDividerColor,
      },
    },
  },
  headCells: {
    style: {
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
      textTransform: "upperCase",
      "&:not(:last-of-type)": {
        borderRightWidth: "1px",
        borderRightColor: primaryDividerColor,
        borderRightStyle: "solid",
      },
    },
    activeSortStyle: {
      color: primaryTextColor,
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: primaryTextColor,
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: primaryTextColor,
      },
      "&:hover": {
        color: primaryTextColor,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightWidth: "1px",
        borderRightColor: primaryDividerColor,
        borderRightStyle: "solid",
      },
    },
  },
  noData: {
    style: {
      color: primaryTextColor,
      backgroundColor: primaryBackgroundColor,
    },
  },
};

export const DashboardStatsGrid: React.FC<Props> = ({
  data,
  tableTitle,
  columns,
}) => {
  return (
    <>
      {tableTitle && <div className={styles.title}>{tableTitle}</div>}
      <div className={styles.chartCard}>
        <div className={styles.chartContainer}>
          {data?.length ? (
            <DataTable
              data={data}
              columns={columns}
              customStyles={DATA_TABLES_DEFAULT_STYLES}
              defaultSortFieldId={columns.length}
              defaultSortAsc={false}
            />
          ) : (
            <div className={styles.dataNotFound}>
              <div className={styles.dataNotFoundMsg}>
                There are no records to display
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
