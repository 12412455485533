import { useContext, useEffect, useRef, useState } from "react";
import { Stage, Layer, Line, Rect } from "react-konva";

import styles from "./PlaySetThumbnail.module.scss";
import {
  convertRelativeLineToAbsolute,
  convertPlayerToShape,
  useContainerDimensions,
} from "../../../shared/shared-with-mobile/play-editor/playEditor.utils";
import Shape from "../../../shared/shared-with-mobile/play-editor/Shape";
import CoverageZone from "../../../shared/shared-with-mobile/play-editor/CoverageZone";
import FieldBackground from "../../../shared/shared-with-mobile/play-editor/FieldBackground";
import { colors } from "../../../shared/shared-with-mobile/play-editor/play-editor-colors";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { DrawablePlayerModel } from "../../../generated/from-api/models/drawable/drawablePlayer.model";
import { lineOfScrimmageY } from "../../../shared/shared-with-mobile/play-editor/playEditor.constants";
import { FormationModel } from "../../../generated/from-api/models/drawable/formation.model";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { PlaySetsContext } from "../../../shared/shared-with-mobile/providers/playSets.provider";
import { PlaySetModel } from "../../../generated/from-api/models/play-set.model";

interface Props {
  play?: PlayModel;
  formation?: FormationModel;
  useCheckbox?: boolean;
  showCheckbox?: boolean;
  playSelected?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  modal?: boolean;
  useLoader?: boolean;
  openEditPlaySet: (playSet: PlaySetModel) => void;
}

const PlaySetThumbnail: React.FC<Props> = ({
  play,
  formation,
  onChange,
  useCheckbox = false,
  showCheckbox = false,
  playSelected = false,
  modal = false,
  useLoader = false,
  openEditPlaySet,
}) => {
  const [showPlay, setShowPlay] = useState<boolean>(!useLoader);
  const stageContainerRef = useRef<HTMLDivElement>(null);
  const {
    containerWidth: stageWidth,
    containerHeight: stageHeight,
  } = useContainerDimensions(stageContainerRef);
  const { playSets } = useContext(PlaySetsContext);
  const [selectedPlaySet, setSelectedPlaySet] = useState<PlaySetModel>();

  const playOrFormation: any = play || formation;

  useEffect(() => {
    if (play) {
      const playSet = playSets.find(
        (playSet) => playSet.id === play?.playSetId
      );

      setSelectedPlaySet(playSet);
    }
  }, [playSets, play]);

  useEffect(() => {
    if (!useLoader && !showPlay) {
      setTimeout(() => {
        setShowPlay(true);
      }, 500);
    }
  }, [useLoader]);

  const thumbnail = (
    <Stage width={stageWidth} height={stageHeight} listening={false}>
      <Layer>
        <Rect
          x={0}
          y={0}
          width={stageWidth}
          height={stageHeight}
          fill={colors.fieldBackgroundGreen}
        />
        <FieldBackground
          stageWidth={stageWidth}
          stageHeight={stageHeight}
          ballOn={playOrFormation.ballOn || 50}
        />
        <Line
          stroke={colors.lineOfScrimmageGray}
          strokeWidth={2.5}
          points={convertRelativeLineToAbsolute(
            [0, lineOfScrimmageY, 1, lineOfScrimmageY],
            stageWidth,
            stageHeight
          )}
        />
        {playOrFormation.drawablePlayers
          .filter((player: DrawablePlayerModel) => {
            if (!player.coverageZoneRadius && !player.coverageZoneRadiusX) {
              return false;
            }

            if (!playOrFormation.hideOpponent) {
              return true;
            }

            return playOrFormation.playType === player.playType;
          })
          .map((player: DrawablePlayerModel) => (
            <CoverageZone
              key={player.id}
              shape={convertPlayerToShape(player)}
              stageHeight={stageHeight}
              stageWidth={stageWidth}
            />
          ))}

        {playOrFormation.drawablePlayers
          .filter((player: DrawablePlayerModel) => {
            if (!playOrFormation.hideOpponent) {
              return true;
            }

            return playOrFormation.playType === player.playType;
          })
          .map((player: DrawablePlayerModel) => (
            <Shape
              key={player.id}
              shape={convertPlayerToShape(
                player,
                playOrFormation.playType || playOrFormation.type
              )}
              stageHeight={stageHeight}
              stageWidth={stageWidth}
              draggable={false}
              isSelected={false}
              isDragging={false}
            />
          ))}
      </Layer>
    </Stage>
  );

  return (
    <div className={styles.playSetThumbnail} ref={stageContainerRef}>
      {showPlay ? (
        useCheckbox && play ? (
          <Checkbox
            showCheckbox={showCheckbox}
            addExtraPadding={true}
            onChange={onChange}
            checked={playSelected}
          >
            {!!play && modal ? (
              <span
                onClick={() =>
                  selectedPlaySet && openEditPlaySet(selectedPlaySet)
                }
              >
                <div className={styles.playsCountContainer}>
                  <span>
                    {selectedPlaySet && selectedPlaySet?.playOrder.length} Play
                    {selectedPlaySet && selectedPlaySet?.playOrder.length === 1
                      ? ""
                      : "s"}
                  </span>
                </div>
                <span>{thumbnail}</span>
              </span>
            ) : (
              <span>
                <div className={styles.playsCountContainer}>
                  <span>
                    {selectedPlaySet && selectedPlaySet?.playOrder.length} Play
                    {selectedPlaySet && selectedPlaySet?.playOrder.length === 1
                      ? ""
                      : "s"}
                  </span>
                </div>
                <span>{thumbnail}</span>
              </span>
            )}
          </Checkbox>
        ) : (
          <span>
            <div className={styles.playsCountContainer}>
              <span>
                {selectedPlaySet && selectedPlaySet?.playOrder.length} Play
                {selectedPlaySet && selectedPlaySet?.playOrder.length === 1
                  ? ""
                  : "s"}
              </span>
            </div>
            <span>{thumbnail}</span>
          </span>
        )
      ) : (
        <div className={styles.loader}></div>
      )}
    </div>
  );
};

export default PlaySetThumbnail;
