import React, { useContext, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import styles from "./EditQuiz.module.scss";
import Button from "../../../../components/Button/Button";
import CharacterInput from "../../../../components/Input/CharacterInput";
import PositionSelector from "../../../../components/PositionSelector/PositionSelector";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import plusIcon from "../../../../resources/images/grayPlus.svg";
import yellowPlusIcon from "../../../../resources/images/yellowPlus.svg";
import pencilIcon from "../../../../resources/images/grayPencil.svg";
import imageIcon from "../../../../resources/images/image.svg";
import closeButtonIcon from "../../../../resources/images/closeButton.png";
import trashIcon from "../../../../resources/images/trash.svg";
import dragIcon from "../../../../resources/images/dragIndicator.svg";
import { CustomQuizModel } from "../../../../generated/from-api/models/custom-quiz.model";
import {
  CustomQuizQuestionOption,
  CustomQuizQuestionOptionType,
} from "../../../../generated/from-api/models/custom-quiz/custom-quiz-question-option.model";
import { TagModel } from "../../../../generated/from-api/models/tag.model";
import { CustomQuizQuestionModel } from "../../../../generated/from-api/models/custom-quiz/custom-quiz-question.model";
import SingleSelect, {
  SingleSelectOption,
} from "../../../../components/SingleSelect/SingleSelect";
import { TeamContext } from "../../../../shared/shared-with-mobile/providers/team.provider";
import MediaThumbnail from "../../../../components/media/MediaThumbnail/MediaThumbnail";
import {
  MediaModel,
  TN_MEDIA_TYPE,
} from "../../../../generated/from-api/models/media.model";
import { MediaContext } from "../../../../shared/shared-with-mobile/providers/media.provider";
import { UIContext } from "../../../../shared/shared-with-mobile/providers/ui.provider";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import MediaGallery from "../../../../components/MediaGallery/MediaGallery";
import {
  validateFileSize,
  uploadFile,
} from "../../../../utils/mediaHelpers.utils";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import DropUpload from "../../../../components/DropUpload/DropUpload";
import ContentLimitModal from "../../../../components/ContentLimitModal/ContentLimitModal";
import QuizCategoriesModal from "../QuizCategoriesModal/QuizCategoriesModal";
import ChevronLeftIcon from "../../../../resources/icons/ChevronLeftIcon";
import { Link } from "react-router-dom";
import { userRoles } from "../../../../shared/shared-with-mobile/constants";
import { UserContext } from "../../../../shared/shared-with-mobile/providers/user.provider";
import validateQuizAndGetErrorMessage from "../../../../utils/quizValidator.utils";
import Switch from "../../../../components/Switch/Switch";
import Checkbox from "../../../../components/Checkbox/Checkbox";

interface Props {
  quiz: CustomQuizModel;
  updateQuiz: (quizToUpdate: CustomQuizModel) => void;
  openDeleteQuizModal: (quizToDelete: CustomQuizModel) => void;
  categories: TagModel[];
  userIsAdmin?: boolean;
}

const EditQuiz: React.FC<Props> = ({
  quiz,
  updateQuiz,
  openDeleteQuizModal,
  categories,
  userIsAdmin = false,
}) => {
  const { currentTeam } = useContext(TeamContext);
  const { userProfile } = useContext(UserContext);
  const [quizModal, setQuizModal] = useState<CustomQuizModel>();
  const { dispatchModal, dispatchToast, handleCreateError } = useContext(
    UIContext
  );
  const { addMediaItems, mediaItemsMap } = useContext(MediaContext);

  const quizRouteName =
    userProfile?.role === userRoles.ADMIN ? "global-flashcards" : "flashcards";

  const categoryOptions: SingleSelectOption[] = categories.map((category) => ({
    label: category.name,
    value: category.id as string,
  }));

  const updatePositions = (positions: string) => {
    const nextQuiz = cloneDeep(quiz);
    nextQuiz.showQuizToPositions = positions;
    updateQuiz(nextQuiz);
  };

  const updateIncludeToTeamsValue = (value: boolean) => {
    const newQuiz = cloneDeep(quiz);
    newQuiz.autoInclude = value;
    updateQuiz(newQuiz);
  };

  const setQuizAutoGenerateQuestions = (
    event: React.MouseEvent<HTMLDivElement>,
    newVal: boolean
  ) => {
    event.stopPropagation();
    const nextQuiz = cloneDeep(quiz);
    nextQuiz.autoGenerateQuestions = newVal;
    updateQuiz(nextQuiz);
  };

  const addQuestion = () => {
    const nextQuiz = cloneDeep(quiz);
    const newQuestion = {
      id: uuidv4(),
      question: "",
      correctOptions: [],
      incorrectOptions: [],
    };

    nextQuiz.questions.push(newQuestion);
    updateQuiz(nextQuiz);
  };

  const updatePublish = (newVal: string) => {
    const newQuiz = cloneDeep(quiz);
    newQuiz.published = newVal === "published";
    if (newQuiz.published) {
      const isQuizValid = validateQuizAndGetErrorMessage(
        newQuiz,
        Object.values(mediaItemsMap)
      );

      if (!isQuizValid.isValid) {
        newQuiz.published = false;
        dispatchToast({
          type: "error",
          message: `${isQuizValid.errorMessage}`,
        });
      }
    }
    updateQuiz(newQuiz);
  };

  const updateName = (newVal: string) => {
    const nextQuiz = cloneDeep(quiz);
    nextQuiz.name = newVal;
    updateQuiz(nextQuiz);
  };

  const updateDescription = (newVal: string) => {
    const nextQuiz = cloneDeep(quiz);
    nextQuiz.description = newVal;
    updateQuiz(nextQuiz);
  };

  const updateCategory = (newVal: string) => {
    const nextQuiz = cloneDeep(quiz);
    nextQuiz.categoryTagId = newVal;
    updateQuiz(nextQuiz);
  };

  const updateQuestion = (newVal: string, index: number) => {
    const nextQuiz = cloneDeep(quiz);
    nextQuiz.questions[index].question = newVal;
    updateQuiz(nextQuiz);
  };

  const updateOptionText = (
    newVal: string,
    questionIndex: number,
    optionIndex: number,
    isCorrectOption: boolean
  ) => {
    const nextQuiz = cloneDeep(quiz);
    const optionsArrayKey = isCorrectOption
      ? "correctOptions"
      : "incorrectOptions";

    nextQuiz.questions[questionIndex][optionsArrayKey][
      optionIndex
    ].text = newVal;

    updateQuiz(nextQuiz);
    setQuizModal(nextQuiz);
  };

  const addTextOption = (questionIndex: number, isCorrectOption: boolean) => {
    const nextQuiz = cloneDeep(quiz);

    const newOption: CustomQuizQuestionOption = {
      optionType: "TEXT",
      text: "",
    };

    if (isCorrectOption) {
      nextQuiz.questions[questionIndex].correctOptions.push(newOption);
    } else {
      nextQuiz.questions[questionIndex].incorrectOptions.push(newOption);
    }

    updateQuiz(nextQuiz);
  };

  const removeOption = (
    questionIndex: number,
    optionIndex: number,
    isCorrectOption: boolean
  ) => {
    const nextQuiz = cloneDeep(quiz);
    const optionsKey = isCorrectOption ? "correctOptions" : "incorrectOptions";
    nextQuiz.questions[questionIndex][optionsKey].splice(optionIndex, 1);
    updateQuiz(nextQuiz);
    setQuizModal(nextQuiz);
  };

  const openDeleteQuestionModal = (questionIndex: number) => {
    dispatchModal({
      title: "Delete Flashcard",
      open: true,
      body: (
        <ConfirmationModal
          actionName="Delete"
          itemName="card"
          actionCallback={() => {
            const nextQuiz = cloneDeep(quiz);
            nextQuiz.questions.splice(questionIndex, 1);
            updateQuiz(nextQuiz);
          }}
        />
      ),
    });
  };

  const openMediaGalleryModal = (
    isQuestion: boolean,
    isCorrectOption: boolean,
    questionIndex: number,
    defaultFilters: Array<TN_MEDIA_TYPE> = ["IMAGE", "VIDEO"],
    allowBulkSelect = true
  ) => {
    let itemName = "Question";
    if (quiz.autoGenerateQuestions) {
      itemName = isQuestion ? "Term" : "Definition";
    } else {
      itemName = isQuestion ? "Question" : "Answer";
    }

    dispatchModal({
      open: true,
      size: "large",
      body: (
        <MediaGallery
          type={"Modal"}
          allowBulkSelect={allowBulkSelect}
          defaultFilters={defaultFilters}
          handleAddMedia={(media: MediaModel[]) => {
            if (isQuestion) {
              addMediaToQuestion(questionIndex, media);
            } else {
              addMediaOptions(isCorrectOption, questionIndex, media);
            }
          }}
          addMediaButtonText={`Add Media to ${itemName}`}
        />
      ),
      className: styles.mediaGallerySetupModal,
    });
  };

  const uploadMedia = async (
    isCorrectOption: boolean,
    questionIndex: number,
    file: File
  ) => {
    const obj = validateFileSize(file);
    if (!obj.isValid) {
      dispatchToast({
        type: "error",
        message: obj.errorMessage,
      });
      return;
    }

    try {
      const result = await uploadFile(file, currentTeam?.id);
      if (result && result.id) {
        addMediaItems([result]); // add to context
        addMediaOption(isCorrectOption, questionIndex, result.type, result.id); // add to question answer options
      }
    } catch (error) {
      handleCreateError(error, ContentLimitModal, "media item", "media items");
    }
  };

  const addMediaOption = (
    isCorrectOption: boolean,
    questionIndex: number,
    optionType: CustomQuizQuestionOptionType,
    mediaId: string
  ) => {
    const nextQuiz = cloneDeep(quiz);
    const newOption: CustomQuizQuestionOption = {
      optionType,
      mediaId,
    };

    const optionsKey = isCorrectOption ? "correctOptions" : "incorrectOptions";
    nextQuiz.questions[questionIndex][optionsKey].push(newOption);

    updateQuiz(nextQuiz);
  };

  const addMediaOptions = (
    isCorrectOption: boolean,
    questionIndex: number,
    media: MediaModel[]
  ) => {
    const nextQuiz = cloneDeep(quiz);
    const newOptions: CustomQuizQuestionOption[] = media.map((item) => {
      return {
        mediaId: item.id,
        optionType: item.type,
      };
    });

    const optionsKey = isCorrectOption ? "correctOptions" : "incorrectOptions";
    nextQuiz.questions[questionIndex][optionsKey].push(...newOptions);

    updateQuiz(nextQuiz);
  };

  // TODO: conditionally disallow bulk selection in the media gallery modal
  // because only a single media item can be added to a question
  const addMediaToQuestion = (questionIndex: number, media: MediaModel[]) => {
    const nextQuiz = cloneDeep(quiz);

    if (media.length) {
      const selectedMedia = media[0];

      // only image and video can be attached to questions, per designs
      if (selectedMedia.type === "IMAGE" || selectedMedia.type === "VIDEO") {
        nextQuiz.questions[questionIndex].questionMediaId = selectedMedia.id;
        updateQuiz(nextQuiz);
      }
    }
  };

  const removeQuestionMedia = (questionIndex: number) => {
    const nextQuiz = cloneDeep(quiz);
    nextQuiz.questions[questionIndex].questionMediaId = undefined;
    updateQuiz(nextQuiz);
  };

  const reorderQuestionsInQuiz = (startIndex: number, endIndex: number) => {
    const reorderedQuiz = cloneDeep(quiz);
    const [removed] = reorderedQuiz.questions.splice(startIndex, 1);
    reorderedQuiz.questions.splice(endIndex, 0, removed);
    updateQuiz(reorderedQuiz);
  };

  const handleDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      // dropped outside of droppable area
      return;
    }

    const startIndex = dropResult.source.index;
    const endIndex = dropResult.destination.index;
    if (startIndex === endIndex) {
      return;
    }

    reorderQuestionsInQuiz(startIndex, endIndex);
  };

  // returns JSX for either the "Correct Answers / Definitions" section or the "Inncorrect Answers" section
  const renderAnswersSection = (
    isCorrectOption: boolean,
    question: CustomQuizQuestionModel,
    questionIndex: number
  ) => (
    <>
      <div className={styles.answersLabel}>
        {!isCorrectOption
          ? "Incorrect Answers"
          : !quiz.autoGenerateQuestions
          ? "Correct Answers"
          : "Definitions"}
      </div>
      {quiz.autoGenerateQuestions && (
        <p>
          Add any content that demonstrates this term, like a definition, photo
          or video.{" "}
          <Tooltip
            top={3}
            left={3}
            tip="For the best player experience, make sure each piece of content you upload only matches this term, and not another term in the same quiz."
          />
        </p>
      )}
      <div className={styles.answersSection}>
        <div className={styles.answerTypes}>
          <DropUpload
            hoverClass={styles.dropUpload}
            dropAction={(files: any) => {
              uploadMedia(isCorrectOption, questionIndex, files[0]);
            }}
          >
            <div className={styles.answerType} role="button">
              <img src={imageIcon} alt="image icon" />
              <div>
                Drop files or{" "}
                <span
                  className={styles.mediaGalleryLink}
                  role="button"
                  onClick={() => {
                    openMediaGalleryModal(
                      false,
                      isCorrectOption,
                      questionIndex
                    );
                  }}
                >
                  browse
                </span>
                <label
                  htmlFor={`file-upload-${questionIndex}-${isCorrectOption}`}
                  className={styles.fileUploader}
                >
                  <input
                    id={`file-upload-${questionIndex}-${isCorrectOption}`}
                    accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime, video/avi, video/x-ms-asf, video/hevc, audio/mp3, audio/wav"
                    type="file"
                    className={styles.fileUploaderInput}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length) {
                        uploadMedia(
                          isCorrectOption,
                          questionIndex,
                          e.target.files[0]
                        );
                      }
                      e.target.value = null as any;
                    }}
                  />
                </label>
              </div>
            </div>
          </DropUpload>
          <div
            className={styles.answerType}
            role="button"
            onClick={() => {
              addTextOption(questionIndex, isCorrectOption);
            }}
          >
            <img src={pencilIcon} alt="pencil icon" />
            <div>Write a definition</div>
          </div>
        </div>
        {(isCorrectOption
          ? question.correctOptions
          : question.incorrectOptions
        ).map((option, optionIndex) => (
          <div key={optionIndex} className={styles.answerBox}>
            <img
              className={styles.closeButton}
              src={closeButtonIcon}
              alt="remove icon"
              role="button"
              onClick={() => {
                removeOption(questionIndex, optionIndex, isCorrectOption);
              }}
            />
            {option.optionType === "TEXT" && (
              <textarea
                placeholder={`Write a definition for "${question.question}"`}
                className={styles.answerTextArea}
                style={{ resize: "none" }}
                value={(() => {
                  if (quizModal === undefined) {
                    return option.text;
                  }
                  const optionType = isCorrectOption
                    ? "correctOptions"
                    : "incorrectOptions";
                  if (
                    quizModal.questions[questionIndex] &&
                    quizModal.questions[questionIndex][optionType] &&
                    quizModal.questions[questionIndex][optionType][
                      optionIndex
                    ] !== undefined
                  ) {
                    return quizModal.questions[questionIndex][optionType][
                      optionIndex
                    ].text;
                  } else {
                    return "";
                  }
                })()}
                onChange={(e) => {
                  updateOptionText(
                    e.currentTarget.value,
                    questionIndex,
                    optionIndex,
                    isCorrectOption
                  );
                }}
              />
            )}
            {option.optionType !== "TEXT" && option.mediaId && (
              <MediaThumbnail mediaItem={mediaItemsMap[option.mediaId]} />
            )}
          </div>
        ))}
      </div>
    </>
  );

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "#484A59" : "#303241",
    borderRadius: "6px",
  });

  return (
    <div className={styles.editQuiz}>
      <div className={styles.editQuizTopBar}>
        <div className={styles.editQuizTitle}>
          {quizRouteName && (
            <Link
              className={styles.QuizBuilderBackButton}
              to={`/${quizRouteName}/`}
            >
              <ChevronLeftIcon />
            </Link>
          )}
          Flashcard Game
        </div>
        <div className={styles.editQuizTopButtons}>
          <Button
            theme="tertiary"
            size={"small"}
            destructive
            onClick={() => {
              openDeleteQuizModal(quiz);
            }}
          >
            Delete
          </Button>
        </div>
      </div>

      <div className={styles.QuizBuilderNameAndType}>
        <CharacterInput
          className={styles.QuizBuilderNameAndTypeName}
          placeholder="Name"
          value={quiz.name}
          onChange={(e) => {
            updateName(e.currentTarget.value);
          }}
          maxLength={255}
        />
        <div className={styles.QuizBuilderNameAndTypePublishToggleRow}>
          <Switch
            theme="yellow"
            toggled={quiz.published ? true : false}
            onToggle={() => {
              updatePublish(quiz.published ? "draft" : "published");
            }}
          />
          {quiz.published ? "Published" : "Unpublished"}
        </div>
      </div>

      <CharacterInput
        placeholder="Description"
        value={quiz.description}
        onChange={(e) => {
          updateDescription(e.currentTarget.value);
        }}
        maxLength={255}
      />
      {!!categoryOptions.length && (
        <div className={styles.categoryEditLine}>
          <SingleSelect
            placeholder="Category"
            options={categoryOptions}
            value={quiz.categoryTagId || ""}
            onChange={(event) => {
              updateCategory(event.currentTarget.value);
            }}
            className={styles.categoryEditSelector}
          />
          <Button
            theme="tertiary"
            onClick={() => {
              dispatchModal({
                body: <QuizCategoriesModal />,
                size: "medium",
                open: true,
              });
            }}
            className={styles.categoryEditButton}
          >
            Edit Categories
          </Button>
        </div>
      )}
      <div
        className={`${styles.QuizBuilderRow} ${styles.QuizBuilderRowSpacingLarge}`}
      >
        <PositionSelector
          checkboxLabel="Show this game to all player positions"
          showToPositions={quiz.showQuizToPositions}
          updatePositions={updatePositions}
        />
      </div>

      {userIsAdmin && (
        <div
          className={`${styles.QuizBuilderRow} ${styles.QuizBuilderRowSpacingLarge}`}
        >
          <Checkbox
            onChange={(event) =>
              updateIncludeToTeamsValue(event.currentTarget.checked)
            }
            checked={!!quiz.autoInclude}
          >
            Automatically include this game for all new teams
          </Checkbox>
        </div>
      )}
      <div className={styles.quizFormatOptions}>
        <div
          className={styles.quizFormatOption}
          onClick={(e) => {
            setQuizAutoGenerateQuestions(e, true);
          }}
        >
          <RadioButton selected={!!quiz.autoGenerateQuestions} />
          <div>
            Use Team Nation’s App Engine to create games automatically.
            (Recommended){" "}
            <Tooltip
              top={4}
              left={4}
              tip="Team Nation will create games based on the content you provide."
            />
          </div>
        </div>
        <div
          className={styles.quizFormatOption}
          onClick={(e) => {
            setQuizAutoGenerateQuestions(e, false);
          }}
        >
          <RadioButton selected={!quiz.autoGenerateQuestions} />
          <div>I would like to hand-build my own Flashcard Game.</div>
        </div>
      </div>
      <div className={styles.quizTerms}>
        <div className={styles.quizTermsHeaderBar}>
          <div>Flashcards</div>
          <img
            src={plusIcon}
            alt="add flashcard term"
            role="button"
            onClick={addQuestion}
          />
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {quiz.questions.map((question, questionIndex) => (
                  <Draggable
                    key={question.id}
                    draggableId={question.id}
                    index={questionIndex}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`${styles.editQuizTerm} question`}
                      >
                        <div className={styles.quizTermHeader}>
                          <div className={styles.flexRow}>
                            <img
                              src={dragIcon}
                              alt="draggable icon"
                              role="button"
                              {...provided.dragHandleProps}
                            />
                            <span className={styles.order}>
                              {questionIndex + 1}
                            </span>
                          </div>
                          <img
                            src={trashIcon}
                            alt="delete icon"
                            role="button"
                            onClick={() => {
                              openDeleteQuestionModal(questionIndex);
                            }}
                          />
                        </div>
                        <div className={styles.quizTermContainer}>
                          <CharacterInput
                            placeholder={
                              quiz.autoGenerateQuestions ? "Term" : "Question"
                            }
                            value={question.question}
                            onChange={(e) => {
                              updateQuestion(
                                e.currentTarget.value,
                                questionIndex
                              );
                            }}
                          />
                          {question.questionMediaId ? (
                            <div className={styles.questionMediaContainer}>
                              <img
                                className={styles.removeQuestionMediaButton}
                                role="button"
                                src={closeButtonIcon}
                                onClick={() => {
                                  removeQuestionMedia(questionIndex);
                                }}
                                alt="remove icon"
                              />
                              <MediaThumbnail
                                mediaItem={
                                  mediaItemsMap[question.questionMediaId]
                                }
                              />
                            </div>
                          ) : (
                            <div
                              className={styles.addQuestionMediaButton}
                              role="button"
                              onClick={() => {
                                openMediaGalleryModal(
                                  true,
                                  false,
                                  questionIndex,
                                  ["IMAGE", "VIDEO"],
                                  false
                                );
                              }}
                            >
                              <img src={yellowPlusIcon} alt="plus icon" />
                              <div>
                                Add Image or Video to the{" "}
                                {quiz.autoGenerateQuestions
                                  ? "term"
                                  : "question"}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={styles.quizTermAnswers}>
                          {renderAnswersSection(true, question, questionIndex)}
                          {!quiz.autoGenerateQuestions &&
                            renderAnswersSection(
                              false,
                              question,
                              questionIndex
                            )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div
          role="button"
          className={styles.addTermButton}
          onClick={addQuestion}
        >
          <img src={plusIcon} alt="plus icon" />
          <span>Add Flashcard</span>
        </div>
      </div>
    </div>
  );
};

export default EditQuiz;
