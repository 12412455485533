import React, { useContext, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";

import styles from "./LoginSignup.module.scss";
import Logo from "../../resources/images/Logo.svg";
import Button from "../../components/Button/Button";
import CharacterInput from "../../components/Input/CharacterInput";
import { auth } from "../../shared/shared-with-mobile/api-client/firebase.utils";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import visibility from "../../resources/images/visibility.svg";
import visibilityOff from "../../resources/images/visibility_off.svg";

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
}

const Login: React.FC = () => {
  const history = useHistory();
  const { closeModal, dispatchModal } = useContext(UIContext);
  const { currentUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const entryUrl = localStorage.getItem("entryUrl");

  enum errorMessages {
    noUser = "This email is not associated with any accounts.",
  }

  const goToSignupHandler = (isNoEmail: boolean) => {
    closeModal();
    if (isNoEmail) {
      history.push("/signup");
    }
  };

  const login = async (event: React.FormEvent) => {
    event.preventDefault();

    const { email, password } = (event.currentTarget as HTMLFormElement)
      .elements as FormElements;

    try {
      const response = await auth.signInWithEmailAndPassword(
        email.value,
        password.value
      );
      return response;
    } catch (err: any) {
      const noUserErrorCode = "auth/user-not-found";
      const isEmailNotFoundError = err.code === noUserErrorCode;
      dispatchModal({
        title: "Login Error",
        open: true,
        size: "small",
        className: styles.errorModal,
        body: (
          <>
            <p>{isEmailNotFoundError ? errorMessages.noUser : err.message}</p>
            <Button
              size={"small"}
              onClick={() => goToSignupHandler(isEmailNotFoundError)}
            >
              {isEmailNotFoundError ? "Go to Signup" : "Try again"}
            </Button>
          </>
        ),
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return currentUser ? (
    <Redirect to={entryUrl ? entryUrl : "/dashboard"} />
  ) : (
    <div className={styles.login}>
      <section className={styles.content}>
        <img src={Logo} alt="Team Nation" className={styles.logo} />
        <form className={styles.form} onSubmit={login}>
          <div className={styles.title}>Login</div>
          <CharacterInput
            type={INPUT_TYPES.EMAIL}
            placeholder="Email"
            id="email"
          />
          <div className={styles.passwordContainer}>
            <CharacterInput
              type={showPassword ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
              placeholder="Password"
              id="password"
            />
            <span
              className={styles.showPassword}
              onClick={togglePasswordVisibility}
            >
              <img src={showPassword ? visibilityOff : visibility} />
            </span>
          </div>
          <Link className={styles.forgot} to="forgot-password">
            Forgot Password?
          </Link>
          <Button size={"small"} buttonType={"submit"}>
            Continue
          </Button>
        </form>
        <p className={styles.otherCta}>
          Don&apos;t have an account?
          <br />
          <Link to="signup">Create Account</Link>
        </p>
        <Button
          className={styles.helpButton}
          type={"external link"}
          externalLinkOptions={{
            href: "https://knowledge.teamnationsports.com",
          }}
          theme="tertiary"
          size={"x-small"}
        >
          Help
        </Button>
      </section>
    </div>
  );
};

export default Login;
