import * as React from "react";

const UploadIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 13.5V7.5H14.5L7.5 0.5L0.5 7.5H4.5V13.5H10.5ZM7.5 3.33L9.67 5.5H8.5V11.5H6.5V5.5H5.33L7.5 3.33ZM14.5 17.5V15.5H0.5V17.5H14.5Z"
    />
  </svg>
);

export default UploadIcon;
