import * as React from "react";

const ToolbarBoldIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="12"
    viewBox="0 0 10 12"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.68742 5.82508C8.49575 5.26675 9.06242 4.35008 9.06242 3.50008C9.06242 1.61675 7.60408 0.166748 5.72908 0.166748H0.520752V11.8334H6.38742C8.12909 11.8334 9.47908 10.4167 9.47908 8.67508C9.47908 7.40842 8.76242 6.32508 7.68742 5.82508ZM3.02075 2.25008H5.52075C6.21242 2.25008 6.77075 2.80841 6.77075 3.50008C6.77075 4.19175 6.21242 4.75008 5.52075 4.75008H3.02075V2.25008ZM3.02075 9.75008H5.93742C6.62909 9.75008 7.18742 9.19175 7.18742 8.50008C7.18742 7.80841 6.62909 7.25008 5.93742 7.25008H3.02075V9.75008Z"
    />
  </svg>
);

export default ToolbarBoldIcon;
