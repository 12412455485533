import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom"; //useParams,
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import validateQuizAndGetErrorMessage from "../../utils/quizValidator.utils";

import QuizListItem from "./quiz-components/ListItem";
// import FeaturedQuizzes from "./quiz-components/FeaturedQuizzes";
import CharacterInput from "../../components/Input/CharacterInput";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import EditQuiz from "./quiz-components/EditQuiz/EditQuiz";
import search from "../../resources/images/search.svg";

// This view is mostly a clone of the install one, include it's CSS and the Installs one.
import quizzesStyles from "./Quizzes.module.scss";
import installStyles from "../Installs/Installs.module.scss";
import mediaGalleryStyles from "../../components/MediaGallery/MediaGallery.module.scss";
import sidebarStyles from "./quiz-components/QuizSidebar/QuizSidebar.module.scss";
const styles = {
  ...installStyles,
  ...quizzesStyles,
  ...mediaGalleryStyles,
  ...sidebarStyles,
};

import { CustomQuizModel } from "../../generated/from-api/models/custom-quiz.model";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { userRoles } from "../../shared/shared-with-mobile/constants";

import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { QuizzesContext } from "../../shared/shared-with-mobile/providers/quizzes.provider";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { TagsContext } from "../../shared/shared-with-mobile/providers/tags.provider";
import { MediaContext } from "../../shared/shared-with-mobile/providers/media.provider";
import ArchivedQuiz from "./quiz-components/ArchiveItem";
import QuizImportModal from "./quiz-components/QuizImportModal/QuizImportModal";
import QuizCategoriesModal from "./quiz-components/QuizCategoriesModal/QuizCategoriesModal";
import ContentLimitModal from "../../components/ContentLimitModal/ContentLimitModal";
import isEmpty from "lodash/isEmpty";
import ListIcon from "../../resources/icons/ListIcon";
import GearIcon from "../../resources/icons/GearIcon";
import Button from "../../components/Button/Button";
import PhonePreview from "../../components/PhonePreview/PhonePreview";
import QuizPreview from "./quiz-components/QuizPreview/QuizPreview";
import Tooltip from "../../components/Tooltip/Tooltip";
import { UserProfileModel } from "../../generated/from-api/models/user-profile.model";

const Quizzes: React.FC = () => {
  const { currentTeam } = useContext(TeamContext);
  const { userProfile, updateProfile } = useContext(UserContext);
  const { customQuizCategoryTags } = useContext(TagsContext);
  const { dispatchModal, dispatchToast, handleCreateError } = useContext(
    UIContext
  );
  const { mediaItemsMap, globalAndTeamMediaLoaded } = useContext(MediaContext);
  const {
    quizzes,
    globalQuizzes,
    removeQuiz,
    updateQuiz,
    updateQuizzes,
    addQuiz,
    quizStats,
    updateGlobalQuizzes,
    areQuizTooltipsTemporarilyDismissed,
  } = useContext(QuizzesContext);
  const [searchString, setSearchString] = useState("");
  const [filterCategories, setFilterCategories] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState<CustomQuizModel | null>(
    null
  );
  const history = useHistory();
  const { id: selectedQuizId } = useParams<{ id: string }>();

  const quizRouteName =
    userProfile?.role === userRoles.ADMIN ? "global-flashcards" : "flashcards";

  const isAdmin = userProfile?.role === userRoles.ADMIN;
  const isUser = userProfile?.role === userRoles.USER;
  const [
    quizzesHaveBeenFiltered,
    setQuizzesHaveBeenFiltered,
  ] = useState<boolean>(false);

  const [
    globalQuizzesHaveBeenFiltered,
    setGlobalQuizzesHaveBeenFiltered,
  ] = useState<boolean>(false);

  const createQuizContainerRef = useRef<null | HTMLDivElement>(null);

  // for top
  const executeScrollForStep2 = () =>
    createQuizContainerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });

  // scroll to step-2
  useEffect(() => {
    executeScrollForStep2();
  }, [userProfile?.tooltipsInfo?.quizzesToolTip === 2]);

  // Manage swapping between edit and display views
  useEffect(() => {
    const matchQuiz = quizzes.find((q) => q.id === selectedQuizId);
    if (matchQuiz) {
      setSelectedQuiz(matchQuiz);
    } else {
      setSelectedQuiz(null);
    }
  }, [quizzes, selectedQuizId]);

  async function updateWrongfullyPublishedQuiz(quiz: CustomQuizModel) {
    const draftQuiz = Object.assign({}, quiz);
    draftQuiz.published = false;
    const response = await APIService.CUSTOM_QUIZ.PUT(draftQuiz);
    return response as CustomQuizModel;
  }

  useEffect(() => {
    async function updateNewQuizzes() {
      if (quizzesHaveBeenFiltered && !isUser) {
        return;
      }
      if (
        quizzes &&
        !isEmpty(quizzes) &&
        mediaItemsMap &&
        !isEmpty(Object.values(mediaItemsMap)) &&
        !quizzesHaveBeenFiltered &&
        globalAndTeamMediaLoaded
      ) {
        setQuizzesHaveBeenFiltered(true);
        const newQuizzes = Promise.all(
          quizzes.map(async (quiz) => {
            const isQuizValid = validateQuizAndGetErrorMessage(
              quiz,
              Object.values(mediaItemsMap)
            );
            if (quiz.published && !isQuizValid.isValid) {
              const res = await updateWrongfullyPublishedQuiz(quiz);
              return res as CustomQuizModel;
            } else {
              return quiz;
            }
          })
        );

        const res = await newQuizzes;
        updateQuizzes(res);
      }
    }
    updateNewQuizzes();
  }, [quizzes, mediaItemsMap, globalAndTeamMediaLoaded]);

  useEffect(() => {
    async function updateNewGlobalQuizzes() {
      if (globalQuizzesHaveBeenFiltered && !isAdmin) {
        return;
      }
      if (
        globalQuizzes &&
        !isEmpty(globalQuizzes) &&
        mediaItemsMap &&
        !isEmpty(Object.values(mediaItemsMap)) &&
        !globalQuizzesHaveBeenFiltered &&
        globalAndTeamMediaLoaded
      ) {
        setGlobalQuizzesHaveBeenFiltered(true);
        const newQuizzes = Promise.all(
          globalQuizzes.map(async (quiz) => {
            const isQuizValid = validateQuizAndGetErrorMessage(
              quiz,
              Object.values(mediaItemsMap)
            );
            if (quiz.published && !isQuizValid.isValid) {
              const res = await updateWrongfullyPublishedQuiz(quiz);
              return res as CustomQuizModel;
            } else {
              return quiz;
            }
          })
        );

        const res = await newQuizzes;
        updateGlobalQuizzes(res);
      }
    }
    updateNewGlobalQuizzes();
  }, [globalQuizzes, mediaItemsMap, globalAndTeamMediaLoaded]);

  const saveQuiz = async (quizToUpdate: CustomQuizModel) => {
    try {
      await APIService.CUSTOM_QUIZ.PUT(quizToUpdate);
    } catch (e) {
      dispatchToast({
        type: "error",
        message: "An error occurred while updating the flashcard.",
      });
    }
  };

  const debouncedSaveQuiz = useRef(
    debounce((quizToUpdate: CustomQuizModel) => {
      saveQuiz(quizToUpdate);
    }, 2000)
  );

  const guideFlowComplete = async () => {
    if (!userProfile) {
      return;
    }
    // quizzesToolTip: 0 means all the nodes are visited no need to go forward

    const updatedUserProfile: UserProfileModel = {
      ...userProfile,
      tooltipsInfo: { ...userProfile.tooltipsInfo, quizzesToolTip: 0 },
    } as UserProfileModel;

    const updated = await APIService.USER_PROFILE.PUT(
      userProfile.id,
      updatedUserProfile
    );

    if (updated) {
      updateProfile(updated);
    }
  };

  const openDeleteQuizModal = (quiz: CustomQuizModel) => {
    if (!quiz.id) {
      return;
    }

    dispatchModal({
      title: "Delete Flashcard Game",
      open: true,
      body: (
        <ConfirmationModal
          actionName="delete"
          itemName=""
          actionCallback={async () => {
            try {
              await APIService.CUSTOM_QUIZ.DELETE(quiz.id as string);
              removeQuiz(quiz.id as string);
              history.push(`/${quizRouteName}`);
              dispatchToast({
                type: "success",
                message: "Flashcard deleted.",
              });
            } catch (e) {
              dispatchToast({
                type: "error",
                message: "Error deleting flashcard.",
              });
            }
          }}
        />
      ),
    });
  };

  const createNewQuiz = async () => {
    const newQuiz: CustomQuizModel = {
      teamId: currentTeam?.id,
      name: "",
      description: "",
      categoryTagId: undefined,
      showQuizToPositions: "",
      published: false,
      questions: [],
      autoGenerateQuestions: true,
      autoInclude: false,
    };
    try {
      const response = await APIService.CUSTOM_QUIZ.POST([newQuiz]);
      if (response && response[0]) {
        const createdQuiz = response[0];
        addQuiz(createdQuiz);
        history.push(`/${quizRouteName}/${createdQuiz.id}`);
      }
    } catch (error) {
      handleCreateError(
        error,
        ContentLimitModal,
        "flashcard game",
        "flashcard games"
      );
    }
  };

  const validateAndUpdateQuiz = (quiz: CustomQuizModel) => {
    setTimeout(() => {
      const nextQuiz = cloneDeep(quiz);

      // if quiz is already published, we may need to unpublish it
      // if edits to the quiz caused it to be no longer valid
      if (quiz.published) {
        const validityObject = validateQuizAndGetErrorMessage(
          nextQuiz,
          Object.values(mediaItemsMap)
        );

        if (!validityObject.isValid) {
          nextQuiz.published = false;
          dispatchToast({
            type: "error",
            message: validityObject.errorMessage,
          });
        }
      }

      updateQuiz(nextQuiz);
      debouncedSaveQuiz.current(nextQuiz);
    }, 50);
  };

  const importQuizModal = (quiz?: CustomQuizModel) => {
    dispatchModal({
      open: true,
      size: "large",
      body: (
        <QuizImportModal
          specificQuiz={quiz ? quiz : undefined}
          importQuiz={async (quiz) => {
            try {
              const response = await APIService.CUSTOM_QUIZ.POST([quiz]);
              if (response && response[0]) {
                const createdQuiz = response[0];
                addQuiz(createdQuiz);
                dispatchToast({
                  type: "success",
                  message: `${quiz.name} game added`,
                });
              }
            } catch (error) {
              handleCreateError(
                error,
                ContentLimitModal,
                "flashcard game",
                "flashcards games"
              );
            }
          }}
        />
      ),
      className: styles.quizImportModalSize,
    });
  };

  if (selectedQuiz) {
    return (
      <div className={styles.quizPage}>
        <div className={styles.quizPageEditQuizWrapper}>
          <EditQuiz
            quiz={selectedQuiz}
            updateQuiz={validateAndUpdateQuiz}
            openDeleteQuizModal={openDeleteQuizModal}
            userIsAdmin={isAdmin}
            categories={customQuizCategoryTags}
          />
        </div>
        <div className={styles.quizPageQuizPreviewContainer}>
          <div className={styles.quizPageQuizPreviewHeader}>
            Flashcard Preview
          </div>
          <PhonePreview containerClass={styles.quizPageQuizPreviewContainer}>
            <QuizPreview quiz={selectedQuiz} />
          </PhonePreview>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="pageHeader">
          <h3 className="pageTitle">Flashcard Games</h3>

          {selectedQuiz ? null : (
            <div
              className={styles.installsPageHeaderButtons}
              ref={createQuizContainerRef}
            >
              {!isAdmin &&
              !areQuizTooltipsTemporarilyDismissed &&
              userProfile?.tooltipsInfo?.quizzesToolTip === 2 ? (
                <Tooltip
                  tip={
                    <p>
                      You can redownload any of these flashcards by accessing
                      the Team Nation Library here.
                      <br />
                      <span className={`${styles.tooltipStep2Footer}`}>
                        <Button
                          size="x-small"
                          onClick={() => guideFlowComplete()}
                        >
                          Got it
                        </Button>
                      </span>
                    </p>
                  }
                  placement="top-start"
                  defaultOpen={true}
                  showPermanent={true}
                >
                  {currentTeam && (
                    <Button
                      theme="secondary"
                      size="small"
                      icon={<ListIcon />}
                      className={styles.libraryButton}
                      onClick={() => importQuizModal()}
                    >
                      Library
                    </Button>
                  )}
                </Tooltip>
              ) : (
                currentTeam && (
                  <Button
                    theme="secondary"
                    size="small"
                    icon={<ListIcon />}
                    className={styles.libraryButton}
                    onClick={() => importQuizModal()}
                  >
                    Library
                  </Button>
                )
              )}
              <Button theme="primary" size="small" onClick={createNewQuiz}>
                Create Game
              </Button>
            </div>
          )}
        </div>
        {/* {userProfile?.role !== "ADMIN" && globalQuizzes.length > 0 ? (
          <FeaturedQuizzes
            importQuizModal={importQuizModal}
            featured={globalQuizzes.filter((e) => !e.deleted)}
          />
        ) : null} */}

        <div className={styles.installsPageFilterBar}>
          <div className={styles.installsPageFilterBarCategoryCombo}>
            <MultiSelect
              options={customQuizCategoryTags.map((tag) => {
                return {
                  label: tag.name as string,
                  value: tag.id as string,
                };
              })}
              placeholder="All Categories"
              onChange={(e) => {
                setFilterCategories((e.currentTarget as any).selectedList);
              }}
            />
            <Button
              theme={"transparent"}
              icon={<GearIcon />}
              onClick={() => {
                dispatchModal({
                  body: <QuizCategoriesModal />,
                  size: "medium",
                  open: true,
                });
              }}
            />
          </div>
          <CharacterInput
            placeholder={"Search"}
            value={searchString}
            maxLength={255}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            icon={search}
          />
        </div>
        <div
          className={`
            tableHeader
            ${styles.installsPageListHeader}
            ${userProfile?.role === "ADMIN" ? "simplified-admin" : ""}
          `}
        >
          <span>Name</span>
          <span>Category</span>
          {userProfile?.role !== "ADMIN" ? <span>Avg Score</span> : null}
          {userProfile?.role !== "ADMIN" ? <span>Participation</span> : null}
          <span>Created</span>
          {userProfile?.role !== "ADMIN" ? <span>Due Date</span> : null}
          <span></span>
        </div>

        <ul className={`${styles.installsPageListActual}`}>
          {quizzes
            .filter((quiz) => {
              // Hide Deleted and ones that are not for the team
              if (
                quiz.deleted ||
                (userProfile?.role !== "ADMIN" && quiz.teamId === null) ||
                quiz.archived === true
              ) {
                return false;
              }

              // if a search term is present, and the quiz name does not match, then hide
              if (
                searchString !== "" &&
                quiz.name
                  .toLowerCase()
                  .search(
                    new RegExp(
                      searchString
                        .toLowerCase()
                        .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
                      "g"
                    )
                  ) < 0
              ) {
                return false;
              }

              // if category filter is present, and quiz category does not match, then hide
              if (
                filterCategories.length > 0 &&
                filterCategories.indexOf(quiz.categoryTagId as never) < 0
              ) {
                return false;
              }

              return true;
            })
            .map((quiz, index) => {
              return (
                <QuizListItem
                  key={`quiz-item-${quiz.id}`}
                  quiz={quiz}
                  stats={quizStats.filter(
                    (quizStat) => quizStat.customQuizId === quiz.id
                  )}
                  editAction={() => {
                    history.push(`/${quizRouteName}/${quiz.id}`);
                  }}
                  showGuidingFlow={
                    index === 0 &&
                    globalQuizzes.map((q) => q.name).includes(quiz.name)
                  }
                />
              );
            })}
        </ul>

        <div
          className={`tableHeader ${styles.installsPageArchiveHeader} ${
            userProfile?.role === "ADMIN" ? "simplified-admin" : ""
          }`}
        >
          <span>Archived Flashcard Games</span>
          {userProfile?.role !== "ADMIN" ? <span>Avg Score</span> : null}
          {userProfile?.role !== "ADMIN" ? <span>% Finished</span> : null}
          {userProfile?.role !== "ADMIN" ? <span>Finished</span> : null}
          {userProfile?.role !== "ADMIN" ? <span>Assigned</span> : null}
          <span>Created</span>
          {userProfile?.role === "ADMIN" ? <span>Last Mod.</span> : null}
          {userProfile?.role !== "ADMIN" ? <span>DueDate</span> : null}
          {userProfile?.role !== "ADMIN" ? <span>Positions</span> : null}
          <span></span>
        </div>
        <ul className={`${styles.installsPageArchiveActual}`}>
          {(() => {
            let foundQuiz = false;
            const archivedInstallRows = quizzes.map((quiz) => {
              if (quiz.archived) {
                foundQuiz = true;
                return (
                  <ArchivedQuiz
                    key={`archive-item-${quiz.id}`}
                    data={quiz}
                    stats={quizStats.filter(
                      (quizStat) => quizStat.customQuizId === quiz.id
                    )}
                  />
                );
              }
            });
            if (foundQuiz) {
              return archivedInstallRows;
            } else {
              return (
                <li className={`${styles.installsPageArchiveNoItems}`}>
                  Archived Flashcard Games will appear here.
                </li>
              );
            }
          })()}
        </ul>
      </>
    );
  }
};

export default Quizzes;
