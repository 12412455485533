import React, { useContext, useEffect, useState } from "react";
import styles from "./QuizzesQuestions.module.scss";
import CloseIcon from "../../resources/icons/CloseIcon";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import search from "../../resources/images/search.svg";
import CharacterInput from "../Input/CharacterInput";
import SingleSelect from "../SingleSelect/SingleSelect";
import { INPUT_TYPES } from "../../utils/web-only-constants";
import { QuizzesContext } from "../../shared/shared-with-mobile/providers/quizzes.provider";
import QuizCard from "./QuizCard/QuizCard";
import { CustomQuizModel } from "../../generated/from-api/models/custom-quiz.model";
import { VirtuosoGrid } from "react-virtuoso";
import QuestionCard from "./QuestionCard/QuestionCard";
import { CustomQuizQuestionModel } from "../../generated/from-api/models/custom-quiz/custom-quiz-question.model";
import SelectedQuiz from "../../pages/Quizzes/quiz-components/QuizImportModal/SelectedQuiz/SelectedQuiz";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { TagModel } from "../../generated/from-api/models/tag.model";
import { TagTypeEnum } from "../../generated/from-api/models/enums/tag-type.enum";
import trim from "lodash/trim";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { generateCategoryNameById } from "../../utils/functions";
import { MediaContext } from "../../shared/shared-with-mobile/providers/media.provider";
import Button from "../Button/Button";
import { CustomQuizUtility } from "../../generated/from-api/shared-utilities/custom-quiz.utility";

interface Props {
  activeTab?: "flashcard" | "question";
  isCurrentQuestionImagesAsAnswers?: boolean;
  currentQuestionObject?: CustomQuizQuestionModel;
  addQuizCallback?: (quizId: string) => void;
  addCustomQuestionCallback?: (
    question: CustomQuizQuestionModel,
    isImagesAsAnswers: boolean
  ) => void;
}

type SortOptionsType = "recent" | "oldest" | "a-z" | "z-a";
const sortOptions: { label: string; value: SortOptionsType }[] = [
  { label: "Most Recent", value: "recent" },
  { label: "Most Oldest", value: "oldest" },
  { label: "Title A-Z", value: "a-z" },
  { label: "Title Z-A", value: "z-a" },
];

const QuizzesQuestionsGallery: React.FC<Props> = ({
  activeTab = "flashcard",
  isCurrentQuestionImagesAsAnswers,
  addQuizCallback,
  addCustomQuestionCallback,
  currentQuestionObject,
}) => {
  const { quizzes } = useContext(QuizzesContext);
  const { closeModal } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);

  const [globalCategories, setGlobalCategories] = useState<any>({ All: "" });
  const { mediaItemsMap, mediaItems } = useContext(MediaContext);

  const allQuizzes =
    quizzes && mediaItems
      ? quizzes.filter(
          (quiz) => CustomQuizUtility.isQuizValid(quiz, mediaItems).valid
        )
      : [];

  const [filteredQuizzes, setFilteredQuizzes] = useState<
    CustomQuizModel[] | undefined
  >(allQuizzes);

  const [currentSort, setCurrentSort] = useState<SortOptionsType>("recent");
  const [isQuizzesTabActive, setQuizzesTabStatus] = useState<boolean>(
    activeTab === "flashcard"
  );
  const [isImagesAsAnswers, setImagesAsAnswers] = useState<boolean>(
    !!isCurrentQuestionImagesAsAnswers
  );

  const [
    customObjectQuestion,
    setCustomObjectQuestion,
  ] = useState<CustomQuizQuestionModel | null>(null);

  const [
    isCustomQuestionValid,
    setCustomQuestionValidation,
  ] = useState<boolean>(
    customObjectQuestion ? !!customObjectQuestion.question : false
  );

  const [isQuizPreview, setQuizPreview] = useState<boolean>(false);
  const [quizPreviewId, setQuizPreviewId] = useState<string | undefined>("");

  useEffect(() => {
    sortQuizzes();
  }, [filteredQuizzes, currentSort]);

  useEffect(() => {
    async function fetchData() {
      try {
        const categories = (
          await APIService.TAG.GET_TAGS_FOR_TEAM(null)
        ).filter(
          (tag: TagModel) => tag.type === TagTypeEnum.CUSTOM_QUIZ_CATEGORY
        );

        const obj: any = {};

        categories.forEach((category) => {
          obj[trim(category.name as string)] = category.id as string;
        });

        setGlobalCategories({ ...globalCategories, ...obj });
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, [currentTeam]);

  const handleSearchFilter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!allQuizzes) return;

    let filteredQuizzesBySearch = [...allQuizzes];
    const searchValue = event.currentTarget.value;

    filteredQuizzesBySearch = filteredQuizzesBySearch.filter(
      (quiz: CustomQuizModel) =>
        quiz.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    );

    setFilteredQuizzes(filteredQuizzesBySearch);
  };

  const handleQuizzesSort = (event: any) => {
    setCurrentSort(event.currentTarget.value);
  };

  const sortQuizzes = () => {
    if (!filteredQuizzes) return;

    switch (currentSort) {
      case "recent":
        filteredQuizzes.sort(
          (quizA: CustomQuizModel, quizB: CustomQuizModel): number => {
            return quizA.created && quizB.created
              ? Date.parse(quizB.created) - Date.parse(quizA.created)
              : 0;
          }
        );
        break;
      case "oldest":
        filteredQuizzes.sort(
          (quizA: CustomQuizModel, quizB: CustomQuizModel): number => {
            return quizA.created && quizB.created
              ? Date.parse(quizA.created) - Date.parse(quizB.created)
              : 0;
          }
        );
        break;
      case "a-z":
        filteredQuizzes.sort(
          (quizA: CustomQuizModel, quizB: CustomQuizModel): number => {
            return quizA.name
              .toLowerCase()
              .localeCompare(quizB.name.toLowerCase());
          }
        );
        break;
      case "z-a":
        filteredQuizzes.sort(
          (quizA: CustomQuizModel, quizB: CustomQuizModel): number => {
            return quizB.name
              .toLowerCase()
              .localeCompare(quizA.name.toLowerCase());
          }
        );
        break;
    }

    setFilteredQuizzes(filteredQuizzes);
  };

  const previewQuizCallback = (quizId: string) => {
    setQuizPreviewId(quizId);
    setQuizPreview(true);
  };

  return (
    <div className={styles.qqGallery}>
      <div className={styles.qqGalleryHeader}>
        <div className={styles.qqGalleryTitleWrapper}>
          <h4 className={styles.qqGalleryTitle}>
            {isQuizzesTabActive ? "Add Flashcards" : "Add Custom Question"}
          </h4>
        </div>
        <div className={styles.qqGalleryCloseButtonWrapper}>
          <button
            type={"button"}
            className={styles.qqGalleryCloseButton}
            onClick={() => closeModal()}
          >
            <CloseIcon />
          </button>
        </div>
      </div>

      <div className={styles.qqGalleryBody}>
        {!isQuizPreview && (
          <div className={styles.qqGalleryTabs}>
            <div className={styles.qqGalleryTab}>
              <button
                className={`${styles.qqGalleryTabButton} ${
                  isQuizzesTabActive ? styles.qqGalleryTabButtonActive : ""
                }`}
                type={"button"}
                onClick={() => setQuizzesTabStatus(true)}
              >
                My Flashcards
              </button>
            </div>
            <div className={styles.qqGalleryTab}>
              <button
                className={`${styles.qqGalleryTabButton} ${
                  !isQuizzesTabActive ? styles.qqGalleryTabButtonActive : ""
                }`}
                type={"button"}
                onClick={() => setQuizzesTabStatus(false)}
              >
                Custom Questions
              </button>
            </div>
          </div>
        )}

        {isQuizzesTabActive && !isQuizPreview && (
          <>
            <div className={styles.qqGalleryFilters}>
              <div className={styles.qqGallerySearchWrapper}>
                <CharacterInput
                  type={INPUT_TYPES.TEXT}
                  placeholder="Search"
                  icon={search}
                  onChange={handleSearchFilter}
                  clearButton
                  autofocus
                />
              </div>
              <div className={styles.qqGallerySortWrapper}>
                <SingleSelect
                  options={sortOptions}
                  value={currentSort}
                  placeholder="Sort by: Most Recent"
                  onChange={handleQuizzesSort}
                />
              </div>
            </div>
            <div className={styles.qqGalleryQuizzesWrapper}>
              {filteredQuizzes && filteredQuizzes.length > 0 && (
                <VirtuosoGrid
                  totalCount={filteredQuizzes.length}
                  itemClassName={styles.qqGalleryQuizzes}
                  itemContent={(index: number) => {
                    const quiz = filteredQuizzes[index];
                    return (
                      <div
                        key={quiz.id}
                        className={`${styles.qqGalleryQuizCardWrapper} ${
                          filteredQuizzes.length > 8
                            ? styles.qqGalleryQuizCardWrapperSpacing
                            : ""
                        }`}
                      >
                        <QuizCard
                          quiz={quiz}
                          previewQuizCallback={previewQuizCallback}
                          addQuizCallback={addQuizCallback}
                        />
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </>
        )}

        {isQuizPreview && (
          <SelectedQuiz
            settings={{
              isInstallQuizPreview: true,
              headerTitle: "Back",
            }}
            quiz={quizzes.filter((quiz) => quiz.id === quizPreviewId)[0]}
            setIsQuizSelected={setQuizPreview}
            generateCategoryNameById={generateCategoryNameById}
            globalCategories={globalCategories}
            globalMediaMap={Object.values(mediaItemsMap)}
            addQuizCallback={addQuizCallback}
          />
        )}
      </div>

      {!isQuizzesTabActive && !isQuizPreview && (
        <>
          <div className={styles.qqGalleryQuestionCard}>
            <QuestionCard
              currentQuestionObject={currentQuestionObject}
              isCurrentQuestionImagesAsAnswers={isImagesAsAnswers}
              onChangeCallback={(
                questionObject: CustomQuizQuestionModel,
                isImagesAsAnswers
              ) => {
                setCustomQuestionValidation(!!questionObject.question);
                setImagesAsAnswers(isImagesAsAnswers);
                setCustomObjectQuestion(questionObject);
              }}
            />
          </div>

          <div className={styles.qqGalleryFooter}>
            <div className={styles.qqGalleryAddPlayButtonWrapper}>
              <Button
                size={"small"}
                onClick={() =>
                  addCustomQuestionCallback &&
                  customObjectQuestion &&
                  addCustomQuestionCallback(
                    customObjectQuestion,
                    isImagesAsAnswers
                  )
                }
                disabled={!isCustomQuestionValid}
              >
                {currentQuestionObject ? "Update Question" : "Add Question"}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuizzesQuestionsGallery;
