import React, { useState, useEffect } from "react";
import { CustomQuizModel } from "../../../../../generated/from-api/models/custom-quiz.model";
import { CustomQuizQuestionModel } from "../../../../../generated/from-api/models/custom-quiz/custom-quiz-question.model";
import styles from "./SelectedQuiz.module.scss";
import backArrow from "../../../../../resources/images/arrowLeft.svg";
import trim from "lodash/trim";
import MediaThumbnail from "../../../../../components/media/MediaThumbnail/MediaThumbnail";
import isEmpty from "lodash/isEmpty";
import Button from "../../../../../components/Button/Button";

interface Props {
  quiz: CustomQuizModel;
  setIsQuizSelected: React.Dispatch<React.SetStateAction<boolean>>;
  generateCategoryNameById: (id: string, categories: any) => any;
  globalCategories: any;
  globalMediaMap: any;
  addGlobalQuiz?: (q: CustomQuizModel) => void;
  disabledQuizzes?: any;
  setDisabledQuizzes?: React.Dispatch<React.SetStateAction<any>>;
  addQuizCallback?: (quizId: string) => void;
  settings?: {
    isInstallQuizPreview?: boolean;
    headerTitle?: string;
  };
}

const SelectedQuiz = ({
  quiz,
  setIsQuizSelected,
  generateCategoryNameById,
  globalCategories,
  globalMediaMap,
  addGlobalQuiz,
  disabledQuizzes,
  setDisabledQuizzes,
  addQuizCallback,
  settings,
}: Props): JSX.Element => {
  const [scrollbarWidth, setScrollbarWidth] = useState<number>();
  const isInstallQuizPreview = settings && settings.isInstallQuizPreview;

  useEffect(() => {
    const node = document.querySelector(".selected-quiz-list") as HTMLElement;
    if (node instanceof HTMLElement) {
      setScrollbarWidth(node.scrollWidth);
    }
  }, []);

  const computeLargestAnswersArray = (questions: CustomQuizQuestionModel[]) => {
    return Math.max(
      ...questions.map(
        (question: CustomQuizQuestionModel) => question.correctOptions.length
      )
    );
  };

  const maxNumOptions = computeLargestAnswersArray(quiz.questions);
  const MIN_TILES = 3; // show at least 3 empty boxes per row for purely aesthetic reasons
  const MIN_NUM_COLUMNS = MIN_TILES + 1; // 1 column for each tile, plus 1 column for question.question
  const renderRemainingEmptyTiles = (optionsLength: number) => {
    const minTilesToRender = Math.max(MIN_TILES, maxNumOptions);
    const numEmptyTilesToRender = minTilesToRender - optionsLength;

    const emptyTiles = [];
    for (let i = 0; i < numEmptyTilesToRender; i++) {
      emptyTiles.push(
        <div key={`empty box ${i}`} className={styles.emptyBox} />
      );
    }
    return emptyTiles;
  };

  return (
    <div className={styles.SelectedQuiz}>
      <div
        className={`${styles.header} ${
          isInstallQuizPreview ? styles.headerQuizPreview : ""
        }`}
      >
        <span
          className={styles.goBackArea}
          onClick={() => {
            setIsQuizSelected(false);
          }}
        >
          <img src={backArrow} alt="back arrow icon" role="button" />
          <span>
            {settings && settings.headerTitle ? settings.headerTitle : "Quiz"}
          </span>
        </span>
      </div>

      <div
        className={`${styles.quizModalContent} ${
          isInstallQuizPreview ? styles.quizModalContentPreview : ""
        }`}
      >
        <div className={styles.scrollableSection}>
          <div
            className={`${styles.headDescriptionWrapper} ${
              isInstallQuizPreview
                ? styles.headDescriptionWrapperQuizPreview
                : ""
            }`}
          >
            <div
              className={`${styles.headDescription} ${
                isInstallQuizPreview ? styles.headDescriptionQuizPreview : ""
              }`}
            >
              <p className="pageTitle" style={{ fontSize: "28px" }}>
                {trim(quiz?.name)}
              </p>
              <p className={styles.description}>{trim(quiz?.description)}</p>
              {globalCategories && (
                <div className={styles.tagContainer}>
                  <span className={styles.tag}>
                    {generateCategoryNameById(
                      quiz?.categoryTagId
                        ? quiz.categoryTagId
                        : "Uncategorized",
                      globalCategories
                    )}
                  </span>
                </div>
              )}
            </div>
            {addQuizCallback && (
              <Button
                size={"small"}
                onClick={() => quiz.id && addQuizCallback(quiz.id)}
              >
                Add Quiz
              </Button>
            )}
          </div>

          <div
            className={`${styles.selectedQuizWrapper} ${
              isInstallQuizPreview ? styles.selectedQuizWrapperPreview : ""
            }`}
            style={{ clear: "both" }}
          >
            <ol className={`${styles.selectedQuizList} selected-quiz-list`}>
              {quiz &&
                quiz.questions &&
                quiz.questions.map((question) => {
                  return (
                    <li
                      key={question.id}
                      className={styles.listItem}
                      style={{
                        gridTemplateColumns: `4rem 6rem repeat(${Math.max(
                          MIN_NUM_COLUMNS,
                          maxNumOptions + 1
                        )}, 1fr)`,
                        width: scrollbarWidth,
                      }}
                    >
                      <span className={styles.questionName}>
                        {question.question}
                      </span>

                      {question.correctOptions &&
                        !isEmpty(question.correctOptions) &&
                        question.correctOptions.map(
                          (answer: any, answerIndex) => (
                            <div
                              key={answerIndex}
                              className={styles.answerBox}
                              style={{ background: answer.mediaId && "none" }}
                            >
                              {answer.optionType === "TEXT" && (
                                <span>{answer.text}</span>
                              )}
                              {answer.optionType !== "TEXT" &&
                                answer.mediaId && (
                                  <div className={styles.mediaBox}>
                                    {globalMediaMap && (
                                      <MediaThumbnail
                                        mediaItem={globalMediaMap.find(
                                          (item: any) =>
                                            item.id === answer.mediaId
                                        )}
                                      />
                                    )}
                                  </div>
                                )}
                            </div>
                          )
                        )}
                      {!isEmpty(question.correctOptions) &&
                        renderRemainingEmptyTiles(
                          question.correctOptions.length
                        )}
                    </li>
                  );
                })}
            </ol>
          </div>
        </div>

        {disabledQuizzes && setDisabledQuizzes && addGlobalQuiz && (
          <div className={styles.selectedQuizTabFooter}>
            <span>
              <Button
                theme="primary"
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  if (quiz) {
                    addGlobalQuiz(quiz);
                    if (!disabledQuizzes.hasOwnProperty(quiz.name)) {
                      setDisabledQuizzes({
                        ...disabledQuizzes,
                        [quiz.name]: true,
                      });
                    }
                  }
                }}
                disabled={quiz && disabledQuizzes.hasOwnProperty(quiz.name)}
              >
                {quiz && disabledQuizzes.hasOwnProperty(quiz.name)
                  ? "Copied"
                  : "Add Flashcard"}
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedQuiz;
