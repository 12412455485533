import React, { useContext, useState } from "react";
import styles from "./TeamCoaches.module.scss";
import CharacterInput from "../../Input/CharacterInput";
import { coachPositions, INPUT_TYPES } from "../../../utils/web-only-constants";
import Button from "../../Button/Button";
import SingleSelect from "../../SingleSelect/SingleSelect";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { InvitationsContext } from "../../../shared/shared-with-mobile/providers/invitations.provider";
import CloseIcon from "../../../resources/icons/CloseIcon";
import { CoachRole } from "../../../generated/from-api/models/enums/coach-role.enum";

interface Props {
  teamId?: string;
}

interface Coach {
  firstName: string;
  lastName: string;
  email: string;
  role: CoachRole | undefined;
}

const emptyCoach: Coach = {
  firstName: "",
  lastName: "",
  email: "",
  role: undefined,
};

const TeamCoaches: React.FC<Props> = ({ teamId }) => {
  const { dispatchToast } = useContext(UIContext);
  const {
    currentCoachInvitations,
    addOrUpdateInvitation,
    removeInvitation,
  } = useContext(InvitationsContext);

  const [coach, setCoach] = useState<Coach>(emptyCoach);

  const inviteCoach = async () => {
    try {
      const newCoachInvite = await APIService.INVITATION.POST({
        type: "COACH",
        teamId: teamId ? teamId : "",
        toEmail: coach.email,
        firstName: coach.firstName,
        lastName: coach.lastName,
        coachRole: coach.role,
      });

      if (newCoachInvite) {
        addOrUpdateInvitation(newCoachInvite);
        setCoach(emptyCoach);
      }

      dispatchToast({
        type: newCoachInvite ? "success" : "error",
        message: newCoachInvite
          ? `Invitation to ${newCoachInvite.firstName} ${newCoachInvite.lastName} was created.`
          : "Invitation Failed. Please try again.",
      });
    } catch (error) {
      dispatchToast({
        type: "error",
        message: "Invitation Failed. Please try again.",
      });
    }
  };

  const deleteInvitation = async (invitationId: string | undefined) => {
    if (invitationId) {
      try {
        await APIService.INVITATION.DELETE(invitationId);
        removeInvitation(invitationId);
        dispatchToast({
          type: "success",
          message: "Invitation has been deleted.",
        });
      } catch (error) {
        dispatchToast({
          type: "error",
          message: "Error deleting invitation.",
        });
      }
    } else {
      dispatchToast({
        type: "error",
        message: "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <div className={styles.teamCoaches}>
      <div className={styles.teamCoaches__Row}>
        <CharacterInput
          type={INPUT_TYPES.TEXT}
          placeholder={"First Name"}
          value={coach.firstName}
          onChange={(e) =>
            setCoach((prevState: Coach) => ({
              ...prevState,
              firstName: e.target.value,
            }))
          }
          autofocus
        />
        <CharacterInput
          type={INPUT_TYPES.TEXT}
          placeholder={"Last Name"}
          value={coach.lastName}
          onChange={(e) =>
            setCoach((prevState: Coach) => ({
              ...prevState,
              lastName: e.target.value,
            }))
          }
        />
        <CharacterInput
          type={INPUT_TYPES.EMAIL}
          placeholder={"Email"}
          value={coach.email}
          onChange={(e) =>
            setCoach((prevState: Coach) => ({
              ...prevState,
              email: e.target.value,
            }))
          }
        />
        <SingleSelect
          placeholder="Role"
          options={[...coachPositions].map((roleOption) => {
            return {
              label: roleOption === "DEFAULT" ? "ASSISTANT" : roleOption,
              value: roleOption,
            };
          })}
          value={coach.role ? coach.role : ""}
          onChange={(e) =>
            setCoach((prevState: any) => ({
              ...prevState,
              role: e.currentTarget.value,
            }))
          }
        />
        <div>
          <Button
            onClick={inviteCoach}
            disabled={
              !(coach.firstName && coach.lastName && coach.email && coach.role)
            }
          >
            Add
          </Button>
        </div>
      </div>
      {currentCoachInvitations &&
        Array.from(currentCoachInvitations.entries())
          .filter(([key, invitation]) => key && invitation.teamId === teamId)
          .reverse()
          .map((invitation) => {
            const [key, value] = invitation;
            return (
              <div key={key} className={styles.teamCoaches__Row}>
                <CharacterInput
                  type={INPUT_TYPES.TEXT}
                  value={value.firstName}
                  readonly
                />
                <CharacterInput
                  type={INPUT_TYPES.TEXT}
                  value={value.lastName}
                  readonly
                />
                <CharacterInput
                  type={INPUT_TYPES.EMAIL}
                  value={value.toEmail}
                  readonly
                />
                <CharacterInput
                  type={INPUT_TYPES.TEXT}
                  value={
                    value.coachRole === "DEFAULT"
                      ? "ASSISTANT"
                      : value.coachRole
                  }
                  readonly
                />
                <div>
                  <Button
                    theme={"transparent"}
                    icon={<CloseIcon />}
                    onClick={() => deleteInvitation(value.id)}
                  />
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default TeamCoaches;
