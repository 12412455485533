import React, { useContext, useEffect, useState } from "react";
import { VirtuosoGrid } from "react-virtuoso";
import isEmpty from "lodash/isEmpty";

import styles from "../Playbook.module.scss";
import PlayThumbnail from "../../../components/drawing/PlayThumbnail";
import RenamePlays from "./RenamePlays";
import PlaybookSidebar from "../PlaybookSidebar/PlaybookSidebar";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { PlayModel } from "../../../generated/from-api/models/play.model";
import { PlaybookContext } from "../../../shared/shared-with-mobile/providers/playbook.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import { TeamContext } from "../../../shared/shared-with-mobile/providers/team.provider";
import { PlaybookFiltersModel } from "../Playbook";
import { filterPlaybook, mapTagToOption } from "../../../utils/functions";
import { FormationsContext } from "../../../shared/shared-with-mobile/providers/formations.provider";
import MultiSelect, {
  MultiSelectOption,
} from "../../../components/MultiSelect/MultiSelect";
import { FormationOption } from "../../../shared/shared-with-mobile/play-editor/drawing.types";
import { TagModel } from "../../../generated/from-api/models/tag.model";
import { TagTypeEnum } from "../../../generated/from-api/models/enums/tag-type.enum";
import ContentLimitModal from "../../../components/ContentLimitModal/ContentLimitModal";
import Button from "../../../components/Button/Button";
import PlaybookTags from "../PlaybookTags";

const initialFilters: PlaybookFiltersModel = {
  category: null,
  scheme: null,
  searchText: "",
  formationIds: [],
  defensiveSetIds: [],
  situationNames: [],
  personnelPackageNames: [],
  gamePlanId: null,
};

const ImportPlays: React.FC = () => {
  const { dispatchSingleModal } = useContext(UIContext);
  const { addPlays } = useContext(PlaybookContext);
  const { closeModal, handleCreateError } = useContext(UIContext);
  const { currentTeam } = useContext(TeamContext);
  const { globalFormations } = useContext(FormationsContext);
  const [globalPlays, setGlobalPlays] = useState<PlayModel[]>([]);
  const [selectedPlays, setSelectedPlays] = useState<Map<string, PlayModel>>(
    new Map()
  );
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [filteredPlaybook, setFilteredPlaybook] = useState<PlayModel[]>(
    globalPlays || []
  );
  const [playbookFilters, setPlaybookFilters] = useState<PlaybookFiltersModel>({
    ...initialFilters,
  });
  const [formationOptions, setFormationOptions] = useState<FormationOption[]>(
    []
  );
  const [defensiveSetOptions, setDefensiveSetOptions] = useState<
    FormationOption[]
  >([]);
  const [globalSituationTags, setGlobalSituationTags] = useState<TagModel[]>(
    []
  );
  const [globalPersonnelPackageTags, setGlobalPersonnelPackageTags] = useState<
    TagModel[]
  >([]);

  useEffect(() => {
    let isCancelled = false;
    const loadGlobalPlaybook = async () => {
      const globalPlays = await APIService.PLAY.LIST();

      if (!isCancelled) {
        setGlobalPlays(globalPlays);
      }
    };

    const loadGlobalTags = async () => {
      const fetchedTags = await APIService.TAG.GET_TAGS_FOR_TEAM(null);
      if (fetchedTags && !isCancelled) {
        const fetchedSituationTags = fetchedTags.filter(
          (tag: TagModel) => tag.type === TagTypeEnum.SITUATION
        );

        const fetchedPersonnelPackageTags = fetchedTags.filter(
          (tag: TagModel) => tag.type === TagTypeEnum.PERSONNEL_PACKAGE
        );
        setGlobalSituationTags(fetchedSituationTags);
        setGlobalPersonnelPackageTags(fetchedPersonnelPackageTags);
      }
    };

    loadGlobalPlaybook();
    loadGlobalTags();

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (globalPlays) {
      setFilteredPlaybook(globalPlays);
    }
  }, [globalPlays]);

  useEffect(() => {
    const nextFormationOptions = globalFormations
      .filter((formation) => formation.type === "Offensive")
      .map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));
    const nextDefensiveSetOptions = globalFormations
      .filter((formation) => formation.type === "Defensive")
      .map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));
    setFormationOptions(nextFormationOptions);
    setDefensiveSetOptions(nextDefensiveSetOptions);
  }, [globalFormations]);

  useEffect(() => {
    const nextFilteredPlaybook = filterPlaybook(
      playbookFilters,
      globalPlays || [],
      // team plays may use global formations, or current formations & should be able to filter by both
      globalFormations
    );

    setFilteredPlaybook(nextFilteredPlaybook);
  }, [playbookFilters]);

  const importPlays = async () => {
    if (!currentTeam) {
      return;
    }

    const selectedPlaysCopy = selectedPlays
      ? new Map(selectedPlays)
      : new Map<string, PlayModel>();

    selectedPlaysCopy.forEach((play) => {
      play.teamId = currentTeam.id;
      return play;
    });

    const playsArray = Array.from(selectedPlaysCopy.values());

    try {
      const createdPlays = await APIService.PLAY.POST(playsArray);
      if (createdPlays && createdPlays.length > 0) {
        addPlays(createdPlays);
        closeModal();
      }
    } catch (error: any) {
      if (error?.response?.status === 422) {
        handleCreateError(error, ContentLimitModal, "play", "plays");
      } else if (error?.response?.data?.length) {
        const duplicates: string[] = error.response.data;
        dispatchSingleModal({
          title: "We found duplicates",
          open: true,
          size: "medium",
          body: (
            <RenamePlays
              plays={selectedPlaysCopy}
              duplicateNames={duplicates}
            />
          ),
        });
      }
    }
  };

  const handleSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    play: PlayModel
  ) => {
    if (!play.id) {
      return;
    }

    const selectedPlaysCopy = selectedPlays
      ? new Map(selectedPlays)
      : new Map<string, PlayModel>();

    if (e.currentTarget.checked) {
      selectedPlaysCopy.set(play.id, play);
    } else {
      selectedPlaysCopy.delete(play.id);
    }
    setSelectedPlays(selectedPlaysCopy);
  };

  return (
    <div className={styles.importPlays}>
      <div className={styles.modalSidebar}>
        <PlaybookSidebar
          playbookFilters={playbookFilters}
          setPlaybookFilters={setPlaybookFilters}
          playbook={globalPlays}
          hideSettingsLink={true}
          showGlobalTags
        />
      </div>
      <div style={{ overflow: "hidden" }}>
        <div className={styles.filterBar}>
          <MultiSelect
            placeholder="OFFENSIVE FORMATION"
            options={formationOptions}
            onChange={(value) => {
              setPlaybookFilters({
                ...playbookFilters,
                formationIds: (value.currentTarget as any).selectedList,
              });
            }}
            filter
          />
          <MultiSelect
            placeholder="PERSONNEL"
            options={
              globalPersonnelPackageTags.map(
                mapTagToOption
              ) as MultiSelectOption[]
            }
            onChange={(value) => {
              setPlaybookFilters({
                ...playbookFilters,
                personnelPackageNames: (value.currentTarget as any)
                  .selectedList,
              });
            }}
            filter
          />
          <MultiSelect
            placeholder="SITUATION"
            options={
              globalSituationTags.map(mapTagToOption) as MultiSelectOption[]
            }
            onChange={(value) => {
              setPlaybookFilters({
                ...playbookFilters,
                situationNames: (value.currentTarget as any).selectedList,
              });
            }}
            filter
          />
          <MultiSelect
            placeholder="DEFENSIVE FRONT"
            options={defensiveSetOptions}
            onChange={(value) => {
              setPlaybookFilters({
                ...playbookFilters,
                defensiveSetIds: (value.currentTarget as any).selectedList,
              });
            }}
            filter
          />
        </div>
        <div className={styles.modalPlaysList}>
          {globalPlays && globalPlays.length ? (
            filteredPlaybook.length ? (
              <VirtuosoGrid
                totalCount={filteredPlaybook.length}
                isScrolling={(e) => setIsScrolling(e)}
                itemContent={(index: number) => {
                  const play = filteredPlaybook[index];
                  return (
                    <div key={play.id} className={styles.playCard}>
                      <PlayThumbnail
                        play={play}
                        useCheckbox
                        useLoader={isScrolling}
                        playSelected={
                          (play.id && selectedPlays.has(play.id)) || false
                        }
                        onChange={(e) => handleSelection(e, play)}
                        showCheckbox={!isEmpty(selectedPlays)}
                      />
                      <div className={styles.playTitle}>{play.name}</div>
                      <PlaybookTags play={play} noLink />
                    </div>
                  );
                }}
              />
            ) : (
              <div className={styles.grid}>
                <div>0 plays matching applied filters.</div>
              </div>
            )
          ) : (
            <div className={styles.grid}>
              <div className={`${styles.playCard} ${styles.loader}`}></div>
              <div className={`${styles.playCard} ${styles.loader}`}></div>
              <div className={`${styles.playCard} ${styles.loader}`}></div>
              <div className={`${styles.playCard} ${styles.loader}`}></div>
              <div className={`${styles.playCard} ${styles.loader}`}></div>
              <div className={`${styles.playCard} ${styles.loader}`}></div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.importPlaysButtonWrapper}>
        <Button
          theme="secondary"
          size={"small"}
          onClick={importPlays}
          disabled={selectedPlays.size === 0}
          className={styles.addButton}
        >
          Add to Playbook
        </Button>
      </div>
    </div>
  );
};

export default ImportPlays;
