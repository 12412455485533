import styles from "./Dashboard.module.scss";
import DashboardHome from "./DashboardHome/DashboardHome";
import DashboardOverall from "./DashboardOverall/DashboardOverall";
import React from "react";
import DashboardSidebar from "./DashboardSidebar/DashboardSidebar";
import { RouteComponentProps, match } from "react-router-dom";
import DashboardPlaybook from "./Playbook/DashboardPlaybook";
import DashboardLessons from "./Lessons/DashboardLessons";
import DashboardQuizzes from "./Quizzes/DashboardQuizzes";

interface Props extends RouteComponentProps {
  match: match<MatchParams>;
}
interface MatchParams {
  module: string;
}

const Dashboard: React.FC<Props> = ({ match }) => {
  const module = match.params.module;

  return (
    <div className={styles.dashboard}>
      <section className={styles.dashboardContainer}>
        <DashboardSidebar />
        <div className={styles.dashboardContent}>
          {!module && <DashboardHome />}
          {module === "overall" && <DashboardOverall />}
          {module === "playbook" && <DashboardPlaybook />}
          {module === "lessons" && <DashboardLessons />}
          {module === "flashcards" && <DashboardQuizzes />}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
