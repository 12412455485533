import { createContext, useState } from "react";

import { CoachModel } from "../../../generated/from-api/models/relationships/coach.model";

export const CoachesContext = createContext<{
  currentCoaches: undefined | null | Map<string, CoachModel>;
  replaceCoaches: (newCoaches: CoachModel[]) => void;
  addOrUpdateCoach: (coach: CoachModel) => void;
  removeCoach: (coachId: string) => void;
  clearCoaches: () => void;
}>({
  currentCoaches: undefined,
  replaceCoaches: () => null,
  addOrUpdateCoach: () => null,
  removeCoach: () => null,
  clearCoaches: () => null,
});

export const CoachesProvider: React.FC = ({ children }) => {
  const [currentCoaches, setCurrentCoaches] = useState<Map<
    string,
    CoachModel
  > | null>(null);

  const replaceCoaches = (newCoaches: CoachModel[]) => {
    const newCoachesMap = new Map<string, CoachModel>();

    newCoaches.forEach((coach) => {
      newCoachesMap.set(coach.id as string, coach);
    });

    setCurrentCoaches(newCoachesMap);
  };

  const addOrUpdateCoach = (coach: CoachModel) => {
    const currentCoachesCopy = currentCoaches
      ? new Map(currentCoaches)
      : new Map<string, CoachModel>();

    currentCoachesCopy.set(coach.id as string, coach);

    setCurrentCoaches(currentCoachesCopy);
  };

  const removeCoach = (coachId: string) => {
    const currentCoachesCopy = currentCoaches
      ? new Map(currentCoaches)
      : new Map<string, CoachModel>();

    currentCoachesCopy.delete(coachId);

    setCurrentCoaches(currentCoachesCopy);
  };

  const clearCoaches = () => {
    setCurrentCoaches(null);
  };

  return (
    <CoachesContext.Provider
      value={{
        currentCoaches,
        replaceCoaches,
        addOrUpdateCoach,
        removeCoach,
        clearCoaches,
      }}
    >
      {children}
    </CoachesContext.Provider>
  );
};
