import { Line, Group, Text, Rect } from "react-konva";

import { colors } from "./play-editor-colors";
import { convertRelativeLineToAbsolute } from "./playEditor.utils";

interface Props {
  stageHeight: number;
  stageWidth: number;
  ballOn: number;
  printableView?: boolean;
}

// draw 130 yards total (most will be offscreen)
// 130 = 100 + 20 + 10
// 100 = non-endzone field
// 20 = 2 * 10 (each endzone is 10 yards long)
// 10 = 2 * 5 (a 5 yard long empty space past each endzone)
// The empty space helps the line of scrimmage stay centered in the viewport
// even when placed at the 0th or the 100th yard of the non-endzone field
const fieldHeightYards = 130;

// vertical viewport of play editor will represent 30 yards
// a few bugs will still occur if we change this number
const viewportYards = 30;

const sideLines = [
  [0, 0, 0, fieldHeightYards / viewportYards],
  [1, 0, 1, fieldHeightYards / viewportYards],
];

const generateNYardIncrementLines = (nYards: number, numSkipped: number) => {
  const numLines = fieldHeightYards / nYards;
  const multiplier = nYards / viewportYards;

  const lines = [];
  const lineX1 = 0;
  const lineX2 = 1;

  // numSkipped is how many lines to NOT draw at the beginning and end
  // e.g. we will want to skip 5 when drawing one yard lines so we don't draw on the empty spaces next to each endzone
  for (
    let lineNum = numSkipped;
    lineNum <= numLines - numSkipped;
    lineNum += 1
  ) {
    const lineY = lineNum * multiplier;
    const line = [lineX1, lineY, lineX2, lineY];
    lines.push(line);
  }

  return lines;
};

const generateDashArray = (dimension: number) => {
  // use dimension = width to generate dashes for a horizontal line
  // use dimension = height to generate dashes for a vertical line
  const relativeDashSize = 0.017;
  const numDashes = 4;
  const numGaps = numDashes - 1;
  const relativeGapSize = (1 - numDashes * relativeDashSize) / numGaps;
  const absoluteDashSize = relativeDashSize * dimension;
  const absoluteGapSize = relativeGapSize * dimension;
  return [absoluteDashSize, absoluteGapSize];
};

const generateEndzoneRectangles = () => {
  const rects = [
    {
      x: 0,
      y: 5 / viewportYards,
      width: 1,
      height: 10 / viewportYards,
    },
    {
      x: 0,
      y: (fieldHeightYards - 15) / viewportYards,
      width: 1,
      height: 10 / viewportYards,
    },
  ];

  return rects;
};

const FieldBackground: React.FC<Props> = ({
  stageHeight,
  stageWidth,
  ballOn,
  printableView = false,
}) => {
  const oneYardIncrementLines = generateNYardIncrementLines(1, 5);
  const fiveYardIncrementLines = generateNYardIncrementLines(5, 0);
  const endzones = generateEndzoneRectangles();
  const textBoundingBoxSize = 150;
  const fontSize = stageWidth / 19;
  const lineStrokeWidth = stageWidth < 400 ? 1 : 1.5;
  const endZoneLetterSpacing = stageWidth / 40; // ~30

  const getYardLineNumber = (index: number) => {
    let yardNumber = (index + 1) * 10;
    if (yardNumber > 50) {
      yardNumber = 50 - (yardNumber % 50);
    }
    return String(yardNumber);
  };

  const numLinesWithoutNumbers = 5;
  const indexOfFirst5YardLineWithNumber = numLinesWithoutNumbers;
  const indexOfLast5YardLineWithNumber =
    fieldHeightYards / 5 - numLinesWithoutNumbers;

  const textYLocations = fiveYardIncrementLines
    .filter((line, index) => {
      return (
        index % 2 === 1 &&
        index >= indexOfFirst5YardLineWithNumber &&
        index <= indexOfLast5YardLineWithNumber
      );
    })
    .map((line) => line[1]);

  const textXLocations = [1 / 6, 5 / 6];

  const textObjects = [];
  for (let i = 0; i < textXLocations.length; i++) {
    for (let j = 0; j < textYLocations.length; j++) {
      const rotation = i === 0 ? 90 : -90;

      const textObject = {
        location: [textXLocations[i], textYLocations[j]],
        rotation,
        text: getYardLineNumber(j),
      };

      textObjects.push(textObject);
    }
  }

  const offsetYMultiplier = ballOn / viewportYards;

  return (
    <Group listening={false} offsetY={stageHeight * offsetYMultiplier}>
      {oneYardIncrementLines.map((line, index) => (
        <Line
          key={index}
          points={convertRelativeLineToAbsolute(line, stageWidth, stageHeight)}
          stroke={printableView ? "#bbbbbb" : colors.fieldMarkingsGreen}
          strokeWidth={lineStrokeWidth}
          dash={generateDashArray(stageWidth)}
        />
      ))}
      {fiveYardIncrementLines.map((line, index) => (
        <Line
          key={index}
          points={convertRelativeLineToAbsolute(line, stageWidth, stageHeight)}
          stroke={printableView ? "#bbbbbb" : colors.fieldMarkingsGreen}
          strokeWidth={lineStrokeWidth}
        />
      ))}
      {endzones.map((rect, index) => (
        <Rect
          key={index}
          x={rect.x * stageWidth}
          y={rect.y * stageHeight}
          width={rect.width * stageWidth}
          height={rect.height * stageHeight}
          fill={printableView ? "white" : colors.endZoneBackgroundGreen}
        />
      ))}
      {endzones.map((rect, index) => (
        <Text
          key={index}
          text="END ZONE"
          letterSpacing={endZoneLetterSpacing}
          x={rect.x * stageWidth}
          y={rect.y * stageHeight}
          width={rect.width * stageWidth}
          height={rect.height * stageHeight}
          fill={printableView ? "white" : colors.fieldMarkingsGreen}
          stroke={colors.fieldMarkingsGreen}
          fontFamily="Chakra Petch"
          fontStyle="italic"
          fontSize={fontSize * 1.9}
          align="center"
          verticalAlign="middle"
        />
      ))}
      {sideLines.map((line, index) => (
        <Line
          key={index}
          points={convertRelativeLineToAbsolute(line, stageWidth, stageHeight)}
          stroke={colors.fieldMarkingsGreen}
          strokeWidth={lineStrokeWidth}
        />
      ))}
      {textObjects.map((obj, index) => {
        const [x, y] = obj.location;
        return (
          <Text
            key={index}
            letterSpacing={5}
            stroke={
              printableView ? colors.lightGray : colors.fieldMarkingsGreen
            }
            fill={printableView ? colors.lightGray : colors.fieldMarkingsGreen}
            text={obj.text}
            fontFamily="Chakra Petch"
            fontStyle="italic"
            fontSize={fontSize}
            width={textBoundingBoxSize}
            height={textBoundingBoxSize}
            x={x * stageWidth}
            y={y * stageHeight}
            offsetX={textBoundingBoxSize / 2}
            offsetY={textBoundingBoxSize / 2}
            align="center"
            verticalAlign="middle"
            rotation={obj.rotation}
          />
        );
      })}
    </Group>
  );
};

export default FieldBackground;
