import { useContext } from "react";
import mean from "lodash/mean";

import CurrentLeadersBoard from "../../../shared/shared-with-mobile/dashboard-widgets/CurrentLeadersBoard/CurrentLeadersBoard";
import mainStyles from "../Dashboard.module.scss";
import styles from "../DashboardHome/DashboardHome.module.scss";
import HomeGrid from "../Components/HomeGrid/HomeGrid";
import { ReadinessScoresContext } from "../../../shared/shared-with-mobile/providers/readinessScores.provider";
import OverallReadiness from "../../../shared/shared-with-mobile/dashboard-widgets/TeamReadiness/OverallReadiness";
import CircularSpinner from "../../../components/Loaders/CircularSpinner";
import { getAbbreviatedDisplayName } from "../../../shared/shared-with-mobile/utilities/getAbbreviatedName";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {
  PlayerOverallReadinessModel,
  PlayerToInstallReadinessModel,
  PlayerToPlayReadinessModel,
} from "../../../generated/from-api/models/dtos/player-to-play.readiness.model";
import ReadinessOverTime from "../../../shared/shared-with-mobile/dashboard-widgets/ReadinessOverTime/ReadinessOverTime";

const DashboardHome: React.FC = () => {
  const {
    loading,
    playerOverallMetrics,
    playerPlayMetrics,
    playerInstallMetrics,
    teamMetricsOverTime,
  } = useContext(ReadinessScoresContext);

  const playersOverallMetricsToDisplay = playerOverallMetrics.filter(
    (player: PlayerOverallReadinessModel) => !player.playerHideOnLeaderBoard
  );

  const top10StrongPlayers = [...playersOverallMetricsToDisplay]
    .sort((a, b) => {
      return Number(b.overallScore) - Number(a.overallScore);
    })
    .slice(0, 10);

  const top10WeakPlayers = [...playersOverallMetricsToDisplay]
    .sort((a, b) => {
      return Number(a.overallScore) - Number(b.overallScore);
    })
    .slice(0, 10);

  const formatPlayerTableData = (players: PlayerOverallReadinessModel[]) => {
    return players.map((player) => {
      const name = getAbbreviatedDisplayName(
        player.playerFirstName,
        player.playerLastName
      );
      const readiness = Number(player.overallScore).toFixed(0) + "%";
      return [name, readiness];
    });
  };

  const formatTop10PlaysTableData = (
    metrics: PlayerToPlayReadinessModel[],
    sort: "highToLow" | "lowToHigh"
  ) => {
    const playMap: Record<
      string,
      {
        playName: string;
        scores: number[];
      }
    > = {};

    // prepare metrics for computing averages
    metrics.forEach((metric) => {
      if (!playMap[metric.playId]) {
        playMap[metric.playId] = {
          playName: metric.playName,
          scores: [Number(metric.readinessScore)],
        };
      } else {
        playMap[metric.playId].scores.push(Number(metric.readinessScore));
      }
    });

    // compute averages
    const playScoreObjects = Object.values(playMap).map((obj) => ({
      playName: obj.playName,
      avgScore: mean(obj.scores),
    }));

    // perform sorting
    if (sort === "highToLow") {
      playScoreObjects.sort((a, b) => b.avgScore - a.avgScore);
    } else if (sort === "lowToHigh") {
      playScoreObjects.sort((a, b) => a.avgScore - b.avgScore);
    }

    // format for table (HomeGrid component)
    const formattedTableData = playScoreObjects
      .map((obj) => {
        const playName = obj.playName;
        const avgScoreStr = obj.avgScore.toFixed(0) + "%";
        return [playName, avgScoreStr];
      })
      .slice(0, 10);

    return formattedTableData;
  };

  const formatInstallTableData = (metrics: PlayerToInstallReadinessModel[]) => {
    const installMap: Record<
      string,
      {
        installName: string;
        scores: number[];
        progressArr: number[];
      }
    > = {};

    // prepare metrics for computing averages
    metrics.forEach((metric) => {
      if (!installMap[metric.installId]) {
        installMap[metric.installId] = {
          installName: metric.installName,
          scores: [Number(metric.readinessScore)],
          progressArr: [Number(metric.progress)],
        };
      } else {
        installMap[metric.installId].scores.push(Number(metric.readinessScore));
        installMap[metric.installId].progressArr.push(Number(metric.progress));
      }
    });

    // compute averages and sort by average readiness
    const installMetricsObjects = Object.values(installMap)
      .map((obj) => ({
        installName: obj.installName,
        avgScore: mean(obj.scores),
        avgProgress: mean(obj.progressArr),
      }))
      .sort((a, b) => b.avgScore - a.avgScore);

    // format data for table (HomeGrid component)
    const formattedTableData = installMetricsObjects.map((obj, index) => {
      const installName = obj.installName;
      const progressBar = (
        <ProgressBar key={index} progress={obj.avgProgress * 100} height={9} />
      );
      const avgScoreStr = obj.avgScore.toFixed(0) + "%";

      return [installName, progressBar, avgScoreStr];
    });

    return formattedTableData;
  };

  return loading ? (
    <CircularSpinner />
  ) : (
    <div>
      <div className={mainStyles.dashboardHeader}>
        <p className={mainStyles.dashboardHeader__Title}>Dashboard Home</p>
      </div>

      <div style={{ padding: "8px" }}>
        <CurrentLeadersBoard />
      </div>

      <div className={`${styles.chartContainer}`}>
        <ReadinessOverTime
          title="Overall Readiness Over Time"
          xLabel="Date"
          yLabel="Readiness"
          ySymbol="%"
          data={
            teamMetricsOverTime
              ? [
                  {
                    id: "line1",
                    data: teamMetricsOverTime.map((t) => {
                      return {
                        x: t.created,
                        y: +t.overallScore,
                      };
                    }),
                  },
                ]
              : []
          }
        />
        <OverallReadiness
          title="Overall Readiness Distribution"
          type="Bar-Distribution"
          isLabel={true}
          xLabel="Percentile"
          yLabel="% of Players"
          ySymbol="%"
          scoreType="overallScore"
        />
        <OverallReadiness
          title="Overall Readiness by Unit"
          type="Bar-Unit"
          xLabel="Unit"
          yLabel="Readiness"
          ySymbol="%"
          scoreType="overallScore"
        />

        <HomeGrid
          title="Top 10 All Time Strong Players"
          columnHeaders={["Player", "Readiness"]}
          rows={formatPlayerTableData(top10StrongPlayers)}
        />

        <HomeGrid
          title="Top 10 All Time Weak Players"
          columnHeaders={["Player", "Readiness"]}
          rows={formatPlayerTableData(top10WeakPlayers)}
        />
        <HomeGrid
          title="Top 10 All Time Strong Plays"
          columnHeaders={["Play", "Readiness"]}
          rows={formatTop10PlaysTableData(playerPlayMetrics, "highToLow")}
        />
        <HomeGrid
          title="Top 10 All Time Weak Plays"
          columnHeaders={["Play", "Readiness"]}
          rows={formatTop10PlaysTableData(playerPlayMetrics, "lowToHigh")}
        />
        <HomeGrid
          title="Lessons Readiness"
          columnHeaders={["Lesson", "Participation", "Readiness"]}
          rows={formatInstallTableData(playerInstallMetrics)}
        />
        {/* <HomeGrid
          title="Top 10 All Time Weak Players"
          columnHeaders={["Player", "Readiness"]}
          rows={formatPlayerTableData(top10WeakPlayers)}
        /> */}
      </div>
    </div>
  );
};

export default DashboardHome;
