import { memo, useState, useContext, useEffect } from "react";

import styles from "./InstallPreview.module.scss";
import { InstallSlideModel } from "../../../../generated/from-api/models/install/install-slide.model";
import MediaThumbnail from "../../../../components/media/MediaThumbnail/MediaThumbnail";
import { MediaContext } from "../../../../shared/shared-with-mobile/providers/media.provider";
import PlayThumbnail from "../../../../components/drawing/PlayThumbnail";
import { PlaybookContext } from "../../../../shared/shared-with-mobile/providers/playbook.provider";
import Wysiwyg from "../../../../components/Wysiwyg/Wysiwyg";
import { QuizzesContext } from "../../../../shared/shared-with-mobile/providers/quizzes.provider";
import liveHelp from "../../../../resources/images/liveHelp.svg";
import { INSTALL_SETTINGS } from "../../../../utils/web-only-constants";
import InstallPreviewQuestion from "./InstallPreviewQuestion";
import Button from "../../../../components/Button/Button";

interface Props {
  slides: InstallSlideModel[];
  key?: string;
  focusedSlideIndex?: number;
}

const InstallPreview: React.FC<Props> = ({ slides, focusedSlideIndex }) => {
  const { LABELS } = INSTALL_SETTINGS;
  const { mediaItemsMap } = useContext(MediaContext);
  const { playItemsMap } = useContext(PlaybookContext);
  const { quizItemsMap } = useContext(QuizzesContext);
  const slideCount = slides.length;
  const slideTotalStr = " of " + (slideCount <= 0 ? 1 : slideCount);
  const [slideIdx, setSlideIdx] = useState<number>(0);
  const [slideNum, setSlideNum] = useState<number>(1);
  const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(
    slideCount <= 1 ? true : false
  );
  const [previewSlideAnimating, setPreviewSlideAnimating] = useState("");

  const incrementSlide = (change: number) => {
    const newIdx = slideIdx + change;
    if (newIdx < 0 || newIdx >= slides.length) {
      return;
    }
    setPreviewSlideAnimating(
      change > 0 ? "animating-next" : "animating-previous"
    );
    setTimeout(() => {
      setPreviewSlideAnimating("");
      setSlideIdx(newIdx);
      setSlideNum(slideNum + change);
      setIsFirstSlide(newIdx === 0);
      setIsLastSlide(newIdx === slideCount - 1);
    }, 300);
  };

  useEffect(() => {
    if (focusedSlideIndex !== undefined) {
      setSlideIdx(focusedSlideIndex);
    }
  }, [focusedSlideIndex]);

  return (
    <>
      <div className={styles.previewPhoneContainer}>
        <div className={styles.previewPhoneScreen}>
          <div className={styles.previewPhoneHeader}>
            {LABELS.singular_capitalized}
          </div>
          {slideCount > 0 ? (
            <ol
              className={`${styles.previewPhoneSlides} ${previewSlideAnimating}`}
            >
              {(function () {
                // Determine the range of slides we're gonna be looking at
                const returnedSlides = [];

                for (let i = slideIdx - 1; i <= slideIdx + 1; i++) {
                  const slide =
                    i < 0
                      ? { id: "first-slide-placeholder" }
                      : i >= slides.length
                      ? { id: "last-slide-placeholder" }
                      : slides[i];
                  if (slide === undefined || slide.id === undefined) {
                    return null;
                  }

                  returnedSlides.push(
                    <li
                      key={`install-phone-preview-slide-${i}`}
                      className={`${styles.previewPhoneSlide} ${
                        returnedSlides.length === 0
                          ? "previous-slide"
                          : returnedSlides.length === 2
                          ? "next-slide"
                          : "current-slide"
                      }`}
                    >
                      {!slide?.customQuizId &&
                        !slide?.question &&
                        slide?.slideElements?.map(
                          (slideElement, slideElementIndex) => {
                            return (
                              <div
                                key={`install-phone-preview-slide-${i}-element-${slideElementIndex}`}
                                className={styles.previewPhoneSlideElement}
                              >
                                {(function () {
                                  switch (slideElement.type) {
                                    case "TEXT":
                                      return (
                                        <div
                                          className={
                                            styles.previewPhoneSlideTextElement
                                          }
                                        >
                                          <Wysiwyg
                                            editorData={{
                                              content: slideElement.textValue!
                                                .data,
                                              textAlignment: slideElement.textValue!
                                                .textAlignment,
                                            }}
                                            onContentUpdate={() => {
                                              return;
                                            }}
                                            readOnly
                                            key={JSON.stringify(
                                              slideElement.textValue
                                            ).length.toString()}
                                          />
                                        </div>
                                      );
                                      break;
                                    case "MEDIA":
                                      return (
                                        <div
                                          className={
                                            styles.previewPhoneSlideMediaElement
                                          }
                                        >
                                          <MediaThumbnail
                                            mediaItem={
                                              mediaItemsMap[
                                                slideElement.mediaId || ""
                                              ]
                                            }
                                          />
                                        </div>
                                      );
                                      break;
                                    case "PLAY":
                                      return (
                                        <div
                                          className={
                                            styles.previewPhoneSlidePlayElement
                                          }
                                        >
                                          <div
                                            className={
                                              styles.previewPhoneSlidePlayTitle
                                            }
                                          >
                                            {slideElement.playId &&
                                            playItemsMap[slideElement.playId]
                                              ? playItemsMap[
                                                  slideElement.playId
                                                ].name
                                              : "Missing Play"}
                                          </div>
                                          <div
                                            className={
                                              styles.InstallSlidePlayCardThumbnailWrapper
                                            }
                                          >
                                            {slideElement.playId &&
                                            playItemsMap[
                                              slideElement.playId
                                            ] ? (
                                              <PlayThumbnail
                                                play={
                                                  playItemsMap[
                                                    slideElement.playId
                                                  ]
                                                }
                                              />
                                            ) : (
                                              "No Play Found"
                                            )}
                                          </div>
                                        </div>
                                      );
                                      break;
                                    default:
                                      return (
                                        <div>
                                          No mobile preview for
                                          {slideElement.type} type elements.
                                        </div>
                                      );
                                      break;
                                  }
                                })()}
                                {/* Slide ID: {JSON.stringify(slideElement, null, 2)} */}
                              </div>
                            );
                          }
                        )}
                      {!!slide?.customQuizId && (
                        <div className={styles.previewPhoneSlideQuiz}>
                          <div
                            className={styles.previewPhoneSlideQuizSplashIcon}
                          >
                            <img src={liveHelp} height={36} width={36} />
                          </div>
                          <div
                            className={styles.previewPhoneSlideQuizSplashTitle}
                          >
                            Quiz Time!
                          </div>
                          <div
                            className={styles.previewPhoneSlideQuizSplashInfo}
                          >
                            {
                              "You'll be competing with your teammates for the top score!"
                            }
                          </div>
                          <div className={styles.previewPhoneSlideQuizHeader}>
                            <div>Quiz</div>
                            <div
                              className={styles.previewPhoneSlideQuizHeaderName}
                            >
                              {quizItemsMap[slide.customQuizId]
                                ? quizItemsMap[slide.customQuizId].name
                                : "The Quiz is unavailable"}
                            </div>
                          </div>
                          <div className={styles.previewPhoneSlideQuizButton}>
                            <Button
                              className={styles.startButton}
                              size="x-small"
                              width="full"
                            >
                              Start Quiz
                            </Button>
                          </div>
                        </div>
                      )}
                      {!!slide?.question && (
                        <InstallPreviewQuestion
                          question={slide.question}
                          isMultipleChoice
                        />
                      )}
                    </li>
                  );
                }
                return returnedSlides;
              })()}
            </ol>
          ) : (
            "Slide content will appear here"
          )}
          <div className={styles.previewPhoneFooter}>
            <div
              className={`${styles.Previous} ${!isFirstSlide ? "Active" : ""}`}
              onClick={() => {
                incrementSlide(-1);
              }}
            >
              Prev
            </div>
            <div>{slideNum + slideTotalStr}</div>
            <div
              className={`${styles.Next} ${!isLastSlide ? "Active" : ""}`}
              onClick={() => {
                incrementSlide(1);
              }}
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(InstallPreview);
