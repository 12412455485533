import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { InvitationCodeModel } from "../../generated/from-api/models/invitation-code.model";
import PlayerLoginIcon from "../../resources/images/player-login.svg";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import { TeamContext } from "../../shared/shared-with-mobile/providers/team.provider";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import styles from "./ShareCode.module.scss";

interface Props {
  onBackButtonClick: () => void;
}

const ShareCode: React.FC<Props> = ({ onBackButtonClick }) => {
  const { dispatchToast } = useContext(UIContext);
  const [currentInvitationCode, setCurrentInvitationCode] = useState<
    InvitationCodeModel | undefined
  >();
  const { currentTeam } = useContext(TeamContext);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    const loadInvitationCode = async () => {
      setShowLoading(true);
      if (!currentTeam?.id) {
        return;
      } else {
        let existingCode = await APIService.INVITATION_CODE.GET(
          currentTeam?.id
        );
        if (!existingCode) {
          existingCode = await APIService.INVITATION_CODE.POST({
            teamId: currentTeam?.id,
          });
          // since we want code to be disabled by default (in this case)
          existingCode = await APIService.INVITATION_CODE.PUT(
            {
              teamId: currentTeam?.id,
              expirationDate: null,
              disabled: true,
            },
            false
          );
        }
        if (!isCancelled && existingCode) {
          setCurrentInvitationCode(existingCode);
          setShowLoading(false);
        }
      }
    };
    loadInvitationCode();
    return () => {
      isCancelled = true;
    };
  }, [currentTeam]);

  return (
    <div className={styles.shareCode}>
      {showLoading ? (
        "Loading..."
      ) : (
        <div>
          <div className={styles.wrapper}>
            <h3>Team Code</h3>
            <img style={{ margin: "30px 0" }} src={PlayerLoginIcon} />
            <div className={styles.codeContainer}>
              <p color="primary">
                <div
                  style={{
                    fontSize: "17px",
                    textAlign: "center",
                    marginBottom: "12px",
                  }}
                >
                  Share this code with anyone you want to join your team
                </div>
              </p>
              <p color="primary">
                <div className={styles.invitationCode}>
                  {currentInvitationCode?.invitationCode}
                </div>
              </p>
              <Button
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(
                      currentInvitationCode?.invitationCode ?? ""
                    );
                    dispatchToast({
                      type: "success",
                      message: "Invite code copied to clipboard.",
                    });
                  } catch (error) {
                    dispatchToast({
                      type: "error",
                      message:
                        "Invite code copy to clipboard failed. No permissions.",
                    });
                  }
                }}
                theme="primary"
              >
                {"Share Code"}
              </Button>
            </div>
            <Button
              onClick={() => onBackButtonClick()}
              className={styles.backBtn}
              theme="secondary"
            >
              Back
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareCode;
