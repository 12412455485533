import React, { useState } from "react";
import Checkbox from "../../../components/Checkbox/Checkbox";

import styles from "../Account.module.scss";

type notificationsOptions = {
  [key: string]: [boolean, boolean];
};

const NotificationsTab: React.FC = () => {
  const [getNotificationSettings, setNotificationSettings] = useState({
    weeklySummary: [true, true],
    newPlays: [true, true],
    newFeatures: [true, true],
    joinRequest: [true, true],
    playersJoin: [true, true],
    inviteAccepted: [true, true],
    deadlineReached: [true, true],
  } as notificationsOptions);

  const notificationToggle = (e: any) => {
    const [notification, option] = e.target.name.split("-");
    const newNotificationSettings = Object.assign({}, getNotificationSettings);
    if (option === "email") {
      newNotificationSettings[notification][0] = !newNotificationSettings[
        notification
      ][0];
    } else {
      // site notification
      newNotificationSettings[notification][1] = !newNotificationSettings[
        notification
      ][1];
    }
    setNotificationSettings(newNotificationSettings);
  };

  return (
    <div>
      <h1>Notifications</h1>
      Everything here is just for looks, none of these options save/load yet.
      <br />
      <br />
      <fieldset className={styles.notificationsSection}>
        <legend className={styles.notificationsSectionHeader}>
          <span>Updates</span>
          <span>Email</span>
          <span>On site</span>
        </legend>
        <ul className={styles.notificationsSectionOptions}>
          <li>
            <span>
              Weekly summary of your team’s progress, leadboard positions, top
              strong/weak players of the week.
            </span>
            <span>
              <Checkbox
                name="newPlays-email"
                checked={getNotificationSettings.newPlays[0]}
                onChange={notificationToggle}
              />
            </span>
            <span>
              <Checkbox
                name="newPlays-site"
                checked={getNotificationSettings.newPlays[1]}
                onChange={notificationToggle}
              />
            </span>
          </li>
          <li>
            <span>
              New plays, lessons and flashcards are added to the library.
            </span>
            <span>
              <Checkbox
                name="weeklySummary-email"
                checked={getNotificationSettings.weeklySummary[0]}
                onChange={notificationToggle}
              />
            </span>
            <span>
              <Checkbox
                name="weeklySummary-site"
                checked={getNotificationSettings.weeklySummary[1]}
                onChange={notificationToggle}
              />
            </span>
          </li>
          <li>
            <span>New features are released.</span>
            <span>
              <Checkbox
                name="newFeatures-email"
                checked={getNotificationSettings.newFeatures[0]}
                onChange={notificationToggle}
              />
            </span>
            <span>
              <Checkbox
                name="newFeatures-site"
                checked={getNotificationSettings.newFeatures[1]}
                onChange={notificationToggle}
              />
            </span>
          </li>
        </ul>
      </fieldset>
      <fieldset className={styles.notificationsSection}>
        <legend className={styles.notificationsSectionHeader}>
          <span>Roster</span>
          <span>Email</span>
          <span>On site</span>
        </legend>
        <ul className={styles.notificationsSectionOptions}>
          <li>
            <span>Players request to join your team.</span>
            <span>
              <Checkbox
                name="playersJoin-email"
                checked={getNotificationSettings.playersJoin[0]}
                onChange={notificationToggle}
              />
            </span>
            <span>
              <Checkbox
                name="playersJoin-site"
                checked={getNotificationSettings.playersJoin[1]}
                onChange={notificationToggle}
              />
            </span>
          </li>
          <li>
            <span>Players accept invitation.</span>
            <span>
              <Checkbox
                name="inviteAccepted-email"
                checked={getNotificationSettings.inviteAccepted[0]}
                onChange={notificationToggle}
              />
            </span>
            <span>
              <Checkbox
                name="inviteAccepted-site"
                checked={getNotificationSettings.inviteAccepted[1]}
                onChange={notificationToggle}
              />
            </span>
          </li>
        </ul>
      </fieldset>
      <fieldset className={styles.notificationsSection}>
        <legend className={styles.notificationsSectionHeader}>
          <span>Playbook</span>
          <span>Email</span>
          <span>On site</span>
        </legend>
        <ul className={styles.notificationsSectionOptions}>
          <li>
            <span>Lessons and flashcards deadlines are reached.</span>
            <span>
              <Checkbox
                name="deadlineReached-email"
                checked={getNotificationSettings.deadlineReached[0]}
                onChange={notificationToggle}
              />
            </span>
            <span>
              <Checkbox
                name="deadlineReached-site"
                checked={getNotificationSettings.deadlineReached[1]}
                onChange={notificationToggle}
              />
            </span>
          </li>
        </ul>
      </fieldset>
    </div>
  );
};

export default NotificationsTab;
