interface Props {
  progress?: number;
  height?: number;
}

const ProgressBar: React.FC<Props> = ({ progress, height }) => {
  let bgcolor = "#eb5953";
  if (progress && progress >= 60) {
    bgcolor = "#2CDA92";
  } else if (progress && progress >= 20 && progress && progress < 60) {
    bgcolor = "#FFCC3D";
  } else {
    bgcolor = "#eb5953";
  }
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "#282B3A",
    borderRadius: 40,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}></div>
    </div>
  );
};

export default ProgressBar;
