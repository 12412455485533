import React from "react";
import styles from "./DashboardSidebar.module.scss";
import homeIcon from "../../../resources/images/home.svg";
import barIcon from "../../../resources/images/barIcon_24px.svg";
import liveHelpIcon from "../../../resources/images/live_help_24px.svg";
import bookIcon from "../../../resources/images/book_24px.svg";
import listIcon from "../../../resources/images/list_alt_24px.svg";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { match } from "react-router-dom";
export interface DashboardSidebarRoutes {
  isHome?: boolean;
  isOverall?: boolean;
  isPlaybook?: boolean;
  isLessons?: boolean;
  isQuizzes?: boolean;
  isTimeSpent?: boolean;
}

interface Props extends RouteComponentProps {
  match: match<MatchParams>;
}
interface MatchParams {
  module: string;
}

const DashboardSidebar: React.FC<Props> = ({ match }) => {
  const history = useHistory();
  const module = match.params.module;
  return (
    <div className={styles.dashboardSidebar}>
      <div className={styles.sidebarContainer}>
        <div className={styles.categoryFilters}>
          <div
            className={!module ? styles.active : styles.headerBar}
            onClick={() => history.push("/dashboard")}
          >
            <img src={homeIcon} />
            <div className={styles.itemName}>Home</div>
          </div>
          <div className={styles.categoryName}>
            <span>Performance</span>
          </div>
          <div
            className={module === "overall" ? styles.active : styles.headerBar}
            onClick={() => history.push("/dashboard/overall")}
          >
            <img src={barIcon} />
            <div className={styles.itemName}>Overall</div>
          </div>
          <div
            className={module === "playbook" ? styles.active : styles.headerBar}
            onClick={() => history.push("/dashboard/playbook")}
          >
            <img src={bookIcon} />
            <div className={styles.itemName}>Playbook</div>
          </div>
          <div
            className={module === "lessons" ? styles.active : styles.headerBar}
            onClick={() => history.push("/dashboard/lessons")}
          >
            <img src={listIcon} />
            <div className={styles.itemName}>Lessons</div>
          </div>
          <div
            className={
              module === "flashcards" ? styles.active : styles.headerBar
            }
            onClick={() => history.push("/dashboard/flashcards")}
          >
            <img src={liveHelpIcon} />
            <div className={styles.itemName}>Flashcards</div>
          </div>
          {/* <div className={styles.categoryName}>
            <span>Engagement</span>
          </div>
          <div
            className={isActive.isTimeSpent ? styles.active : styles.headerBar}
            onClick={() => setIsActive({ isTimeSpent: true })}
          >
            <img src={timmerIcon} />
            <div className={styles.itemName}>Time Spent</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(DashboardSidebar);
