import * as React from "react";

const ChevronLeftIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="16"
    viewBox="0 0 10 16"
  >
    <path d="M9.94006 14.12L3.83339 8L9.94006 1.88L8.06006 0L0.0600586 8L8.06006 16L9.94006 14.12Z" />
  </svg>
);

export default ChevronLeftIcon;
