import { KonvaEventObject } from "konva/types/Node";
import { useContext, useRef, useState, useEffect } from "react";
import { Stage, Layer, Line, Rect, Group } from "react-konva";
import { RouteComponentProps } from "react-router";
import { match } from "react-router-dom";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";

import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";
import {
  useContainerDimensions,
  lockNumberInRange,
  convertRelativeLineToAbsolute,
  convertPlayerToShape,
  flipPlayersByDimension,
  getRelativeCoordinates,
  reduceAlpha,
  hexToRgbA,
} from "../../shared/shared-with-mobile/play-editor/playEditor.utils";
import Shape from "../../shared/shared-with-mobile/play-editor/Shape";
import FieldBackground from "../../shared/shared-with-mobile/play-editor/FieldBackground";
import EditPlayToolbar from "../../components/drawing/EditPlayToolbar";
import FormationSettingsToolbar from "../../components/drawing/FormationSettingsToolbar/FormationSettingsToolbar";
import { colors } from "../../shared/shared-with-mobile/play-editor/play-editor-colors";
import { KonvaDragHandler } from "../../shared/shared-with-mobile/play-editor/drawing.types";
import {
  DrawablePlayerModel,
  FillPattern,
} from "../../generated/from-api/models/drawable/drawablePlayer.model";
import { FormationModel } from "../../generated/from-api/models/drawable/formation.model";
import { FormationsContext } from "../../shared/shared-with-mobile/providers/formations.provider";
import { lineOfScrimmageY } from "../../shared/shared-with-mobile/play-editor/playEditor.constants";
// sharing styles with EditPlay page
import styles from "../EditPlay/EditPlay.module.scss";
import { PlayTypeEnum } from "../../generated/from-api/models/enums/play-type.enum";
import PlayerChangingFieldOverlay from "../../components/drawing/PlayerChangingFieldOverlay/PlayerChangingFieldOverlay";

interface Props extends RouteComponentProps {
  match: match<MatchParams>;
}
interface MatchParams {
  id: string;
}

const debounceTime = 2000;

const EditFormation: React.FC<Props> = ({ match }) => {
  const {
    globalFormations,
    currentFormations,
    updateFormation: updateFormationInContext,
  } = useContext(FormationsContext);
  const formationId = match.params.id;
  const loadedFormation = [...globalFormations, ...currentFormations].find(
    (formation: FormationModel) => formation.id === formationId
  );

  if (!loadedFormation) {
    return null;
  }

  const [formation, setFormation] = useState<
    Omit<FormationModel, "drawablePlayers">
  >(loadedFormation);

  const [players, setPlayers] = useState<DrawablePlayerModel[]>(
    loadedFormation.drawablePlayers
  );

  const [selectedPlayerIds, setSelectedPlayerIds] = useState<string[] | null>(
    null
  );
  const [draggedPlayerId, setDraggedPlayerId] = useState<string | null>(null);

  // not actually application state, but extremely helpful to have snapshot of data for reference when repositioning all players
  const [playersCopyOnDragStart, setPlayersCopyOnDragStart] = useState<
    DrawablePlayerModel[] | null
  >(null);

  // selection box state
  const [isDrawingSelectionBox, setIsDrawingSelectionBox] = useState(false);
  const [selectionBoxFirstCorner, setSelectionBoxFirstCorner] = useState<
    number[] | null
  >(null);
  const [selectionBoxSecondCorner, setSelectionBoxSecondCorner] = useState<
    number[] | null
  >(null);

  const [isPlayerChanging, setIsPlayerChanging] = useState<boolean>(false);
  const [
    playerToBeAdded,
    setPlayerToBeAdded,
  ] = useState<DrawablePlayerModel | null>(null);

  const stageContainerRef = useRef<HTMLDivElement>(null);
  const {
    containerWidth: stageWidth,
    containerHeight: stageHeight,
  } = useContainerDimensions(stageContainerRef);

  const [
    hasTransformedPlayerIds,
    setHasTransformedPlayerIds,
  ] = useState<boolean>(false);

  // As of March 2023, we are beginning to support more or less than 11 players, or 22 players on the field at one time
  // So we need to start using actual unique ids for each player, instead of just integers starting at 1, to prevent bugs
  // this useEffect will transform player ids to uuidv4 if needed
  useEffect(() => {
    if (!players || hasTransformedPlayerIds) {
      return;
    }

    const nextPlayers = players.map((player) => ({
      ...player,
      id: player.id.length < 10 ? uuidv4() : player.id,
    }));

    setHasTransformedPlayerIds(true);
    setPlayers(nextPlayers);
  }, [players, hasTransformedPlayerIds]);

  useEffect(() => {
    debouncedUpdateFormation.current({
      ...formation,
      drawablePlayers: players,
    });
  }, [formation, players]);

  const updateFormation = async (formationToUpdate: FormationModel) => {
    const updated = await APIService.FORMATION.PUT(formationToUpdate);
    if (updated) {
      updateFormationInContext(updated);
    }
  };

  const debouncedUpdateFormation = useRef(
    debounce((formationToUpdate: FormationModel) => {
      updateFormation(formationToUpdate);
    }, debounceTime)
  );

  // this use effect calls the selectPlayers function when it detects the user has just finished drawing a selection box
  useEffect(() => {
    if (!isDrawingSelectionBox) {
      if (selectionBoxFirstCorner && selectionBoxSecondCorner) {
        selectPlayers(selectionBoxFirstCorner, selectionBoxSecondCorner);
      }

      setSelectionBoxFirstCorner(null);
      setSelectionBoxSecondCorner(null);
    }
  }, [isDrawingSelectionBox]);

  const handleStageMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const { clientX, clientY } = e.evt;
    handleStageDown(clientX, clientY, e.target);
  };

  const handleStageMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const { clientX, clientY } = e.evt;
    handleStageMove(clientX, clientY, e.target);
  };

  const handleStageMouseUp = (e: KonvaEventObject<MouseEvent>) => {
    const { clientX, clientY } = e.evt;
    handleStageUp(clientX, clientY, e.target);
  };

  const handleStageMouseLeave = () => {
    setIsDrawingSelectionBox(false);
    setPlayerToBeAdded(null);
  };

  const handleStageMove = (clientX: number, clientY: number, target: any) => {
    if (
      !stageContainerRef.current ||
      (!isDrawingSelectionBox && !isPlayerChanging)
    ) {
      return;
    }

    const coordinates = getRelativeCoordinates(
      clientX,
      clientY,
      stageContainerRef.current
    );

    if (isDrawingSelectionBox) {
      setSelectionBoxSecondCorner(coordinates);
    } else if (isPlayerChanging) {
      showPlayerToBeAdded(coordinates, target);
    }
  };

  const showPlayerToBeAdded = (coordinates: number[], target: any) => {
    const targetName = target.__proto__.nodeType;
    const newPlayerPlayType: PlayTypeEnum =
      coordinates[1] >= lineOfScrimmageY
        ? formation.type === "Offensive"
          ? "Offensive"
          : "Defensive"
        : formation.type === "Offensive"
        ? "Defensive"
        : "Offensive";

    if (targetName === "Stage" && newPlayerPlayType === formation.type) {
      const tempPlayer: DrawablePlayerModel = {
        x: coordinates[0],
        y: coordinates[1],
        id: uuidv4(),
        fillPattern: getMostCommonFill(newPlayerPlayType),
        color: reduceAlpha(
          hexToRgbA(getMostCommonColor(newPlayerPlayType)),
          0.6
        ),
        playType: newPlayerPlayType,
      };

      setPlayerToBeAdded(tempPlayer);
    } else {
      setPlayerToBeAdded(null);
    }
  };

  const handleStageTouchStart = (e: KonvaEventObject<TouchEvent>) => {
    const { clientX, clientY } = e.evt.changedTouches[0];
    handleStageDown(clientX, clientY, e.target);
  };

  const handleStageTouchEnd = (e: KonvaEventObject<TouchEvent>) => {
    const { clientX, clientY } = e.evt.changedTouches[0];
    handleStageUp(clientX, clientY, e.target);
  };

  const handleStageTouchMove = (e: KonvaEventObject<TouchEvent>) => {
    const { clientX, clientY } = e.evt.changedTouches[0];
    handleStageMove(clientX, clientY, e.target);
  };

  const getMostCommonFill = (playType: PlayTypeEnum): FillPattern => {
    const counts: Record<string, number> = {};
    let maxNum = 0;
    let whichFill: FillPattern = "filled";

    for (const player of players.filter((p) => p.playType === playType)) {
      if (counts[player.fillPattern] === undefined) {
        counts[player.fillPattern] = 1;
      } else {
        counts[player.fillPattern]++;
      }

      if (counts[player.fillPattern] > maxNum) {
        maxNum = counts[player.fillPattern];
        whichFill = player.fillPattern;
      }
    }

    return whichFill;
  };

  const getMostCommonColor = (playType: PlayTypeEnum): string => {
    const counts: Record<string, number> = {};
    let maxNum = 0;
    let whichColor = colors.gray;

    for (const player of players.filter((p) => p.playType === playType)) {
      if (counts[player.color] === undefined) {
        counts[player.color] = 1;
      } else {
        counts[player.color]++;
      }

      if (counts[player.color] > maxNum) {
        maxNum = counts[player.color];
        whichColor = player.color;
      }
    }

    return whichColor;
  };

  const addOrRemovePlayer = (target: any, coordinates: number[]) => {
    const targetName = target.__proto__.nodeType;

    if (targetName === "Shape" && target?.id()) {
      const nextPlayers = players.filter((player) => player.id !== target.id());

      if (nextPlayers.length >= 1) {
        setPlayers(nextPlayers);
      }
      return;
    }

    const newPlayerPlayType: PlayTypeEnum =
      coordinates[1] >= lineOfScrimmageY
        ? formation.type === "Offensive"
          ? "Offensive"
          : "Defensive"
        : formation.type === "Offensive"
        ? "Defensive"
        : "Offensive";

    if (newPlayerPlayType === formation.type && targetName === "Stage") {
      const newPlayer: DrawablePlayerModel = {
        x: coordinates[0],
        y: coordinates[1],
        id: uuidv4(),
        fillPattern: getMostCommonFill(newPlayerPlayType),
        color: getMostCommonColor(newPlayerPlayType),
        playType: newPlayerPlayType,
      };
      const nextPlayers = [...players, newPlayer];
      setPlayers(nextPlayers);
    }
  };

  // handleStageUp will either
  // select a single player, or unselect a player if the singly selected player is clicked again
  // OR add or remove a player if "isPlayerChanging" is true
  // OR finish drawing a selection box to select multiple players
  // OR do nothing if no player is selected or clicked
  const handleStageUp = (clientX: number, clientY: number, target: any) => {
    if (!stageContainerRef.current) {
      return;
    }

    setIsDrawingSelectionBox(false);

    const targetName = target.__proto__.nodeType;

    const coordinates = getRelativeCoordinates(
      clientX,
      clientY,
      stageContainerRef.current
    );

    if (isPlayerChanging) {
      addOrRemovePlayer(target, coordinates);
      return;
    }

    if (targetName === "Shape") {
      if (
        !selectedPlayerIds || // none are selected
        selectedPlayerIds.length !== 1 || // multiple are selected
        selectedPlayerIds[0] !== target.id() // one is selected, and it's different than the clicked target
      ) {
        setSelectedPlayerIds([target.id()]);
      } else {
        // exactly one is selected, and it's the same as the click target
        setSelectedPlayerIds(null);
      }
      return;
    }
  };

  // handleStageDown will start drawing a selection box
  const handleStageDown = (clientX: number, clientY: number, target: any) => {
    if (!stageContainerRef.current) {
      return;
    }

    const targetName = target.__proto__.nodeType;

    const coordinates = getRelativeCoordinates(
      clientX,
      clientY,
      stageContainerRef.current
    );

    if (isPlayerChanging) {
      return;
    }

    // start drawing selection box to select multiple players
    if (targetName === "Stage") {
      setIsDrawingSelectionBox(true);
      setSelectionBoxFirstCorner(coordinates);
      setSelectionBoxSecondCorner(coordinates);
    }
  };

  const selectPlayers = (firstCorner: number[], secondCorner: number[]) => {
    let nextSelectedPlayerIds: string[] = [];

    const [x1, y1] = firstCorner;
    const [x2, y2] = secondCorner;

    const leftEdgeX = Math.min(x1, x2);
    const rightEdgeX = Math.max(x1, x2);
    const topEdgeY = Math.min(y1, y2);
    const bottomEdgeY = Math.max(y1, y2);

    // select all players
    for (const player of players) {
      const leeway = 0.01;

      if (
        player.x >= leftEdgeX - leeway &&
        player.x <= rightEdgeX + leeway &&
        player.y >= topEdgeY - leeway &&
        player.y <= bottomEdgeY + leeway
      ) {
        nextSelectedPlayerIds.push(player.id);
      }
    }

    // manipulate selection to ensure that we are only selecting either all team players or all opponent players
    // and not a mix of both
    const teamPlayerIds = players
      .filter((player) => player.playType === formation.type)
      .map((player) => player.id);
    const opponentPlayerIds = players
      .filter((player) => player.playType !== formation.type)
      .map((player) => player.id);

    let selectedTeamCount = 0;
    let selectedOpponentCount = 0;
    for (const id of nextSelectedPlayerIds) {
      if (teamPlayerIds.includes(id)) {
        selectedTeamCount++;
      } else if (opponentPlayerIds.includes(id)) {
        selectedOpponentCount++;
      }
    }

    const shouldSelectOwnTeam = selectedTeamCount >= selectedOpponentCount;

    nextSelectedPlayerIds = nextSelectedPlayerIds.filter((id) =>
      shouldSelectOwnTeam
        ? teamPlayerIds.includes(id)
        : opponentPlayerIds.includes(id)
    );

    setSelectedPlayerIds(
      !!nextSelectedPlayerIds.length ? nextSelectedPlayerIds : null
    );
  };

  const onPlayerDragStart = (e: KonvaEventObject<DragEvent>) => {
    const id = e.target.id();
    setDraggedPlayerId(id);

    let isDraggingMultiplePlayers =
      !!selectedPlayerIds && selectedPlayerIds?.length > 1;

    if (
      !selectedPlayerIds ||
      (!!selectedPlayerIds?.length && !selectedPlayerIds.includes(id))
    ) {
      setSelectedPlayerIds([id]);
      isDraggingMultiplePlayers = false;
    }

    const center = players.find((p) => p.position === "C");

    const isDraggingCenter = center?.id === id;

    if (isDraggingCenter || isDraggingMultiplePlayers) {
      setPlayersCopyOnDragStart(cloneDeep(players));
    }
  };

  const onPlayerDragEnd = () => {
    setDraggedPlayerId(null);
    setPlayersCopyOnDragStart(null);
  };

  const onPlayerDrag: KonvaDragHandler = (position) => {
    const isDraggingCenter = playersCopyOnDragStart !== null;
    if (selectedPlayerIds?.length && selectedPlayerIds.length > 1) {
      return onMultiplePlayerDrag(position);
    } else if (isDraggingCenter) {
      return onCenterDrag(position);
    } else {
      return onRegularPlayerDrag(position);
    }
  };

  const onMultiplePlayerDrag: KonvaDragHandler = (position) => {
    if (!playersCopyOnDragStart) {
      return position;
    }

    const draggedPlayerAtDragStart = playersCopyOnDragStart.find(
      (player) => player.id === draggedPlayerId // huh? what was this doing, and will it still work
    );

    const draggedPlayer = players.find(
      (player) => player.id === draggedPlayerId // huh? what was this doing, and will it still work
    );
    if (!draggedPlayerAtDragStart || !draggedPlayer) {
      return position;
    }

    const isDraggingOpponentPlayer = draggedPlayer?.playType !== formation.type;
    const relXMin = 0;
    const relXMax = 1;
    const relYMin = isDraggingOpponentPlayer ? 0 : lineOfScrimmageY;
    const relYMax = isDraggingOpponentPlayer ? lineOfScrimmageY : 1;

    const selectedPlayers = players.filter((player) =>
      selectedPlayerIds?.includes(player.id)
    );

    const playersXMin = Math.min(...selectedPlayers.map((p) => p.x));
    const playersXMax = Math.max(...selectedPlayers.map((p) => p.x));
    const playersYMin = Math.min(...selectedPlayers.map((p) => p.y));
    const playersYMax = Math.max(...selectedPlayers.map((p) => p.y));
    // if any one of the players is at a boundary
    // we should prevent further movement towards that side
    const shouldPreventXDecrease = playersXMin <= relXMin;
    const shouldPreventXIncrease = playersXMax >= relXMax;
    const shouldPreventYDecrease = playersYMin <= relYMin;
    const shouldPreventYIncrease = playersYMax >= relYMax;

    const { x, y } = position;
    const xIncreasing = x / stageWidth > draggedPlayer.x;
    const xDecreasing = x / stageWidth < draggedPlayer.x;
    const yIncreasing = y / stageHeight > draggedPlayer.y;
    const yDecreasing = y / stageHeight < draggedPlayer.y;

    const lockHorizontalMovement =
      (shouldPreventXIncrease && xIncreasing) ||
      (shouldPreventXDecrease && xDecreasing);

    const lockVerticalMovement =
      (shouldPreventYIncrease && yIncreasing) ||
      (shouldPreventYDecrease && yDecreasing);

    const absXMin = relXMin * stageWidth;
    const absXMax = relXMax * stageWidth;
    const absYMin = relYMin * stageHeight;
    const absYMax = relYMax * stageHeight;

    const absoluteX = lockHorizontalMovement
      ? draggedPlayer.x * stageWidth
      : lockNumberInRange(x, absXMin, absXMax);

    const absoluteY = lockVerticalMovement
      ? draggedPlayer.y * stageHeight
      : lockNumberInRange(y, absYMin, absYMax);

    const relativeX = absoluteX / stageWidth;
    const relativeY = absoluteY / stageHeight;

    // total change in X (relative) since the start of dragging
    const diffX = relativeX - draggedPlayerAtDragStart.x;
    const diffY = relativeY - draggedPlayerAtDragStart.y;

    // use the difference that the dragged player has moved, to apply that same x and y delta to all selected players
    const nextPlayers = playersCopyOnDragStart.map((player) => {
      const nextX = lockNumberInRange(player.x + diffX, relXMin, relXMax);
      const nextY = lockNumberInRange(player.y + diffY, relYMin, relYMax);
      const playerIsSelected = selectedPlayerIds?.includes(player.id);

      return {
        ...player,
        x: playerIsSelected ? nextX : player.x,
        y: playerIsSelected ? nextY : player.y,
      };
    });

    setPlayers(nextPlayers);

    // required by Konva, must return the absoluteX and absoluteY value of dragged entity
    return {
      x: absoluteX,
      y: absoluteY,
    };
  };

  const onRegularPlayerDrag: KonvaDragHandler = ({ x, y }) => {
    const draggedPlayer = players.find((p) => p.id === draggedPlayerId);

    // as of 3/24/2023, this if block should no longer be necessary to prevent bugs
    // but I'm leaving it here anyway as a safeguard
    if (!selectedPlayerIds || selectedPlayerIds.length !== 1) {
      setSelectedPlayerIds(draggedPlayerId ? [draggedPlayerId] : null);
      return {
        x: (draggedPlayer?.x || 0) * stageWidth,
        y: (draggedPlayer?.y || 0) * stageHeight,
      };
    }

    const isDraggingOpponentPlayer = draggedPlayer?.playType !== formation.type;

    const xMin = 0;
    const xMax = stageWidth;
    const yMin = isDraggingOpponentPlayer ? 0 : lineOfScrimmageY * stageHeight;
    const yMax = isDraggingOpponentPlayer
      ? lineOfScrimmageY * stageHeight
      : stageHeight;

    const absoluteX = lockNumberInRange(x, xMin, xMax);
    const absoluteY = lockNumberInRange(y, yMin, yMax);

    const nextX = absoluteX / stageWidth;
    const nextY = absoluteY / stageHeight;

    const nextPlayers = players.map((player) => ({
      ...player,
      x: player.id === selectedPlayerIds[0] ? nextX : player.x,
      y: player.id === selectedPlayerIds[0] ? nextY : player.y,
    }));

    setPlayers(nextPlayers);

    return {
      x: absoluteX,
      y: absoluteY,
    };
  };

  // special behavior when user moves the shape representing the Center
  const onCenterDrag: KonvaDragHandler = (position) => {
    if (!playersCopyOnDragStart) {
      return position;
    }

    const draggedPlayerAtDragStart = playersCopyOnDragStart.find(
      (player) => player.id === selectedPlayerIds?.[0]
    );

    const draggedPlayer = players.find(
      (player) => player.id === selectedPlayerIds?.[0]
    );
    if (!draggedPlayerAtDragStart || !draggedPlayer) {
      return position;
    }

    const playersXMin = Math.min(...players.map((p) => p.x));
    const playersXMax = Math.max(...players.map((p) => p.x));
    // if any one of the players is at a side boundary
    // we should prevent further movement towards that side
    const shouldPreventXDecrease = playersXMin === 0;
    const shouldPreventXIncrease = playersXMax === 1;

    const { x } = position;
    const xIncreasing = x / stageWidth > draggedPlayer.x;
    const xDecreasing = x / stageWidth < draggedPlayer.x;

    const lockHorizontalMovement =
      (shouldPreventXIncrease && xIncreasing) ||
      (shouldPreventXDecrease && xDecreasing);

    const absoluteX = lockHorizontalMovement
      ? draggedPlayer.x * stageWidth
      : lockNumberInRange(x, 0, stageWidth);

    const absoluteY = draggedPlayerAtDragStart.y * stageHeight;

    const relativeX = absoluteX / stageWidth;

    // total change in X (relative) since the start of dragging
    const diffX = relativeX - draggedPlayerAtDragStart.x;

    if (!lockHorizontalMovement) {
      const nextPlayers = playersCopyOnDragStart.map((player) => {
        const nextX = lockNumberInRange(player.x + diffX, 0, 1);

        if (player.playType !== "Offensive") {
          return player;
        }

        return {
          ...player,
          x: nextX,
        };
      });

      setPlayers(nextPlayers);
    }

    return {
      x: draggedPlayer.x * stageWidth,
      y: absoluteY,
    };
  };

  const flipPlay = () => {
    const nextPlayers = flipPlayersByDimension(players, "x");
    setPlayers(nextPlayers);
  };

  const togglePlayerChanging = () => {
    setSelectedPlayerIds(null);
    setIsPlayerChanging(!isPlayerChanging);
  };

  const getSelectionBoxRectProps = (): {
    x: number; // in Konva, x supplied to Rect will be used for the x value of the top left corner
    y: number; // and y supplied to Rect will be used for the y value of the top left corner
    width: number;
    height: number;
  } => {
    if (!selectionBoxFirstCorner || !selectionBoxSecondCorner) {
      return { x: 0, y: 0, width: 0, height: 0 };
    }

    const [x1, y1] = selectionBoxFirstCorner;
    const [x2, y2] = selectionBoxSecondCorner;

    const topLeftCornerX = Math.min(x1, x2);
    const topLeftCornerY = Math.min(y1, y2);
    const bottomRightCornerX = Math.max(x1, x2);
    const bottomRightCornerY = Math.max(y1, y2);

    const topLeftCornerXAbsolute = topLeftCornerX * stageWidth;
    const topLeftCornerYAbsolute = topLeftCornerY * stageHeight;
    const width = (bottomRightCornerX - topLeftCornerX) * stageWidth;
    const height = (bottomRightCornerY - topLeftCornerY) * stageHeight;

    return {
      x: topLeftCornerXAbsolute,
      y: topLeftCornerYAbsolute,
      width: width,
      height: height,
    };
  };

  return (
    <div className={styles.editPlay}>
      <FormationSettingsToolbar
        formation={formation}
        setFormation={setFormation}
      />
      <div className={styles.playEditor} style={{ height: `${stageHeight}px` }}>
        <EditPlayToolbar
          selectedPlayerIds={selectedPlayerIds || undefined}
          setSelectedPlayerIds={setSelectedPlayerIds}
          canDrawRoutesAndZones={false}
          players={players}
          setPlayers={setPlayers}
          close={() => setSelectedPlayerIds(null)}
          isPlay={false}
          flipPlay={flipPlay}
          togglePlayerChanging={togglePlayerChanging}
          isPlayerChanging={isPlayerChanging}
        />
        <div
          className={styles.stageContainer}
          ref={stageContainerRef}
          style={{ height: `${stageHeight}px` }}
        >
          <div className={styles.stageAbsoluteWrapper}>
            <Stage
              width={stageWidth}
              height={stageHeight}
              onMouseDown={handleStageMouseDown}
              onTouchStart={handleStageTouchStart}
              onTouchMove={handleStageTouchMove}
              onTouchEnd={handleStageTouchEnd}
              onMouseMove={handleStageMouseMove}
              onMouseUp={handleStageMouseUp}
              onMouseLeave={handleStageMouseLeave}
            >
              <Layer>
                <Rect
                  listening={false}
                  fill={colors.fieldBackgroundGreen}
                  x={0}
                  y={0}
                  width={stageWidth}
                  height={stageHeight}
                />
                <FieldBackground
                  stageWidth={stageWidth}
                  stageHeight={stageHeight}
                  ballOn={50}
                />
                {isPlayerChanging && (
                  <PlayerChangingFieldOverlay
                    isPlay={false}
                    playType={formation.type}
                    stageWidth={stageWidth}
                    stageHeight={stageHeight}
                  />
                )}
                <Line
                  stroke={colors.lineOfScrimmageGray}
                  strokeWidth={3}
                  points={convertRelativeLineToAbsolute(
                    [0, lineOfScrimmageY, 1, lineOfScrimmageY],
                    stageWidth,
                    stageHeight
                  )}
                  listening={false}
                />
                {playerToBeAdded !== null && isPlayerChanging && (
                  <Group listening={false}>
                    <Shape
                      shape={convertPlayerToShape(
                        playerToBeAdded,
                        formation.type
                      )}
                      stageWidth={stageWidth}
                      stageHeight={stageHeight}
                      draggable={false}
                      isDragging={false}
                      isSelected={false}
                    />
                  </Group>
                )}
                {players.map((player: DrawablePlayerModel) => (
                  <Shape
                    key={player.id}
                    shape={convertPlayerToShape(player)}
                    stageHeight={stageHeight}
                    stageWidth={stageWidth}
                    draggable={!isPlayerChanging}
                    onDragStart={onPlayerDragStart}
                    onDragEnd={onPlayerDragEnd}
                    dragBoundFunc={onPlayerDrag}
                    isSelected={!!selectedPlayerIds?.includes(player.id)}
                    isDragging={player.id === draggedPlayerId}
                    isPlayerChanging={isPlayerChanging}
                    cannotDelete={players.length === 1}
                  />
                ))}
                {!!selectionBoxFirstCorner && !!selectionBoxSecondCorner && (
                  <Rect
                    listening={false}
                    fill={reduceAlpha(hexToRgbA(colors.lightning), 0.25)}
                    strokeWidth={1}
                    stroke={colors.lightning}
                    {...getSelectionBoxRectProps()}
                  />
                )}
              </Layer>
            </Stage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFormation;
