import Roster from "./pages/Roster/Roster";
import Playbook from "./pages/Playbook/Playbook";
import EditPlay from "./pages/EditPlay/EditPlay";
import Admin from "./pages/Admin/ManageLicensesAdmin";
import Account from "./pages/Account/Account";
import GlobalPlaybook from "./pages/Admin/GlobalPlaybook";
import PlaybookSettings from "./pages/PlaybookSettings/PlaybookSettings";
import EditFormation from "./pages/EditFormation/EditFormation";
import Quizzes from "./pages/Quizzes/Quizzes";
import Installs from "./pages/Installs/Installs";
import Dashboard from "./pages/Dashboard/Dashboard";
import MediaGallery from "./components/MediaGallery/MediaGallery";
/**
 * The logic for generating the title in the browser tab of this application comes from the "path" value
 * in these route objects. When creating a new route make sure that a path exists and it has only letters,
 * numbers, and the "-" characters (dashes). URL params will be ignored.
 */

const routes = [
  {
    path: "/admin",
    exact: true,
    component: Admin,
    display: "Manage Licenses",
    isAdmin: true,
    isWide: true,
  },
  {
    path: "/global-playbook",
    exact: true,
    component: GlobalPlaybook,
    display: "Manage Playbook",
    isAdmin: true,
  },
  {
    path: "/account",
    exact: true,
    component: Account,
    isAdmin: false,
    isOffCenter: true,
  },
  {
    path: "/dashboard",
    exact: true,
    component: Dashboard,
    display: "Dashboard",
    isAdmin: false,
    isOffCenter: true,
  },
  {
    path: "/dashboard/:module/:id?",
    exact: true,
    component: Dashboard,
    isAdmin: false,
    isOffCenter: true,
  },
  {
    path: "/account/:tab",
    exact: true,
    component: Account,
    isAdmin: false,
    isOffCenter: true,
  },
  {
    path: "/playbook-settings",
    exact: true,
    component: PlaybookSettings,
    isAdmin: false,
  },
  {
    path: "/playbook",
    exact: true,
    component: Playbook,
    display: "Playbook",
    isAdmin: false,
  },
  {
    path: "/flashcards",
    exact: true,
    component: Quizzes,
    display: "Flashcards",
    isAdmin: false,
  },
  {
    path: "/flashcards/:id",
    exact: true,
    component: Quizzes,
    isAdmin: false,
    isOffCenter: true,
  },
  {
    path: "/global-flashcards",
    exact: true,
    component: Quizzes,
    display: "Manage Flashcards",
    isAdmin: true,
  },
  {
    path: "/global-flashcards/:id",
    exact: true,
    component: Quizzes,
    isAdmin: true,
    isOffCenter: true,
  },
  {
    path: "/lessons",
    exact: true,
    component: Installs,
    display: "Lessons",
    isAdmin: false,
  },
  {
    path: "/lessons/:id",
    exact: true,
    component: Installs,
    isAdmin: false,
    isOffCenter: true,
  },
  {
    path: "/global-lessons",
    exact: true,
    component: Installs,
    display: "Manage Lessons",
    isAdmin: true,
  },
  {
    path: "/global-lessons/:id",
    exact: true,
    component: Installs,
    isAdmin: true,
    isOffCenter: true,
  },

  { path: "/edit-play/:id", exact: true, component: EditPlay, isAdmin: false },
  {
    path: "/edit-formation/:id",
    exact: true,
    component: EditFormation,
    isAdmin: false,
  },
  {
    path: "/edit-play-admin/:id",
    exact: true,
    component: EditPlay,
    isAdmin: true,
  },
  {
    path: "/media",
    exact: true,
    component: MediaGallery,
    isAdmin: false,
    display: "Media",
    isOffCenter: true,
  },
  {
    path: "/global-media",
    exact: true,
    component: MediaGallery,
    isAdmin: true,
    display: "Media",
    isOffCenter: true,
  },
  {
    path: "/roster",
    exact: true,
    component: Roster,
    display: "Roster",
    isAdmin: false,
  },
];

export default routes;
