import React, { useState, useContext } from "react";

import styles from "../../PlaybookSettings.module.scss";
import CharacterInput from "../../../../components/Input/CharacterInput";
import { TagModel } from "../../../../generated/from-api/models/tag.model";
import { TeamContext } from "../../../../shared/shared-with-mobile/providers/team.provider";
import { TagTypeEnum } from "../../../../generated/from-api/models/enums/tag-type.enum";
import { APIService } from "../../../../shared/shared-with-mobile/api-client/api.service";
import { TagsContext } from "../../../../shared/shared-with-mobile/providers/tags.provider";
import PlusIcon from "../../../../resources/icons/PlusIcon";
import TrashIcon from "../../../../resources/icons/TrashIcon";
import Button from "../../../../components/Button/Button";

interface Props {
  tagType: TagTypeEnum;
  customHeaderText?: string;
}

// this component currently used to show both "Situation" tab and "Personnel Package" tab
// depending on the "tagType" prop
// Schemes tab had a fairly different UI, so that was handled separately by "SchemesTab.tsx"
const TagsTab: React.FC<Props> = ({ tagType, customHeaderText }) => {
  const { currentTeam } = useContext(TeamContext);
  const {
    situationTags,
    personnelPackageTags,
    blitzTags,
    coverageTags,
    customQuizCategoryTags,
    addTag,
    updateTag,
    removeTag,
  } = useContext(TagsContext);

  let tags: TagModel[] = [];
  if (tagType === TagTypeEnum.SITUATION) {
    tags = situationTags;
  } else if (tagType === TagTypeEnum.PERSONNEL_PACKAGE) {
    tags = personnelPackageTags;
  } else if (tagType === TagTypeEnum.BLITZ) {
    tags = blitzTags;
  } else if (tagType === TagTypeEnum.COVERAGE) {
    tags = coverageTags;
  } else if (tagType === TagTypeEnum.CUSTOM_QUIZ_CATEGORY) {
    tags = customQuizCategoryTags;
  }

  const [newTagInput, setNewTagInput] = useState<string>("");

  const createTag = async () => {
    const teamId: string = currentTeam?.id || "";

    const newTag: TagModel = {
      name: newTagInput,
      teamId,
      type: tagType,
      deleted: false,
      category: null,
      createdBy: "",
    };

    const created = await APIService.TAG.POST(newTag);

    if (created) {
      addTag(created, tagType);
      setNewTagInput("");
    }
  };

  const deleteTag = async (tagToDelete: TagModel) => {
    if (tagToDelete.id) {
      const response = await APIService.TAG.DELETE(tagToDelete.id);

      if (response && response.deleted) {
        removeTag(tagToDelete.id, tagType);
      }
    }
  };

  const renameTag = async (tag: TagModel, newTagName: string) => {
    if (tag.id) {
      const updated = await APIService.TAG.PUT(tag.id, {
        name: newTagName,
      });

      if (updated) {
        updateTag(updated, tagType);
      }
    }
  };

  const getTabHeader = (type: TagTypeEnum) => {
    if (customHeaderText) {
      return customHeaderText;
    }

    const tagAsLowerCase = type.toLowerCase();
    if (tagAsLowerCase.endsWith("s") || tagAsLowerCase.endsWith("z")) {
      return `${tagAsLowerCase}es`;
    } else {
      return `${tagAsLowerCase}s`;
    }
  };

  return (
    <div className={`${styles.tabContent} ${styles.tagsTab}`}>
      <div className={styles.tabHeader}>{getTabHeader(tagType)}</div>
      {tags.map((tag: TagModel) => (
        <div key={tag.id} className={styles.inputRow}>
          <CharacterInput
            value={tag.name}
            maxLength={100}
            onBlur={(e) => {
              renameTag(tag, e.target.value);
            }}
          />
          <Button
            className={styles.button}
            theme={"transparent"}
            icon={<TrashIcon />}
            onClick={() => {
              deleteTag(tag);
            }}
          />
        </div>
      ))}
      <div className={styles.inputRow}>
        <CharacterInput
          placeholder="Add new"
          value={newTagInput}
          maxLength={100}
          onChange={(e) => {
            setNewTagInput(e.target.value);
          }}
        />
        <Button
          className={`${styles.button} ${styles.plusIcon} ${
            newTagInput && styles.showing
          }`}
          theme={"transparent"}
          icon={<PlusIcon />}
          onClick={createTag}
        />
      </div>
    </div>
  );
};

export default TagsTab;
