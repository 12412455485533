import React, { useContext, useState } from "react";
import styles from "./TeamPlayers.module.scss";
import CharacterInput from "../../Input/CharacterInput";
import { INPUT_TYPES } from "../../../utils/web-only-constants";
import Button from "../../Button/Button";
import { APIService } from "../../../shared/shared-with-mobile/api-client/api.service";
import { InvitationsContext } from "../../../shared/shared-with-mobile/providers/invitations.provider";
import { UIContext } from "../../../shared/shared-with-mobile/providers/ui.provider";
import CloseIcon from "../../../resources/icons/CloseIcon";
import PhoneInput from "react-phone-input-2";
import InviteWithCode from "../../Invitations/InviteWithCode/InviteWithCode";

interface Props {
  teamId?: string;
}

interface emptyPlayer {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const emptyPlayer: emptyPlayer = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
};

const TeamPlayers: React.FC<Props> = ({ teamId }) => {
  const { dispatchToast } = useContext(UIContext);
  const {
    currentPlayerInvitations,
    addOrUpdateInvitation,
    removeInvitation,
  } = useContext(InvitationsContext);

  const [player, setPlayer] = useState<emptyPlayer>(emptyPlayer);

  const invitePlayer = async () => {
    try {
      const newPlayerInvite = await APIService.INVITATION.POST({
        type: "PLAYER",
        teamId: teamId ? teamId : "",
        firstName: player.firstName,
        lastName: player.lastName,
        toPhoneNumber: player.phoneNumber,
      });

      if (newPlayerInvite) {
        addOrUpdateInvitation(newPlayerInvite);
        setPlayer(emptyPlayer);
      }

      dispatchToast({
        type: newPlayerInvite ? "success" : "error",
        message: newPlayerInvite
          ? `Invitation to ${newPlayerInvite.firstName} ${newPlayerInvite.lastName} was created.`
          : "Invitation Failed. Please try again.",
      });
    } catch (error) {
      dispatchToast({
        type: "error",
        message: "Invitation Failed. Please try again.",
      });
    }
  };

  const deleteInvitation = async (invitationId?: string) => {
    if (invitationId) {
      try {
        await APIService.INVITATION.DELETE(invitationId);
        removeInvitation(invitationId);
        dispatchToast({
          type: "success",
          message: "Invitation has been deleted.",
        });
      } catch (error) {
        dispatchToast({
          type: "error",
          message: "Error deleting invitation.",
        });
      }
    } else {
      dispatchToast({
        type: "error",
        message: "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <div className={styles.teamPlayers}>
      <div className={styles.teamPlayers__InviteWrapper}>
        <InviteWithCode teamId={teamId} />
      </div>
      <div className={styles.teamPlayers__PlayersList}>
        <div className={styles.teamPlayers__PlayersListRow}>
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder={"First Name"}
            value={player.firstName}
            onChange={(e) =>
              setPlayer((prevState: emptyPlayer) => ({
                ...prevState,
                firstName: e.target.value,
              }))
            }
            autofocus
          />
          <CharacterInput
            type={INPUT_TYPES.TEXT}
            placeholder={"Last Name"}
            value={player.lastName}
            onChange={(e) =>
              setPlayer((prevState: emptyPlayer) => ({
                ...prevState,
                lastName: e.target.value,
              }))
            }
          />
          <PhoneInput
            containerClass="phoneInputContainer"
            inputClass="phoneInput phoneInput--heightNormal"
            buttonClass="phoneInputButton"
            dropdownClass="phoneInputDropdown"
            placeholder="+1234567890"
            country="us"
            onChange={(value) =>
              setPlayer((prevState: emptyPlayer) => ({
                ...prevState,
                phoneNumber: value,
              }))
            }
            inputProps={{
              required: true,
            }}
            value={player.phoneNumber}
          />
          <div>
            <Button
              onClick={invitePlayer}
              disabled={
                !(player.firstName && player.lastName && player.phoneNumber)
              }
            >
              Add
            </Button>
          </div>
        </div>
        {currentPlayerInvitations &&
          Array.from(currentPlayerInvitations.entries())
            .filter(([key, invitation]) => key && invitation.teamId === teamId)
            .reverse()
            .map((invitation) => {
              const [key, value] = invitation;
              return (
                <div key={key} className={styles.teamPlayers__PlayersListRow}>
                  <CharacterInput
                    type={INPUT_TYPES.TEXT}
                    value={value.firstName}
                    readonly
                  />
                  <CharacterInput
                    type={INPUT_TYPES.TEXT}
                    value={value.lastName}
                    readonly
                  />
                  <PhoneInput
                    containerClass="phoneInputContainer"
                    inputClass="phoneInput phoneInput--heightNormal"
                    buttonClass="phoneInputButton"
                    dropdownClass="phoneInputDropdown"
                    country="us"
                    value={value.toPhoneNumber}
                    disabled
                  />
                  <div>
                    <Button
                      theme={"transparent"}
                      icon={<CloseIcon />}
                      onClick={() => deleteInvitation(value.id)}
                    />
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default TeamPlayers;
