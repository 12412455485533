import React, { useEffect, useState, useContext } from "react";
import styles from "./PlayerModal.module.scss";
import Tabs from "../../../../components/Tabs/Tabs";
import Avatar from "../../../../shared/shared-with-mobile/components/PlayerAvatar/Avatar";
import { PlayerModel } from "../../../../generated/from-api/models/relationships/player.model";
import PlayerOverall from "../../DashboardOverall/PlayerOverall";
import PlayerPlaybook from "../../Playbook/Playbook-components/PlayerDrillIn/PlayerPlaybook";
import CloseIcon from "../../../../resources/images/close.svg";
import PlayerLessons from "../../Lessons/Lesson-components/PlayerDrillIn/PlayerLessons";
import PlayerQuizzes from "../../Quizzes/Quiz-components/PlayerDrillIn/PlayerQuizzes";
import { ReadinessScoresContext } from "../../../../shared/shared-with-mobile/providers/readinessScores.provider";
import { getPlayerRanking } from "../../../../shared/shared-with-mobile/utilities/getDataForPlayerDetailStats";
import { PlayerReadinessSnapshotModel } from "../../../../generated/from-api/models/readiness-snapshot/player-readiness-snapshot.model";
import { APIService } from "../../../../shared/shared-with-mobile/api-client/api.service";

type PlayerTab = "overall" | "playbook" | "lessons" | "flashcards";

interface Props {
  player: PlayerModel;
  visible: boolean;
  defaultTab?: PlayerTab;
  close: () => void;
}

const PlayerModal: React.FC<Props> = ({
  player,
  visible,
  defaultTab = "overall",
  close,
}) => {
  const [currentTab, setCurrentTab] = useState<PlayerTab>(defaultTab);
  const [closing, setClosing] = useState<boolean>(false);
  const { playerOverallMetrics } = useContext(ReadinessScoresContext);
  const [overallRank, setOverallRank] = useState<number | undefined>(); // score+category+position+leaderscore

  const [playerReadinessOverTime, setPlayerReadinessOverTime] = useState<
    PlayerReadinessSnapshotModel[]
  >([]);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        close();
        setClosing(false);
      }, 350);
    }
  }, [closing]);

  useEffect(() => {
    let isCancelled = false;

    const loadData = async () => {
      const newRanking = getPlayerRanking(
        player,
        playerOverallMetrics,
        "overallScore"
      );
      setOverallRank(newRanking);
      const fiveWeeksInMS = 1000 * 60 * 60 * 24 * 7 * 5;
      const playerDataOverTime = await APIService.DASHBOARD.GET_PLAYER_READINESS_OVER_TIME(
        player.id as string,
        new Date(new Date().getTime() - fiveWeeksInMS).toISOString(),
        new Date().toISOString()
      );

      if (!isCancelled && playerDataOverTime) {
        setPlayerReadinessOverTime(playerDataOverTime);
      }
    };
    if (player && player.id && player.userId) {
      loadData();
    }
    return () => {
      isCancelled = true;
    };
  }, [player]);

  return (
    <>
      <div
        className={`
          ${styles.editRosterWrapper}
          ${visible ? styles.open : ""}
          ${closing ? styles.closing : ""}
        `}
      >
        <div
          className={`
            ${styles.editRoster}
          `}
        >
          <div className={styles.modalHeader}>
            <div className={styles.playerContainer}>
              <div className={styles.avatarContainer}>
                <Avatar
                  userId={player.id ? player.id : ""}
                  rankingData={overallRank}
                  type="player"
                />
              </div>
              <div className={styles.playerInfoWrapper}>
                <div className={styles.playerName}>
                  {`${player.firstName} ${player.lastName}`}
                </div>
                {player.positions && (
                  <div className={styles.playerPositions}>
                    {player.positions}
                  </div>
                )}
                <div className={styles.playerScore}>{player.schoolYear}</div>
              </div>
            </div>
            <span className={styles.closeIcon} onClick={() => setClosing(true)}>
              <img src={CloseIcon} />
            </span>
          </div>
          <div className={styles.editRosterBody}>
            <Tabs
              defaultTab={defaultTab}
              onSelect={(selection) => setCurrentTab(selection as PlayerTab)}
            >
              {["overall", "playbook", "lessons", "flashcards"]}
            </Tabs>
            <div className={styles.rosterTab}>
              {currentTab === "overall" && (
                <div className={styles.contentWrapper}>
                  <PlayerOverall
                    player={player}
                    playerReadinessOverTime={playerReadinessOverTime}
                  />
                </div>
              )}
              {currentTab === "playbook" && (
                <div className={styles.contentWrapper}>
                  <PlayerPlaybook
                    player={player}
                    playerReadinessOverTime={playerReadinessOverTime}
                  />
                </div>
              )}
              {currentTab === "lessons" && (
                <div className={styles.contentWrapper}>
                  <PlayerLessons
                    player={player}
                    playerReadinessOverTime={playerReadinessOverTime}
                  />
                </div>
              )}
              {currentTab === "flashcards" && (
                <div className={styles.contentWrapper}>
                  <PlayerQuizzes
                    player={player}
                    playerReadinessOverTime={playerReadinessOverTime}
                  />
                </div>
              )}
              {/* {currentTab === "engagement" && (
                <div className={styles.contentWrapper}>
                  <PlayerOverall />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.editRosterOverlay} ${visible ? styles.open : ""} ${
          closing ? styles.closing : ""
        }`}
        onClick={() => {
          setClosing(true);
        }}
      />
    </>
  );
};

export default PlayerModal;
