import { mean } from "lodash";
import { FormationModel } from "../../generated/from-api/models/drawable/formation.model";
import { PlayOrder } from "../../generated/from-api/models/play-set.model";
import { PlayModel } from "../../generated/from-api/models/play.model";
import {
  defensivePositions,
  offensivePositions,
  specialTeamsPositions,
} from "./play-editor/playEditor.constants";

/**
 * @description Given an array of positions and the type of position to search for, determine if the array contains a position within the matching type.
 * @param {string[]} positions
 * @param {"" | "offensive" | "defensive" | "specialTeams"} type
 * @returns {boolean} boolean
 */
export const hasPositionType = (
  positions: string[],
  type: "" | "offensive" | "defensive" | "specialTeams"
): boolean => {
  switch (type) {
    case "specialTeams":
      return positions.some((item: any) =>
        specialTeamsPositions.includes(item)
      );
    case "defensive":
      return positions.some((item: any) => defensivePositions.includes(item));
    case "offensive":
      return positions.some((item: any) => offensivePositions.includes(item));
    default:
      return true;
  }
};

/**
 * @description Given an array of formation IDs and and an array of formations, return an array of formation names.
 * @param {string[]} formationIds
 * @param {FormationModel[]} formations
 * @returns {string[]}
 */
export const convertFormationIdsToNames = (
  formationIds: string[],
  formations: FormationModel[]
): (string | undefined)[] =>
  formationIds.map(
    (formationId) =>
      formations.find((formation) => formation.id === formationId)?.name
  );

export type PositionFilter = {
  label: string;
  value: "" | "offensive" | "defensive" | "specialTeams";
};
export const PositionFiltersMap: PositionFilter[] = [
  { label: "All", value: "" },
  { label: "Offense", value: "offensive" },
  { label: "Defense", value: "defensive" },
  { label: "S.T.", value: "specialTeams" },
];

export type PlayerRow = {
  playerFirstName: string;
  playerLastName: string;
  playerId: string;
  playerUserId: string;
  positions: string;
  readiness: number;
};

/**
 * @description Given an array objects that contains the type of positions as in category and average readiness score as in averageScore.
 * @param  type PlayerRow
 * @returns {category: "type_of_position",averageScore: avg_readiness} object[]
 */

export const formatReadinessByUnitChartData = (
  players: PlayerRow[]
): {
  category: string;
  averageScore: number;
}[] => {
  const offensiveScores = players
    .filter((player) => {
      return (
        player.positions &&
        hasPositionType(player.positions.split("|"), "offensive")
      );
    })
    .map((player) => Number(player["readiness"]));
  const defensiveScores = players
    .filter((player) => {
      return (
        player.positions &&
        hasPositionType(player.positions.split("|"), "defensive")
      );
    })
    .map((player) => Number(player["readiness"]));

  const specialTeamsScores = players
    .filter((player) => {
      return (
        player.positions &&
        hasPositionType(player.positions.split("|"), "specialTeams")
      );
    })
    .map((player) => Number(player["readiness"]));

  const offensiveAvg = mean(offensiveScores) || 0;
  const defensiveAvg = mean(defensiveScores) || 0;
  const specialTeamsAvg = mean(specialTeamsScores) || 0;

  return [
    {
      category: "Offense",
      averageScore: offensiveAvg,
    },
    {
      category: "Defense",
      averageScore: defensiveAvg,
    },
    {
      category: "S.T.",
      averageScore: specialTeamsAvg,
    },
  ];
};

// Parse CSV file to JSON
export const CSVToJSON = (
  csv: string | ArrayBuffer | null
): any[] | undefined => {
  if (typeof csv !== "string") return;

  const cleanCsv = csv.trim().replaceAll('"', "");

  const lines = cleanCsv.split("\n");
  const keys = lines[0].trim().split(",");

  return lines.slice(1).map((line: string) => {
    return line
      .trim()
      .split(",")
      .reduce((acc, cur: string, i: number) => {
        const toAdd: any = {};
        toAdd[keys[i]] = cur;
        return { ...acc, ...toAdd };
      }, {});
  });
};

// used to check if a quiz or lesson should be shown to a player, based on their position
// both positionStr and playerPositionStr will be formatted like "QB|RB|TE|WR"
// if at least one position exists in both positionStr and playerPositionStr, return true
// otherwise return false
export const doPositionsOverlap = (
  positionsStr: string,
  playerPositionsStr: string
): boolean => {
  if (!positionsStr || !playerPositionsStr) {
    return true;
  }
  const positionsArr = positionsStr.split("|");
  const playerPositionsArr = playerPositionsStr.split("|");

  for (const position of positionsArr) {
    if (playerPositionsArr.includes(position)) {
      return true;
    }
  }

  return false;
};

export const getPositionArray = (positionStr: string | undefined) => {
  return positionStr && positionStr.length > 0 ? positionStr.split("|") : [];
};

export const getGamifiedPlays = (
  list1: PlayModel[],
  list2: PlayModel[],
  isUnion = false
) =>
  // if isUnion true -> get common items from both lists.
  // if isUnion false -> get difference from list 1.
  list1.filter(
    ((set) => (list1Play: PlayModel) => isUnion === set.has(list1Play.id))(
      new Set(list2.map((list2Play: PlayModel) => list2Play.id))
    )
  );

export const getGamifiedPlayOrders = (
  list1: PlayOrder[],
  list2: PlayModel[],
  isUnion = false
) =>
  // if isUnion true -> get common items from both lists.
  // if isUnion false -> get difference from list 1.
  list1.filter(
    ((set) => (list1PlayOrder: PlayOrder) =>
      isUnion === set.has(list1PlayOrder.playId))(
      new Set(list2.map((list2Play: PlayModel) => list2Play.id))
    )
  );
