import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styles from "./WelcomeCarousel.module.scss";
import MobileStepper from "@material-ui/core/MobileStepper";
import Typography from "@material-ui/core/Typography";
import welcomeStep1 from "../../resources/images/welcomeStep1.png";
import welcomeStep2 from "../../resources/images/welcomeStep2.png";
import welcomeStep3 from "../../resources/images/welcomeStep3.png";
import welcomeStep4 from "../../resources/images/welcomeStep4.png";
import welcomeStep5 from "../../resources/images/welcomeStep5.png";
import welcomeStep6 from "../../resources/images/welcomeStep6.png";
import Button from "../Button/Button";
import { UIContext } from "../../shared/shared-with-mobile/providers/ui.provider";
import { UserContext } from "../../shared/shared-with-mobile/providers/user.provider";
import { UserProfileModel } from "../../generated/from-api/models/user-profile.model";
import { APIService } from "../../shared/shared-with-mobile/api-client/api.service";

const tutorialSteps = [
  {
    label: "",
    description:
      "From here, you can create and customize content for your team to learn. The content will be used to automatically create learning games for your athletes to play on their phones.",
    imgPath: welcomeStep1,
  },
  {
    label: "Playbook",
    description:
      "Create formations, plays, and installs which your athletes can learn using their phones. You see a playbook, your team sees games.",
    imgPath: welcomeStep2,
  },
  {
    label: "Team Nation Library",
    description:
      "Your account gives you access to top-tier pre-built content including plays, flashcard games, and lessons. Some of it has been automatically activated for your team so you can hit the ground running.",
    imgPath: welcomeStep3,
  },
  {
    label: "Flashcards",
    description:
      "Any content you want your players to learn outside of the playbook can be added as flashcard games. The only limit is your creativity",
    imgPath: welcomeStep4,
  },
  {
    label: "Lessons",
    description:
      "Teach your program from anywhere, anytime. Build slides and even add narration so athletes learn at their own pace, letting you focus on what’s most important when the team is together",
    imgPath: welcomeStep5,
  },
  {
    label: "Leader Board",
    description:
      "Get real-time insights into how well your team knows your program and where to spend your practice time. Look at the team as a whole or focus in on a specific athlete, play, or game.",
    imgPath: welcomeStep6,
  },
];

interface WelcomeProps {
  visible?: boolean;
}

const Welcome: React.FC<WelcomeProps> = ({ visible = true }) => {
  const history = useHistory();
  const { userProfile, updateProfile } = useContext(UserContext);
  const { closeModal } = useContext(UIContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [closing, setClosing] = useState<boolean>(false);
  const maxSteps = tutorialSteps.length;

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 350);
    }
  }, [closing]);

  const handleNext = () => {
    if (activeStep == maxSteps - 1) {
      handleDone();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDone = async () => {
    if (!userProfile) {
      return;
    }

    const updatedUserProfile: UserProfileModel = {
      ...userProfile,
      tooltipsInfo: {
        ...userProfile.tooltipsInfo,
        welcomeCarouselStep: 0,
      },
    } as UserProfileModel;

    const updated = await APIService.USER_PROFILE.PUT(
      userProfile.id,
      updatedUserProfile
    );

    if (updated) {
      updateProfile(updated);
      closeModal();
      history.push("/playbook");
    }
  };

  return (
    <>
      <div
        className={`
            ${styles.wrapper}
            ${visible ? styles.open : ""}
            ${closing ? styles.closing : ""}
            `}
        data-lockfocus={visible}
      >
        <div
          className={`
                ${styles.welcome}
            `}
        >
          <div className={styles.modalHeader}>
            <div style={{ fontStyle: "italic", fontSize: 36, fontWeight: 700 }}>
              WELCOME TO THE TEAM NATION COACH PORTAL
            </div>
          </div>
          <div className={styles.welcomeBody}>
            <img
              className={styles.image}
              src={tutorialSteps[activeStep].imgPath}
              alt={tutorialSteps[activeStep].label}
            />
            <Typography variant="h4">
              {tutorialSteps[activeStep].label}
            </Typography>
            <Typography variant="body1">
              {tutorialSteps[activeStep].description}
            </Typography>
            {/* <div>
              {" "}
              <Button size="medium" theme="secondary">
                Learn More
              </Button>
            </div> */}
          </div>
          <div className={styles.modalFooter}>
            <MobileStepper
              steps={maxSteps}
              className={styles.footer}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={
                <Button size="small" onClick={handleNext}>
                  {activeStep === maxSteps - 1 ? "Done" : "Next"}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  theme={activeStep === 0 ? "tertiary" : "secondary"}
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  Previous
                </Button>
              }
            />
          </div>
        </div>
      </div>
      <div
        className={`${styles.welcomeOverlay} ${visible ? styles.open : ""} ${
          closing ? styles.closing : ""
        }`}
        onClick={() => setClosing(true)}
      />
    </>
  );
};

export default Welcome;
